import React, { Component } from 'react';
import StationStats from './StationStats.js';
import Map from './Map';
import { connect } from 'react-redux';
import {getStationMetrics} from '../../actions/header'
import { universalMetrics } from '../../configuration/enums'
import { getConfigItem } from '../../api/index'
const DEBUG_MODE = process.env.DEBUG_MODE;

class header extends Component {

    constructor(props) {
        super(props);
        var headerSettings = getConfigItem('$.general.header')
        var headerHeight = getConfigItem('$.general.header.headerHeight') || 250

        this.toggle = this.toggle.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            dropdownOpen: false, collapsed: true, headerSettings, headerHeight
        };
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    timer() {
        if (!DEBUG_MODE) {
            this.props.getStationMetrics();
        }
    }

    componentDidMount() {
        this.props.getStationMetrics();
        this.timerId = setInterval(this.timer.bind(this), 10000);
    }

    getMetric(metid, avg, factor, decimalPlaces) {
        var result;

        if (avg && this.props.filterAverageMetric) {
            result = this.props.filterAverageMetric[metid];
        } else if(this.props.filterTotalMetric)  {
            result = this.props.filterTotalMetric[metid];
        }
        if (result != undefined) {
            result = result * factor
            if (result < 0.01)
            {
                result = 0;
            }
            return result.toFixed(decimalPlaces);
        } else {
            return '-';
        }
    }

    getHeaderRender() {
        var headerBG = <Map />

        if (this.state.headerSettings) {
            var s = this.state.headerSettings
            if (s.customBanner) {
                return <div className='headerImg'><img src={'../../../content/banners/' + s.customBanner} /></div>
            }
        }
        else if (DEBUG_MODE) {
            return <div className='headerImg debugHeader'><img src={'../../../content/banners/debug.jpg'} /></div>
        }

        return headerBG
    }

    render() {
        return (
            <div className="container-fluid" id="top-container" style={{height: this.state.headerHeight + 'px'}} >
                
                <StationStats getMetric={(metid, avg, factor, decimalPlaces) =>  this.getMetric(metid, avg, factor, decimalPlaces)} windSpeed={this.getMetric(universalMetrics.WindSpeed, true, 1, 1)} totalExport={this.getMetric(universalMetrics.RocPower, false, 0.001, 2)} capacityFactor={"-"} /> 

                {this.getHeaderRender()}

            </div>
        );
    }
}

const mapStateToProps = state => {
    return state.common.header
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getStationMetrics: () => {
      dispatch(getStationMetrics());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(header)