import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Modal, ModalFooter, ModalBody, Button, ModalHeader } from 'reactstrap'
import classnames from 'classnames'
import { ApiGet } from '../../../api'
import moment from 'moment'
import DropDatePicker from '../../../components/DropDatePicker'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown';
import Dash from '../../Dashboard/components/DashContainer';
export const API_PATH = process.env.SERVER_PATH + "/api";

class Cases extends Component {

    mdRefCallback = (instance) => {
        this.caseMD = instance ? instance.getWrappedInstance() : null;
    }

    pillColors = {oC: 'danger', mC: 'warning', cC: 'success', pC: 'info'}
    constructor(props) {
        super(props);
        var date = new Date()
        this.handleDate = this.handleDate.bind(this)
        this.setCaseOption = this.setCaseOption.bind(this)
        this.state = {
            data: null, columns: null, subcomponents: null, complete: false, activeTab: '1', mainActiveTab: '1', caseModalOpen: false, guid: null, casesPageSize: 5,
            fromDate: moment(date).add(-7, 'days').startOf('day'), toDate: moment(date.getTime()).endOf('day'), caseCount: null,
            caseOptions: [{ value: 1, field: 'All Open' }, { value: 2, field: '30 Days Last Occ' }, { value: 3, field: '30 Days Fault Freq' }, { value: 4, field: '30 Days Downtime' }], selectedOption: 1,
            statusModal: false, caseInfo: null
        };
    }

    componentDidMount() {
        this.getCaseCount()
        this.setState(this.caseMD.getFromHistory(['selectedOption']))
    }

    getCaseCount() {
        ApiGet('Cases', 'GetCaseCount')
        .then((data) => { this.setState({ caseCount: data }) })
    }

    goNav(rec, location) {
        this.props.history.push(location)
    }

    getPill(tabid) {
        let ret = null
        if (this.state.caseCount) {
            const v = this.state.caseCount[tabid]
            let cl = "badge badge-pill badge-primary"
            if (this.pillColors[tabid]) {
                cl = "badge badge-pill badge-" + this.pillColors[tabid]
            }
            //if (v > 0) {
                ret = <span className={cl}>{v}</span>
            //}
        }
        return ret;
    }

    toggleCaseModal(modalId, rec, e) {
        console.log('toggle', modalId)
        var wGuid = null;
        if (rec) {
            wGuid = rec.wcardGuid;
        }
        this.setState({ [modalId]: !this.state[modalId], guid: wGuid})
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab, selectedOption: 2});
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    closeCase(id) {
        window.alertbox.openYesNo('Close case ' + id + '?', () => { ApiPost('Cases', 'CloseCase', { CaseId: id }).then((data) => { console.log('caseData', data); }), this.props.history.goBack() })
    }

    addResolution(res) {
        console.log('res',res)
        var temp = this.state.caseInfo
        temp.caseRes = res;
        this.setState({caseInfo: temp})
    }

    renderModal() {
        if (this.state.caseInfo) {
            return (
                <Modal key={'md' + this.props.level} isOpen={this.state.statusModal} autoFocus={false} style={{ maxWidth: '400px' }} modalTransition={{ timeout: 0 }} >
                    <ModalHeader>'{this.state.caseInfo.caseRef}' Resolution</ModalHeader>
                    <ModalBody style={{ height: '130px', backgroundColor: 'rgb(243, 243, 243)' }}>
                        <Row>
                            <Col sm="12">
                                <textarea className="form-control" style={{ resize: 'none' }} rows={5} onBlur={e => this.addResolution(e.target.value)} />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" style={{ marginRight: 'auto' }} onClick={() => this.toggleCaseModal('statusModal')}>Cancel</Button>
                        <Button color="primary" onClick={() => this.setCustomDDValue()}>Save</Button>
                    </ModalFooter>
                </Modal>)
        }
        else {
            return null
        }
    }

    handleDate(fromDate, toDate, custom) {
        this.setState({ fromDate, toDate, dateSelectTitle: moment(fromDate).format('DD/MM/YY') + ' to ' + moment(toDate).format('DD/MM/YY') })
    }

    setCustomDDValue(e, rec) {
        if (!this.state.caseInfo) {
            console.log('e', e)
            console.log('currentRec', rec)
            this.setState({ caseInfo: { caseId: rec.id, caseStatus: e, caseRef: rec.caseRef } }, () => (e != 4 && e != 1 && rec.status != 3) ? this.toggleCaseModal('statusModal') : this.updateCaseStatus())
        }
        else {
            this.toggleCaseModal('statusModal')
            this.updateCaseStatus()
        }
        
    }

    updateCaseStatus() {
        ApiPost('Cases', 'UpdateCaseStatus', this.state.caseInfo)
        .then((data) => {
            console.log('data', data)
            this.caseMD.refreshData()
            this.getCaseCount()
            this.setState({ caseInfo: null })
        });
    }

    getCustomDD(options, rec) {
        //var rO = []
        //options.map(x => {
        //    rO.push({id: x.id, name: x.id})
        //})
        return <IdNameDropdown style={{ padding: '0rem .75rem', height: '100%' }} options={options} valueField='id' displayField='name' onChange={(e) => this.setCustomDDValue(e,rec)} defaultValue={rec.status} />
    }

    cascadeComponents() {
        var iTaskFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Complete', fieldId: 'complete', type: 'checkbox', width: 70, display: 'grid' },
            { label: 'CaseId', fieldId: 'caseId', display:'none', getId: -1},
            { label: 'Task', fieldId: 'task', inlineEdit: true, type:'text', width: 500 },
            { label: 'Assigned', fieldId: 'assignedTo', list: listRequest.operators, inlineEdit: true,},
            { label: 'Completed', fieldId: 'completeDate', type: 'datetime' },
            //{ label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];
        var iTaskConfig = {
            model: 'iTasks',
            path: '/tasks',
            fields: iTaskFields,
            crudParams: { caseId: this.props.match.params.id },
            gridButton: false,
            gridProps: { defaultPageSize: 5 },
            addInline: true,
            type: 'checklist',
            apiPath: API_PATH
		}
        return <MasterDetail {...iTaskConfig} />
    }

    getTab(caseStatus) {

        var lists = {
            status: [{ id: 1, name: 'Open' }, { id: 2, name: 'Closed' }, { id: 4, name: 'Low Priority' }, { id: 3, name: 'Monitoring' }],
            quotes: [{ id: 1, name: 'Initial Estimate' }, { id: 2, name: 'Quoted' }, { id: 3, name: 'Confirmed Cost' }]
        };

        var casesFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Case Title', fieldId: 'caseRef', canFilter: true, section: 'Info' },
            { label: 'Days Open', fieldId: 'daysOpen', type: 'info', typeProps: { suffix: ' days' }, width: 70, canFilter: true, section: 'Info', col:2  },
            { label: 'Category', fieldId: 'category', display: 'none' },
            { label: 'Asset', fieldId: 'stationId', list: listRequest.windsites, validation: 'required', section: 'Info' },
            { label: 'Tasks', fieldId: 'taskCount', width: 50, display: 'grid' },
            { label: 'Total Downtime', fieldId: 'downTime', type: 'info', typeProps: { subType: 'duration' }, display: 'form', section: 'Info', col: 2,  },
            { label: 'Count', fieldId: 'faultCount', width: 70, display: this.state.selectedOption != 3 ? 'none' : 'grid' },
            { label: 'Trend', fieldId: 'faultFreq', width: 70, type: 'percentageIndicator', display: this.state.selectedOption != 3 ? 'none' : 'grid' },
            { label: 'Downtime', fieldId: 'totalDownTime', width: 70, display: this.state.selectedOption != 4 ? 'none' : 'grid' },
            { label: 'DT Trend', fieldId: 'downtimeFreq', width: 70, type:'percentageIndicator', display: this.state.selectedOption != 4 ? 'none' : 'grid' },
            { label: 'Log', fieldId: 'recentLog', display: 'grid', type: 'logSummary' },
            { label: 'Last Occ', fieldId: 'lastOccurance', type: 'datetime', width: 120, canFilter: true, display: 'grid' },
            { label: 'Status', fieldId: 'status', width: 50, list: lists.status, section: 'Info', display:'form' },
            { label: 'Status', fieldId: 'updateStatus', type: 'custom', custom: (rec) => this.getCustomDD(lists.status, rec), width: 100, display: 'grid' },
            { label: 'Downtime Cost', fieldId: 'downTimeCost', type: 'info', display: 'form', section: 'Info', col: 2  },
            { label: '', fieldId: '', type: 'info', display: 'form', section: 'Info'},
            { label: 'Total Downtime Cost', fieldId: 'totalDownTimeCost', type: 'info', display: 'form', section: 'Info', col: 2 },
            { label: 'Latest Update', fieldId: 'caseMit', display: 'form', type: 'textarea', section: 'Info' },
            { label: 'Resolution', fieldId: 'resolution', display: 'form', type: 'textarea', displayIf: (rec) => rec.status == 2 || rec.status == 3, validation: 'required', section: 'Info' },
            { label: 'Quote Stage', fieldId: 'quoteStage', display: 'form', section: 'Costs', list: lists.quotes },
            { label: 'Materials Cost', fieldId: 'costMaterials', display: 'form', section: 'Costs', validation: 'number' },
            { label: 'Labour Cost', fieldId: 'costLabour', display: 'form', section: 'Costs', validation: 'number' },
            { label: 'Cost Description', fieldId: 'costDescr', display: 'form', section: 'Costs' },
            { label: '', fieldId: 'navButton', type: 'navButton', display: 'grid', width: 70 }
        ];

        var incidentFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Description', fieldId: 'desc' },
            { label: 'Source', fieldId: 'source', width: 100, type: 'readOnly' },
            { label: 'Fault', fieldId: 'fault', type: 'readOnly' },
            { label: 'Created', fieldId: 'created', type: 'datetime', display:'none' },
            { label: 'Resolved', fieldId: 'resolved', type: 'datetime', display: 'none' },
            { label: 'Duration (Hrs)', fieldId: 'duration', type: 'readOnly', width: 120, display: 'none' },
            { label: "Alert Period", width: 240, fieldId: "range", display: 'grid', type: 'dateRange', typeProps: { sDate: 'created', eDate: 'resolved', showDuration: true } },
            { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { location: '/Incidents/' }, width: 70 }
        ];

        var scheduledFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'grid' },
            { label: 'Description', fieldId: 'desc' },
            { label: 'Source', fieldId: 'source', type: 'readOnly' },
            { label: 'From', fieldId: 'created', type: 'datetime', display: 'none'  },
            { label: 'End', fieldId: 'resolved', type: 'datetime', display: 'none'  },
            { label: "Scheduled Period", width: 180, fieldId: "range", display: 'grid', type: 'dateRange', typeProps: { sDate: 'created', eDate: 'resolved' } },
            { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { location: '/Incidents/' }, width: 70 }
        ];

        var logFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'grid' },
            { label: 'Response Level', fieldId: 'level', type: 'responseLevel', width: 100 },
            { label: 'Engineer', fieldId: 'name', width: 100 },
            { label: 'Updated', fieldId: 'startDate', type: 'datetime', width: 120 },
            { label: 'Notes', fieldId: 'notes' },
            { label: 'wcardGuid', fieldId: 'wcardGuid', display: 'form' },
            { label: '', fieldId: 'navButton', type: 'navButton', display: 'grid', typeProps: { location: '/WorkCards/' }, width: 70 }
        ];

        var taskFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Complete', fieldId: 'complete', type: 'checkbox', width: 70, display: 'grid' },
            { label: 'Task', fieldId: 'task', width: 500 },
            { label: 'Created', fieldId: 'created', type: 'datetime' },
            { label: 'Completed', fieldId: 'completeDate', type: 'datetime'},
            { label: '', fieldId: 'navButton', width: 70, display: 'grid', type: 'navButton' },
        ];

        var incidentConfig = {
            model: 'caseIncidents',
            path: '/Incidents/GetIncidents/' + this.props.match.params.id,
            fields: incidentFields,
            titleField: 'fault',
            gridButton: { pathname: '/Incidents/New' },
            gridProps: { defaultPageSize: 5 },
            apiPath: API_PATH
        }

        var scheduledConfig = {
            model: 'scheduled',
            path: '/Incidents/GetScheduled/' + this.props.match.params.id,
            fields: scheduledFields,
            gridButton: { pathname: '/Incidents/New' },
            gridProps: { defaultPageSize: 5 },
            apiPath: API_PATH
        }

        var sO = this.state.selectedOption;
        var caseSort = sO <= 2 ? [{ id: 'lastOccurance', desc: true }] : sO == 3 ? [{ id: 'faultCount', desc: true }] : [{ id: 'totalDownTime', desc: true }] 

        var closedCasesConfig = {
            model: 'closedCases',
            path: '/Cases',
            fields: casesFields,
            crudParams: { fromDate: moment(this.state.fromDate).format('DD-MM-YYYY'), toDate: moment(this.state.toDate).format('DD-MM-YYYY'), faultCaseState: 2 },
            master: true,
            showFilterRow: false,
            hideDelete: true,
            titleField: 'caseRef',
            preData: this.props.location.state,
            gridButton: false,
            apiPath: API_PATH,
            gridProps: { defaultPageSize: 20, getTdProps: (state, rowInfo, column, instance) => { return { onDoubleClick: (e) => this.goNav(rowInfo, '/Cases/' + rowInfo.row.id) } } }
        }

        var lowPriorityCasesConfig = {
            model: 'lowPriorityCases',
            path: '/Cases',
            fields: casesFields,
            crudParams: { fromDate: moment(this.state.fromDate).subtract(10, 'Y').format('DD-MM-YYYY'), toDate: moment(this.state.toDate).format('DD-MM-YYYY'), faultCaseState: 4  },
            master: true,
            showFilterRow: false,
            hideDelete: true,
            titleField: 'caseRef',
            preData: this.props.location.state,
            gridButton: false,
            apiPath: API_PATH,
            gridProps: { defaultPageSize: 20, getTdProps: (state, rowInfo, column, instance) => { return { onDoubleClick: (e) => this.goNav(rowInfo, '/Cases/' + rowInfo.row.id) } } }
        }

        var monitoringCasesConfig = {
            model: 'monCases',
            path: '/Cases',
            fields: casesFields,
            crudParams: { fromDate: moment(this.state.fromDate).format('DD-MM-YYYY'), toDate: moment(this.state.toDate).format('DD-MM-YYYY'), faultCaseState: 3 },
            master: true,
            showFilterRow: false,
            hideDelete: true,
            titleField: 'caseRef',
            preData: this.props.location.state,
            gridButton: false,
            apiPath: API_PATH,
            gridProps: { defaultPageSize: 20, getTdProps: (state, rowInfo, column, instance) => { return { onDoubleClick: (e) => this.goNav(rowInfo, '/Cases/' + rowInfo.row.id) } } }
        }

        var caseLogConfig = {
            model: 'caseLog',
            path: '/Cases/GetCaseLogs/' + this.props.match.params.id,
            fields: logFields,
            //gridButton: { pathname: '/Workcards/New' },
            gridButton: false,
            gridProps: { defaultPageSize: 5 },
            apiPath: API_PATH
        }

        var tasksConfig = {
            model: 'tasks',
            //path: '/tasks/GetCaseTasks/' + this.props.match.params.id,
            fields: taskFields,
            crudParams: { caseId: this.props.match.params.id },
            gridButton: { pathname: '/Tasks/New', state: { caseId: this.props.match.params.id } },
            gridProps: { defaultPageSize: 5 },
            apiPath: API_PATH
        }

        var casesConfig = {
            model: 'cases',
            fields: casesFields,
            crudParams: { fromDate: moment(this.state.fromDate).format('DD-MM-YYYY'), toDate: moment(this.state.toDate).format('DD-MM-YYYY'), option: this.state.selectedOption, faultCaseState: 1 },
            master: true,
            showFilterRow: false,
            hideDelete: true,
            cascade: true,
            cascadeComponents: this.cascadeComponents(),
            multiSelect: [{ name: 'Merge', endPoint: { module: 'Cases', func: 'Merge' } }],
            titleField: 'caseRef',
            preData: this.props.location.state,
            submitAction: () => { this.getCaseCount(), this.props.history.go(-1) },
            defaultSorted: caseSort,
            //() => this.caseMD.customSubmit('testParam')
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        var config = caseStatus == 'open' ? casesConfig : caseStatus == 'closed' ? closedCasesConfig : caseStatus == 'monitoring' ? monitoringCasesConfig : lowPriorityCasesConfig

        return (<div>
            <MasterDetail {...config} wrappedComponentRef={this.mdRefCallback} headerControl={!this.props.match.params.id ? caseStatus == 'open' ? <IdNameDropdown options={this.state.caseOptions} zeroText='Select Options' valueField='value' displayField='field' onChange={this.setCaseOption} value={this.state.selectedOption} /> : caseStatus == 'closed' ? <DropDatePicker right handleDate={this.handleDate} startDate={this.state.fromDate} endDate={this.state.toDate} /> : null : null}>
                {this.props.match.params.id != 'New' ?
                    [<Nav tabs key='caseTabs' style={{ marginTop: '20px' }}>
                        <NavItem >
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1', 'sub') }}>Alerts</NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3', 'sub') }}>Scheduled</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2', 'sub') }}>Log</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4', 'sub') }}>Tasks</NavLink>
                        </NavItem>
                    </Nav>,
                    <TabContent key='casesTabs' activeTab={this.state.activeTab}>
                        <TabPane tabId='1'>
                            <MasterDetail {...incidentConfig} />
                        </TabPane>
                        <TabPane tabId='2'>
                            <MasterDetail {...caseLogConfig} />
                        </TabPane>
                        <TabPane tabId='3'>
                            <MasterDetail {...scheduledConfig} />
                        </TabPane>
                        <TabPane tabId='4'>
                            <MasterDetail {...tasksConfig} />
                        </TabPane>
                    </TabContent>] : null}
            </MasterDetail></div>
            )
    }

    setCaseOption(id) {
        this.caseMD.pushToHistory('selectedOption', id)
        this.setState({ selectedOption: id})
    }

    render() {
        const { mainActiveTab } = this.state;
        const preloadedWidgets =
        [
                { i: '535507', x: 0, y: 0, w: 2, h: 1, type: '31', period: '7', metric: null, params: null},
                { i: '535508', x: 2, y: 0, w: 2, h: 1, type: '32', period: '7', metric: null, params: null },
                { i: '328884', x: 4, y: 0, w: 8, h: 1, type: '33', period: '9', metric: null, params: null }
        ]

        const preloadedDashInfo = { dashId: 999, tabId: 0, tabName: 'CasesDash', dashType: 0, fullDash: false, cols: 12, rowHeight: 180 }

        return (
            <div><div className='titleBlock'>Cases</div>
            {!this.props.match.params.id ?<div style={{width:'100%', height:'200px', overflow:'hidden'}} id='casesDash'>
                    <Dash preloadedWidgets={preloadedWidgets} preloadedDashInfo={preloadedDashInfo} hideEdit={true} hideTitle={true} />
            </div> : null}
            {!this.props.match.params.id ? <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Open{this.getPill('oC')}</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: mainActiveTab === '4' })} onClick={() => { this.toggle('4', 'main') }}>Monitoring{this.getPill('mC')}</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: mainActiveTab === '3' })} onClick={() => { this.toggle('3', 'main') }}>Low Priority{this.getPill('pC')}</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Closed</NavLink>
                </NavItem>
            </Nav> : null}
            <TabContent key='mainTabs' activeTab={mainActiveTab} >
                <TabPane tabId='1'>
                    {this.state.mainActiveTab == '1' ? this.getTab('open') : null}
                </TabPane>
                <TabPane tabId='4'>
                    {this.state.mainActiveTab == '4' ? this.getTab('monitoring') : null}
                </TabPane>
                <TabPane tabId='2'>
                    {this.state.mainActiveTab == '2' ? this.getTab('closed') : null}
                </TabPane>
                <TabPane tabId='3'>
                    {this.state.mainActiveTab == '3' ? this.getTab('lowPriority') : null}
                </TabPane>
                </TabContent>
                {this.state.statusModal ? this.renderModal() : null}
            </div>);       
    }  
}

export default (Cases)
