import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import { Row, Col, Input } from 'reactstrap'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment'
import { ApiPost, ApiGet } from '../../../api'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
export const API_PATH = process.env.SERVER_PATH + '/api'

class PickList extends Component {

    constructor(props) {
        super(props)
        var date = moment()
        var fromDate = moment().subtract(30, 'days')
        this.state = {
            toDate: date, fromDate: fromDate, dateSelectTitle: 'Select Custom Time', pId: 1, headStatus: 0, isMobile: false, whs: null, selectedwh: null
        }
    }

    componentDidMount() {
        this.handleDate(this.state.fromDate, this.state.toDate, false)
        if (window.screen.availWidth <= 576) {
            this.setState({isMobile: true})
        }
        document.title = 'Picklist | izon'
        var listsToGet = [listRequest.Warehouses]
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
        .then((data) => {
            this.setState({ whs: data[listRequest.Warehouses], selectedwh: data[listRequest.Warehouses][0].id })
        })
    }

    handleDate(fromDate, toDate, updateData = true) {
        if (fromDate.format('DD/MM/YY').toString() == toDate.format('DD/MM/YY').toString()) {
            this.setState({
                dateSelectTitle: fromDate.format('DD/MM/YY') + ' ' + fromDate.format('HH:mm') + ' to ' + toDate.format('HH:mm')
            })
        }
        else {
            this.setState({
                dateSelectTitle: fromDate.format('DD/MM/YY HH:mm') + ' to ' + toDate.format('DD/MM/YY HH:mm')
            })
        }
        this.setState({ fromDate, toDate })
    }

    generatePicklist(rec) {
        ApiPost('PickList', 'Generate', { id: rec.id })
        .then((data) => {
            console.log('generatePicklist', data)
            this.setState({ pId: Math.random() })
        })
    }

    completePicklist(rec) {
        console.log('completePicklist', rec.id)
        ApiPost('PickList', 'Complete', { id: rec.id })
        .then((data) => {
            console.log('completePicklist', data)
            this.setState({ pId: Math.random() })
        })
    }

    addReturn() {
        console.log('add return for ' + this.props.match.params.id) 
        ApiPost('Returns', 'Create', { id: this.props.match.params.id })
        .then((data) => {
            console.log('returnCreated', data)
            this.props.history.push('/Returns/' + data.returnId, { history: null })
        })
    }


    parseMDData(rec) {
        if (rec.data && rec.data.pickState && this.state.headStatus != rec.data.pickState) {
            console.log('rec', rec.data.pickState)
            this.setState({ headStatus: rec.data.pickState })
        }
    }

    render() {

        const { isMobile, headStatus, fromDate, toDate } = this.state
        let local = { format: "DD-MM-YYYY HH:mm", sundayFirst: false }
        let cStyle = { customRangeButtons: { color: '#0a92b1' }, customRangeSelected: { backgroundColor: '#0a92b1' }, fromDate: { backgroundColor: '#0a92b1' }, toDate: { backgroundColor: '#0a92b1' } }

        var lists = {
            archivePlan: [{ id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4' }, { id: 5, name: '5' }],
            pickState: [{ id: 0, name: 'Ready To Pick' }, { id: 1, name: 'In Progress' }, {id: 2, name: 'Complete' }]
        }

        var PendingPickListFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Job Ref", fieldId: "jobRef", width: 170, canFilter: true, readOnly:true },
            { label: 'Site', fieldId: 'thing', canFilter: true, readOnly: true  },
            { label: "State", fieldId: 'pickState', list: lists.pickState, width: 150, readOnly: true },
            { label: "WH", fieldId: 'warehouse', list: listRequest.Warehouses, width: 100, readOnly: true },
            //{ label: "", fieldId: "funcButton", display: 'form', displayIf: (r) => r.pickState == 0, type: 'funcButton', typeProps: { label: <span>Generate Picklist</span>, style: { width: '100%', backgroundColor: '#0a92b1', color: '#FFF' }, func: (rec) => this.generatePicklist(rec) } },
            { label: "", fieldId: "funcButton2", display: 'form', displayIf: (r) => r.pickState < 2, type: 'funcButton', typeProps: { label: <span>Complete Picklist</span>, style: { width: '100%', backgroundColor: '#0a92b1', color: '#FFF' }, func: (rec) => this.completePicklist(rec) } },
            { label: 'View', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' },
        ]

        var PickListLinesFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Status", fieldId: "status",  display: 'none' },
            { label: "Product", fieldId: "name", canFilter: true, width: isMobile ? 200 : 500 },
            { label: "Location", fieldId: 'location', width: isMobile ? 50 : 170},
            { label: "Qty In Stock", fieldId: 'instockQty', display: isMobile ? 'none' : 'grid'},
            { label: "Qty To Pick", fieldId: 'pickQty', copyTo: headStatus != 2 && 'qtyPicked', typeProps: { style: { fontSize: '20px' } } },
            { label: "Picked", fieldId: 'qtyPicked', inlineEdit: true, width: isMobile ? 50 : 200 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: headStatus == 2 ? 'none' : 'grid', type: 'inlineSaveBtn' }
        ]

        var PickListReturnFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "PickId", fieldId: "pickId", display: 'none', getId: -1 },
            { label: "Return Date", fieldId: "returnDate", type: 'datetime' },
            { label: "Note", fieldId: "note"},
            { label: "", fieldId: "navButton", display: 'grid', type: 'navButton', typeProps: { location: '/Returns/' }, width: 70 }
        ]

        var PendingPickListConfig = {
            model: 'PickList',
            fields: PendingPickListFields,
            crudParams: { fromDate: fromDate, toDate: toDate, status: 1, wh: this.state.selectedwh },
            master: true,
            hideDelete: true,
            showFilterRow: true,
            gridButton: false,
            mdData: (e) => this.parseMDData(e),
            gridProps: { defaultPageSize: 10 },
            defaultSorted: [{ id: 'id', desc: false }],
            formActions: [],
            apiPath: API_PATH
        }

        var PickedPickListConfig = {
            model: 'PickList',
            fields: PendingPickListFields,
            crudParams: { fromDate: fromDate, toDate: toDate, status: 2, wh: this.state.selectedwh },
            master: true,
            hideDelete: true,
            showFilterRow: true,
            gridButton: false,
            gridProps: { defaultPageSize: 10 },
            defaultSorted: [{ id: 'id', desc: false }],
            apiPath: API_PATH
        }

        var PickListDetailConfig = {
            model: 'PickListLines',
            fields: PickListLinesFields,
            //crudParams: { fromDate: this.state.fromDate, toDate: this.state.toDate, status: 2 },
            hideDelete: true,
            showFilterRow: false,
            inlineEdit: true,
            gridProps: { defaultPageSize: 10 },
            gridButton: false,
            defaultSorted: [{ id: 'location', desc: false }],
            apiPath: API_PATH
        }

        var PickListReturnsConfig = {
            model: 'Returns',
            fields: PickListReturnFields,
            crudParams: { pickListId: this.props.match.params.id },
            hideDelete: true,
            showFilterRow: false,
            gridButton: false,
            gridProps: { minRows: 1, noDataText: <div><br /><b>No Returns</b></div>, showPagination: false },
            apiPath: API_PATH
        }

        console.log('headStatus', this.state.headStatus)

        return (
            <div>
                {this.props.match.params.id == undefined ?
                    <div>
                        <Row>
                            <Col xs={12} sm={4}>
                                <DateTimeRangeContainer start={fromDate} end={toDate} ranges={{}} local={local} applyCallback={(sd, ed) => this.handleDate(sd, ed)} style={cStyle} >
                                    <Input id="formControlsTextB" type="text" label="Text" placeholder={this.state.dateSelectTitle} />
                                </DateTimeRangeContainer>
                            </Col>
                            <Col xs={12} sm={4}>
                                <IdNameDropdown style={{ width: '115px' }} options={this.state.whs} valueField='id' displayField='name' onChange={(e) => this.setState({selectedwh:e})} value={this.state.selectedwh} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col xs={12} sm={6}>
                                {this.props.match.params.id == undefined && <h2 style={{ marginBottom: '-30px', position: 'relative' }}>To Pick</h2>}
                                <MasterDetail {...PendingPickListConfig} />
                            </Col>
                            <Col xs={12} sm={6}>
                                {this.props.match.params.id == undefined && <h2 style={{ marginBottom: '-30px', position: 'relative' }}>Picked</h2>}
                                <MasterDetail {...PickedPickListConfig} />
                            </Col>
                        </Row>
                    </div>
                    :
                    <MasterDetail key={'p_' + this.state.pId} {...PendingPickListConfig} >
                        {this.state.headStatus != null && <MasterDetail key={this.state.pId} {...PickListDetailConfig} />}
                        {this.state.headStatus == 2 &&
                            <div>
                                <h2 style={{ marginTop: '30px', marginBottom: '-30px', position: 'relative', width: '50%' }}>Returns</h2>
                                <MasterDetail key={this.state.pId} {...PickListReturnsConfig} headerControl={<button key='viewBtn' className="btn btn-default" onClick={() => this.addReturn() }><i className='fa fa-plus' /></button>} />
                            </div>
                        }
                    </MasterDetail>
                }               
            </div >)
    }
}

export default (PickList)