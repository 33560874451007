import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
export const API_PATH = process.env.SERVER_PATH + '/api'

class ContactSetup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var lists = {
            ContactType: [{ id: 1, name: 'Site Contact' }, { id: 2, name: 'Billing Contact' }],
        }

        var contactFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Name', fieldId: 'name', validation: 'required'},
            { label: 'Phone', fieldId: 'tel' },
            { label: 'Email', fieldId: 'email' },
            { label: 'Address', fieldId: 'address', display: 'form' , type: 'textarea'},
            { label: 'Contact Type', fieldId: 'typeId', display: 'form', list: lists.ContactType, validation: 'requiredList' },
            { label: 'Notes', fieldId: 'notes', display: 'form', type: 'textarea'},
        ];

        var contactConfig = {
            model: 'contacts',
            path: '/CustomerSetup/Contact/',
            fields: contactFields,
            titleField: 'name',
            master: true,
            preData: { stationId: this.props.location.state.stationId },
            formActions: [
                { action: 'delete' },
                { action: 'back' },
                { action: 'save' }   
            ],
            apiPath: API_PATH
        }

        return (
            <div>
                <div>
                    <div className='titleBlock'>Contact Setup</div>
                </div>

                <div>
                    <MasterDetail {...contactConfig} />
                </div>
            </div>
        )
    }
}

export default (ContactSetup)