import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import _ from 'lodash'
import { ApiPost, ApiGet, getConfigItem } from '../../../api'
import { Nav, NavItem, NavLink, TabContent, TabPane, Button, Col, Row, Input  } from 'reactstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'
import SimpleModal from '../../../components/SimpleModal'
export const API_PATH = process.env.SERVER_PATH + '/api'

class Quote extends Component {

    constructor(props) {
        super(props);
        var xeroEnabled = getConfigItem('$.finance.xeroEnabled') || false
        this.state = {
            poStatus: null, poId: 1, slId: 1, nslId: 1, activeTab: '1', mainActiveTab: '1', awaitingApproval: [], wfStatus: null, customFilter: null,
            wfStatuses: { 1: 'Request Approval', 2: 'Approve Purchase Order', 3: 'Approve Variance', 4: 'Request Variance Approval', 5: 'Goods Received' },
            footer: null, header: null, body: null, open: false, xeroEnabled
        };
    }

    componentDidMount() {
        if (!isNaN(this.props.match.params.id)) {
            this.getStatus()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.setState({ poStatus: null, activeTab: '1', mainActiveTab: '1' })
            if (!isNaN(this.props.match.params.id)) {
                this.getStatus()
            }
        }
    }

    filterChange(e) {
        var newFilter = []
        if (this.state.customFilter) {
            newFilter = this.state.customFilter
        }
        if (e.key == 'filter') {
            newFilter[e.key] = e.filtered
            this.setState({ customFilter: newFilter.filter })
        }
    }

    getStatus() {
        ApiGet('Stock', 'GetPOStatus', { id: this.props.match.params.id })
        .then((data) => {
            console.log('thengetstatus')
            this.setState({ poStatus: data.status, poId: Math.random(), wfStatus: data.wfStatus })
        })
    }

    receiveAll() {
        ApiPost('Stock', 'POReceiveAll', { id: this.props.match.params.id })
        .then((data) => {
            this.reloadForms()
        })
    }

    submitCallback(e) {
        console.log('submit callback')
        setTimeout(() => this.setState({poId: Math.random()}),1000)
    }

    reloadForms() {
        console.log('reload forms')
        this.setState({ poId: Math.random(), nslId: Math.random, slId: Math.random }, () => this.getStatus())
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    workflowAction(rec) {
        ApiPost('Stock', 'poWorkflow', { id: rec.id })
        .then((data) => {
            this.reloadForms()
        })
    }

    workflowLabel(rec) {
        return this.state.wfStatuses[this.state.wfStatus]
    }

    getSupplierEmail(rec) {
        ApiPost('Stock', 'GetSupplierEmail', { supplierId: rec.partSupplierId})
        .then((data) => {
            var email = data.email
            this.toggleEmailModal(rec,email)
        })
    }

    toggleEmailModal(rec, preEmail) {
        var email = preEmail
        var cc = false
        var header = <b>Confirm Email</b>
        var body = <div>
                        <Row>
                            <Col sm="4"><b>Supplier Email</b></Col>
                            <Col sm="8">
                                <Input onChange={(e) => email = e.target.value} defaultValue={email} style={{ width: "100%" }} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col sm="4"><b>CC yourself?</b></Col>
                            <Col sm="8">
                                <Input type="checkbox" style={{marginLeft:'2px'}} onClick={() => cc = !cc} />
                            </Col>
                        </Row>
                    </div>
        var footer = <div style={{ width: '100%' }}>
            <Button style={{ float: 'left' }} onClick={() => this.setState({open:!this.state.open})}>Cancel</Button>
            <Button color='primary' style={{ float: 'right' }} onClick={() => this.sendToSupplier(rec, cc, email)}>Send Email</Button>
        </div>
        this.setState({header,body,footer,open:!this.state.open})
    }

    sendToSupplier(rec, cc, email) {
        if (email != '') {
            ApiPost('Stock', 'EmailSupplier', { id: rec.id, cc, email })
            .then((data) => {
                console.log('email sent') 
            })
        }
        this.setState({ open: !this.state.open })
    }

    sendToXero(rec) {
        console.log('send to xero')

        //RETURN INVOICE ID AND UPDATE DB QUOTE HEADER
        if (this.state.xeroEnabled) {
            ApiPost('Invoice', 'CreateInvoiceFromQuote', { quoteId: this.props.match.params.id })
            .then((data) => {
                console.log('invoice created', data)
                alert("Invoice " + data.Invoices[0].InvoiceID + " created in xero")
            })
        }
    }

    getMD(tabStatus) {
        var userId = this.props.commonstate.users.authInternalId, rId = this.props.match.params.id, status = this.state.poStatus
        var isAdmin = this.props.commonstate.users.authRole.includes("Administrator") || this.props.commonstate.users.authRole.includes("StockAdmin")
        var isNew = (rId == 'New')
        var lists = {
            stockCat: [{ id: 1, name: 'CON' }, { id: 2, name: 'CP' }, { id: 3, name: 'SER' }, { id: 4, name: 'PRT' }],
            wh: [{ id: 1, name: 'Default' }],
            status: [{ id: 0, name: 'Open' }, { id: 2, name: 'Approved' }, { id: 5, name: 'Complete' }],
            departments: [{ id: 1, name: 'O&M' }, { id: 2, name: 'AM' }, { id: 3, name: 'Software/IT' }, { id: 4, name: 'Office' }],
            nc: [{ id: 1, name: 'Non chargeable' }, { id: 2, name: 'Chargeable' }],
        };

        var poFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Id', fieldId: 'jobId', width: 70, display: 'none' },
            { label: 'Site Ref', fieldId: 'billableSiteId', list: listRequest.windsites, width: 120 },
            { label: 'Description', fieldId: 'note'},
            { label: 'Raised By', fieldId: 'completedBy', displayIf: () => !isNew, list: listRequest.operators, width: 130, readOnly: true },
            { label: 'Raised Date', fieldId: 'orderDate', type: 'date', readOnly: status > 1 && !isAdmin && true, width: 100 },
            { label: 'Completed Date', fieldId: 'deliverDate', display: 'form', type: 'datetime', displayIf: () => rId != 'New' },
            { label: 'Status', fieldId: 'status', list: lists.status, displayIf: () => !isNew, readOnly: true, width: 150 },
            { label: 'Total Value', fieldId: 'expectedValue', type: 'info', col: 2, displayIf: () => rId != 'New', width: 170 },
            { label: 'Xero Invoice Id', fieldId: 'extId', type: 'info', col: 2, displayIf: (r) => r.extId != null, width: 170 },
            //{ label: '', fieldId: 'workflowBtn', display: 'form', type: 'funcButton', displayIf: (rec) => !isNew && this.workflowLabel(rec), typeProps: { label: (rec) => this.workflowLabel(rec), style: { width: '100%', backgroundColor: '#0a92b1', color: '#FFF' }, func: (rec) => this.workflowAction(rec) } },
            { label: '', fieldId: "navButton", width: 60, display: 'grid', type: 'navButton' },
            { label: '', fieldId: 'sendToSupplier', col: 2, display: 'form', displayIf: () => !isNew, type: 'funcButton', typeProps: { label: <span><i className="fal fa-envelope" /> Email quote to customer</span>, style: { width: '100%', backgroundColor: '#0a92b1', color: '#FFF' }, func: (rec) => this.getSupplierEmail(rec) }  },
            { label: '', fieldId: 'sendToXero', col: 2, display: 'form', displayIf: () => !isNew && this.state.xeroEnabled, type: 'funcButton', typeProps: { label: <span><i className="fal fa-file-invoice" /> Send quote to xero</span>, style: { width: '100%', backgroundColor: '#0a92b1', color: '#FFF' }, func: (rec) => this.sendToXero(rec) } },
        ];

        var preData = {}
        if (this.props.location.state) {
            if (this.props.location.state.siteId) {
                preData.billableSiteId = this.props.location.state.siteId
            }
            if (this.props.location.state.jobId) {
                preData.jobId = this.props.location.state.jobId
            }
        }
        if (isNew) {
            preData.orderDate = moment().format('YYYY-MM-DD') 
        }
        console.log('this.props.location', this.props.location)
        var quoteConfig = {
            model: 'quote',
            master: true,
            crudParams: { status: tabStatus },
            preData,
            fields: poFields,
            showFilterRow: false,
            titleField: 'invoiceNo',
            loadNewRec: true,
            customFilter: this.state.customFilter,
            showFilterRow: true,
            gridProps: { defaultPageSize: 25 },
            apiPath: API_PATH,
            formActions: status != 1 && rId != 'New' && [{ action: 'delete' }, { action: 'save', label: 'Save', icon: 'fal fa-save', after: () => this.reloadForms() }]
        }

        var isHistoric = this.props.match.params.id != 'New' && this.props.match.params.id < 3553 ? true : false;

        var poLines = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: "Part", fieldId: "partId", list: isHistoric ? listRequest.PartsIncArchive : listRequest.Parts, categories: listRequest.PartTypes, inlineEdit: status > 1 && !isAdmin ? false : true, type: 'filterList', footer: <b>Totals</b>, typeProps: { showCats: true } },
            //{ label: "NC", fieldId: "nominalCode", width: 140, list: lists.nc, inlineEdit: true, display: status >= 3 && 'none' },
            //{ label: "WH", fieldId: "moveTo", width: 90, list: listRequest.Warehouses, inlineEdit: true, validation: 'requiredList', display: status >= 3 && 'none'},
            { label: "Qty", fieldId: "expectedQty", width: 100, inlineEdit: status > 1 && !isAdmin ? false : true, copyTo: status == 6 && 'qty', footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.expectedQty)), 2)}</b>) },
            //{ label: "Actual Qty", fieldId: "qty", width: 100, inlineEdit: true, display: status < 3 && 'none', footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.qty)), 2)}</b>) },
            { label: "Price (\u00A3)", fieldId: "expectedPrice", copyTo: status == 6 && 'costPrice', width: 120, inlineEdit: status > 1 && !isAdmin ? false : true, footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.expectedPrice)), 2)}</b>) },
            //{ label: "Actual Price (\u00A3)", fieldId: "costPrice", width: 100, display: status < 3 && 'none', inlineEdit: true, footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.costPrice)), 2)}</b>) },
            //{ label: "KF Ref (KF)", fieldId: "invoiceRef", inlineEdit: true, display: status < 3 && 'none' },
            { label: "POId", fieldId: "partMovementHeaderId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var poLInesConfig = {
            model: 'quoteOrderLine',
            fields: poLines,
            gridButton: false,
            addInline: status > 1 && !isAdmin ? false : true,
            gridProps: { defaultPageSize: 10 },
            submitCallback: (e) => this.submitCallback(e),
            apiPath: API_PATH
        }

        var nsLines = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: "Category", fieldId: "categoryId", list: listRequest.NonStockSubCats, categories: listRequest.NonStockCats, inlineEdit: status > 1 && !isAdmin ? false : true, type: 'filterList', typeProps: { showCats: true } },
            { label: "Description", fieldId: "description", inlineEdit: status > 1 && !isAdmin ? false : true },
            { label: "Qty", fieldId: "expectedQty", width: 120, inlineEdit: status > 1 && !isAdmin ? false : true, copyTo: status == 6 && 'qty', footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.expectedQty)), 2)}</b>) },
            //{ label: "Actual Qty", fieldId: "qty", width: 120, inlineEdit: true, display: status < 3 && 'none', footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.qty)), 2)}</b>) },
            { label: "Price (\u00A3)", fieldId: "expectedPrice", copyTo: status == 6 && 'costPrice', width: 120, inlineEdit: status > 1 && !isAdmin ? false : true, footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.expectedPrice)), 2)}</b>) },
            //{ label: "Actual Price (\u00A3)", fieldId: "costPrice", width: 120, display: status < 3 && 'none', inlineEdit: true, footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.costPrice)), 2)}</b>) },
            //{ label: "KF Ref (KF)", fieldId: "invoiceRef", inlineEdit: true, display: status < 3 && 'none' },
            { label: "POId", fieldId: "partMovementHeaderId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var nsLinesConfig = {
            model: 'nonStockLine',
            path: '/quoteOrderLine/NonStock',
            fields: nsLines,
            gridButton: false,
            addInline: status > 1 && !isAdmin ? false : true,
            gridProps: { defaultPageSize: 10 },
            submitCallback: (e) => this.submitCallback(e),
            apiPath: API_PATH
        }

        var commentLines = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: "Time", fieldId: 'timeStamp', type: 'datetime', width: 120 },
            { label: "User", fieldId: "userId", list: listRequest.operators, width: 100 },
            { label: "Comment", fieldId: "comment", inlineEdit: true },
            { label: "Notify", fieldId: "notifyUserId", inlineEdit: true, list: listRequest.operators, width: 120 },
            { label: "POId", fieldId: "partMovementHeaderId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var commentConfig = {
            model: 'PMComment',
            fields: commentLines,
            gridButton: false,
            addInline: true,
            gridProps: { defaultPageSize: 10 },
            apiPath: API_PATH
        }

        //key={'mdPO' + this.state.poId + status}
        console.log('poStatus', this.state)

        return (
            <MasterDetail key={'mdPO' + this.state.poId + status} {...quoteConfig} filterChange={(e) => this.filterChange(e)}>

                {!isNew && this.state.poStatus != null &&
                    <div style={{ marginRight: '15px' }}>
                        <div style={{ margin: '10px 0 10px 0', display: 'flex', justifyContent: 'flex-end' }}>{this.state.poStatus == 6 && <Button className="btn btn-primary" onClick={() => this.receiveAll()}>Receive All</Button>}</div>
                        <Nav tabs className="nav-docked">
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1') }}>Stock Lines</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2') }}>Non-stock Lines</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3') }}>Comments</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent key='clientTabs' activeTab={this.state.activeTab}>
                            <TabPane tabId='1'>
                                {this.state.activeTab == '1' && <MasterDetail key={'mdPOL' + this.state.slId} {...poLInesConfig} />}
                            </TabPane>
                            <TabPane tabId='2'>
                                {this.state.activeTab == '2' && <MasterDetail key={'mdNSL' + this.state.nslId} {...nsLinesConfig} />}
                            </TabPane>
                            <TabPane tabId='3'>
                                {this.state.activeTab == '3' && <MasterDetail key={'mdComments'} {...commentConfig} />}
                            </TabPane>
                        </TabContent>
                    </div>
                }

            </MasterDetail>
        )
    }

    render() {
        const { footer, header, body, open } = this.state
        return (
            <div>
                <div className='titleBlock'>Quote</div>
                {this.getMD(9999)}
                <SimpleModal {...{ header, body, footer, open}} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common }
}

export default connect(mapStateToProps)(Quote)
