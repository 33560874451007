import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import Password from '../../../scenes/ClientSetup/components/Password';
export const API_PATH = process.env.SERVER_PATH + '/api'

class UserCard extends Component {    

    constructor(props) {
        super(props);     
        
    }   

    render() {
        console.log('this.props.match.params.id', this.props.match.params.id)
        var UserCardFields = [
            { label: "Id", fieldId: "id", width: 150, display: 'none', section: 'User Details' },
            { label: "Full Name", fieldId: "name", width: 150, section: 'User Details' },
            { label: "Email", fieldId: "email", width: 150, section: 'User Details'},           
            { label: "Phone Number", fieldId: "phoneNumber", width: 150, section: 'User Details' },
            { label: "Last Login", fieldId: "lastLogin", width: 150, type: 'datetime', readOnly: 'true', section: 'User Details' },
            { label: "Persistant Session", fieldId: "persistantSession", type: 'checkbox', section: 'User Details' },
            { label: "Client Id", fieldId: "clientId", width: 150, getId: 'ClientSettings', display: 'none', section: 'User Details'},
            { label: 'Roles', fieldId: 'roles', width: 60, display: 'form', type: 'checkList', listSource: '/UserCard/GetRoles', section: 'User Details' },
            { label: "All Sites", fieldId: "allSites", type: 'checkbox', section: 'User Details' },
            { label: 'Sites', fieldId: 'sites', width: 60, displayIf: (rec) => this.props.match.params.id != 'New' && !rec.allSites, type: 'checkList', listSource: '/UserCard/GetSites', section: 'User Details', typeProps: { defaultSorted: [{ id: 'name' }], listHeight: '300px' } },
        ];

        var UserCardConfig = {
            model: 'UserCard',            
            fields: UserCardFields,
            master: true,
            titleField: '',
            apiPath: API_PATH
        }         

        return (
            <div>
                <div className='titleBlock'>Form Builder</div>
                <div>
                    <MasterDetail key='mdwc' {...UserCardConfig} />
                </div>
                {this.props.match.params.id != 'New' && <Password userId={this.props.match.params.id} />}                    
            </div>        
        );
    }
}

export default (UserCard)