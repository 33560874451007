import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import ReactTable from 'react-table'
import { ApiPost, ApiGet } from '../../../api'
import { Button } from 'reactstrap'
import ConfirmButton from '../../../components/Controls/ConfirmButton'
export const API_PATH = process.env.SERVER_PATH + "/api";

class StockTake extends Component {

    constructor(props) {
        super(props);
        this.iRefs = []
        this.stockQtys = []
        this.state = { stock: null, stStatus: 0, pendingSave: false, saveSuccessful: false, saveFault: false, stId: 1, qtyFocus: null };
    }

    componentDidMount() {
        if (this.props.match.params.id != undefined) {
            this.getStockTake()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.setState({stock:null})
            if (!isNaN(this.props.match.params.id)) {
                this.setState({ stStatus: 0, stId: Math.random()})
                this.getStockTake()
            }
        }
    }

    qtyBox(x) {
        var r = x.original
        return <input type='number' autoFocus={this.state.qtyFocus == r.partId} defaultValue={r.qty} id={r.partId} key={r.partId + '-' + x.viewIndex + '-' + r.qty} style={{ width: '100%' }} ref={ref => this.iRefs[x.viewIndex] = ref} onKeyUp={(e) => this.onKeyPress(e, x.viewIndex, r.partId)} onBlur={(e) => this.submitLine(e.target.value, r.partId)} onWheelCapture={e => { e.target.blur() }} />
    }

    aisleBox(x) {
        return (<div>
            <input defaultValue={x.original.aisle} key={'a' + x.viewIndex + '-' + x.original.aisle} style={{ width: '40%', marginRight: '5%' }} onBlur={(e) => this.updatePartLocation(e.target.value, x.original.partId, 1)} />
            <input defaultValue={x.original.location} key={'l' + x.viewIndex + '-' + x.original.location} style={{ width: '40%' }} onBlur={(e) => this.updatePartLocation(e.target.value, x.original.partId, 2)} />
        </div>)
    }

    locationBox(x) {
        return <input defaultValue={x.original.location} id={'l' + x.original.partId} style={{ width: '100%' }} onBlur={(e) => this.updatePartLocation(e.target.value, x.original.partId, 2)} />
    }

    difBox(e) {
        var val = e.original.difQty
        return <span style={{fontSize:'1rem'}} className={'badge badge-pill badge-' + (val > 0 ? 'success' : 'danger')}>{val}</span>
    }

    updatePartLocation(val, id, type) {
        ApiPost('Stock', 'UpdatePartLocation', { id, val, type, stockTakeId: this.props.match.params.id })
        .then((data) => {
            var upStock = this.state.stock
            if (type == 1) {
                upStock.find(x => x.partId == id).aisle = val
            }
            else {
                upStock.find(x => x.partId == id).location = val
            }
            this.setState({ stock: upStock })
        })
    }


    onKeyPress(e, i, id) {
        if (e.keyCode === 13 || e.keyCode === 9) {
            this.submitLine(e.target.value, id)
            if (this.iRefs[i + 1]) {
                this.setState({ qtyFocus: this.iRefs[i + 1].id })
            }
        }
    }

    submitLine(value, id) {
        if (value != "") {
            if (this.stockQtys.find(x => x.id == id)) {
                this.stockQtys.find(x => x.id == id).qty = value
            }
            else {
                this.stockQtys.push({ id: id, qty: value })
            }
            var eStock = this.state.stock
            eStock.find(x => x.partId == id).qty = value
            this.setState({stock: eStock, qtyFocus: null })
        }
        //console.log('stockQtys',this.stockQtys)
    }

    submitLines() {
        console.log('line data', this.stockQtys)
        ApiPost('Stock', 'SubmitStockLines', { id: this.props.match.params.id, lines: JSON.stringify(this.stockQtys)})
        .then((data) => {
            console.log('data', data)
            var upStock = this.state.stock
            this.stockQtys.map(x => {
                upStock.find(y => y.partId == x.id).qty = x.qty
            })

            this.setState({ pendingSave: false, saveSuccessful: true, stock: upStock })
            //this.props.history.goBack()
        })
    }

    getStockTake() {
        ApiGet('Stock', 'GetWhStock', { id: this.props.match.params.id})
        .then((data) => {
            this.setState({stock: data.stockLines, stStatus: data.status})
        })
    }

    cusSort(a, b, desc) {
        console.log('abdesc', { a, b, desc })
        a = a === null || a === undefined ? '' : a
        b = b === null || b === undefined ? '' : b
        a = typeof a === 'string' ? a.toLowerCase() : a
        b = typeof b === 'string' ? b.toLowerCase() : b
        if (a > b) {
            return 1
        }
        if (a < b) {
            return -1
        }
        return 0
    }

    render() {
        const columns = [
            { Header: 'Location', accessor: r => [r.aisle, r.location], id: 'aisle', width: 120, Cell: (e) => this.state.stStatus != 1 ? this.aisleBox(e) : e.original.aisle + ' - ' + e.original.location },
            //{ Header: 'Location', accessor: 'location', width: 75, Cell: (e) => this.state.stStatus != 1 ? this.locationBox(e) : e.original.location },
            { Header: 'Part', accessor: 'partName' },
            { Header: 'Type', accessor: 'type' },
            { Header: 'Expected Qty', accessor: 'eQty', width: 100, show: this.state.stStatus == 1 },
            { Header: 'Qty', accessor: 'qty', width: 100, Cell: (e) => this.state.stStatus != 1 ? this.qtyBox(e) : e.original.qty },
            { Header: 'Difference', accessor: 'difQty', width: 100, show: this.state.stStatus == 1, Cell: (e) => this.difBox(e) },
        ]

        var lists = {
            stockCat: [{ id: 1, name: 'CON' }, { id: 2, name: 'CP' }, { id: 3, name: 'SER' }, { id: 4, name: 'PRT' }],
            status: [{ id: 0, name: 'Open' }, { id: 1, name: 'Complete' }, { id: 3, name: 'Cancelled' }],
        };

        var poFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Reference', fieldId: 'invoiceNo', validation: 'required'},
            { label: 'Entered By', fieldId: 'completedBy', list: listRequest.operators, displayIf: () => this.props.match.params.id != 'New' },
            { label: 'TimeStamp', fieldId: 'orderDate', type: 'datetime', validation: 'required' },
            { label: 'WH', fieldId: 'warehouseId', list: listRequest.Warehouses },
            { label: 'Status', fieldId: 'status', list: lists.status },
            { label: 'Total Value', fieldId: 'totalValue', type: 'info', display: 'form', col: 2, displayIf: () => this.state.stStatus == 1},
            { label: 'Expected Value', fieldId: 'expectedValue', type: 'info', display: 'form', col: 2, displayIf: () => this.state.stStatus == 1 },
            { label: '', fieldId: "navButton", width: 60, display: 'grid', type: 'navButton'},
        ];

        var poConfig = {
            model: 'stockTake',
            master: true,
            fields: poFields,
            titleField: 'invoiceNo',
            loadNewRec: true,
            apiPath: API_PATH,
            formActions: this.state.stStatus == 0 && this.props.match.params.id != 'New' && [{ action: 'delete' }, { action: 'save', label: 'Save', icon: 'fal fa-save', after: () => this.setState({ stId: Math.random() }, () => this.getStockTake()) }]
        }

        //<Button className="btn btn-primary" style={{ float: 'right', marginTop: '10px' }} onClick={() => this.submitLines()}>Submit Lines</Button>

        return (
            <div>
                <div className='titleBlock'>Stock Take</div>
                <MasterDetail key={'mdST' + this.state.stId} {...poConfig}>
                    {/*this.props.match.params.id != 'New' && <MasterDetail key='mdPOL' {...poLInesConfig} />*/}
                    {this.state.stock &&
                        <div>
                            {this.state.stStatus == 0 ? <ConfirmButton text='Submit Lines' style={{ float: 'right', marginTop: '10px', width: '100%' }} clickAction={() => this.setState({ pendingSave: true }, () => this.submitLines())} pending={this.state.pendingSave} successful={this.state.saveSuccessful} fault={this.state.saveFault} /> : null}
                            <ReactTable filterable className='-striped -highlight' defaultPageSize={100} data={this.state.stock} columns={columns} defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase())} />
                        </div>}
                </MasterDetail>
            </div>
        )
    }
}

export default (StockTake)
