import React, { Component } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { ApiPost } from '../../../api'
import { withRouter } from 'react-router-dom'
import SimpleModal from './../../../components/SimpleModal'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'

class TemplateBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            config: null, header: null, body: null, footer: null, open: false,
            paneNames: ['', 'Header', 'Column 1', 'Column 2', 'Column 3', 'Footer'], types: [{ name: 'Form', id: 1 }, { name: 'Form Group List', id: 2 }],
        }
    }

    componentDidMount() {
        console.log('props', this.props)
        if (this.props.config && this.props.config.includes('{')) {
            this.setState({ config: JSON.parse(this.props.config) })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.type != this.props.type) {
            if (this.props.config && this.props.config.includes('{')) {
                var tCon = JSON.parse(this.props.config)
                tCon.templateType = this.props.type
                const t = { entityId: this.props.match.params.id, config: JSON.stringify(tCon) }
                ApiPost('CustomForm', 'UpdatePageConfig', t)
                .then((n) => {
                    console.log('n', n)
                })
            }
        }
    }

    getPaneModal(id, type, sVal) {
        var header = <span>{this.state.paneNames[id]}</span>

        var body =
        (<div>
            <Label>Pane Content</Label><IdNameDropdown zeroText='Select Content' options={this.state.types} valueField="id" displayField="name" value={type} onChange={(e) => { type = e; this.getPaneModal(id, type, sVal); }} />
            {type == 1 && <div><Label>Forms</Label><IdNameDropdown zeroText='Select Form' options={this.props.forms} valueField="formEntityId" displayField="name" value={sVal} onChange={(e) => { sVal = e; this.getPaneModal(id, type, sVal); }} /></div>}
            {type == 2 && <div><Label>Form Groups</Label><IdNameDropdown zeroText='Select Group' options={this.props.groups} valueField="formEntityGroupId" displayField="groupName" value={sVal} onChange={(e) => { sVal = e; this.getPaneModal(id, type, sVal); }} /></div>}
            {type == 3 && <div><Label>Widgets</Label><IdNameDropdown zeroText='Select Widget' options={this.state.types} valueField="id" displayField="name" value={sVal} onChange={(e) => { sVal = e; this.getPaneModal(id, type, sVal); }} /></div>}
        </div>)

        var footer = (<div style={{ width: '100%' }}>
            <button type='button' className='btn'  onClick={() => { this.setState({ open: !this.state.open, sVal: null }); }}>Cancel</button>
            <button type='button' className='btn'  color='primary' style={{ float: 'right' }} onClick={() => this.saveConfig(id,type, sVal)}>Save</button>
        </div>)

        this.setState({ open: true, header, body, footer })
    }

    saveConfig(id, type, val) {
        var tCon = this.state.config
        if (tCon) {
            var i = tCon.panes.findIndex(x => x.id == id)
            if (i != -1) {
                tCon.panes[i] = { id, type, val }
            }
            else {
                tCon.panes.push({id,type, val})
            }
        }
        else {
            tCon = { templateType: this.props.type, panes: [{ id, type, val }] }
        }
        tCon.templateType = this.props.type
        tCon.panes = tCon.panes.filter(x => x.type != 0)

        const t = { entityId: this.props.match.params.id, config: JSON.stringify(tCon) }
        ApiPost('CustomForm', 'UpdatePageConfig', t)
        .then((n) => {
            this.setState({ open: !this.state.open })
        })
    }

    getPane(id, config) {
        //CHECK FOR EXISITNG PANE IN CONFIG
        if (config && config.panes.find(x => x.id == id)) {
            var type = config.panes.find(x => x.id == id).type
            var val = config.panes.find(x => x.id == id).val
            return (<div className='addTemplate'>
                        <p>{this.state.paneNames[id]}</p>
                        <b>{this.state.types.find(x => x.id == type).name}</b>
                        <button type='button' className='btn' onClick={() => this.getPaneModal(id, type, val)}><i className='fal fa-edit' /></button>
                    </div>)
        }
        else {
            var type = config && config.panes.find(x => x.id == id) ? config.panes.find(x => x.id == id).type : null
            return (<div className='addTemplate'>
                        <p>{this.state.paneNames[id]}</p>
                        <button type='button' className='btn' onClick={()=>this.getPaneModal(id, type, val)}><i className='fal fa-plus' /></button> 
                    </div>)
        }
    }

    render() {
        const { footer, header, body, open, config } = this.state
        const { type } = this.props
        var templateTypes = [{ name: 'Single Form', id: 1 }, { name: '1 Column', id: 2 }, { name: '2 Column', id: 3 }, { name: '3 Column', id: 4 }]

        return (
            <div>

                <Row>
                    {type > 1 && <Col sm={12}>{this.getPane(1, config)}</Col>}
                </Row>

                <Row>
                    <Col>{this.getPane(2, config)}</Col>
                    {type > 2 && <Col>{this.getPane(3, config)}</Col>}
                    {type > 3 && <Col>{this.getPane(4, config)}</Col>}
                </Row>

                <Row>
                    {type > 1 && <Col sm={12}>{this.getPane(5, config)}</Col>}
                </Row>

                <SimpleModal {...{ header, body, footer, open }} />
            </div>
            )
    }
}

export default withRouter(TemplateBuilder)
