import React, { Component } from 'react';
import ResetsSummaryPanel from './ResetsSummaryPanel'
import  Workflow  from '../../../components/Workflow/Workflow'
import  WizardValidation  from '../../../components/Workflow/WizardValidation'
import  CasePicker  from '../../../components/Workflow/CasePicker'
import  DeferAction  from '../../../components/Workflow/DeferAction'
import CommandDefer from '../../../components/Workflow/CommandDefer'
import CommandButton  from '../../../components/Workflow/CommandButton'
import WcDetails  from '../../../components/Workflow/WcDetails'
import {CardBody, Card } from 'reactstrap';
import Collapsible from 'react-collapsible';
import { getConfigItem, ApiPost, ApiGet } from '../../../api/index'
import FaultTagMgmt from './ScadaFaultTagMgmt';
import WfShowMessage from '../../../components/Workflow/WfShowMessage';
import WfNote from '../../../components/Workflow/WfNote';
import WfSubmit from '../../../components/Workflow/WfSubmit';
const SCADA_PATH = process.env.SCADA_PATH;
const SERVER_PATH = process.env.SERVER_PATH;

class ScadaActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alertData: null,
            alertid: 0,
            mgmtOpen: false,
            wfOptions: {}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { alerts } = this.props;
        if (JSON.stringify(prevProps.scadaGroups) != JSON.stringify(this.props.scadaGroups)) {
            this.getInitData()
        }
        if (alerts && alerts.length > 0 && this.state.alert != alerts[0]) {
            this.setState({ alert: alerts[0] })
        }
    }

    getInitData() {
        const { alerts, metrics, stationDeets } = this.props;
        var alert = null; var alertId = 0;
        if (alerts && alerts.length > 0) {
            alert = alerts[0];
            if (alert) {
                alertId = alert.stationAlertId
            }
        }

        var wfOptions = {}
        const wfParam = { stationId:stationDeets.id, alertId }
        ApiGet('Monitoring', 'GetScadaWfData', wfParam)
        .then((data) => {
            wfOptions = data
            if (alert) {
                const reqAlert = { alertId: alert.stationAlertId, newCaseId: null, stationId: stationDeets.id }
                ApiGet('Monitoring', 'GetAlertData', reqAlert)
                    .then((data) => {
                        const mdata = { alert, ...data }
                        this.setState({ 
                            alertData: mdata,
                            wfOptions: wfOptions
                        });
                    });
            } else {
                const { controller } = this.props
                var controllerid = 0;
                if (controller != undefined) {
                    controllerid = controller.id;
                }
                this.setState({
                    alertData: { stationId: controllerid },
                    wfOptions: wfOptions
                }, () => console.log('scadaac', this.state)) 
            }
        });
    }
    
    toggleMgmt() {
        this.setState({ mgmtOpen: !this.state.mgmtOpen })
    }

    manageFaultMetrics(e) {
        e.stopPropagation();
        this.setState({ mgmtOpen: !this.state.mgmtOpen }, () => console.log('mgmtOpen', this.state.mgmtOpen));
    }

    getAlertFaultName(alert) {
        var ret =
        
        alert.cntrLogMessage?alert.cntrLogMessage:'';
        
    }

    renderScadaWarning() {
        const production = (SERVER_PATH.indexOf('localhost') < 0)
        if (production) {
            return null
        } else {
            if (SCADA_PATH.indexOf('localhost') > 0) {
                return <div className="alert alert-success">
                    Connecting to local Comms Server
                </div>
            } else {
                return <div className="alert alert-danger">
                    Connecting to LIVE sites!!
                </div>
            }
        }
    }

    render() {
        const { alerts, metrics, stationDeets, scadaGroups, toggleFavMetric, getScadaValue, getMetricUnit,getMetricName, favourites } = this.props;
        const { alertData,alert,wfOptions } = this.state;
        const wfTemplate = getConfigItem('$.monitoring.alertWorkflow');
        var workFlowComponents = {
            'WizardValidation': WizardValidation,
            'CasePicker': CasePicker,
            'DeferAction':DeferAction,
            'CommandDefer':CommandDefer,
            'CommandButton':CommandButton,
            'WcDetails':WcDetails,
            'ShowMessage':WfShowMessage,
            'Submit':WfSubmit,
            'Note':WfNote
        }
        //var cardHeading = <span>Action <i className="fa fa-pencil" onClick={(e) => { this.manageFaultMetrics(e) }} /></span>;
        var cardHeading = <span>Action</span>;
        var cardClassName = ""
        var wfData = null;
        var pwrVal = 0
        if (this.state.alert) {
            if (scadaGroups) {
                var m = scadaGroups[stationDeets.id].find(x => x && x.uMetId == 11)
                if (m) {
                    pwrVal = m.metricId ? getScadaValue(m.metricId, 1) : 0
                }
            }
            cardClassName = "faulted" + (pwrVal > 1 ? " resolving" : "")
            cardHeading = "Actions (FAULTED) - " + this.state.alert.cntrLogMessage;
            wfData = this.state.alert;
        }
        wfData = {
            station: stationDeets,
            alertData,
            stationId: stationDeets.id,
            alert,
            scadaGroups,
            metricFuncs: { toggleFavMetric, getScadaValue, getMetricUnit, getMetricName },
            favourites,
            wfOptions
        };
        return (
            <div>
                {this.renderScadaWarning()}
                <Collapsible trigger={cardHeading} triggerClassName="card-heading" triggerOpenedClassName="card-heading" className={cardClassName} openedClassName={cardClassName} transitionTime={200} >
                    <Card>
                        <div className="card-block" style={{ backgroundColor: 'rgb(230, 230, 230)' }}>
                            <ResetsSummaryPanel message={wfOptions.message} iconColor={wfOptions.messageColor} icon={wfOptions.messageIcon} data={this.state.alertData} />
                            <Workflow template={wfTemplate} alert={this.state.alert} metrics={metrics} hideRoot={false} data={wfData} components={workFlowComponents} cmdSucess={this.props.cmdSuccess} />
                        </div>
                    </Card>
                </Collapsible>

                {this.state.mgmtOpen ? <FaultTagMgmt isOpen={this.state.mgmtOpen} toggleMgmt={() => this.toggleMgmt()} stationDeets={this.props.stationDeets} scadaGroups={this.props.scadaGroups} /> : null}
            </div>
        )
    }
}


export default ScadaActions;
    