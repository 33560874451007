import React, { Component } from 'react';
import { widgetconfig, metricInfo } from '../../../configuration/widgets';
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { GetStationsStateC, GetStationsStateIndustry } from '../../../api'

class WidgetContainer extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            modal: false, tooltipOpen: false
        };
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    combineDeviceIndustries(v) {
        var t = []
        Object.keys(v).map(x => {
            t = [...v[x].items]
        })
        return {items: t}
    }

    getActiveWidget() {
        var wS = widgetconfig[this.props.widgetInfo.type];
        var params = JSON.stringify(this.props.widgetInfo.params)
        var dKey = this.props.widgetInfo.type + '-' + (this.props.widgetInfo.period || 0) + '-' + params
        var widgetValue = this.props.dashboard[dKey] && this.props.dashboard[dKey].value
        if (wS) {
            if (wS.liveData) {
                var allDevices = this.combineDeviceIndustries(GetStationsStateIndustry(this.props))
                return <wS.type widgetValues={allDevices} selectedParam={this.props.widgetInfo} devices={this.props.header.assets} />
            }
            else {
                return <wS.type {...wS} widgetValue={widgetValue} period={this.props.widgetInfo.period} selectedParam={this.props.widgetInfo} client={this.props.header.client} />
            }
        }
        else {
            return <div className='widgetUnavil'><p>Widget unavailable</p></div>
        }
    }

    getTipDesc()
    {
        var tipDesc = "";
        var params = JSON.stringify(this.props.widgetInfo.params)
        var widgetValue = this.props.dashboard[this.props.widgetInfo.type + '-' + this.props.widgetInfo.period + '-' + params] ? this.props.dashboard[this.props.widgetInfo.type + '-' + this.props.widgetInfo.period + '-' + params].value : null;
        if (widgetValue && widgetValue.customToolTip) {
            tipDesc = widgetValue.customToolTip
        }
        else if (widgetconfig[this.props.widgetInfo.type].periods) {
            try {
                tipDesc = widgetconfig[this.props.widgetInfo.type].periods.find(x => x.id == this.props.widgetInfo.period)
                if (tipDesc) {
                    tipDesc = tipDesc.info
                }
            }
            catch (err) {
                console.log('tipDesc', err);
            }
        }
        else {
            tipDesc = widgetconfig[this.props.widgetInfo.type].description;
        }

        return tipDesc;
    }

    render() {
        return (
            <div className="default-widget" key={this.props.key}>

                {!this.props.widgetInfo.noShadow && <a data-tip className={"infoTip " + this.tooltipLevel} data-for={"infoTip" + this.props.widgetInfo.i}><i className="fa fa-info-circle"></i></a>}
                <ReactTooltip place="left" visible={this.state.tooltipOpen} id={"infoTip" + this.props.widgetInfo.i} getContent={() => this.getTipDesc()} />

                {this.getActiveWidget()}

            </div>
        );
    }
};

const mapStateToProps = state => {
    return state.common
}

export default connect(
    mapStateToProps
)(WidgetContainer)
