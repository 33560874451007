import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
//import MasterDetail from '../../../testComp/MasterDetail/src'
import { dbTables } from '../../../configuration/formTables';
export const API_PATH = process.env.SERVER_PATH + '/api'

class FieldServiceReportUpload extends Component {

    constructor(props) {
        super(props);
        this.state = { smId: 1};
    }

    render() {

        var serviceReportFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Title", fieldId: "title" },
            { label: "Upload", fieldId: "upload", type: "upload", config: { uploadType: this.props.location.state && this.props.location.state.uploadType == 1 ? "img" : "file", uploadPath: API_PATH } },
        ];

        var engineerSelectionConfig = {
            model: 'FieldServiceReportUpload',
            fields: serviceReportFields,
            gridButton: false,
            showFilterRow: false,
            master: true,
            defaultSorted: [{ id: 'startTime', desc: true }],
            uploadFolder: 'ServiceReport',
            apiPath: API_PATH,
            crudParams: { addNew: this.props.location.state && this.props.location.state.addNew ? true : false, isJob: this.props.location.state && this.props.location.state.isJob ? true : false }
        }

        return (
            <div>
                <div className='titleBlock'>Work Report Upload</div>
                <MasterDetail key={'mdPO' + this.state.smId} {...engineerSelectionConfig} />
            </div>
        )
    }
}

export default FieldServiceReportUpload
