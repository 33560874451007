import React, { Component } from 'react';
import { ApiGet, ApiPost } from '../../../api';
import { connect } from 'react-redux';
import ParamTable from './ParameterTable';
import { Input, Label } from 'reactstrap';

class ParameterTables extends Component {
    constructor(props) {
        super(props);
        this.state = { onlyDiff: false }
    }

    onCheck() {
        this.setState({ onlyDiff: !this.state.onlyDiff });
    }

    render() {
        return (
            <div>
                {!this.props.external && <Label check style={{ float: 'right', marginTop: '10px' }}><Input type="checkbox" checked={this.state.onlyDiff ? 'checked' : ''} onChange={(e) => this.onCheck(e)} /> Only Show Diff</Label>}
                {this.props.paramInfo ?
                    this.props.paramInfo.map(x => {
                        return <ParamTable key={x.sectionId} sectionNo={x.sectionId} sectionName={x.sectionName} sites={this.props.sites} paramData={x.sectionParams} paramValues={this.props.paramVals} onlyDiff={this.state.onlyDiff}
                        fnReloadSite={this.props.fnReloadSite}
                        />
                    })
                    : null}
            </div>
        );
    }

};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(ParameterTables)
