import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import { dbTables } from '../../../configuration/formTables'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap'
import classnames from 'classnames'
import { ApiPost } from '../../../api'
import { connect } from 'react-redux'
import ShortCodeGenerator from '../../../components/ShortCodeGenerator'
import StockQty from './StockQty'
export const API_PATH = process.env.SERVER_PATH + "/api";

class Parts extends Component {

    constructor(props) {
        super(props);
        this.state = { activeTab: '1', mainActiveTab: '1', stockAdmin: false };
    }

    shouldComponentUpdate(pp, ps) {
        if (pp.match.params.id != this.props.match.params.id || JSON.stringify(ps) != JSON.stringify(this.state)) {
            return true
        }
        else {
            return false
        }
    }

    componentDidMount() {
        var roles = this.props.commonstate ? this.props.commonstate.users.authRole : ''
        if (roles.includes("Administrator") || roles.includes("StockAdmin")) {
            this.setState({ stockAdmin: true })
        }

        var locationState = this.props.location.state
        if (locationState != null) {
            locationState.history.map((h) => {
                if (h.model.includes("suppliers")) {
                    this.setState({ mainActiveTab: '2' })
                }
            })
        }
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    deleteRecFn(id) {
        if (id != null && id > 0) {
            ApiPost('PartSelection', 'RemoveSubPart', { id })
                .then((d) => {
                    console.log('subPart removed:', d)
                });
        }
    }

    viewRecFn(id) {
        if (id != null && id > 0) {
            this.props.history.push('/parts/' + id)
        }
    }

    displaySubTable() {
        const { mainActiveTab, activeTab } = this.state;
        var ret = null
        var isEdit = false

        var lists = {
            type: [{ id: 0, name: '' }, { id: 1, name: 'Purchase' }, { id: 2, name: '' }, { id: 3, name: 'Sale/Repair' }, { id: 4, name: 'Stock Take' }],
        }

        if (this.props.match && this.props.match.params.id) {
            if (this.props.match.params.id != "New") {
                if (this.props.match.params.id > 0) {
                    isEdit = true
                }
            }
        }

        if (mainActiveTab === '1' && isEdit) {
            var partHistoryFields = [
                { label: 'Id', fieldId: 'id', display: 'none' },
                { label: 'Time', fieldId: 'time', display: 'grid', width: 200, type: 'datetime' },
                { label: 'Type', fieldId: 'type', display: 'grid', width: 200, list: lists.type },
                { label: 'From', fieldId: 'movedFrom', display: 'grid', width: 200 },
                { label: 'To', fieldId: 'movedTo', display: 'grid', width: 200 },
                { label: 'Qty.', fieldId: 'qty', display: 'grid', width: 150 },
                { label: 'Avg. Cost (£)', fieldId: 'cost', display: 'grid', width: 150 },
                { label: 'Levels', fieldId: 'levels', display: 'grid' }
            ];

            var partHistoryConfig = {
                model: 'partHistory',
                path: '/PartSelection/PartHistory',
                fields: partHistoryFields,
                crudParams: { partId: this.props.match.params.id },
                defaultSorted: [{ id: 'time', desc: true }],
                gridButton: false,
                gridProps: { minRows: 1, noDataText: <div><br /><b>No Part Movements</b></div>, showPagination: false },
                apiPath: API_PATH
            }

            var partLocationFields = [
                { label: 'Id', fieldId: 'id', display: 'none' },
                { label: 'partId', fieldId: 'partId', display: 'none', getId: -1 },
                { label: 'Aisle', fieldId: 'aisle', display: 'grid', width: 200, inlineEdit: true },
                { label: 'Location', fieldId: 'location', display: 'grid', width: 200, inlineEdit: true },
                { label: 'Warehouse', fieldId: 'warehouseId', display: 'grid', width: 200, list: listRequest.Warehouses, inlineEdit: true },
                { label: 'Edit', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
                { label: 'Delete', fieldId: 'inlineDeleteBtn', width: 60, display: 'grid', type: 'inlineDeleteBtn' },
            ];

            var partLocationConfig = {
                model: 'partLocation',
                fields: partLocationFields,
                addInline: true,
                gridButton: false,
                gridProps: { minRows: 1, noDataText: <div><br /><b>No Part Locations</b></div>, showPagination: false },
                apiPath: API_PATH
            }


            ret = (<div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1') }}>History</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { this.toggle('2') }}>Part Location</NavLink>
                    </NavItem>
                </Nav>
                <TabContent key='clientTabs' activeTab={this.state.activeTab}>
                    <TabPane tabId='1'>
                        {this.state.activeTab == '1' && <MasterDetail {...partHistoryConfig} />}
                    </TabPane>
                    <TabPane tabId='2'>
                        {this.state.activeTab == '2' && <MasterDetail {...partLocationConfig} />}
                    </TabPane>
                </TabContent>
            </div >)
        }

        return ret
    }

    displaySubTable() {
        const { mainActiveTab } = this.state;
        var ret = null
        var isEdit = false

        var lists = {
            type: [{ id: 0, name: '' }, { id: 1, name: 'Purchase' }, { id: 2, name: '' }, { id: 3, name: 'Sale/Repair' }, { id: 4, name: 'Stock Take' }],
        }

        if (this.props.match && this.props.match.params.id) {
            if (this.props.match.params.id != "New") {
                if (this.props.match.params.id > 0) {
                    isEdit = true
                }
            }
        }

        if (mainActiveTab === '1' && isEdit) {
            var partHistoryFields = [
                { label: 'Id', fieldId: 'id', display: 'none' },
                { label: 'Time', fieldId: 'time', display: 'grid', width: 200, type: 'datetime' },
                { label: 'Type', fieldId: 'type', display: 'grid', width: 200, list: lists.type },
                { label: 'From', fieldId: 'movedFrom', display: 'grid', width: 200 },
                { label: 'To', fieldId: 'movedTo', display: 'grid', width: 200 },
                { label: 'Qty.', fieldId: 'qty', display: 'grid', width: 150 },
                { label: 'Avg. Cost (£)', fieldId: 'cost', display: 'grid', width: 150 },
                { label: 'Levels', fieldId: 'levels', display: 'grid' }
                // { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', funcProps: (rec) => { return { location: '/ImportLookup/' + rec._original.id + '/0/' } }, width: 100 }
            ];

            var partHistoryConfig = {
                model: 'part History',
                path: '/PartSelection/PartHistory',
                fields: partHistoryFields,
                crudParams: { partId: this.props.match.params.id },
                defaultSorted: [{ id: 'time', desc: true }],
                //gridButton: { pathname: '/PartMovement/New', state: { partId: this.props.match.params.id } },
                gridButton: false,
                gridProps: { minRows: 1, noDataText: <div><br /><b>No Part Movements</b></div>, showPagination: false },
                apiPath: API_PATH
            }       

            ret = (
                <div style={{marginTop: '20px'}}>
                    <div className='titleBlock'>History</div>
                    
                    <TabContent>
                        <TabPane><MasterDetail {...partHistoryConfig} /></TabPane>
                    </TabContent>
                </div>
            )
        }

        return ret
    }

    cascadeComponents() {
        var subCatFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Subcategory Name', fieldId: 'name', inlineEdit: true },
            { label: 'CatId', fieldId: 'parentCatId', display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];
        var nsSubCatsConfig = {
            model: 'subCats',
            path: '/NonStockCats',
            fields: subCatFields,
            gridButton: false,
            crudParams: { cId: this.props.match.params.id },
            gridProps: { defaultPageSize: 5 },
            addInline: true,
            apiPath: API_PATH
        }
        return <MasterDetail {...nsSubCatsConfig} />
    }

    render() {
        const { mainActiveTab } = this.state;

        var lists = {
            stockCat: [{ id: 1, name: 'CON' }, { id: 2, name: 'CP' }, { id: 3, name: 'SER' }, { id: 4, name: 'PRT' }],
            leadTime: [{ id: 1, name: 'Same/Next Day' }, { id: 2, name: '3 days' }, { id: 3, name: '1 weeks' }, { id: 4, name: '1-2 weeks' }, { id: 5, name: '2-4 weeks' }, { id: 6, name: '4+ weeks' }]
        };

        var subPartsFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'TurbinePartId', fieldId: 'turbinePartId', display: 'none', getId: -1 },
            { label: "Part", fieldId: "partId", canFilter: false, list: listRequest.Parts, width: 400, categories: listRequest.PartTypes, inlineEdit: true, type: 'filterList' },
            { label: "Qty", fieldId: "qty", inlineEdit: true },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
            { label: '', fieldId: 'inlineDeleteBtn', width: 60, display: 'grid', type: 'inlineDeleteBtn' },
            // { label: '', fieldId: "funcButton", display: 'grid', type: 'inlineFuncButton', typeProps: { label: <i className="fal fa-eye" />, func: (rec) => this.viewRecFn(rec._original.partId) }, width: 60, },
        ];

        var subPartsConfig = {
            model: 'SubPartSelection',
            fields: subPartsFields,
            crudParams: { parentPartId: this.props.match.params.id },
            titleField: 'partId',
            addInline: true,
            gridButton: false,
            submitAction: () => { },
            gridProps: { minRows: 1, noDataText: <div><br /><b>No Parts Added</b></div>, showPagination: false },
            apiPath: API_PATH
        }

        var newPart = this.props.match.params.id == "New" ? true : false

        var partsFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Manufacturer', fieldId: 'manufacturerRef', display: 'form' },
            { label: '', fieldId: 'whStock', display: 'form', type: 'custom', custom: (rec) => rec.data && rec.data.id ? <StockQty rec={rec} /> : null, col: 2 },
            { label: 'Name', fieldId: 'name', inlineEdit: true, width: 500, canFilter: true },
            { label: 'Product Code', fieldId: 'serialNumber', display: 'form' },
            { label: 'Short Code', fieldId: 'shortCode', display: 'grid', canFilter: true },
            { label: 'Short Code', fieldId: 'shortCode', display: 'form', type: 'custom', custom: (rec) => rec.data && rec.data.id ? <ShortCodeGenerator rec={rec} /> : null },
            { label: 'Type', fieldId: 'partTypeId', list: listRequest.PartTypes, inlineEdit: true },
            { label: 'Composite Part', fieldId: 'complicatedPart', type: 'checkbox', display: 'form', displayIf: (rec) => ![undefined, 0, false].includes(!newPart) },
            { label: "Component Parts", fieldId: "parts", type: 'custom', display: 'form', custom: MasterDetail, customProps: subPartsConfig, displayIf: (rec) => rec.complicatedPart },
            { label: 'Supplier', fieldId: 'supplierId', list: listRequest.PartSuppliers, inlineEdit: true },
            { label: 'Stock Cat', fieldId: 'stockCatId', display: 'form', list: lists.stockCat },
            { label: 'Cost Price (\u00A3)', fieldId: 'price', inlineEdit: true },
            { label: 'Sale Price (\u00A3)', fieldId: 'salePrice', display: 'form' },
            { label: 'Notes', fieldId: 'notes', display: 'form', type: 'textarea' },
            { label: 'Lead Time', fieldId: 'leadTimeId', display: 'form', list: lists.leadTime },
            { label: 'Repair Cost (\u00A3)', fieldId: 'repairCost', display: 'form' },
            { label: 'Annual Usage', fieldId: 'annualUsage', display: 'form' },
            { label: 'Qty per set', fieldId: 'qtyPerSet', display: 'form' },
            { label: 'Min Stock', fieldId: 'minStock', display: 'form' },
            { label: 'Edit', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
            { label: 'Delete', fieldId: 'inlineDeleteBtn', width: 60, display: 'grid', type: 'inlineDeleteBtn' },
            { label: 'Detail', fieldId: "navButton", width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/Parts/' } },
            { label: "Sub Parts", fieldId: "parts", type: 'custom', display: 'form', custom: MasterDetail, customProps: subPartsConfig, displayIf: (rec) => ![undefined, 0, false].includes(rec.complicatedPart) },
        ];

        var partsConfig = {
            model: 'parts',
            path: '/PartSelection/AllParts',
            master: true,
            fields: partsFields,
            //gridButton: false,
            gridProps: { defaultPageSize: 25 },
            //addInline: true,
            showFilterRow: true,
            defaultSorted: [{ id: 'name', desc: false }],
            titleField: 'name',
            linkedEntity: dbTables.Parts,
            apiPath: API_PATH
        }

        var suppliersFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Name', fieldId: 'name', inlineEdit: true },
            { label: 'Contact', fieldId: 'contactPerson', inlineEdit: true },
            { label: 'Phone', fieldId: 'phone', inlineEdit: true },
            { label: 'Email', fieldId: 'email', inlineEdit: true },
            { label: 'VAT No', fieldId: 'vatNo', inlineEdit: true },
            { label: 'Account No', fieldId: 'accountNo', inlineEdit: true },
            { label: 'Edit', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
            { label: 'Delete', fieldId: 'inlineDeleteBtn', width: 60, display: 'grid', type: 'inlineDeleteBtn' },
            { label: 'Detail', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', funcProps: (rec) => { return { location: '/PartsSuppliers/' + rec._original.id } } }
        ];

        var suppliersConfig = {
            model: 'partsSuppliers',
            path: '/PartSelection/AllSuppliers',
            fields: suppliersFields,
            titleField: 'name',
            master: true,
            defaultSorted: [{ id: 'name', desc: false }],
            gridButton: { pathname: '/PartsSuppliers/New' },
            gridProps: { defaultPageSize: 25 },
            apiPath: API_PATH
        }

        var dFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Name', fieldId: 'name', inlineEdit: true },
            { label: 'Edit', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
        ];

        var typeConfig = {
            model: 'partTypes',
            path: '/PartSelection/AllTypes',
            fields: dFields,
            gridButton: false,
            gridProps: { defaultPageSize: 25 },
            addInline: true,
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH
        }

        var departFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Department', fieldId: 'name', inlineEdit: true, width: 500 },
            //{ label: 'HOD Approver', fieldId: 'primaryUserId', list: listRequest.operators, inlineEdit: true },
            //{ label: 'Finance Approver', fieldId: 'secondaryUserId', list: listRequest.operators, inlineEdit: true },
            { label: 'Variance % Threshold', fieldId: 'variancePercentageThreshold'},
            { label: 'Variance Min \u00A3 Threshold', fieldId: 'varianceMinAmountThreshold'},
            { label: '', fieldId: "navButton", width: 60, display: 'grid', type: 'navButton' },
            //{ label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var departConfig = {
            model: 'purchasingDepartment',
            fields: departFields,
            gridProps: { defaultPageSize: 25 },
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH
        }

        var nsFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Category Name', fieldId: 'name', inlineEdit: true },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var nonStockConfig = {
            model: 'NonStockCats',
            fields: nsFields,
            gridButton: false,
            gridProps: { defaultPageSize: 25 },
            addInline: true,
            defaultSorted: [{ id: 'name', desc: false }],
            cascade: true,
            cascadeComponents: this.cascadeComponents(),
            apiPath: API_PATH
        }

        return (
            <div>
                {this.props.match.params.id != 'New' ?
                    <div>
                        {!this.props.match.params.id && <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Parts</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Part Suppliers</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '3' })} onClick={() => { this.toggle('3', 'main') }}>Part Categories</NavLink>
                            </NavItem>
                            {this.state.stockAdmin && <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '4' })} onClick={() => { this.toggle('4', 'main') }}>Purchasing Departments</NavLink>
                            </NavItem>}
                            {this.state.stockAdmin && <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '5' })} onClick={() => { this.toggle('5', 'main') }}>Non-stock Categories</NavLink>
                            </NavItem>}
                        </Nav>}
                        <TabContent key='clientTabs' activeTab={this.state.mainActiveTab}>
                            <TabPane tabId='1'>
                                {this.state.mainActiveTab == '1' ? <div><MasterDetail key='mdParts' {...partsConfig} />{this.displaySubTable()}</div> : null}
                            </TabPane>
                            <TabPane tabId='2'>
                                {this.state.mainActiveTab == '2' ? <MasterDetail key='mdSup' {...suppliersConfig} /> : null}
                            </TabPane>
                            <TabPane tabId='3'>
                                {this.state.mainActiveTab == '3' ? <MasterDetail key='mdCat' {...typeConfig} /> : null}
                            </TabPane>
                            <TabPane tabId='4'>
                                {this.state.mainActiveTab == '4' ? <MasterDetail key='mdDepart' {...departConfig} /> : null}
                            </TabPane>
                            <TabPane tabId='5'>
                                {this.state.mainActiveTab == '5' ? <MasterDetail key='mdNonstock' {...nonStockConfig} /> : null}
                            </TabPane>
                        </TabContent>
                    </div>
                    :
                    <MasterDetail key='mdParts' {...partsConfig} />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common }
}

export default connect(mapStateToProps)(Parts)
