import  {ApiPost}  from '../../../api/index'

export const TRY_LOGIN = 'TRY_LOGIN'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const SET_AUTH_INFO = 'GOT_AUTH_INFO'

export function authenticateUser(login) {
    ApiPost('auth','login',login).then(function(response) {
        return response.json();
    }).then(function(data) {
        console.log('Created Gist:', data);
    });
}

export function setAuthInfo(authInfo, userInfo) {
    return {
        type: SET_AUTH_INFO,
        authInfo,
        userInfo
    }
}
