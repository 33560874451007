import React, { Component } from 'react'
import moment from 'moment'
import IncidentChart from '../../Incidents/components/IncidentChart'
import IncidentMap from '../../Incidents/components/IncidentMap'
import { getConfigItem, ApiPost, ApiGet } from '../../../api'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { Input } from 'reactstrap'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'

class VesselMap extends Component {
    constructor(props) {
        super(props);
        var date = moment()
        var offset = date.utcOffset()

        var fDate = moment(date).add(-2, 'hours'),
            tDate = date

        var plotTime = fDate.format('YYYY-MM-DDTHH:mm:ss')
        this.state = {
            cats: null, chartData: null, plotTime, plotlineActive: false,
            showChart: true, showMap: true, selectedVessel: null,
            iData: null,
            dAlertData: null,
            fromDate: fDate,
            toDate: tDate,
            vesselDetails: null,
            dateSelectTitle: fDate.format('DD/MM/YY HH:mm') + ' to ' + tDate.format('DD/MM/YY HH:mm'),
            source: 2,
            guardAreas: null,
            offset,
            assets: null,
            selectedAsset: null,
            noData: false
        }
    }

    componentDidMount() {
        this.getVesselInfo()
    }

    getVesselInfo() {
        ApiGet('Monitoring', 'GetVesselDetails')
        .then((data) => {
            this.setState({ vesselDetails: data.vessels, guardAreas: data.guardAreas, assets: data.assets, selectedAsset: data.assets ? 135 : 0 }, () => this.createCategories())
        })
    }

    createCategories() {
        this.setState({ noData: false })
        var fDate = moment(this.state.fromDate).subtract(this.state.offset, 'm').format('YYYY-MM-DDTHH:mm:ss'), tDate = moment(this.state.toDate).subtract(this.state.offset, 'm').format('YYYY-MM-DDTHH:mm:ss')
        var geoConfig = { mmsi: '', fromDate: fDate, toDate: tDate, sourceId: this.state.source, asset: this.state.selectedAsset }
        ApiGet('Monitoring', 'GetAlertGeoData', geoConfig)
        .then((d) => {
            var start = performance.now();
            var vessels = []
            var v = d.values
            if (v) {
                Object.keys(v).map(x => {
                    var id = parseInt(v[x][7])
                    if (!vessels.includes(id)) {
                        vessels.push(id)
                    }
                })
                var dAlertData = []
                Object.keys(vessels).map((x, i) => {
                    var vGeoData = d.values.filter(z => z[7] == vessels[x])
                    if (vGeoData.length > 1) {
                        var name = vessels[x]
                        if (this.state.vesselDetails[vessels[x]]) {
                            name = this.state.vesselDetails[vessels[x]]
                        }
                        var rTime = moment(vGeoData[0][0]).format('YYYY-MM-DDTHH:mm:ss'), cTime = moment(vGeoData[vGeoData.length - 1][0]).format('YYYY-MM-DDTHH:mm:ss')
                        dAlertData.push({ alertTypeId: 35, closed: cTime, groupId: vessels[x], groupRef: name, id: i, raised: rTime })
                    }
                })
                this.setState({ iData: d, dAlertData }, () => this.genCats())
            }
            else {
                this.setState({ noData: true })
            }
            var end = performance.now();
            console.log('geoDataTime', ((end - start) * 0.001) + " secs");
        });
    }

    genCats() {
        var cats = [], mmsis = []
        this.state.dAlertData.map(x => {
            if (!cats.includes(x.groupRef)) {
                cats.push(x.groupRef)
                mmsis.push(x.groupId)
            }
        })
        this.setState({ cats }, () => this.genChartData())
    }

    handleDate(fromDate, toDate, custom, noLoad) {

        if (fromDate.format('DD/MM/YY').toString() == toDate.format('DD/MM/YY').toString()) {
            this.setState({
                dateSelectTitle: moment(fromDate).format('DD/MM/YY') + ' ' + moment(fromDate).format('HH:mm') + ' to ' + moment(toDate).format('HH:mm')
            })
        }
        else {
            this.setState({
                dateSelectTitle: moment(fromDate).format('DD/MM/YY HH:mm') + ' to ' + moment(toDate).format('DD/MM/YY HH:mm')
            })
        }
        console.log('handleDate', {fromDate, toDate})
        this.setState({ fromDate, toDate }, () => this.createCategories())
    }

    genChartData() {
        var alertColours = { 34: '#9bc7cf', 35: '#FCD757', 36: '#fc9f57', 37: '#EE6352' }
        var chartData = []
        this.state.dAlertData.map(x => {
            chartData.push({ x: Date.parse(x.raised), x2: x.closed ? Date.parse(x.closed) : new Date().getTime(), y: this.state.cats.indexOf(x.groupRef), color: alertColours[x.alertTypeId] })
        })
        this.setState({chartData})
    }

    setPlotTime(x) {
        this.setState({ plotTime: moment(x).format('YYYY-MM-DDTHH:mm:ss') });
    }

    selectVessel(e) {
        console.log('selectedVessel', e)
    }

    render() {
        let local = { format: "DD-MM-YYYY HH:mm", sundayFirst: false }
        let cStyle = { customRangeButtons: { color: '#0a92b1' }, customRangeSelected: { backgroundColor: '#0a92b1' }, fromDate: { backgroundColor: '#0a92b1' }, toDate: { backgroundColor: '#0a92b1' } }

        return(
        <div>
            <div style={{width:'350px', paddingBottom:'0.5rem', display:'inline-block' }}><DateTimeRangeContainer start={this.state.fromDate} end={this.state.toDate} ranges={{}} local={local} applyCallback={(sd, ed) => this.handleDate(sd, ed, true)} style={cStyle} >
                <Input id="formControlsTextB" type="text" label="Text" placeholder={this.state.dateSelectTitle} />
            </DateTimeRangeContainer>
            </div>
            <div style={{ display: 'inline-block', marginLeft: '5px' }}>
                <span>Asset: </span>
                <IdNameDropdown value={this.state.selectedAsset} style={{ width: '200px', display: 'inline-block' }} options={this.state.assets} valueField="id" displayField="name" onChange={(e) => this.setState({ selectedAsset: e }, () => this.createCategories())} />
            </div>
            <div style={{ display: 'inline-block', marginLeft: '5px'}}>
                <span>Source: </span>
                <IdNameDropdown value={this.state.source} style={{ width: '150px', display: 'inline-block' }} options={[{ id: 1, name: 'Vessel Tracker' }, { id: 2, name: 'Guard Vessel' }]} valueField="id" displayField="name" onChange={(e) => this.setState({ source: e }, () => this.createCategories())} />
            </div>

            {this.state.noData && <p style={{textAlign:'center'}}><b>No data for date / asset / source combination.</b></p>}
            {this.state.chartData && this.state.showMap && <IncidentMap data={this.state.dAlertData} iData={this.state.iData} plotTime={this.state.plotTime} fromDate={this.state.fromDate} toDate={this.state.toDate} guardAreas={this.state.guardAreas} selectedAsset={this.state.selectedAsset} />}
            {this.state.chartData && this.state.showChart && <IncidentChart chartData={this.state.chartData} setPlotTime={(x) => this.setPlotTime(x)} plotTime={this.state.plotTime} cats={this.state.cats} fromDate={this.state.fromDate} toDate={this.state.toDate} setGroupRef={(e) => this.selectVessel(e)} /> }
        </div>)
    }  
}

export default (VesselMap)
