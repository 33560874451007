import React from 'react';
import ReactDOM from 'react-dom';
import { ApiGet, ApiPost, GetStationLiveState } from '../../api';
import { connect } from 'react-redux';
import TestControlTags from './TestControl_Tags';
import TestControlCrud from './TestControl_Crud';

class TestControl extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            user: "Unknown"
        }
    }
    
    render() {
        const { user } = this.state
        return (
            <div style={{width:'100%', height:'400px'}}>
                <TestControlCrud />
            </div>
        )
    }
}

const mapStateToProps = state => {
    // Dictionary of stationId { ...asset, siteMetric[umet], alerts[] }
    return { commonstate: state.common, scada: state.common.scada }
    //state.common.header
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateLiveConnection: (siteList) => {
            dispatch(updateLiveConnection(siteList));
        },
        disconnectLive: () => {
            dispatch(disconnectLive());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestControl)
