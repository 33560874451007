import React from 'react'

const LiveSpinner = (props) => {

    return (
        <div className="livenow">
            <div style={!props.isLive ? { animation: 'none', backgroundColor: '#EE6352', borderColor:'#EE6352' } : {}}></div>
            <div style={!props.isLive ? { animation: 'none', backgroundColor: '#EE6352', borderColor:'#EE6352' } : {}}></div>
        </div>
     )
}

export default LiveSpinner