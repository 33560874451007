import React from 'react';
import moment from 'moment';

let VestasHeader = props => {
  const { vdfHeader } = props;
  var kv = {}
  kv["Turbine Model"] = "V" + vdfHeader["turbineModel"];
  kv["Rcs"] = vdfHeader["rcsNumber"];
  kv["Error Triggered At"] = moment(vdfHeader["trigDateTime"]).format('DD/MM/YY HH:mm:ss');
  if (vdfHeader["error"]) {
  //kv["Error Num"] = vdfHeader["error"]["cntrErrNum"];
  kv["Error Msg"] = <span><b>{vdfHeader["error"]["cntrErrNum"]}: </b>{vdfHeader["error"]["cntrLogMessage"]}</span>;
  kv["Error Criteria"] = vdfHeader["error"]["cntrCriterion"];
  }
  return (
    <div className="card" style={{marginTop:'15px'}}>
      <h5 className="card-header">Vestas VDF</h5>
      <div className="card-body" style={{padding:'15px 15px 5px'}}>
        {Object.keys(kv).map((k) => <dl className="row" key={k} style={{marginBottom:0}}>
          <dt className="col-sm-3" style={{lineHeight:1, maxWidth:'140px'}}>{k}</dt>
          <dd className="col-sm-9" style={{lineHeight:1}}>{kv[k]}</dd>
        </dl>
        )}
      </div>
    </div>
  );
};

export default VestasHeader