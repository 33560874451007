import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { formatPower } from './MapStation';
import Station from './MapStation.js';
import MapStationSquare from './MapStationSquare'

const K_WIDTH = 45;
const K_HEIGHT = 45;

const stationStyle =
{
    width: K_WIDTH,
    height: K_HEIGHT
};

const outputStyle =
    {
        position: 'absolute',
        width: K_WIDTH * 2,
        height: K_HEIGHT / 1.9,
        left: -K_WIDTH / 2,
        top: K_HEIGHT * 1,
        backgroundColor: '#004336',
        textAlign: 'center',
        color: '#FFF',
        fontSize: 10,
        fontWeight: 'bold',
        padding: -1
    };

const siteNameStyle =
    {
        position: 'absolute',
        width: K_WIDTH * 4,
        height: K_HEIGHT / 1.5,
        textAlign: 'center',
        top: K_HEIGHT * 1.5,
        left: -K_HEIGHT * 1.5
    };

export default class ClusterStation extends Component {
    static propTypes =
    {
        windSpeed: PropTypes.string,
        siteName: PropTypes.string,
        siteOutput: PropTypes.string
    };

    static defaultProps = {};

    pointsToRows(points) {
        let rs = 1;
        if (points.length > 2) rs = 2;
        if (points.length > 6) rs = 3;
        let cs = 2;
        if (points.length > 4) rs = 3;
        let r = 0;
        let c = 0;
        let curP = 0;
        let ret = [];
        for (r = 0; r < rs; r++) {
            let row = [];
            while (c < cs && curP < points.length) {
                row.push(points[curP])
                c = c + 1;
                curP = curP + 1;
            }   
            ret.push(row);
            c = 0;
        }
        return ret;
    }

    printSiteNames(points) {
        var siten = points.map((p,i) => p.name)
        return siten.join(" / ")
    }

    render() {

        const { points } = this.props;

        const pointRows = this.pointsToRows(points);
        //console.log("pointRows",pointRows)
        var avgWS = Math.round(points.reduce((a, b) => a + parseInt(b.ws), 0) / points.length);
        var totPower = points.reduce((a, b) => a + parseInt(b.pw), 0);
        var avgPW = Math.round(totPower / points.length);

        var ws = avgWS?(
            <div style={stationStyle}>
                {avgWS}
            </div>
        ) : <div style={stationStyle} />;

        var pointWidth = 40;
        const clusterStyle = {
            width: pointWidth*(points.length>4?3:2)
        }
        const clusterWrapStyle = {
            left: clusterStyle.width/-2 + 'px',
        }
        if (points.length > 9) {
            var classn = "big-cluster";
            if (totPower >= 1) {
                classn += " producing"
            }
            return (<div style={stationStyle} className={classn}>
                {points.length} <span className="small">sites</span><br/>
                {formatPower(totPower,null ,null )}
            </div>)
        }  
        else {
            return (
                <div className="clusterwrap" style={clusterWrapStyle}>
                    <div className="cluster"style={clusterStyle}>
                        {pointRows.map((rw,rwi) => 
                            <div className="cluster-row" key={rwi}> 
                                {rw.map((item,ci) => 
                                    <MapStationSquare key={ci} id={item.id} industryId={item.industryId} alertsx={item.alerts} lat={item.lat} lng={item.lng} asset={item} siteName={item.name} siteOutput={item.pw} stalex={item.stale} />
                                )}
                            </div>)
                        }
                    </div>
                    {points.length < 4?<div className="point-footer site-name">{this.printSiteNames(points)}</div>:null}
                    <div className="point-footer">{formatPower(totPower,null ,null )}</div>
                </div>
            );
        }
    }
}



const getStylePos = function(pos) {
    return {  left: pos[0] + 'px', top: pos[1] + 'px' }
}

const getRotation = function(index, total, pointR) {
    var extrarot = 0;
    if (total == 3) {
        extrarot = (Math.PI /2) * 3;
    } else if (total == 4) {
        extrarot = (Math.PI /4);
    }
    var deg = (index / total) * 360
    // Overlap a bit
    //if (total > 4) { pointR = Math.floor(pointR * 0.8); }
    //get radius of circle to wrap points around

    var clusterRadius = pointR / Math.cos(Math.PI * (total -2)/ (2* total) );
    var height = 2*(clusterRadius);
    var midpoint = clusterRadius;

    var rot = index * (2*Math.PI / total);
    var centerPoint = rotate(midpoint, midpoint, 0,midpoint, rot + extrarot);
    return [centerPoint[0], centerPoint[1], height];
}

const rotate = function(cx, cy, x, y, radians) {
    var cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx, ny];
}