import React, { Component } from 'react';
import { ApiGet } from '../../../api'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class DropFolderParserLookup extends Component {
    mdRefCallback = (instance) => {
        this.caseMD = instance ? instance.getWrappedInstance() : null;
    }

    constructor(props) {
        super(props);

        // this.state = {stationList: [],}
    }

    componentDidMount() {
        // this.getStationList()
    }

    getStationList(){
        ApiGet('DropFolderParser', 'GetStationList')
            .then((data) => {
                // console.log('data', data)
                // this.setState({ stationList: data.stationsInfo })
            })
    }

    render() {
        // var lists = {
        //     stations: this.state.stationList
        // }

        var lookUpFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Mapped Station Name', fieldId: 'name', display: 'form', width: 500 , validation: 'required' },
            { label: 'Station', fieldId: 'stationId', display: 'form', width: 500, list: listRequest.windsites, validation: 'requiredList' },
        ];

        var lookUpConfig = {
            model: 'lookup Details',
            path: '/DropFolderParser/LookUps/',
            fields: lookUpFields,
            titleField: 'name',
            master: true,
            preData: { clientTemplateId: this.props.location.state.clientTemplateId },
            formActions: [
                { action: 'delete' },
                { action: 'back' },
                { action: 'save' }   
            ],
            apiPath: API_PATH
        }

        return (
            <div>
                <div>
                    <div className='titleBlock'>Import Selection</div>
                </div>

                <div>
                    <MasterDetail {...lookUpConfig} wrappedComponentRef={this.mdRefCallback} headerControl={null} />
                </div>
            </div>
        )
    }
}

export default (DropFolderParserLookup)