import React, { Component } from 'react'
import { Col } from 'reactstrap'
import { industryDefaultMetrics } from '../../../configuration/enums'
import { connect } from 'react-redux'
import MonitoringList from './MonitoringList'
import Collapsible from 'react-collapsible';

class MonitoringLists extends Component {
    
    constructor(props) {
        super(props);
        this.renderGroupedSites = this.renderGroupedSites.bind(this)
    }

    renderGroupedSites(id, title, sites, scada) {
        return (
        <Collapsible trigger={title} key={title} transitionTime={200} open={true}>
            <div className="row">
                <Col sm="12">
                    <MonitoringList sites={sites} industryId={id} />
                </Col>
            </div>
        </Collapsible>
        )
    }
    renderGroupedSites2(id, title, sites, scada) {
        return (
            <div className="row">
                <Col sm="12">
                    <MonitoringList title={title} sites={sites} industryId={id} />
                </Col>
            </div>
        )
    }


    render() {
        const { sites, scada } = this.props
        var vorder = [1,2,7,6,5];
        if (industryDefaultMetrics.viewOrder) {
            vorder = industryDefaultMetrics.viewOrder;
        }
        vorder = [1,2];
        return (
            <div className="accordionCards">
                {vorder.map((k) => {
                        const industry = sites[k]
                        if (industry) {
                            return this.renderGroupedSites2(k, industry.name, industry.items, scada)
                        }
                    }
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    // Dictionary of stationId { ...asset, siteMetric[umet], alerts[] }
    //const newstations = GetStationsState(state)
    //return {stations: newstations, scada: state.common.scada }
    //state.common.header
    return {scada: state.common.scada }
}

export default connect(
    mapStateToProps
)(MonitoringLists)