import React, { Component } from 'react'
import { Col, Row, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { ApiPost, getConfigItem } from '../../../api'
import moment from 'moment'
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu"

class NonEventModal extends Component {

	constructor(props) {
        super(props);
        var jobInfoEnabled = getConfigItem('$.general.viewJobInfo') || 'true'
        this.state = {
            jobStatus: props.job.jobStatus,
            deviceId: props.job.deviceId ? props.job.deviceId : props.preRec && props.preRec.deviceId ? props.preRec.deviceId : null,
            dispatchNote: props.job.note || '',
            workCarriedOut: props.job.workCarriedOut || '',
            eventStartTime: props.job.startTimestamp ? moment(props.job.startTimestamp).format('HH:mm') : '09:00',
            eventEndTime: props.job.endTimestamp ? moment(props.job.endTimestamp).format('HH:mm') : '17:00',
            fsrStatus: props.job.fsrStatus || 1,
            dueBy: props.job.dueBy,
            users: props.job.users,
            jobInfoEnabled,
            sites: props.customerWithSites,
            calEventType: props.job.calEventType,
            scheduledTime: props.job.scheduledTime,
        }
    }

    componentDidMount() {

    }

    setTime(type, v) {
        var value = v.target.value
        if (this.state[type] != null && value != this.state[type]) {
            v.currentTarget.blur()
        }
        this.setState({ [type]: value })
    }

    saveAction() {
        console.log('modal save action', this.state)
        var saveType = 'other'
        this.props.saveEvent(saveType, { ...this.props.job, newEventType: this.state.newEventType, calEventType: this.state.calEventType, deviceId: this.state.deviceId, jobStatus: this.state.jobStatus, note: this.state.dispatchNote, eWorkCard: this.state.eWorkCard, eventEndTime: this.state.eventEndTime, eventStartTime: this.state.eventStartTime, workCarriedOut: this.state.workCarriedOut, fsrStatus: this.state.fsrStatus, dueBy: this.state.dueBy, users: this.state.users, scheduledTime: this.state.scheduledTime })
        this.props.cancel(this.props.job.calId)
    }

    getMenuButton(job) {
        var colId = parseInt(this.props.colId)

        if (job.users.length < 2 && job.usrsInfo.length > 0 && (job.usrsInfo[0].onSiteTime == null)) {
            return (<Button onClick={() => this.props.removeEvent(job.calId, colId)}>Delete</Button>)
        }
        else if (job.users.length > 1 && (job.usrsInfo.every(x => x.onSiteTime == null))) {
            return (
                <Menu menuButton={<MenuButton className='btn'>Delete</MenuButton>} >
                    <MenuItem onClick={() => this.props.removeEvent(job.calId)}>for all users</MenuItem>
                    <MenuItem onClick={() => this.props.removeEvent(job.calId, colId)}>only this user</MenuItem>
                </Menu>)
        }
        else {
            return null
        }
    }

    render() {
        const { job, calEventTypes } = this.props

        return (
            <Modal className='calendarModal' isOpen={true} autoFocus={false} backdrop={true} toggle={() => this.props.cancel(job.calId)}>
                <ModalHeader>
                    Adhoc Event
                </ModalHeader>
                <ModalBody>
                    <Row style={{ marginTop: '-10px' }}>
                        <Col xs={12}><span>Select Event Type</span></Col>
                    </Row>
                    <Row>
                        <Col xs={12}><IdNameDropdown options={calEventTypes.filter(x => x.id != 1)} zeroText='Select Event Type' style={{ height: '100%', padding: '0.2rem' }} valueField='id' displayField='name' defaultValue={this.state.calEventType} onChange={(e) => this.setState({ calEventType: e })} /></Col>
                    </Row>
                    <Row>
                        <Col xs={12}><span>Scheduled Time</span><input style={{ lineHeight: '16px', marginLeft: '5px' }} type='checkbox' defaultChecked={this.state.scheduledTime} onClick={() => { this.setState({ scheduledTime: !this.state.scheduledTime }) }} /></Col>
                    </Row>
                    {this.state.scheduledTime && <Row>
                        <Col xs={12}>
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <input type='time' className='form-control' defaultValue={this.state.eventStartTime} style={{ padding: '0.25rem' }} onChange={(e) => this.setTime('eventStartTime', e)} />
                                <span>to</span>
                                <input type='time' className='form-control' defaultValue={this.state.eventEndTime} style={{ padding: '0.25rem' }} onChange={(e) => this.setTime('eventEndTime', e)} />
                            </div>
                        </Col>
                    </Row>}
                    <Row>
                        <Col xs={12}><span>Note</span></Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <textarea className='form-control' rows='3' defaultValue={this.state.dispatchNote} style={{ padding: '0.25rem' }} onChange={(e) => this.setState({ dispatchNote: e.target.value })} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => this.props.cancel(job.calId)}>Cancel</Button>
                    {job.calId != 9999 && this.getMenuButton(job)}
                    <Button color='primary' style={{ marginLeft: 'auto' }} onClick={() => this.saveAction()}>Save</Button>
                </ModalFooter>
            </Modal>
			)
	}
}

export default NonEventModal
