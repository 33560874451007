import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { Button } from 'reactstrap'
import GroupSelector from './GroupSelector'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import TemplateBuilder from './TemplateBuilder'
export const API_PATH = process.env.SERVER_PATH + '/api'

class FormPageSetup extends Component {

    constructor(props) {
        super(props);
        this.state = { activeTab: '1', mainActiveTab: '1', aTbls: null, aTblFields: null, templateConfig: null, fId: 1 };
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    parseMDData(e) {
        if (e.data.templateConfig) {
            var tC = e.data.templateConfig
            if (tC != this.state.templateConfig) {
                this.setState({ templateConfig: tC })
            }
        }
    }

    getDetail() {

        var FormFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Name", fieldId: "name", inlineEdit: true },
            //{ label: "Description", fieldId: "desc", inlineEdit: true  },
            //{ label: "Group", fieldId: "groupId", list: listRequest.FormEntityGroups },
            { label: '', fieldId: "pageId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/FormBuilder/' } },
        ];

        var FormsConfig = {
            model: 'PageForms',
            path: '/FormPageSetup/PageForms/',
            crudParams: { pageId: this.props.match.params.id },
            fields: FormFields,
            titleField: 'name',
            addInline: true,
            defaultSorted: [{ id: 'name', desc: true }],
            gridProps: { defaultPageSize: 50 },
            apiPath: API_PATH
        }

        return (
            <div>
                <Nav tabs >
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Forms</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Page Template</NavLink>
                    </NavItem>
                </Nav>
                <TabContent key='clientTabs' activeTab={this.state.mainActiveTab}>
                    <TabPane tabId='1'>
                        {this.state.mainActiveTab == '1' && <MasterDetail key='mdcs' {...FormsConfig} />}
                    </TabPane>
                    <TabPane tabId='2'>
                        {this.state.mainActiveTab == '2' && <TemplateBuilder config={this.state.templateConfig} />}
                    </TabPane>
                </TabContent>
            </div>
            )
    }

    render() {

        var PageFields = [
            { label: "Id", fieldId: "id", display:'none' },
            { label: "Page Name", fieldId: "name", inlineEdit: true },
            { label: "", fieldId: "groupId", type: 'custom', display: 'form', custom: (r) => <GroupSelector {...r} gType={3} /> },
            { label: "", fieldId: "groupList", display: 'none' },
            //{ label: "Nav Section", fieldId: "navSection", list: listRequest.FormNavSections, display: 'form' },
            //{ label: "Form Name", fieldId: "formName", display: 'form' },
            //{ label: '', fieldId: "funcButton", type: 'funcButton', display: 'form', displayIf: (rec) => this.props.match.params.id != 'New', typeProps: { label: 'View Form', func: (rec) => { return this.props.history.push('/FormBuilder/' + rec.formId, { history: this.props.history.location.state.history }) } } },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/FormPageSetup/' } },
            { label: "", fieldId: "funcButton", type: 'funcButton', display: 'form', width: 70, typeProps: { label: 'View Page', func: (rec) => { return this.props.history.push('/FormPage/' + rec.id, { history: null }) } } }
        ];

        var PageConfig = {
            model: 'FormPageSetup',
            fields: PageFields,
            titleField: 'name',
            master: true,
            mdData: (e) => this.parseMDData(e),
            gridProps: { defaultPageSize: 50 },
            defaultSorted: [{ id: 'name', desc: false }],
            addInline: true,
            apiPath: API_PATH
        }

        var FormFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Name", fieldId: "name", inlineEdit: true },
            //{ label: "Description", fieldId: "desc", inlineEdit: true  },
            //{ label: "Group", fieldId: "groupId", list: listRequest.FormEntityGroups },
            { label: '', fieldId: "pageId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/FormBuilder/' } }
        ];

        var FormsConfig = {
            model: 'PageForms',
            path: '/FormPageSetup/PageForms/',
            crudParams: { pageId: this.props.match.params.id },
            fields: FormFields,
            titleField: 'name',
            addInline: true,
            defaultSorted: [{ id: 'name', desc: false }],
            gridProps: { defaultPageSize: 50 },
            apiPath: API_PATH
        }
        return (
            <div>
                <div className="mr-auto p-2">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}><div className='titleBlock'>App Builder</div><Button onClick={() => this.props.history.push('/FormLookup')}>Manage Lookups</Button></div>
                    {this.props.match.params.id == undefined && <div className='notifInfo'>
                        <span>
                            Create a new section of the Portal to store data specific to your needs. Build upon exisiting database entities or create a section from scratch.
                            To begin, add a new page below to create your new section's homepage and navigation option.
                        </span>
                    </div>}
                </div>
                {this.props.match.params.id == undefined ? <div>
                    <Nav tabs >
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Forms</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Pages</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent key='clientTabs' activeTab={this.state.mainActiveTab}>
                        <TabPane tabId='1'>
                            {this.state.mainActiveTab == '1' && <MasterDetail key={'m' + this.state.fId} {...FormsConfig} />}
                        </TabPane>
                        <TabPane tabId='2'>
                            {this.state.mainActiveTab == '2' && <MasterDetail key='mdcs' {...PageConfig} />}
                        </TabPane>
                    </TabContent>
                </div> : <MasterDetail key='mdcs' {...PageConfig} />}
            </div >
            )
    }
}

export default (FormPageSetup)