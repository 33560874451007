import React, { Component } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import IdNameDropdown from '../../../components/Controls/IdNameDropdown';
import CheckBoxList from '../../../components/Controls/CheckBoxList';
import { ApiPost } from '../../../api';
import MasterDetail from 'MasterDetail'
import CheckBox from './../../../components/Controls/CheckBox'
const SERVER_PATH = process.env.SERVER_PATH;
import Collapsible from 'react-collapsible';
export const API_PATH = process.env.SERVER_PATH + '/api'

class DocumentRepo extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.OnSiteChange.bind(this);
        this.handleChange = this.OnYearChange.bind(this);
        this.state = {
            CompanyData: [], companyValue: 0, companyName: '',
            DocumentType: [], DocumentValue: 0, DocumentName: '', DocumentData: null,
            Sites: [], SelectedSites: null, checked: false,
            Years: [], SelectedYears: null, checked: false,
            DefaultIds: [], DefaultSiteIds: null, DefaultYearIds: null,
            FilesData: null,
            isLoading: true,
            fileDetails: null,
            fileId: '', fileName: '', accessToken: '', filePath: '', fileData: '', NoSite: true,
        };

    }

    componentDidMount() {

        this.OnLoad(event)
    }

    OnLoad(event) {

        this.setState({ companyName: event })

        ApiPost('DocRepo', 'GetDriveCompanies', { CompanyData: this.state.CompanyData })
			.then((data) => {
				if (data.length > 0) {
					this.setState({ CompanyData: data, isLoading: false }, () => { this.OnCompanyChange(data[0].name) })
				}
            })
    }

    GetFiles() {

        var fileDetails = { companyName: this.state.companyName, companyName2: this.state.companyName, DocumentName: this.state.DocumentName, selectedSites_local: JSON.stringify(this.refs.SiteCheckList.getSelectedIds()), selectedYears_local: JSON.stringify(this.refs.YearCheckList.getSelectedIds()) }
        this.setState({ fileDetails, isLoading: false });

        ApiPost('DocRepo', 'GetDriveFiles', fileDetails)
            .then((data) => {
                this.setState({ FilesData: data, isLoading: false }
                );
            })
    }

    OnCompanyChange(SelectedCompanyName) {
        console.log("SelectedCompanyName is ", SelectedCompanyName);
        this.setState({ isLoading: true, Years: [], companyName: SelectedCompanyName }, () => { this.GetCategoriesFromSelectedCompany() })
    }

    GetCategoriesFromSelectedCompany() {

        ApiPost('DocRepo', 'GetCategoriesFromSelectedCompany', { companyName: this.state.companyName })
            .then((data) => {
                this.setState({ DocumentType: data }, () => { this.GetSitesFromSelectedCompany() }
                );
            })
    }

    GetSitesFromSelectedCompany() {

        ApiPost('DocRepo', 'GetSitesFromSelectedCompany', { companyName: this.state.companyName })
            .then((data) => {

                //var sitesFromServer = [{id: "No Sites", name: "No Sites"}];
                //var defaultSitesFromServer = ["No Sites"];

                var sitesFromServer = [{ id: "Group Documents", name: "Group Documents" }];
                var defaultSitesFromServer = ["Group Documents"];

                Object.keys(data).map((x) => {
                    sitesFromServer.push({ id: data[x], name: data[x] })
                    defaultSitesFromServer.push(data[x])

                })

                console.log('defaultSitesFromServer', defaultSitesFromServer);
                console.log('sitesFromServer', sitesFromServer);

                this.refs.SiteCheckList.setSelectedIds(defaultSitesFromServer);

                this.setState({ Sites: sitesFromServer }, () => { this.GetYearsFromSelectedCompany() });
            })
    } 

    GetYearsFromSelectedCompany() {

        ApiPost('DocRepo', 'GetYearsFromSelectedCompany', { companyName: this.state.companyName, DocumentName: this.state.DocumentName, selectedSites: this.refs.SiteCheckList.getSelectedIds() })
            .then((data) => {

                var yearsFromServer = [];
                var defaultYearsFromServer = [];

                Object.keys(data).map((x) => {
                    yearsFromServer.push({ id: data[x], name: data[x] })
                    defaultYearsFromServer.push(data[x])
                })

                this.refs.YearCheckList.setSelectedIds(defaultYearsFromServer);

                this.setState({ Years: yearsFromServer }, () => { this.GetFiles() });
            })
    } 

    OnYearChange(SelectedCheckBoxIds, Id, current_Status) {

        this.setState({ isLoading: true, SelectedYears: SelectedCheckBoxIds }, () => this.GetFiles())
    }    

    OnSiteChange(SelectedCheckBoxIds, Id, current_Status) {

        this.setState({ isLoading: true, SelectedSites: SelectedCheckBoxIds }, () => this.GetYearsFromSelectedCompany())
    } 

    OnDocumentChange(SelectedDocumentName) {

        this.setState({ isLoading: true, DocumentName: SelectedDocumentName }, () => { this.GetYearsFromSelectedCompany() })
    }       

    FileDownload(rec) {

        this.setState({ fileId: rec.id, fileName: rec.name, filePath: rec.webContentLink }, () => this.ReadFile());
    }

    ReadFile() {

        var data = new FormData();        
        var dataObj = { filePath: this.state.filePath };        
        for (var key in dataObj) {
            data.append(key, dataObj[key]);
        }
        fetch(SERVER_PATH + '/' + "DocRepo" + "/" + "GetFileData", { method: "POST", body: data, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }})
            .then(function (response) {
                return response.blob();
            }).then((data) => {
                data.lastModifiedDate = new Date();
                data.name = this.state.fileName;
                this.setState({ fileData: data }, () => this.DownLoadFile());
            });        
    }

    DownLoadFile() {     

        var a = window.document.createElement("a");
        var data = new Blob([this.state.fileData], { type: "application/octet-stream" });
        a.href = window.URL.createObjectURL(data);
        a.download = this.state.fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    FileEdit(rec) {
        console.log('file rec ', rec)
        drive = build('drive', 'v2', http)

    }

    NoSiteChange() {
        this.setState({ NoSite : false})
    }

    render() {

        //var fileDetails = { companyName: this.state.companyName, selectedSites_local: JSON.stringify(this.refs.SiteCheckList.getSelectedIds()), selectedYears_local: JSON.stringify(this.state.SelectedYears) }

        var DriveSelectionFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Link", fieldId: "webContentLink", display: 'none' }, 
            { label: "Type", fieldId: "type", width: 175 },
            { label: "File Name", fieldId: "name", width: 175 },  
            { label: "Asset", fieldId: "site", width: 170 },
            { label: "Year", fieldId: "year", width: 40 },
            { label: "Uploaded", fieldId: "createdTime", width: 80 },
            //{ label: "Edit", fieldId: "funcButton", display: 'grid', type: 'funcButton', typeProps: { label: <i className="fas fa-edit" />, func: (rec) => this.FileEdit(rec) } },
            { label: "", fieldId: "funcButton", display: 'grid', width: 40, type: 'funcButton', typeProps: { label: <i className="fa fa-download" />, func: (rec) => this.FileDownload(rec) } },

        ];

        var DriveSelectionConfig = {
            model: 'DocRepo',
            path: '/DocRepo/GetDriveFiles',
            fields: DriveSelectionFields,
            gridButton: false,
            crudParams: this.state.fileDetails,
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH
        }   

        return (
            <div>
                <Row border="1" style={{ backgroundColor: 'rgba(247,247,247,1)', border: 'thin solid rgba(26, 27, 27, 0.32)', borderSpacing: '15px', padding: '4px', marginLeft: '1px', marginRight: '1px' }}>
                    <Col lg={1} style={{ marginTop: '6px' }}>
                        Company
                    </Col>
                    <Col lg={3}>
                        <IdNameDropdown options={this.state.CompanyData} valueField='name' displayField='name' value={this.state.companyName} onChange={(SelectedCompanyName) => this.OnCompanyChange(SelectedCompanyName)} />
                    </Col>
                    <Col lg={2}>
                    </Col>
                    <Col lg={2} style={{ marginTop: '6px', textAlign: 'right' }}>
                        DocumentType
                    </Col>
                    <Col lg={3} style={{ marginLeft: '1px' }}>
                        <IdNameDropdown options={this.state.DocumentType} valueField='name' displayField='name' value={this.state.DocumentName} zeroText='All Types' onChange={(SelectedDocumentName) => this.OnDocumentChange(SelectedDocumentName)} />
                    </Col>
                    <Col lg={2}>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <div style={{ marginTop: '10px' }}>
                            <div className="card">
                                <div className="card-header">Years</div>
                                <div className="card-body">
                                    {console.log("this.state.Years.length", this.state.Years.length)}
                                    <CheckBoxList showSelectAll={this.state.Years.length > 0} options={this.state.Years} innerStyle={{ paddingTop: '10px' }} ref='YearCheckList' onChange={(SelectedCheckBoxIds) => this.OnYearChange(SelectedCheckBoxIds)} />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <div className="card">
                                <div className="card-header">Assets</div>
                                <div className="card-body"> 
                                    <CheckBoxList showSelectAll={true} options={this.state.Sites} innerStyle={{ paddingTop: '10px' }} ref='SiteCheckList' onChange={(SelectedCheckBoxIds) => this.OnSiteChange(SelectedCheckBoxIds)} />
                                </div>
                            </div>
                        </div>
                    </Col>

                    {this.state.isLoading ?
                        <div style={{ marginLeft: '170px', marginTop: '100px' }}><i className="fa fa-spinner fa-spin" style={{ fontSize: '2em', color: 'grey' }} /></div>
                        : this.state.fileDetails ? <Col lg={9} style={{ marginTop: '10px' }}>
                            <MasterDetail key='mdwc' {...DriveSelectionConfig} />
                        </Col> : null
                    }
                </Row>
            </div>

        )

    }
}

export default (DocumentRepo)


