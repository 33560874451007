import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class ControllerSettings extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        var ControllerFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Controller Name", fieldId: "name", canFilter: true },
            { label: "Manufacturer", fieldId: "mfacId", list: listRequest.TurbineManufacturers, canFilter: true },
            { label: "Industry", fieldId: "industryId", list: listRequest.Industries, canFilter: true },
            { label: 'Default Metrics', fieldId: 'defaultMetrics', display: 'form', type: 'checkList', listSource: '/ControllerSettings/GetMetrics' },
            { label: 'Supported Commands', fieldId: 'supportedCommands', display: 'form', type: 'checkList', listSource: '/ControllerSettings/GetSupportedCommands' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' }
        ];

        var ControllerConfig = {
            model: 'ControllerSettings',
            fields: ControllerFields,
            master: true,
            titleField: 'name',
            hideDelete: true,
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        var FaultTagFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Tag", fieldId: "tagName", canFilter: true, width: 100 },
            { label: "Fault Message", fieldId: "cntrLogMessage", canFilter: true },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/FaultTagSettings/' } },
        ];

        var FaultTagConfig = {
            model: 'FaultTagSettings',
            fields: FaultTagFields,
            path: '/ControllerSettings/GetFaultTags/' + this.props.match.params.id,
            master: false,
            hideDelete: true,
            showFilterRow: true,
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Controller Settings</div>
                <MasterDetail key='mdcs' {...ControllerConfig}>
                    <MasterDetail key='mdcs' {...FaultTagConfig} />
                </MasterDetail>
            </div >
            );
    }
}

export default (ControllerSettings)