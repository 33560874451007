import React from 'react';

const SignalOverlay = (props) => {

    return (
        <div className='scadaSignal'>
            <i className='far fa-phone-laptop'/>
            <i className='fal fa-arrows-h' style={{ color: '#27c564', padding:'0 6px' }}  />
            <i className='far fa-server'/>
            <span className="fa-stack" style={{width:'32px',height:'1em',lineHeight:'1em', padding:'0 6px' }}><i className='fal fa-arrows-h fa-stack-1x' style={{ color: '#d0021b', fontSize: '32px' }} /><i className='fad fa-times-circle fa-stack-1x faRWS' style={{fontSize:'12px' }} /></span>
            <i className='far fa-router' />
            <p style={{fontSize:'1rem', textAlign:'center'}}>Poor connection to device</p>
        </div>
    );
};


export default SignalOverlay;
    