import React, { Component } from 'react';
import Logo from '../../content/izon-logo.png';
import { Container, Row, Col, Button } from 'reactstrap';

const Loader = (props) => {
    var height = '100vh';
    if (props.height) {
        height = props.height;
    }
    return (
        <div style={{ height }}>

            {props.reconnect ? <Button color="primary" style={{ position: 'relative', margin: '95px auto', display: 'block', cursor: 'pointer' }} onClick={() => props.toggleLive(true)} >Reconnect</Button> :

                <div className="kart-loader" style={{ top: '25%' }}>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                    <div className="sheath">
                        <div className="segment"></div>
                    </div>
                </div>

            }

                <div style={{display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '10%', left: 0, right: 0}}>
                {(props.customMessage) ? <h3>{props.customMessage}</h3> : <img className="loaderLogo" src={Logo} className='lf' height="50px"></img>} 
                </div>
                
            </div>
        );
}


export default Loader;