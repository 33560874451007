import React, { Component } from 'react'
import { GetStationsState } from '../../../api'
import { ToggleButton } from '../../../components/Controls/ToggleButton'
import { universalMetrics, universalState,industryDefaultMetrics } from '../../../configuration/enums'
import { connect } from 'react-redux'
import { updateLiveConnection, disconnectLive  } from '../../../actions/scada'
import {withRouter} from "react-router-dom";

class MonitoringTile extends Component {
    
    constructor(props) {
        super(props);
        this.liveToggle = this.liveToggle.bind(this);
    }

    liveToggle(IsOn) {
        var toState = IsOn;
        const { station } = this.props;
        let siteids = [];
        this.setState({ connected: toState })
        var prevArr = this.props.livesiteids?this.props.livesiteids:[];
        if (toState) {
            siteids = [].concat(prevArr,this.props.station.id);
        } else {
            siteids = this.delValue(prevArr, this.props.station.id)   
            //this.props.disconnectLive();
        }
        this.props.updateLiveConnection(siteids);
    }

    delValue(arr, val) {
        var i = arr.findIndex((v) => (v == val));
        return this.immutableDelete(arr, i);
    }

    immutableDelete (arr, index) {
        return arr.slice(0,index).concat(arr.slice(index+1))
    }

    isConnected() {
        var prevArr = this.props.livesiteids?this.props.livesiteids:[];
        var i = prevArr.findIndex((v) => (v == this.props.station.id));
        return (i>=0);
    }

    titleClick(stationid) {
        this.props.history.push('/Scada/' + stationid)
    }

    renderPowerBar(value, valuepercent, key, overclass, overpower) {
        return (
            <div className="bar-row power" key={key}>
                <div className="bar-wrap">
                    <div className="border"></div>
                    <div className={"box bind-power-percent bind-over-kw " + overclass} style={{width: valuepercent + '%'}}>
                        {(overclass)?"+ " +overpower.toFixed(1) + " Kw":""}
                    </div>
                </div>
                <div className="wind-area unit-area">
                    <span className="value bind-power">
                        {value}
                    </span>
                    <span className="unit power">kw</span>
                </div>
            </div>
        )
    }

    renderBar(connected, noCommsMsg, value, valuepercent, units, key, barName) {
        return ((connected && value != undefined)?
            <div className="bar-row wind"  key={key}>
                <div className="bar-wrap if-comms">
                    <div className="border"></div>
                    <div className="box wind bind-wind-speed-percent" style={{width: valuepercent + '%'}}>{barName}</div>
                </div>
                <div className="wind-area unit-area if-comms">
                    <span className="value bind-wind-speed">
                        {value}
                    </span>
                    <span className="wind unit" dangerouslySetInnerHTML={{__html: units}}></span>
                </div>
            </div>
            :
            <div className="bar-row wind" key={key}>
                <div className="unit-area if-no-comms" style={{marginLeft: 15}}>
                    <i className="fa fa-chain-broken"></i>
                    <span className="wind unit">{noCommsMsg}</span>
                </div>
            </div>
        )
    }

    getMetricDetail = (o, id) => {
        const ind = o.industryId
        const info = industryDefaultMetrics.renderDetails[id]
        if (o.metrics && info) {
            var max = info.max
            const value = o.metrics[id]
            if (value != undefined) {
                if (!Number.isInteger(info.max)) {
                    max = o[info.max]
                }
                var per = ((value / max) * 100)
                var overclass = ""
                if (per < 0) per = 0
                if (per > 100) {
                    if (per > 102) {
                        overclass = "over"
                    }
                    per = 100
                }
                
                var ret = {
                    v: value.toFixed(info.decimalPlaces),
                    max: max,
                    renderer: info.renderer,
                    units: info.unit,
                    title: info.title,
                    per,
                    overclass: overclass
                }
                return ret;
            }
        } 
    }
    renderMetricCell(metricid, siteinfo) {
        const valueinfo = this.getMetricDetail(siteinfo,metricid);
        if (valueinfo == undefined) {
            return (<div key ={metricid} className="metric"></div>)
        }
        else if (valueinfo.renderer == 'bar') {
            var per =  ((valueinfo.v / valueinfo.max) * 100);
            if (per > 100) { per = 100 }
            return (metricid == 11) ?
                this.renderPowerBar(valueinfo.v,valueinfo.per,metricid,valueinfo.overclass, (valueinfo.v - valueinfo.max))
                :this.renderBar(true,"No Comms",valueinfo.v,per,valueinfo.units, metricid, valueinfo.title)
        } else if (valueinfo.renderer == 'value') {
            return (<div className="metric" key={metricid}>
                    {valueinfo.v}
            </div>)
        }
    }

    render () {
        
        const o = this.props.station
        const industryId = this.props.industryId
        //console.log("station", o)
        let alertDesc = ""
        let stateDesc = ""
        let powerPercent = 0
        let windSpeed = 0
        let windSpeedPer = 0
        let overpower = 0
        let overclass = ""
        let controllerComms = false
        let bestPower = 0
        let rocComms = false
        let rpm = 0
        let statusClass = ""
        let statusIcon = ""
        let hasController = false
        if (o.metrics && o.metrics[universalMetrics.ControllerComms]) {
            controllerComms = o.metrics[universalMetrics.ControllerComms] == 1
            hasController = true
        }
        if (o.metrics && o.metrics[universalMetrics.RocComms]) {
            rocComms = o.metrics[universalMetrics.RocComms] == 1
        }
        statusIcon = "fa-warning"
        if (o.metrics == undefined) {
            alertDesc = "Connecting..."
            statusClass = "connecting"
            statusIcon = "connectSpinner";
        }
        else if (!controllerComms && !rocComms && o.industryId == 1 && o.metrics[universalMetrics.UniversalState] != 15) {
            alertDesc = "Power Cut"
            statusClass = "alerting"
        }
        if (o.stale) {
            alertDesc = "Comms Issue"
            statusClass = "alerting"
            statusIcon = "fa-chain-broken"
        }
        if (o.alerts && o.alerts.length > 0) {
            alertDesc = o.alerts[0].cntrLogMessage || o.alerts[0].alertName
            if (!alertDesc) {
                alertDesc = alert.alertName;
            }
            statusClass = "alerting"
            if (o.alerts[0].alertTypeId >= 50) {
                statusClass = "event"
                statusIcon = "fa-clock-o"
            }
        }
        if (o.metrics) {
            bestPower = o.metrics[universalMetrics.RocPower]
            // Use Roc Over Controller
            if (bestPower == undefined && o.metrics[universalMetrics.ControllerPower] != undefined) {
                bestPower = o.metrics[universalMetrics.ControllerPower]
            }
        }
        powerPercent = (bestPower / o.maxkw) * 100
        if (o.metrics && o.metrics[universalMetrics.WindSpeed] != undefined) {
            windSpeed = o.metrics[universalMetrics.WindSpeed]
            windSpeedPer = (windSpeed / 20) * 100
            if (windSpeedPer > 100) {
                windSpeedPer = 100
            }
        }
        if (powerPercent < 0) powerPercent = 0
        if (powerPercent > 100) {
            overpower = bestPower - o.maxkw
            if (powerPercent > 102) {
                overclass = "over"
            }
            powerPercent = 100
        }
        if (o.metrics && o.metrics[universalMetrics.RPM] != undefined) {
            rpm = o.metrics[universalMetrics.RPM]
        }
        stateDesc = "No Controller Comms"
        if (o.metrics && (controllerComms || (!hasController && rocComms)) && o.metrics[universalMetrics.UniversalState] != undefined) {
            stateDesc = universalState[o.metrics[universalMetrics.UniversalState]]
            if (stateDesc == undefined) {
                stateDesc = bestPower > 0 ? "Production" : "Freewheeling"
            }
        }
        else if (o.metrics && (controllerComms || (!hasController && rocComms)) && o.metrics[universalMetrics.UniversalState] == undefined && industryId == 1) {
            stateDesc = bestPower > 0 ? "Production" : "Freewheeling"
        }
        else if (o.metrics == null) {
            alertDesc = ""
            //statusClass = ""
            statusIcon = ""
            stateDesc = "External Monitoring"
        }

        let dur = 0
        if (rpm > 0) {
            dur = (40 / rpm)
        } else if (o.maxkw > 0 && bestPower> 0) {
            if ((o.maxkw / bestPower) > 0) {
                dur = (o.maxkw / bestPower)
            }
        } else if (bestPower > 0) {
            dur = 250 / bestPower
        } else {
            dur = 0
        }

        var cRpmClass = "speed"
        if (dur <= 0) cRpmClass += "0"
        else if (dur < 1.3) cRpmClass += "1-0"
        else if (dur < 1.6) cRpmClass += "1-3"
        else if (dur < 2) cRpmClass += "1-6"
        else if (dur < 2.5) cRpmClass += "2-0"
        else if (dur < 3) cRpmClass += "2-5"
        else if (dur < 4) cRpmClass += "3-0"
        else cRpmClass += "5-0"

        if (alertDesc && o.scheduled == false) {
            stateDesc = alertDesc
            if (o.alerts) {
                if (o.alerts[0].caseRef && o.alerts[0].caseRef != alertDesc) {
                    alertDesc = o.alerts[0].caseRef + " - " + alertDesc
                }
            }
        }
        let iconfile = "/Content/images/blades2.png"
        if (industryId > 1) {
            iconfile = "/Content/images/spiral2.png"
            if (o.metrics) {
                stateDesc = bestPower > 0 ? "Production" : "Stopped"
            }
        }
        const outerClass = "ctrl-box-animation station-" + o.d
        return (
            <li key={o.id} className={outerClass} data-station-id={o.assetRef}>
                <div className={"controller " + statusClass} onClick={() => this.titleClick(o.id)}>
                    <div className="controller-box error on">
                        <div className="head">
                            <h2>
                                <ToggleButton smallMode={true} textOn="" iconOn="fa fa-wifi blink" ison={this.isConnected()} textOff="" iconOff="fa fa-clock-o" onToggle={this.liveToggle} />
                                
                                 {o.assetRef} {o.name}
                            </h2>
                            <div className="rotor-wrap pull-right">
                                <span className="rpm-wrapper">
                                    <img src={iconfile} alt="" className={"rotor-icon " + cRpmClass}/>
                                </span>
                            </div>
                        </div>
                        <div className="content">
                            <div style={{zIndex: 1000, position: "relative"}}>
                                <div style={{display:"flex",zIndex:2000}}>
                                    <div className="flex-fill"><span className="value bind-status-msg">{stateDesc}</span></div>
                                    <div className="curve-back"></div>
                                </div>
                            </div>
                            {industryDefaultMetrics.views[industryId].metrics.map((k,i) => { 
                                            return this.renderMetricCell(k,o,industryDefaultMetrics.renderDetails[k])
                                        })
                                    }
    
    
                            <div className="overlay active">
                                <div className="bg"></div>
                                <div className="overlay-content">
                                    {
                                        (statusIcon.indexOf("fa-") < 0)?
                                        <div className={statusIcon}></div>:
                                        <i className={"fa " + statusIcon + " fa-2x"}></i>
                                    }
                                    <h4 className="error">
                                        <span className="bind-error-msg">{alertDesc}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </li>
        )
    }
}

const mapStateToProps = state => {
    // Dictionary of stationId { ...asset, siteMetric[umet], alerts[] }
    return { stations: GetStationsState(state), scada: state.common.scada }
    //state.common.header
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateLiveConnection: (siteList) => {
            dispatch(updateLiveConnection(siteList));
        },
        disconnectLive: () => {
            dispatch(disconnectLive());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MonitoringTile))