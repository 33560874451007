import React, { Component } from 'react';
import { connect } from 'react-redux';
import { universalMetrics,industryDefaultMetrics } from '../../../configuration/enums'
import { GetStationsStateIndustry } from '../../../api';
import moment from 'moment'

class MonitoringList extends Component {

    constructor(props) {
        super(props);
        this.state = { issueList: {}, pendingIssueList: {}, exclusionList: {}}
        this.getClass = this.getClass.bind(this);
    }


    componentDidUpdate() {
        this.cleanLists()
    }

    cleanLists() {
        var eList = this.state.exclusionList

        Object.keys(eList).map(x => {
            if (moment.duration(moment().diff(eList[x])).asMinutes() > 2) {
                delete eList[x]
                this.setState({ exclusionList: eList })
            }
        })
    }

    getSitesIndustryArray() {
        let ss = GetStationsStateIndustry(this.props.commonstate);
        return (!ss)?[]:ss;
    }
    
    getClass(stm) {
        const { pw, alerts, scheduled, stale } = stm;

        var showRow = false
        var classfa = <i className='fa fa-check'/>;
        if (pw > 0) {
            classfa = <i className='fa fa-check success' title='Production' />;
        }
        if (pw <= 0.5) {
            classfa = <i className='fa fa-check disabled'  title='OK. No Production' />;
        }
        if (stm.metrics) {
            const ustate = stm.metrics[universalMetrics.UniversalState];
            if (ustate != undefined) {
                if(ustate == 20) {
                    classfa = <i className='fa fa-pause info' title='Paused' />;
                    showRow = true
                } else if (ustate == 21) {
                    classfa = <i className='fa fa-undo' title='Untwisting' />;
                    showRow = true
                } else if (ustate == 19) {
                    classfa = <i className='fa fa-pause danger' title='Emergency Stop' />;
                    showRow = true
                } 
            } 
            const comms = stm.metrics[universalMetrics.ControllerComms];
            if (comms != undefined && comms != 1) {
                classfa = <i className='fad fa-eye-slash' title='No Controller Comms' />;
                showRow = true
            } 
        }
        if (stale) {
            classfa = <i className='fa fa-signal' title='Lost Signal to Site' />;
            showRow = true
        }
        if (alerts && alerts.length > 0) {
            classfa = <i className='fa fa-exclamation-triangle danger' title='Alerted' />;
            showRow = true

            alerts[0]

            if (alerts[0].alertTypeId == 32) {
                classfa = <i className='fa fa-truck danger' title='No Biomethane' />;
                showRow = true
            } else if (alerts[0].alertTypeId == 33) { 
                classfa = <i className='fa fa-truck warning' title='Low Biomethane' />;
                showRow = true
            }
            
        }
        if (scheduled) {
            classfa = <i className='fa fa-clock-o info' title='Scheduled Site Visit' />;
            showRow = true
        }

        return { icon: classfa, show: showRow };
    }

    getMetricDetail = (o, id) => {
        const ind = o.industryId
        const info = industryDefaultMetrics.renderDetails[id]
        const avgInfo = this.props.header.filterAverageMetric
        if (o.metrics) {
            const value = o.metrics[id]
            const err = (info.alert ? info.alert(value, o, avgInfo) : false);
            if (info && value != undefined) {
                var max = info.max
                if (!Number.isInteger(info.max)) {
                    max = o[info.max]
                }
                var ret = {
                    v: value.toFixed(info.decimalPlaces),
                    max: max,
                    renderer: info.renderer,
                    info: info,
                    err
                }
                return ret;
            } 
        } else {
            //console.log("Metric null");
        }
        return null;
    }

    renderMetricCell(metricid, siteinfo, showUnits) {
        const valueinfo = this.getMetricDetail(siteinfo,metricid);
        var classN = "metric"
        
        if (valueinfo == undefined) {
            return (<td key ={metricid} className={classN}>-</td>)
        } else {
            if (valueinfo.err) {
                classN += " error"
            }
            if (valueinfo.renderer == 'bar') {
                var bOffset = 0;
                if (valueinfo.info.barOffset) { 
                    bOffset = valueinfo.info.barOffset;
                }
                var a = parseFloat(valueinfo.v) + parseInt(bOffset)
                var b = parseFloat(valueinfo.max) + parseInt(bOffset) 
                var per =  (a/b) * 100;
                if (per > 100) { per = 100 }
                var barclass = "td-bar" + this.getWarnLevel(valueinfo.info.warnLevels, valueinfo.v);
                return (<td className={classN} key={metricid} style={siteinfo.stale ? { color: 'rgba(0,0,0,0.3)' } : {}}>
                    <div className={barclass} style={{width:per + "%"}}></div>
                    <div className="td-bar-label">
                        {valueinfo.v}
                        {(showUnits)?<span className="small" dangerouslySetInnerHTML={{__html: valueinfo.info.unit}} />:null}
                    </div>
                </td>)
            } else if (valueinfo.renderer == 'value') {
                return (<td className={classN} key={metricid} style={siteinfo.stale ? { color: 'rgba(0,0,0,0.3)' } : {}}>
                        {valueinfo.v}
                </td>)
            }
        }
    }

    getWarnLevel(levels, value) {
        if (!levels) return "";
        var ret = ""; var offset = 0;
        if (levels.length == 4) {
            offset = 2;
            if (levels[0] != null && value < levels[0]) {
                ret = " error";
            }
            if (levels[1] != null && value < levels[1]) {
                ret = " warn";
            }
        }
        if (levels.length == 2 || levels.length == 4) {
            if (levels[offset] != null && value >= levels[offset]) {
                ret = " warn";
            }
            if (levels[offset +1] != null && value >= levels[offset + 1]) {
                ret = " error";
            }
        }
        return ret;
    }

    decode(encodedStr) {
        var parser = new DOMParser;
        var dom = parser.parseFromString(
            '<!doctype html><body>' + encodedStr,
            'text/html');
        return dom.body.textContent;
    }

    logIssue(issueId) {
        if (this.state.pendingIssueList.hasOwnProperty(issueId) && !this.state.issueList.hasOwnProperty(issueId)) {
            var issueTime = this.state.pendingIssueList[issueId]
            if (moment.duration(moment().diff(issueTime)).asMinutes() > 1 && !this.state.exclusionList.hasOwnProperty(issueId)) {
                console.log('logIssue : ' + issueId, moment().format('HH:mm:ss'))
                var tList = this.state.issueList
                var pList = this.state.pendingIssueList
                tList[issueId] = moment()
                delete pList[issueId]
                this.setState({ issueList: tList, pendingIssueList: pList })
            }
        }
        else if (!this.state.issueList.hasOwnProperty(issueId)){
            var pList = this.state.pendingIssueList
            pList[issueId] = moment()
            this.setState({ pendingIssueList: pList })
        }
    }

    removeIssue(issueId) {
        if (this.state.issueList.hasOwnProperty(issueId)) {
            console.log('removeIssue', issueId)
            var tList = this.state.issueList
            var eList = this.state.exclusionList
            eList[issueId] = moment()
            delete tList[issueId]
            this.setState({ issueList: tList, exclusionList: eList })
        }
    }

    render() {
        const { sites, industryId, title  }= this.props;
        const unitsInCell = industryDefaultMetrics.views[industryId].unitsInCell;
        const tableClass = "metricTable " + (unitsInCell ? "unitsInCell" : "") + " stripped-row";
        return (
            <div style={{padding:'0', width:'100%'}} className="">
                <table style={{width: 'calc(100% - 40px)'}} className={tableClass}>
                    <thead>
                        <tr>
                            <th className="title">{title}</th>
                            <th className="rotate"><div><span>Status</span></div></th>
                            {industryDefaultMetrics.views[industryId].metrics.map((k,i) => { 
                                    var m = industryDefaultMetrics.renderDetails[k]
                                    return <th className="rotate" key={k}><div><span>{m.title} <span className="small" dangerouslySetInnerHTML={{__html: unitsInCell?"":m.unit}} /></span></div></th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sites.map((stm) => {
                                var r = this.getClass(stm)
                                var renderR = true
                                if (stm.metrics) {
                                    renderR = false
                                    industryDefaultMetrics.views[industryId].metrics.map((k, i) => {
                                        const info = industryDefaultMetrics.renderDetails[k]
                                        const value = stm.metrics[k] || 0
                                        const avgInfo = this.props.header.filterAverageMetric
                                        if (r.show) {
                                            renderR = true
                                        }
                                        if (info.alert && info.alert(value, stm, avgInfo)) {
                                            this.logIssue(stm.id + '-' + k)
                                        }
                                        else {
                                            this.removeIssue(stm.id + '-' + k)
                                        }
                                        if (this.state.issueList.hasOwnProperty(stm.id + '-' + k) && !renderR) {
                                            r.icon = <i className='fa fa-question danger' title={'Potential Issue (' + Math.round(moment.duration(moment().diff(this.state.issueList[stm.id])).asMinutes(), 0) + ' mins ago)'}/>
                                            renderR = true
                                        }
                                    })
                                }
                                if (!this.props.onlyIssues || (this.props.onlyIssues && renderR)) {
                                    return (
                                        <tr key={stm.id} >
                                            <td>{stm.name} <span className="small" style={{ color: "#999" }}>{stm.modelName}</span></td>
                                            <td className="metric">{r.icon}</td>
                                            {industryDefaultMetrics.views[industryId].metrics.map((k, i) => {
                                                return this.renderMetricCell(k, stm, unitsInCell)
                                            })}
                                        </tr>)
                                }
                            })
                        }
                        
                    </tbody>
                </table>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(MonitoringList)
