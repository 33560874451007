import React, { Component } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { ApiPost } from '../../../api'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { withRouter } from 'react-router-dom'

class GroupSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { groupList: null, selGroup: null, groupName: null, id: null, type: 'Group' }
    }

    setGroup(id) {
        console.log('setGroup', this.props)
        this.setState({ selGroup: id })
        if (id != '-') {
            var newGId = id.toString()
            console.log('newGId', newGId)
            this.props.onChange(newGId)
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.setup(this.props)
        }
    }

    setup(p) {
        this.setState({ groupList: p.data.groupList, id: p.gType == 2 ? p.data.id : p.data.formEntityId })
        if (p.gType == 3) {
            this.setState({type:'Nav Section'})
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.data && this.props.data && nextProps.data.id != this.props.data.id) {
            this.setup(nextProps)
        }
        return true
    }

    createGroup() {
        const t = { entityId: this.state.id, groupName: this.state.groupName, gType: this.props.gType }
        ApiPost('CustomForm', 'CreateGroup', t)
        .then((n) => {
            var gL = this.state.groupList
            var id = n.groupId
            gL.push({ id, name: this.state.groupName })
            this.setState({ groupList: gL, groupName: null }, () => this.setGroup(id))
        })
    }

    getList(data) {
        var complete = []

        complete.push({ name: 'New ' + this.state.type, id: '-' })
        complete.push({ name: '───── ' + this.state.type + ' ─────', id: '-faU', disabled: true })
        data.map(x => complete.push(x) )
        return complete
    }

    render() {
        const { groupList, selGroup } = this.state

        if (groupList && this.props.data) {
            return (
                <div style={{ width: '100%' }}>
                    <Row>
                        <Col xs={12} sm={2}><Label ><b>{this.state.type}</b></Label></Col>
                        <Col xs={12} sm={4}>
                            <IdNameDropdown options={this.getList(groupList)} zeroText={'Select ' + this.state.type} valueField="id" displayField="name" value={this.state.selGroup || this.props.data.groupId} onChange={(e) => this.setGroup(e)} />
                        </Col>
                    </Row>
                    {selGroup == '-' &&
                    <Row style={{ marginTop: '10px' }}>
                        <Col xs={12} sm={2}><Label ><b>{this.state.type} Name</b></Label></Col>
                        <Col xs={8} sm={4} style={{ paddingLeft: '15px' }}>
                            <input className="input" className="form-control" placeholder={'New ' + this.state.type} onChange={(e) => this.setState({ groupName: e.target.value })} />
                        </Col>
                        <Col xs={2} sm={1}>
                            <button className="btn btn-primary" type="button" onClick={(e) => this.createGroup(e)}>Create New</button>
                        </Col>
                    </Row>}
                </div>
            )
        }
        else {
            return null
        }
    }
}

export default withRouter(GroupSelector)
