import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Input } from 'reactstrap'
import moment from 'moment'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import CheckBox from '../../../components/Controls/CheckBox'
import ExportModal from './../../Metering/components/MeteringExportModal'

class DeviceMeteringController extends Component {
    constructor(props) {
        super(props)
        var date = moment()
        var fromDate = moment().subtract(30, 'days')
        this.state = {
            sortType: 1, exportOpen: false, toDate: date, fromDate: fromDate, showReadings: false, dateSelectTitle: 'Select Custom Time'
        }
    }

    componentDidMount() {
        this.handleDate(this.state.fromDate, this.state.toDate, false)
        this.updateData()
    }

    componentDidUpdate(pp) {
        if (pp.mData) {
            if (pp.mData.sortType != this.props.mData.sortType) {
                this.setState({ sortType: this.props.mData.sortType })
            }
        }
    }

    toggleExportModal() {
        this.setState({ exportOpen: !this.state.exportOpen })
    }  

    handleDate(fromDate, toDate, updateData = true) {
        if (fromDate.format('DD/MM/YY').toString() == toDate.format('DD/MM/YY').toString()) {
            this.setState({
                dateSelectTitle: fromDate.format('DD/MM/YY') + ' ' + fromDate.format('HH:mm') + ' to ' + toDate.format('HH:mm')
            })
        }
        else {
            this.setState({
                dateSelectTitle: fromDate.format('DD/MM/YY HH:mm') + ' to ' + toDate.format('DD/MM/YY HH:mm')
            })
        }
        this.setState({ fromDate, toDate }, () => updateData && this.updateData())
    }

    updateData() {
        this.props.handleData({...this.state })
    }

	render() {
        let local = { format: "DD-MM-YYYY HH:mm", sundayFirst: false }
        let cStyle = { customRangeButtons: { color: '#0a92b1' }, customRangeSelected: { backgroundColor: '#0a92b1' }, fromDate: { backgroundColor: '#0a92b1' }, toDate: { backgroundColor: '#0a92b1' } }
        return (
            <div>
                <Row style={{ marginBottom: '15px' }}>
                    <Col lg={3} xs={6}>
                        <div style={{ maxWidth: '350px' }}>
                            <DateTimeRangeContainer start={this.state.fromDate} end={this.state.toDate} ranges={{}} local={local} applyCallback={(sd, ed) => this.handleDate(sd, ed)} style={cStyle} >
                                <Input id="formControlsTextB" type="text" label="Text" placeholder={this.state.dateSelectTitle} />
                            </DateTimeRangeContainer>
                        </div>
                    </Col>
                    <Col lg={3} />
                    <Col lg={1} xs={1} style={{ alignItems: 'center', display: 'flex' }}>

                    </Col>
                    <Col lg={2} xs={2} style={{alignItems:'center', display:'flex'}}>
                        <div>
                            <CheckBox name='showReadings' checked={this.state.showReadings} onChange={() => this.setState({ showReadings: !this.state.showReadings }, () => this.updateData())} />
                            <span>Show Readings</span>
                        </div>
                    </Col>
                    <Col lg={2} xs={3}>
                        <select className="form-control" value={this.state.sortType} onChange={(e) => this.setState({ sortType: e.target.value }, () => this.updateData()) } >
                            <option value={1}>Device/Period</option>
                            <option value={2}>Period/Device</option>
                            <option value={3}>Custom</option>
                        </select>
                    </Col>
                    <Col lg={1} xs={1}>
                        <i className="fa fa-download chart-option-btn" style={{ marginTop: '10px' }} onClick={() => this.toggleExportModal()} />
                    </Col>
                </Row>
                {this.state.exportOpen && <ExportModal toggleExportModal={() => this.setState({ exportOpen: !this.state.exportOpen })} handleDate={(sd, ed) => this.handleDate(sd, ed)} fromDate={this.state.fromDate} toDate={this.state.toDate} sample={true} stations={this.props.header.assets} />}
            </div>
        );
    }

};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(mapStateToProps)(DeviceMeteringController)
