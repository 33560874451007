import React, { Component } from 'react'
import { FormGroup, Col, Input } from 'reactstrap'
import moment from 'moment'
import { getConfigItem } from '../../../api'
import ChartControls from './ChartControls'
import { withRouter } from 'react-router-dom'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'

const DAY_FORMAT = 'DD/MM/YYYY';
const TIME_FORMAT = 'HH:mm:ss'
const alertDD = [];


class ScadaHistoricPicker extends Component {

    constructor(props) {
        super(props);
        var tDR = getConfigItem('$.monitoring.scadaDefaultRange')
		if (this.props.location.state && this.props.location.state.rangeId) {
			tDR = this.props.location.state.rangeId
        }
        this.state = {
            collapse: false, selectedRange: {}, timeFromOpen: false, timeToOpen: false,
            fromDate: null, toDate: null,
            dateSelectTitle: 'Select Custom Time', defaultPeriodSelect: tDR ? tDR : 4, alertSelected: false, recentAlerts: null, errorMsg: '', isVDF: props.analysisData ? true : false,
            listenerSet: false
        }

        this.handleDate = this.handleDate.bind(this);
    }

    componentWillReceiveProps() {
        if (this.props.recentAlerts) {
            if (this.props.recentAlerts != this.state.recentAlerts) {
                this.mapAlertDD();
                this.setState({ recentAlerts: this.props.recentAlerts });
            }
        }
    }

    componentDidMount() {
        if (!this.props.analysisData) {
            this.onChangePeriod(this.state.defaultPeriodSelect)
        }
        else {
            this.props.toggleLive(false)
            this.props.getVDFData()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.analysisData && prevProps.analysisData != this.props.analysisData) {
            this.props.getVDFData()
        }
        if (this.props.externalDates.from != prevProps.externalDates.from || this.props.externalDates.to != prevProps.externalDates.to || this.props.externalDates.noLoad != prevProps.externalDates.noLoad) {
            this.setState({ fromDate: moment(this.props.externalDates.from), toDate: moment(this.props.externalDates.to) }, () => this.handleDate(this.state.fromDate, this.state.toDate, true, this.props.externalDates.noLoad))
        }
        if (document.querySelector(".timeIconStyle") && !this.state.listenerSet) {
            var fromElement = document.getElementsByClassName("timeIconStyle")[0]
            var toElement = document.getElementsByClassName("timeIconStyle")[1]
            fromElement.addEventListener("click", () => { this.resetTime(0) })
            toElement.addEventListener("click", () => { this.resetTime(1) })
            this.setState({ listenerSet: true })
        }
    }

    resetTime(type) {
        if (type == 0) {
            var hs = document.getElementById("Hour_start")
            hs.value = 0
            hs.dispatchEvent(new Event('change', { 'bubbles': true }))
            var ms = document.getElementById("Minutes_start")
            ms.value = 0
            ms.dispatchEvent(new Event('change', { 'bubbles': true }))
        }
        else {
            var he = document.getElementById("Hour_end")
            he.value = 0
            he.dispatchEvent(new Event('change', { 'bubbles': true }))
            var me = document.getElementById("Minutes_end")
            me.value = 0
            me.dispatchEvent(new Event('change', { 'bubbles': true }))
        }
    }

    mapAlertDD() {
        alertDD.length = 0;

        alertDD.push(<option key={'alert_place'} value="">Select Alert</option>);

        this.props.recentAlerts.recentAlerts.map((x,index) => {
            alertDD.push(<option key={'alert_' + index} value={index}>{moment(x.raised).format(DAY_FORMAT)} - {x.msg}</option>);
        })
    }

    handleChange(event) {
        this.setState({ selectedRange: event });
    }

    onChangePeriod(e) {
        var offset = moment.parseZone().utcOffset()
        var startDate = moment.utc().add(offset, 'm')
        var endDate = moment.utc().add(offset, 'm')
        this.setState({
            defaultPeriodSelect: e
        })

        if (e == 0) {
            this.props.toggleLive(false);
            this.setState({
                alertSelected: true
            })
            if (this.props.recentAlerts.recentAlerts.length > 0) {
                this.onSelectAlertRange();
            }
            else {
                alertDD.length = 0;
                alertDD.push(<option key={'noalerts'} value="">No Recent Alerts</option>);
            }
        }
        else if (e == 1) {
            console.log('toggling live');
            this.props.toggleLive(true);
            this.props.renderChart();
        }
        else if (e == 2) {
            console.log('1 hour date range');
            startDate.add(-1, 'hours');
            this.props.toggleLive(false);
        }
        else if (e == 3) {
            console.log('3 hour date range');
            startDate.add(-3, 'hours');
            this.props.toggleLive(false);
        }
        else if (e == 4) {
            console.log('12 hour date range');
            startDate.add(-12, 'hours');
            this.props.toggleLive(false);
        }
        else if (e == 5) {
            console.log('24 hour date range');
            startDate.add(-24, 'hours');
            this.props.toggleLive(false);
        }
        else if (e == 6) {
            console.log('1 month date range');
            startDate.add(-1, 'months');
            this.props.toggleLive(false);
        }
        else if (e == 7) {
            if (this.state.fromDate) {
                startDate = this.state.fromDate
                endDate = this.state.toDate
                if (startDate.isSame(endDate)) {
                    startDate.add(-1, 'hours')
                }
            }
            else {
                startDate.add(-1, 'hours')
            }
            this.props.toggleLive(false);
        }

        if (e != 0) {
            this.setState({ alertSelected: false })
            if (e != 7) {
            }
        }

        if (e != 1 && e != 0 && e != 8) {
            this.setState({ fromDate: startDate, toDate: endDate }, () => this.handleDate(this.state.fromDate, this.state.toDate, false));
        }
    }

    onSelectAlertRange(e) {

        var selectedIndex;
        if (e == undefined) {
            selectedIndex = 0;
        }
        else{
            selectedIndex = e.target.value;
        }

        var selectedAlert = this.props.recentAlerts.recentAlerts[selectedIndex];
        var fromDate = null;
        var toDate = null;
        fromDate = moment(parseInt(moment(selectedAlert.raised).subtract(10, 'minutes').format('x')));
        toDate = moment(parseInt(moment(selectedAlert.raised).add(30, 'seconds').format('x')));

        this.props.toggleOverlay();

        console.log('onSelectAlertRange')
        this.setState({ fromDate, toDate });
    }

    togglePlayback() {
        this.props.togglePlayback(); 
    }

    handleDate(fromDate, toDate, custom, noLoad) {

        if (fromDate.format('DD/MM/YY').toString() == toDate.format('DD/MM/YY').toString()) {
            this.setState({
                dateSelectTitle: fromDate.format('DD/MM/YY') + ' ' + fromDate.format('HH:mm') + ' to ' + toDate.format('HH:mm')
            })
        }
        else {
            this.setState({
                dateSelectTitle: fromDate.format('DD/MM/YY HH:mm') + ' to ' + toDate.format('DD/MM/YY HH:mm')
            })
        }
        if (custom) {
            this.setState({ defaultPeriodSelect: 7 })
        }
        if (!noLoad) {
            this.props.toggleOverlay()
            console.log('picker get historic data')
            this.props.getHistoricData(fromDate, toDate, false)
        }
    }

    render() {
        let local = {format: "DD-MM-YYYY HH:mm", sundayFirst: false }
        let cStyle = { customRangeButtons: { color: '#0a92b1' }, customRangeSelected: { backgroundColor: '#0a92b1' }, fromDate: { backgroundColor: '#0a92b1' }, toDate: { backgroundColor: '#0a92b1' } }
        return (
            <div>
                <FormGroup row className="historicPicker">

                    {!this.state.isVDF &&
                        <Col xs={4} lg={2} className="hpCol">
                            <select className="form-control" value={this.state.defaultPeriodSelect} onChange={e => this.onChangePeriod(e.target.value)} >
                                <option value="0">Alert Periods</option>
                                <option value="1">Live</option>
                                <option value="2">1 Hour</option>
                                <option value="3">3 Hours</option>
                                <option value="4">12 Hours</option>
                                <option value="5">24 Hours</option>
                                <option value="6">Month</option>
                                <option value="7">Custom</option>
                            </select>
                        </Col>}

                    {!this.props.isLive && !this.state.isVDF && this.state.fromDate ?
                        <Col xs={7} lg={3} className="hpCol" style={{ display: !this.state.alertSelected ? 'block' : 'none' }}>
                            <DateTimeRangeContainer start={this.state.fromDate} end={this.state.toDate} ranges={{}} local={local} applyCallback={(sd, ed) => this.handleDate(sd, ed, true, false, true)} style={cStyle} >
                                <Input id="formControlsTextB" type="text" label="Text" placeholder={this.state.dateSelectTitle} />
                            </DateTimeRangeContainer>
                        </Col>
                        : null}

                    {!this.props.isLive && this.state.alertSelected ?
                        <Col xs={6} lg={3} className="hpCol">
                            <select className="form-control" onChange={e => this.onSelectAlertRange(e)} defaultValue={0} >
                                {alertDD}
                            </select>
                        </Col>
                        : null}


                    {!this.props.isLive ?
                        <Col lg={5} className="hpCol d-none d-sm-block">
                            <ChartControls moveAction={(r, v) => this.props.moveAction(r, v)} togglePlot={() => this.props.togglePlayback()} plotlineActive={this.props.plotlineActive} />
                        </Col>
                        : null}

                </FormGroup>
            </div>
        )
    }
}

export default withRouter(ScadaHistoricPicker);
