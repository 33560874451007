import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import ConditionSelector from './ConditionSelector'
import { ApiGet } from '../../../api'
export const API_PATH = process.env.SERVER_PATH + "/api"

class AlertRules extends Component {

    constructor(props) {
        super(props);
        this.state = {listData: null}
    }

    getFormulaBox() {
        return <FormulaBox />
    }


    componentDidMount() {
        this.getListData()
        document.title = 'Alert Rules | izon'
    }

    getListData() {
        var listsToGet = [listRequest.UniversalMetric]
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
        .then((data) => {
            var tempList = listsToGet;
            Object.keys(data).map(x => {
                tempList[x] = data[x]
            })
            this.setState({ listData: tempList, complete: true });
        })
    }

    render() {

        var lists = {
            scope: [{ id: 1, name: 'Device' }, { id: 2, name: 'Device Type' }],
            calculation: [{ id: 0, name: 'None' },{ id: 1, name: 'Average' }, { id: 2, name: 'Formula' }],
            schedule: [{ id: 1, name: 'None' },{ id: 2, name: 'Morning and weekends' }, { id: 3, name: 'Weekends' }, { id: 4, name: 'Out of office' }, { id: 5, name: 'Night' }, { id: 6, name: 'Custom' }],
            conditions: [{ id: 1, name: 'All conditions required to alert (AND)' }, { id: 2, name: 'Max' }, { id: 3, name: 'Min' }],
            msgTypes: [{ id: 1, name: 'Static' }, { id: 2, name: 'CSV List' }, { id: 3, name: 'Controller' }],
        }

        var iN = this.props.match.params.id == 'New'

        var AlertRuleFields = [
            { label: "Id", fieldId: "id", display: 'none', section:'Info' },
            { label: "Rule Name", fieldId: "name", section: 'Info' },
            { label: "Scope", fieldId: "scope", list: lists.scope, section: 'Info' },
            { label: "Device", fieldId: "device", list: listRequest.AllDevices, section: 'Info', displayIf: (rec) => rec.scope == 1  },
            { label: "Device Type", fieldId: "deviceType", list: listRequest.DeviceTypes, section: 'Info', displayIf: (rec) => rec.scope == 2 },
            //{ label: "Do not disturb schedule", fieldId: "alertSchedule", display: 'form', type: 'custom', custom: (r) => <Scheduler rec={r} list={lists.schedule} />, section: 'Info' },
            { label: "Condition Type", display: 'form', fieldId: "conditionType", list: lists.conditions, section: 'Conditions'  },
            { label: "Conditions", display: 'form', fieldId: "conditions", section: 'Conditions', type: 'custom', custom: (rec) => this.state.listData && ((rec.data && rec.data.id) || iN) ? <ConditionSelector rec={rec} list={this.state.listData[listRequest.UniversalMetric]} /> : null},
            { label: "Message Type", fieldId: "messageTypeId", section: 'Message', list: lists.msgTypes },
            { label: "Metric Lookup", display: 'form',fieldId: "messageMetricId", section: 'Message', list: listRequest.UniversalMetric, displayIf: (rec) => rec.messageTypeId == 2 || rec.messageTypeId == 3 },
            { label: "Controller Type", display: 'form',fieldId: "messageControllerId", section: 'Message', list: listRequest.controllers, displayIf: (rec) => rec.messageTypeId == 3  },
            { label: "Message", display: 'form',fieldId: "message", type: 'textarea', section: 'Message', displayIf: (rec) => rec.messageTypeId == 1 || rec.messageTypeId == 2 },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton'},
        ]

        var AlertRuleConfig = {
            model: 'AlertRules',
            fields: AlertRuleFields,
            master: true,
            titleField: 'name',
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            apiPath: API_PATH
            //defaultSorted: [{ id: 'name', desc: false }],
        }

        return (
            <div>
                <div className='titleBlock'>Alerting Rules</div>
                <MasterDetail key='mdcs' {...AlertRuleConfig} />                    
            </div >
            );
    }
}

export default (AlertRules)