import React, { Component } from 'react';
import logo from '../../../content/izon-logo.png';
import { Nav, NavItem, Navbar, NavbarBrand, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import FilterSearch from './FilterSearch'
import { connect } from 'react-redux';
import { toggleEditMapDash } from '../../actions/header';
import NotificationMgmt from './NotificationMgmt'

class header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            settingsOpen: false, profileOpen: false, alertOpen: false
        };
    }

    componentDidMount() {
        window.addEventListener("keydown", (e) => {
            if (e.ctrlKey && e.shiftKey && e.keyCode === 81) {
                e.preventDefault();
                document.getElementById('removeFilterBtn').click()
            }
            else if ((e.ctrlKey && e.keyCode === 81)) {
                e.preventDefault();
                document.getElementById('filterSearch').focus()
            }
        })
    }

    toggleSettings() {
        this.setState({
            settingsOpen: !this.state.settingsOpen
        });
    }
    toggleProfile() {
        this.setState({
            profileOpen: !this.state.profileOpen
        });
    }
    toggleAlert() {
        this.setState({
            alertOpen: !this.state.alertOpen
        });
    }

    render() {

        let editBtnText = ' Edit';

        if (this.props.mapDashBtn)
        {
            editBtnText = ' Add';
        }

        return (

            <Navbar id="headerNavbar" >

                <Nav navbar className="flex-row header-navbar justify-content-end" >

                    <NavbarBrand to="/Dashboard" tag={Link} id="navLogo" className="mr-auto pt-3"><img src={logo} alt="everun" className='lf' height="40px"></img></NavbarBrand>

                        <FilterSearch /> 
                        {
                        this.props.fullScreen ?

                        <NavItem className="form-inline p-2 navbar-components">             
                            <Button className="btn" onClick={() => this.props.toggleEditMapDash(this.props.editMapDash)} style={{cursor : 'pointer', width: '80px'}}><i className="fa fa-pencil" ></i>{this.props.editMapDash ? ' Save' : editBtnText}</Button>
                        </NavItem>

                        :

                            <div className="form-inline">
                            <NavItem className="form-inline p-2 navbar-components  responNotif">
                                <i className="fa fa-magic fa-lg" id="toggleEditMode" style={{ color: '#9d9d9d' }} />
                            </NavItem>
                            <NavItem className="form-inline p-2 navbar-components  responNotif">
                                <NotificationMgmt notifications={this.props.notifications}/>
                            </NavItem>
                            <NavItem className="form-inline p-2 navbar-components">
                                <Dropdown isOpen={this.state.profileOpen} toggle={event => this.toggleProfile()} className="headerDD">
                                    <DropdownToggle >
                                        <i className="fa fa-user fa-lg" style={{ color: '#9d9d9d' }} />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem to="/ChangePassword" tag={Link}>Change Password</DropdownItem>
                                        <DropdownItem to="/Login/?Logout=1" tag={Link}>Log out</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </NavItem>
                        </div>                  
                        }

                    {this.props.editMapDash ? <div className="navEditMode"><span>EDIT MODE ACTIVE</span></div> : null}

                </Nav>

            </Navbar>

            );
    }
}

const mapStateToProps = state => {
    return state.common.header
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleEditMapDash: (editMapDash) => {
            dispatch(toggleEditMapDash(editMapDash));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(header)