import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

let Simpleform = props => {
  const { handleSubmit, load, pristine, reset, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>First Name</label>
        <div>
          <Field
            name="name"
            component="input"
            type="text"
            placeholder="First Name"
          />
        </div>
      </div>
      <div>
        <label>Last Name</label>
        <div>
          <Field
            name="lastName"
            component="input"
            type="text"
            placeholder="Last Name"
          />
        </div>
      </div>
      <div>
        <button type="submit" disabled={pristine || submitting}>Submit</button>
        <button type="button" disabled={pristine || submitting} onClick={reset}>
          Undo Changes
        </button>
      </div>
    </form>
  );
};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Simpleform = reduxForm({
  form: 'Simpleformx', // a unique identifier for this form
})(Simpleform);

// You have to connect() to any reducers that you wish to connect to yourself
Simpleform = connect(
  state => ({
    initialValues: {
  name: "Jane",
  lastName: "Doe"} // pull initial values from account reducer
  })
)(Simpleform);

export default Simpleform