import React, { Component } from 'react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink, Navbar} from 'reactstrap';
import Collapsible from 'react-collapsible';
import { ApiGet } from '../api';
import FilterSearch from './Header/FilterSearch'

class ResponsiveMenu extends Component {

    constructor(props) {
        super(props);

        this.state = { isOpen: false, navOptions: [], showNav: false, showSearch: false };
    }

    componentDidMount()
    {
        this.getNavOptions();
    }

    getNavOptions()
    {
        ApiGet('UserSession', 'GetNavOptions')
        .then((data) => {
            this.setState({ navOptions: JSON.parse(data.navLayoutResponse) })
        })
    }

    toggle() {
        window.scrollTo(0,0)
        this.setState({ showNav: !this.state.showNav })
    }

    getNav()
    {
        if (this.state.navOptions)
        {
            return (
                <Navbar light className="mainMenu navbar navbar-toggleable navbar-light" style={{paddingTop:'10px'}}>
                    <Collapse isOpen={true} navbar>
                        <Nav navbar>
                            {this.state.navOptions.map((i, index) => {
                                if (i.submenu == undefined) {
                                    return <NavItem className={i.responOnly ? 'responNav' : ''} key={index}><NavLink key={i.title} to={i.link} className="Collapsible__trigger is-disabled" tag={Link} onClick={() => this.toggle()}><i className={i.icon + ' nav-icon'} />{i.title}</NavLink></NavItem>
                                }
                                else {
                                    return (
                                        <NavItem className={i.responOnly ? 'responNav' : ''} key={index}>
                                            <Collapsible trigger={<div><i className={i.icon + ' nav-icon'} />{i.title}</div>} transitionTime={200}>
                                                {i.submenu.map(x => {
                                                    return <NavLink key={x.title} to={x.link} className="Collapsible__trigger is-disabled" tag={Link} onClick={() => this.toggle()}>{x.title}</NavLink>
                                                })}
                                            </Collapsible>
                                        </NavItem>)
                                }
                            }
                            )}
                        </Nav>
                    </Collapse>
                </Navbar>)
        }
    }

    getDynamicButton() {
        if (this.props.authRole && this.props.authRole.includes("FieldEngineer")) {
            return <i className='fas fa-calendar-alt' onClick={() => this.props.history.push('/EngineerSchedule')} />
        }
        else {
            return <i className='fas fa-layer-group' onClick={() => this.props.history.push('/Incidents')} />
        }
    }

    render() {
        return (
            <div>
                <div className='responsiveMenu'>
                    <i className='fas fa-home-alt' onClick={() => this.props.history.push('/Dashboard')} />
                    <i className='fas fa-search' onClick={() => { window.scrollTo(0, 0); this.setState({ showSearch: !this.state.showSearch }); }} />
                    <button className={this.state.showNav ? 'hamburger hamburger--spin is-active' : 'hamburger hamburger--spin'} type="button" onClick={() => this.toggle()}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                    {this.getDynamicButton()}
                    <i className='fas fa-bell' onClick={() => this.props.history.push('/Notifications')} />
                </div>
                {this.state.showNav && <div style={{ position: 'absolute', zIndex: 103, top: 0, width: '100%', height: '100%' }}>{this.getNav()}</div>}
                {this.state.showSearch && <div className='respSearchContain'><FilterSearch onSelect={() => this.setState({showSearch: false})} /></div>}
            </div>
        );
    }
}

export default withRouter(ResponsiveMenu)