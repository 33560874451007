import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ResponseLevel from '../../../components/ResponseLevel';

export default class LogDetail extends Component {

    render() {

        return (
            <div>
            <Row className="log-detail">
                <Col sm="1">
                    <ResponseLevel level={this.props.logLevel} />
                </Col>
                <Col sm="2">
                    <div >
                        <span><b>{this.props.logDate}</b></span>
                    </div>
                    <div>
                        <span><b>{this.props.logUser}</b></span>
                    </div>
                </Col>
                <Col>
                    <div>
                        <span>{this.props.logMessage}</span>
                    </div>
                </Col>
                <Col sm="1">
                </Col>
            </Row>
            </div>
        );
    }
}