import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import  Crud  from '../../api/crud/Crud'
import helpers from '../../utils/helpers'

class ListCrud extends React.Component {

  static propTypes =
    {
        model: PropTypes.string.isRequired,
        path: PropTypes.string,
        layout: PropTypes.string
    };

  constructor(props) {
    super(props)
    this.crud = (() => new Crud(props))();
    this.fetchRecsFn = (page) => this.props.dispatch(this.crud.fetchRecs({ page }))
    this.fetchRecFn = (id) => this.props.dispatch(this.crud.fetchRec(id))
    this.createRecFn = (rec) => this.props.dispatch(this.crud.createRec(rec))
    this.deleteRecFn = (id) => this.props.dispatch(this.crud.deleteRec(id))
    this.updateRecFn = (id, rec) => this.props.dispatch(this.crud.updateRec(id))
  }

  componentWillMount() {
    const { recs, dispatch } = this.props
    if (recs.needsFetch) {
      dispatch(recs.fetch)
    }
    }

    componentWillReceiveProps(nextProps) {
        const { recs } = nextProps
        const { dispatch } = this.props
        if (recs.needsFetch) {
            dispatch(recs.fetch)
        }
    }

  getModal(frm) {
    const { currentRecId } = this.props
    return (
      <Modal isOpen={currentRecId!=null} autoFocus={false}>
          <ModalBody>
            {frm}
          </ModalBody>
          <ModalFooter>
                <button type="button" className="btn" onClick={() => { this.reactform.submit(), console.log('submitting', this.reactform) }} >Save</button>
             <button type="button" className="btn" onClick={() => this.crud.cancelEdit()} >Cancel</button>
          </ModalFooter>
        </Modal>
    )
  }

  render() {
    const { recs, children, form, currentRecId,layout } = this.props
    let cloneform = React.cloneElement(children.form, { data:recs, onSubmit:this.crud.handleSubmit, ref:(instance) => { if (instance) this.reactform = instance.getWrappedInstance(); }} )
    console.log('listCrud props', this.props.recs.data);
    if (layout == 'modal')  {
      cloneform = this.getModal(cloneform)
    }
    if (recs.isLoading) {
      return <div>
        <p>loading...</p>
      </div>
    } else
    {
      return <div>
          {helpers.reduceNull(recs.data).map(post => 
            <li key={post.id}>
              {children.row(post)}
              <button type="button" className="btn btn-default" onClick={() => this.crud.startEdit(post.id)} ><i className="fa fa-edit"></i></button>
              <button type="button" className="btn btn-danger" onClick={() => this.crud.startDel(post.id)} ><i className="fal fa-trash-alt"></i></button>
            </li>
          )}
          <div><button type="button" className="btn" onClick={() => this.crud.startAdd()} >Add</button></div>
          {cloneform}
      </div>
    }
  }
}

function mapStateToProps(state, ownProps) {
  const crud = () => new Crud(ownProps); // get our Crud object linked to the model, takes Path as second option
  return { recs: crud().getData(state, { page: 1 }), currentRecId: crud().getCurId(state) } // get the data from the state and refresh the store if stale data
}

export default connect(mapStateToProps,null, null, { withRef: true })(ListCrud)