import React, { Component } from 'react'
import { Col } from 'reactstrap'
import { industryDefaultMetrics } from '../../../configuration/enums'
import { connect } from 'react-redux'
import MonitoringTileX from './MonitoringTileX'
import MonitoringTileMap from './MonitoringTileMap'
import MonitoringTileDevices from './MonitoringTileDevices'
import Collapsible from 'react-collapsible'
import { ApiPost, ApiGet } from '../../../api'

class MonitoringGrid extends Component {
    
    constructor(props) {
        super(props)
        this.renderGroupedSites = this.renderGroupedSites.bind(this)
        this.state = {config: ''}
    }

    componentDidMount() {
        ApiGet('Monitoring', 'GetMonitoringConfigs')
        .then((data) => {
            this.setState({config:data.configs})
        })
    }

    renderGroupedSites(title, sites, scada, industryid, commonstate) {
        var tileTypes = { 1: { tile: MonitoringTileX }, 2: { tile: MonitoringTileX }, 4: { tile: MonitoringTileX }, 5: { tile: MonitoringTileX }, 6: { tile: MonitoringTileX }, 7: { tile: MonitoringTileX }, 8: { tile: MonitoringTileMap }, 9: { tile: MonitoringTileDevices }, 3: { tile: MonitoringTileDevices }   }
        return (
            <Collapsible trigger={title} key={title} transitionTime={200} open={true}>
                <div className="row">
                    <Col sm="12">
                        <div key={'g' + title} className='dashboard-controllers themelight col'>
                            {sites.map((o, i) => {
                                if (o.childDevices.length > 0 || o.parentId == null) {
                                    const cType = o.assetPath ? tileTypes[2] : tileTypes[o.industryId] ? tileTypes[o.industryId] : { tile: MonitoringTileDevices }
                                    return <cType.tile station={o} key={i} livesiteids={scada.livesiteids} industryId={industryid} childDevices={commonstate.header.childDevices} config={this.state.config} />
                                }
                            }
                        )}
                        </div>
                    </Col>
                </div>
            </Collapsible>
        )
    }

    render() {
        const { sites, scada, commonstate } = this.props
        var vorder = [1,2,7,6,5,4];
        if (industryDefaultMetrics.viewOrder) {
            vorder = industryDefaultMetrics.viewOrder;
        }
        return (
            <div className="accordionCards">
                {vorder.map((k,i) => { 
                        const industry = sites[k]
                        if (industry && this.state.config != '') {
                            var sSites = industry.items
                            var parentSites = sSites.filter(x => x.parentId == null)
                            if (parentSites.length == sSites.length) //PRE-DEVICES CHANGE OVER
                            {
                                return this.renderGroupedSites(industry.name, sSites, scada, industry.id, commonstate)
                            }
                            else
                            {
                                return (
                                        parentSites.map(ps => {
                                            var childSites = sSites.filter(s => s.parentId == ps.id)
                                            return this.renderGroupedSites(ps.name, childSites[0].childDevices.length == 0 ? sSites : childSites, scada, industry.id, commonstate)
                                        })
                                )
                            }
                        }
                    }
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common, scada: state.common.scada }
}

export default connect(
    mapStateToProps
)(MonitoringGrid)