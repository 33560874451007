import React, { Component } from 'react';
import logo from '../../../../content/everun-logo.png';
import { Row, Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Label, Col, ListGroup, ListGroupItem, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import classnames from 'classnames';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import { ApiPost } from '../../../api';
export const API_PATH = process.env.SERVER_PATH + '/api'

class ModelSettings extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { fileDetails: null, clientName: '', activeTab: '1', mainActiveTab: '1' };
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    render() {

        const { mainActiveTab } = this.state;

        var lists = {
            regulation: [{ id: 1, name: 'Pitch' }, { id: 2, name: 'Stall' }]            
        };

        var ModelSelectionFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Model Name", fieldId: "name", width: 170, canFilter: true },            
            { label: "DeRated", fieldId: "deRated", type: 'checkbox', width: 170, canFilter: false, display: 'form'},   
            { label: "Invertor", fieldId: "invertor", type: 'checkbox', width: 170, canFilter: false, display: 'form' },
            { label: "Kw", fieldId: "kw", width: 170, canFilter: true },
            { label: "Manufacturer", fieldId: "manufacturerId", width: 170, list: listRequest.TurbineManufacturers },
            { label: "Regulation", fieldId: "regulationId", width: 170, list: lists.regulation, display: 'form', canFilter: false},            
            { label: "Rotor Dia", fieldId: "rotorDia", width: 170, canFilter: true, display: 'form', canFilter: false },
            { label: "Industry Name", fieldId: "industryId", width: 170, list: listRequest.Industries },             
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton'},
        ];

        var ModeSelectionConfig = {
            model: 'ModelSettings',
            fields: ModelSelectionFields,
            master: true,
            titleField: 'name',
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            apiPath: API_PATH
        }

        var MetricRangesFields = [  
            { label: "Turbinelimit Id", fieldId: "id", width: 170, display: 'none' },
            { label: "Metric", fieldId: "universalMetricId", width: 170, list: listRequest.UniversalMetric },
            { label: "Turbine Model", fieldId: "turbineModelId", width: 170, display: 'none' },
            { label: "Error Over", fieldId: "errorOver", width: 170, canFilter: true },
            { label: "Error Under", fieldId: "errorUnder", width: 170, canFilter: true },
            { label: "Warn Over", fieldId: "warnOver", width: 170, canFilter: true },
            { label: "Warn under", fieldId: "warnUnder", width: 170, canFilter: true },
            { label: "Filter high pass", fieldId: "filterHighPass", width: 170, canFilter: true, display: 'form' },
            { label: "Filter Low Pass", fieldId: "filterLowPass", width: 170, canFilter: true, display: 'form' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/UniversalMetricCard/' }},
        ];

        var MetricRangesConfig = {
            model: 'ModelSettings',
            path: '/ModelSettings/GetTurbineMetricRanges/' + this.props.match.params.id,
            fields: MetricRangesFields,
            gridButton: { pathname: '/UniversalMetricCard/New'},
            titleField: 'id',
            apiPath: API_PATH
        }   

        return (
            <div>
                <div className='titleBlock'>Models</div>
                <div>
                    <MasterDetail key='mdmc' {...ModeSelectionConfig} >
                    {this.props.match.params.id != 'New' ?
                        [<Nav tabs>
                            <NavItem>
                                    <NavLink className={classnames({ active: mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Metric Ranges</NavLink>
                            </NavItem>
                        </Nav>,
                        <TabContent key='modelTabs' activeTab={this.state.mainActiveTab}>
                            {console.log('activeTab', this.state.mainActiveTab)}
                            <TabPane tabId='1'>
                                {this.state.mainActiveTab == '1' ? <MasterDetail key='mdcc' {...MetricRangesConfig} /> : null}
                            </TabPane>
                        </TabContent>]: null}
                    </MasterDetail>    
                </div>
            </div >
        );
    }
}

export default (ModelSettings)