
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import {openAlert, AlertYesClick, AlertNoClick, AlertCancelClick,FunctionExecuted} from './actions.js';

class AlertBox extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.alert.isOpen || nextProps.alert.button > 0
  }

  // Test button clicked and reset state if clicked
  componentWillUpdate(nextProps, nextState){
    const { alert } = nextProps
    const { setFunctionExecuted } = this.props
    if (alert.button > 0) {
      setFunctionExecuted()
      var ab = window.alertbox
      if (alert.button == 1 && ab && ab.onYes) {
            ab.onYes()
        }
      if (alert.button == 2 && ab && ab.onNo) {
            ab.onNo()
        }  
      if (alert.button == 3 && ab && ab.onCancel) {
            ab.onCancel()
        }
    }
  }

  render() {
    const {alert, onYesClick, onNoClick,onCancelClick} = this.props
    const YesBtn = <Button color="primary" onClick={onYesClick}>{(alert.mode =='ok') ?'OK':'Yes'}</Button>
    const NoBtn = (alert.mode =='yesno' || alert.mode =='yesnocancel') ? <Button color="btn btn-" onClick={onNoClick}>No</Button> : undefined
    const CancelBtn = (alert.mode =='yesnocancel') ? <Button color="secondary" onClick={onCancelClick}>Cancel</Button> : undefined
    return (
      <div>
            <Modal isOpen={alert.isOpen} toggle={onCancelClick} className={this.props.className} autoFocus={false}>
          <ModalBody>
            {alert.message}
          </ModalBody>
          <ModalFooter>
             {YesBtn}{' '}
             {NoBtn}{' '}
             {CancelBtn}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { alert: state.alertbox }
}

function mapDispatchToProps (dispatch) {
  var ab = (window.alertbox = window.alertbox? window.alertbox: {});
  if (!ab.open) {
    ab.openOk = (msg, onYes) => {
      return dispatch(openAlert(msg,'ok',onYes))
    }
    ab.openYesNo = (msg, onYes, onNo) => {
      return dispatch(openAlert(msg,'yesno',onYes, onNo))
    }
    ab.openYesNoCancel = (msg, onYes, onNo, onCancel) => {
      return dispatch(openAlert(msg,'yesnocancel',onYes, onNo, onCancel))
    } 
  }
  return {
    onYesClick () {
      dispatch(AlertYesClick())
    },
    onNoClick () {
      dispatch(AlertNoClick())
    },
    onCancelClick () {
      dispatch(AlertCancelClick())
    },
    setFunctionExecuted () {
      dispatch(FunctionExecuted())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
) (AlertBox)
