import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

export default class VanPin extends Component {
    static propTypes =
    {
        siteName: PropTypes.string,
            speed: PropTypes.number,
            heading: PropTypes.number
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
    }

    render() {
        const { siteName, speed, heading } = this.props;

        var rotate = '';
        var vanClass = 'van';

        if (speed <= 0) {
            rotate = 'rotate(' + 0 + 'deg)';
            vanClass = 'van vanStopped';
        }
        else {
            rotate= 'rotate(' + (heading + 90) + 'deg)'
        }  
       
        var styles = {
            transform: rotate
        }
        
        if (speed <= 0) {
            
        }
        return <div className={vanClass} speed={speed} style={styles} >{siteName}</div>
    }
}