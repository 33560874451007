import React, { Component } from 'react'
//import MasterDetail from '../../../testComp/MasterDetail/src'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import { dbTables } from '../../../configuration/formTables';
import { Nav, NavItem, NavLink, TabContent, TabPane, Modal } from 'reactstrap';
import { ApiGet } from '../../../api'
import moment from 'moment'
export const API_PATH = process.env.SERVER_PATH + '/api'

class FieldServiceReport extends Component {

    mdRefCallback = (instance) => {
        this.srFull = instance ? instance.getWrappedInstance() : null;
    }

    constructor(props) {
        super(props);
        this.state = { smId: 1, activeTab: 1, mdRec: [], formSubmit: false };
    }

    componentDidMount() {
        this.getJobForms()
    }

    getJobForms() {
        ApiGet('ServiceReportForm', 'GetJobFormList', { id: this.props.match.params.id, isServiceReport: true })
        .then((data) => {
            this.setState({ formList: data })
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            if (!isNaN(this.props.match.params.id)) {
                this.setState({smId: Math.random()})
            }
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    parseMDData(e) {
        var d = e.data.filter(x => x.id != -1)
        if (d.length > 0 && d.length != this.state.mdRec.length) {
            console.log('this.state.mdRec', this.state.mdRec)
            if (this.state.mdRec.length == 0) {
                this.setState({ mdRec: d })
            }
            else {
                this.setState({ mdRec: d }, () => this.props.history.push('/FormCustom/' + this.state.mdRec[0].rowId + '/1'))
            }
        }
    }

    submitCallback(e) {
        console.log('submitCallback')
        this.setState({ smId: Math.random(), formSubmit: true })
    }

    getTable(jobType) {

        var lists = {
            stockCat: [{ id: 1, name: 'CON' }, { id: 2, name: 'CP' }, { id: 3, name: 'SER' }, { id: 4, name: 'PRT' }],
            wh: [{ id: 1, name: 'Default' }],
            formList: this.state.formList,
            partGroups: this.state.partGroups ? this.state.partGroups : [{ id: 'Default', name: 'Default' }, { id: 'Electrical', name: 'Electrical' }, { id: 'Civils', name: 'Civils' }],
        }

        if (jobType == 1) //PARTS
        {
            var partSelectionFields = [
                { label: "Id", fieldId: "id", display: 'none' },
                { label: "", fieldId: "serviceReportId", display: 'none', getId: -1 },
                //{ label: "", fieldId: "pickStatus", width: 30, type: 'custom', custom: (rec) => this.getIndicator(rec.pickStatus) },
                { label: "Part", fieldId: "inPartId", list: listRequest.PartNameIdentifier, inlineEdit: true, type: 'searchableList', width: 500 },
                { label: "WH", fieldId: "outPartLocation", list: listRequest.Warehouses, rememberSelection: true, inlineEdit: true, width: 200 },
                { label: "Qty", fieldId: "qty", inlineEdit: true, width: 200 },
                { label: "Group", fieldId: "partGroup", inlineEdit: true, list: lists.partGroups },
                { label: "Group", fieldId: "rGroup" },
                { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
            ];

            var partSelectionConfig = {
                model: 'partSelection',
                fields: partSelectionFields,
                addInline: true,
                gridButton: false,
                crudParams: { isServiceReport: true },
                //externalFormId: this.props.externalFormId ? this.props.externalFormId : null,
                customWidth: 0,
                expandAll: true,
                groupRowsBy: 'rGroup',
                //mdData: (e) => this.parsePartsData(e),
                gridProps: { minRows: 1, noDataText: <div><br /><b>No Parts Added</b></div>, showPagination: false },
                parentId: 'serviceReportId',
                submitCallback: (e) => setTimeout(() => this.submitCallback(e), 1000),
                apiPath: API_PATH
            }

            return <MasterDetail key={jobType + this.state.smId} {...partSelectionConfig} />
        }
        else if (jobType == 2) //FORMS
        {
            var formSelectionFields = [
                { label: "Id", fieldId: "id", display: 'none' },
                { label: "", fieldId: "serviceReportId", display: 'none', getId: -1 },
                { label: "Form Type", fieldId: "formId", list: lists.formList, inlineEdit: true },
                { label: "Row Id", fieldId: "rowId", display: 'none' },
                { label: "Created", fieldId: "created", type: 'datetime' },
                { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn', displayIf: (r) => r.id == -1},
                { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { btnLabel: 'View' }, funcProps: (rec) => { return { location: '/FormCustom/' + rec._original.rowId + '/1/', state: { formSelectionId: rec._original.formId } } }, width: 70, displayIf: (r) => r.id != -1 }
            ];
            console.log('this.props.location.state', this.props.location.state)
            var formSelectionConfig = {
                model: 'ServiceReportForm',
                fields: formSelectionFields,
                addInline: true,
                gridButton: false,
                expandAll: true,
                submitCallback: (e) => setTimeout(() => this.submitCallback(e), 1000),
                defaultSorted: [{ id: 'created', desc: true }],
                preData: { created: moment() },
                //crudParams: { formSelectionId: this.props.match.params.id },
                //mdData: (e) => this.parseMDData(e),
                gridProps: { minRows: 20, defaultPageSize: 20, noDataText: <div><br /><b>No Forms Added</b></div>, showPagination: true },
                apiPath: API_PATH
            }

            return <MasterDetail key={jobType} wrappedComponentRef={this.mdRefCallback} {...formSelectionConfig} />
        }
        else if (jobType == 3) //IMAGES
        {
            var galleryFields = [
                { label: "Id", fieldId: "id" },
                { label: "Thumbnail", fieldId: "thumbnail" }
            ]

            var jobOverviewSetup = {
                model: 'FieldServiceReportUpload',
                fields: galleryFields,
                galleryView: { "gallery": { "rows": "2", "cols": "4" } },
                crudParams: { type: 1 },
                defaultSorted: [{ id: 'lastUpdated', desc: true }],
                parentId: 'serviceReportId',
                gridButton: { pathname: '/FieldServiceReportUpload/' + this.props.match.params.id, state: { addNew: true, uploadType: 1 } },
                apiPath: API_PATH
            }

            return <MasterDetail key={jobType} {...jobOverviewSetup} />
        }
        else if (jobType == 4) //DOCUMENTS
        {
            var galleryFields = [
                { label: "Id", fieldId: "id" },
                { label: "Thumbnail", fieldId: "thumbnail" }
            ]

            var jobOverviewSetup = {
                model: 'FieldServiceReportUpload',
                fields: galleryFields,
                galleryView: { "gallery": { "rows": "2", "cols": "4" } },
                crudParams: { type: 2 },
                defaultSorted: [{ id: 'lastUpdated', desc: true }],
                parentId: 'serviceReportId',
                gridButton: { pathname: '/FieldServiceReportUpload/' + this.props.match.params.id, state: { addNew: true, uploadType: 2 } },
                apiPath: API_PATH
            }

            return <MasterDetail key={jobType} {...jobOverviewSetup} />
        }
        else {
            return null
        }


    }

    getmodal() {
        return (
            <div>
                <button onClick={() => this.setState({modalOpen: true })}>Open modal</button>
                <Modal isOpen={this.state.modalOpen}>
                    <span>test</span>
                </Modal>
            </div>
        )
    }

    render() {

        if (this.srFull && this.state.formSubmit == true) {
            console.log('sr', this.srFull.props.recs.data)
            var rec = this.srFull.props.recs.data[this.srFull.props.recs.data.length - 1]
            if (rec) {
                console.log('rec', rec)
                //this.props.history.push({ pathname: '/FormCustom/' + rec.rowId + '/1', state: { formSelectionId: rec.formId } })
            }
        }

        var serviceReportFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Assigned", fieldId: "assigned", readOnly: true },
            { label: "Scheduled", fieldId: "scheduledTime", readOnly: true },
            { label: "Status", fieldId: "jobStatus", list: listRequest.AllJobStatuses, readOnly: true },
            { label: "Dispatch Note", fieldId: "dispatchNote", type: 'textarea' },
            { label: "Work Carried Out", fieldId: "workCarriedOut", display: 'form', type: 'textarea' },
            //{ label: "Part Selection", fieldId: "parts", type: 'custom', display: 'form', custom: MasterDetail, customProps: partSelectionConfig },
            //{ label: "test", fieldId: "test", type: 'custom', display: 'form', custom: (r) => this.getmodal()  },
            { label: 'nId', fieldId: "nId", display: 'none', getId: -1 }
        ];

        var engineerSelectionConfig = {
            model: 'ServiceReport',
            fields: serviceReportFields,
            gridButton: false,
            showFilterRow: false,
            master: true,
            linkedEntity: dbTables.ServiceReport,
            defaultSorted: [{ id: 'startTime', desc: false }],
            apiPath: API_PATH
        }

        const { activeTab } = this.state;
        const tabConfig = [{ id: 1, name: 'Parts' },{ id: 2, name: 'Forms' }, { id: 3, name: 'Images' }, { id: 4, name: 'Documents' }]

        return (
            <div>
                <div className='titleBlock'>Work Report</div>
                <MasterDetail key={'mdPO' + this.state.smId} {...engineerSelectionConfig}>
                    <div>
                        <Nav tabs>
                            {tabConfig.map(t => (
                                <NavItem key={t.id}>
                                    <NavLink
                                        className={activeTab === t.id ? 'active' : ''}
                                        onClick={() => this.toggle(t.id)}>
                                        {t.name}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                        <TabContent key='clientTabs' activeTab={activeTab}>
                            {tabConfig.map(t => (
                                <TabPane key={t.id} tabId={t.id}>
                                    {activeTab === t.id && this.getTable(t.id)}
                                </TabPane>
                            ))}
                        </TabContent>
                    </div>
                </MasterDetail>
            </div>
        )
    }
}

export default FieldServiceReport
