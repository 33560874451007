import React, { Component } from 'react';
import ScadaChart from './ScadaChart';
import ScadaCollapsibles from './ScadaCollapsibles';
import ScadaTagMgmt from './ScadaTagMgmt';
import ScadaExportModal from './ScadaExportModal';
import { ToggleSwitch } from '../../../components/Controls/ToggleSwitch';

import { ApiGet, ApiPost, GetStationLiveState} from '../../../api';
import { connect } from 'react-redux';

import moment from 'moment';
import { updateLiveConnection, toggleLiveConnection, disconnectLive, scadaSendCommand, SCADA_COMMANDS  } from '../../../actions/scada';
import ScadaActions from './ScadaActions'

class ScadaWF extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLive: true, scadaData: null, gotGroups: false, recentAlerts: null, tagMgmtOpen: false, renderInfo: null,
            lastMetricVals: null, futureMetricVals: null, editMode: false, mgmtMetric: null, defaultMetric: null, exportOpen: false, connectionState: null, retryConnection: false,
            fromDate: null, toDate: null, defaultMetrics: [11, 2], alertMetrics: [3], favourites: [], allHistoricTimes: [], maxMetricVals: [], minMetricVals: [], binaryMetrics: [241, 242, 243, 308, 309, 310, 311]
        }
    }

    componentWillMount() {
        this.stationId = parseInt(this.props.match.params.stationid);
        this.getRenderInfo();
    }

    componentDidMount() {
        this.stationDet = null;
        this.getLiveData();
    }

    componentWillUnmount() {
        this.props.updateLiveConnection([]);
    }

    toggleGetGroups() {
        this.setState({ gotGroups: !this.state.gotGroups });
    }

    toggleEditMode() {
        this.setState({ editMode: !this.state.editMode });
    }

    componentWillReceiveProps() {
        this.stationDet = GetStationLiveState(this.props.commonstate, this.stationId);
        if (this.state.isLive && this.stationDet) {
            if (this.stationDet.metrics && this.stationDet.metrics !== this.state.scadaData) {
                Object.keys(this.stationDet.metrics).map(i => {
                    if (i == 241 || i == 242 || i == 243 || i == 308 || i == 309 || i == 310 || i == 311) {
                        var value = '';
                        var wsValue = this.stationDet.metrics[i];
                        var bin = ("0".repeat(16) + (wsValue >>> 0).toString(2));
                        bin = bin.substr(bin.length - 16, 16);

                        this.state.renderInfo.renderInfo.map(x => {
                            if (x.metricId == i) {
                                value = '';
                                value = bin.substr(15 - (x.lookupCode - 1), 1);
                                this.stationDet.metrics[i + '-' + x.lookupCode] = parseInt(value);
                            }
                        })
                    }
                    this.setMaxMin(i);
                })
                this.setState({ scadaData: this.stationDet.metrics })
            }
        }

        if (this.props.scada.connectionState == 'error' && this.state.connectionState == null) {
            this.setState({ connectionState: 'error', retryConnection: true  });
        }
        else if (this.props.scada.connectionState == 'disconnected' && this.state.connectionState == null) {
            this.setState({ connectionState: 'disconnected', retryConnection: true });
        }

    }

    setMaxMin(metricId, metricVal) {
        var tempMax = this.state.maxMetricVals;
        var tempMin = this.state.minMetricVals;

        if (this.state.isLive) {
            metricVal = this.stationDet.metrics[metricId];
        }

        if (tempMax[metricId] != null) {
            if (tempMax[metricId] < metricVal) {
                tempMax[metricId] = metricVal;
                this.setState({ maxMetricVals: tempMax });
            }
        }
        else {
            tempMax[metricId] = metricVal;
            this.setState({ maxMetricVals: tempMax });
        }

        if (tempMin[metricId] != null) {
            if (tempMin[metricId] > metricVal) {
                tempMin[metricId] = metricVal;
                this.setState({ minMetricVals: tempMin });
            }
        }
        else {
            tempMin[metricId] = metricVal;
            this.setState({ minMetricVals: tempMin });
        }

    }

    getLiveData() {
        const { station } = this.props;
        let siteids = [this.stationId];
        this.props.updateLiveConnection(siteids);
        setTimeout(() => this.checkLiveData(), 10000);
    }

    checkLiveData() {
        if (this.state.isLive && !this.state.scadaData && !this.state.connectionState) {
            this.setState({ connectionState: 'disconnected', retryConnection: true });
            this.props.updateLiveConnection([]);
        }
    }

    getScadaGroups(groupType, metricList) {

        if (metricList) {
            metricList = JSON.stringify(metricList).replace('[', '').replace(']', '')
        }

        var scadaGroup = {
            groupType: groupType,
            metricList: metricList,
            stationId: this.stationId
        }

        ApiGet('Monitoring', 'GetScadaGroups', scadaGroup)
        .then((data) => {
        var tempGroups = [];
        data.scadaGroups.map(x => {
            tempGroups[x.metricId] = x

            if (this.state.defaultMetrics.includes(parseInt(x.uMetId))) {
                this.setState({ favourites: this.state.favourites.concat(parseInt(x.metricId)) });
                if (this.state.defaultMetric == null) {
                    this.setState({ defaultMetric: parseInt(x.metricId) });
                }
            }

            if (this.state.alertMetrics.includes(parseInt(x.uMetId)) && this.stationDet && this.stationDet.alerts && this.stationDet.alerts != undefined && this.stationDet.alerts.length > 0) {
                this.setState({ favourites: this.state.favourites.concat(parseInt(x.metricId)), defaultMetric: parseInt(x.metricId)  });
            }
        })

        this.state.renderInfo.renderInfo.map(x => {
            if (tempGroups[x.metricId] && this.state.binaryMetrics.includes(x.metricId)) {
                tempGroups.push( { 'metricId': x.metricId + '-' + x.lookupCode, 'name': x.desc.replace('!','').replace('*',''), 'group': 9999, 'decimalPlaces' : 0 });
            }
        }) 

        this.setState({ scadaGroups: tempGroups, gotGroups: true  });

        })
    }

    getRecentAlerts() {
        var alertStations = {
            stationId: this.stationId
        }
        ApiGet('Monitoring', 'GetRecentAlerts', alertStations)
        .then((data) => {
            console.log("Recent Alerts", data)
            this.setState({ recentAlerts: data });
        })
    }

    getRenderInfo() {
        ApiGet('Monitoring', 'GetRenderInfo')
        .then((data) => {
            this.setState({ renderInfo: data });
        })
        .then(() => {
            this.getScadaGroups('Live');
        })
        .then(() => {
            this.getRecentAlerts();
        })
    }

    getHistoricLastVals(fromDate) {

        var allLastVals;
        var startDate = moment(fromDate).add(-1, 'hours');
        var endDate = fromDate;
        var lastVals = [];

        var dataSettings = {
            fromDate: moment(startDate).format('DD/MM/YY HH:mm:ss'),
            toDate: moment(endDate).format('DD/MM/YY HH:mm:ss'),
            stationIds: this.stationId,
            reportType: 4
        }

        ApiPost('Monitoring', 'GetHistoricScada', dataSettings)
        .then((data) => {
            allLastVals = data.series;
            allLastVals.map(i => {   
                if (i.tags.MetricId) {
                  lastVals[parseInt(i.tags.MetricId)] = (i.values[0][1]);
                }
            })

            this.setState({ lastMetricVals: lastVals });            
        })
    }

    getFutureVals(toDate) {

        var allFutureVals;
        var endDate = moment(toDate).add(1, 'hours');
        var startDate = toDate;
        var futureVals = [];

        var dataSettings = {
            fromDate: moment(startDate).format('DD/MM/YY HH:mm:ss'),
            toDate: moment(endDate).format('DD/MM/YY HH:mm:ss'),
            stationIds: this.stationId,
            reportType: 3
        }

        ApiPost('Monitoring', 'GetHistoricScada', dataSettings)
        .then((data) => {
            allFutureVals = data.historicData.results[0].series;

            allFutureVals.map(i => {
                if (i.tags.MetricId) {
                    futureVals[parseInt(i.tags.MetricId)] = (i.values[0][1]);
                }
            })

            this.setState({ futureMetricVals: futureVals });

        })
    }

    getHistoricData(fromDate, toDate, getFuture) {

        var allHistoricData;
        var metricList = [];
        this.dataByTime = [];
        var startDate = null;
        var endDate = null;
        var groupRate = '1m';

        this.getHistoricLastVals(fromDate);
        if (getFuture) {
            this.getFutureVals(toDate);
        }

        if (fromDate != null && toDate != null)
        {
            var offset = new Date().getTimezoneOffset();

            startDate = fromDate.add(offset, 'minutes');
            endDate = toDate.add(offset, 'minutes');
            var timeDiff = moment(endDate).diff(moment(startDate), 'minutes');

            groupRate = Math.round(timeDiff / 500) + 'm';

            if (groupRate == '0m'){
                groupRate = '10s';
            }
        }
        else
        {
            startDate = moment(startDate).add(-1, 'days');
            endDate = new Date().getTime();
            groupRate = '5m';
        }

        var calcMethod = 'Last';
        var metricId;

        var dataSettings = {
            fromDate: moment(startDate).format('DD/MM/YY HH:mm:ss'),
            toDate: moment(endDate).format('DD/MM/YY HH:mm:ss'),
            stationIds: this.stationId ,
            groupRate: groupRate,
            reportType: 2
        }
        ApiPost('Monitoring', 'GetHistoricScada', dataSettings)
        .then((data) => {
            allHistoricData = data.historicData.results[0].series;

            var tAllHistoricTimes = [];
            allHistoricData[0].values.map(i => {
                tAllHistoricTimes.push(parseInt(i[0]));
                this.dataByTime[i[0]] = [];
            })

            this.setState({ allHistoricTimes: tAllHistoricTimes });

            //console.log('allHistoricData', dataSettings);
        })
        .then(() => {
            var start = performance.now();
            allHistoricData.map(i => {
                metricId = parseInt(i.tags.MetricId);
                calcMethod = this.getMetricCalc(metricId);

                if (calcMethod == "Last") {
                    this.setLastInterpolation(metricId, i.values, getFuture);
                }
                else if (calcMethod == "Avg") {
                    this.setAvgInterpolation(metricId, i.values, getFuture);
                }
            })
            var end = performance.now();
            console.log('processedData', ((end - start) * 0.001) + " secs");

        })
        .then(() => {
            this.setState({ scadaData: allHistoricData, fromDate: startDate, toDate: endDate })
            this.refs.ScadaChart.setHistoricGraphData();
        })
    }

    setLastInterpolation(metricId, metricVals, getFuture) {
            var lastArray = (metricVals.length - 1);

            if (metricVals[0][1] == null) {
                metricVals[0][1] = this.state.lastMetricVals ? this.state.lastMetricVals[metricId] : 0
            }
            if (metricVals[lastArray][1] == null && getFuture && this.state.futureMetricVals) {
                metricVals[lastArray][1] = this.state.futureMetricVals[metricId];
            }

            var validVal;

            metricVals.map(sv => {
                if (sv[1] != null) {
                    validVal = sv[1];
                }
                else {
                    sv[1] = validVal;
                }

                this.dataByTime[parseInt(sv[0])][metricId] = sv[1];
            })
        }

    setAvgInterpolation(metricId, metricVals, getFuture) {
        var lastArray = (metricVals.length - 1);

        if (metricVals[0][1] == null) {
            metricVals[0][1] = this.state.lastMetricVals ? this.state.lastMetricVals[metricId] : 0
        }
        if (metricVals[lastArray][1] == null && getFuture && this.state.futureMetricVals) {
            metricVals[lastArray][1] = this.state.futureMetricVals[metricId];
        }

        var startVal;
        var endVal;
        var endIndex;
        
        metricVals.map((sv, i) => {
            var endValSet = false;
            if (sv[1] != null) {
                startVal = sv[1];
                //GET NEXT VALID POINT
                metricVals.map((next, index) => {
                    if (next[1] != null && index > i && !endValSet) {
                        endVal = next[1];
                        endIndex = index;
                        endValSet = true;
                    }
                })
            }
            else {
                var newVal = startVal + (endVal - startVal) * (i / lastArray); 
                sv[1] = newVal; 
            }

            this.dataByTime[parseInt(sv[0])][metricId] = sv[1];
        })
    }

    getDataAtTime(origX) {
        for (var i = 0; i < this.state.allHistoricTimes.length; i++) {
            if (this.state.allHistoricTimes[i] > origX) {
                this.newX = this.state.allHistoricTimes[i];

                break;
            }
            if (this.newX != this.prevX)
            {
                this.prevX = this.newX;
                this.forceUpdate();
            }
        }
    }

    toggleMetricHide(stationTagId) {

        console.log('toggleHiddenTag ' + stationTagId, this.state.scadaGroups);

        var tempGroup = this.state.scadaGroups;

        tempGroup.map(x => {
            if (x.stationTagId == stationTagId) {
                x.hideMetric = !x.hideMetric
            }
        })

        this.setState({ scadGroups: tempGroup });

        const metricInfo = { stationTagId: stationTagId }
        ApiPost('Monitoring', 'ToggleHiddenMetric', metricInfo)
        .then((data) => {
            if (data) {
                console.log('metricToggled', data);
            }
        })
    }

    toggleFavMetric(metricId)
    {
        if (this.state.favourites.includes(metricId))
        {
            if (this.state.favourites.length > 1) {
                var index = this.state.favourites.indexOf(metricId);
                var array = this.state.favourites;
                array.splice(index, 1);
                this.setState({ favourites: array });

                this.refs.ScadaChart.removeChartSeries(metricId);
            }
            else {
                console.log('at least 1 metric required');
            }      
        }
        else
        {
            if (this.state.isLive) {
                console.log('addFav', metricId);
                this.setState({ favourites: this.state.favourites.concat(metricId) });
            }
            else {
                if (this.state.scadaData.find(x => x.tags.MetricId == metricId.toString())) {
                    console.log('addFav', metricId);
                    this.setState({ favourites: this.state.favourites.concat(metricId) });
                }
                else {
                    console.log('no data exists for metric')
                }
            }
            
        }
    }

    toggleLive(toggleLive) {

        this.setState({ scadaData: null });

        if (toggleLive) {
            this.setState({ isLive: true, connectionState: null, retryConnection: false }, this.getLiveData());
        }
        else {
            this.props.updateLiveConnection([]);
            this.setState({ isLive: false, retryConnection: false  });
        }
    }

    getMetricCalc(id) {
        var name = '';
        this.state.scadaGroups.map(x => {
            if (x.metricId == id) {
                name = x.calcMethod;
            }
        })
        return name;
    }

    getMetricName(id) {
        var name = '';
        if (this.state.scadaGroups) {
            this.state.scadaGroups.map(x => {
                if (x.metricId == id) {
                    name = x.name;
                }
            })
        }
        return name;
    }

    getMetricUnit(id) {
        var name = '';
        if (this.state.scadaGroups) {
            this.state.scadaGroups.map(x => {
                if (x.metricId == id) {
                    if (x.unit) {
                        name = x.unit;
                    }
                }
            })
        }
        return name;
    }

    getScadaValue(metricId, decimalPlaces) {
        //console.log('getValGroup', this.state.scadaGroups);
        var value = '-';
        if (!decimalPlaces) {
            decimalPlaces = 0;
        }
        if (this.state.isLive) {
            if (this.state.scadaData) {
                var wsValue = this.state.scadaData[metricId];
                if (wsValue != undefined && !isNaN(wsValue)) {
                    value = wsValue.toFixed(decimalPlaces);
                }   
            }
        }
        else if (!this.state.isLive && this.dataByTime) {
            if (this.dataByTime[this.newX] != undefined && this.dataByTime[this.newX][metricId] != null) {
                value = this.dataByTime[this.newX][metricId].toFixed(decimalPlaces);
            }
        }
        return value;
    }

    toggleTagMgmt(metricInfo) {
        this.setState({ tagMgmtOpen: !this.state.tagMgmtOpen, mgmtMetric: metricInfo})
    }

    toggleExportModal() {
        this.setState({ exportOpen: !this.state.exportOpen})
    }  

    renderEditMode() {
        if (this.props.commonstate.users && this.props.commonstate.users.authRole && this.props.commonstate.users.authRole.length > 0) {
            if (this.props.commonstate.users.authRole.includes("Administrator"))
            return (<ToggleSwitch smallMode={true} switchLabel="Edit Mode" onToggle={() => this.toggleEditMode()} />)
        }
        else {
            return null
        }
    }

    renderActionCollapsible(stationDet) {

        if (this.props.commonstate.users && this.props.commonstate.users.authRole && this.props.commonstate.users.authRole.length > 0) {
            if (this.props.commonstate.users.authRole.includes("Internal"))
                return (<ScadaActions alerts={stationDet.alerts} metrics={stationDet.metrics} stationDeets={stationDet} scadaGroups={this.state.scadaGroups} />)
        }
        else {
            return null
        }
    }

    render() {

        const stationDet = GetStationLiveState(this.props.commonstate, this.stationId);

        return ([
                <div className="d-flex justify-content-end" style={{ borderBottom: '1px solid rgba(99, 99, 99, 0.2)' }}>
                    <div className="mr-auto p-2">
                        <h3>Monitoring - {stationDet.name}</h3>
                        <h4>{stationDet.model ? stationDet.model : "-"}</h4>    
                    </div>
                    <div className="ml-auto p-2">
                        <div style={{marginRight: '25px'}}>
                            {this.renderEditMode()}
                        </div>
                    </div>
                </div>,

                <div className="container-fluid" style={{paddingLeft: '5px', paddingRight: '5px'}}>
                    <div className="accordionCards">

                        <ScadaChart ref="ScadaChart" {...this.state} getHistoricData={(fromDate, toDate, getFuture) => this.getHistoricData(fromDate, toDate, getFuture)} toggleFavMetric={(e) => this.toggleFavMetric(e)} getScadaValue={(metricId, decimalPlaces) => this.getScadaValue(metricId, decimalPlaces)} getMetricUnit={(id) => this.getMetricUnit(id)} getMetricName={(id) => this.getMetricName(id)} getDataAtTime={(origX) => this.getDataAtTime(origX)} toggleLive={(toggleLive) => this.toggleLive(toggleLive)} toggleGetGroups={() => this.toggleGetGroups()} toggleExportModal={() => this.toggleExportModal()} />
                        {this.renderActionCollapsible(stationDet)}
                        <ScadaCollapsibles ref="ScadaCollapsibles" stationId={this.stationId} cmdState={this.stationDet ? this.stationDet.cmdState : []} {...this.state} toggleFavMetric={(e) => this.toggleFavMetric(e)} toggleMetricHide={(e) => this.toggleMetricHide(e)} getScadaValue={(metricId, decimalPlaces) => this.getScadaValue(metricId, decimalPlaces)} getMetricUnit={(id) => this.getMetricUnit(id)} getMetricName={(id) => this.getMetricName(id)} toggleTagMgmt={(metricInfo) => this.toggleTagMgmt(metricInfo)} />

                        {this.state.tagMgmtOpen ? <ScadaTagMgmt mgmtMetric={this.state.mgmtMetric} stationDeets={stationDet} metrics={this.state.scadaGroups} editMode={this.state.editMode} isOpen={this.state.tagMgmtOpen}  toggleTagMgmt={() => this.toggleTagMgmt()} /> : null}

                        {this.state.exportOpen ? <ScadaExportModal {...this.state} stationId={this.stationId} stationDeets={stationDet} getMetricName={(id) => this.getMetricName(id)} authRole={this.props.commonstate.users.authRole} toggleExportModal={() => this.toggleExportModal()} /> : null }

                    </div>
                </div>
        ]);
    }
}

const mapStateToProps = state => {
    // Dictionary of stationId { ...asset, siteMetric[umet], alerts[] }
    return { commonstate: state.common, scada: state.common.scada }
    //state.common.header
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateLiveConnection: (siteList) => {
            dispatch(updateLiveConnection(siteList));
        },
        disconnectLive: () => {
            dispatch(disconnectLive());
        },
        sendCommand: (stationId, Command) => {
            dispatch(scadaSendCommand(stationId,Command));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScadaWF)
