import React, { Component } from 'react';
import { Button, Modal, Badge, Input, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { ApiPost, ApiGet } from '../../../api';
import moment from 'moment';
import Select from 'react-select';

export default class ScadaExportModal extends Component {

    constructor(props) {
        super(props);

        this.state = { exportGroupRate: '60m', exportValueType: 'AUTO', isExporting: false, isGMT: true, stationMetrics: null, removeSelected: true, disabled: false, stayOpen: false, sites: [] };
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        this.getAllStationMetrics();
    }

    getExportData() {

        var startDate = this.props.fromDate;
        var endDate = this.props.toDate;
        var exportData = [];
        this.setState({ isExporting: true });
        var siteArray = JSON.parse("[" + this.state.sites + "]");
        console.log('exportDataProps', this.props)

        if (this.props.metering) {
            var mapLength = siteArray.length;
            siteArray.map((x, i) => {
                if (i < 10) {
                    var dataSettings = {
                        fromDate: moment(startDate).format('DD/MM/YY HH:mm:ss'),
                        toDate: moment(endDate).format('DD/MM/YY HH:mm:ss'),
                        exportValueType: this.state.exportValueType,
                        stationIds: x,
                        groupRate: this.state.exportGroupRate,
                        reportType: 6,
                        metricIds: this.props.favourites,
                        industryId: this.props.stationDeets.industryId
                    }

                    ApiPost('Monitoring', 'GetHistoricScada', dataSettings)
                        .then((data) => {
                        console.log('scadaDataExport', data);
                        exportData = [];
                        var last = false;
                        data.historicData.results.map(x => {
                        x.series.map(y => {
                            exportData = exportData.concat(y);
                        })
                    })
                    this.downloadData(exportData, startDate, endDate, this.props.stations.find(y=> y.id == x).name, mapLength === (i + 1) ? true : false);
                    })
                }
            })
        }
        else {
            var dataSettings = {
                fromDate: moment(startDate).format('DD/MM/YY HH:mm:ss'),
                toDate: moment(endDate).format('DD/MM/YY HH:mm:ss'),
                exportValueType: this.state.exportValueType,
                stationIds: this.props.stationDeets ? this.props.stationDeets.id : 0,
                groupRate: this.state.exportGroupRate,
                reportType: 1,
                metricIds: this.props.favourites[this.props.stationDeets.id],
                industryId: this.props.stationDeets.industryId
            }

            ApiPost('Monitoring', 'GetHistoricScada', dataSettings)
                .then((data) => {
                    console.log('historicData', data);
                    exportData = [];
                    data.historicData.results.map(x => {
                        x.series.map(y => {
                            exportData = exportData.concat(y);
                        })
                    })
                    this.downloadData(exportData, startDate, endDate, false, true);
                })
        }
    }

    downloadData(exportData, startDate, endDate, name, last) {

        var dataTimeGrouped = [];
        var selectedMetrics = [];
        var csv;

        exportData[0].values.map(i => {
            dataTimeGrouped[i[0]] = [];
            if (this.state.isGMT) {
                dataTimeGrouped[i[0]].push(moment.utc(i[0]).format('DD/MM/YYYY HH:mm:ss'));
            }
            else {
                dataTimeGrouped[i[0]].push(moment(i[0]).format('DD/MM/YYYY HH:mm:ss'));
            }
        })
        exportData.map(i => {
            selectedMetrics.push(parseInt(i.tags.MetricId));
            i.values.map(x => {
                if (x[1]) {
                    var tempT = dataTimeGrouped[x[0]]
                    if (tempT != undefined) {
                        dataTimeGrouped[x[0]].push(x[1])
                    }
                    else {
                        var r = Math.round(x[0] / 1000 / 60 / 10) * 10 * 60 * 1000; //TEMP FIX TO GET NEAREST HALF HOUR 20/03/24
                        dataTimeGrouped[r].push(x[1])
                    }
                }
                else {
                    dataTimeGrouped[x[0]].push('')
                }
            })
        })

        let title = ["Date"];

        selectedMetrics.map(f => {
            title.push(this.state.stationMetrics[f].metricName);
        })

        csv += title.join(",") + "\r\n";

        Object.keys(dataTimeGrouped).map(time => {
            let row = dataTimeGrouped[time].join(",");
            csv += row + "\r\n";
        })

        csv = csv.replace("undefined", "");

        var fileName = (this.props.stationDeets ? this.props.stationDeets.name : name) + ' ' + this.state.exportGroupRate + ' export ' + moment(startDate).format('DD-MM-YYYY') + ' - ' + moment(endDate).format('DD-MM-YYYY');
        var a = window.document.createElement("a");
        var data = new Blob([csv], { type: 'text/csv' });
        a.href = window.URL.createObjectURL(data);
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        if (last) {
            this.props.toggleExportModal();
            this.setState({ isExporting: false });
        }
    }

    onIntervalChange(e) {
        console.log('interval', e.target.value);
        this.setState({exportGroupRate: e.target.value})
    }

    getAllStationMetrics() {
        ApiGet('Monitoring', 'GetMetrics')
        .then((data) => {
        this.setState({ stationMetrics: data });
        })
    }

    onTypeChange(e) {
        console.log('type', e.target.value);
        this.setState({ exportValueType: e.target.value })
    }

    onCheck() {
        this.setState({ isGMT: !this.state.isGMT });
    }

    mapStationOptions() {
        var options = [];
        this.props.stations.map(x => {
            options.push({ label: x.name, value: x.id });
        })
        return options;
    }

    handleSelectChange(sites) {
        this.setState({ sites });
    }

    getExportIntervals() {
        var interArray = [<option value="60m">60 mins</option>, <option value="30m">30 mins</option>, <option value="10m">10 mins</option>];
        var duration = moment.duration(this.props.toDate.diff(this.props.fromDate)).asHours();

        if (this.props.authRole && this.props.authRole.length > 0 && this.props.authRole.includes("Administrator")) {
            interArray.push(<option value="5m">5 mins</option>);
            interArray.push(<option value="1m">1 min</option>);
            interArray.push(<option value="30s">30 Secs</option>);
            interArray.push(<option value="10s">10 Secs</option>);
            interArray.push(<option value="6h">6 Hours</option>);
            interArray.push(<option value="12h">12 Hours</option>);
            interArray.push(<option value="24h">1 day</option>);
            interArray.push(<option value="168h">7 day</option>);
        }
        else {
            if (duration <= 768) {
                interArray.push(<option value="5m">5 mins</option>);
                interArray.push(<option value="1m">1 min</option>);
            }
            if (duration <= 192) {
                interArray.push(<option value="30s">30 Secs</option>);
                interArray.push(<option value="10s">10 Secs</option>);
            }
        }

        return (interArray)
    }

    render() {

        return (
            <Modal isOpen={true} autoFocus={false}>
                <ModalHeader>Export Data</ModalHeader>
                <ModalBody>
                    {this.props.metering ?
                        [<Row>
                            <Col sm="3">
                                <b>Sites</b>
                            </Col>
                            <Col sm="9">
                                <Select
                                    closeOnSelect={!this.state.stayOpen}
                                    disabled={this.state.disabled}
                                    multi
                                    onChange={this.handleSelectChange}
                                    options={this.mapStationOptions()}
                                    placeholder="Select site(s)"
                                    removeSelected={this.state.removeSelected}
                                    clearable={false}
                                    simpleValue
                                    value={this.state.sites} />
                            </Col>
                        </Row>,<br/>] : null}
                        <Row>
                        <Col sm="3">
                            <b>Interval</b>
                        </Col>
                        <Col sm="9">
                            <select className="form-control" onChange={e => this.onIntervalChange(e)}>
                                {this.getExportIntervals()}
                            </select>
                        </Col>
                    </Row>
                    <br />
                    {!this.props.metering ?
                    [<Row>
                        <Col sm="3">
                            <b>Values</b>
                        </Col>
                        <Col sm="9">
                            <select className="form-control" onChange={e => this.onTypeChange(e)}>
                                <option value="AUTO">Based on data type</option>
                                <option value="FIRST">First</option>
                                <option value="LAST">Last</option>
                                <option value="MEAN">Avg</option>
                                <option value="MIN">Min</option>
                                <option value="MAX">Max</option>
                            </select> 
                        </Col>
                    </Row>,<br/>]
                    : null}
                    <Row>
                        <Col sm="3">
                            <b>GMT Time</b>
                        </Col>
                        <Col sm="9" style={{paddingLeft:'35px'}}>
                            <Input type="checkbox"  checked={this.state.isGMT ? 'checked' : ''}  onClick={(e) => this.onCheck(e)} />
                        </Col>
                    </Row>
                    {this.props.metering ?
                    <Row>
                        <Col sm="12">
                            <br/>
                            <span>Please note: Exports will be saved in seperate files.</span><br/>
                            <span>There is a max of 10 sites per export.</span>
                        </Col>
                    </Row> : null}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" style={{marginRight: 'auto'}} onClick={() => this.props.toggleExportModal()}>Cancel</Button>
                    <Button color="primary" onClick={() => this.getExportData()}>Export {this.state.isExporting ? <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} /> : null}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
