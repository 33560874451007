import React, { Component } from 'react'
import moment from 'moment'
import ResponseLevel from '../../../components/ResponseLevel'
import { Col, Row, Container, Button } from 'reactstrap'
import Collapsible from 'react-collapsible'
import { ApiPost, getConfigItem } from '../../../api'
import helpers from '../../../utils/helpers'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import MDChecklist from '../../../components/MDChecklist'
import CalendarModal from './CalendarModal'
import NonEventModal from './NonEventModal'
import DurationTracker from './DurationTracker'
import { fieldServiceReportStatuses } from '../../../configuration/enums'

class ActivityCards extends Component {
    constructor(props) {
        super(props);
        //ACTIVITY TYPE 1 = ENGINEERSCHEDULE

        this.state = { activityData: [], newEventType: 51, errorMsg: null, eventStartTime: null, eventEndTime: null, checklist: null, eWorkCard: null, jobStatus: null, todayDate: moment().format('YYYY-MM-DDT00:00:00'), keepOpenCollapse: [] }
    }

    componentDidMount() {
        this.setState({ activityData: this.props.activityData })
    }

    componentDidUpdate(pp, ps) {
        if ((pp.activityData.length != this.props.activityData.length) || (pp.isLoading != this.props.isLoading)) {
            this.setState({ activityData: this.props.activityData })
        }
    }

    wcGo(id) {
        this.props.history.push('Workcards/' + id, { history: [{ id: id, model: "acCard", label: this.props.activityType > 0 ? "Schedule" : 'Calendar', path: "/EngineerSchedule", mdFilter: [] }] })
    }

    wcAction(id, action, view, reload) {
        ApiPost('Operations', 'SetWorkCardAction', { id, action })
        .then((data) => {
            this.props.reloadData()
            if(action == 'offsite'){
                setTimeout(() => this.navigateFSR(id), 500)
            }
        })
    }

    checkOnSite() {
        var alreadyOnSite = false
        this.state.activityData.map(x => {
            if (x.userStatus && x.userStatus.onSiteTime != null && x.userStatus.offSiteTime == null && moment(x.userStatus.onSiteTime).day() == moment().day()) {
                alreadyOnSite = true
            }
        })
        return alreadyOnSite
    }

    openCheckList(job, action) {
        if (!this.checkOnSite()) {
            this.setState({ checklist: <MDChecklist detailId={job.recordId} showPreCheck={true} preCheckOnly={true} onComplete={() => this.wcAction(job.calId, action, false, true)} /> }, () => console.log('cl', this.state.checlist))
        }
        else {
            alert('Please sign off any existing jobs before signing on to this job.')
        }
    }

    getJobAction(job) {
        var buttonArray = []
        if ((moment(job.activityDate).format('DD/MM/YY') == moment().format('DD/MM/YY')) || (job.userStatus && job.userStatus.onSiteTime)) 
        {
            if (job.userStatus && job.userStatus.onSiteTime == null) {
                buttonArray.push(<button key='onsiteBtn' className="btn btn-default" onClick={() => { this.openCheckList(job, 'onsite'); }}><i className='fad fa-user-check faBG' /> On Site</button>)
            }
            else if (job.userStatus && job.userStatus.offSiteTime == null) {
                buttonArray.push(<DurationTracker key='trk' startTime={job.userStatus.onSiteTime} />)
                buttonArray.push(<button key='btnOfsite' className="btn btn-default" onClick={() => this.wcAction(job.calId, 'offsite', true)}><i className='fad fa-user-times faBR' />Offsite</button>)
            }
            else if (job.userStatus && job.userStatus.onSiteTime && job.userStatus.offSiteTime) {
                buttonArray.push(<DurationTracker key={'trkF' + job.userStatus.onSiteTime + job.userStatus.offSiteTime} startTime={job.userStatus.onSiteTime} endTime={job.userStatus.offSiteTime} allowEdit={true} timeId={job.userStatus.calendarEventUserId} reload={()=>this.props.reloadData()} />)
            }
        }

        return buttonArray
    }

    updateCard(id, value, attrib) {
        var tempAD = this.state.activityData
        var adIndex = tempAD.findIndex(x => x.calId == id)
        tempAD[adIndex][attrib] = value
        this.setState({ activityData: tempAD })
    }

    removeCard(id) {
        var tempAD = this.state.activityData
        tempAD = tempAD.filter(x => x.calId != id)
        console.log('tempAd', tempAD)
        this.props.removeEvent(id)
        this.setState({ activityData: tempAD }, () => this.resetCard())
    }

    resetCard() {
        this.setState({ newEventType: null, newEventStation: null, eventEndTime: null, eventStartTime: null, jobStatus: null, eWorkCard: null })
    }

    setTime(type, v) {
        var value = v.target.value
        if (this.state[type] != null && value != this.state[type]) {
            v.currentTarget.blur()
        }
        this.setState({ [type]: value })
    }

    saveEvent(type, eventDetail) {
        var required = { new: ['newEventType', 'deviceId', 'eventEndTime', 'eventStartTime'], existing: ['eWorkCard'], modify: ['eventStartTime','eventEndTime'], other: [] }
        var submitOK = true, timeError = false
        required[type].map(x => {
            if (!eventDetail[x]) {
                submitOK = false
            }
        })
        if (type != 'other' && (this.state.eventEndTime < this.state.eventStartTime)) {
            submitOK = false
            timeError = true
        }
        console.log('submitOK', submitOK)
        if (submitOK) {
            this.props.saveEvent(eventDetail)
            this.setState({ errorMsg: null })
        }
        else {
            this.setState({ errorMsg: timeError ? 'Check times' : 'Complete all inputs' })
        }

    }

    getCard(y) {
        if (this.props.isCompact) {
            if (y.preSelect) {
                return (
                    <div className='cardSelect' >
                        {this.props.calEventTypes.map(c => {
                            return (<button key={'btn'+c.name} type="button" className="btn btn-secondary" style={{minWidth:'80px'}} onClick={() => { this.updateCard(y.calId, false, 'preSelect'); this.updateCard(y.calId, c.id, 'calEventType'); }}>{c.name}</button>)
                        })}
                    </div>)
            }
            else {
                if (y.calEventType == 1) {
                    return this.getWorkcardCard(y)
                }
                else {
                    return this.getOtherCard(y)
                }
            }
        }
        else {
            if (y.newEvent) {
                return this.getWorkcardCard(y)
            }
            else {
                return this.getFullsizeCard(y)
            }
        }
    }

    getOtherCard(y) {
        var cInfo = this.props.calEventTypes.find(x => x.id == y.calEventType)
        return(
        y.selectEvent ?
            <div style={{ width: '100%' }}>
                <Col xs={12}>
                        <NonEventModal {...this.props} job={y} cancel={(e) => y.editEvent ? this.updateCard(y.calId, false, 'selectEvent') : this.removeCard(e)} saveEvent={(e, a) => this.saveEvent(e, a)} errorMsg={this.state.errorMsg} calEventTypes={this.props.calEventTypes} removeEvent={(e, u) => this.props.removeEvent(e, u)} />
                </Col>
            </div>
            :
            <div style={{ padding: '0px 12px 0px 8px', display:'flex', width:'100%' }}>
                <i style={{ color: JSON.parse(cInfo.config) ? JSON.parse(cInfo.config).colour : '#CCC', marginRight: '5px' }} className={JSON.parse(cInfo.config) && JSON.parse(cInfo.config).icon} />
                <span className='wcNoFlow' style={{ width: '100%' }} onClick={() => { this.updateCard(y.calId, true, 'selectEvent'); this.updateCard(y.calId, true, 'selectEvent'); this.updateCard(y.calId, true, 'editEvent'); }}>{y.note}</span>
                {y.scheduledTime && <b className='wcNoFlow' style={{ width: '100%' }}>{moment(y.startTimestamp).format('HH:mm')} - {moment(y.endTimestamp).format('HH:mm')}</b>}
            </div>
        )
    }

    getWorkcardCard(y) {
        var wcType = y.workcard ? y.workcard.responseLevel : ''
        var wcI = this.getTypeInfo(wcType)
        return (
            <div style={{ width: '100%', display:'flex' }}>
                <div className='cardTag' style={{ backgroundColor: wcI && wcI.colour ? wcI.colour : '#ccc' }} />
                <Col style={{marginLeft:'10px', paddingRight:'10px'}} xs={11}>{
                        y.selectEvent
                        ?
                            <div className='cardSelect' >
                                <button type="button" className="btn btn-secondary" style={{ float: 'left' }} onClick={() => { this.updateCard(y.calId, false, 'selectEvent'); this.updateCard(y.calId, true, 'newEvent'); }}>New Job</button>
                                <button type="button" className="btn btn-secondary" style={{ float: 'right' }} onClick={() => { this.updateCard(y.calId, false, 'selectEvent'); this.updateCard(y.calId, true, 'existingEvent'); }}>Existing Job</button>
                            </div>
                        :
                        y.newEvent || y.existingEvent ?
                            <CalendarModal {...this.props} job={y} cancel={(e) => this.removeCard(e)} saveEvent={(e, a) => this.saveEvent(e, a)} errorMsg={this.state.errorMsg} removeEvent={(e, u) => this.props.removeEvent(e, u)} />
                        :
                        this.getAllocatedWorkcard(y, wcType, wcI)}
                </Col>
            </div>)
    }

    navigateFSR(id) {
        this.props.history.push('FieldServiceReport/' + id, { history: [{ id: id, model: "acCard", label: this.props.activityType > 0 ? "Schedule" : 'Calendar', path: "/EngineerSchedule", mdFilter: [] }] })
    }

    getFullsizeCard(y) {
        if (y.workcard) {
            var wcI = this.getTypeInfo(y.workcard.responseLevel)
            var workCardStatuses = this.props.workStatuses.find(x => x.id == y.workcard.responseLevel) ? this.props.workStatuses.find(x => x.id == y.workcard.responseLevel).options : []
            var wcStatus = workCardStatuses.find(x => x.id == y.jobStatus) ? workCardStatuses.find(x => x.id == y.jobStatus).name : 'Status'
            return (
                <div style={{ width: '100%' }}>
                    <Row style={{ margin: '0 5px' }}>
                        <Col xs={8} sm={10}>
                            <Row style={{alignItems:'center'}}>
                                <Col xs={6} sm={3} style={{ display: 'flex', alignItems: 'flex-start', marginTop: '3px', padding: 0 }}>
                                    <ResponseLevel key={'response' + y.workcard.engineerLogId} level={wcI.name} icon={wcI.icon} style={{ cursor: 'default', backgroundColor: wcI.colour, width: '100%' }} />
                                </Col>
                                <Col xs={6} sm={7}>
                                    <Row><Col><b>{wcStatus}</b></Col></Row>
                                    <Row style={{ marginTop: '-6px' }}><Col>{y.scheduledTime && <span style={{ fontSize: '1rem' }}>{moment(y.startTimestamp).format('HH:mm')} - {moment(y.endTimestamp).format('HH:mm')}</span>}</Col></Row>
                                </Col>
                            </Row>
                            <div onClick={() => this.navigateFSR(y.calId)} style={{cursor:'pointer', marginTop:'5px'}}>
                            {y.users &&
                                <div>
                                    <Row>
                                        <h6>Allocated</h6>
                                    </Row>
                                    <Row>
                                        <span style={{ fontSize: '1rem' }}>{this.getUserNamesAndTimes(y.usrsInfo)}</span>
                                    </Row>
                                </div>
                            }
                                <Row style={{ cursor: 'pointer' }} >
                                    <Col xs={12}>
                                        {y.note && <div><Row className='wcInfo'><h6>Dispatch Note</h6></Row><Row>{y.note}</Row></div>}
                                        {y.workCarriedOut && <div><Row className='wcInfo'><h6>Work Carried Out</h6></Row><Row>{y.workCarriedOut}</Row></div>}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={4} sm={2} style={{padding:0}}>
                            <div className="scheduleButtons">
                                {this.props.activityType == 1 ? this.getJobAction(y) : !this.props.isCompact && <button key='viewBtn' className="btn btn-default" onClick={() => this.wcGo(y.workcard.engineerLogId)}>View Job</button>}
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
        else if (this.props.calEventTypes) {
            var eTData = this.props.calEventTypes.find(x => x.id == y.calEventType)
            var eTConfig = eTData && eTData.config ? JSON.parse(eTData.config) : {icon: 'fa fa-user', colour: '#ccc'}
            return (
                <div style={{ width: '100%', padding: '5px' }}>
                    <Row>
                        <Col xs={6} sm={3} >
                            <ResponseLevel level={eTData.name} icon={eTConfig.icon} style={{ cursor: 'default', backgroundColor: eTConfig.colour, width: '100%' }} />
                        </Col>
                        <Col xs={6} sm={7} style={{display:'flex',alignItems:'center',marginLeft:'-20px'}}>
                            <span style={{ fontSize: '1rem' }}>{moment(y.startTimestamp).format('HH:mm')} - {moment(y.endTimestamp).format('HH:mm')}</span>
                        </Col>
                    </Row>
                    <Col>{y.note && <div><Row className='wcInfo'><h6>Note</h6></Row><Row>{y.note}</Row></div>}</Col>
                </div>)
        }
    }

    getTypeInfo(typeId) {
        var c = { name: '', colour: null, icon: null }
        if (this.props.eventTypes) {
            var wcTInfo = this.props.eventTypes.find(x => x.id == typeId)
            if (wcTInfo && wcTInfo.config) {
                var typeConfig = JSON.parse(wcTInfo.config)
                Object.keys(c).map(a => {
                    c[a] = typeConfig[a] && typeConfig[a]
                })
                c.name = wcTInfo.name
            }
        }
        return c
    }

    getUserNames(y) {
        var nameS = ""
        if (this.props.users) {
            var uni = [...new Set(y)];
            uni.map(e => {
                if (this.props.users.find(x => x.id == e)) {
                    nameS += this.props.users.find(x => x.id == e).name + ", "
                }
            })
            nameS = nameS.substring(0, nameS.length - 2)
        }
        return nameS
    }

    getUserNamesAndTimes(y) {
        var nameS = ""
        if (this.props.users) {
            var uni = [...new Set(y)];
            uni.map(e => {
                if (this.props.users.find(x => x.id == e.userId)) {
                    nameS += this.props.users.find(x => x.id == e.userId).name + (e.onSiteTime ? (' (' + helpers.parseGMTTime(e.onSiteTime).format('HH:mm') + " - " + (e.offSiteTime ? helpers.parseGMTTime(e.offSiteTime).format('HH:mm') : 'Now') +') ') : '') + ", "
                }
            })
            nameS = nameS.substring(0, nameS.length - 2)
        }
        return nameS
    }

    getEditCalEvent() {
        var y = this.state.activityData.find(x => x.calId == this.state.activeEdit)
        if (y) {
            console.log('y',y)
            return <CalendarModal key={'cm'+y.calId} {...this.props} job={y} cancel={(e) => this.setState({ activeEdit: null })} saveEvent={(e, a) => this.saveEvent(e, a)} errorMsg={this.state.errorMsg} edit={true} />
        }
        else {
            console.log('cant find cal event')
        }
    }

    getFSRStatus(job) {
        var fsrStatus = '', statusColor = '#666', toolTip = null

        if (job.fsrStatus) {
            fsrStatus = fieldServiceReportStatuses.find(x => x.id == job.fsrStatus).name

            if (job.fsrStatus == 1 && (moment(job.startTimestamp) < moment().add(-1, 'hours'))) {
                statusColor = '#EE6352'
                toolTip = 'Engineers are now overdue onsite'
            }
            else if (job.fsrStatus == 2 && job.userStatus) {
                statusColor = '#44AF69'
                toolTip = 'Onsite for ' + helpers.parseDuration(job.userStatus.onSiteTime, moment().utc())
            }
            else if (job.fsrStatus == 3 && job.userStatus) {
                toolTip = 'Onsite time ' + helpers.parseRange(job.userStatus.onSiteTime, job.userStatus.offSiteTime)
            }
        }

        return <b className='wcNoFlow' title={toolTip} style={{ color: statusColor, cursor: 'pointer' }}>{fsrStatus}</b>
    }

    getAllocatedWorkcard(y, wcType) {
        var workCardStatuses = this.props.workStatuses.find(x => x.id == wcType) ? this.props.workStatuses.find(x => x.id == wcType).options : []
        var wcStatus = workCardStatuses.find(x => x.id == y.jobStatus) ? workCardStatuses.find(x => x.id == y.jobStatus).name : y.alertName
        return (
            <div onClick={() => this.setState({ activeEdit: y.calId })} >
                <Row>
                    <Col xs={12}>
                        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <i className={'fas ' + (this.props.siteToggle == '2' ? 'fa-map-marker-alt' : 'fa-users')} style={{ fontSize: '12px' }} />
                                <span className='wcNoFlow' style={{ marginLeft: '5px', width: '190px' }}>{this.props.siteToggle == '2' ? y.stationName : this.getUserNames(y.users)}</span>
                            </div>
                            {this.props.displayBoth &&
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <i className={'fas ' + (this.props.siteToggle == '1' ? 'fa-map-marker-alt' : 'fa-users')} style={{ fontSize: '12px' }} />
                                    <span className='wcNoFlow' style={{ marginLeft: '5px', width: '190px' }}>{this.props.siteToggle == '1' ? y.stationName : this.getUserNames(y.users)}</span>
                                </div>
                            }
                            {this.getFSRStatus(y)}
                        </Row>
                        {/*{!this.props.compactMode &&*/}
                        {/*    <Row>*/}
                        {/*        <span className='wcNoFlow' style={{ color: '#0a92b1', marginTop: '-3px', width: '110px' }}>{wcStatus}</span>*/}
                        {/*    </Row>*/}
                        {/*}*/}
                    </Col>
                </Row>
                <Row>
                    {this.props.activityType == 1 ?
                        <div style={{ lineHeight: 1.3 }}>
                            <b className='wcNoFlow'>{wcStatus}</b><br />
                            <Row>{y.workcard.timeStatus}</Row>
                        </div>
                        :
                        !this.props.compactMode &&
                        <div className='cardDetail' >
                            <span className='wcNoFlow' style={{ width: '72%' }}>{y.note}</span>
                            {y.scheduledTime && <b className='wcNoFlow' >{moment(y.startTimestamp).format('HH:mm')} - {moment(y.endTimestamp).format('HH:mm')}</b>}
                        </div>
                    }
                </Row>
            </div>)
    }

    getCardHeader(x) {
        return (
            <div>
                <Row key={'row' + x.stationName} style={{ margin: '0 0 0 1px', alignItems: 'center' }}>
                    <Col xs={8} sm={6}><Row><h5 style={{ marginBottom: 0 }}>{x.stationName} </h5></Row></Col>
                    <Col xs={4} sm={{ size: 2, offset: 4 }} style={{ paddingRight: 0, textAlign: 'right' }}></Col>
                </Row>
            </div>)
    }

    addEvent() {
        var tempData = this.state.activityData, nDate = moment().format('DD/MM/YY')
        tempData.push({ date: nDate, activityDate: nDate, calId: 9999, deviceId: 9999, newEvent: true, users: null, preSelect: false, adHoc: true, workcard: { engineerLogId: 9999 }, calEventType: 1 })
        this.setState({ activityData: tempData })
    }

    checkOpenCollapse(activityData, dateS) {
        var open = false
        if (this.state.todayDate == dateS) {
            open = true
        }
        else {
            activityData.map(a => {
                if (a.userStatus && a.userStatus.onSiteTime != null && a.userStatus.offSiteTime == null) {
                    open = true
                    if (!this.state.keepOpenCollapse.includes(a.calId)) {
                        this.setState({ keepOpenCollapse: [a.calId, ...this.state.keepOpenCollapse] })
                    }
                }
                else if (this.state.keepOpenCollapse.includes(a.calId)) {
                    open = true
                }
            })
        }
        return open
    }

    render() {
        const { activityData, activeEdit, todayDate } = this.state
        var dateS, deviceS, fList = [], dateCases = [], collapseCount = 0
        //activityType 1 == engineerView, 0 == calendar view
        if (activityData.length > 0) {
            //if (!activityData.find(x => x.deviceId == 9999999)) {
                activityData.push({ activityDate: '2999-01-11T00:01:01.100', deviceId: 9999999 })
            //}
            activityData.map((x, i) => {
                if (x.calId == 9999) {
                    console.log('x', x)
                    console.log('dateS',dateS)
                }
                if (x.activityDate != dateS || x.activityDate == null)
                {
                    if (dateCases.length > 0) {
                        if (this.props.activityType == 1 || this.props.activityType == 0) {
                            var open = this.props.activityType == 0 ? (dateS == todayDate) : (this.checkOpenCollapse(activityData.filter(x => x.activityDate==dateS),dateS))
                            fList.push
                            (<div key={'d' + x.activityDate + x.calId} className="accordionCards" id={i}>
                                <Collapsible trigger={<div key={i}>{!this.props.isCompact && <div><span key={'s' + x.activityDate} style={{ fontSize: '1.5rem', marginRight: '5px' }}>{moment(dateS).format('Do MMMM YYYY')}</span><span key={'b' + x.activityDate} className='badge badge-pill' style={{ backgroundColor: '#666', fontSize: '11px', verticalAlign: 'text-top', marginTop: '2px' }}>{dateCases.length}</span></div>}</div>} transitionTime={200} open={open} overflowWhenOpen='visible' >
                                    {dateCases}
                                </Collapsible>
                            </div>)
                            collapseCount++
                        }
                        else {
                            fList.push(<div key={i}>{!this.props.isCompact && <span style={{ fontSize: '1.5rem', marginRight: '5px' }} key={'h3' + dateS}>{moment(dateS).format('Do MMMM YYYY')}</span>}{dateCases}</div>)
                        }
                    }
                    dateCases = []
                }
                if (x.deviceId != deviceS || x.activityDate != dateS || x.activityDate == null) {
                    deviceS = x.deviceId
                    dateS = x.activityDate
                    var tCases = activityData.filter(z => { return z.deviceId == x.deviceId && z.activityDate == dateS })
                    if (tCases.length && tCases[0].calId) {
                        dateCases.push(
                            <Container key={'contain' + x.calId} className={this.props.isCompact ? "activityCardCompact" : "activityCard"}>
                                {!this.props.isCompact && this.getCardHeader(x)}
                                {tCases.map((y, j) => {
                                    return (
                                        <Row key={y.calId + '_' + j} className={this.props.isCompact ? "activityRowCompact" : "activityRow"}>
                                            {this.getCard(y)}
                                        </Row>)
                                })}
                            </Container>
                        )
                    }
                }
            })
        }
        else {
            fList.push(<div style={{ textAlign: 'center', marginTop: '50px' }} key='noResDiv'><h5>No result matching filter</h5></div>)
        }
        if (activeEdit) {
            fList.push(this.getEditCalEvent())
        }

        return (<div>
                    {this.props.activityType == 0 && <button type="button" className="btn btn-info" style={{marginTop:'-40px', float:'right'}} onClick={() => this.addEvent()}><i className="fa fa-plus"></i> Add Event</button>}
                    {fList}
                    {this.state.checklist}
                </div>)
    }
}

export default (ActivityCards)
