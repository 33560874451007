import React from 'react'

const LiquidLevel = (props) => {

    const percent = props.valuePercent > 0 ? props.valuePercent : 0
    const topVal = -percent + 'px'
    const colour = props.rendererDetails && props.rendererDetails.colour ? props.rendererDetails.colour : '#0a92b1'

    const halfsize = (props.size * 0.5);

    return (
        <div className='dCard'>
            <div className="liquidHolder">
                <div className="liquid" style={{ top : topVal, background: colour }}></div>
            </div>
            <div style={{ position: 'absolute', color: percent > 69 && '#FFF' }}>
                <div className="donutchart-text" x={halfsize} y={halfsize + 5} style={{ textAnchor: 'middle' }} >
                    <span className="donutchart-text-val">{props.value}</span><br/>
                    <span className="donutchart-text-percent">{props.units}</span>
                </div>
            </div>
        </div>
     )
}

export default LiquidLevel