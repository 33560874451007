import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment'
import helpers from '../../../utils/helpers'

class AlertWidget extends Component {

    constructor(props) {
        super(props);
    }

    getAlertType(id) {

        var at = {
            2:"Meter Warning Alert",
            3:"Meter Fault Alert",
            5:"Adhoc Maintenance",
            6:"No Data Sync",
            7:"Meter Fault Alert",
            8:"Meter Comms Alert",
            11:"Controller Alert",
            12:"Invalid Warning",
            13:"Controller Comms",
            14:"Power Supply",
            15:"Force majeure",
            16:"No Export",
            17:"Invalid Fault",
            18:"Paused Mode",
            19:"Manual Mode",
            21:"VMP4400 Alert",
            22:"TMC2 Alert",
            23:"TMC3 Alert",
            24:"Grid Alert",
            25:"G59 Alert",
            27:"VMP3500 Alert",
            28:"TMC3-Bonus",
            29:"Nordex",
            31:"No Generation",
            32:"No Biomethane",
            33:"Producing but No Biomethane",
            51:"Service",
            52:"Scheduled Maintenance",
            53:"Commisioning",
            54:"Investigation",
            55:"Stat Inspection",
            56: "Bolt Torque",
            58: "SCADA",
            60:"Scheduled Grid Maintenance"
        }

        return at[id]
    }
    getUser(id) {

        var at = {
            1:"Admin",
            2:"Aaron Devaney",
            3:"Jonny Barr",
            4:"Colm McClean",
            5:"Rob McMillan",
            6:"Cliona Gormley",
            7:"James Watt",
            8:"Bettina Horb",
            13:"Kris Perry",
            22:"Ian Hutchinson",
            25:"Duncan Plant",
            39:"Kenny Nixon",
            40:"Aaron Devaney",
            43:"Opti",
            44:"Eamon Hughes",
            45:"System",
            51:"Chris OConnor",
            52:"Adam Smyth",
            56:"Sharon Gamble",
            57:"Denise McCloskey",
            58:"Kyle Murray",
            61:"Ian Hutchinson",
            62:"Rorie McBride",
            64: "Paul Millen",
            65: "Stephen McCorkell",
            164: "Francis McKeating",

        }

        if (this.props.header && this.props.header.users) {
            return this.props.header.users[id]
        }
        else {
            return at[id]
        }

    }

    getAlertColour(id) {

        if (id > 50) {
            return 'sAlert'
        }
        else {
            return 'aAlert'
        }
            
    }

    getResponse(val, note) {
        if (this.props.commonstate.users && this.props.commonstate.users.authRole && this.props.commonstate.users.authRole.length > 0) {
            if (this.props.commonstate.users.authRole.includes("Internal")) {
                return (<div className="responseBox"><div className={'levelBox response' + val}>{val == 'L99' ? <i className='fal fa-calendar-plus' /> : val == 'L100' ? <i className='fal fa-wrench' /> : val}</div><span style={{ fontSize: '12px' }}>{note}</span></div>)
            }
        }
        else {
            return null
        }
    }

    render() {
        const { alerts } = this.props.header
        return (
            <div className={"widget " + this.props.header.level} style={{width:'100%'}}>
                <p style={{margin:0,padding:'5px 0 0 5px', fontWeight:'normal'}}><strong>Open Alerts</strong></p>
                {alerts?<div>
                    {
                        Object.keys(alerts).map((stn) =>
                            <div key={stn} className="alertbox">
                                <div className={'bodybox ' + this.getAlertColour(alerts[stn][0].alertTypeId)} style={{ float:'left' }}>
                                    <h1>
                                        {alerts[stn][0].stationName}
                                    </h1>
                                    <p style={{fontWeight:'bold'}}>
                                        {alerts[stn][0].alertName != alerts[stn][0].caseRef ? alerts[stn][0].caseRef : null}
                                    </p>
                                    <p>
                                        {this.getUser(alerts[stn][0].windNiUserId)}
                                    </p>
                                </div>
                                <div className={'sidebox ' + this.getAlertColour(alerts[stn][0].alertTypeId)}>
                                    <p>{this.getAlertType(alerts[stn][0].alertTypeId)}</p>
                                    <p>{helpers.parseGMTTime(alerts[stn][0].raised).format('DD/MM/YY HH:mm')}</p>
                                </div>
                                {this.getResponse(alerts[stn][0].responseLevel, alerts[stn][0].incidentNote)}                
                            </div>
                        )
                    }
                </div>:null}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps,  
)(AlertWidget)