import React, { Component } from 'react';
import Highcharts from 'highcharts/highstock'
import addTreemap from 'highcharts/modules/treemap'
import addExporting from 'highcharts/modules/exporting'
import addIndicators from 'highcharts/indicators/indicators'
import addTrendline from 'highcharts/indicators/trendline'
addIndicators(Highcharts)
addTrendline(Highcharts)
addTreemap(Highcharts)
addExporting(Highcharts)

class DynamicChartWidget extends Component {

    constructor(props) {
        super(props)

        const cacheResult = null
    }

    getValue()
    {
        var result = this.props.widgetValue;

        if (JSON.stringify(result) == this.cacheResult)
        {
            return null;
        }

        if (result != undefined)
        {
            this.cacheResult = JSON.stringify(result)
            return result;
        }
        else
        {
            return null;
        }
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps.widgetValue || (prevProps.widgetValue && JSON.stringify(this.props.widgetValue.series) != JSON.stringify(prevProps.widgetValue.series))) {

            var updateValue = this.getValue();

            if (updateValue != null) {

                var periodTitle = this.props.periods.find(x => x.id == this.props.period)

                if (this.chart == null) {
                    var t0 = performance.now()
                    this.chart = new Highcharts["Chart"]
                        (
                            this.refs.chart,
                            updateValue
                    );
                    var t1 = performance.now()
                }
                else if (this.chart != null) {
                    if ((updateValue.series.length != this.chart.series.length) || (this.chart.subtitle.textStr != periodTitle.title)) {
                        console.log('destroying output chart: ' + this.chart.subtitle.textStr + " - " + periodTitle.title);
                        this.chart.destroy();
                        var t0 = performance.now()
                        this.chart = new Highcharts["Chart"]
                            (
                                this.refs.chart,
                                updateValue
                        );
                        var t1 = performance.now()
                        console.log(this.props.id + ' rebuild took: ', (t1 - t0))
                    }
                    else {
                        var t0 = performance.now()
                        this.chart.update(updateValue)
                        var t1 = performance.now()
                        //console.log(this.props.id + ' update took: ',(t1-t0))
                    }
                }

                if (this.chart != null && this.props.periods != null) {
                    var attribs = this.props.selectedParam.params
                    if (periodTitle && this.chart.title.textStr != periodTitle.title) {
                        this.chart.setTitle({ text: attribs.customTitle }, { text: periodTitle.title });
                    }
                }
            }

            if (this.chart) {
                this.chart.reflow();
            }
        }
    }

	componentDidMount()
    {
        var attribs = this.props.selectedParam.params
        var updateValue = this.getValue()


        if (updateValue != null)
        {

            this.chart = new Highcharts["Chart"](
                this.refs.chart,
                updateValue
            );

            if (this.props.periods && attribs)
            {
                var periodTitle = this.props.periods.find(x => x.id == this.props.period)
                if (periodTitle) {
                    this.chart.setTitle({ text: attribs.customTitle }, { text: periodTitle.title })
                }
            }
        }  
	}

    componentWillUnmount()
    {
        if (this.chart != null)
        {
            this.chart.destroy();
        }
    }

    getPeriodDots() {
        if (this.props.periods != null) {
            const dots = [];
            for (var i = 0; i < this.props.periods.length; i++) {
                if (this.props.periods[i].id == this.props.period) {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 1 }} key={i}></i>);
                }
                else {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 0.4 }} key={i}></i>);
                }
            }
            return dots;
        }
        else {
            return null;
        }
    }

    getPeriodExists() {
        if (this.props.periods != null) {
            return { cursor: 'pointer', width: '100%' };
        }
        else {
            return { width: '100%' };
        }
    }

    render() {
        return (
            <div id="resizer" style={this.getPeriodExists()}>
                <div className="chartDots">{this.getPeriodDots()}</div>
                <div ref="chart" className="default-chart-widget"/>
            </div>

        )
    }
};

export default DynamicChartWidget