import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { ApiGet } from '../api';
import IdNameDropdown from '../components/Controls/IdNameDropdown';
import { withRouter } from "react-router-dom";

class CasePicker extends Component {

    constructor(props) {
        super(props);
        this.state = { alertId: null, selCase: 0, faultCaseName: null, alertId: null, alertData: null }
        this.setFaultCase = this.setFaultCase.bind(this)
    }

    getInitData(data, newCaseId) {
        const reqAlert = { alertId: data.id, newCaseId: newCaseId, stationId: data.stationId ? parseInt(data.stationId) : null }
        ApiGet('Monitoring', 'GetAlertData', reqAlert)
            .then((data) => {
                const mdata = { alert, ...data }
                this.setState({
                    alertData: mdata,
                    alertId: alert,
                    //selCase: newCaseId,
                    faultCaseName: alert ? alert.cntrlLogMessage : '',
                });
            });
    }

    setFaultCase(id) {
        this.setState({ selCase: id });
        this.props.onChange(id);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.data != this.props.data) {
            if (this.props.data && (this.props.data.id || this.props.data.stationId)) {
                this.getInitData(nextProps.data)
            }
        }
        return true
    }

    createCase() {
        const newCase = { caseRef: this.state.faultCaseName, stationId: this.props.data.stationId }
        ApiPost('Cases', 'CreateCase', newCase)
            .then((newId) => {
                this.getInitData(this.props.data, newId);
                this.setFaultCase(newId);
            })
    }

    getFaultList(faultCases) {
        var complete = [];

        complete.push({ caseRef: 'New Case', caseId: '-' });
        complete.push({ caseRef: '───── Cases ─────', caseId: '-faU', disabled: true });
        faultCases.map(x => { if (!x.fixed) { complete.push(x) } })
        //complete.push({ caseRef: '──── Permanent ────', caseId: '-fiL', disabled: true });
        //faultCases.map(x => { if (x.fixed) { complete.push(x) } })

        return complete;
    }

    render() {
        if (!this.state.alertData && this.props.data && (this.props.data.id || this.props.data.stationId)) {
            this.getInitData(this.props.data)
        }
        const { alertData } = this.state;
        const { engineers, faultCases, recCase, recomendedCase } = (alertData ? alertData : {})

        if (alertData) {
            return (
                <div style={{ width: '100%' }}>
                    {this.props.data.caseId == null && this.props.data.id != null ?
                        <Row>
                            <Col xs={12} sm={2}><Label ><b>Recommended Case</b></Label></Col>
                            <Col xs={8} sm={4} style={{ paddingLeft: '15px' }}><Label ><b>{recomendedCase.name}</b></Label></Col>
                            <Col xs={4} sm={1}><button className="btn btn-primary" type="button" onClick={() => this.setFaultCase(recomendedCase.id)}>Use</button></Col>
                        </Row>
                        : null}
                    <Row>
                        <Col xs={12} sm={2}><Label ><b>Case</b></Label></Col>
                        <Col xs={12} sm={4}>
                            <IdNameDropdown options={this.getFaultList(faultCases)} zeroText='Select Case' valueField="caseId" displayField="caseRef" value={this.state.selCase || this.props.data.caseId} onChange={this.setFaultCase} />
                        </Col>
                    </Row>
                    {this.state.selCase == '-' ?
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs={12} sm={2}><Label ><b>Case Name</b></Label></Col>
                            <Col xs={8} sm={4} style={{ paddingLeft: '15px' }}>
                                <input className="input" className="form-control" placeholder="New Case" onChange={(event) => this.setState({ faultCaseName: event.target.value })} />
                            </Col>
                            <Col xs={2} sm={1}>
                                <button className="btn btn-primary" type="button" onClick={(e) => this.createCase(e)}>Create New</button>
                            </Col>
                        </Row>
                        : null}

                </div>
            );
        }
        else {
            return null
        }
    }
}

export default withRouter(CasePicker)
