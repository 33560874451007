import React, { Component } from 'react';
import { Button, Modal, DropdownToggle, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { ApiPost, ApiGet } from '../../../api';
import classnames from 'classnames';

export default class ScadaFaultTagMgmt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mOpen: false,  activeTab: '1'
        }
    }

    componentDidMount() {
        this.getAllControllerFaults();
        //console.log('stationDeets', this.props.stationDeets);
    }

    navToggle(tab) {
        if (this.state.activeTab != tab) {
            this.setState({ activeTab: tab });
        }
    }

    setLists(valArray, ddVal, ddName, type) {
        if (valArray)
            return (valArray.map(i => {
                if (this.state.listFilter) {
                    if (i[ddName].toLowerCase().includes(this.state.listFilter)) {
                        return (this.renderDropdownItem(i[ddVal], i[ddName], type))
                    }
                }
                else {
                    //console.log('i',i)
                    return (this.renderDropdownItem(i[ddVal], i[ddName], type))
                }
            }))
    }

    filterList(e) {
        var filterText = null;
        if (e) {
            filterText = e.target.value.toString().toLowerCase();
        }
        this.setState({ listFilter: filterText });
    }

    renderDropdownItem(value, name, type) {
        if (type == 'fault') {
            return (
                <DropdownItem key={value} value={value} onClick={(e) => this.faultSelected(e)}>{name}</DropdownItem >
            )
        }
        else if (type == 'metric') {
            return (
                <DropdownItem key={value} value={value} onClick={(e) => this.metricSelected(e)}>{name}</DropdownItem >
            )
        }
    }

    faultSelected(e) {
        console.log('selected - ' + e.target.value, this.state.controllerFaults.find(x => x.faultTagId == e.target.value));
        this.setState({ selectedFault: this.state.controllerFaults.find(x => x.faultTagId == e.target.value) }, () => this.getAllFaultMetrics());

    }

    metricSelected(e) {
        var newMetric = parseInt(e.target.value);
        this.setFaultMetric(newMetric);
        var metricSelected = this.state.faultMetrics.concat(newMetric);
        this.setState({ faultMetrics: metricSelected }, () => console.log('metricSelected', this.state.faultMetrics));
    }

    toggleM(event) {
        this.setState({
            mOpen: !this.state.mOpen,
            listFilter: null
        });
    }

    toggle() { }

    onCntrlChange(e) {
        this.setState({ controllerId: e.target.value }, () => this.getAllControllerFaults())
    }

    getAllControllerFaults() {
        console.log('getFaults', this.props.stationDeets.controllerid);
        const reqFaults = { controllerId: this.props.stationDeets.controllerid }
        ApiGet('Monitoring', 'GetControllerFaults', reqFaults)
        .then((data) => {
            console.log('controller faults', data)
            this.setState({ controllerFaults: data });
        });
    }

    getAllFaultMetrics() {
        const reqMetrics = { faultId: this.state.selectedFault.faultTagId, clientId: this.props.stationDeets.clientid }
        ApiGet('Monitoring', 'GetFaultMetrics', reqMetrics)
        .then((data) => {
            this.setState({ faultMetrics: data });
            console.log('faultMetrics', data);
        });
    }

    setFaultMetric(newMetric) {
        const reqMetrics = { faultId: this.state.selectedFault.faultTagId, clientId: this.props.stationDeets.clientid, metricId: newMetric }
        ApiPost('Monitoring', 'SetFaultMetric', reqMetrics)
            .then((data) => {
                console.log('setMetrics', data);
            });
    }

    renderSelected() {
        var metricInfo;
        if (this.state.faultMetrics) {
            return (Object.keys(this.state.faultMetrics).map(x => {
                metricInfo = this.props.scadaGroups.find(i => i.metricId == this.state.faultMetrics[x]);
                return (<div className="fav-badge" style={{ minWidth: '180px' }} key={this.state.faultMetrics[x]} ><span>{metricInfo.name}</span><i className="fa fa-times remove-metric" /></div>)
            }))
        }
    }

    render() {

        return (
            <Modal isOpen={this.props.isOpen} style={{ maxWidth: '684px' }} autoFocus={false}>
                <ModalHeader>Fault Tag Metric Management</ModalHeader>
                <ModalBody>

                    <Row style={{marginBottom: '20px'}}>
                        <Col sm="6">
                            <h6>Selected Fault</h6>
                            <h4>{this.state.selectedFault ? this.state.selectedFault.cntrlLogMessage : 'None'}</h4>
                        </Col>
                        {
                        //<Col sm="6">
                        //    <h6>Controller</h6>
                        //    <select className="form-control" id="controllerList" defaultValue={1} onChange={e => this.onCntrlChange(e)}>
                        //        <option value={1}>VMP4400</option>
                        //        <option value={2}>TMC2</option>
                        //        <option value={3}>TMC3 - Nordex</option>
                        //        <option value={4}>Grid</option>
                        //        <option value={5}>G59</option>
                        //        <option value={6}>TMC - NDK</option>
                        //        <option value={7}>VMP3500</option>
                        //        <option value={8}>TMC3 - Bonus</option>
                        //        <option value={9}>Nordex</option>
                        //    </select>
                        //</Col>
                        }
                    </Row>

                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.navToggle('1'); }}>
                                Validation
                    </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.navToggle('2'); }}>
                                Settings
                    </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row style={{ minHeight: '450px', marginTop: '20px' }}>
                                <Col sm="6">
                                    <h6>Faults</h6>
                                    <Dropdown isOpen={true} toggle={() => this.toggle()} style={{ display: 'inline-block' }}>
                                        <DropdownToggle />
                                        <input onKeyUp={(e) => this.filterList(e)} placeholder={'\uf002'} style={{ fontFamily: 'FontAwesome, Arial', width: '300px' }} />
                                        <DropdownMenu style={{ overflowY: 'scroll', width: '300px', minHeight: '400px' }}>
                                            {this.setLists(this.state.controllerFaults, 'faultTagId', 'cntrlLogMessage', 'fault')}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col sm="6">
                                    <h6>Fault Metrics</h6>
                                    {this.renderSelected()}
                                    <Dropdown isOpen={this.state.mOpen} toggle={(e) => this.toggleM(e)} style={{ display: 'inline-block' }}>
                                        <DropdownToggle style={{ display: this.state.mOpen ? 'none' : 'inline-block' }} className="ddToggleSmall"><i className="fa fa-plus btn" /></DropdownToggle >
                                        {this.state.mOpen ? <input onKeyUp={(e) => this.filterList(e)} placeholder={'\uf002'} style={{ fontFamily: 'FontAwesome, Arial', width: '300px' }} /> : null}
                                        <DropdownMenu style={{ overflowY: 'scroll', width: '300px' }}>
                                            {this.setLists(this.props.scadaGroups, 'metricId', 'name', 'metric')}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="6">

                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" style={{ marginRight: 'auto' }} onClick={() => this.props.toggleMgmt()}>Cancel</Button>
                    <Button color="primary" onClick={() => this.props.toggleMgmt()}>Save</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
