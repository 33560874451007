import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
export const API_PATH = process.env.SERVER_PATH + '/api'

class TagSettings extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        var TagFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Station Name", fieldId: "station" },  
            { label: "Tag Name", fieldId: "tag" },
            { label: "Device/Apps", fieldId: "device" }
        ];

        var TagConfig = {
            model: 'TagSettings',
            fields: TagFields,
            master: true,
            gridButton: false,
            titleField: 'tag',
            apiPath: API_PATH
        }

        return (
            <div>                
                <div>
                    <MasterDetail key='mdwc' {...TagConfig} />
                </div>
            </div>
        );
    }
}

export default (TagSettings)