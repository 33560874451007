import React, { Component } from 'react';
import WorkflowComponent from './WorkflowComponent'
import { Collapse, CardBlock, Card, CardTitle, Col, Form, FormGroup, Label, Input, FormText, Button, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';

export default class WfNote extends WorkflowComponent {

    constructor(props) {
        super(props);
        this.state = { note: ""  }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {

    }

    handleChange(ev) {
        var note = ev.target.value;
        this.setState({ note })
        this.updateState({ note })
    }

    render() {
        var msg = "";
        const { data } = this.state;
        if (data) msg = data.message;
        return (
            <div style={{width :'100%', padding:'10px'}} className="card">
                <h5 class="card-title">{this.props.title}</h5>
                <textarea type="text" name="ruleCriteria" className="form-control" cols="40" rows="5" onChange={this.handleChange} value={this.state.note} />
            </div>
        );
    }
}