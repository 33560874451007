import React from 'react';

const ScadaDashlight = (props) => {

    const desc = props.desc ? props.desc : props.metricDeets.desc.replace('*', '').replace('!', '')

    return (
        <tr key={props.i}>
            <td className="scada-star" style={{ width: '20%' }}><i className={"fa fa-star " + props.favourited} onClick={() => props.toggleFavMetric(props.metricDeets.metricId + '-' + props.metricDeets.lookupCode)} /></td>
            <td className="scada-metric-title" style={{ width: '70%' }}>{desc}</td>
            <td><div style={{ height: '25px', textAlign: 'center', lineHeight: '25px' }}><i className={"fa fa-circle dashlight " + props.dashLight} /></div></td>
        </tr>
        
    );
};


export default ScadaDashlight;
    