import React from 'react'
import { Rnd } from 'react-rnd'

const Box = (props) => {
    const { canDrag, deets, children, editBox, id, cStyle, modifyTag } = props;
    const style = {
        position: 'absolute', backgroundColor: 'white', cursor: canDrag ? 'move' : 'default',
        textAlign: 'center', borderStyle: 'solid', borderWidth: '2px', ...cStyle
    }
    const dConfig = { x: deets.l, y: deets.t, height: deets.h || 52, width: deets.w || 100 }
    return (
        <Rnd key={'rnd' + id} className='rnd-box' default={dConfig} style={style} minHeight={43} minWidth={100} disableDragging={!canDrag} enableResizing={{ bottomRight: canDrag }} onDoubleClick={() => editBox(id)} onResizeStop={(e, di, r, d) => modifyTag({ id, s: 'resize', l: d.width, t: d.height })} onDragStop={(e, d) => modifyTag({ id, s:'move', l:d.lastX, t:d.lastY })}>
            {children}
        </Rnd>
        )
}
export default Box