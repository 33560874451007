import React from "react";

export class ToggleButton  extends React.Component {
   constructor(props) {
    super(props)
    this.state = { checked: false };
    this.onToggleClick = this.onToggleClick.bind(this);
  }

  onToggleClick()
  {
    
      const { onToggle, ison } = this.props;
      const toState = (ison == undefined)?!this.state.checked:!ison;

      this.setState({checked: toState});
      if (onToggle) {
        onToggle(toState)
      }
  }

  isChecked() {
    var ret = false;
    const { ison } = this.props;
    if (ison == undefined) {
        ret = this.state.checked;
    } else {
        ret = ison;
    }
    return ret;
  }

  render() {
    const { smallMode=false, textOn, textOff, onToggle, ison, iconOn, iconOff } = this.props;
    const classn = smallMode?"toggle-but sm": "toggle-but"
    
    let iel = null;
    if (iconOn || iconOff) {
      let cl = iconOff;
      if (this.isChecked()) {
        cl = iconOn;
      } 
      iel = <i className={cl} />
    }
    return (
          (this.isChecked())?
            <button type="button" className="btn btn-primary btn-sm btn-toggle-on" onClick={() => this.onToggleClick()}>{iel}{textOn}</button>
            :
            <button type="button" className="btn btn-secondary btn-sm btn-toggle-off" onClick={() => this.onToggleClick()}>{iel}{textOff}</button>
      )
  }
}
