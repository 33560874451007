import React from 'react';
import Box from './Box'

const Container = (props) => {
    const { canDrag, tags, getValue, dM, editTag, modifyTag, devices } = props;
    const styles = {
        width: props.dimensions.imgW,
        height: props.dimensions.imgH,
        position: "relative",
        //border: "1px solid #ccc"
    }
    return (
        <div style={styles}>
            {props.editActive && <i className="fal fa-trash-alt" style={{ fontSize: '1.5rem', padding: '10px', position: 'absolute', bottom: 0, left: '10px' }} />}
            {tags && dM && Object.keys(dM).map((x, i) => {
                var dId = x.split('_')[0]
                var mId = x.split('_')[1]

                var t = tags[dId] ? tags[dId].find(a => a != null && a.metricId == mId) : null , o = dM[x]
                if (t || x.includes('9999_') || x.includes('9998_')) {
                    if (x.includes('9999_')) {
                        var cStyle = {
                            backgroundColor: o.bColour || '#f7f7f7', color: o.fColour || '', borderColor: o.oColour || '#000', zIndex: 1,
                            borderWidth: o.bWidth ? o.bWidth + 'px' : '1px', fontSize: o.tSize ? o.tSize + 'px' : '14px', fontWeight: o.tBold || 'normal', borderRadius: '6px'
                        }
                        return (
                            <Box key={'bx' + x} id={x} deets={o} canDrag={canDrag} modifyTag={(i) => modifyTag(i)} cStyle={cStyle} editBox={(e) => props.editActive && editTag(e)}>
                                <b style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: 'center', left: 0, textTransform: 'uppercase' }}>{o.title || ''}</b><br />
                            </Box>
                        );
                    }
                    else if (x.includes('9998_')) {
                        var sImg
                        if (props.templates) {
                            sImg = props.templates.find(x => x.schematicTemplateId == o.subSchem)
                            if (sImg) {
                                sImg = sImg.schematicImg
                            }
                        }
                        //SUB SCEHMATIC CONTAINER
                        var cStyle = {
                            backgroundColor: o.bColour || '#f7f7f7', color: o.fColour || '', borderColor: o.oColour || '#000', zIndex: 1, overflow: 'hidden',
                            borderWidth: o.bWidth ? o.bWidth + 'px' : '1px', fontSize: o.tSize ? o.tSize + 'px' : '14px', fontWeight: o.tBold || 'normal', borderRadius: '6px'
                        }
                        return (
                            <Box key={'bx' + x} id={x} deets={o} canDrag={canDrag} modifyTag={(i) => modifyTag(i)} cStyle={cStyle} editBox={(e) => props.editActive && editTag(e)}>
                                {sImg && <img className='subSchematicPre' src={sImg} />}
                                <i className='fas fa-project-diagram' style={{ position: 'absolute', top: '5px', cursor: 'pointer', right: '5px', fontSize: '1.5rem' }} onClick={() => props.viewSub(o.subSchem)} />
                                <b style={{ position: 'absolute', bottom: 0, width: '100%', textAlign: 'center', left: 0, textTransform: 'uppercase' }}>{o.title || ''}</b><br />
                            </Box>
                        )
                    }
                    else {
                        var cStyle = {
                            backgroundColor: o.bColour || '#FFF', color: o.fColour || '', borderColor: o.oColour || '#d9d9d9', zIndex: 2,
                            borderWidth: o.bWidth ? o.bWidth + 'px' : '2px', fontSize: o.tSize ? o.tSize + 'px' : '14px', fontWeight: o.tBold || 'normal', borderRadius: '6px'
                        }
                        var sVal = getValue(mId, -1, dId)
                        var valPercent = null
                        if (o.viz == '2' || o.viz == '3') {
                            var max = parseInt(o.mViz) || 100
                            var min = parseInt(o.nViz) || 0
                            valPercent = ((sVal - min) / (max - min)) * 100
                        }
                        var deviceName = devices.find(x => x.id == dId).name || ''
                        return (
                            <Box key={'bx' + x} id={x} deets={o} canDrag={canDrag} modifyTag={(i) => modifyTag(i)} cStyle={cStyle} editBox={(e) => props.editActive && editTag(e)}>
                                {valPercent &&
                                    <div className="scada-bullet-maincontainer" style={{ margin: 0, position: 'absolute', height: '100%', width: '100%', zIndex: -1 }}>
                                        <div className="scada-bullet-container" style={{ width: '100%' }}>
                                            <div className="scada-bullet current"
                                                style={{ width: o.viz == '2' ? valPercent + '%' : '100%', height: o.viz == '2' ? '100%' : valPercent + '%', backgroundColor: o.vColour, position: 'absolute', bottom: o.viz == '3' && 0, top: o.viz == '3' && 'auto', borderRadius: '5px 0px 0px 5px' }} ></div>
                                        </div>
                                    </div>}
                                <p style={{ margin: 0 }}>{o.title || t.name}</p>
                                <p style={{ fontSize: '0.8rem', margin: '-5px 0 -2px 0' }}>{deviceName}</p>
                                <b>{sVal}</b> <span>{t.unit && t.unit.replace('&deg;', '\u00B0')}</span>
                            </Box>
                        );
                    }
                }
                
            })}
        </div>
    );
};

export default Container;