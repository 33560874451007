import React, { Component } from 'react';
import { Col, FormGroup, Label, Input } from 'reactstrap';

const WfCheckItem = (props) => {
    const { id, name, title, values, onCheck } = props
    const value = values[id] ? values[id].replace('&deg;', '\u00B0') : ''
    return (
        <FormGroup row>
            <Col xs={3} lg={3}><Label ><b>{title}</b></Label></Col>
            <Col xs={9} lg={9}><Input type="checkbox" style={{marginTop: 0}} onClick={(e) => onCheck(e,id)} /> &nbsp;&nbsp;<Label ><b>{name}</b></Label> &nbsp;&nbsp; <Label className="badge badge-pill badge-success" style={{ fontSize:"13px" }}>{value}</Label> </Col>
        </FormGroup>
    )
}
export default WfCheckItem