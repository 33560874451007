import React, { Component } from 'react';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
class ResetsSummaryPanel extends Component {

    constructor(props) {
        super(props);
    }

    getClass(resets, allowed) {
        return resets > (allowed-1)?"danger":""
    }

    getDate(dt) {
        return dt?moment(dt).format('DD/MM/YYYY HH:mm'):'na';
    }

    renderResetsTable(resetHis) {
        if (resetHis && resetHis.resetsMonth > 0) {
            return (
                <table className="table summary-table" style={{ width: "350px",backgroundColor: "white"}}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Last</th>
                            <th>24hr</th>
                            <th>48hr</th>
                            <th>week</th>
                            <th>month</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Resets</td>
                            <td>{this.getDate(resetHis.lastRaised)}</td>
                            <td className={() => this.getClass(resetHis.resets24hours,2)}>{resetHis.resets24hours}</td>
                            <td className={() => this.getClass(resetHis.resets48hours,3)}>{resetHis.resets48hours}</td>
                            <td className={() => this.getClass(resetHis.resets1week,6)}>{resetHis.resets1week}</td>
                            <td className={() => this.getClass(resetHis.resetsMonth,10)}>{resetHis.resetsMonth}</td>
                        </tr>
                    </tbody>
                </table>
            )
        } else {
            return (
                <table className="table summary-table" style={{ width: "300px",backgroundColor: "white"}}>
                    <tbody>
                        <tr>
                            <td>No Resets in current month</td>
                        </tr>
                    </tbody>
                </table>
            )
        }
    }

    render() {
        const { message, icon, iconColor, data } = this.props;
        let resetHis = null;
        let alert = null;
        let inst = "No Instructions in Wiki";
        if (data) {
            resetHis = data.resetHistory;
            if (data.alert) {
                alert = data.alert;
                inst = data.alert.instructions;
            }
        }
        var iconStyle = {
            color: iconColor,
            float:'left',
            paddingRight:"8px"
        }
        var iconClass = icon?"fa-2x far " + icon: ""
        return (
            <Row>
                <Col xs={12} md={6} className="card">
                    {inst}
                    {(message)?
                        <div className="card-body">
                            <i className={iconClass} style={iconStyle} />
                            <b> {message}</b>
                        </div>
                        :null
                    }
                </Col>
                <Col xs={12} md={6} className="card">{this.renderResetsTable(resetHis)}</Col>
            </Row>
        )
    }
}

export default ResetsSummaryPanel;
    