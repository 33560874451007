import * as scadaActions from '../actions/scada'
import helper from '../utils/helpers'
import { Map, fromJS, List, set }  from 'immutable'

const scada = (state = { assetsLive: Map({}), assetsLiveU: Map({}), livesiteids: [], timeOut: false }, action) => {
  switch (action.type) {
    case scadaActions.UPDATE_LIVE_SAMPLE:
      var t0 = performance.now();
      //action.cmdState
      var umetdic = {}
      var metdic = {}
      var src = action.payload;
      for (var key in src) {
        if (src.hasOwnProperty(key)) {           
          var item = src[key];  
          metdic[key] = item.V;
          if (item.U) {
            umetdic[item.U] = item.V;
          }
        }
      }
      //arrDispatchBuffer = [];
      var ret = {
        ...state,
        assetsLive: state.assetsLive.set(action.siteId, metdic),
        assetsLiveU: state.assetsLiveU.set(action.siteId, umetdic),
        cmdState: {[action.siteId]: action.cmdState},
        connectionState: null,
        actCmdSuc: false
      }
      var t1 = performance.now();
      //console.log("Call to UPDATE_LIVE_SAMPLE took " + (t1 - t0) + " milliseconds.")
      return ret;
      case scadaActions.UPDATE_LIVE_SAMPLE_BATCH:
        var t0 = performance.now();
        let output = {}
        let outputU = {}
        action.payload.forEach(element => {
            let umetdic = {}
            let metdic = {}
            let src = element.payload;
            for (var key in src) {
              if (src.hasOwnProperty(key)) {           
                var item = src[key];  
                metdic[key] = item.V;
                if (item.U) {
                  umetdic[item.U] = item.V;
                }
              }
            }
            output[parseInt(element.siteId)] = metdic;
            outputU[parseInt(element.siteId)] = umetdic;
        });
        action.payload = []
        var ret = {
          ...state,
          assetsLive: state.assetsLive.merge(output),
          assetsLiveU: state.assetsLiveU.merge(outputU),
          connectionState: null,
          actCmdSuc: false
        }
        var t1 = performance.now();
        //console.log("Call to UPDATE_LIVE_SAMPLE took " + (t1 - t0) + " milliseconds.")
      return ret;
    case scadaActions.UPDATE_LIVE_CONNECTIONS:
      return {
        ...state,
        livesiteids: action.payload.siteIds,
        connected: action.payload.connected,
        connectionState: null,
        timeOut: false
      }
    case scadaActions.DISCONNECT_LIVE:
      return {
        ...state,
        livesiteids: [],
        connectionState: 'disconnected'
        }
      case scadaActions.CONNECT_ERROR:
        return {
            ...state,
            connectionState: 'error',
          }
    case scadaActions.POOR_SIGNAL:
        return {
            ...state,
            connectionState: 'signal',
          }
    case scadaActions.TIME_OUT:
        return {
            ...state,
            connectionState: 'disconnected',
            timeOut: true
          }
    case scadaActions.AUTH_ERROR:
        console.log('** AUTH ERROR **')
        return {
            ...state,
            connectionState: 'auth',
          }
    case scadaActions.ACT_CMD_SUCCESS:
        return {
            ...state,
            actCmdSuc: true
        }
    default:
      return state
  }
}
export default scada
