import React, { Component } from 'react'
//import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler'
import withDragDropContext from './withDnDContext'
//import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'
import SimpleModal from './../../../components/SimpleModal'

class JobScheduler extends Component {
    constructor(props) {
        super(props);
        let config = { schedulerWidth: '90%', resourceName: 'Device', views: [], dayStartFrom: 8, dayStopTo: 20, dayCellWidth: 100, minuteStep: 60 }
        let startDate = moment().format('YYYY-MM-DD')
        let schedulerData = new SchedulerData(startDate, ViewTypes.Day, false, false, config, { isNonWorkingTimeFunc: () => { return false } })
        schedulerData.localeMoment.locale('en');
        schedulerData.setResources(props.resources);
        schedulerData.setEvents(props.events);
        this.state = {
            viewModel: schedulerData, header: null, body: null, footer: null, open: false
        }
    }

    render() {
        const { viewModel, footer, header, body, open } = this.state
        return (<div>not building</div>)
        //return (
        //    <div>
        //        <SimpleModal {...{ header, body, footer, open }} />
        //        <Scheduler schedulerData={viewModel}
        //            prevClick={this.prevClick}
        //            nextClick={this.nextClick}
        //            onSelectDate={this.onSelectDate}
        //            onViewChange={this.onViewChange}
        //            eventItemClick={this.eventClicked}
        //            //viewEventClick={this.ops1}
        //            //viewEventText="Ops 1"
        //            //viewEvent2Text="Ops 2"
        //            //viewEvent2Click={this.ops2}
        //            updateEventStart={this.updateEventStart}
        //            updateEventEnd={this.updateEventEnd}
        //            moveEvent={this.moveEvent}
        //            newEvent={this.newEvent}
        //            onScrollLeft={this.onScrollLeft}
        //            onScrollRight={this.onScrollRight}
        //            onScrollTop={this.onScrollTop}
        //            onScrollBottom={this.onScrollBottom}
        //            toggleExpandFunc={this.toggleExpandFunc}
        //        />
        //    </div>
        //)
    }

    prevClick = (schedulerData) => {
        schedulerData.prev();
        schedulerData.setEvents(this.props.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    nextClick = (schedulerData) => {
        schedulerData.next();
        schedulerData.setEvents(this.props.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(this.props.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(this.props.events);
        this.setState({
            viewModel: schedulerData
        })
    }

    eventClicked = (schedulerData, event) => {
        alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
    };

    ops1 = (schedulerData, event) => {
        alert(`You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`);
    };

    ops2 = (schedulerData, event) => {
        alert(`You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`);
    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        //this.setState({open: !this.state.open })
        if (confirm(`Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`)) {

            let newFreshId = 0;
            schedulerData.events.forEach((item) => {
                if (item.id >= newFreshId)
                    newFreshId = item.id + 1;
            })

            let newEvent = {
                id: newFreshId,
                title: 'New event you just created',
                start: start,
                end: end,
                resourceId: slotId,
                bgColor: 'purple',
                newJob: true
            }

            schedulerData.addEvent(newEvent);
            this.props.createJob(newEvent)
            this.setState({
                viewModel: schedulerData
            })
        }
    }

    updateEventStart = (schedulerData, event, newStart) => {
        if (confirm(`Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`)) {
            schedulerData.updateEventStart(event, newStart);
        }
        this.setState({
            viewModel: schedulerData
        })
    }

    updateEventEnd = (schedulerData, event, newEnd) => {
        if (confirm(`Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`)) {
            schedulerData.updateEventEnd(event, newEnd);
        }
        this.setState({
            viewModel: schedulerData
        })
    }

    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        var w = window.event
        var action = 'move'
        if (w.ctrlKey) {
            action = 'copy'
        }
        if (confirm('Do you want to ' + action + ` the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`)) {
            if (action == 'copy') {
                let newFreshId = 0;
                schedulerData.events.forEach((item) => {
                    if (item.id >= newFreshId)
                        newFreshId = item.id + 1;
                });
                let newEvent = {
                    id: newFreshId,
                    title: event.title,
                    start: start,
                    end: end,
                    resourceId: slotId,
                    bgColor: '#4D5061',
                    newJob: false
                }
                schedulerData.addEvent(newEvent);
                this.props.createJob(newEvent)
            }
            else {
                schedulerData.moveEvent(event, slotId, slotName, start, end);
            }
            this.setState({
                viewModel: schedulerData
            })
        }
    }

    onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
        if (schedulerData.ViewTypes === ViewTypes.Day) {
            schedulerData.next();
            schedulerData.setEvents(this.props.events);
            this.setState({
                viewModel: schedulerData
            });

            schedulerContent.scrollLeft = maxScrollLeft - 10;
        }
    }

    onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
        if (schedulerData.ViewTypes === ViewTypes.Day) {
            schedulerData.prev();
            schedulerData.setEvents(this.props.events);
            this.setState({
                viewModel: schedulerData
            });

            schedulerContent.scrollLeft = 10;
        }
    }

    onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
        console.log('onScrollTop');
    }

    onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
        console.log('onScrollBottom');
    }

    toggleExpandFunc = (schedulerData, slotId) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData
        });
    }
}

export default withDragDropContext(JobScheduler)
