import React, { Component } from 'react'
import { listRequest } from '../../../configuration/enums'
import { ApiGet } from '../../../api'
import MasterDetail from 'MasterDetail'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
export const API_PATH = process.env.SERVER_PATH + '/api'

class FormSelection extends Component {

    constructor(props) {
        super(props);
        var rFields = [{ label: "Id", fieldId: "id", display: 'none' }, { label: "", fieldId: "name", width: 150 }]
        this.state = { title: null, responseFields: rFields, isChecklist: false, filterState: [], mdKey: props.formEntityId ? props.formEntityId : props.match.params.fId, customFilter: null, page: 0, pageCount: 0, listData: null, rqId: 1, isWizard: false }
    }

    getUrl(rec) {
        var url = '/FormCustom/' + this.state.mdKey + '/0'
        if (rec.eRow == null) {
            url = '/FormCustom/' + rec.id + '/1'
        }
        else if (rec.eRow != '00000000-0000-0000-0000-000000000000') {
            url = '/FormCustom/' + rec.eRow + '/1'
        }
        return url
    }

    componentDidMount() {
        this.initiate()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match) {
            if (prevProps.match.params.fId != this.props.match.params.fId) {
                this.setState({ mdKey: this.props.match.params.fId,  responseFields: [{ label: "Id", fieldId: "id", display: 'none' }, { label: "", fieldId: "name", width: 150 }] }, () => this.initiate())
            }
        }
        else if (prevProps.formEntityId) {
            if (prevProps.formEntityId != this.props.formEntityId) {
                this.setState({ mdKey: this.props.formEntityId, responseFields: [{ label: "Id", fieldId: "id", display: 'none' }, { label: "", fieldId: "name", width: 150 }] }, () => this.initiate())
            }
        }
    }

    customFilter(e, rec) {
        var id = rec.id, tableId
        var v = this.state.filterState.find(x => x.id == id) ? this.state.filterState.find(x => x.id == id).v : ''
        if (rec.fType == 10) {
            tableId = JSON.parse(rec.config).dbLookupTbl
        }
        return (<div>
                {rec.fType == 10 ?
                    <IdNameDropdown style={{ display: 'inline-block', width: '90%' }} options={this.state.listData[tableId]} zeroText='Select' valueField="id" displayField="name" onChange={(e) => this.setFilterState(id, e)} /> :
                    <input style={{ display: 'inline-block', width: '90%' }} type="text" className="form-control" defaultValue={v} onChange={(a => this.setFilterState(id, a.target.value))} />}
                    <i style={{ display: 'inline-block', cursor: 'pointer', marginLeft: '5px' }} className='far fa-search' onClick={() => this.searchFilter()} />
                </div>)
    }

    getList(rec) {
        var tableId = JSON.parse(rec.config).dbLookupTbl
        console.log('this.state.listData', this.state.listData)
        return this.state.listData[tableId]
    }

    searchFilter() {
        this.setState({ page: 0, customFilter: this.state.filterState, rqId: Math.random() }, () => console.log('searching'))
    }

    setFilterState(id, v) {
        var tFilter = this.state.filterState
        var i = tFilter.findIndex(x => x.id == id)
        if (i > -1) {
            tFilter[i] = { id, v }
        }
        else {
            tFilter.push({id,v})
        }
        this.setState({ filterState: tFilter })
    }

    getList(x) {
        var list = null, config = x.config != "" ? JSON.parse(x.config) : null
        if (config) {
           // if (x.fieldDataType == 9) {
           //     list = this.state.lookups[config.lookupTbl]
           // }
           // else if (x.fieldDataType == 10) {
                list = parseInt(config.dbLookupTbl)
           // }
        }

        return list
    }

    initiate() {
        ApiGet('FormSelection', 'GetFormDetails', { formId: this.state.mdKey })
        .then((d) => {
            var tFields = this.state.responseFields, tIndexed = d.isIndexedFilter
            if ((this.props.match && this.props.match.params.ent == 0) || this.props.formEntityId) {
                tFields.pop() 
            }
            if (d.isTaskList) {
                this.setState({ isChecklist: true })
            }
            if (d.isWizard) {
                this.setState({ isWizard: true })
            }
            if (d.titleField != 0) {
                this.setState({ titleField: 'f_'+d.titleField })
            }
            d.fields.map(x => {
                tFields.push({ label: x.name, fieldId: 'f_' + x.id, type: x.fType == 3 ? 'datetime' : x.fType == 8 ? 'checkbox' : null, width: x.gridWidth, display: x.gridWidth == 0 && 'none', canFilter: x.allowFilter, customFilter: tIndexed ? (e) => this.customFilter(e, x) : null, list: this.getList(x)})
            })
            tFields.push({ label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { btnLabel: 'Select' }, funcProps: (rec) => { return { location: this.getUrl(rec._original), state: { dbEntityKey: rec.id != undefined ? rec.id : null, formSelectionId: this.props.match.params.fId } } }, width: 70 })
            this.setState({ title: d.title, fields: tFields })
        });
    }

    shouldComponentUpdate(nP, nS) {
        if (nS.mdKey != this.state.mdKey || nS.fields != this.state.fields || nS.filterState != this.state.filterState || nS.pageCount != this.state.pageCount || nS.page != this.state.page || nS.rqId != this.state.rqId) {
            return true
        }
        else if ((nP.match && nP.match.params.fId != this.props.match.params.fId) || (nP.formEntityId != this.props.formEntityId)) {
            return true
        }
        else {
            return false
        }
    }

    navPage(index) {
        this.setState({ page: index })
        //this.setState({ mdKey: Math.random(), page: this.state.page +1 })
    }

    parseMDData(e) {
        if (e.data[0]) {
            var tC = e.data[0]._original.pageCount
            if (tC != this.state.pageCount) {
                this.setState({ pageCount: tC })
            }
        }
        if (e.listData) {
            this.setState({ listData: e.listData })
        }
    }

    lazyLoad() {
        return (<div className='-pagination'>
            <div className='-previous'>
                <button type="button" className="-btn">Previous</button>
            </div>
            <div className='-center'>
               
            </div>
            <div className='-next'>
                <button type="button" className="-btn" onClick={() => this.navPage()} >Next</button>
            </div>
        </div>)
    }

    getGridButton() {
        var gb = (this.props.match && this.props.match.params.ent == 0 || this.props.formEntityId) ? {
            pathname: '/FormCustom/' + this.state.mdKey + '/0' + (this.props.formEntityId ? '/1' : ''), state: { formSelectionId: this.props.match.params.fId } } : false
        return gb
    }


    render() {
        var SelectionConfig = {
            model: 'SelectionModel',
            path: '/FormSelection/Selection',
            initialBread: this.state.title,
            //fields: this.props.match.params.ent == 0 ? this.state.responseFields : this.state.responseFields,
            fields: this.state.responseFields,
            titleField: this.state.titleField ? this.state.titleField : 'name',
            master: !this.props.formEntityId,
            mdData: (e) => this.parseMDData(e),
            showFilterRow: true,
            gridProps: { defaultPageSize: 50, pages: this.state.pageCount, page: this.state.page, onPageChange: (pI) => this.navPage(pI), manual: true, showPageSizeOptions: false },
            //gridProps: { defaultPageSize: 50, PaginationComponent: () => this.lazyLoad() },
            crudParams: { resp: this.props.match ? this.props.match.params.ent : 0, formId: this.state.mdKey, page: this.state.page, filters: this.state.customFilter && JSON.stringify(this.state.customFilter), isTemplate: this.props.formEntityId != null, formSelectionId: this.state.formSelectionId },
            defaultSorted: [{ id: 'name', desc: false }],
            gridButton: this.getGridButton(),
            type: this.state.isChecklist ? 'checklist' : null,
            isWizard: this.state.isWizard,
            apiPath: API_PATH
        }

        //, getPaginationProps: (state, rowInfo, column, instance) => { return { onClick: (e) => console.log('page', { state, rowInfo, column, instance, e}) } } 
        if (this.state.title && this.state.rqId) {
            return (
                <div>
                    {!this.props.formEntityId && <div className='titleBlock'>{this.state.title}</div>}
                    <MasterDetail key={this.state.rqId} {...SelectionConfig} />
                </div >
            )
        }
        else {
            return null
        }
    }
}

export default (FormSelection)