import React from 'react';
import PropTypes from 'prop-types';
import { ApiPost } from '../../../api';


export default class ChangePassword extends React.Component {
    
    static propTypes = {
        forced: PropTypes.bool,
        onSuccess: PropTypes.func
    };

    constructor() {
      super();
      this.state = {
        oldpassword: "",
        password: "",
        password2: "",
        errorMsg: "",
        submitok: false
      };
    }
  
    onChange(key, value) {
      var submitok = true;
      var err = "";
      const { forced=false } = this.props
      var o = this.state.oldpassword;
      var p = this.state.password;
      var p2 = this.state.password2;
      var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})");
      if (key == 'oldpassword') { o = value; }
      if (key == 'password') { p = value; }
      if (key == 'password2') { p2 = value; }

      if (!forced && o.length == 0) submitok = false;
      if (p.length == 0) submitok = false;
      if (p2.length == 0) submitok = false;
      if (submitok && p != p2) {
        submitok = false;
        err = "Confirm Password does not match";
      }
      if (submitok && !strongRegex.test(p)) {
        submitok = false;
        err = "Password must contain at least one numeric, one Uppercase and one special char. Password must be at least 7 chars long.";
      }
      this.setState({ [key]: value, errorMsg:err, submitok: submitok });

    }
    
    onSubmit(ev) {
      ev.preventDefault();
      const oldpassword = this.state.oldpassword;
      const password = this.state.password;
      const p = {oldPassword:oldpassword, newPassword: password }
      ApiPost('UserProfile', 'ChangePassword', p)
      .then((data) => {
          console.log('Change Password', data);
          if (data) {
              if (this.props.onSuccess) {
                this.props.onSuccess()
              }
          } else {
            this.setState({ errorMsg: 'Unable to set password. Was your old password correct?' });
          }
      });
    }
  
    render() {
     const { errorMsg, submitok } = this.state
     const { forced=false } = this.props
      const onOldPasswordChange = ev => this.onChange("oldpassword", ev.target.value); 
      const onPasswordChange = ev => this.onChange("password", ev.target.value);
      const onPassword2Change = ev => this.onChange("password2", ev.target.value);

      console.log('oldPassword', this.state.oldPassword)

      return [
        <div className="form-group row" style={{textAlign:'left'}}>
          <label className="col-sm-6 col-form-label" style={{textAlign:'right'}}>Old Password:</label>
          <div className="col-sm-6">
            <input name="password" type="password" value={this.state.oldpassword} onChange={onOldPasswordChange} />
          </div>
          <label className="col-sm-6 col-form-label" style={{textAlign:'right'}}>New Password:</label>
          <div className="col-sm-6">
            <input name="password" type="password" value={this.state.password} onChange={onPasswordChange} />
          </div>
          <label className="col-sm-6 col-form-label" style={{textAlign:'right'}}>Confirm Password:</label>
          <div className="col-sm-6">
            <input name="password2" type="password" value={this.state.password2} onChange={onPassword2Change} />
          </div>
        </div>,
        <div className="row">
          {(errorMsg)?
          <div className="card bg-dark col-sm-12" style={{marginBottom:"10px"}}>
            <div className="card-body">{errorMsg}</div>
          </div>
          :null}
          <div  className="col-sm-12">
          {(submitok)?
            <button type="button" className="btn btn-primary" onClick={ev=> this.onSubmit(ev)}>Change Password</button>
          :
            <button type="button" className="btn btn-primary" disabled="disabled">Change Password</button>
          }
          
          </div>
        </div>
      ];
    }
}