import React, { Component } from 'react';
import { renderToString } from 'react-dom/server'
import { Responsive, WidthProvider } from 'react-grid-layout';
const ResponsiveReactGridLayout = WidthProvider(Responsive);
import { Button } from 'reactstrap';
import WidgetContainer from './WidgetContainer';
import AddWidgetModal from './AddWidgetModal';
import { ApiPost, ApiGet } from '../../../api';
import { connect } from 'react-redux';
import { widgetconfig, metricInfo } from '../../../configuration/widgets';
import { getWidgetData } from '../../../actions/dashboard';
import MetricWidget from './MetricWidget';
//import jsPDF from 'jspdf'
//import html2canvas from 'html2canvas'
import html2pdf from 'html2pdf.js'

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false, editMode: false, newCounter: 0, componentElement: <WidgetContainer />, typeValue: null, widgetWidth: 3, widgetHeight: 1, maxWidgetW: 3, maxWidgetH: 1,
            newLayout: null, lastFilterToken: null, widgetPeriod: null, widgetMetric: null, widgetParams: null, editModal: null, editWidgetInfo: null, widgeLay: null, maxRows: null, deviceConfig: null
        };

        this.toggleWidgetModal = this.toggleWidgetModal.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.onEditItem = this.onEditItem.bind(this);
        this.createElement = this.createElement.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.getLayout = this.getLayout.bind(this);
        this.postLayout = this.postLayout.bind(this);
        this.getScreenSize = this.getScreenSize.bind(this);
        this.onResizeStop = this.onResizeStop.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.header.siteMetric != this.props.header.siteMetric && this.props.header.siteMetric != undefined) {
            this.getRefreshData();
        }
        if ((nextProps.header.editMapDash != this.state.editMode) && nextProps.header.fullScreen) {
            this.setState({ editMode: !this.state.editMode }, this.toggleEdit());
        }
    }

    componentDidMount()
    {
        this.getLayout()
        this.getClientDeviceConfig()
    }

    toggleWidgetModal() {

        if (this.state.modal)
        {
            this.setState({
                typeValue: null,
                widgetWidth: null,
                widgetHeight: null,
                widgetMetric: null,
                widgetParams: null,
                editWidgetInfo: null
            });
        }

        this.setState({
            modal: !this.state.modal,
            editModal: false,
            widgetParams: false,
            editWidgetInfo: null
        });
    }

    toggleEdit() {
        if (this.state.editMode)
        {
            var oldLayout = this.widgetItemList;
            var newLayout = this.state.newLayout;
            var updatedLayout = [];

            newLayout.map(nL => {
                if (oldLayout.find(x => x.i == nL.i)) {
                    var oL = oldLayout.find(x => x.i == nL.i)
                    updatedLayout.push({ i: oL.i, x: nL.x, y: nL.y, w: nL.w, h: nL.h, type: oL.type, period: oL.period, metric: oL.metric, params: oL.params })
                }
            })

            this.widgetItemList = updatedLayout;

            this.postLayout(updatedLayout);
        }

        this.setState({
            editMode: !this.state.editMode
        });

    }

    onResizeStop(i) {
        this.getRefreshData();
    }

    onEditItem()
    {
        var cW = this.state.editWidgetInfo;

        this.widgetItemList = this.widgetItemList.filter(x => x.i != this.state.editWidgetInfo.i)

        if (this.state.typeValue != null) {
            cW.type = this.state.typeValue;
        }

        if (this.state.widgetPeriod != null)
        {
            cW.period = this.state.widgetPeriod
        }

        if (this.state.widgetMetric != null)
        {
            cW.metric = this.state.widgetMetric
        }

        if (this.state.widgetParams != null) {
            cW.params = this.state.widgetParams
        }

        this.widgetItemList = this.widgetItemList.concat(cW);

        this.setState({
            editWidgetInfo: null,
            typeValue: null,
            editModal: false,
            modal: !this.state.modal
        });

        this.getRefreshData();

        this.toggleEdit();
    }

    onAddItem()
    {
        if (this.widgetItemList == null)
        {
            this.widgetItemList = [];
        }
        this.widgetItemList = this.widgetItemList.concat({
            i: "" + Math.floor(Math.random() * 1000000),
            x: (this.props.dashInfo.cols / 2),
            y: 2,
            w: this.state.widgetWidth,
            h: this.state.widgetHeight,
            type: this.state.typeValue,
            period: this.state.widgetPeriod,
            metric: this.state.widgetMetric,
            params: this.state.widgetParams
        })

        this.setState({
            newCounter: this.state.newCounter + 1,
            modal: !this.state.modal,
            typeValue: null,
            metric: null,
            period: 1,
            widgetParams: null
        });

        this.getRefreshData();
    }

    onRemoveItem(i) {
        this.widgetItemList = this.widgetItemList.filter(x => x.i != i)
        this.forceUpdate();
    }

    onLayoutChange(layout, layouts) {
        if (this.props.header.fullScreen) {
            var rowCount = (Math.floor((window.screen.availHeight - 70) / (this.props.dashInfo.rowHeight + 12)));
            this.setState({ maxRows: rowCount });
        }
        
        this.setState({
            newLayout: layout
        });
    }

    onEditWidget(e, el)
    {
        this.setState({
            editWidgetInfo: el,
            editModal: true,
            modal: !this.state.modal,
            widgetParams: null
        });
    }

    onTogglePeriod(el)
    {
        var periodList = [];

        if (widgetconfig[el.type] == undefined || widgetconfig[el.type].periods == null)
        {
            return
        }

        widgetconfig[el.type].periods.map(period =>
        {
            periodList.push(period.id);
        });
        var periodIndex = periodList.indexOf(parseInt(el.period));

        if (periodIndex + 1 >= periodList.length)
        {
            periodIndex = -1;
        }

        var newPeriod = periodList[periodIndex + 1];
        var cW = el;

        this.widgetItemList = this.widgetItemList.filter(x => x.i != cW.i)

        if (this.state.typeValue != null) {
            cW.type = this.state.typeValue;
        }

        cW.period = newPeriod;
        cW.metric = widgetconfig[el.type].metric
        this.widgetItemList = this.widgetItemList.concat(cW);

        this.getRefreshData();
    }

    createElement(el)
    {
        if (this.getScreenSize() == 2) {
            el.maxW = 6;
            el.minW = 6;
            el.w = 6;
        }
        else {
            el = this.getDimensions(el);
        }
        var noShadow = ["21", "34", "57"].includes(el.type) || el.noShadow == true

        el.noShadow = noShadow

        var ind = this.widgetItemList.indexOf(el) + 1

        var tClass = this.state.editMode ? 'edit' : ''

        el.deviceConfig = this.state.deviceConfig

        return (
            <div key={el.i} id={ind} data-grid={el} className={tClass} style={{ boxShadow: noShadow && 'none'}}>
                <div className={this.state.editMode ? "widget-edit-tl-corner" : ""}></div>
                <div className={this.state.editMode ? "widget-edit-tr-corner" : ""}></div>
                <div className={this.state.editMode ? "widget-edit-bl-corner" : ""}></div>
                <div onClick={() => this.onTogglePeriod(el)} style={{height: '100%'}}>
                    <WidgetContainer widgetInfo={el} />
                </div>
                <span className={this.state.editMode ? "edit" : "hide"} onClick={() => this.onEditWidget(this, el)}><i className="fal fa-pencil"></i></span>
                <span className={this.state.editMode ? "move" : "hide"}><i className="fal fa-arrows"></i></span>
                <span className={this.state.editMode ? "remove" : "hide"} onClick={this.onRemoveItem.bind(this, el.i)}><i className="fal fa-trash-alt"></i></span>  
            </div>
        )
    }

    getDimensions(el) {
        if (widgetconfig[el.type]) {
            el.maxH = widgetconfig[el.type]?widgetconfig[el.type].maxH:0;
            el.maxW = widgetconfig[el.type]?widgetconfig[el.type].maxW:0;
            el.minH = widgetconfig[el.type]?widgetconfig[el.type].minH:0;
            el.minW = widgetconfig[el.type]?widgetconfig[el.type].minW:0;
        }
        return el;
    }

    selectWidgetType(e)
    {
        var widgetID = e.target.value;
        this.setState({
            typeValue: widgetID,
            widgetWidth: widgetconfig[widgetID].minW,
            widgetHeight: widgetconfig[widgetID].minH,
        });
    }

    selectPeriod(e) {
        this.setState({
            widgetPeriod: e.target.value
        });
    }

    selectParam(e, p, type) {

        var widgetID = this.state.typeValue;

        if (!widgetID) {
            widgetID = 1;
        }

        var tParams = this.state.widgetParams || (this.state.editWidgetInfo && this.state.editWidgetInfo.params) || {};
        if (type == 'checkbox') {
            tParams[p] = e.target.checked;
        }
        else if (type == 'hierarchyTree' || type == 'tags' || type == 'metricPicker' || type == 'swatchPicker') {
            tParams[p] = e
        }
        else {
            tParams[p] = e.target.value;
        }
        this.setState({
            widgetParams: tParams
        });

        if (type == 'dropdown' && p == 'metric') {
            var metricDeets = metricInfo[widgetID][e.target.value];
            this.setState({
                widgetWidth: metricDeets.minW,
                widgetHeight: metricDeets.minH,
            });
        }
    }

    getScreenSize()
    {
        var screenSize = 2;
        if (window.screen.availWidth > 576) {
            screenSize = 1
        }
        return screenSize;
    }

    postLayout(updatedLayout) {
        var layoutSpec = {
            dashboardId: this.props.dashInfo.dashId ? this.props.dashInfo.dashId : 1,
            tabId: this.props.dashInfo.tabId ? this.props.dashInfo.tabId : 0,
            boundryId: this.getScreenSize(),
            layoutCache: JSON.stringify(updatedLayout)
        }
        ApiPost('Dashboard', 'LayoutSave', layoutSpec)
        .then((data) => {
            if (data)
                console.log("Layout saved");
        });

        var widgetList = [];
        this.widgetItemList.map(function (w) {
            widgetList = widgetList.concat({ widgetId: w.i });
        })

    }

    getClientDeviceConfig() {
        ApiGet('Monitoring', 'GetMonitoringConfigs')
        .then((data) => {
            this.setState({ deviceConfig: data.configs })
        })
    }

    getLayout()
    {
        var dashSpec = {
            dashboardId: this.props.dashInfo.dashId ? this.props.dashInfo.dashId : 1,
            tabId: this.props.dashInfo.tabId ? this.props.dashInfo.tabId : 0,
            boundryId: this.getScreenSize()
        }
        ApiGet('Dashboard', 'LayoutLoad', dashSpec)
        .then((data) => {
            if (this.props.preloadedWidgets) {
                this.widgetItemList = this.props.preloadedWidgets
            }
            else if (data.layoutCache !== null) {
                this.widgetItemList = JSON.parse(data.layoutCache)
            } 
        })
        .then(() => {

            var widgetList = { widgets: [] };
            var layoutData = this.widgetItemList;

            if (layoutData) {
                Object.keys(layoutData).map(i => {
                    if (widgetconfig[layoutData[i].type]) {
                        if (widgetconfig[layoutData[i].type].type != MetricWidget) {
                            var singleWidget = [parseInt(layoutData[i].type), parseInt(layoutData[i].period), this.props.dashInfo.stationId, JSON.stringify(layoutData[i].params)]
                            widgetList["widgets"].push(singleWidget);
                        }
                    }
                })
                this.props.getWidgetData(widgetList);
            }
        })  
    }

    getRefreshData()
    {
        console.log('getRefreshData')
        if ((this.props.header.fullScreen && this.props.dashInfo.fullscreen) || (!this.props.header.fullScreen && !this.props.dashInfo.fullscreen)) {
            var widgetList = { widgets: [] };
            var layoutData = this.widgetItemList;
            if (layoutData) {
                Object.keys(layoutData).map(i => {
                    if (widgetconfig[layoutData[i].type]) {
                        if (widgetconfig[layoutData[i].type].type != MetricWidget) {
                            var singleWidget = [parseInt(layoutData[i].type), parseInt(layoutData[i].period), this.props.dashInfo.stationId, JSON.stringify(layoutData[i].params)];
                            widgetList["widgets"].push(singleWidget);
                        }
                    }
                })
                this.props.getWidgetData(widgetList);
            }
            window.dispatchEvent(new Event('resize'));
        }
    }

    printDash2() {

        let mywindow = window.open('', 'PRINT')
        let divId = 'dash-container'
        let title = 'izon Dashboard Export ' 

        mywindow.document.write(`<html><head><title>${title}</title>`);
        mywindow.document.write(`<link rel="stylesheet" type="text/css" href="/style/printStyles.css">`);
        mywindow.document.write(`<script type="text/javascript" charset="UTF-8" src="/script/autoPrint.js"></script>`);
        mywindow.document.write('</head><body><div>');
        mywindow.document.write(document.getElementById(divId).innerHTML);
        mywindow.document.write('</div></body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        //mywindow.print();
        //mywindow.close();

        return true
    }

    componentDidUpdate()
    {
        var filterHasChanged = false;
        if ((this.state.lastFilterToken != this.props.header.filterToken) && (this.props.header.filterToken != 'none')) {
            this.setState({ lastFilterToken: this.props.header.filterToken })
            filterHasChanged = true
        }
        if (filterHasChanged) {
            console.log("Filter Changed", this.props.header.filterToken)
            this.getRefreshData();
        }
    }

    render()
    {
        return (
            <div style={{ pointerEvents: 'none' }}>
                {(this.props.header.fullScreen && this.props.dashInfo.fullscreen && this.getScreenSize() == 1) || (!this.props.header.fullScreen && !this.props.dashInfo.fullscreen) ?
                    <div id="dash-container" style={{ maxWidth: this.props.header.fullScreen || this.props.dashInfo.fullDash ? '99vw' : '99vw', pointerEvents: 'none', width: this.props.dashInfo.fullDash ? '98vw' : ''}}>
                        <div className="d-flex justify-content-end">
                            {!this.props.hideTitle && <div className={this.state.editMode ? "mr-auto p-2 hide-mobile" : "mr-auto p-2"}>
                                <div className='titleBlock'>{this.props.dashInfo.dashTitle || 'Dashboard'}</div>
                            </div>}
                            {!this.props.hideEdit && <div style={{ pointerEvents: 'auto', position: this.props.hideTitle && 'absolute', zIndex: this.props.hideTitle && 9 }}  className={this.state.editMode && !this.props.header.fullScreen ? 'form-inline ml-auto p-2 editActive' : 'form-inline ml-auto p-2'}>
                                {
                                    this.props.header.fullScreen ?
                                        this.state.editMode ? <Button className="btn" onClick={this.toggleWidgetModal} style={{ marginRight: '5vw', marginTop: '13px' }}><i className="fa fa-plus"></i> Add</Button> : null
                                        :
                                        this.state.editMode ?
                                            <div>
                                                <span >EDIT MODE ACTIVE </span>
                                                <Button className="btn" onClick={this.toggleWidgetModal} style={{ marginRight: '1rem' }}><i className="fa fa-plus"></i> Add</Button>
                                                <Button className="btn" onClick={this.toggleEdit}><i className={this.state.editMode ? 'fa fa-save' : 'fa fa-pencil'}></i>{this.state.editMode ? ' Save' : ' Edit'}</Button>
                                            </div>
                                            : <div><Button className="btn" onClick={this.toggleEdit} style={{ border: 'none', backgroundColor: '#ffffff94' }}><i className={this.state.editMode ? 'fa fa-save' : 'fa fa-pencil'}></i>{this.state.editMode ? ' Save' : ' Edit'}</Button></div>
                                }
                                <Button className="btn" onClick={() => this.printDash2()} style={{ border: 'none', backgroundColor: '#ffffff94' }}><i className='fal fa-file-export'></i> Export</Button>
                            </div>}
                        </div>

                        {this.state.modal ? <AddWidgetModal isOpen={this.state.modal} selectWidgetType={e => this.selectWidgetType(e)} onChangePeriod={e => this.selectPeriod(e)} onSelectParam={(e, p, type) => this.selectParam(e, p, type)} onAddItem={this.onAddItem} onEditItem={this.onEditItem} toggleWidgetModal={this.toggleWidgetModal} editModal={this.state.editModal} editWidgetInfo={this.state.editWidgetInfo} auth={this.props.users.authRole} widgetParams={this.state.widgetParams} /> : null}

                        <ResponsiveReactGridLayout ref="rrgl" {...this.props} className="layout" draggableHandle=".move" maxRows={this.state.maxRows || 999} rowHeight={this.props.dashInfo.rowHeight} onResizeStop={this.onResizeStop} isDraggable={true} isResizable={true} breakpoints={{ lg: 539, sm: 0 }} cols={{ lg: this.props.dashInfo.cols, sm: 6 }} layouts={this.state.layouts} onLayoutChange={this.onLayoutChange} compactType={(this.props.header.fullScreen || (this.props.dashInfo.disableCompact && this.props.dashInfo.disableCompact == "true")) ? null : 'vertical'}>
                            {this.widgetItemList ? this.widgetItemList.map(x => { return this.createElement(x) }) : null}
                        </ResponsiveReactGridLayout>
                </div> : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state.common
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getWidgetData: (widgetList, getType) => {
            dispatch(getWidgetData(widgetList, getType));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)