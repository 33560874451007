import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink, Col, Navbar, NavbarToggler, Badge } from 'reactstrap';
import Collapsible from 'react-collapsible';
import { ApiGet } from '../api';

export default class nav extends Component {

    constructor(props) {
        super(props);

        this.state = { isOpen: false, navOptions: [] };
    }

    componentWillMount()
    {
        this.getNavOptions();
    }

    toggle() {
        if (window.screen.availWidth <= 576) {
            console.log('togglingNav')
            this.setState({
                isOpen: !this.state.isOpen
            });
        } 
    }

    getNavOptions()
    {
        ApiGet('UserSession', 'GetNavOptions')
        .then((data) => {
            this.setState({ navOptions: JSON.parse(data.navLayoutResponse) })
        })
    }

    getAllNav()
    {
        if (!this.state.navOptions)
        {
            return null;
        }
        else
        {
            return(
            <Navbar light className="mainMenu navbar navbar-toggleable navbar-light">
            <NavbarToggler className="navbar-toggler navbar-toggler-right" onClick={() => this.toggle()} />
            <Collapse isOpen={this.state.isOpen} navbar>
                <Nav navbar className="mainMenuNav">
                {this.state.navOptions.map((i, index) => 
                {
                        if (i.submenu == undefined)
                        {
                            return <NavItem className={i.responOnly ? 'responNav' : ''} key={index}><NavLink key={i.title} to={i.link} className="Collapsible__trigger is-disabled" tag={Link}><i className={i.icon + ' nav-icon'} />{i.title}</NavLink></NavItem>
                        }
                        else
                        {
                            return(
                            <NavItem className={i.responOnly ? 'responNav' : ''} key={index}>
                                <Collapsible trigger={<div><i className={i.icon + ' nav-icon'} />{i.title}</div>} transitionTime={200}>
                                {i.submenu.map(x => {
                                    return <NavLink key={x.title} to={x.link} className="Collapsible__trigger is-disabled" tag={Link} onClick={() => this.toggle()}>{x.title}</NavLink>
                                })}
                            </Collapsible>
                            </NavItem>)
                        }
                    }
                )}
                </Nav>
            </Collapse> 
            </Navbar>)
        }
    }

    render() {
        return (
            <Col sm="1" className="navColumn"> 
                {this.getAllNav()}  
            </Col>
        );
    }
}