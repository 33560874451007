import React, { Component } from 'react';
import { Input } from 'reactstrap';

export default class CheckBox extends Component {
    constructor(props) {
        super(props);
    } 

    trimLabel(text) {
        const { labelMaxChars=0 } = this.props
        var ret = text;
        if (text && text.length > labelMaxChars && labelMaxChars) {
            ret = text.substring(0, labelMaxChars) + "...";
        }
        return ret
    }

    render() {
        const { checked, label, onChange, value, name, renderer, style } = this.props
        //console.log('checkbox props',this.props)
        const checkClass = "far fa-" + (checked ? "check-square" : "square") + " fa-w-14";
        return (
            <div className='cbxContain' style={style}>
                <Input type="checkbox" style={{ display: 'none' }} name={name} value={value} checked={checked} onChange={(e) => true} />
                <i onClick={() => onChange({ checked: !checked, value: value, labelClick: false })} className={checkClass + ' cbxI'}></i>
                {renderer ? <div onClick={() => onChange({ checked: !checked, value: value, labelClick: true })} style={{ display: 'inline-block', padding:'2px' }}>{renderer}</div> : <label className="pointer" style={{ marginLeft: '8px' }} onClick={() => onChange({ checked: !checked, value: value, labelClick: true })}>{this.trimLabel(label)}</label>}
            </div>
            )
    }
}