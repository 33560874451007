import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
export const API_PATH = process.env.SERVER_PATH + '/api'

class IncidentSettings extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        var incidentFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'AlertName', fieldId: 'alertName' },
            { label: 'Record Downtime', fieldId: 'recordDownTime', type: 'checkbox' },
            { label: 'Prearranged', fieldId: 'preArranged', type: 'checkbox' },
            { label: "", fieldId: "navButton", width: 70, display: 'grid', type: 'navButton' }
        ];

        var incidentsConfig = {
            model: 'IncidentSettings',
            fields: incidentFields,
            master: true,
            showFilterRow: false,
            defaultSorted: [{ id: 'alertName', desc: false }],
            apiPath: API_PATH
        }

        return ([<h1 key='h1Task'>Scheduled Incidents</h1>, <MasterDetail key='mdIncidents' {...incidentsConfig}  />])
    }
}

export default (IncidentSettings)
