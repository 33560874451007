import React, { Component } from 'react';
import supercluster from 'points-cluster';
import Station from './MapStation.js';
import VanPin from './VanPin.js';
import MapVans from './MapVans.js';
import MapPath from './MapPath.js';
import MapStationSquare from './MapStationSquare'
import ClusterStationSq from './ClusterStationSq.js';
import ClusterStation from './ClusterStation.js';
import GoogleMap from 'google-map-react';
import { connect } from 'react-redux';
import { findZoomAndCenter } from '../../utils/findZoomAndCenter'
import { universalMetrics } from '../../configuration/enums'
import { toggleFullScreen } from '../../actions/header';
import { GetStationsStateC } from '../../api';
import moment from 'moment';
import { ApiPost } from '../../api'
const DEBUG_MODE = process.env.DEBUG_MODE;


import MapDash from '../../scenes/Dashboard/components/Dashboard';
//import { isNullOrUndefined } from 'util';

const stationMap = {
    defaultZoom: 11,
    defaultCenter: { lat: 55.0655387, lng: -6.8154664 },
    options: {
        fullscreenControl: false,
        maxZoom: 16,
        //mapTypeId: 'hybrid',
        styles: [{ "featureType": "administrative", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative.country", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative.province", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "color": "#f2f2f2" }] }, { "featureType": "landscape.natural", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "color": "#e2e4e4" }] }, { "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "labels.text", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.station.airport", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.station.airport", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e2e4e4" }] }, { "featureType": "water", "elementType": "labels", "stylers": [{ "visibility": "off" }] }],
        gestureHandling: 'greedy'
    },
};

class Map extends Component {

    constructor(props) {
        super(props);
        this.mapRef = null;

        this.state = {
            windSpeed: Math.round((Math.random() * (20)) * 10) / 10,
            totalExport: Math.floor(Math.random() * 251),
            capacityFactor: Math.floor(Math.random() * 45),
            height: 0,
            width: 0,
            mapOptions: {},
            clusters: [],
            cookieMessage: false,
            mapHeight: 0,
            mapWidth: 0,
            VanLocations: null,
            gpsUserSessionId: "",
            VanData: [],
            resizing: false
        };

        //this.mv = new MapVans()
    }

    componentDidMount() {

        const height = this.mapCanvas.clientHeight;
        const width = this.mapCanvas.clientWidth;
        const offsetLong = { 0: [-8.85, - 3.63], 1: [-8.456, -4.396] };
        const offsetLat = { 0: [55.48, 53.705], 1: [55.317, 53.98] };
        this.setState({ height, width });

        document.getElementById("btn-expand-map").addEventListener("click", function () {
            document.getElementById("top-container").classList.toggle("is-full");
            document.getElementById("full-container").classList.toggle("is-full");
            document.getElementById("main-container").classList.toggle("is-full");
            document.getElementById("mainFilter").classList.toggle("is-full");
            document.getElementById("stationModel").classList.toggle("is-full");
            document.getElementById("headerNavbar").classList.toggle("is-full");
            document.getElementById("btn-expand-map").classList.toggle("is-full");
            document.getElementById("map-canvas").classList.toggle("is-full");
            document.querySelector(".main-filter-container").classList.toggle("is-full");
            document.querySelector(".station-info-bar").classList.toggle("is-full");
            document.getElementById("root").classList.toggle("is-fullscreen");
            if (document.querySelector(".mapZoomMsg")) {
                document.querySelector(".mapZoomMsg").classList.add("remove");
            }
        });

        document.getElementById("btn-expand-map").addEventListener("click", function () {
            document.getElementById("root").classList.toggle("is-reduced");
        });

        var centerLeft = ((window.innerWidth - (document.getElementById("full-container").offsetWidth)) / 2);
        document.getElementById("map-canvas").style.marginLeft = "-" + centerLeft + "px";
        document.getElementById("map-canvas").style.marginTop = "-300px";

        this.setState({
            mapHeight: document.getElementById("top-container").offsetHeight,
            mapWidth: document.getElementById("top-container").offsetWidth
        })

        this.timerId = setInterval(this.timer.bind(this), 20000);

        this.getZoomMsg();
    }

    readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    getZoomMsg() {
        if (this.readCookie("zoomMsg") == null) {
            this.setState({
                cookieMessage: true
            });
            document.cookie = "zoomMsg = 1; expires = Tue, 02 Oct 2028 00:00:00 GMT; path = /";
        }
    }

    getSitesArray() {
        let ss = GetStationsStateC(this.props.commonstate);
        return (!ss) ? [] : ss;
    }

    getClusters = () => {

        var newMarkersData = this.getSitesArray();

        const clusters = supercluster(newMarkersData, {
            minZoom: 3,
            maxZoom: 15,
            radius: 55,
        });

        return clusters(this.state.mapOptions);
    };

    createClusters = props => {
        this.setState({
            clusters: this.state.mapOptions.bounds
                ? this.getClusters(props).map(({ wx, wy, numPoints, points }) => ({
                    lat: wy,
                    lng: wx,
                    numPoints,
                    id: `${numPoints}_${points[0].id}`,
                    points
                }))
                : [],
        });
    };

    handleMapChange = ({ center, zoom, bounds }) => {
        this.setState(
            {
                mapOptions: {
                    center,
                    zoom,
                    bounds,
                },
            },
            () => {
                this.createClusters(this.props);
            }
        );
    };

    timer() {
        this.createClusters(this.props);
    };


    fromDate() {
        var dt = moment().subtract(12, 'hour');
        return dt.format('YYYY-MM-DDTHH:mm:00');
    }

    toDate() {
        var dt = moment().add(1, 'hour');
        return dt.format('YYYY-MM-DDTHH:mm:00');
    }

    expandClick() {
        this.props.toggleFullScreen(this.props.header.fullScreen)
        this.setState({ resizing: true }, () => setTimeout(() => this.setState({ resizing: !this.state.resizing }), 1000))
    }

    assets2Paths(assets) {
        return assets.map((o, i) => {
            if (o.assetPath) {
                return o.assetPath
            }
        });
    }

    render() {
        var assetList = this.getSitesArray()
        var assetPaths = this.assets2Paths(assetList)
        const { center, zoom } = findZoomAndCenter(assetList, this.props.header.fullScreen) // adjust zoom? probably be fine once more sites are added
        return (
            <div style={{ width: '98vw', pointerEvents: 'none' }}>
                <MapVans mapRef={this.mapRef}></MapVans>
                <MapPath mapRef={this.mapRef} paths={assetPaths} />
                <i id="btn-expand-map" className="fa fa-expand icon-button" onClick={() => this.expandClick()} ></i>

                {!this.state.resizing ? <MapDash dashInfo={{ dashId: 3, tabId: 0, tabName: '', dashTitle: ' ', dashType: 0, fullscreen: true, cols: 36, rowHeight: 120 }} style={{ marginTop: '20px' }} /> : null}

                <div id="map-canvas" ref={(mapCanvas) => this.mapCanvas = mapCanvas}>
                    {this.state.cookieMessage ? <div className="mapZoomMsg"><span>scroll to zoom <i className="fa fa-search-plus"></i></span></div> : null}
                    <GoogleMap onGoogleApiLoaded={({ map, maps }) => this.mapRef = map} loading="async" yesIWantToUseGoogleMapApiInternals style={{ pointerEvents: 'auto' }} center={center} zoom={zoom} options={stationMap.options} onChange={this.handleMapChange} bootstrapURLKeys={{ key: "AIzaSyCXiGpHgoE_CLLCLjc40v0tKqQtzmRlV60" }}>
                        {!this.state.resizing && this.state.clusters ?
                            this.state.clusters.map(item => {
                                if (item.numPoints === 1) {
                                    if (item.points[0].industryId != 1)
                                    {
                                        return (
                                            <MapStationSquare key={item.id} id={item.points[0].id} industryId={item.points[0].industryId} alertsx={item.points[0].alerts} lat={item.points[0].lat} lng={item.points[0].lng} asset={item.points[0]} siteName={item.points[0].name} siteOutput={item.points[0].pw} stalex={item.points[0].stale} />
                                        )
                                    } 
                                    else
                                    {
                                        return (
                                            <Station key={item.id} id={item.points[0].id} alertsx={item.points[0].alerts} lat={item.points[0].lat} lng={item.points[0].lng} windSpeed={item.points[0].ws} siteName={item.points[0].name} siteOutput={item.points[0].pw} stalex={item.points[0].stale} modelId={item.points[0].turbineModelId} />
                                        )
                                    }
                                }
                                if (item.points[0].industryId != 1) {
                                    return (<ClusterStationSq key={item.id} lat={item.lat} lng={item.lng} points={item.points} />)
                                } else {
                                    return (<ClusterStation key={item.id} lat={item.lat} lng={item.lng} points={item.points} />)
                                }
                            })
                            : null
                        }
                        {window.vanMap ? window.vanMap.markers() : null}
                    </GoogleMap>
                </div>

                <span style={{ display: 'none' }}><b>{this.state.height}px</b></span> <span style={{ display: 'none' }}><b>{this.state.width}px</b></span>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getStationMetrics: () => {
            dispatch(headerActions.getStationMetrics());
        },
        toggleFullScreen: (fullScreen) => {
            dispatch(toggleFullScreen(fullScreen));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Map)


