import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { resetFilter, changeValue } from '../../actions/header';

class FilterSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdown: false,
            value:'',
            index:-1,
            resultCount: 0,
            result: [],
            selectedItem: {}
        }
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    toggle(e) {
        this.setState({ 
            dropdown: !this.state.dropdown 
        })
    }

    toggleUp(e) {
        //e.stopPropagation()
        if (this.props.onSelect) {
            this.props.onSelect()
        }
        this.setState({ dropdown: false, value: '', index:-1 })
    }

    onKeyPress(e) {
        const {index,resultCount, result } = this.state;
        if (e.keyCode == 38 && this.state.index > 0) {
            this.setState( { index: index - 1 } )
            e.preventDefault();
        } 
        else if (e.keyCode == 40 && (index+1) < resultCount ) {
            this.setState( { index:index + 1 } )
        } else if (e.keyCode == 13) {
            e.preventDefault();
            var selectedItem = this.getSelectedItem();
            this.SelectItem(selectedItem.sec, selectedItem.id)
            this.setState({ selectedItem });
        } else {
            if (index == -1 && e.target.value.length > 0) {
                this.setState( { index: 0 } )
            } 
        }
    } 

    getSelectedItem() {
        const { result } = this.state;
        let selectedItem = null;
        if (result.length > 0) {
            result.map(
                (sec,index) => {
                    {sec.items.map(r => 
                        {
                            if (r.key == this.state.index) {
                                selectedItem = r
                            }
                        }
                    )}
                 }
            )
        }
        return selectedItem
    }

    SelectItem(name,id) {
        this.props.changeValue(name, [id], this.props.filters, true)
        this.toggleUp(); 
    }

    toggleDown(e) {
        //console.log("down",e.target.value.length > 0)
        var newState = { dropdown: e.target.value.length  > 0, value:e.target.value  }
        this.getResult(newState)
    }

    getResult({value,dropdown} = curState) {
        const { filters } = this.props;
        const { index } = this.state;
        
        let findex = index;
        let selectedItem = null;
        let result = [];
        var resultCount = 0;
        var seccnt = 0;
        var itemsAdded = {};
        if (value && dropdown) {
            const re = new RegExp("\\b" + value, 'i'); 
            for (var p in filters) {
                seccnt++;
                let h = { name: filters[p].display, items: [], key: seccnt *-1 }
                for (var item of filters[p].items) {
                    if (item.name.match(re)) { 
                        if (itemsAdded[item.id] == undefined) {
                            itemsAdded[item.id] = 1
                            resultCount++;
                            var mitem = {id:item.id, name:item.name, sec:p, key: resultCount-1 }
                            h.items.push(mitem)
                            if (index == (resultCount-1)) {
                                selectedItem = mitem
                            } 
                        }
                    }
                }
                if (h.items.length > 0) {
                    result.push(h)
                }
            }
        }
        if (index >= result.length && result.length > 0) {
            findex = 0;
        }
        this.setState({ resultCount, result, value, dropdown,index:findex, selectedItem })
    }

    getMenuItems() {
        const { result } = this.state

        if (result.length > 0) {
            return (result.map(
                (sec,index) => (
                    <div key={index}> 
                        <DropdownItem key={sec.key} header>{sec.name}</DropdownItem>
                        {sec.items.map(r => 
                            <DropdownItem tag="a" className={(this.state.index == r.key)?'active':''}  key={r.key} onMouseDown={event => { console.log("xxxxxxxxxxx", sec); event.preventDefault(); var selectedItem = r;  this.SelectItem(sec.name.toLowerCase(),r.id); this.setState({ selectedItem, index:r.key }); }}>
                                <div>
                                    {r.name}
                                </div>
                            </DropdownItem>
                        )}
                    </div>
                )
            ))
        } else {
            return (<DropdownItem  header>No results</DropdownItem>)
        }
    }

    render() {
        var r = this.getMenuItems()

        return (
            <div className="form-inline p-2" >
                <Dropdown isOpen={this.state.dropdown} toggle={event => this.toggleUp(event)} className="headerDD" style={{ width: '100%' }}>
                    <DropdownToggle/>
                    <input id='filterSearch' onKeyDown={(e) => this.onKeyPress(e)} className="form-control mr-sm-2" value={this.state.value} onBlur={e => this.toggleUp(e)} onChange={e => this.toggleDown(e)} onFocus={e => this.toggleDown(e)} type="text" placeholder={'\uf002'} style={{ fontFamily: 'FontAwesome, Arial' }} />
                    <DropdownMenu style={{width:'100%'}}>
                        {r}
                    </DropdownMenu>
                </Dropdown>
            </div>
        )    
    }
}

const mapStateToProps = (state) => state.common.header

const mapDispatchToProps = (dispatch) => {
    return {
        setAndClearFilter: (name, id) => {
            dispatch(resetFilter({ name, id }));
        },
        changeValue: (filterTarget, index, getFiltFunc, close) => {
            dispatch(changeValue(filterTarget, index, getFiltFunc, close));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterSearch)

