import React, { Component } from 'react'
import { GetStationsState } from '../../../api'
import { ToggleButton } from '../../../components/Controls/ToggleButton'
import LiveSpinner from '../../../components/MiniCharts/LiveSpinner'
import { universalMetrics, universalState, industryDefaultMetrics } from '../../../configuration/enums'
import { connect } from 'react-redux'
import { updateLiveConnection, disconnectLive  } from '../../../actions/scada'
import {withRouter} from "react-router-dom";

class MonitoringTileDynamic extends Component {
    
    constructor(props) {
        super(props)
        this.state = { initialMetricVals: [] }
    }

    titleClick(stationid) {
        this.props.history.push('/Scada/' + stationid)
    }

    renderPowerBar(value, valuepercent, key, overclass, overpower) {
        return (
            <div className="bar-row power" key={key}>
                <div className="bar-wrap">
                    <div className="border"></div>
                    <div className={"box bind-power-percent bind-over-kw " + overclass} style={{width: valuepercent + '%'}}>
                        {(overclass)?"+ " +overpower.toFixed(1) + " Kw":""}
                    </div>
                </div>
                <div className="wind-area unit-area">
                    <span className="value bind-power">
                        {value}
                    </span>
                    <span className="unit power">kw</span>
                </div>
            </div>
        )
    }

    renderBar(connected, noCommsMsg, value, valuepercent, units, key, barName) {
        return ((connected && value != undefined)?
            <div className="bar-row wind"  key={key}>
                <div className="bar-wrap if-comms" style={{ margin: '0px 5px', borderRadius: '6px' }}>
                    <div className="border"></div>
                    <div className="box wind bind-wind-speed-percent" style={{width: valuepercent + '%'}}>{barName}</div>
                </div>
                <div className="wind-area unit-area if-comms">
                    <span className="value bind-wind-speed">
                        {value}
                    </span>
                    <span className="wind unit" dangerouslySetInnerHTML={{__html: units}}></span>
                </div>
            </div>
            :
            <div className="bar-row wind" key={key}>
                <div className="unit-area if-no-comms" style={{marginLeft: 15}}>
                    <i className="fa fa-chain-broken"></i>
                    <span className="wind unit">{noCommsMsg}</span>
                </div>
            </div>
        )
    }

    getMetricDetail = (o, id) => {
        const info = industryDefaultMetrics.renderDetails[id]
        if (info) {
            var max = info.max
            var cVal = null, cMetId = null
            if (o.metrics) {
                cMetId = id
                cVal = o.metrics[id]
            }
            else if (this.props.metricMap) {
                cMetId = this.props.metricMap[id]
                cVal = parseFloat(this.props.getVal(cMetId, info.decimalPlaces, o.id))
            }
            if ((this.state.initialMetricVals[o.id] == null || this.state.initialMetricVals[o.id][cMetId] == null) && !isNaN(cVal)) {
                var temp = this.state.initialMetricVals
                if (temp[o.id] == null) {
                    temp[o.id] = []
                }
                temp[o.id][cMetId] = cVal
                this.setState({ initialMetricVals: temp })
            }
            if (this.state.initialMetricVals[o.id] && this.state.initialMetricVals[o.id][cMetId] && info.renderer == 'difference') {
                cVal = (cVal - this.state.initialMetricVals[o.id][cMetId])
            }

            if (cVal != null) {
                if (!Number.isInteger(info.max)) {
                    max = o[info.max]
                }
                var per = ((cVal / max) * 100)
                var overclass = ""
                if (per < 0) per = 0
                if (per > 100) {
                    if (per > 102) {
                        overclass = "over"
                    }
                    per = 100
                }
                
                var ret = {
                    v: cVal.toFixed(info.decimalPlaces),
                    max: max,
                    renderer: info.renderer,
                    units: info.unit,
                    title: info.title,
                    per,
                    overclass: overclass
                }
                return ret
            }
        } 
    }

    renderMetricCell(metricid, siteinfo) {
        const valueinfo = this.getMetricDetail(siteinfo, metricid)
        var metricCell = null
        if (valueinfo == undefined) {
            metricCell = <div key={metricid} className="metric"></div>
        }
        else if (valueinfo.renderer == 'bar')
        {
            var per =  ((valueinfo.v / valueinfo.max) * 100);
            if (per > 100)
            {
                per = 100
            }
            if (valueinfo.v < 0) {
                per = 0
            }
            metricCell = this.renderBar(true, "No Comms", valueinfo.v, per, valueinfo.units, metricid, valueinfo.title)
                //this.renderPowerBar(valueinfo.v,valueinfo.per,metricid,valueinfo.overclass, (valueinfo.v - valueinfo.max))
                //:this.renderBar(true,"No Comms",valueinfo.v,per,valueinfo.units, metricid, valueinfo.title)
        }
        else if (valueinfo.renderer == 'value')
        {
            metricCell = <div className="metric" key={metricid}>{valueinfo.v}</div>
        }
        else if (valueinfo.renderer == 'difference') {
            metricCell = (<div className="metric" style={{ marginLeft: '5px' }} key={metricid}>
                <div style={{width:'190px', display:'inline-block'}}><b>{valueinfo.title}</b></div>
                <span className='value bind-wind-speed'>{valueinfo.v}</span>
                <span className='wind unit'>{valueinfo.units}</span>
            </div>)
        }
        if (this.props.favourites) {
            var favourited = ''
            var tId = this.props.metricMap[metricid]
            if (this.props.favourites.includes(tId)) {
                favourited = 'fav'
            }
            return (
                <div style={{display:'flex'}}>
                    <div className="scada-star" style={{ width: '6%', textAlign: 'right' }}><i className={"fa fa-star " + favourited} onClick={() => this.props.toggleFavMetric(tId, siteinfo.id)} /></div>
                    {metricCell}
                </div>)
        }
        else {
            return metricCell
        }
        
    }

    renderMetrics(device) {
        var metrics = []
        if (this.props.config[device.deviceTypeId]) {
            metrics = this.props.config[device.deviceTypeId].metrics
        }
        else {
            metrics = industryDefaultMetrics.views[device.industryId].metrics
        }
        return (metrics.map((k, i) => {
            return this.renderMetricCell(k, device, industryDefaultMetrics.renderDetails[k])
        }))
    }

    renderDeviceState(device) {
        var status = "OK"
        if (device.deviceTypeId && this.props.config[device.deviceTypeId]) {
            var dC = this.props.config[device.deviceTypeId]
            if (dC.status) {
                var stateVal = device.metrics[dC.status.metric]
                if (dC.status.type == 'binary') {
                    var bin = ("0".repeat(16) + (stateVal >>> 0).toString(2))
                    bin = bin.substr(bin.length - 16, 16)
                    if (dC.status.targets) {
                        if (dC.status.default) {
                            status = dC.status.default
                        }
                        Object.keys(dC.status.targets).map(x => {
                            var tVal = bin.substr(15 - x, 1)
                            if (tVal == 1) {
                                status = dC.status.targets[x]
                            }
                        })
                    }
                    else if (dC.status.default) {
                        status = dC.status.default
                    }
                }
                else if (dC.status.default) {
                    status = dC.status.default
                }
            }
        }
        return status
    }

    render () {
        
        const o = this.props.station
        let alertDesc = ""
        let stateDesc = this.renderDeviceState(o)
        let childCount = this.props.childDevices && this.props.childDevices[o.id] ? this.props.childDevices[o.id].length : 0
        var isLive = false
        var isStale = this.props.stations.find(x => x.id == o.id).stale
        if (this.props.config[o.deviceTypeId].isActive && o.metrics) {
            var tt = this.props.config[o.deviceTypeId].isActive
            var mval = o.metrics[tt.metric]
            if (mval > tt.minVal && mval < tt.maxVal) {
                isLive = true
            }
        }
        return (
            <div key={o.id} className="controller-box dynamic-box error on" style={{ maxWidth: '300px', pointer: 'auto', marginLeft: '5px' }} onClick={() => this.titleClick(o.id)}>
                {isStale && <div className='dynamicStale'><span>Comms Issue</span><i className='fal fa-unlink' style={{marginLeft:'5px'}} /></div> }
                <div className="head" style={{ pointer: 'cursor' }}>
                    <span>{o.name}</span>
                    <span style={{ marginLeft: '5px', fontSize: '0.9rem' }}>{o.deviceTypeId && this.props.config[o.deviceTypeId] && this.props.config[o.deviceTypeId].title}</span>
                    {isLive && <LiveSpinner isLive={isLive} />}
                    {childCount > 0 && <div className='deviceCounter'>
                        <span style={{marginRight:'5px'}}>{childCount}</span>
                        <i className='fal fa-code-branch' />
                    </div>}
                </div>
                <div className="content">
                    <div style={{marginLeft:'6px'}}><span className="value bind-status-msg">{stateDesc}</span></div>
                    <div className='metricCells'>
                        {this.renderMetrics(o)}
                    </div>
                    <div className="overlay active">
                        <div className="bg"></div>
                        <div className="overlay-content">
                            <h4 className="error">
                                <span className="bind-error-msg">{alertDesc}</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { stations: GetStationsState(state), scada: state.common.scada }
}


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateLiveConnection: (siteList) => {
            dispatch(updateLiveConnection(siteList));
        },
        disconnectLive: () => {
            dispatch(disconnectLive());
        }
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MonitoringTileDynamic))