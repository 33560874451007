import React, { Component } from 'react';
import { Row, Col, Button, Label } from 'reactstrap';
import ReactTooltip from 'react-tooltip'

export default class EventRow extends Component {

    renderStatusIcons() {

        if (this.props.Status) {
            return (
                this.props.Status.map(i => {
                    if (i.type == 'scheduled') {
                        if (i.level == 'red') {
                            return (<i className="fa fa-clock-o" data-tip="Service Scheduled for 08/03/18" style={{ color: this.getColour(i.level), fontSize: '24px', paddingLeft: '5px' }} ><ReactTooltip/></i>)
                        }
                        else 
                        {
                            return (<i className="fa fa-clock-o" data-tip="Job Created. Yet to be scheduled" style={{ color: this.getColour(i.level), fontSize: '24px', paddingLeft: '5px' }} ><ReactTooltip/></i>)
                        }
                    }
                    else if (i.type == 'outstanding') {
                        return (<i className="fa fa-exclamation-circle" data-tip="Service Outstanding" style={{ color: this.getColour(i.level), fontSize: '24px', paddingLeft: '5px' }} ><ReactTooltip /></i>)
                    }
                })
            )
        }
        else {
            return null
        }
    }

    getColour(level) {
        if (level == 'red') {
            return '#d0021b';
        }
        else if (level == 'orange') {
            return '#ffa500';
        }
        else if (level == 'blue') {
            return '#5b8cd2';
        }
        else if (level == 'green') {
            return '#27c564';
        }
    }

    render() {

        return (
            <div>
                <Row style={{ display: 'flex', alignItems: 'center', paddingTop: '5px', paddingBottom: '5px', borderBottom: '1px solid rgba(0,0,0,.125)'}}>
                    <Col sm={2}>
                        <div className="eventType">
                            {this.props.Type}
                        </div>
                    </Col>
                    <Col sm={2}>
                        <Label>{this.props.Date}</Label>
                    </Col>
                    <Col sm={2}>
                        <Label>{this.props.Location}</Label>
                     </Col>
                    <Col sm={3}>
                        {this.renderStatusIcons()}
                    </Col>
                    <Col sm={3}>
                    {
                        Array.isArray(this.props.Action)?
                            <div>
                                {this.props.Action.map((v) => 
                                    <Button className="btn btn-primary pull-right">{v}</Button>
                                )}
                            </div>
                        :
                        <Button className="btn btn-primary pull-right">{this.props.Action}</Button>
                    }
                        
                    </Col>
                </Row>
            </div>
        );
    }
}