import React, { Component } from 'react';
import Dash from './DashContainer';

export default class DashFullscreen extends Component {

    constructor(props) {
        super(props);
          this.state = {
        };
    }

    componentDidMount() {
        document.getElementById("full-container").classList.toggle("is-full");
        document.getElementById("top-container").classList.toggle("is-hide");
        document.getElementById("dash-container").classList.toggle("is-full");
        document.querySelector(".navColumn").classList.toggle("is-full");
        document.getElementById("headerNavbar").classList.toggle("is-hide");
    }

    componentWillUnmount() {
        document.getElementById("full-container").classList.toggle("is-full");
        document.getElementById("top-container").classList.toggle("is-hide");
        document.getElementById("dash-container").classList.toggle("is-full");
        document.querySelector(".navColumn").classList.toggle("is-full");
        document.getElementById("headerNavbar").classList.toggle("is-hide");
    }

    render() {
        return (
            <Dash dashId={4} />
        );
    }
}