import React, { Component } from 'react'
import DonutChart from '../../../components/MiniCharts/DonutChart'

class LiveStatusWidget extends Component {

    getVal() {
        var deviceId = this.props.selectedParam.params.devices
        var uMId = this.props.selectedParam.params.valueToChart || this.props.selectedParam.params.deviceMetrics
        var op = this.props.selectedParam.params.okStatusOperator
        var okVal = this.props.selectedParam.params.okStatusValue
        var deviceInfo = this.props.widgetValues.items.find(x => x.id == deviceId)
        if (deviceInfo && deviceInfo.metrics) {
            var val = deviceInfo.metrics[uMId]
            var renderer = null
            var status = 'Error'
            if (deviceInfo) {
                if (op == '>') {
                    if (val > okVal) {
                        status = 'OK'
                    }
                }
                else if (op == '<') {
                    if (val < okVal) {
                        status = 'OK'
                    }
                }
                else {
                    if (val == okVal) {
                        status = 'OK'
                    }
                }
                renderer = this.getRenderInfo(val, deviceInfo.name, status)
            }
        }

        return renderer
    }

    getRenderInfo(val, deviceName, status) {
        var deets = { width: 50, textColour: '#FFF', colour: status == 'OK' ? '#8bc83b' : '#b1290a'}
        return (<div>
            <DonutChart textValue={status} valuePercent={100} size={100} units='Status' rendererDetails={deets} />
            <span style={{ fontSize: '14px' }}>{deviceName}</span>
        </div>)
    }

    render() {
        return (
            <div className={"summary-widget widget"}>
                <div className="widget-info" style={{ width: '100%' }}>
                    {this.props.widgetValues && this.props.widgetValues.items && this.getVal()}
                </div>
            </div>
        );
    }
}

export default LiveStatusWidget