import React from 'react'

const DonutChart = (props) => {

    const label = props.label ? props.label : 'Loading...'
    const iconColour = props.iconColour ? props.iconColour : '#a7a7a7'
    const icon = props.icon ? props.icon : 'fad fa-spinner-third'
    const rotate = props.rotate == false ? '' : 'fa-spin'
    const iconSize = props.iconSize ? props.iconSize : 'fa-3x'
    const fullIcon = icon + ' ' + rotate + ' ' + iconSize
    const percent = props.valuePercent > 0 ? props.valuePercent : 0

    const trackWidth = props.rendererDetails && props.rendererDetails.width ? props.rendererDetails.width : '7.5'
    const halfsize = (props.size * 0.5);
    const radius = halfsize - (trackWidth * 0.5);
    const circumference = 2 * Math.PI * radius;
    const strokeval = ((props.valuePercent * circumference) / 100);
    const dashval = (strokeval + ' ' + circumference);
    const trackColor = props.rendererDetails && props.rendererDetails.colour ? props.rendererDetails.colour : '#0a92b1'
    const textColor = props.rendererDetails && props.rendererDetails.textColour ? props.rendererDetails.textColour : '#607580'
    const trackstyle = { strokeWidth: trackWidth }
    const indicatorstyle = { strokeWidth: trackWidth, strokeDasharray: dashval, stroke: trackColor}
    const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')'
    return (
        <div className='dCard'>
            <svg width={props.size} height={props.size} className="donutchart">
                <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track" />
                <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className="donutchart-indicator"/>
                <text dy="0" className="donutchart-text" x={halfsize} y={halfsize} style={{ textAnchor: 'middle' }} >
                    <tspan x={halfsize} dy="0em" className="donutchart-text-val" style={{ fill: textColor }}>{!isNaN(props.value) ? props.value : props.textValue ? props.textValue : 'x'}</tspan>
                    <tspan x={halfsize} dy="1.5em" className="donutchart-text-percent" style={{ fill: textColor }}>{props.units}</tspan>
                </text>
            </svg>
        </div>
     )
}

export default DonutChart