import React, { Component } from 'react';
import { listRequest } from '../../../configuration/enums'
import ReactBlockly from 'react-blockly'
import Blockly from 'blockly'
import BlocklyPython from 'blockly/python'
import { defaultConfig, defaultXML, INITIAL_TOOLBOX_XML, defaultConfig2 } from '../../../configuration/BlocklyConfig'
import { ApiGet } from '../../../api'
//import parseWorkspaceXml from 'react-blockly/src/BlocklyHelper'

class FormulaBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //toolboxCategories: parseWorkspaceXml(INITIAL_TOOLBOX_XML), valid: null, code: null
            toolboxCategories: null, valid: null, code: null, blocks: null, blockXML: null, pythonCode: null
        };
    }

    componentDidUpdate(pp) {
        if ((this.props.rec.data && this.props.rec.data.id) || this.props.id == 'New') 
        {
            if (this.state.blockXML == null) //INITAL PROPS LOADED
            {
                if (this.props.rec.data.formula) {
                    this.setState({ blockXML: this.props.rec.data.formula })
                }
                else {
                    this.setState({ blockXML: defaultXML })
                }
            }
        }
    }

    componentDidMount() {
        this.getListData()
    }

    getListData() {
        var listsToGet = [listRequest.UniversalMetric];
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
        .then((data) => {
            var tempList = listsToGet;
            Object.keys(data).map(x => {
                tempList[x] = data[x]
            })
            this.setState({ listData: tempList, complete: true }, () => this.generateToolbox());
        })
    }

    generateToolbox() {

        var config = defaultConfig
        var tArray = []

        tArray.push(
            {
                "fields": {
                    "VAR": 'result',
                },
                "type": "variables_set"
            })

        this.state.listData[listRequest.UniversalMetric].map(x => {
            if(x.id < 100)
            tArray.push(
                {
                    "fields": {
                        "VAR": '__' + x.name + '__',
                    },
                    "type": "variables_get"
                })
        })
        if (config.find(x => x.name == 'Tags')) {
            config.find(x => x.name == 'Tags').blocks.push(...tArray)
        }
        else {
            config.push({ blocks: tArray, name: 'Tags' })
        }
        this.setState({ toolboxCategories: config })

    }

    workspaceDidChange(e) {
        var blocks = Blockly.Xml.workspaceToDom(e)
        var code = Blockly.JavaScript.workspaceToCode(e)
        var pythonCode = this.cleanCode(BlocklyPython.workspaceToCode(e))
        if (code != this.state.code || blocks != this.state.blocks) {
            if (this.state.blocks) {
                var xmlString = new XMLSerializer().serializeToString(blocks)
                var value = JSON.stringify({ code: pythonCode, blocks: xmlString })
                this.props.rec.onChange(value)
            }
            this.setState({ code, pythonCode, valid: null, blocks })
        }
    }

    cleanCode(code, doEval) {
        var tCode = " " + code
        this.state.listData[listRequest.UniversalMetric].map(x => {
            var tVar = '__' + x.name.replace(/ /g, '_') + '__'
            var regex = new RegExp(tVar,'g')
            if (tCode.includes(tVar)) {
                if (doEval) {
                    tCode = tCode.replace(tVar, tVar + ' = 1')
                }
                else {
                    tCode = tCode.replace(regex, '#'+x.id+'#')
                }
            }
        })
        return tCode
    }

    evalCode() {
        try {
            var code = this.cleanCode(this.state.code, true)
            //var code = this.state.code
            console.log('evalCode', code)
            if (eval(code)) {
                this.setState({ valid: true })
            }
            else {
                this.setState({ valid: false })
            }
        }
        catch (e) {
            console.log('evalIssue', e)
            this.setState({ valid: false })
        }
    }

    render() {
        var { valid, toolboxCategories, blockXML } = this.state
        console.log('stit', { valid, toolboxCategories, blockXML })
        return (
            <div style={{padding:0}}>
                {toolboxCategories && blockXML &&
                <div>
                    <button type="button" className={'btn btn-' + (valid == null ? 'default' : valid ? 'success' : 'danger') + ' formulaTest'} onClick={() => this.evalCode()} >
                        <i className={valid == null ? 'fal fa-question-square' : valid ? 'fal fa-check-square' : 'fal fa-times-square'} />
                        <span style={{ marginLeft: '5px' }}>Test</span>
                    </button>
                    <ReactBlockly
                        workspaceConfiguration={{ scrollbars: false }}
                        toolboxCategories={toolboxCategories}
                        initialXml={blockXML}
                        wrapperDivClassName="fill-blockly"
                        workspaceDidChange={(e) => this.workspaceDidChange(e)}
                    />
                </div>}
            </div >
            )
    }
}

export default (FormulaBox)