import redux from 'redux'
export const GET_WIDGET_DATA = 'GET_WIDGET_DATA'
export const GOT_WIDGET_DATA = 'GOT_WIDGET_DATA'


import { ApiPost, ApiJsonPost, ApiGet } from '../api'

import { widgetconfig } from '../configuration/widgets';

export function getWidgetData(widgetList, getType) {

    return (dispatch) => {
        dispatch({
            type: GET_WIDGET_DATA
        })
        if (getType == 1)
        {
            getType = 'WidgetsRefresh'
        }
        else
        {
            getType = 'WidgetsLoad'
        }

        if (widgetList.widgets.length > 0)
        {
            ApiGet('Dashboard', getType, { widgetList: JSON.stringify(widgetList) })
            .then((data) =>
                dispatch({
                    type: GOT_WIDGET_DATA,
                    payload: data
                })
            )
            //.then((data) => console.log('returningData', data))
        }
   
    }
}


