import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { ApiGet } from '../api'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap'
import { checklistPositions } from '../configuration/enums';
export const API_PATH = process.env.SERVER_PATH + "/api";

export default class MDChecklist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clCount: 0, modalOpen: false, error: null
        }
    }

    componentDidMount() {
        this.getCount(false, true)
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props) != JSON.stringify(nextProps) || JSON.stringify(this.state) != JSON.stringify(nextState)) {
            return true
        }
        else {
            return false
        }
    }

    acknowledge(save) {
        if (this.state.clCount == 0) {
            if (this.props.onComplete) {
                this.props.onComplete()
            }
            this.setState({ modalOpen: !this.state.modalOpen });
        }
    }

    getModalChecklists() {
        if (!this.props.showPreCheck) {
            return null
        }

        var checklistConfig = {
            model: 'wcChecklistModal',
            path: '/Checklist',
            fields: [{ label: 'Id', fieldId: 'id', display: 'none' }, { label: 'Checklist', fieldId: 'name' }],
            gridProps: { minRows: 0, showPaginationBottom: false},
			crudParams: { checklistPosition: checklistPositions.PreForm, wcId: this.props.detailId},
            cascade: true,
            cascadeComponents: this.cascadeLists(),
            defaultSorted: [{ id: 'id', desc: false }],
            gridButton: false,
            preExpand: { 0: true },
            apiPath: API_PATH
        }

        return (
            <Modal isOpen={this.state.modalOpen} autoFocus={false} >
                <ModalBody style={{minHeight:'350px'}}>
                    <MasterDetail {...checklistConfig} />
                </ModalBody>
                <ModalFooter>
                    {this.state.error && <h4 style={{ position: 'absolute', left: '15px' }}>Please complete all checklists</h4>}
                    <Button color="primary" onClick={() => this.getCount(true) }>OK</Button>
                </ModalFooter>
            </Modal>
        )
     }

    getCount(close, firstLoad) {
        const response = { checklistTypeId: this.props.checklistType, checklistPosition: checklistPositions.PreForm, id: this.props.detailId, firstLoad }
        ApiGet('Checklist', 'GetCount', response)
        .then((data) => {
            console.log('getcount', data)
            if (data > 0) {
                this.setState({ clCount: data, modalOpen: true })
                if (!firstLoad) {
                    this.setState({ error: true })
                }
            }
            else {
                this.setState({ clCount: 0, error: false }, () => close ? this.acknowledge() : null)
            }
        });
     }

     cascadeLists() {
        var iChecklistFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Question', fieldId: 'question' },
            { label: '', fieldId: 'answer', type: 'dynChecklist', width: 100, display: 'grid' },
            { label: 'DetailId', fieldId: 'detailId', display: 'none', getId: -1 }
        ];

        var iChecklistConfig = {
            model: 'iChecklists',
            path: '/Checklist/GetQuestions',
            fields: iChecklistFields,
            crudParams: { checklistId: undefined, detailId: this.props.detailId },
            gridButton: false,
            gridProps: { defaultPageSize: 50, showPaginationBottom: false, minRows: 0},
            styleClass: 'checklistMaster',
            defaultSorted: [{ id: 'id', desc: false }],
            type: 'checklist',
            apiPath: API_PATH
        }

        return <MasterDetail {...iChecklistConfig} />
    }

    //parseMDData(e) {
    //    console.log('parseMDData', e)
    //}

    render() {
        var checklistConfig = {
            model: 'wcChecklist',
            path: '/Checklist',
            fields: [{ label: 'Id', fieldId: 'id', display: 'none' }, { label: 'Name', fieldId: 'name' }],
            gridProps: { minRows: 1, noDataText: <div><br /><b>No checklists available</b></div>, showPagination: false, style: { border: 'none' }},
			crudParams: { checklistPosition: checklistPositions.MidForm, wcId: this.props.detailId},
            cascade: true,
            cascadeComponents: this.cascadeLists(),
            defaultSorted: [{ id: 'id', desc: false }],
            gridButton: false,
            preExpand: { 0: true },
            apiPath: API_PATH
            //mdData: (e) => this.parseMDData(e)
        }

        //return ([this.props.preCheckOnly ? null : <MasterDetail {...checklistConfig} key='mdChecklist' />, this.state.clCount > 0 && this.state.modalOpen ? this.getModalChecklists() : this.props.onComplete ? this.props.onComplete() : null])

        return (
            <div>
                {!this.props.preCheckOnly && <MasterDetail {...checklistConfig} key='mdChecklist' />}
                {this.state.clCount > 0 && this.state.modalOpen ? this.getModalChecklists() : (this.props.onComplete && this.state.clCount == 0 && this.props.onComplete())}
            </div>
        )

    }
}
