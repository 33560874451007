import { TRY_LOGIN, FORGOT_PASSWORD, SET_AUTH_INFO } from './actions'


const reducer = (state = { model: {}, authInfo: false, userInfo: null }, action) => {

  switch (action.type) {
    case TRY_LOGIN:
      console.log("login")
      return {
          ...state,

      }
    case SET_AUTH_INFO:
        return {
            ...state,
            authInfo: action.authInfo,
            authRole: action.userInfo.roles,
            authInternalId: action.userInfo.internalId,
            authClientId: action.userInfo.clientId
        }
    default: 
      return state
  }
}

export default reducer