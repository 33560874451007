import React, { Component } from 'react';
import { Collapse, CardBlock, Card, Col, Form, FormGroup, Label, Input, FormText, Button, Container, Row } from 'reactstrap';
import Collapsible from 'react-collapsible';
import LogDetail from './LogDetail';
import DropDatePicker from '../../../components/DropDatePicker';
import moment from 'moment';

export default class IncidentDetail extends Component {

    constructor(props) {
        super(props);
        var toDate = moment();
        var fromDate = moment().subtract(30, 'days');
        this.state = {
            selectedRange: {}, collapse: false, fromDate: fromDate, toDate: toDate
        }
        this.handleDate = this.handleDate.bind(this);
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    handleChange(event) {
        this.setState({selectedRange : event });
    }

    handleDate(fromDate, toDate) {
        this.setState({ fromDate: fromDate, toDate: toDate });
    }

    renderCase() {
        return (
            <Collapsible trigger="Case" transitionTime={200} open={true}>
                <Card>
                    <CardBlock>
                        <Form>
                            <FormGroup row>
                                <Label for="exampleSelect1" sm={3}>Hydraulic Pressure Low</Label>
                                <Col sm={4}>
                                    <Input type="select" name="select" id="exampleSelect">
                                        <option>New Case</option>
                                        <option>Negative Power Gen</option>
                                        <option>Low Wind</option>
                                        <option>Yaw Motor Fault</option>
                                        <option>Fault Anemometer</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Suggested Case</Label>
                                <Col sm={3}>
                                    <label>Hydraulic Pressure Low</label>
                                </Col>
                                <Col sm={1}>
                                    <Button className="btn pull-right">Use</Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>New Case Name</Label>
                                <Col sm={4}>
                                    <input type="text" className="form-control" placeholder="New Case Name" />
                                </Col>
                                <Col sm={4} />
                                <Col sm={1}>
                                    <Button className="btn btn-primary pull-right">Save</Button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </CardBlock>
                </Card>
            </Collapsible>)
    }

    renderFault() {
        return (<Collapsible trigger="VMP4400: Hydraulic Pressure Low" transitionTime={200}>
            <Card>
                <CardBlock>
                    <Form>
                        <FormGroup row>
                            <Label sm={2}>Controller:</Label>
                            <Col sm={10}>
                                <label>VMP4400</label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Error Num:</Label>
                            <Col sm={10}>
                                <label>133</label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Monitoring:</Label>
                            <Col sm={4}>
                                <label>1 sec. scan in pause and run</label>
                            </Col>
                            <Label sm={2}>Signal:</Label>
                            <Col sm={4}>
                                <label>F106, F107</label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Fault Criteria:</Label>
                            <Col sm={10}>
                                <label>One of the signals is disabled</label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Reaction:</Label>
                            <Col sm={10}>
                                <label>Stop</label>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Acknowledge:</Label>
                            <Col sm={4}>
                                <label>Remote</label>
                            </Col>
                            <Label sm={2}>Alert Delay:</Label>
                            <Col sm={4}>
                                <label>60 minutes</label>
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBlock>
            </Card>
        </Collapsible>)
    }

    renderDowntime() {
        return (<Collapsible trigger="Downtime: 122 Minutes" transitionTime={200}>
            <Card>
                <CardBlock>
                    <Form>
                        <FormGroup row>
                            <Label sm={2}>From:</Label>
                            <Col sm={4}>
                                <DropDatePicker handleDate={this.handleDate} startDate={this.state.fromDate} endDate={this.state.toDate} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Downtime Exempt:</Label>
                            <Col sm={1}>
                                <input className="form-check-input" type="checkbox" value="" style={{ marginLeft: '0' }} />
                            </Col>
                            <Col sm={8} />
                            <Col sm={1}>
                                <Button className="btn btn-primary pull-right">Save</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBlock>
            </Card>
        </Collapsible>)
    }

    renderButtons() {
        return (
            <div className="row container-fluid pt-3">

                <div className="col-6 col-sm-4 col-md-4 col-lg-2 p-0">
                    <button type="button" className="btn btn-secondary cntrlbtn">L1 - Reset</button>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-2 p-0">
                    <button type="button" className="btn btn-secondary cntrlbtn">L2 - Local Reset</button>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-2 p-0">
                    <button type="button" className="btn btn-secondary cntrlbtn">L3 - Site Visit</button>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-2 p-0">
                    <button type="button" className="btn btn-secondary cntrlbtn">L4 - Outsource</button>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-2 p-0">
                    <button type="button" className="btn btn-secondary cntrlbtn">Escalate</button>
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-2 p-0">
                    <button type="button" className="btn btn-secondary cntrlbtn">Note</button>
                </div>

            </div>)
    }

    renderLogdetail() {
        return (<div className="log-detail-list">
            <LogDetail logLevel="L1" logDate="13/07/2018 12:00" logUser="Adam Smyth" logMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio." />
            <LogDetail logLevel="L3" logDate="13/07/2018 12:00" logUser="Adam Smyth" logMessage="Praesent libero. Sed cursus ante dapibus diam. Sed nisi." />
            <LogDetail logLevel="L4" logDate="13/07/2018 12:00" logUser="Adam Smyth" logMessage="Class aptent taciti sociosqu ad litora torquent per conubia nostra." />
        </div>)
    }

    render() {

        const format = 'dddd, D MMMM YYYY';

        return (
            <div>

                <div className="d-flex justify-content-end" style={{ borderBottom: '1px solid rgba(99, 99, 99, 0.2)' }}>
                    <div className="mr-auto p-2">
                        <h1>Incident Detail</h1>
                        <p style={{ fontSize: '1.3rem' }}>01/04/2018 to 01/06/2018 <b>></b> Hydraulic Pressure Low <b>> </b><b style={{ color: '#d0021b' }}>Fault 10345, Hydr Pressure Low</b></p>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="accordionCards">
                            {this.renderCase()}
                            {this.renderFault()}
                            {this.renderDowntime()}
                    </div>
                    {this.renderButtons()}
                </div>

                {this.renderLogdetail()}
            </div>
        );
    }
}
