import React from "react";
import {connect, distatch} from "react-redux"
import { Field, reduxForm, initialize } from "redux-form";

class TestForm  extends React.Component {
   constructor(props) {
    super(props)
    this.submit = this.submit.bind(this);
  }

  submit() {
    
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, initialValues, dispatch, onSubmit } = this.props;
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field hidden={true}
                name="id"
                component="input"
                type="text"
                placeholder="id"
              />   {/*  Must have this field */}
          <div>
            <label>First Name</label>
            <div>
              <Field
                name="name" /*  Must match field name */
                component="input"
                type="text"
                placeholder="First Name"
              />
            </div>
          </div>
          {/*  don't add a submit button if used in a modal */}
        </form>
      )
  }
}

TestForm = reduxForm({
  form: "TestForm" // a unique identifier for this form
})(TestForm);

TestForm = connect(
    (state, ownProps) => ({
        initialValues: ownProps.data.getCurrent(state), enableReinitialize: true
    }), 
      null, 
      null, 
      { withRef: true }  // Must allow the instance to be referenced
)(TestForm)


export default TestForm