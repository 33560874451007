import { SET_RECORD } from './actions'


const crud = (state = { model: {} }, action) => {

  switch (action.type) {
    case SET_RECORD:
      let dict = state.model
      dict[action.model] = {
        currentRecId: action.payload.id  
      }
      return {
        ...state,
        model: dict
      }
    case 'redux-crud-store/crud/CREATE':
    case 'redux-crud-store/crud/UPDATE':
    case 'redux-crud-store/crud/DELETE':

    let dict2 = state.model
    if (!action.payload.params.retain) {
        dict2[action.meta.model] = {
            currentRecId: null
        }
    }
      
      return {
        ...state,
        model: dict2
          }
    case 'redux-crud-store/crud/DELETE_SUCCESS':
          console.log('delete success');
    default: 
      return state
  }
}

export default crud