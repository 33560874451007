import React from 'react';

const OverlayLoader = (props) => {

        const label = props.label ? props.label : 'Loading...'
        const iconColour = props.iconColour ? props.iconColour : '#a7a7a7'
    const icon = props.icon ? props.icon : 'fad fa-spinner-third'
        const rotate = props.rotate == false ? '' : 'fa-spin'
        const iconSize = props.iconSize ? props.iconSize : 'fa-3x'
        const fullIcon = icon + ' ' + rotate + ' ' + iconSize
        const actionButton = props.actionButton || null

        return (
            <div className="loaderOverlay">
                <div style={{textAlign:'center'}}>
                    <i className={fullIcon} style={{ color: iconColour }} />
                    <b style={{ marginTop: '5px', display: 'block' }}>{label}</b>
                    {actionButton}
                </div>
            </div>
        );
}

export default OverlayLoader;