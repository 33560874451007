import redux from 'redux'
export const GET_APP_LOOKUPS = 'GET_APP_LOOKUPS'
export const GOT_APP_LOOKUPS = 'GOT_APP_LOOKUPS'

import { ApiGet } from '../api'

import { widgetconfig } from '../configuration/widgets';

export function getLookups() {
    return (dispatch) => {
        ApiGet('App','GetLookups')
        .then((items) =>
            dispatch({
                type: GOT_APP_LOOKUPS,
                payload: items
            })
        )
    }
}

