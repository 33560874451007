import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ExportModal from './MeteringExportModal';
import moment from 'moment';

class MeteringController extends Component {
    constructor(props) {
        super(props);
        var date = moment();
        var fromDate = moment().subtract(30, 'days');
        this.state = {
            selectedType: 1, selectedMonth: date.month(), exportOpen: false, toDate: date, fromDate: fromDate, month: 0, year: date.year()
        };
        this.handleDate = this.handleDate.bind(this);
        this.typeChange = this.typeChange.bind(this);
    }
   
    typeChange(event, type) {
        if (type == 'type') {
            this.setState({ selectedType: event.target.value }, () => this.props.handleData([this.state.selectedType, this.state.month, this.state.year]));
            //this.props.handleData(event.target.value);
        }
        else if (type == 'month') {
            this.setState({ month: event.target.value }, () => this.props.handleData([this.state.selectedType, this.state.month, this.state.year]));
        }
        else if (type == 'year') {
            this.setState({ year: event.target.value }, () => this.props.handleData([this.state.selectedType, this.state.month, this.state.year]));
        }
    }

    handleDate(fromDate, toDate) {
        this.setState({ fromDate: fromDate, toDate: toDate });
    }

    toggleExportModal() {
        this.setState({ exportOpen: !this.state.exportOpen })
    }  

    renderDays() {
        var r = [];
        for (var i = 1; i < 32; i++) {
            r.push(<option value={i}>{i}</option>)
        }
        return r
    }

	render() {

        var ddStart = moment('2016-01-01')
        var ddEnd = moment()
        var yearValues = []
        while (ddEnd > ddStart || ddStart.format('Y') === ddEnd.format('Y')) {
            yearValues.push(<option value={ddEnd.format('YYYY')}>{ddEnd.format('YYYY')}</option>)
            ddEnd.add(-1, 'year')
        }

        return (
            <div>
                <Row style={{marginBottom:'15px'}}>
                    <Col lg={2} xs={6}>
                        <select className="form-control" onChange={(e) => this.typeChange(e, 'type')} >
                            <option value={1}>Monthly</option>
                            <option value={2}>Daily</option>
                        </select>
                    </Col>
                    <Col lg={2} xs={6}>
                        <select className="form-control" onChange={(e) => this.typeChange(e, 'year')} >
                            {yearValues}
                        </select>
                    </Col>
                    {this.state.selectedType == 2 ? <Col lg={2} xs={6}>
                        <select className="form-control" onChange={(e) => this.typeChange(e, 'month')} >
                            <option value={0}>All</option>
                            <option value={1}>January</option>
                            <option value={2}>February</option>
                            <option value={3}>March</option>
                            <option value={4}>April</option>
                            <option value={5}>May</option>
                            <option value={6}>June</option>
                            <option value={7}>July</option>
                            <option value={8}>August</option>
                            <option value={9}>September</option>
                            <option value={10}>October</option>
                            <option value={11}>November</option>
                            <option value={12}>December</option>
                        </select>
                    </Col> : <Col lg={2} />}
                    <Col lg={3} />
                    <Col lg={3} xs={6}>
                        <i className="fa fa-download chart-option-btn" style={{marginTop:'10px'}} onClick={() => this.toggleExportModal()} />
                    </Col>
                </Row>
				{this.state.exportOpen ? <ExportModal toggleExportModal={() => this.toggleExportModal()} stations={this.props.header.assets} handleDate={this.handleDate} startDate={this.state.fromDate} endDate={this.state.toDate}  sample={true} stations={this.props.header.assets} {...this.state} /> : null}
            </div>
        );
    }

};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(MeteringController)
