import React from 'react'
import { ApiGet,ApiJsonPost } from '../../api'
import StandardButton from './StandardButton'

export default class Workflow extends React.Component {

    constructor(props) {
        super(props)
        const { template } = this.props;
        const { start } = template;
        var his = [];
        his.push(start);
        this.state = {
            history: his,
            curLevel: start,
            curIndex: 0,
            data: {},
            validationMessage: "",
            valid: true,
            initData: null
        }
        this.updateData = this.updateData.bind(this)
        this.getData = this.getData.bind(this)
        this.buttons = this.buttons.bind(this)
        this.submitWFData = this.submitWFData.bind(this)
        this.changeLevel = this.changeLevel.bind(this)
        this.backLevel = this.backLevel.bind(this)
        this.advanceLevel = this.advanceLevel.bind(this)
        this.standardComponents =  {
            'StandardButton': StandardButton
        }
    }

    initData(data) {
        this.setState({
            initData: data
        })
    }

    updateData(lvlname,data, valid, validationMessage) {
        let dic = {};
        if (data != null)  {dic[lvlname] = data; }
        this.setState({validationMessage, 
            valid,
            data: {
                ...this.state.data,
                ...dic
            }
        })
        //console.log("data ", { lvlname, data, valid, validationMessage })
    }

    getData(level) {
        if (level) {
            return this.state.data[level]?this.state.data[level]:null;
        } else {
            return this.state.data
        }
    }

    submitWFData(controller, funcname, param) {
        const {history, data } = this.state;
        const submitData = {
            history,
            ...data,
            param:param
        }
        const ret =  ApiJsonPost(controller,funcname, submitData);
        return ret;
    }

    onsubmit() {
        this.props.onsubmit(this.state.data);
    }

    getComp(compName) {
        const comp = {
            ...this.standardComponents,
            ...this.props.components
        }
        return comp[compName];
    }

    getComponent(lvl) {
        var ret = null;
        const btn = lvl.template;
        const CustomTag = this.getComp(btn.module);
        ret = <CustomTag {...btn} key={btn.title} initData={this.props.data} level={lvl.name} submitWFData={this.submitWFData} updateData={this.updateData} getData={this.getData} />
        return ret;
    }

    getBtnComponent(btn) {
        var ret = null;
        var modulename = btn.module;
        var cls = "";
        var show = true;
        var allowed = true;
        if (!modulename) {
            modulename = "StandardButton"
            cls = ""
            if (btn.className) {
                cls += " " + btn.className
            } else {
                cls += " btn-dark"
            }
        } else if (modulename == "CommandButton") {
            var wfOpt = this.props.data.wfOptions;
            show = false;
            if (wfOpt) {
                allowed = false;
                //console.log('wfOpt', this.props.data)
                if (wfOpt.supportedCommands) {
                    for (var idname of wfOpt.supportedCommands) {
                        if (btn.data == idname.n) {
                            show = true;
                            allowed = (idname.code == 1);
                        }
                    }
                }
            }
        }
        if (show) {
            const CustomTag = this.getComp(modulename);
            ret = <CustomTag {...btn} key={btn.title} disabled={!this.state.valid || !allowed} className={cls} initData={this.props.data} updateData={this.updateData} getData={this.getData} submitWFData={this.submitWFData} advanceLevel={this.advanceLevel} submitController={btn.submitController} submit={btn.submit} cmdSucess={this.props.cmdSucess} />
        } else {
            ret = <div />;
        }
        return ret;
    }

    changeLevel(index) {
        var i = 0;
        var newArr = [];
        var newLevelId = "";
        for (i = 0; i<= index;i++) {
            newArr.push(this.state.history[i]);
            newLevelId = this.state.history[i];
        }
        this.setState({ history: newArr, curLevel:newLevelId, curIndex: index, valid: true })
    }

    backLevel() {
        this.changeLevel(this.state.curIndex -1)
    }

    getLevel() {
        const { template } = this.props;
        const { start, levels } = template;
        const { curLevel } = this.state;
        var ret = {};
        var lvlName = "";
        if (curLevel == "") {
            ret.name  = template.start;
        } else {
            ret.name  = curLevel;
        }
        ret.template = levels[ret.name];
        return ret;
    }

    advanceLevel(newlevel, message) {
        if (newlevel == "root") {
            this.setState({ history: [newlevel],
                curLevel:newlevel, 
                curIndex: 0,
                data:{},
                validationMessage: "",
                valid: true,
                initData: null })
        } else {
            let dic = {}; // add message info to next level state
            dic[newlevel] = { message: message }; 
            this.setState( { history: this.state.history.concat(newlevel),
                curLevel:newlevel, 
                curIndex: this.state.curIndex+1,
                data: {
                    ...this.state.data,
                    ...dic
                } 
            })
        }
    }

    buttons() {
        const lvl = this.getLevel();
        const props = this.props
        //console.log('wf props', props)
        return (
            <div className="wf-buttons">
            {(this.state.curIndex > 0)?
                <button className="btn" onClick={() => this.backLevel()}>Back</button>
            :
            null
            }
            {lvl.template.btns.map(
                (btn, index) => {
                    //console.log('btn',btn)
                    var cls = ""
                    if (btn.className) {
                        cls += " " + btn.className
                    } else {
                        cls += " btn-dark"
                    }
                    var show = true;
                    var ret = null;
                    if (btn.if) {
                        show = (props[btn.if] != undefined)
                    }
                    if (show) {
                        ret = this.getBtnComponent(btn)
                    }
                    return ret;
                }
            )}
            </div>
        )
    }
    
    wfContainer() {
        const lvl = this.getLevel();
        
        return (
            lvl.template.module?
                <div className="wf-container">
                    {this.getComponent(lvl)}
                </div>
                :null
        )
    }

    breadcrumb() {
        const { template,hideRoot=false } = this.props;
        const { start, levels } = template;
        const { history, curLevel } = this.state;
        if (history.length == 1 && hideRoot) {
            return (null)
        } else {
            return ( 
                <div className="wf-breadcrumb">
                {history.map(
                    (levelname,index) => {
                        let lvl = levels[levelname]
                        let prefix = null;
                        if (index > 0) {
                            prefix = " > "
                        }
                        return (
                            <a key={index} onClick={() => { this.changeLevel(index) }}>
                                {prefix}
                                {lvl.title}
                            </a>
                        )
                    }
                )}
                </div>
            )
        }
    }

    render() {
        const { template } = this.props;
        const { start, levels } = template;
        const rootlevel = levels[start];

        return (
            <div>
                {this.breadcrumb()}
                {this.wfContainer()}
                {this.buttons()}
            </div>
        )
    }
}