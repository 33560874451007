export const morningWeekend = [
    "2020-01-06T00:00:00+00:00",
    "2020-01-06T01:00:00+00:00",
    "2020-01-06T02:00:00+00:00",
    "2020-01-06T03:00:00+00:00",
    "2020-01-06T04:00:00+00:00",
    "2020-01-06T05:00:00+00:00",
    "2020-01-06T06:00:00+00:00",
    "2020-01-06T07:00:00+00:00",
    "2020-01-06T08:00:00+00:00",
    "2020-01-07T00:00:00+00:00",
    "2020-01-07T01:00:00+00:00",
    "2020-01-07T02:00:00+00:00",
    "2020-01-07T03:00:00+00:00",
    "2020-01-07T04:00:00+00:00",
    "2020-01-07T05:00:00+00:00",
    "2020-01-07T06:00:00+00:00",
    "2020-01-07T07:00:00+00:00",
    "2020-01-07T08:00:00+00:00",
    "2020-01-08T00:00:00+00:00",
    "2020-01-08T01:00:00+00:00",
    "2020-01-08T02:00:00+00:00",
    "2020-01-08T03:00:00+00:00",
    "2020-01-08T04:00:00+00:00",
    "2020-01-08T05:00:00+00:00",
    "2020-01-08T06:00:00+00:00",
    "2020-01-08T07:00:00+00:00",
    "2020-01-08T08:00:00+00:00",
    "2020-01-09T00:00:00+00:00",
    "2020-01-09T01:00:00+00:00",
    "2020-01-09T02:00:00+00:00",
    "2020-01-09T03:00:00+00:00",
    "2020-01-09T04:00:00+00:00",
    "2020-01-09T05:00:00+00:00",
    "2020-01-09T06:00:00+00:00",
    "2020-01-09T07:00:00+00:00",
    "2020-01-09T08:00:00+00:00",
    "2020-01-10T00:00:00+00:00",
    "2020-01-10T01:00:00+00:00",
    "2020-01-10T02:00:00+00:00",
    "2020-01-10T03:00:00+00:00",
    "2020-01-10T04:00:00+00:00",
    "2020-01-10T05:00:00+00:00",
    "2020-01-10T06:00:00+00:00",
    "2020-01-10T07:00:00+00:00",
    "2020-01-10T08:00:00+00:00",
    "2020-01-11T00:00:00+00:00",
    "2020-01-11T01:00:00+00:00",
    "2020-01-11T02:00:00+00:00",
    "2020-01-11T03:00:00+00:00",
    "2020-01-11T04:00:00+00:00",
    "2020-01-11T05:00:00+00:00",
    "2020-01-11T06:00:00+00:00",
    "2020-01-11T07:00:00+00:00",
    "2020-01-11T08:00:00+00:00",
    "2020-01-12T00:00:00+00:00",
    "2020-01-12T01:00:00+00:00",
    "2020-01-12T02:00:00+00:00",
    "2020-01-12T03:00:00+00:00",
    "2020-01-12T04:00:00+00:00",
    "2020-01-12T05:00:00+00:00",
    "2020-01-12T06:00:00+00:00",
    "2020-01-12T07:00:00+00:00",
    "2020-01-12T08:00:00+00:00",
    "2020-01-11T09:00:00+00:00",
    "2020-01-11T10:00:00+00:00",
    "2020-01-11T11:00:00+00:00",
    "2020-01-11T12:00:00+00:00",
    "2020-01-11T13:00:00+00:00",
    "2020-01-11T14:00:00+00:00",
    "2020-01-11T15:00:00+00:00",
    "2020-01-11T16:00:00+00:00",
    "2020-01-11T17:00:00+00:00",
    "2020-01-11T18:00:00+00:00",
    "2020-01-11T19:00:00+00:00",
    "2020-01-11T20:00:00+00:00",
    "2020-01-11T21:00:00+00:00",
    "2020-01-11T22:00:00+00:00",
    "2020-01-11T23:00:00+00:00",
    "2020-01-12T09:00:00+00:00",
    "2020-01-12T10:00:00+00:00",
    "2020-01-12T11:00:00+00:00",
    "2020-01-12T12:00:00+00:00",
    "2020-01-12T13:00:00+00:00",
    "2020-01-12T14:00:00+00:00",
    "2020-01-12T15:00:00+00:00",
    "2020-01-12T16:00:00+00:00",
    "2020-01-12T17:00:00+00:00",
    "2020-01-12T18:00:00+00:00",
    "2020-01-12T19:00:00+00:00",
    "2020-01-12T20:00:00+00:00",
    "2020-01-12T21:00:00+00:00",
    "2020-01-12T22:00:00+00:00",
    "2020-01-12T23:00:00+00:00"
]

export const weekend = [
    "2020-01-11T00:00:00+00:00",
    "2020-01-11T01:00:00+00:00",
    "2020-01-11T02:00:00+00:00",
    "2020-01-11T03:00:00+00:00",
    "2020-01-11T04:00:00+00:00",
    "2020-01-11T05:00:00+00:00",
    "2020-01-11T06:00:00+00:00",
    "2020-01-11T07:00:00+00:00",
    "2020-01-11T08:00:00+00:00",
    "2020-01-12T00:00:00+00:00",
    "2020-01-12T01:00:00+00:00",
    "2020-01-12T02:00:00+00:00",
    "2020-01-12T03:00:00+00:00",
    "2020-01-12T04:00:00+00:00",
    "2020-01-12T05:00:00+00:00",
    "2020-01-12T06:00:00+00:00",
    "2020-01-12T07:00:00+00:00",
    "2020-01-12T08:00:00+00:00",
    "2020-01-11T09:00:00+00:00",
    "2020-01-11T10:00:00+00:00",
    "2020-01-11T11:00:00+00:00",
    "2020-01-11T12:00:00+00:00",
    "2020-01-11T13:00:00+00:00",
    "2020-01-11T14:00:00+00:00",
    "2020-01-11T15:00:00+00:00",
    "2020-01-11T16:00:00+00:00",
    "2020-01-11T17:00:00+00:00",
    "2020-01-11T18:00:00+00:00",
    "2020-01-11T19:00:00+00:00",
    "2020-01-11T20:00:00+00:00",
    "2020-01-11T21:00:00+00:00",
    "2020-01-11T22:00:00+00:00",
    "2020-01-11T23:00:00+00:00",
    "2020-01-12T09:00:00+00:00",
    "2020-01-12T10:00:00+00:00",
    "2020-01-12T11:00:00+00:00",
    "2020-01-12T12:00:00+00:00",
    "2020-01-12T13:00:00+00:00",
    "2020-01-12T14:00:00+00:00",
    "2020-01-12T15:00:00+00:00",
    "2020-01-12T16:00:00+00:00",
    "2020-01-12T17:00:00+00:00",
    "2020-01-12T18:00:00+00:00",
    "2020-01-12T19:00:00+00:00",
    "2020-01-12T20:00:00+00:00",
    "2020-01-12T21:00:00+00:00",
    "2020-01-12T22:00:00+00:00",
    "2020-01-12T23:00:00+00:00"
]

export const outOfOffice = [
    "2020-01-11T00:00:00+00:00",
    "2020-01-11T01:00:00+00:00",
    "2020-01-11T02:00:00+00:00",
    "2020-01-11T03:00:00+00:00",
    "2020-01-11T04:00:00+00:00",
    "2020-01-11T05:00:00+00:00",
    "2020-01-11T06:00:00+00:00",
    "2020-01-11T07:00:00+00:00",
    "2020-01-11T08:00:00+00:00",
    "2020-01-12T00:00:00+00:00",
    "2020-01-12T01:00:00+00:00",
    "2020-01-12T02:00:00+00:00",
    "2020-01-12T03:00:00+00:00",
    "2020-01-12T04:00:00+00:00",
    "2020-01-12T05:00:00+00:00",
    "2020-01-12T06:00:00+00:00",
    "2020-01-12T07:00:00+00:00",
    "2020-01-12T08:00:00+00:00",
    "2020-01-11T09:00:00+00:00",
    "2020-01-11T10:00:00+00:00",
    "2020-01-11T11:00:00+00:00",
    "2020-01-11T12:00:00+00:00",
    "2020-01-11T13:00:00+00:00",
    "2020-01-11T14:00:00+00:00",
    "2020-01-11T15:00:00+00:00",
    "2020-01-11T16:00:00+00:00",
    "2020-01-11T17:00:00+00:00",
    "2020-01-11T18:00:00+00:00",
    "2020-01-11T19:00:00+00:00",
    "2020-01-11T20:00:00+00:00",
    "2020-01-11T21:00:00+00:00",
    "2020-01-11T22:00:00+00:00",
    "2020-01-11T23:00:00+00:00",
    "2020-01-12T09:00:00+00:00",
    "2020-01-12T10:00:00+00:00",
    "2020-01-12T11:00:00+00:00",
    "2020-01-12T12:00:00+00:00",
    "2020-01-12T13:00:00+00:00",
    "2020-01-12T14:00:00+00:00",
    "2020-01-12T15:00:00+00:00",
    "2020-01-12T16:00:00+00:00",
    "2020-01-12T17:00:00+00:00",
    "2020-01-12T18:00:00+00:00",
    "2020-01-12T19:00:00+00:00",
    "2020-01-12T20:00:00+00:00",
    "2020-01-12T21:00:00+00:00",
    "2020-01-12T22:00:00+00:00",
    "2020-01-12T23:00:00+00:00",
    "2020-01-06T00:00:00+00:00",
    "2020-01-06T01:00:00+00:00",
    "2020-01-06T02:00:00+00:00",
    "2020-01-06T03:00:00+00:00",
    "2020-01-06T04:00:00+00:00",
    "2020-01-06T05:00:00+00:00",
    "2020-01-06T06:00:00+00:00",
    "2020-01-06T07:00:00+00:00",
    "2020-01-06T08:00:00+00:00",
    "2020-01-07T00:00:00+00:00",
    "2020-01-07T01:00:00+00:00",
    "2020-01-07T02:00:00+00:00",
    "2020-01-07T03:00:00+00:00",
    "2020-01-07T04:00:00+00:00",
    "2020-01-07T05:00:00+00:00",
    "2020-01-07T06:00:00+00:00",
    "2020-01-07T07:00:00+00:00",
    "2020-01-07T08:00:00+00:00",
    "2020-01-08T00:00:00+00:00",
    "2020-01-08T01:00:00+00:00",
    "2020-01-08T02:00:00+00:00",
    "2020-01-08T03:00:00+00:00",
    "2020-01-08T04:00:00+00:00",
    "2020-01-08T05:00:00+00:00",
    "2020-01-08T06:00:00+00:00",
    "2020-01-08T07:00:00+00:00",
    "2020-01-08T08:00:00+00:00",
    "2020-01-09T00:00:00+00:00",
    "2020-01-09T01:00:00+00:00",
    "2020-01-09T02:00:00+00:00",
    "2020-01-09T03:00:00+00:00",
    "2020-01-09T04:00:00+00:00",
    "2020-01-09T05:00:00+00:00",
    "2020-01-09T06:00:00+00:00",
    "2020-01-09T07:00:00+00:00",
    "2020-01-09T08:00:00+00:00",
    "2020-01-10T00:00:00+00:00",
    "2020-01-10T01:00:00+00:00",
    "2020-01-10T02:00:00+00:00",
    "2020-01-10T03:00:00+00:00",
    "2020-01-10T04:00:00+00:00",
    "2020-01-10T05:00:00+00:00",
    "2020-01-10T06:00:00+00:00",
    "2020-01-10T07:00:00+00:00",
    "2020-01-10T08:00:00+00:00",
    "2020-01-06T18:00:00+00:00",
    "2020-01-06T19:00:00+00:00",
    "2020-01-06T20:00:00+00:00",
    "2020-01-06T21:00:00+00:00",
    "2020-01-06T22:00:00+00:00",
    "2020-01-06T23:00:00+00:00",
    "2020-01-07T18:00:00+00:00",
    "2020-01-07T19:00:00+00:00",
    "2020-01-07T20:00:00+00:00",
    "2020-01-07T21:00:00+00:00",
    "2020-01-07T22:00:00+00:00",
    "2020-01-07T23:00:00+00:00",
    "2020-01-08T18:00:00+00:00",
    "2020-01-08T19:00:00+00:00",
    "2020-01-08T20:00:00+00:00",
    "2020-01-08T21:00:00+00:00",
    "2020-01-08T22:00:00+00:00",
    "2020-01-08T23:00:00+00:00",
    "2020-01-09T18:00:00+00:00",
    "2020-01-09T19:00:00+00:00",
    "2020-01-09T20:00:00+00:00",
    "2020-01-09T21:00:00+00:00",
    "2020-01-09T22:00:00+00:00",
    "2020-01-09T23:00:00+00:00",
    "2020-01-10T18:00:00+00:00",
    "2020-01-10T19:00:00+00:00",
    "2020-01-10T20:00:00+00:00",
    "2020-01-10T21:00:00+00:00",
    "2020-01-10T22:00:00+00:00",
    "2020-01-10T23:00:00+00:00",
    "2020-01-11T18:00:00+00:00",
    "2020-01-11T19:00:00+00:00",
    "2020-01-11T20:00:00+00:00",
    "2020-01-11T21:00:00+00:00",
    "2020-01-11T22:00:00+00:00",
    "2020-01-11T23:00:00+00:00"
]

export const night = [
    "2020-01-11T00:00:00+00:00",
    "2020-01-11T01:00:00+00:00",
    "2020-01-11T02:00:00+00:00",
    "2020-01-11T03:00:00+00:00",
    "2020-01-11T04:00:00+00:00",
    "2020-01-11T05:00:00+00:00",
    "2020-01-11T06:00:00+00:00",
    "2020-01-11T07:00:00+00:00",
    "2020-01-11T08:00:00+00:00",
    "2020-01-12T00:00:00+00:00",
    "2020-01-12T01:00:00+00:00",
    "2020-01-12T02:00:00+00:00",
    "2020-01-12T03:00:00+00:00",
    "2020-01-12T04:00:00+00:00",
    "2020-01-12T05:00:00+00:00",
    "2020-01-12T06:00:00+00:00",
    "2020-01-12T07:00:00+00:00",
    "2020-01-12T08:00:00+00:00",
    "2020-01-06T00:00:00+00:00",
    "2020-01-06T01:00:00+00:00",
    "2020-01-06T02:00:00+00:00",
    "2020-01-06T03:00:00+00:00",
    "2020-01-06T04:00:00+00:00",
    "2020-01-06T05:00:00+00:00",
    "2020-01-06T06:00:00+00:00",
    "2020-01-06T07:00:00+00:00",
    "2020-01-06T08:00:00+00:00",
    "2020-01-07T00:00:00+00:00",
    "2020-01-07T01:00:00+00:00",
    "2020-01-07T02:00:00+00:00",
    "2020-01-07T03:00:00+00:00",
    "2020-01-07T04:00:00+00:00",
    "2020-01-07T05:00:00+00:00",
    "2020-01-07T06:00:00+00:00",
    "2020-01-07T07:00:00+00:00",
    "2020-01-07T08:00:00+00:00",
    "2020-01-08T00:00:00+00:00",
    "2020-01-08T01:00:00+00:00",
    "2020-01-08T02:00:00+00:00",
    "2020-01-08T03:00:00+00:00",
    "2020-01-08T04:00:00+00:00",
    "2020-01-08T05:00:00+00:00",
    "2020-01-08T06:00:00+00:00",
    "2020-01-08T07:00:00+00:00",
    "2020-01-08T08:00:00+00:00",
    "2020-01-09T00:00:00+00:00",
    "2020-01-09T01:00:00+00:00",
    "2020-01-09T02:00:00+00:00",
    "2020-01-09T03:00:00+00:00",
    "2020-01-09T04:00:00+00:00",
    "2020-01-09T05:00:00+00:00",
    "2020-01-09T06:00:00+00:00",
    "2020-01-09T07:00:00+00:00",
    "2020-01-09T08:00:00+00:00",
    "2020-01-10T00:00:00+00:00",
    "2020-01-10T01:00:00+00:00",
    "2020-01-10T02:00:00+00:00",
    "2020-01-10T03:00:00+00:00",
    "2020-01-10T04:00:00+00:00",
    "2020-01-10T05:00:00+00:00",
    "2020-01-10T06:00:00+00:00",
    "2020-01-10T07:00:00+00:00",
    "2020-01-10T08:00:00+00:00"
]