import React, { Component } from 'react'
import helpers from '../../../utils/helpers'
import moment from 'moment'
import { Modal, ModalFooter, ModalHeader, ModalBody, Row, Col } from 'reactstrap'
import CheckBox from '../../../components/Controls/CheckBox'
import { ApiPost } from '../../../api'

class VanLocationWidget extends Component {

    constructor(props) {
        super(props);
        this.state = { edits: {}, cbSite: null, editAddress: null  }
    }

//{
//    this.props.widgetValue.map(x => { return <div>T</div> })
//}

    getVanStatus(vanDeet) {

        var now = moment.utc().format("YYYY-MM-DD HH:mm:ss")
        var lastM = moment(vanDeet.lastMovement).format("YYYY-MM-DD HH:mm:ss")
        var lastS = moment(vanDeet.lastSync).format("YYYY-MM-DD HH:mm:ss")
        var sLM = moment.duration(moment(now).diff(moment(lastM))).asMinutes()
        var sLS = moment.duration(moment(now).diff(moment(lastS))).asMinutes()
        var duration = null
        var dRender = null
        var colour = '#27c564'
        var vIcon = 'fas fa-truck'

        if ((vanDeet.speed == 0 && sLM > 2) ) {
            colour = '#5a5a5a'
            duration = helpers.parseDuration(null, null, sLM)
            dRender = <i className='fas fa-minus-octagon' title={'Stopped for ' + duration} style={{ color: '#d0021b' }} />
            if (vanDeet.onSite) {
                vIcon = 'fas fa-map-marker-alt'
            }
        }
        if ((sLS > 10) || (sLM > 60 &&  vanDeet.speed > 1 )){
            colour = '#96969663'
            duration = helpers.parseDuration(null, null, sLM)
            dRender = <i className='fas fa-question-circle' title='No comms' style={{ color: '#969696' }} />
        } 

        return {
            render: <i className={vIcon} style={{ color: colour, fontSize: '1em', display: 'inline-block', margin: '0 5px 0 0', minWidth:'18px' }} />,
            duration: duration && <div style={{ position:'absolute', right:'5px', top:0, backgroundColor:'#FFF'}}>{dRender && dRender}<span style={{ marginLeft: '5px', fontSize: '0.8em', fontWeight: 'bold' }}>({duration})</span></div>
        }
        //return <div><i className='fa fello-vantf' style={{ color: colour, fontSize: '1em' }} />{duration && <span style={{ marginLeft: '30px', fontSize: '0.8em', fontWeight: 'bold' }}>({duration})</span>}</div>
    }

    parseAddress(add) {
        var parts = add.split(',')
        return parts[0] + (parts[1] ? ', ' + parts[1].split(' ')[1] : '')
    }

    getFuncBtns(rec) {
        return <button className='btn btn-default btn-option' onClick={() => this.toggleEdit(rec)} style={{ padding: '0 0.1rem', color: '#666', display: 'none', position:'absolute', right:0 , top: 0, backgroundColor:'#FFF'}} ><i className='fas fa-edit' /></button>
    }

    toggleEdit(rec) {
        this.setState({editModal : rec })
    }

    updateAddress(rec) {
        if (this.state.editAddress && rec.locationGeohashId != 0) {
            var ds = {
                id: rec.locationGeohashId,
                address: this.state.editAddress,
                isSite: this.state.cbSite ? this.state.cbSite : rec.onSite
            }
            ApiPost('VehicleTracking', 'UpdateAddress', ds)
            .then((data) => {
                console.log('data',data)
            })
        }
        this.setState({ editModal: null, editAddress: null, cbSite: null })
    }

    getModal() {
        var rec = this.state.editModal
        return (<Modal isOpen={true} autoFocus={false} >
            <ModalHeader>
                Modify Address
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={2}>Address</Col>
                    <Col sm={9}><input type="text" className="form-control" defaultValue={this.parseAddress(rec.address)} onBlur={(e) => { this.setState({ editAddress: e.target.value }) }} /></Col>
                </Row>
                <Row style={{marginTop:'10px'}}>
                    <Col sm={2}>Is site?</Col>
                    <Col sm={9}><CheckBox checked={this.state.cbSite ? this.state.cbSite : rec.onSite} onChange={(e) => { this.setState({ cbSite: e.checked }) }} /></Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-default' onClick={() => this.setState({ editModal: null, editAddress: null, cbSite: null })}>Cancel</button>
                <button className='btn btn-primary' onClick={() => this.updateAddress(rec)}>Save</button>
            </ModalFooter>
        </Modal>)
    }

    render() {
        return (
            <div className='vanTrackWidget'>
                <p style={{ margin:0 }}><strong>Van Tracking</strong></p>
                {this.props.widgetValue && this.props.widgetValue.map(x => {
                    var status = this.getVanStatus(x)
                    return (
                        <div key={'v' + x.vanId} className='trackRow'>
                            <div style={{ width: '25%', position: 'relative' }}>{status.render}{x.vanName}{this.getFuncBtns(x)}</div><div style={{ width: '65%' }}><span style={{whiteSpace:'nowrap'}}>{x.address && this.parseAddress(x.address)}</span></div><div style={{ width: '10%' }} >{this.state.edits[x.vanId] && this.getFuncBtns(x)}{status.duration && status.duration}</div>
                        </div>
                    )
                })}
                {this.state.editModal && this.getModal()}
            </div>
        )
    }
};

export default VanLocationWidget;
