import React, { Component } from 'react';
import { ApiPost, ApiGet } from '../../../api'
import { Col, Label, Row, Input } from 'reactstrap';

class StockQty extends Component {

    constructor(props) {
        super(props);
        this.state = { whQty: null }
    }

    componentDidMount() {
        this.getStockQty()
    }

    getStockQty() {
        ApiGet('Stock', 'GetStockQty', { id: this.props.rec.data.id })
        .then((d) => {
            this.setState({ whQty: d })
        });
    }

    render() {
        const { whQty } = this.state;

        if (whQty && whQty.length > 0) {
            return (
                <div>
                    <b>Part Stock</b>
                    {whQty.map(x => {
                        return <div className='whStock' key={'wh' + x.wh}><b>{x.wh} Warehouse</b><span>{x.qty}</span></div>
                    })}
                </div>
            )
        }
        else {
            return <div><b>Part Stock</b><div className='whStock' key={'whNostock'}><span>No stock held</span></div></div>
        }
    }
}

export default (StockQty)