import redux from 'redux'
export const SET_RECORD = 'SET_RECORD'

export default function setRecord(model, id) {
    const payload = {id}

    return {
        type: SET_RECORD,
        payload,
        model
    }
}
