import React, {Component } from 'react';
import { ApiPost } from '../../../api';
import FormCustom from '../../../scenes/CustomForm/components/FormCustom'
import Dash from '../../Dashboard/components/DashContainer'
import DeviceTagPicker from '../../../components/DeviceTagPicker'
import { Col, Row } from 'reactstrap'

class SiteDetails extends Component {
  constructor(props) {
      super(props);
      this.state = {
      }

    }

    componentDidMount() {

    }

    render() {

        const preloadedWidgets = [{ i: "334961" + this.props.station.id, x: 0, y: 0, w: 6, h: 3, type: "52", period: "10", metric: null, params: { "theme": "2", "refreshId": this.props.station.id } }]

        const preloadedDashInfo = { dashId: 997, tabId: 0, tabName: 'SiteDash', dashType: 0, fullDash: false, cols: 12, rowHeight: 180, stationId: this.props.station.id }

      return (<div>
        <Dash preloadedWidgets={preloadedWidgets} preloadedDashInfo={preloadedDashInfo} hideEdit={true} hideTitle={true} />
        <Row><Col xs={12} sm={6}><DeviceTagPicker deviceId={this.props.station.id} /></Col></Row>
    </div>)
  }
}

export default SiteDetails