import React, {Component } from 'react';
import { ApiPost, CommApiPost } from '../../../api';
import ComboBox from '../../../components/Controls/ComboBox'
import ProgressBar from '../../../components/Controls/ProgressBar'
import OverlayLoader from '../../../components/OverlayLoader'
import { Col, Row, Button, Input } from 'reactstrap'
import ReactTable from "react-table";

class ScadaLogs extends Component {
  constructor(props) {
      super(props);
      this.state = {
        logId: 1,
        logdata: [{TimeStamp:null,Message:"Select a Log Type and Load"}],
        loading:false,
        progress: 0
      }

  }

  getOptions() {
    var opts = [];
    opts.push({ id: 1, name: "System Log" });
    opts.push({ id: 2, name: "Operational Log" });
    opts.push({ id: 3, name: "Alert Log" });

    /* 
    for (i = 1; i <= 3; i++) {
      opts.push({ id: 2013 + i, name: 2013 + i });
    }
    */
    return opts;
  }

  onLogChange(value) {
    this.setState({ logId: value})
  }

  onLogLoad(value) {
    this.setState({loading:true, progress:0});
    CommApiPost('logs', "", { siteid: this.props.siteid, logid:this.state.logId, mode:"read" })
        .then((data) => {
            setTimeout(() => this.onCheckProgress(), 3000);
        })
  }

  onCheckProgress() {
    CommApiPost('logs', "", { siteid: this.props.siteid, logid:this.state.logId, mode:"result" })
        .then((data) => {
            if (data.Data.Records && data.Data.Records.length > 0) {
              this.setState({ logdata: data.Data.Records, loading: false });
            } else {
              setTimeout(() => this.onCheckProgress(), 5000);
              this.setState({ progress: data.Data.Progress });
            }
        })
  }


  render() {
    const {logdata, loading, progress} = this.state;
    return <div>
      <Col><Row style={{ padding: '10px 0', justifyContent: 'space-between' }}>
        <h3>Logs</h3>
        <div style={{width:300 + 'px'}}>
        <button className="btn" onClick={() => this.onLogLoad()} style={{float:'right', marginTop:8+'px'}}>Load <i className="fal fa-file-download"></i></button>
          <ComboBox value={this.state.logId} onClick={(v) => this.onLogChange(v)} options={this.getOptions()} />
        </div>
      </Row></Col>
      <Row style={{minHeight:'300px'}}>
        <Col>
        {loading?<Row style={{marginTop:"100px"}}>
          <Col className="col-3  mx-auto text-center"><ProgressBar value={progress} /></Col>
          </Row>:
        <ReactTable
          data={logdata}
          columns={[
            {
              Header: "TimeStamp",
              accessor: "TimeStamp",
              type: 'datetime',
              width: 200
            },
            {
              Header: "Message",
              accessor: "Message"
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        }
        </Col>
      </Row>
      <Row>
        <Col>
        
        </Col>
      </Row>
    </div>
  }
}

export default ScadaLogs