import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

class MetricSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false, filterList: null, fText: null
        };
        this.tTitle = null
    }

    toggle() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen, fText: null });
    }

    filterMetric(e) {
        this.setState({ fText: e.target.value.toString().toLowerCase() })
    }


    renderDropdownItem(i, dId) {
        if (this.state.fText) {
            if (i.name.toLowerCase().includes(this.state.fText)) {
                return (
                    <DropdownItem key={i.metricId + '-' + dId} onClick={() => this.props.toggleFavMetric(dId + '_' + i.metricId)}>{i.name}</DropdownItem >
                )
            }
        }
        else {
            return (
                <DropdownItem key={i.metricId + '-' + dId} onClick={() => this.props.toggleFavMetric(dId + '_' + i.metricId)}>{i.name}</DropdownItem >
            )
        }
    }

    getMetricFilterList() {
        var filterList = []
        if (this.props.scadaGroups != undefined) {
            Object.keys(this.props.scadaGroups).map(x => {
                var d = this.props.devices.find(i => i.id == x)
                filterList.push(<DropdownItem key={'d-' + x} header>{d.name}</DropdownItem >)
                filterList.push(this.props.scadaGroups[x].map(i => !i.hideMetric && this.renderDropdownItem(i,x)))
            })
        }
        return filterList
    }

    render() {
        return (
                <Dropdown isOpen={this.state.dropdownOpen} toggle={(e) => this.toggle(e)} style={{ display: 'inline-block', verticalAlign: 'top', ...this.props.style, zIndex:3 }}>
                    <DropdownToggle style={{ display: this.state.dropdownOpen ? 'none' : 'inline-block' }} className="ddToggleSmall"><i className="fa fa-plus btn" /></DropdownToggle >
                    {this.state.dropdownOpen ? <input onKeyUp={(e) => this.filterMetric(e)} onBlur={() => this.getMetricFilterList()} placeholder={'\uf002'} style={{ fontFamily: 'FontAwesome, Arial', width: '200px' }} /> : null}
                    <DropdownMenu className="metricChartDropdown" id={this.state.tId}>
                        {this.props.addSub && <DropdownItem key={9998} onClick={() => this.props.toggleFavMetric(9998)}>Sub-schematic Box</DropdownItem >}
                        {this.props.addGeneric && <DropdownItem key={9999} onClick={() => this.props.toggleFavMetric(9999)}>Outline Box</DropdownItem >}
                        {this.getMetricFilterList()}
                    </DropdownMenu>
                </Dropdown>
            )
    }
}

export default (MetricSelect)
