import React, { Component } from 'react'
import { connect } from 'react-redux'
import MasterDetail from 'MasterDetail'
import DropDatePicker from '../../../components/DropDatePicker';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { ApiPost, ApiGet } from '../../../api'
import moment from 'moment'
import JobScheduler from '../components/JobScheduler'
export const API_PATH = process.env.SERVER_PATH + '/api'

class Jobs extends Component {

    constructor(props) {
        super(props);
        this.handleDate = this.handleDate.bind(this);

        var date = new Date();
        this.state = {
            fromDate: moment(date).add(-3, 'months').startOf('day'),
            toDate: moment(date.getTime()).endOf('day'),
            activeTab: '1', mainActiveTab: '1', events: []
        }
    }

    componentDidMount() {
        this.getScheduleData()
    }

    getScheduleData() {
        console.log('preState', this.state)
        var eventBounds = { date: moment().utc().format('DD/MM/YY') }
        console.log('eventBounds', eventBounds)
        ApiGet('Job', 'GetEvents', eventBounds)
        .then((events) => {
            console.log('events', events)
            this.setState({events})
        });
    }

    handleDate(fromDate, toDate, custom) {
        this.setState({ fromDate, toDate, dateSelectTitle: moment(fromDate).format('DD/MM/YY') + ' to ' + moment(toDate).format('DD/MM/YY') })
    }

    getHeaderControl() {
        var headerControl = []
        headerControl.push(<DropDatePicker key='ddpTas' handleDate={this.handleDate} startDate={this.state.fromDate} endDate={this.state.toDate} />)
        return headerControl
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    getTimeline() {
        return (<div style={{ width: '900px' }}><JobScheduler resources={this.props.header.assets} events={this.state.events} createJob={(j) => this.createJob(j)} /></div>)
    }

    createJob(job) {
        console.log('createJob', job)
        ApiPost('Job', 'AddJob', job)
        .then((data) => {

        });
    }

    editJob() {

    }
                      
    render() {
        const { mainActiveTab } = this.state;

        var jobFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Job Ref', fieldId: 'jobRef' },
            { label: 'Start Time', fieldId: 'startTime', type: 'datetime' },
            { label: 'End Time', fieldId: 'endTime', type: 'datetime' },
            { label: 'Description', fieldId: 'description', type: 'textarea', colSpan: 4 },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' },
        ];

        var jobConfig = {
            model: 'job',
            fields: jobFields,
            crudParams: { fromDate: moment(this.state.fromDate).format('DD-MM-YYYY'), toDate: moment(this.state.toDate).format('DD-MM-YYYY') },
            master: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        console.log('props', this.props)

        return (
            <div>
                {this.props.match.params.id != 'New' ?
                    <div>
                        {!this.props.match.params.id && <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Overview</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Schedule</NavLink>
                            </NavItem>
                        </Nav>}
                        <TabContent key='clientTabs' activeTab={this.state.mainActiveTab}>
                            <TabPane tabId='1'>
                                {this.state.mainActiveTab == '1' && <MasterDetail key='mdTasks' {...jobConfig} headerControl={!this.props.match.params.id && this.getHeaderControl()} />}
                            </TabPane>
                            <TabPane tabId='2'>
                                {this.state.mainActiveTab == '2' && this.getTimeline()}
                            </TabPane>
                        </TabContent>
                    </div>
                    :
                    <MasterDetail key='mdTasks' {...jobConfig} headerControl={!this.props.match.params.id && this.getHeaderControl()} />}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(mapStateToProps)(Jobs)
