import React, { Component } from 'react';
import classnames from 'classnames';
import Collapsible from 'react-collapsible';
import EventRow from './EventRow';
import { Col, Row } from 'reactstrap';


export default class EventsWorkflow extends Component {

    constructor(props) {
        super(props);
    }

    renderCollapsibles() {
        return (
                <div className="accordionCards">

                <Collapsible trigger={<span><b>Recently Completed</b> - 2 Services</span>} open={false} transitionTime={200}>
                    <EventRow Type="Service" Date="08/03/2018" Location="Ballylarne Rd" Action={'View'} Status={[]} />
                    <EventRow Type="Service" Date="24/03/2018" Location="Ballylarne Rd" Action={'View'} Status={[]} />
                </Collapsible>
                <Collapsible trigger={<span><b>Require Followup</b> - 2 Stat Inspections</span>} open={false} transitionTime={200}>
                    <EventRow Type="Stat Inspection" Date="08/03/2018" Location="Ballylarne Rd" Action={['Create Job','View']} Status={[]} />
                    <EventRow Type="Stat Inspection" Date="24/03/2018" Location="Ballylarne Rd" Action={'Create Job'} Status={[]} />
                </Collapsible>
                <Collapsible trigger={<span><b>Outstanding</b> - 3 Services</span>} open={true} transitionTime={200}>
                    <EventRow Type="Service" Date="08/03/2018" Location="Ballylarne Rd" Action={'Change Schedule'} Status={[{ 'type': 'scheduled', 'level': 'green' }, { 'type': 'outstanding', 'level': 'red' }]} />
                    <EventRow Type="Service" Date="24/03/2018" Location="Ballylarne Rd" Action={'Change Schedule'} Status={[{ 'type': 'scheduled', 'level': 'green' }, { 'type': 'outstanding', 'level': 'red' }]} />
                    <EventRow Type="Service" Date="25/03/2018" Location="Ballylarne Rd" Action={'Change Schedule'} Status={[{ 'type': 'scheduled', 'level': 'green' }, { 'type': 'outstanding', 'level': 'red' }]} />
                </Collapsible>

                <Collapsible trigger={<span><b>June</b> - 2 Services, 1 stat inspection</span>} open={true} transitionTime={200}>
                    <EventRow Type="Service" Date="08/03/2018" Location="Ballylarne Rd" Action={'Schedule'} Status={[{ 'type': 'scheduled', 'level': 'blue' },{ 'type': 'outstanding', 'level': 'orange' }]} />
                    <EventRow Type="Service" Date="24/03/2018" Location="Ballylarne Rd" Action={'Create Job'} Status={[{ 'type': 'outstanding', 'level': 'orange' }]} />
                    <EventRow Type="Stat Inspection" Date="25/03/2018" Location="Ballylarne Rd" Action={'Create Job'} Status={[{ 'type': 'outstanding', 'level': 'orange' }]} />
                </Collapsible>

                <Collapsible trigger={<span><b>July</b> - 3 Services, 2 stat inspections</span>} transitionTime={200}>
                    <EventRow Type="Service" Date="08/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                    <EventRow Type="Service" Date="24/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                    <EventRow Type="Service" Date="25/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                    <EventRow Type="Stat Inspection" Date="31/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                    <EventRow Type="Stat Inspection" Date="31/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                </Collapsible>

                <Collapsible trigger={<span><b>August</b> - 3 Services, 2 stat inspections</span>} transitionTime={200}>
                    <EventRow Type="Service" Date="08/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                    <EventRow Type="Service" Date="24/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                    <EventRow Type="Service" Date="25/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                    <EventRow Type="Stat Inspection" Date="31/03/2018" Location="Ballylarne Rd" Action={'Create Job'} />
                    <EventRow Type="Stat Inspection" Date="31/03/2018" Location="Ballylarne Rd"   />
                </Collapsible>

                </div>
            )
    }

    renderDrops() {
        return (
            <div className="d-flex justify-content-end" style={{ marginTop: '10px' }}>
                <select className="form-control" style={{ maxWidth: '20%' }}>
                    <option value="0">Show All Event Status</option>
                    <option value="1">Job Created</option>
                    <option value="2">Job Scheduled</option>
                    <option value="3">Outstanding</option>
                </select>

                <select className="form-control" style={{ maxWidth: '20%' }}>
                    <option value="0">All Event Types</option>
                    <option value="1">Services</option>
                    <option value="2">Stat Inspection</option>
                    <option value="2">Grid Outage</option>
                </select>

            </div>)
    }

    render() {

        return (
            <div>
                {this.renderDrops()}
                {this.renderCollapsibles()}

            </div>

        );
    }
}
