import React, { Component } from 'react';
import IdNameDropdown from '../Controls/IdNameDropdown'
import WorkflowComponent from './WorkflowComponent'
import { CardBody, Card, CardTitle, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import CheckBox from '../Controls/CheckBox'

export default class CasePicker extends WorkflowComponent {

    constructor(props) {
        super(props);
        this.setFaultCase = this.setFaultCase.bind(this)
        this.setDoNotTrack = this.setDoNotTrack.bind(this)
    }

    componentDidMount() {
        var alert = this.props.initData.alert;
        var alertName = "";
        let alertId;

        if (alert) {
            alertName = alert.cntrLogMessage;
            alertId = alert.stationAlertId;
        }
        this.initState(
            { selCase: null, faultCaseName: alertName, alertId, doNotTrack: false }
        )
    }

    setFaultCase(id) {
        this.updateState({ selCase: id });
    }

    setDoNotTrack() {
        this.updateState({ doNotTrack: !this.state.data.doNotTrack });
    }

    render() {

        const { alertData } = this.props.initData
        const { engineers, faultCases, recCase, recomendedCase} = (alertData?alertData:{})
        let faultCaseInput = null
        if (this.state.data.selCase == 0) {
            faultCaseInput = <FormGroup row>
                <Col xs={3} lg={3}><Label ><b>Case Name:</b></Label></Col>
                <Col xs={6} lg={5}>
                    <Input className="input" value={this.state.data.faultCaseName} onChange={(event) => this.updateState({faultCaseName: event.target.value }) } />
                </Col>
            </FormGroup>
        }

        return (
            <div style={{width :'100%'}}>
                <Card>
                    <CardBody style={{ backgroundColor: 'rgb(230, 230, 230)', padding:'5px' }}>
                        <CardTitle>Case Selection</CardTitle>
                        <Form>
                            {!this.state.data.doNotTrack && <div><FormGroup row>
                                <Col xs={4} lg={3}><Label ><b>Recommended Case</b></Label></Col>
                                {recomendedCase && [<Col xs={5} lg={5} key='recCase' style={{paddingLeft:'15px', paddingRight:'15px'}}><Label><b>{recomendedCase.name}</b></Label></Col>, <Col xs={3} lg={1} key='caseBtn'><button className="btn btn-primary" type="button" onClick={() => this.setFaultCase(recomendedCase.id)}>Use</button></Col>]}
                            </FormGroup>
                            <FormGroup row>
                                <Col xs={3} lg={3}><Label ><b>Select Case</b></Label></Col>
                                <Col xs={6} lg={5}>
                                    <IdNameDropdown zeroText='Select Case' options={faultCases} valueField="caseId" displayField="caseRef" value={this.state.data.selCase} onChange={this.setFaultCase} />
                                </Col>
                                <Col xs={1} lg={1}><button className="btn btn-primary" type="button" onClick={() => this.setFaultCase(0)}>New</button></Col>
                            </FormGroup></div>}
                            <FormGroup row>
                                <Col xs={3} lg={3}><Label ><b>Do not track</b></Label></Col>
                                <Col xs={6} lg={5}>
                                    <CheckBox name='dotnotcbx' checked={this.state.data.doNotTrack} onChange={this.setDoNotTrack} />
                                </Col>
                            </FormGroup>
                            {faultCaseInput}
                        </Form>
                    </CardBody>
                </Card>
            </div>
        );
    }
}