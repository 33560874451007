import React, { Component } from 'react';
import classnames from 'classnames';
import { Col, Row, Table, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip'

export default class AssetSchedule extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div>
            <div className="d-flex justify-content-end" style={{ marginTop: '10px' }}>
                <select className="form-control" style={{ maxWidth: '20%' }}>
                    <option value="0">Filter to sites with</option>
                    <option value="1">Job Created</option>
                    <option value="2">Job Scheduled</option>
                    <option value="3">Outstanding</option>
                    <option value="4">Failed Inspection</option>
                    <option value="5">Tasks Outstanding</option>
                </select>
            </div>
            <br/>
            <Table striped responsive bordered className="scheduleTable" >
                <thead><tr><th>SiteId</th><th>Site Name</th><th >Stat Inspection</th><th >Service</th><th>BAI Inspection</th><th >Other</th></tr></thead>
                    <tbody><tr>
                        <td>1001</td><td><b>Ballylarne</b></td>
                        <td>
                            <div>N: 24/02/18 <i className="fa fa-clock-o" data-tip="Job Scheduled 20/02/18" style={{ color: '#27c564' }} ></i> <i className="fa fa-exclamation-circle" data-tip="Stat Inspection Outstanding" style={{ color: '#ffa500' }} ><ReactTooltip/></i></div>
                            <div>L: 24/08/17 <b data-tip="Stat Inspection Failed 20/08/17" style={{ color: '#d0021b' }}>F</b> <Button className="btn btn-primary small">Revisit</Button> </div>
                        </td>
                        <td>
                            <div>N: 24/02/18</div>
                            <div>L: 24/08/17 <b data-tip="Serviced 18/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td>
                            <div>N: 24/02/18</div>
                            <div>L: 24/08/17 <b data-tip="BIA Inspection Passed 19/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>1002</td><td><b>Benraw</b></td>
                        <td>
                            <div>N: 25/05/18 </div>
                            <div>L: 24/11/17 <b data-tip="Stat Inspection Passed 19/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td>
                            <div>N: 24/02/18</div>
                            <div>L: 24/08/17 <b data-tip="Passed 20/08/17 - Tasks Outstanding" style={{ color: '#ffa500' }}>CHECK</b></div>
                        </td>
                        <td>
                            <div>N: 24/02/18</div>
                            <div>L: 24/08/17 <b data-tip="Serviced 20/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>1003</td><td><b>Site A</b></td>
                        <td>
                            <div>N: 24/05/18 </div>
                            <div>L: 24/11/17 <b data-tip="Stat Inspection Passed 19/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td>
                            <div>N: 24/02/18</div>
                            <div>L: 24/08/17 <b data-tip="Serviced 19/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td>
                            <div>N/A</div>
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>1004</td><td><b>Site B</b></td>
                        <td>
                            <div>N: 24/05/18 </div>
                            <div>L: 24/11/17 <b data-tip="Passed 20/08/17 - Tasks Outstanding" style={{ color: '#ffa500' }}>CHECK</b></div>
                        </td>
                        <td>
                            <div>N: 24/02/18</div>
                            <div>L: 24/08/17 <b data-tip="Serviced 19/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td>
                            <div>N/A</div>
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>1005</td><td><b>Site C</b></td>
                        <td>
                            <div>N: 24/05/18 <i data-tip="Event Due 19/05/18" className="fa fa-clock-o" style={{ color: '#5b8cd2' }} /></div>
                            <div>L: 24/11/17 <b data-tip="Stat Inspection Failed 19/08/17" style={{ color: '#d0021b' }}>F</b></div>
                        </td>
                        <td>
                            <div>N: 24/02/18</div>
                            <div>L: 24/08/17 <b data-tip="Serviced 19/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td>
                            <div>N: 24/02/18</div>
                            <div>L: 24/08/17 <b data-tip="BIA Inspection Passed 19/08/17" style={{ color: '#27c564' }}>P</b></div>
                        </td>
                        <td></td>
                    </tr>

                    <tr>
                        <td>1006</td><td><b>Site D</b></td>
                        <td>
                            <div>N: <Button className="btn btn-primary small">Setup</Button></div>
                        </td>
                        <td>
                            <div>N: 24/05/18 </div>
                            <div>L: 24/11/17 <b data-tip="Passed 20/08/17 - Tasks Outstanding" style={{ color: '#ffa500' }}>CHECK</b></div>
                        </td>
                        <td>
                            <div>N/A</div>
                        </td>
                        <td></td>
                    </tr></tbody>
   
                </Table>

            </div>
        );
    }
}
