import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap'
import SimpleModal from '../../../components/SimpleModal'
import classnames from 'classnames'
import { connect } from 'react-redux'
export const API_PATH = process.env.SERVER_PATH + '/api'

class NotificationRules extends Component {

    constructor(props) {
        super(props);
        this.state = { sId: 1, sId2: 1, activeTab: '1', mainActiveTab: '1', helperOpen: false  };
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    tagHelper() {

        var body = (<span>Tags available include: <br />
            [StationId]<br />
            [Date]<br />
            Etc.<br />
        </span>),
        footer = <Button onClick={() => { this.setState({ helperOpen: !this.state.helperOpen })}}>OK</Button>

        return (
        <div style={{ width: '48%' }}>
            <div style={{ float: 'right', cursor: 'pointer' }} onClick={() => this.setState({ helperOpen: !this.state.helperOpen })} >
                <b>Tag Helper</b> <i className='fal fa-info-circle'/>
            </div>
            <SimpleModal header='Tag Helper' body={body} footer={footer} open={this.state.helperOpen} />
        </div>)
    }

    render() {

        const { mainActiveTab } = this.state;
        var lists = {
            rMethods: [{ id: 1, name: 'User' }, { id: 2, name: 'Role' }, { id: 3, name: 'SMS' }, { id: 4, name: 'PushOver' }, { id: 5, name: 'Email' }],
        };

        var nRuleFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Name', fieldId: 'name' },
            { label: 'Client', fieldId: 'clientId', list: listRequest.Clients, readOnly:true },
            { label: 'Company', fieldId: 'companyId', list: listRequest.ClientCompanies },
            { label: 'Site', fieldId: 'stationId', list: listRequest.windsites },
            //{ label: 'Recipients', fieldId: 'recipients'},
            { label: '', fieldId: "navButton", width: 60, display: 'grid', type: 'navButton'},
        ];

        var nRuleConfig = {
            model: 'NotificationRules',
            master: true,
            fields: nRuleFields,
            titleField: 'name',
            preData: { clientId: this.props.user.authClientId },
            gridProps: { defaultPageSize: 25 },
            apiPath: API_PATH
        }

        var nEventFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Event', fieldId: 'rEvent', list: listRequest.NotificationEvents },
            { label: 'Disable', fieldId: 'disable', type:'checkbox', display:'form' },
            { label: 'Email Template', fieldId: 'emailTemplateId', list: listRequest.EmailTemplates },
            { label: '', fieldId: 'tagHelper', type: 'custom', custom: (rec) => this.tagHelper(), display:'form' },
            { label: 'Message', fieldId: 'message', type: 'textarea' },
            { label: 'nId', fieldId: "nId", display: 'none', getId: -1 }
        ];

        var nEventConfig = {
            model: 'notificationEvents',
            fields: nEventFields,
            crudParams: { nId: this.props.match.params.id },
            showFilterRow: false,
            cascade: true,
            addInline: true,
            expandNewRow: true,
            apiPath: API_PATH
        }

        var FieldChildConfig = {
            model: 'notificationEvent',
            path: '/notificationevents',
            fields: nEventFields,
            master: true,
            gridButton: false,
            submitAction: () => this.setState({ sId: Math.random() }),
            hideBread: true,
            apiPath: API_PATH
        }

        var nRecipFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Method', fieldId: 'method', list: lists.rMethods },
            { label: 'Recipient', fieldId: 'recip', display: 'grid' },
            { label: 'User', fieldId: 'user', list: listRequest.operators, display: 'form', displayIf:(rec) => rec.method == 1 },
            { label: 'Role', fieldId: 'roleName', display: 'form', displayIf:(rec) => rec.method == 2 },
            { label: 'Address', fieldId: 'address', display: 'form', displayIf:(rec) => rec.method > 2 },
            { label: 'Send to email', fieldId: 'sendToEmail', type: 'checkbox', display: 'form', displayIf:(rec) => rec.method < 3 },
            { label: 'Send to mobile', fieldId: 'sendToMobile', type: 'checkbox', display: 'form', displayIf:(rec) => rec.method < 3 },
            { label: 'Send to web', fieldId: 'sendToWeb', type: 'checkbox', display: 'form', displayIf: (rec) => rec.method < 3 },
            { label: 'nId', fieldId: "nId", display: 'none', getId: -1 }
        ];

        var nRecipConfig = {
            model: 'notificationRecipients',
            fields: nRecipFields,
            showFilterRow: false,
            cascade: true,
            addInline: true,
            apiPath: API_PATH
        }

        var nRecipChildConfig = {
            model: 'notificationRecipient',
            path: '/notificationrecipients',
            fields: nRecipFields,
            master: true,
            gridButton: false,
            submitAction: () => this.setState({ sId2: Math.random() }),
            hideBread: true,
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Notification Rules</div>
                <MasterDetail key='mdNotif' {...nRuleConfig}>
                    {this.props.match.params.id != 'New' &&
                        <div>
                            <div className='notifInfo'>
                                <b>Notifications Events</b><br />
                                <span>
                                    Recipients can receive messages for a number of system events. Each of these can be assigned their own message. Some event messages can contains <b>tags</b> which
                                are replaced by meta data related to the events.<br />
                                    Click on help to see a list
                                </span>
                            </div>

                        <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ active: mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Events</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Recipients</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent key='clientTabs' activeTab={this.state.mainActiveTab}>
                                <TabPane tabId='1'>
                                {this.state.mainActiveTab == '1' &&
                                    <MasterDetail key={this.state.sId}{...nEventConfig} >
                                        <MasterDetail key='mdNotifDetail' {...FieldChildConfig} />
                                    </MasterDetail>}
                                </TabPane>
                                <TabPane tabId='2'>
                                    {this.state.mainActiveTab == '2' &&
                                        <MasterDetail key={this.state.sId2}{...nRecipConfig} >
                                            <MasterDetail key='mdNotifDetail' {...nRecipChildConfig} />
                                        </MasterDetail>}
                                </TabPane>
                            </TabContent>
                        </div>
                    }
                </MasterDetail>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.common.users
    }
}

export default connect(mapStateToProps)(NotificationRules)

