import { combineReducers } from 'redux';
import common from './reducers/all';
import { crudReducer } from 'redux-crud-store';
import crud from './api/crud/reducer'
import alertbox from './api/alert/reducer'
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  common,
  models:crudReducer,
  crud,
  form: formReducer,
  alertbox 
});

export default rootReducer;
