import React, { Component } from 'react'
import { GetStationsStateC,GetStationsStateIndustry  } from '../../../api'
import { connect } from 'react-redux'
import { Textfit } from 'react-textfit'

class FormulaWidget extends Component {
    
    constructor(props) {
        super(props);
        this.GetWidgetValue = this.GetWidgetValue.bind(this);
        this.getSitesArray = this.getSitesArray.bind(this);
        this.getSitesIndustryArray = this.getSitesIndustryArray.bind(this);
    }

    GetWidgetValue(formula) {
        if (formula) {
            var re = /{.+?}/g;
            var sites = null;
            var industries = null;
            // convert javascript object notation to json
            var formulaJson = formula.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2": ');
            var formulaRep = formulaJson;
            var matches = [];
            while ((matches = re.exec(formulaJson)) != null) {
                var match = matches[0];
                //console.log("match",matches)
                var tag = JSON.parse(match);
                // Match tags with s key. Lookup site 
                if (tag.s != undefined) {
                    if (sites == null) {
                        sites = this.getSitesArray();
                    }
                    if (tag.m != undefined) {
                        var site = sites[tag.s];
                        if (site && site.metrics) {
                            var metricValue = site.metrics[tag.m];
                            if (metricValue != undefined) {
                            formulaRep = formulaRep.replace(match, metricValue);
                            }
                        }
                    }
                } 
                // Match tags with i key. Lookup industry and add specified metric for all sites in Industry
                else if (tag.i != undefined) {
                    if (industries == null) {
                        industries = this.getSitesIndustryArray();
                        
                    }
                    if (tag.m != undefined) {
                        var ind = industries[tag.i];
                        if (ind) {
                            var metricValue = ind.items.map((b) => (b.metrics && b.metrics[tag.m] != undefined?b.metrics[tag.m]:0)).reduce((acc,cur) => (acc+cur));
                            if (metricValue != undefined) {
                                formulaRep = formulaRep.replace(match, metricValue);
                            }
                        }
                    }
                }
            }
            // replace all remaining tags with 0
            formulaRep = formulaRep.replace(/{.+?}/g, "0")
        } else {
            formulaRep = -1;
        }
        return eval(formulaRep)
    }

    getSitesArray() {   
        let ss = GetStationsStateC(this.props.commonstate, true);
        return (!ss)?[]:ss;
    }

    getSitesIndustryArray() {
        let ss = GetStationsStateIndustry(this.props.commonstate);
        return (!ss)?[]:ss;
    }

    render() {
        const { scada, params, selectedParam, icon } = this.props

        var paramValues = {};
        if (selectedParam.params)  {
            paramValues = selectedParam.params;
        }
        //console.log("params.formula", params.formula);
        //console.log("selectedParam", paramValues);
        
        var suffix = "";
        var title = "My Widget";
        var customIcon = icon;
        var backColour = this.props.level;
        var formula = "{i:1,m:1}+{s:16,m:1}";
        if(paramValues.customIcon) {
            customIcon = paramValues.customIcon;
        }
        if (paramValues.backColour != null) {
            backColour = paramValues.backColour;
        }
        if(paramValues.formula) {
            formula = paramValues.formula;
        }
        if (paramValues.customTitle) {
            title = paramValues.customTitle;
        }
        if (paramValues.resultUnits) {
            suffix = paramValues.resultUnits;
        }
        var val = this.GetWidgetValue(formula);
        val = val.toFixed(1);
        return (
            <div className={"summary-widget widget " + backColour}>
                <div className="widget-icon" >
                    <i className={customIcon}></i>
                </div>
                <div className="widget-info">
                    <Textfit>
                        <div >
                            {val}
                            <span>{suffix}</span>
                        </div>
                    </Textfit>
                    <div>
                        <span>{title}</span>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    // Dictionary of stationId { ...asset, siteMetric[umet], alerts[] }
    //const newstations = GetStationsState(state)
    //return {stations: newstations, scada: state.common.scada }
    //state.common.header
    return {scada: state.common.scada, commonstate:state.common }
}

export default connect(
    mapStateToProps
)(FormulaWidget)
