import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class LookupSetup extends Component {

    mdRefCallback = (instance) => {
        this.mdTasks = instance ? instance.getWrappedInstance() : null;
    }

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        var luFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Name', fieldId: 'name' },
            { label: 'Category', fieldId: 'taskCategory', list: this.state.category, display: 'form' },
            { label: '', fieldId:'navButton', width: 60, display: 'grid', type: 'navButton' },
        ];

        var lookupConfig = {
            model: 'lookup',
            fields: luFields,
            master: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        return ([<h1 key='h1Task'>Lookup Setup</h1>, <MasterDetail key='mdLU' {...lookupConfig} />])
    }
}

export default (LookupSetup)
