import { universalMetrics } from './enums'
import MetricWidget from '../scenes/Dashboard/components/MetricWidget'
import TableMetricWidget from '../scenes/Dashboard/components/TableMetricWidget'
import SummaryWidget from '../scenes/Dashboard/components/SummaryWidget'
import CompactWidget from '../scenes/Dashboard/components/CompactSummaryWidget'
import ChartWidget from '../scenes/Dashboard/components/ChartWidget'
import DynamicChartWidget from '../scenes/Dashboard/components/DynamicChartWidget'
import ParentChildChartWidget from '../scenes/Dashboard/components/ParentChildChartWidget'
import DynamicSummaryWidget from '../scenes/Dashboard/components/DynamicSummaryWidget'
import TilesWidget from '../scenes/Dashboard/components/TilesWidget'
import AlertWidget from '../scenes/Dashboard/components/AlertWidget'
import ImageWidget from '../scenes/Dashboard/components/ImageWidget'
import iFrameWidget from '../scenes/Dashboard/components/iFrameWidget'
import FormulaWidget from '../scenes/Dashboard/components/FormulaWidget'
import VanLocationWidget from '../scenes/Dashboard/components/VanLocationWidget'
import DynamicTableWidget from '../scenes/Dashboard/components/DynamicTableWidget'
import HealthWidget from '../scenes/Dashboard/components/HealthWidget'
import HealthAlertWidget from '../scenes/Dashboard/components/HealthAlertWidget'
import LiveSummaryWidget from '../scenes/Dashboard/components/LiveSummaryWidget'
import LiveStatusWidget from '../scenes/Dashboard/components/LiveStatusWidget'
import HTMLWidget from '../scenes/Dashboard/components/HTMLWidget'
import SchematicWidget from '../scenes/Dashboard/components/SchematicWidget'
import util from '../utils/helpers'

export const period = {
    Current: 0,
    LastTenSeconds: 1,
    LastMinute: 2,
    Last10Minutes: 3,
    LastHour: 4,
    CurrentHour: 5,
    Last24hours: 6,
    CurrentDay: 7,
    PrevDay: 8,
    CurrentMonth: 9,
    PrevMonth: 10,
    Last3months: 11,
    Last6months: 14,
    Last12months: 13,
    CurrentYear: 14,
    PrevYear: 15,
    SinceCommisioning: 16,
    Last6hours: 17,
    Last12hours: 18,
    Last2Days: 19,
    Last3Days: 20,
    CurrentWeek: 21,
}

export const widgetCategories = {
    0: { name: 'Performance Summaries', roles:[], clients: [] },
    1: { name: 'Performance Charts', roles: [], clients: []  },
    2: { name: 'Operations', roles: [], clients: []  },
    3: { name: 'General', roles: [], clients: [] },
    4: { name: 'Biogas', roles: [], clients: [] },
    5: { name: 'Prediction', roles: [], clients: [] }
}

export const widgetconfig = {

    1: {
        id: 1,
        title: 'Live Metric',
        description: 'live station metric',
        type: MetricWidget,
        params: {
            metric: {
                type: 'dropdown',
                label: 'Metric',
                items: [
                    { id: universalMetrics.RPM, title: 'RPM' },
                    { id: universalMetrics.WindSpeed, title: 'Wind Speed' },
                    { id: universalMetrics.RocPower, title: 'Roc Power' },
                    { id: universalMetrics.HydraulicTemp, title: 'Hydraulic Temp' },
                    { id: universalMetrics.GearboxTemp, title: 'Gearbox Temp' },
                    { id: universalMetrics.VoltageL1, title: 'Voltage L1' },
                    { id: universalMetrics.VoltageL2, title: 'Voltage L2' },
                    { id: universalMetrics.VoltageL3, title: 'Voltage L3' },
                ]
            }
        },
        category: 'Analysis'
    },
    2: {
        id: 2,
        title: '% of Total Capacity',
        type: SummaryWidget,
        periods: [
            { title: 'current % capacity', id: 1, info: 'live % of output in relation to potential' },
            { title: 'current month capacity', id: period.CurrentMonth, info: 'this months average % of output in relation to potential' },
            { title: '3 month capacity', id: period.Last3months, info: 'average % of output in relation to potential for last 3 months' },
            { title: '12 months capacity', id: period.Last12months, info: 'average % of output in relation to potential for last 12 months' }
        ],
        icon: 'fello-levels',
        subtitle: '% total capacity',
        description: 'current percentage of output in relation to potential',
        suffix: '%',
        minW: 4,
        minH: 1,
        maxW: 6,
        maxH: 2,
        levelRange: { danger: 25, success: 40 },
        iconRange: { 0: 'far fa-tachometer-slowest', 25: 'far fa-tachometer-slow', 50: 'far fa-tachometer-average', 75: 'far fa-tachometer-fast', 100: 'far fa-tachometer-fastest' },
        category: 'Analysis'
    },
    7: {
        id: 7,
        title: 'Average Availability',
        type: SummaryWidget,
        periods: [
            { title: 'current month availability', id: period.CurrentMonth, info: 'average availability for this month' },
            { title: 'previous month availability', id: period.PrevMonth, info: 'average availability for last month' },
            { title: '3 month availability', id: period.Last3months, info: 'average availability for the last 3 months' },
            { title: 'YTD % availability', id: period.CurrentYear, info: 'average availabilty % year to date' },
            { title: '12 month % availability', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'average availabilty % for the last 12 months' }
        ],
        icon: 'far fa-line-chart',
        subtitle: 'month availability',
        description: 'average percentage availability',
        suffix: '%',
        level: 'info',
        decimalPlaces: 1,
        minW: 4,
        minH: 1,
        maxW: 6,
        maxH: 2,
        levelRange: { danger: 80, success: 95 },
        category: 'Analysis'
    },
    9: {
        id: 9,
        title: 'Availability by Site',
        description: 'Bar chart of the % availability for each station',
        type: ChartWidget,
        periods: [
            { title: 'month to date % availability', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'availabilty % month to date' },
            { title: 'last month % availability', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'availabilty % last month' },
            { title: '3 month % availability', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'average availabilty % for the last 3 months' },
            { title: 'YTD % availability', id: period.CurrentYear, info: 'average availabilty % year to date' },
            { title: '12 month % availability', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'average availabilty % for the last 12 months'  }
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    10: {
        id: 10,
        title: 'Power Output Line Chart',
        description: 'power output (Kw) per site. Only first three site enabled by default. You can toggle sites in the legend',
        type: ChartWidget,
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    11: {
        id: 11,
        title: 'Budget Chart',
        description: 'percentage of budget achieved',
        type: ChartWidget,
        periods: [
            { title: 'Current Pro Rata Budget %', id: period.Current, subtitle: util.getMonthName(), info: 'pro rata % of budget acheived'},
            { title: 'Budget % achieved month to date', id: period.CurrentMonth, subtitle: util.getMonthName(), info: '% of budget achieved this month to date' },
            { title: 'Budget % achieved previous month', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: '% of budget achieved for previous month' }
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    17: {
        id: 17,
        title: 'Production Chart',
        description: 'production in MWh bar chart',
        type: ChartWidget,
        periods: [
            //{ title: 'Last 24 Hours MWh Production', id: period.Last24hours, info: 'total production per station in the last 24 hours' },
            { title: 'Current Day MWh Production', id: period.CurrentDay, subtitle: util.getDayName(), info: 'todays total production per station' },
            { title: 'Previous Day MWh Production', id: period.PrevDay, subtitle: util.getDayName(-1), info: 'yesterdays total production per station' },
            { title: 'Current Month MWh Production', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'current month total production per station' },
            { title: 'Previous Month MWh Production', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'previous month total production per station' },
            { title: 'Previous 3 Months MWh Production', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'previous 3 months total production per station' },
            { title: 'Current Year MWh Production', id: period.CurrentYear, info: 'current year total production per station' },
            { title: 'Previous 12 Months MWh Production', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'previous 12 months total production per station' }
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    18: {
        id: 18,
        title: 'Generation Total',
        description: 'total station generation in MWh',
        type: SummaryWidget,
        periods: [
            //{ title: 'last 24 hours generation', id: period.Last24hours, info: 'total generation in the last 24 hours' },
            { title: 'current day generation', id: period.CurrentDay, info: 'total generation today' },
            { title: 'previous day generation', id: period.PrevDay, info: 'total generation yesterday' },
            { title: 'current month generation', id: period.CurrentMonth, info: 'total generation this month' },
            { title: 'previous month generation', id: period.PrevMonth, info: 'total generation last month' },
            { title: 'current year generation', id: period.CurrentYear, info: 'total generation this year' },
            { title: 'previous 12 months generation', id: period.Last12months, info: 'total generation in the last 12 months'  },
        ],
        icon: 'fa fa-bolt',
        subtitle: 'generation total',
        suffix: 'MWh',
        decimalPlaces: 0,
        factor: 0.001,
        level: 'info',
        minW: 4,
        minH: 1,
        maxW: 6,
        maxH: 2,
        category: 'Analysis'
    },
    19: {
        id: 19,
        title: 'Station Status Table',
        description: 'Display station status, windspeed, power in a table',
        type: TableMetricWidget,
        params: {
            displayType: {
                type: 'dropdown',
                label: 'Display',
                items: [
                    { id: 'all', title: 'All' },
                    { id: 'issues', title: 'Only Events' },
                ]
            },
        },
        minW: 3,
        minH: 2,
        maxW: 18,
        maxH: 12,
        category: 'Monitoring'
    },
    20: {
        id: 20,
        title: 'Budget Summary',
        description: 'percentage of budget achieved',
        type: SummaryWidget,
        periods: [
            { title: 'current pro rata budget %', id: period.Current, subtitle: util.getMonthName(), info: 'pro rata % of budget this month' },
            { title: 'budget achieved MTD', id: period.CurrentMonth, subtitle: util.getMonthName(), info: '% of budget achieved this month to date' },
            { title: 'budget achieved last month', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: '% of budget achieved for previous month' }
        ],
        minW: 4,
        minH: 1,
        maxW: 6,
        maxH: 2,
        icon: 'far fa-line-chart',
        suffix: '%',
        decimalPlaces: 1,
        category: 'Analysis'
    },
    21: {
        id: 21,
        title: 'Current Alerts List',
        description: 'list of current alert details',
        type: AlertWidget,
        minW: 3,
        minH: 2,
        maxW: 6,
        maxH: 20,
        suffix: '%',
        decimalPlaces: 1,
        level: 'clear',
        category: 'Operations',
        noShadow: true
    },
    22: {
        id: 22,
        title: 'Price Prediction',
		description: 'Electricity Price Prediction from capacity and time',
		subtitle: 'Price Prediction',
        type: SummaryWidget,
        icon: 'fa fa-bolt',
        minW: 4,
        minH: 1,
        maxW: 6,
        maxH: 2,
        decimalPlaces: 0,
        suffix: 'p/Kwh',
        levelRange: { danger: 3, success: 6 },
        category: 'Analysis'
    },
    23: {
        id: 23,
        title: 'Fuel Mix',
        description: 'Fuel Mix Pie Chart',
        type: ChartWidget,
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis',
        industry: 6
    },
    24: {
        id: 24,
        title: 'Trailer Hours Remaining',
        description: 'Trailer Hours Remaining',
        type: ChartWidget,
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis',
        industry: 6
    },
    25: {
        id: 25,
        title: 'Station Faults',
        description: 'Faults by station model and degree of severity',
        type: ChartWidget,
        minW: 3,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Operations'
    },
    26: {
        id: 26,
        title: 'Top Offenders',
        description: 'Stations that fault most often',
        type: DynamicTableWidget,
        periods: [
            { title: 'Faults order by Downtime', id: period.Current, info: 'Faults order by Downtime' },
            { title: 'Faults order by Freq', id: period.CurrentMonth, info: 'Faults order by Freq' },
        ],
        params: {
            showTrends: {
                type: 'checkbox',
                label: 'Show Trends',
                group: 'trends'
            },
            showFreq: {
                type: 'checkbox',
                label: 'Show Freq %',
                group: 'freq'
            },
        },
        minW: 3,
        minH: 2,
        maxW: 18,
        maxH: 5,
        role: 'Internal',
        category: 'Operations'
    },
    27: {
        id: 27,
        title: 'Health Monitor',
        description: 'Stations Health Monitor',
        type: DynamicTableWidget,
        minW: 3,
        minH: 2,
        maxW: 18,
        maxH: 12,
        category: 'Operations',
        role: 'Administrator',
    },
    28: {
        id: 28,
        title: 'Logo Display',
        description: '',
        type: ImageWidget,
        minW: 4,
        minH: 1,
        maxW: 12,
        maxH: 12,
        level: 'clear',
        category: 'Analysis'
        //params: {
        //    imageURL: {
        //        type: 'textarea',
        //        label: 'Enter Image',
        //        placeHolder: 'Enter Image Url',
        //        rows: 5
        //    }
        //}
    },
    29: {
        id: 29,
        title: 'Formula Widget',
        description: 'Advanced. Enter a formula which may combine and manipulate metrics for any current sites',
        type: FormulaWidget,
        minW: 4,
        minH: 1,
        maxW: 18,
        maxH: 12,
        level: 'danger',
        icon:'fa fa-plug',
        params: {
            customTitle: {
                type: 'textbox',
                label: 'Enter Title',
                placeHolder: 'My Widget'
            },
            resultUnits: {
                type: 'textbox',
                label: 'Enter Units',
                placeHolder: 'kWh'
            },
            customIcon: {
                type: 'dropdown',
                label: 'Icon',
                items: [
                    { id: 'fa fa-bolt', title: 'Elec Bolt' },
                    { id: 'fa fa-exclamation-triangle', title: 'Alert Triangle' },
                    { id: 'fa fa-bell', title: 'Bell' },
                    { id: 'fa fa-plug', title: 'Plug' },
                    { id: 'fa fa-recycle', title: 'Recycle' },
                    { id: 'fa fa-power-off', title: 'Power Off' },
                    { id: 'fa fa-line-chart', title: 'Line Chart' },
                    { id: 'fa fa-bar-chart', title: 'Bar Chart' },
                    { id: 'fa fa-cogs', title: 'Gears' },
                    { id: 'fa fa-check-square-o', title: 'Checkbox' },
                    { id: 'fa fa-battery-quarter', title: 'Battery level' },
                    { id: 'fa fa-thermometer-quarter', title: 'Thermometer' },
                    { id: 'fa fa-tachometer', title: 'Tachometer' },
                    { id: 'fa fa-tags', title: 'Tags' },
                    { id: 'fa fa-user', title: 'User' },
                    { id: 'fa fa-euro', title: 'Euro' },
                    { id: 'fa fa-usd', title: 'Dollar' },
                    { id: 'fa fa-gbp', title: 'Pound' },
                    { id: 'fa fa-money', title: 'Money' },
                ]
            },
            backColour: {
                type: 'dropdown',
                label: 'Background Colour',
                items: [
                    { id: 'clear', title: 'None' },
                    { id: '', title: 'Grey' },
                    { id: 'warning', title: 'Orange' },
                    { id: 'danger', title: 'Red' },
                    { id: 'info', title: 'Blue' },
                ]
            },
            formula: {
                type: 'textarea',
                label: 'Enter formula',
                placeHolder: 'Enter javascript notation formula',
                rows: 5
            }
        },
        category: 'Analysis'
    },
    30: {
        id: 30,
        title: 'Webpage Display',
        description: '',
        type: iFrameWidget,
        minW: 4,
        minH: 1,
        maxW: 36,
        maxH: 36,
        level: 'clear',
        params: {
            iFrameURL: {
                type: 'textbox',
                label: 'Enter page URL',
                placeHolder: 'Enter page URL'
            },
            topOffset: {
                type: 'textbox',
                label: 'Top offset',
                placeHolder: 'Enter offset in pxs'
            },
            sideOffset: {
                type: 'textbox',
                label: 'Side offset',
                placeHolder: 'Enter offset in pxs'
			},
			refresh: {
				type: 'textbox',
				label: 'Refresh interval',
				placeHolder: 'Enter interval in mins'
			}
        },
        category: 'Administrator'
    },
    31: {
        id: 31,
        title: 'New Cases',
        type: CompactWidget,
        periods: [
            { title: 'opened today', id: period.CurrentDay, info: 'number of cases opened today' },
            { title: 'opened this month', id: period.CurrentMonth, info: 'number of cases opened this month' },
            { title: 'opened last 3 months', id: period.Last3months, info: 'number of cases opened in the last 3 months' }
        ],
        subtitle: 'cases opened',
        description: 'number of cases opened within a set time',
        minW: 2,
        minH: 1,
        maxW: 2,
        maxH: 2,
        category: 'Operations'
    },
    32: {
        id: 32,
        title: 'Closed Cases',
        type: CompactWidget,
        periods: [
            { title: 'closed today', id: period.CurrentDay, info: 'number of cases closed today' },
            { title: 'closed this month', id: period.CurrentMonth, info: 'number of cases closed this month' },
            { title: 'closed last month', id: period.PrevMonth, info: 'number of cases closed last month' },
            { title: 'closed last 3 months', id: period.Last3months, info: 'number of cases closed in the last 3 months' }
        ],
        subtitle: 'cases closed',
        description: 'number of cases closed within a set time',
        minW: 2,
        minH: 1,
        maxW: 2,
        maxH: 2,
        category: 'Operations'
    },
    33: {
        id: 33,
        title: 'Case Management Chart',
        description: 'cases opened and closed per period',
        type: ChartWidget,
        periods: [
            { title: 'Opened/Closed this month', id: period.CurrentMonth, info: '% of budget achieved this month to date' },
            { title: 'Opened/Closed last 3 months', id: period.Last3months, info: '% of budget achieved for previous month' }
        ],
        minW: 6,
        minH: 1,
        maxW: 18,
        maxH: 5,
        category: 'Operations'
    },
    34: {
        id: 34,
        title: 'Engineers Onsite',
        description: 'sites where an engineer is present',
        type: TilesWidget,
        minW: 6,
        minH: 1,
        maxW: 18,
        maxH: 20,
        category: 'Operations',
        noShadow: true
    },
    35: {
        id: 35,
        title: 'Van Locations',
        description: 'Current Van Locations',
        type: VanLocationWidget,
        minW: 3,
        minH: 1,
        maxW: 12,
        maxH: 12,
        category: 'Operations'
    },
    36: {
        id: 36,
        title: 'Warning Widget',
        description: 'Health warnings',
        type: HealthWidget,
        minW: 3,
        minH: 1,
        maxW: 18,
        maxH: 12,
        params: {
            warnSource: {
                type: 'dropdown',
                label: 'Source',
                items: [
                    { id: 1, title: 'PC vs Spec' },
                    { id: 2, title: 'PC Deg' },
                    { id: 3, title: 'PC MvC' },
                    { id: 430, title: 'Gen Temp' },
                    { id: 432, title: 'Gen Bearing Temp' },
                    { id: 434, title: 'Gearbox Temp' },
                    { id: 436, title: 'Gearbox Bearing Temp' },
                    { id: 440, title: 'Hydraulic Temp' },
                    { id: 5, title: 'HHD MEC' },
                    { id: 6, title: 'Vibration Sensor' },
                ]
            }
        },
        category: 'Operations'
    },
    37: {
        id: 37,
        title: 'Alert Widget',
        description: 'Threshold alert error',
        type: HealthAlertWidget,
        minW: 3,
        minH: 1,
        maxW: 12,
        maxH: 12,
        params: {
            audible: {
                type: 'checkbox',
                label: 'Audible Alert'
            }
        },
        category: 'Operations'
    },
    38: {
        id: 38,
        title: 'Monthly Production Comparison Chart',
        description: 'comparison',
        type: ChartWidget,
        periods: [
            { title: 'Production ' + util.getYear() + ' YTD vs ' +  util.getYear(-1) + ' YTD (MWh)', id: period.CurrentYear, info: 'YTD vs Prev TYD' },
            { title: 'Production ' + util.getYear(-1) + ' vs ' + util.getYear(-2) + ' (MWh)', id: period.PrevYear, info: 'Last Year vs 2 Years Ago' },
            { title: 'Production last 12 months vs 12 - 24 months vs 24 - 36 (MWh)', id: period.Last12months, info: '12/24/36 month comparison' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        role: 'Administrator',
        category: 'Administrator'
    },
    39: {
        id: 39,
        title: 'Daily Chart',
        description: 'comparison',
        type: ChartWidget,
        periods: [
            { title: 'Current Month Daily Production', id: period.CurrentMonth, info: 'Current Month Daily Production' },
            { title: 'Previous Month Daily Production', id: period.PrevMonth, info: 'Previous Month Daily Production' },
            { title: 'Last 3 Months Daily Production', id: period.Last3months, info: 'Last 3 Months Daily Production' },
            { title: 'Last 12 Months Daily Production', id: period.Last12months, info: 'Last 12 Months Daily Production' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        role: 'Administrator',
        category: 'Administrator'
    },
    40: {
        id: 40,
        title: 'Monthly Availability Comparison Chart',
        description: 'comparison',
        type: ChartWidget,
        periods: [
            { title: 'Availability ' + util.getYear() + ' YTD vs ' + util.getYear(-1) + ' YTD (%)', id: period.CurrentYear, info: 'YTD vs Prev TYD' },
            { title: 'Availability ' + util.getYear(-1) + ' vs ' + util.getYear(-2) + ' (%)', id: period.PrevYear, info: 'Last Year vs 2 Years Ago' },
            { title: 'Availability last 12 months vs 12 - 24 months vs 24 - 36 (%)', id: period.Last12months, info: '12/24/36 month comparison' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        role: 'Administrator',
        category: 'Administrator'
    },
    41: {
        id: 17,
        title: 'Wind Energy Prediction NI',
        description: 'production in MWh bar chart',
        type: ChartWidget,
        periods: [
            //{ title: 'Last 24 Hours MWh Production', id: period.Last24hours, info: 'total production per station in the last 24 hours' },
            { title: '6 Hour Prediction', id: period.Last6hours, subtitle: "", info: 'Prediction at 6 hours before vs Actual' },
            { title: '12 Hour Prediction', id: period.Last12hours, subtitle: "", info: 'Prediction at 12 hours before vs Actual' },
            { title: '24 Hours Prediction', id: period.Last24hours, subtitle: "", info: 'Prediction at 1 day before vs Actual' },
            { title: '48 Hours Prediction', id: period.Last2Days, subtitle: "", info: 'Prediction at 2 days before vs Actual' },
            { title: '72 Hours Prediction', id: period.Last3Days, subtitle: "", info: 'Prediction at 3 days before vs Actual' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    41: {
        id: 41,
        title: 'NI Wind Energy Prediction',
        description: 'Wind Energy Prediction NI region in MWh',
        type: ChartWidget,
        periods: [
            //{ title: 'Last 24 Hours MWh Production', id: period.Last24hours, info: 'total production per station in the last 24 hours' },
            { title: '6 Hour Energy Prediction vs Actual', id: period.Last6hours, subtitle: "", info: 'Prediction at 6 hours before vs Actual' },
            { title: '12 Hour Energy Prediction  vs Actual', id: period.Last12hours, subtitle: "", info: 'Prediction at 12 hours before vs Actual' },
            { title: '24 Hours Energy Prediction vs Actual', id: period.Last24hours, subtitle: "", info: 'Prediction at 1 day before vs Actual' },
            { title: '48 Hours Energy Prediction vs Actual', id: period.Last2Days, subtitle: "", info: 'Prediction at 2 days before vs Actual' },
            { title: '72 Hours Energy Prediction vs Actual', id: period.Last3Days, subtitle: "", info: 'Prediction at 3 days before vs Actual' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    42: {
        id: 42,
        title: 'NI Load Prediction',
        description: 'Load prediction for NI region',
        type: ChartWidget,
        periods: [
            //{ title: 'Last 24 Hours MWh Production', id: period.Last24hours, info: 'total production per station in the last 24 hours' },
            { title: '6 Hour Load Prediction vs Actual', id: period.Last6hours, subtitle: "", info: 'Prediction at 6 hours before vs Actual' },
            { title: '12 Hour Load Prediction vs Actual', id: period.Last12hours, subtitle: "", info: 'Prediction at 12 hours before vs Actual' },
            { title: '24 Hours Load Prediction vs Actual', id: period.Last24hours, subtitle: "", info: 'Prediction at 1 day before vs Actual' },
            { title: '48 Hours Load Prediction vs Actual', id: period.Last2Days, subtitle: "", info: 'Prediction at 2 days before vs Actual' },
            { title: '72 Hours Load Prediction vs Actual', id: period.Last3Days, subtitle: "", info: 'Prediction at 3 days before vs Actual' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    43: {
        id: 43,
        title: 'NI Wind Energy Prediction',
        description: 'Wind Energy Prediction NI region in MWh',
        type: ChartWidget,
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    44: {
        id: 44,
        title: 'NI Load Prediction',
        description: 'Load prediction for NI region',
        type: ChartWidget,
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Analysis'
    },
    45: {
        id: 45,
        title: 'Alerts per day',
        description: 'alerts per day chart',
        type: ChartWidget,
        periods: [
            { title: 'Current Month Alerts', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'current month alerts' },
            { title: 'Previous Month Alerts', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'previous month alerts' },
            { title: 'Previous 3 Months Alerts', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'previous 3 months alerts' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'GuardVessels'
    },
    46: {
        id: 46,
        title: 'Interventions per day',
        description: 'interventions per day chart',
        type: ChartWidget,
        periods: [
            { title: 'Current Month Interventions', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'current month interventions' },
            { title: 'Previous Month Interventions', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'previous month interventions' },
            { title: 'Previous 3 Months Interventions', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'previous 3 months interventions' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'GuardVessels'
    },
    47: {
        id: 47,
        title: 'Alert type summary',
        description: 'alert type pie chart',
        type: ChartWidget,
        periods: [
            { title: 'Current Month Alerts', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'current month alerts' },
            { title: 'Previous Month Alerts', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'previous month alerts' },
            { title: 'Previous 3 Months Alerts', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'previous 3 months alerts' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'GuardVessels'
    },
    48: {
        id: 48,
        title: 'Interventions summary',
        description: 'interventions summary pie chart',
        type: ChartWidget,
        periods: [
            { title: 'Current Month Interventions', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'current month interventions' },
            { title: 'Previous Month Interventions', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'previous month interventions' },
            { title: 'Previous 3 Months Interventions', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'previous 3 months interventions' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'GuardVessels'
    },
    49: {
        id: 49,
        title: 'Dynamic Metric Chart',
        description: 'Customisable metric chart',
        type: DynamicChartWidget,
        periods: [
            { title: 'Current Month', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'current month' },
            { title: 'Previous Month', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'previous month' },
            { title: 'Previous 3 Months', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'previous 3 months' },
            { title: 'Current Year', id: period.CurrentYear, info: 'current year' },
            { title: 'Previous 12 Months', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'previous 12 months' }
        ],
        minW: 4,
        minH: 1,
        maxW: 18,
        maxH: 12,
        fetchParams: true,
        params: {
            customTitle: {
                type: 'textbox',
                label: 'Display Title',
                placeHolder: 'My Widget'
            },
            grouping: {
                type: 'dropdown',
                label: 'X-axis',
                items: [
                    { id: 'device', title: 'Device' },
                    { id: 'day', title: 'Time Period' },
                    { id: 'tag', title: 'Tag' },
                ]
            },
            showLegend: {
                type: 'checkbox',
                label: 'Show Legend'
            },
            stacking: {
                type: 'checkbox',
                label: 'Stacking'
            },
            parentsOnly: {
                type: 'checkbox',
                label: 'Group Devices'
            },
            devices: {
                type: 'hierarchyTree',
                label: 'Devices'
            },
            filter: {
                type: 'tags',
                label: 'Tags'
            },
            valueToChart: {
                type: 'metricPicker',
                label: 'Value To Chart',
            },
            theme: {
                type: 'swatchPicker',
                label: 'Theme'
            },
        },
        category: 'Monitoring'
    },
    50: {
        id: 50,
        title: 'Dynamic Metric Summary',
        description: 'Customisable metric summary',
        type: DynamicSummaryWidget,
        periods: [
            { title: 'Current Month', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'current month' },
            { title: 'Previous Month', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'previous month' },
            { title: 'Previous 3 Months', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'previous 3 months' },
            { title: 'Current Year', id: period.CurrentYear, info: 'current year' },
            { title: 'Previous 12 Months', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'previous 12 months' }
        ],
        minW: 4,
        minH: 1,
        maxW: 18,
        maxH: 12,
        fetchParams: true,
        params: {
            customTitle: {
                type: 'textbox',
                label: 'Display Title',
                placeHolder: 'My Widget'
            },
            customIcon: {
                type: 'dropdown',
                label: 'Icon',
                items: [
                    { id: 'fa fa-bolt', title: 'Elec Bolt' },
                    { id: 'fa fa-exclamation-triangle', title: 'Alert Triangle' },
                    { id: 'fa fa-bell', title: 'Bell' },
                    { id: 'fa fa-plug', title: 'Plug' },
                    { id: 'fa fa-recycle', title: 'Recycle' },
                    { id: 'fa fa-power-off', title: 'Power Off' },
                    { id: 'fa fa-line-chart', title: 'Line Chart' },
                    { id: 'fa fa-bar-chart', title: 'Bar Chart' },
                    { id: 'fa fa-cogs', title: 'Gears' },
                    { id: 'fa fa-check-square-o', title: 'Checkbox' },
                    { id: 'fa fa-battery-quarter', title: 'Battery level' },
                    { id: 'fa fa-thermometer-quarter', title: 'Thermometer' },
                    { id: 'fa fa-tachometer', title: 'Tachometer' },
                    { id: 'fa fa-tags', title: 'Tags' },
                    { id: 'fa fa-user', title: 'User' },
                    { id: 'fa fa-euro', title: 'Euro' },
                    { id: 'fa fa-usd', title: 'Dollar' },
                    { id: 'fa fa-gbp', title: 'Pound' },
                    { id: 'fa fa-money', title: 'Money' },
                    { id: 'fas fa-charging-station', title: 'Charging Station' },
                    { id: 'fas fa-wind-turbine', title: 'Wind Turbine' },
                    { id: 'fas fa-car-battery', title: 'Car Battery' },
                    { id: 'fas fa-battery-bolt', title: 'Charge Battery' },
                    { id: 'fas fa-broadcast-tower', title: 'Broadcast Tower' },
                    { id: 'fello-co2-cloud', title: 'CO2 Cloud' }
                ]
            },
            devices: {
                type: 'hierarchyTree',
                label: 'Devices'
            },
            filter: {
                type: 'tags',
                label: 'Filter'
            },
            valueToChart: {
                type: 'dropdown',
                label: 'Summary metric'
            },
            multiplier: {
                type: 'textbox',
                label: 'Value multiplier'
            },
            altUnit: {
                type: 'textbox',
                label: 'Alternative Unit'
            },
            parentsOnly: {
                type: 'checkbox',
                label: 'Group Devices'
            },
            theme: {
                type: 'swatchPicker',
                label: 'Theme',
                shade: true
            },
        },
        category: 'Monitoring'
    },
    51: {
        id: 51,
        title: 'Dynamic Heatmap',
        description: 'Heatmap of selected metric',
        type: ChartWidget,
        periods: [
            { title:'Current Year Metric Heatmap', subtitle: 'Current Year', id: period.CurrentYear, info: 'current year' },
            { title: 'Previous 12 Months Metric Heatmap', subtitle: 'Previous 12 Months', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'previous 12 months' },
            { title: 'Previous Year Metric Heatmap', subtitle: 'Previous Year ' + util.getYear(-1), id: period.PrevYear, info: 'Last Year' },
        ],
        fetchParams: true,
        params: {
            valueToChart: {
                type: 'dropdown',
                label: 'Summary metric'
            },
            maxVal: {
                type: 'textbox',
                label: 'Max Val'
            },
            minVal: {
                type: 'textbox',
                label: 'Min Val'
            },
            theme: {
                type: 'swatchPicker',
                label: 'Theme',
                shade: true
            },
        },
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Monitoring'
    },
    52: {
        id: 52,
        title: 'Utilization Heatmap',
        description: 'Utilization Heatmap',
        type: ChartWidget,
        periods: [
            { title: 'Current Month Utilization Heatmap', subtitle: 'Current Month', id: period.CurrentMonth, info: 'current month' },
            { title: 'Previous Month Utilization Heatmap', subtitle: 'Previous Month', id: period.PrevMonth, info: 'last months' },
        ],
        params: {
            theme: {
                type: 'swatchPicker',
                label: 'Theme',
                shade: true
            },
        },
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Monitoring'
    },
    53: {
        id: 53,
        title: 'Capacity Heatmap',
        description: 'Heatmap of capacity',
        type: ChartWidget,
        periods: [
            { title: 'Current Year Capacity Heatmap', subtitle: 'Current Year', id: period.CurrentYear, info: 'current year' },
            { title: 'Previous 12 Months Capacity Heatmap', subtitle: 'Previous 12 Months', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'previous 12 months' },
            { title: 'Previous Year Capacity Heatmap', subtitle: 'Previous Year ' + util.getYear(-1), id: period.PrevYear, info: 'Last Year' },
        ],
        params: {
            maxVal: {
                type: 'textbox',
                label: 'Max Val'
            },
            minVal: {
                type: 'textbox',
                label: 'Min Val'
            },
            theme: {
                type: 'swatchPicker',
                label: 'Theme',
                shade: true
            },
        },
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Monitoring'
    },
    54: {
        id: 54,
        title: 'Energy Usage Forecast',
        description: 'Extrapolated import forecast',
        type: ChartWidget,
        periods: [
            { title: 'Import usage annual forecast (kWh)', id: period.Last24hours, subtitle: "Based on usage from previous month", info: 'Previous month extrapolated' },
            //{ title: '2 days ago extrapolated', id: period.Last2Days, subtitle: "", info: '2 days ago extrapolated' },
            //{ title: '3 days ago extrapolated', id: period.Last3Days, subtitle: "", info: '3 days ago extrapolated' },
        ],
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Monitoring'
    },
    55: {
        id: 55,
        title: 'Budget Heatmap',
        description: 'Heatmap of budget',
        type: ChartWidget,
        periods: [
            { title: 'Current Year Budget Heatmap', subtitle: 'Current Year', id: period.CurrentYear, info: 'current year' },
            { title: 'Previous 12 Months Budget Heatmap', subtitle: 'Previous 12 Months', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'previous 12 months' },
            { title: 'Previous Year Budget Heatmap', subtitle: 'Previous Year ' + util.getYear(-1), id: period.PrevYear, info: 'Last Year' },
        ],
        params: {
            maxVal: {
                type: 'textbox',
                label: 'Max Val'
            },
            minVal: {
                type: 'textbox',
                label: 'Min Val'
            },
            theme: {
                type: 'swatchPicker',
                label: 'Theme',
                shade: true
            },
        },
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Monitoring'
    },
    56: {
        id: 56,
        title: 'Dynamic Live Metric',
        description: 'Customisable live metric',
        type: LiveSummaryWidget,
        liveData: true,
        minW: 1,
        minH: 1,
        maxW: 12,
        maxH: 12,
        fetchParams: true,
        params: {
            devices: {
                type: 'dropdown',
                label: 'Device'
            },
            deviceMetrics: {
                type: 'pairedDropdown',
                label: 'Status metric',
                pairedWith: 'devices'
            },
        },
        category: 'Monitoring'
    },
    57: {
        id: 57,
        title: 'HTML Widget',
        description: 'Customisable HTML widget',
        type: HTMLWidget,
        minW: 1,
        minH: 1,
        maxW: 12,
        maxH: 12,
        params: {
            inputHTML: {
                type: 'textarea',
                label: 'HTML'
            },
        },
        category: 'Monitoring',
        noShadow: true
    },
    58: {
        id: 58,
        title: 'Live Status Metric',
        description: 'Customisable status widget',
        type: LiveStatusWidget,
        liveData: true,
        minW: 1,
        minH: 1,
        maxW: 12,
        maxH: 12,
        fetchParams: true,
        params: {
            devices: {
                type: 'dropdown',
                label: 'Device'
            },
            valueToChart: {
                type: 'dropdown',
                label: 'Status metric',
            },
            okStatusOperator: {
                type: 'dropdown',
                label: 'OK status operator',
                items: [
                    { id: '>', title: '>' },
                    { id: '<', title: '<' },
                    { id: '=', title: '=' },
                ]
            },
            okStatusValue: {
                type: 'textbox',
                label: 'OK status value'
            }
        },
        category: 'Monitoring'
    },
    59: {
        id: 59,
        title: 'Schematic Widget',
        description: 'Customisable schematic widget',
        type: SchematicWidget,
        liveData: true,
        minW: 6,
        minH: 3,
        maxW: 24,
        maxH: 24,
        category: 'Monitoring'
    },
    60: {
        id: 60,
        title: 'Number of Imports',
        subtitle: 'number of imports',
        description: 'current number of imports',
        type: CompactWidget,
        periods: [
            { title: 'Current Queued Imports', id: period.Current, info: 'current queued imports' },
            { title: 'This Week Imports', id: period.CurrentWeek, info: 'this week imports' },
            { title: 'This Month Imports', id: period.CurrentMonth, info: 'this month imports' },
            { title: 'Last Month Imports', id: period.PrevMonth, info: 'last month imports' },
            { title: 'Previous 3 Months Imports', id: period.Last3months, info: 'previous 3 months imports' },
        ],
        minW: 2,
        minH: 1,
        maxW: 2,
        maxH: 2,
        category: 'Monitoring'
    },
    61: {
        id: 61,
        title: 'Import Types Used Chart',
        description: 'Import type used per period',
        type: ChartWidget,
        periods: [
            { title: 'Import Types used this month', id: period.CurrentMonth, info: 'import types used this month' },
            { title: 'Import Types used for last month', id: period.PrevMonth, info: 'import types used for last month' },
            { title: 'Import Types used for previous 3 month', id: period.Last3months, info: 'import types used for previous 3 month' }
        ],
        minW: 6,
        minH: 1,
        maxW: 18,
        maxH: 5,
        category: 'Monitoring'
    },
    62: {
        id: 62,
        title: 'Device HHD MIC',
        description: 'Daily overview of HHD MIC',
        type: ChartWidget,
        periods: [
            { title: 'MIC Breach Heatmap', subtitle: 'Last 24 Hours', id: period.Last24hours, info: 'Last 24 Hours' },
        ],
        params: {
            maxVal: {
                type: 'textbox',
                label: 'Max Val'
            },
            minVal: {
                type: 'textbox',
                label: 'Min Val'
            },
            theme: {
                type: 'swatchPicker',
                label: 'Theme',
                shade: true
            },
        },
        minW: 6,
        minH: 2,
        maxW: 18,
        maxH: 5,
        category: 'Monitoring'
    },
    63: {
        id: 63,
        title: 'Child Device vs Parent Device Chart',
        description: 'Customisable chart to compare Child Devices against their parents',
        type: ParentChildChartWidget,
        periods: [
            //{ title: 'Current Month', id: period.CurrentMonth, subtitle: util.getMonthName(), info: 'current month' },
            { title: 'Previous Month', id: period.PrevMonth, subtitle: util.getMonthName(-1), info: 'previous month' },
            { title: 'Previous 3 Months', id: period.Last3months, subtitle: 'Since ' + util.getMonthName(-3), info: 'previous 3 months' },
            { title: 'Current Year', id: period.CurrentYear, info: 'current year' },
            { title: 'Previous 12 Months', id: period.Last12months, subtitle: 'Since ' + util.getMonthName(-12), info: 'previous 12 months' }
        ],
        minW: 4,
        minH: 1,
        maxW: 18,
        maxH: 12,
        fetchParams: true,
        params: {
            customTitle: {
                type: 'textbox',
                label: 'Display Title',
                placeHolder: 'My Widget'
            },
            //stacking: {
            //    type: 'checkbox',
            //    label: 'Stacking'
            //},
            filter: {
                type: 'tags',
                label: 'Parent Tags'
            },
            valueToChart_Parent: {
                type: 'dropdown',
                label: 'Value To Compare (Parent)',
            },
            deviceVariable_Parent: {
                type: 'dropdown',
                label: 'Variable To Compare (Parent)',
            },
            valueToChart_Child: {
                type: 'dropdown',
                label: 'Value To Compare (Child)',
            },
            theme: {
                type: 'swatchPicker',
                label: 'Theme'
            },
        },
        category: 'Monitoring'
    },
}

export const metricInfo = {
    1: {
        3: { id: universalMetrics.RPM, icon: 'fello-gauge', subtitle: 'RPM', level: 'info', decimalPlaces: 0, factor: 1, avg: true, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current average rotor RPM across sites in the set filter' },
        2: { id: universalMetrics.WindSpeed, icon: 'fello-wind-sign', subtitle: 'current wind speed', suffix: 'm/s', level: 'success', decimalPlaces: 1, factor: 1, avg: true, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current average wind speed across sites in the set filter' },
        11: { id: universalMetrics.RocPower, icon: 'fa fa-bolt', subtitle: 'total power', level: 'success', suffix: 'Kw', decimalPlaces: 0, factor: 1, avg: false, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current total power output across sites in the set filter' },
        40: { id: universalMetrics.HydraulicTemp, icon: 'fa fa-thermometer-half', subtitle: 'hydraulic temp', suffix: '\u00B0C', decimalPlaces: 0, factor: 1, avg: true, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current average hydraulic temperature across sites in the set filter' },
        34: { id: universalMetrics.GearboxTemp, icon: 'fa fa-cogs', subtitle: 'gearbox temp', suffix: '\u00B0C', level: 'success', decimalPlaces: 0, factor: 1, avg: true, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current average gearbox temperature across sites in the set filter' },
        14: { id: universalMetrics.VoltageL1, icon: 'fa fa-bolt', subtitle: 'Voltage L1', suffix: 'V', decimalPlaces: 0, factor: 1, avg: true, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current average L1 voltage across sites in the set filter' },
        15: { id: universalMetrics.VoltageL2, icon: 'fa fa-bolt', subtitle: 'Voltage L2', suffix: 'V', decimalPlaces: 0, factor: 1, avg: true, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current average L2 voltage across sites in the set filter' },
        16: { id: universalMetrics.VoltageL3, icon: 'fa fa-bolt', subtitle: 'Voltage L3', suffix: 'V', decimalPlaces: 0, factor: 1, avg: true, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current average L3 voltage across sites in the set filter' },
        20: { id: universalMetrics.PowerFactor, icon: 'fa fa-plug', subtitle: 'avg power factor', decimalPlaces: 2, factor: 1, avg: true, minW: 3, minH: 1, maxW: 6, maxH: 2, description: 'current power factor' }
    }
}