import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums';
import { CrudGet } from '../../../api';
import PropTypes from 'prop-types';
import Password from '../../../scenes/ClientSetup/components/Password';
import Collapsible from 'react-collapsible';
const SERVER_PATH = process.env.SERVER_PATH;

class DeviceSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {devices: []};
    }   

    componentDidMount() {
        this.OnLoad()
    }

    OnLoad() {

        CrudGet("RTUSettings", "GetDevices")
            .then((data) => {
                this.setState({ devices: data });
            });       
    }

    render() {

        var EsyncDeviceFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Device", fieldId: "device", list: this.state.devices },
            { label: "", fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton'}
        ];

        var EsyncDevicesConfig = {
            model: 'RTUSettings',
            fields: EsyncDeviceFields, 
            titleField: 'device',
            master: true, 
        }        

        return (
            <div>               
                <MasterDetail key='mdwc' {...EsyncDevicesConfig} />
            </div>
        );
    }
}

export default (DeviceSettings)