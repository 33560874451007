export const defaultConfig = [
  {
    "name": "Logic",
    "colour": "#5C81A6",
    "blocks": [
      {
        "type": "controls_if"
      },
      {
        "fields": {
          "OP": "EQ"
        },
        "type": "logic_compare"
      },
      {
        "fields": {
          "OP": "AND"
        },
        "type": "logic_operation"
      },
      {
        "type": "logic_negate"
      },
      {
        "fields": {
          "BOOL": "TRUE"
        },
        "type": "logic_boolean"
      },
      {
        "type": "logic_null"
      },
      {
        "type": "logic_ternary"
      }
    ]
  },
  //{
  //  "name": "Loops",
  //  "colour": "#5CA65C",
  //  "blocks": [
  //    {
  //      "values": {
  //        "TIMES": {
  //          "fields": {
  //            "NUM": "10"
  //          },
  //          "type": "math_number",
  //          "shadow": true
  //        }
  //      },
  //      "type": "controls_repeat_ext"
  //    },
  //    {
  //      "fields": {
  //        "MODE": "WHILE"
  //      },
  //      "type": "controls_whileUntil"
  //    },
  //    {
  //      "fields": {
  //        "VAR": "i"
  //      },
  //      "values": {
  //        "FROM": {
  //          "fields": {
  //            "NUM": "1"
  //          },
  //          "type": "math_number",
  //          "shadow": true
  //        },
  //        "TO": {
  //          "fields": {
  //            "NUM": "10"
  //          },
  //          "type": "math_number",
  //          "shadow": true
  //        },
  //        "BY": {
  //          "fields": {
  //            "NUM": "1"
  //          },
  //          "type": "math_number",
  //          "shadow": true
  //        }
  //      },
  //      "type": "controls_for"
  //    },
  //    {
  //      "fields": {
  //        "VAR": "j"
  //      },
  //      "type": "controls_forEach"
  //    },
  //    {
  //      "fields": {
  //        "FLOW": "BREAK"
  //      },
  //      "type": "controls_flow_statements"
  //    }
  //  ]
  //},
  {
    "name": "Math",
    "colour": "#5C68A6",
    "blocks": [
      {
        "fields": {
          "OP": "ROUND"
        },
        "values": {
          "NUM": {
            "fields": {
              "NUM": "3.1"
            },
            "type": "math_number",
            "shadow": true
          }
        },
        "type": "math_round"
      },
      {
        "fields": {
          "NUM": "0"
        },
        "type": "math_number"
      },
      {
        "fields": {
          "OP": "ROOT"
        },
        "values": {
          "NUM": {
            "fields": {
              "NUM": "9"
            },
            "type": "math_number",
            "shadow": true
          }
        },
        "type": "math_single"
      },
      {
        "fields": {
          "OP": "SIN"
        },
        "values": {
          "NUM": {
            "fields": {
              "NUM": "45"
            },
            "type": "math_number",
            "shadow": true
          }
        },
        "type": "math_trig"
      },
      {
        "fields": {
          "CONSTANT": "PI"
        },
        "type": "math_constant"
      },
      {
        "mutation": {
          "attributes": {
            "divisor_input": "false"
          }
        },
        "fields": {
          "PROPERTY": "EVEN"
        },
        "values": {
          "NUMBER_TO_CHECK": {
            "fields": {
              "NUM": "0"
            },
            "type": "math_number",
            "shadow": true
          }
        },
        "type": "math_number_property"
      },
      {
        "fields": {
          "OP": "ADD"
        },
        "values": {
          "A": {
            "fields": {
              "NUM": "1"
            },
            "type": "math_number",
            "shadow": true
          },
          "B": {
            "fields": {
              "NUM": "1"
            },
            "type": "math_number",
            "shadow": true
          }
        },
        "type": "math_arithmetic"
      },
      {
        "mutation": {
          "attributes": {
            "op": "SUM"
          }
        },
        "fields": {
          "OP": "SUM"
        },
        "type": "math_on_list"
      },
      {
        "values": {
          "DIVIDEND": {
            "fields": {
              "NUM": "64"
            },
            "type": "math_number",
            "shadow": true
          },
          "DIVISOR": {
            "fields": {
              "NUM": "10"
            },
            "type": "math_number",
            "shadow": true
          }
        },
        "type": "math_modulo"
      },
      {
        "values": {
          "VALUE": {
            "fields": {
              "NUM": "50"
            },
            "type": "math_number",
            "shadow": true
          },
          "LOW": {
            "fields": {
              "NUM": "1"
            },
            "type": "math_number",
            "shadow": true
          },
          "HIGH": {
            "fields": {
              "NUM": "100"
            },
            "type": "math_number",
            "shadow": true
          }
        },
        "type": "math_constrain"
      },
      {
        "values": {
          "FROM": {
            "fields": {
              "NUM": "1"
            },
            "type": "math_number",
            "shadow": true
          },
          "TO": {
            "fields": {
              "NUM": "100"
            },
            "type": "math_number",
            "shadow": true
          }
        },
        "type": "math_random_int"
      },
      {
        "type": "math_random_float"
      }
    ]
    },
    //{
    //"name": "Text",
    //"colour": "#5CA68D",
    //"blocks": [
    //  {
    //    "values": {
    //      "TEXT": {
    //        "fields": {
    //          "TEXT": "abc"
    //        },
    //        "type": "text",
    //        "shadow": true
    //      }
    //    },
    //    "type": "text_print"
    //  },
    //]
    //},
    {
        "name": "Tags",
        "colour": "#A65C81",
        "blocks": []
    },
    //{
    //    "name": "Func",
    //    "colour": "#995ba5",
    //    "custom": "PROCEDURE"
    //}

  //{
  //  "name": "Variables",
  //  "colour": "#A65C81",
  //  "custom": "VARIABLE",
  //}
]

export const INITIAL_TOOLBOX_XML = '<xml xmlns="https://developers.google.com/blockly/xml" id="toolbox" style="display: none"> <category name="Category 1"> <block type="procedures_defreturn"> <field name="NAME">do something2</field> <comment pinned="false" h="80" w="160">Describe this function...</comment> <statement name="STACK"> <block type="procedures_ifreturn"> <mutation value="1"></mutation> <value name="CONDITION"> <block type="variables_get"> <field name="VAR" id="yu5ORYS@d+f]Vw)jHAe)">tset</field> </block> </value> </block> </statement> <value name="RETURN"> <block type="procedures_callreturn"> <mutation name="do something2"></mutation> </block> </value> </block> </category> <category name="Functions" colour="#995ba5" custom="PROCEDURE"></category> <category name="Variables" colour="#a55b80" custom="VARIABLE"></category> </xml>'

export const defaultXML = '<xml><variables><variable id="b$Yga6Kv3~LJY8upT^5G">Value</variable></variables><block type="variables_set" id="^9An3zKm*sGy^XB;ci$5" x="48" y="46"><field name="VAR" id="b$Yga6Kv3~LJY8upT^5G">Value</field><value name="VALUE"><block type="math_number" id="5q4dP;)i)NpPP8BRy9k|"><field name="NUM">0</field></block></value><next><block type="controls_if" id="vl_Ry.UT)GFW5dLDCUdp"><value name="IF0"><block type="logic_compare" id="k7$7gEgVt`Z50tGgn-Kb"><field name="OP">EQ</field><value name="A"><block type="math_arithmetic" id="XNDYu*0^ATpPXn$H5%Av"><field name="OP">MULTIPLY</field><value name="A"><shadow type="math_number" id="S`}(,v7=54*NRw071?Ae"><field name="NUM">1</field></shadow></value><value name="B"><shadow type="math_number" id=")BAZPm`CuE,B$_4OqGif"><field name="NUM">1</field></shadow></value></block></value><value name="B"><block type="math_number" id="liWciQwt~4wfk+IQ}R|;"><field name="NUM">1</field></block></value></block></value><statement name="DO0"><block type="variables_set" id="jyG4X(/?QA=vA,;o4{1)"><field name="VAR" id="b$Yga6Kv3~LJY8upT^5G">Value</field><value name="VALUE"><block type="math_number" id="T]c%yk;sKlz(,0S[`tyS"><field name="NUM">1</field></block></value></block></statement></block></next></block></xml>'