import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums';
import CaseSelector from '../../../components/CaseSelector';
import DropDatePicker from '../../../components/DropDatePicker';
import CheckBox from '../../../components/Controls/CheckBox'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown';
import moment from 'moment'
export const API_PATH = process.env.SERVER_PATH + '/api'

class Tasks extends Component {

    mdRefCallback = (instance) => {
        this.mdTasks = instance ? instance.getWrappedInstance() : null;
    }

    constructor(props) {
        super(props);
        this.handleDate = this.handleDate.bind(this);
        this.setDateOption = this.setDateOption.bind(this)
        this.setStatusOption = this.setStatusOption.bind(this)
        this.setCatOption = this.setCatOption.bind(this)
        var date = new Date();
        this.state = {
            fromDate: moment(date).add(-3, 'months').startOf('day'),
            toDate: moment(date.getTime()).endOf('day'),
            statuses: [{ id: 0, name: 'All' }, { id: 1, name: 'Complete' }, { id: 2, name: 'Open' }],
            dateType: [{ id: 0, name: 'Created' }, { id: 1, name: 'Due' }, { id: 2, name: 'Completed' }],
            category: [{ id: 0, name: 'All' }, { id: 1, name: 'Asset Management' }, { id: 2, name: 'Operations' }, { id: 3, name: 'Snags' }],
            selectedStatus: 2,
            selectedType: 0,
            selectedCat: 0,
            complete: false

        };
    }

    handleDate(fromDate, toDate, custom) {
        this.setState({ fromDate, toDate, dateSelectTitle: moment(fromDate).format('DD/MM/YY') + ' to ' + moment(toDate).format('DD/MM/YY') })
    }

    onCheck() {
        this.setState({ complete: !this.state.complete })
    }

    setDateOption(id) {
        this.setState({ selectedType: id }, () => this.mdTasks.refreshData())
    }

    setStatusOption(id) {
        this.setState({ selectedStatus: id }, () => this.mdTasks.refreshData())
    }

    setCatOption(id) {
        this.setState({ selectedCat: id }, () => this.mdTasks.refreshData())
    }

    getHeaderControl() {
        var headerControl = []
        headerControl.push(<IdNameDropdown style={{width:'80px', marginRight:'5px'}} options={this.state.dateType} valueField='id' displayField='name' onChange={this.setDateOption} value={this.state.selectedType} />)
        headerControl.push(<DropDatePicker key='ddpTas' handleDate={this.handleDate} startDate={this.state.fromDate} endDate={this.state.toDate} />)
        headerControl.push(<span style={{ margin: '1px 2px 0 5px' }} >Status</span>)
        headerControl.push(<IdNameDropdown style={{ width: '100px' }} options={this.state.statuses} valueField='id' displayField='name' onChange={this.setStatusOption} value={this.state.selectedStatus} />)
        headerControl.push(<span style={{ margin: '1px 2px 0 5px' }} >Category</span>)
        headerControl.push(<IdNameDropdown style={{ width: '115px' }} options={this.state.category} valueField='id' displayField='name' onChange={this.setCatOption} value={this.state.selectedCat} />)
        return headerControl
    }

    render() {

        var preLoad = false
        if (this.props.location.state && this.props.location.state.caseId) {
            preLoad = true
        }

        var taskFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Complete', fieldId: 'complete', type: 'checkbox', width: 70, display: 'grid' },
            { label: 'Category', fieldId: 'taskCategory', list: this.state.category, display: 'form' },
            { label: 'Station', fieldId: 'stationId', width:120, list: listRequest.windsites, displayIf: () => !preLoad },
            { label: 'Case', fieldId: 'caseRef', width: 150, display: 'grid', canFilter: true },
            { label: "", fieldId: "caseId", type: 'custom', display: 'form', custom: CaseSelector },
            { label: 'Assigned', fieldId: 'assignedTo', list: listRequest.operators, canFilter: true, width: 90 },
            { label: 'Task', fieldId: 'task', type: 'textarea', canFilter: true, colSpan: 10 },
            { label: 'Notes', fieldId: 'notes', width: 150, canFilter: true, type: 'textarea', colSpan: 10, display:'form'  },
            { label: 'Created', fieldId: 'created', width: 120, type: 'datetime', display: this.state.selectedType == 0 ? 'grid' : 'none' },
            { label: 'Due', fieldId: 'due', type: 'datetime', width: 120, display: this.state.selectedType == 1 ? null : 'form' },
            { label: 'Complete Date', fieldId: 'completeDate', width: 120, type: 'datetime', display: this.state.selectedType == 2 ? 'grid' : 'none'},
            { label: 'Completed', fieldId:'complete',  type: 'checkbox', display:'form'},
            { label: '', fieldId:'navButton', width: 60, display: 'grid', type: 'navButton' },
        ];

        var tasksConfig = {
            model: 'tasks',
            fields: taskFields,
            crudParams: { fromDate: moment(this.state.fromDate).format('DD-MM-YYYY'), toDate: moment(this.state.toDate).format('DD-MM-YYYY'), status: this.state.selectedStatus, dateType: this.state.selectedType, category: this.state.selectedCat },
            master: true,
            showFilterRow: false,
            preData: this.props.location.state,
            //addInline: true,
            gridProps: { defaultPageSize: 20 },
            type: 'checklist',
            apiPath: API_PATH
        }

        return ([<div key='tt' className='titleBlock'>Tasks</div>, <MasterDetail key='mdTasks' wrappedComponentRef={this.mdRefCallback} {...tasksConfig} headerControl={!this.props.match.params.id ? this.getHeaderControl() : null} />])
    }
}

export default (Tasks)
