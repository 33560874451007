import React, { Component } from 'react';
import { connect } from 'react-redux'
import Loader from '../../../components/Loader';
import { ApiPost, ApiGet } from '../../../api/index';
import ChangePassword from './ChangePassword'

class WelcomePage extends Component {

    constructor(props) {
        super(props)
        this.state = {changed: false }
    }

    onSuccess() {
        this.setState(
            { changed: true }
        )
    }

    render() {
        var {  commonstate } = this.props;
        var { changed } = this.state;
        console.log(commonstate.users)
        return (
            <div className="container" style={{ marginTop: '15px', maxWidth: '500px' }}>
                <div className="row">
                    {(changed)?<div className="col-12" style={{ textAlign: 'center' }}>
                        <h1>Password Updated</h1>
                        <p>Please keep your password secure</p>
                        <button type="button" className="btn btn-primary" onClick={() => this.props.history.push('/Dashboard/')}>Continue</button>
                    </div>:
                    <div className="col-12" style={{ textAlign: 'center' }}>
                        <h1>Change Password</h1>
                        <p>To keep it secure please choose one that has at least 6 characters. One of which must be a number and a special character.</p>
                            <ChangePassword forced={true} onSuccess={ () => this.onSuccess()} />
                    </div>
                }
                </div>
            </div>
        );
    }

    

    
}

const mapStateToProps = state => {
    return { commonstate: state.common }
}

export default connect(
    mapStateToProps,  
)(WelcomePage)