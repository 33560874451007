import React, { Component } from 'react';
import { universalMetrics } from '../../configuration/enums'
import { getConfigItem } from '../../api/index';
const SCADA_PATH = process.env.SCADA_PATH;
const SERVER_PATH = process.env.SERVER_PATH;

export default class StationStats extends Component {

    constructor(props) {
        super(props);
    }

    renderCell(k, conf) {
        const n = conf.title
        const v = this.props.getMetric(conf.umet,conf.average,conf.factor?conf.factor:1,conf.decimalPlaces)
        const u = conf.unit
        return (<li key={k}  className="list-inline-item siteHeaderInfo">
            <span>{v}</span> <span>{u}</span>
            <span>{n}</span>
        </li>)
    }

    getDeployWarningMessage(testDb, production,liveScada) {
        var ret = "";
        if (production) {
            if (testDb) {
                ret += " DB: Test."
            } else if (!liveScada) {
                ret += " No Live Scada."
            }
        } else {
            if (!testDb) {
                ret += " DB: LIVE."
            } else if (liveScada) {
                ret += " Live Scada!!"
            }
        }
        return ret
    }

    render() {
        const headerMetrics = getConfigItem('$.monitoring.headerMetrics')
        const source = getConfigItem('$.general.db')
        const testDb = (source && source.indexOf("Test") >= 0)
        const production = (SERVER_PATH.indexOf('localhost') < 0)
        const liveScada = (SCADA_PATH.indexOf('localhost') < 0)
        var warnMsg = this.getDeployWarningMessage(testDb, production, liveScada)
        return (
            (headerMetrics) ?
                <div className="station-info-bar">
                    <ul className="list-inline">
                        {Object.keys(headerMetrics).map(k => this.renderCell(k, headerMetrics[k]))}
                        {(warnMsg) ?
                            <li className="list-inline-item siteHeaderInfo">
                                <span style={{
                                    backgroundColor: '#d0021b',
                                    color: 'white',
                                    padding: '0 8px',
                                }}>{warnMsg}</span>
                            </li> : null}
                    </ul>
                </div> : null
        )

        //{
        //    (warnMsg) ?
        //    <li className="list-inline-item siteHeaderInfo">
        //        <span style={{
        //            backgroundColor: '#d0021b',
        //            color: 'white',
        //            padding: '0 8px',
        //        }}>{warnMsg}</span>
        //    </li> : null
        //}

        return (
                (headerMetrics)?
                <div className="station-info-bar">
                    <ul className="list-inline">
                        {Object.keys(headerMetrics).map(k => this.renderCell(k, headerMetrics[k]))}
                    </ul>
                </div>:null
            )
    }

}
