import React, { Component } from 'react';
import moment from 'moment';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import IdNameDropdown from '../../../components/Controls/IdNameDropdown';
import CheckBoxList from '../../../components/Controls/CheckBoxList';
import { ApiPost } from '../../../api';
import MasterDetail from 'MasterDetail'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { listRequest } from '../../../configuration/enums';
export const API_PATH = process.env.SERVER_PATH + '/api'




class Billing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Batches: [],
            BatchRef: '',
            BatchId: null,
            InvoiceDate: null,
            crudDetails: null,
            SumOfInvoiceTotal: null,
            ClearChecked: false,
            SelectAllChecked: false,
            cbValues: []
        };

    }

    componentDidMount() {

        this.OnLoad(event)
    }    

    OnLoad(event) { 

        this.setState({ BatchRef: event });
        ApiPost('Billing','GetBatches')
            .then((data) => {
                this.setState({ Batches: data, isLoading: false });  
                
            })
    } 

    OnBatchChange(SelectedBatch) {

        this.setState({ isLoading: true, BatchId: SelectedBatch }, () => this.GetBillingData());        
    }

    onInvoiceDateChange(SelectedInvoiceDate) {

        this.setState({ isLoading: true, InvoiceDate: moment(SelectedInvoiceDate).format("YYYY-MM-DD HH:mm:ss") }, () => this.GetBillingData());  
    }

    GetBillingData() {

        var crudDetails = { InvoiceDate: this.state.InvoiceDate, BatchId: this.state.BatchId }
        this.setState({ crudDetails, isLoading: false }, () => this.GetInvoiceTotal());       
    }

    GetInvoiceTotal() {

        ApiPost('Billing', 'GetInvoiceTotal', this.state.crudDetails)
            .then((data) => {
                console.log('GetInvoiceTotal data',data);
                this.setState({ SumOfInvoiceTotal: data, isLoading: false }
                );
            })

    }

    CheckClear() {
        console.log('i am in CheckClear');
        this.setState({ ClearChecked: true });        
    }

    CheckSelectAll() {
        console.log('i am in CheckSelectAll');
        this.setState({ SelectAllChecked: true }, () => this.SelectAll());
    }

    SelectAll() {
        console.log('i am in SelectAll', this.state.SelectAllChecked);
        var params = { SelectAllChecked: this.state.SelectAllChecked}
        ApiPost('Billing', 'GetBilling', params)
            .then((data) => {

                console.log('GetBilling data', data);
                this.setState({ cbValues: data, isLoading: false }
                );
            })

    }

    render() {

        var BillingSelectionFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Site", fieldId: "siteName", display: 'grid' },
            { label: "Bill Description", fieldId: "description", display: 'grid' },
            { label: "Invoice Date", fieldId: "invoiceDate", display: 'grid', width: 150 },
            { label: "Amount", fieldId: "invoiceTotal", display: 'grid' },
            { label: "", fieldId: "edit", display: 'grid', width: 50, type: 'funcButton', typeProps: { label: <i className="fas fa-edit" /> }},
            { label: "", fieldId: 'testClear', type: 'funcButton', display: 'form', section: 'Create Bills', typeProps: { label: "Clear All", func: () => this.CheckClear() } },
            { label: "", fieldId: 'testSelect', type: "funcButton", display: 'form', section: 'Create Bills', typeProps: { label: "Select All", func: () => this.CheckSelectAll() } },
            { label: 'sites', fieldId: 'sites', width: 60, display: 'form', type: 'checkList', listSource: '/Billing/GetSites', section: 'Create Bills' },
            { label: "Billing Template", fieldId: "name", display: 'form', list: listRequest.BillingTemplate, section: 'Create Bills' },            
        ];

        var BillingSelectionConfig = {
            model: 'Billing',
            path: '/Billing/GetData',
            fields: BillingSelectionFields,
            crudParams: this.state.crudDetails,
            gridButton: false,
            master: true,
            apiPath: API_PATH
        }

        return (
            <div>                
                <Row border="1" style={{ marginBottom: '10px' }}>
                    <Col lg={10} style={{ marginTop: '10px' }}>
                        <h1>Billing</h1> 
                    </Col>
                    {this.props.match.params.id != 'New' ?
                    <Col lg={2}>
                        <button type="button" class="btn" style={{ marginLeft: '26px', marginTop: '20px' }} onClick={() => this.props.history.push('/Billing/New/', { history: null })}> Create Bills </button>
                    </Col> : null} 
                </Row> 
                {this.props.match.params.id != 'New' ?
                <Row border="1" style={{ backgroundColor: 'rgba(247,247,247,1)', border: 'thin solid rgba(26, 27, 27, 0.32)', padding: '4px', marginLeft: '1px', marginRight: '1px' }}>
                    <Col lg={1} style={{ marginTop: '6px' }}>
                        Date
                    </Col>
                    <Col lg={4}>
                        <DateTimePicker format="DD/MM/YYYY HH:mm" defaultValue={new Date()} onChange={(SelectedInvoiceDate) => this.onInvoiceDateChange(SelectedInvoiceDate)} />
                    </Col>
                    <Col lg={2} style={{ marginRight: 'auto' }}>
                    </Col>
                    <Col lg={1} style={{ marginTop: '6px' }}>
                        Batch
                    </Col>
                    <Col lg={3} style={{ marginLeft: '1px' }}>
                            <IdNameDropdown options={this.state.Batches} valueField='id' displayField='createdDate' value={this.state.BatchRef} zeroText='Select Batch' onChange={(SelectedBatch) => this.OnBatchChange(SelectedBatch)} />
                    </Col>                    
                </Row> : null}   
                <Row style={{ marginBottom: '8px' }}>
                    {this.state.isLoading ?
                        <div style={{ marginLeft: '170px', marginTop: '100px' }}><i className="fa fa-spinner fa-spin" style={{ fontSize: '2em', color: 'grey' }} /></div>
                        : <Col lg={12} style={{ marginTop: '10px' }}>
                            <MasterDetail key='mdwc' {...BillingSelectionConfig} />
                        </Col>
                    }
                </Row>
                {this.props.match.params.id != 'New' ?
                    <Row border="1" >
                        <Col lg={9} style={{ marginTop: '6px' }}>
                            <button> Print Statements </button>
                        </Col>
                        <Col lg={3}>
                            <b> Total </b><input readOnly type="text" style={{ marginLeft: '2px' }} value={this.state.SumOfInvoiceTotal ? this.state.SumOfInvoiceTotal:''} />
                        </Col>
                    </Row> : null}         
            </div>

        )

    }
}

export default (Billing)
