import React, { Component } from 'react'
import { ApiPost, ApiGet } from '../../../api'
import NotificationItem from './NotificationItem'

class Notifications extends Component {

    constructor(props) {
        super(props);
        this.state = { notifications: null };
    }

    componentDidMount() {
       this.getNotifications()
    }

    getNotifications() {
        ApiGet('Notifications', 'GetAll', {})
        .then((data) => {
            this.setState({ notifications: data })
        })
    }

    render() {
        return (
            <div>
                <h2>User Notifications</h2>
                {this.state.notifications &&
                    this.state.notifications.map(n => {
                        return <NotificationItem  {...n} />
                    })
                }
            </div>
        )
    }
}

export default (Notifications)
