import React, { Component } from 'react';

export default class WorkflowComponent extends Component {

    constructor(props) {
        super(props);
        this.required = [];
        this.requiredMsg = [];
        this.customValidate = () => ""
        this.initalised = false
        this.refreshbase = this.refreshbase.bind(this);
        this.state = {
            data: { },
            changed: false,
            dataEntered: false,
            valid: false,
        }
    }

    initState(obj) {
        this.updateState(obj,true)
        this.checkReq()
        console.log("WorkflowComponent","initstate")
    }

    updateState(obj, init) {
        const data = {
            ...this.state.data,
            ...obj
        }
        this.setState({ data, changed: true, dataEntered: (this.state.dataEntered ||!init) })
    }

    componentDidUpdate() {
        if (this.state.changed || !this.initalised) {
            this.refreshbase();
            this.initalised = true
        }
    }

    getData() {
        return this.props.getData();
    }

    refreshbase() {
        const req = this.checkReq()
        this.props.updateData(this.props.level, this.state.data, req.valid, req.message);
        this.setState({ changed: false })
    }

    checkReq() {
        let reqmsg = "";
        let i = 0;
        for (i >=0; i < this.required.length; i++) {
            let reqname = this.required[i];
            if (!this.state.data[reqname]) {
                let msg = this.requiredMsg[i];
                if (reqmsg) {
                    reqmsg = reqmsg + ", ";
                }
                if (msg) {   
                    reqmsg = reqmsg + msg;
                } else {
                    reqmsg = reqmsg + (reqname + " required");
                }
            }
        }
        reqmsg = reqmsg + this.customValidate()
        this.setState({ valid: reqmsg.length > 0 });
        var ret = !reqmsg.length > 0
        //if (!this.state.dataEntered) {
        //    reqmsg = ""; // if no dataentered then don't show message
        //}
        return { valid:ret, message:reqmsg };
    }
}