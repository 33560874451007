import React, { Component } from 'react';
import CheckBoxList from '../../../components/Controls/CheckBoxList'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { listRequest } from '../../../configuration/enums'
import { ApiGet } from '../../../api'
import { Textfit } from 'react-textfit'

class AnalysisMenu extends Component {

    constructor(props) {
        super(props)
        this.state = { listData: [], selectedModel: null, filteredModels: null, ids: null }
    }

    componentDidMount() {
        this.getInitData()
    }

    getInitData() {
        var listsToGet = [listRequest.TurbineModelInUse];
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
            .then((data) => {
                var tempList = listsToGet;
                Object.keys(data).map(x => {
                    tempList[x] = data[x]
                })
                this.setState({ listData: tempList[23], complete: true })
            })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.assets != this.props.assets) {
            this.getInitData()
        }
    }

    getWidget(t) {
        return (
            <div key={'vT-' + t.dataVizName} className='analysisWidget' onClick={() => this.selectReport(t.dataVizId)}>
                <div className="summary-widget widget">
                    <div className="widget-icon" >
                        <i className={t.dataVizIcon}></i>
                    </div>
                    <div className="widget-info">
                        <div >
                            {t.dataVizName}
                        </div>
                        <div>
                            <span>{t.dataVizDescription}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    selectReport(rId) {
        this.props.reportTypeChange(rId)
    }

    getPlaceWidget(t) {
        return (
            <div key={'vT-' + t.dataVizName} className='analysisWidget'>
                <i className={t.dataVizIcon} style={{ fontSize: '20rem' }}></i>
                <Textfit>
                    <div >
                        {t.dataVizName}
                    </div>
                </Textfit>
                <p>{t.dataVizDescription}</p>
                <b>Please select options on the left</b>
            </div>
        )
    }

    setModel(mo) {
        var t = this.props.assets.filter(x => x.turbineModelId == mo)
        this.setState({ filteredModels: t })
    }

    render() {
        var { vizTypes, selectedViz } = this.props;
        var iconstyle = {
            fontSize: '24px',
            marginRight: '8px'
        }
        var sVizDets
        if (selectedViz != 0) {
            sVizDets = vizTypes.find(x => x.dataVizId == selectedViz)
        }

        return (
            <div>
                {sVizDets && sVizDets.displayFlags == 1 ?
                    <div>
                        <div className='analysisMenu' style={{ width: this.props.menuVisible ? '10%' : '1%', minWidth: this.props.menuVisible ? '170px' : '20px', textAlign: 'center' }}>
                            <div style={{ width: '150px', float: 'left', height: '100%', paddingRight: '5px', backgroundColor: '#FFF' }} >
                                <IdNameDropdown style={{ margin: '10px 0 10px 0' }} options={this.state.listData} zeroText='Select Model' valueField="id" displayField="name" onChange={(e) => this.setModel(e)} />
                                <CheckBoxList showSelectAll={!this.props.hideShowAll} options={this.state.filteredModels} style={{ maxHeight: '400px' }} innerStyle={{ margin: '5px 0 0 0', textAlign: 'left', width: '100%' }} onChange={(ids) => this.setState({ ids })} />
                                {this.state.filteredModels && <button style={{ width: '100%', marginTop: '10px' }} type="button" className="btn btn-primary" onClick={() => { this.props.setSubFilter({ assets: this.state.ids }), this.setState({ selected: true }, this.props.toggleMenu()) }}>Select <i className="far fa-sync-alt" /> </button>}
                            </div>
                            <div className='toggleBar' onClick={() => this.props.toggleMenu(this.props.menuVisible ? false : true)}><i className={this.props.menuVisible ? 'fa fa-caret-left' : 'fa fa-caret-right'} /></div>
                        </div>
                        {!this.state.selected && !this.props.manageExternal && <div style={{ width: '100%', height: '522px', position: 'absolute', zIndex: 2, textAlign: 'center', backgroundColor: '#ffffffbd' }}>
                            {this.getPlaceWidget(sVizDets)}
                        </div>}
                    </div>
                    :
                    <div className="analysisMenu">
                        <div className="row">
                            <div className="col-12" style={{ textAlign: 'center' }}>
                                <h2 style={{color:'#0a92b1'}}>Data Visualisation</h2>
                                <p>Select an analysis type from the dropdown to begin</p>
                                <div>
                                    {vizTypes.map(t => {
                                        return this.getWidget(t)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }


}

export default AnalysisMenu