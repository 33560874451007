import React from 'react'
import { ApiGet } from '../../api'


export default class CommandDefer extends React.Component {
    
    constructor(props) {
        super(props)
    }

    render() {
        const { title, data=0,className, onButtonClick=() => {} } = this.props;
        var cls = "btn"
        if (className) {
            cls += className
        } else {
            cls += " btn-dark"
        }
        return (
            <button type="button" className={cls} onClick={() => onButtonClick()}>
                {title}
            </button>
        )
    }
}