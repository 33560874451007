import React, { Component } from 'react';
import moment from 'moment'
import { ApiPost, getConfigItem, ApiGet } from '../../../api'
import { Col, Row, Input, Badge, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { connect } from 'react-redux';
import helpers from '../../../utils/helpers'
import { saveActivityFilter } from '../../../actions/header';
import CheckBoxList from '../../../components/Controls/CheckBoxList'
import { listRequest } from '../../../configuration/enums';
import DropDatePicker from '../../../components/DropDatePicker'

class ActivityController extends Component {
    constructor(props) {
        super(props);
        this.handleDate = this.handleDate.bind(this)
        this.cancelFilter = this.cancelFilter.bind(this)
        this.setWCType = this.setWCType.bind(this)
        this.getActivityData = this.getActivityData.bind(this)
        var date = new Date(), enabledContracts = getConfigItem('$.general.enabledContracts') || null
        this.state = {
            fromDate: moment(date).add(this.props.activityType == 1 ? -3 : -7, 'days').startOf('day'),
            toDate: moment(date.getTime()).add(this.props.activityType == 1 ? 7 : 0, 'days').endOf('day'),
            activityData: null,
            activityFilter: null,
            pendingFilter: false,
            textFilter: "",
            filterApplied: false,
            complete: false,
            enabledContracts
        };
        if (this.props.header.activityFilter && this.props.header.activityFilter.activityType != this.props.activityType) {
            this.cancelFilter()
        }
        else if (props.header.activityFilter) {
            this.state = {  ...this.state , ...props.header.activityFilter, ...{ filterApplied: true }}
        }
    }

    componentDidMount() {
        this.props.onRef(this)
        this.getInitData()
        //if (this.props.activityType == 1) {
        //    this.getActivityData(true)
        //}
        //this.props.dispatch(saveActivityFilter({ activityType: this.props.activityType }))
    }

    getInitData() {
        var listsToGet = [listRequest.FieldEngineer, listRequest.WorkCardTypes, listRequest.WorkCardStatuses, listRequest.CalendarEventTypes];
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
        .then((data) => {
            var tempList = listsToGet;
            Object.keys(data).map(x => {
                tempList[x] = data[x]
            })
            this.setState({ listData: tempList }, () => this.setFilter(true));
        })
    }

    getSavedFilter() {
        var tempState = {}, excluded = ['toDate', 'fromDate','activeWeek','activeMonth','activeYear']
        ApiGet('UserSession', 'GetActivityFilter')
        .then(data => {
            if (data.filter && parseInt(this.props.activityType) != 1 && data.filter.activityFilter) {
                Object.keys(data.filter.activityFilter).map(x => {
                    if (data.filter.activityFilter[x] != null && !excluded.includes(x)) {
                        tempState[x] = data.filter.activityFilter[x]
                    }
                })
                if (Object.keys(tempState).length > 0) {
                    this.setState({ ...tempState }, () => { this.setDispatch(), this.getActivityData() })
                }
            }
            else {
                this.getActivityData()
            }
        })
    }

    setFilter(fL = false) {
        //CUSTOM LOGIC FROM CLIENT CONFIG GOES HERE
        var isEngineer = this.props.commonstate.users.authRole && this.props.commonstate.users.authRole.includes('FieldEngineer'),
            wcTypes = this.state.enabledContracts ? this.state.enabledContracts : this.state.listData[listRequest.WorkCardTypes].map(x => x.id)
        var engineers = parseInt(this.props.activityType) == 1 && isEngineer ? [this.props.commonstate.users.authInternalId] : this.state.listData[listRequest.FieldEngineer].map(x => x.id)
        this.setState({ wcTypes, engineers, calEventTypes: this.state.listData[listRequest.CalendarEventTypes].map(x => x.id), complete: true }, () => fL && this.getSavedFilter())
    }

    handleDate(fromDate, toDate, custom, getData) {
        this.setState({ fromDate, toDate, dateSelectTitle: moment(fromDate).format('DD/MM/YY') + ' to ' + moment(toDate).format('DD/MM/YY') }, () => getData && this.getActivityData())
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.header.filterToken != this.props.header.filterToken) {
            this.getActivityData()
        }
    }

    setDispatch(saveFilter = false) {
        this.props.dispatch(saveActivityFilter({
            fromDate: this.state.fromDate, toDate: this.state.toDate, textFilter: this.state.textFilter,
            wcTypes: this.state.wcTypes,
            calEventTypes: this.state.calEventTypes,
            engineers: this.state.engineers,
            activeWeek: this.state.activeWeek,
            activeYear: this.state.activeYear,
            activeMonth: this.state.activeMonth,
            activityType: this.props.activityType,
            rowOrder: this.state.rowOrder
        }, saveFilter))
        this.setState({ filterApplied: true })
    }
   
    getActivityData(firstLoad = false, fromParentYear, fromParentWeek, fromParenList, fromParentMonth, activeWeek, activeYear, activeMonth)
    {
        if (fromParentWeek) {
            this.setState({ pendingFilter: !this.state.pendingFilter })
            var date = new Date();

            var startDate = moment().startOf('week')
            var endDate = moment().endOf('week')

            if (activeWeek) {
                startDate = moment().day("Monday").week(activeWeek).year(activeYear).startOf('week')
                endDate = moment().day("Monday").week(activeWeek).year(activeYear).endOf('week')
            }
            
            this.setState({ fromDate: startDate.endOf('day'), toDate: endDate.endOf('day'), filterText: this.state.textFilter, activeWeek: activeWeek, activeYear: activeYear, }, () => { this.setTitle() })

            var actObj = {
                fromDate: startDate.format('YYYY-MM-DD'),
                toDate: endDate.format('YYYY-MM-DD'),
                filterText: this.state.textFilter,
                activityType: this.props.activityType,
                wcTypes: JSON.stringify(this.state.wcTypes),
                engineers: JSON.stringify(this.state.engineers),
                calEventTypes: JSON.stringify(this.state.calEventTypes)
            }
            if (!firstLoad) {
                this.setDispatch()
            }
            else {
                if (this.state.filterApplied) {
                    this.setTitle()
                }
            }
            if (this.props.external) {
                this.props.handleData(actObj,true);
                this.setState({ pendingFilter: false });
            }
            else {
                ApiGet('Calendar', 'GetActivity', actObj)
                    .then((data) => {
                        console.log('getActivity data')
                        if (data) {
                            var tData = { ...data.lastW, ...data.t };
                            this.setState({ pendingFilter: false });
                            this.props.handleData(data, true);
                        }
                    })
            }
        }
        else if (fromParentMonth)
        {
            this.setState({ pendingFilter: !this.state.pendingFilter })
            var date = new Date()

            if (activeMonth == null) {
                var month = date.getMonth();
                var startDate = new Date(activeYear != null ? activeYear : date.getFullYear(), month, 1);
                var yearLeap = startDate.getFullYear() % 100 === 0 ? startDate.getFullYear() % 400 === 0 : startDate.getFullYear() % 4 === 0;
                var days = ((startDate.getMonth() == 3) || (startDate.getMonth() == 5) || (startDate.getMonth() == 8) || (startDate.getMonth() == 10)) ? 30 : ((startDate.getMonth() == 0) || (startDate.getMonth() == 2) || (startDate.getMonth() == 4) || (startDate.getMonth() == 6) || (startDate.getMonth() == 7) || (startDate.getMonth() == 9) || (startDate.getMonth() == 11)) ? 31 : yearLeap ? 29 : 28;
                var endDate = new Date(activeYear != null ? activeYear : date.getFullYear(), month, days);
            }
            else {
                var startDate = new Date(activeYear != null ? activeYear : date.getFullYear(), activeMonth, 1);
                var yearLeap = startDate.getFullYear() % 100 === 0 ? startDate.getFullYear() % 400 === 0 : startDate.getFullYear() % 4 === 0;
                var days = ((startDate.getMonth() == 3) || (startDate.getMonth() == 5) || (startDate.getMonth() == 8) || (startDate.getMonth() == 10)) ? 30 : ((startDate.getMonth() == 0) || (startDate.getMonth() == 2) || (startDate.getMonth() == 4) || (startDate.getMonth() == 6) || (startDate.getMonth() == 7) || (startDate.getMonth() == 9) || (startDate.getMonth() == 11)) ? 31 : yearLeap ? 29 : 28;
                var endDate = new Date(activeYear != null ? activeYear : date.getFullYear(), activeMonth, days);
            }


            this.setState({ fromDate: moment(startDate).endOf('day'), toDate: moment(endDate).endOf('day'), filterText: this.state.textFilter, activeWeek: activeWeek, activeYear: activeYear, }, () => { this.setTitle() })

            var actObj = {
                fromDate: moment(moment(startDate).add(0, 'days').endOf('day')).format('YYYY-MM-DD'),
                toDate: moment(moment(endDate).add(0, 'days').endOf('day')).format('YYYY-MM-DD'),
                filterText: this.state.textFilter,
                activityType: this.props.activityType,
                wcTypes: JSON.stringify(this.state.wcTypes),
                engineers: JSON.stringify(this.state.engineers),
                calEventTypes: JSON.stringify(this.state.calEventTypes)
            }
            if (!firstLoad) {
                this.setDispatch()
            }
            else {
                if (this.state.filterApplied) {
                    this.setTitle()
                }
            }
            if (this.props.external) {
                this.props.handleData(actObj, true);
                this.setState({ pendingFilter: false });
            }
            else {
                ApiGet('Calendar', 'GetActivity', actObj)
                .then((data) => {
                    this.setState({ pendingFilter: false });
                    this.props.handleData(data, true);
                })
            }
        }
        else {   
            var actObj = {
                fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate: moment(this.state.toDate).format('YYYY-MM-DD'),
                filterText: this.state.textFilter,
                activityType: this.props.activityType,
                wcTypes: JSON.stringify(this.state.wcTypes),
                engineers: JSON.stringify(this.state.engineers),
                calEventTypes: JSON.stringify(this.state.calEventTypes)
            }

            if (!firstLoad) {
                this.setDispatch()
            }
            else {
                if (this.state.filterApplied) {
                    this.setTitle()
                }
            }
            if (this.props.external) {
                this.props.handleData(actObj);
                this.setState({ pendingFilter: false });
            }
            else {
                ApiGet('Calendar', 'GetActivity', actObj)
                .then((data) => {
                    this.setState({ pendingFilter: false })
                    this.props.handleData(data, true)
                })
            } 
        }

    }

    getTitle(dateOnly = false, levelOnly= false) {
        const { showSystem, textFilter, fromDate, toDate, wcTypes } = this.state
        var filterTitle = [];
        if (!levelOnly) {
            if (toDate.date() == moment().date()) {
                filterTitle.push(<span key='spLast'>Last {helpers.parseDuration(fromDate, toDate)}</span>)
            }
            else {
                filterTitle.push(<span key='spLast'>Last {helpers.parseDuration(fromDate, toDate)} to {toDate.format('DD/MM')}</span>)
            }
        }
        if (!dateOnly) {
            if (textFilter) {
                filterTitle.push(<span key='spFilter'>includes: '{textFilter}'</span>)
            }

            if (showSystem) {
                filterTitle.push(<Badge key='bSystem' style={{ backgroundColor: '#343a40' }} >System</Badge>)
            }

            if (wcTypes && this.state.listData) {
                wcTypes.map(a => {
                    var c = this.state.listData[listRequest.WorkCardTypes].find(x => x.id == a)
                    if (c) {
                        var colour = c.config && JSON.parse(c.config).colour ? JSON.parse(c.config).colour : '#9d9da9'
                        filterTitle.push(<Badge key={'b' + a} style={{ backgroundColor: colour }} >{c.name}</Badge>)
                    }
                })
            }
        }

        var returnVal = [];
        for (var i = 0; i < filterTitle.length; i++) {
            returnVal.push(filterTitle[i])
            if (i + 1 != filterTitle.length) {
                if (filterTitle[i].type.name != 'Badge') {
                    returnVal.push(<span key={'sp'+i} style={{ marginRight: '5px' }}>,</span>)
                }
                else {
                    returnVal.push(<span key={'sp' + i} style={{ marginRight: '1px' }}> </span>)
                }
            }
        }
        return returnVal
    }

    setTitle() {
        this.props.updateTitle(<div style={{ fontSize: '1.4rem' }}>{this.getTitle()}</div>)
    }

    cancelFilter() {
        var date = new Date();
        this.props.dispatch(saveActivityFilter(null, true))
        this.setState({
            //fromDate: moment(date).add(this.props.activityType == 1 ? 0 : -7, 'days').startOf('day'),
            //toDate: moment(date.getTime()).add(this.props.activityType == 1 ? 7 : 0, 'days').endOf('day'),
            pendingFilter: false,
            textFilter: "",
            engineers: null,
            filterApplied: false
        }, () => { this.setFilter(false); this.setTitle(); setTimeout(() => this.getActivityData(),500); })
    }

    setWCType(e, type) {
        this.setState({ [type]: e.length > 0 ? e : null }, () => this.setTitle())
    }

    render() {
        const { fromDate, toDate, engineers, listData, textFilter, rToggle, wcTypes, pendingFilter, eToggle, tToggle, calEventTypes } = this.state
        return (
            <div className="activityController">
                <Row key='titleRow'>
                    <Col xs={12} sm={6}>
                        {this.props.activityType == '1' ? <DropDatePicker width='100%' handleDate={this.handleDate} startDate={fromDate} endDate={toDate} /> : this.getTitle(true)}
                    </Col>
                </Row>
                {
                this.state.complete &&
                    <Row>
                        <Col xs={12} sm={6}>
                            <input className="form-control input" placeholder="Search for keywords" onChange={(event) => this.setState({ textFilter: event.target.value }, () => this.setTitle())} value={textFilter} />
                            <Dropdown isOpen={eToggle} toggle={() => this.setState({ eToggle: !this.state.eToggle })} className="ddChecklist">
                                <DropdownToggle caret>{engineers ? engineers.length + ' engineers selected' : 'Select Engineer'}</DropdownToggle>
                                <DropdownMenu style={{ width: '100%', maxHeight: '800px', overflow:'scroll'}}>
                                    <CheckBoxList options={listData[listRequest.FieldEngineer]} DefaultIds={engineers} nameField="name" style={{ border: 'none', padding: '10px 0px 0px', maxHeight: 'none' }} innerStyle={{ marginLeft: 0 }} onChange={(SelectedCheckBoxIds) => this.setWCType(SelectedCheckBoxIds, 'engineers')} showSelectAll={true} />
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Dropdown isOpen={tToggle} toggle={() => this.setState({ tToggle: !this.state.tToggle })} className="ddChecklist" style={{ marginTop: 0 }}>
                                <DropdownToggle style={{ whiteSpace: 'unset' }} caret>{calEventTypes ? calEventTypes.length + ' calendar event types selected' : 'Select Calendar Event Type'}</DropdownToggle>
                                <DropdownMenu style={{ width: '100%', maxHeight: '800px' }}>
                                    <CheckBoxList options={listData[listRequest.CalendarEventTypes]} DefaultIds={calEventTypes} nameField="name" style={{ border: 'none', padding: '10px 0px 0px', maxHeight: 'none' }} innerStyle={{ marginLeft: 0 }} onChange={(SelectedCheckBoxIds) => this.setWCType(SelectedCheckBoxIds, 'calEventTypes')} showSelectAll={true} />
                                </DropdownMenu>
                            </Dropdown>
                            {calEventTypes && calEventTypes.includes(1) && <Dropdown isOpen={rToggle} toggle={() => this.setState({ rToggle: !this.state.rToggle })} className="ddChecklist" style={{ marginTop: 0 }}>
                                <DropdownToggle style={{ whiteSpace: 'unset' }} caret>{wcTypes ? this.getTitle(false, true) : 'Select Response'}</DropdownToggle>
                                <DropdownMenu style={{ width: '100%', maxHeight: '800px' }}>
                                    <CheckBoxList options={listData[listRequest.WorkCardTypes].map(x => { return { id: x.id, name: x.name + ' - ' + x.cGroup } })} DefaultIds={wcTypes} nameField="name" style={{ border: 'none', padding: '10px 0px 0px', maxHeight: 'none' }} innerStyle={{ marginLeft: 0 }} onChange={(SelectedCheckBoxIds) => (SelectedCheckBoxIds.length > 0 && this.setWCType(SelectedCheckBoxIds, 'wcTypes'))} showSelectAll={true} />
                                </DropdownMenu>
                            </Dropdown>}
                        </Col>
                        <Col xs={12} sm={2}>
                            <button type="button" className="btn btn-primary" onClick={() => { this.getActivityData(), this.setTitle(), this.setDispatch(true) }}>Search  {!pendingFilter ? null : <i className="fa fa-spinner fa-spin" />}</button>
                            <button type="button" className="btn" onClick={() => { this.cancelFilter() }}>Clear  </button>
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(mapStateToProps, null, null, { withRef: true })(ActivityController)
