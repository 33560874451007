import React, { Component } from 'react'
import { ApiPost } from '../../../api'
import { Container, Draggable } from 'react-smooth-dnd'
import ActCards from './ActivityCards'

class ActivityScheduler extends Component {

	constructor(props) {
        super(props);
        this.state = { duration: "0h 0m", editMode: false, addEvent:false }
    }

    getButtons() {
        return (
            <div style={{ display: 'flex', justifyContent:'space-between', marginBottom: '5px'}}>
                <button className='btn btn-primary calAddBtn' style={{width:'100%'}} onClick={() => this.setState({addEvent:true})}><i className="fas fa-user-hard-hat" /> Work Report <i className="fas fa-plus" /></button>
                {/*<button className='btn btn-primary calAddBtn' onClick={() => this.props.addNonEvent()}><i className="fas fa-calendar-times" /> Event <i className="fas fa-plus" /></button>*/}
            </div>
            )
    }

    onDrop(e) {
        if ((e.removedIndex !== null) !== (e.addedIndex !== null)) {
            console.log('ondropscheduler', e)
            ApiPost('Calendar', 'UnallocateCalEvent', { calId: e.payload })
            .then((data) => {
                this.props.refreshData()
            })
        }
       
    }

    renderCards(data) {
        var cards = []
        const { mode, history, eventTypes, stations, users, workStatuses, jobList, calEventTypes, customerWithSites } = this.props;
        //console.log('renderCards', data)
        data.map(x => {
            //console.log('x', x)
            //x.newEvent = true
            cards.push(
            <Draggable id={x.calId} key={x.calId}>
                    <div className='cardGrabber'><i className="fal fa-ellipsis-v" style={{ margin: '0 2px 0 20px' }} /><i className="fal fa-ellipsis-v" /></div>
                    <ActCards
                        key={'ac' + x.calId}
                        activityData={[x]}
                        isCompact={true}
                        compactMode={false}
                        history={history}
                        siteToggle={'2'}
                        eventTypes={eventTypes}
                        workStatuses={workStatuses}
                        stations={stations}
                        users={users}
                        jobList={jobList}
                        calEventTypes={calEventTypes}
                        displayBoth={true}
                        removeEvent={(e, u) => { this.props.removeEvent(e, u);  this.props.refreshData(); }}
                        saveEvent={(e) => { this.props.saveEvent(e); this.setState({ addEvent: false }); }}
                        customerWithSites={customerWithSites} />
            </Draggable>)
        })
        var t = {
            "date": null,
            "calId": 9999,
            "deviceId": null,
            "selectEvent": false,
            "users": [],
            "preSelect": false,
            "calEventType": 1,
            "existingEvent":true,
            "newEvent": false,
            "workcard": {
                "engineerLogId": 9999,
                "engineers": []
            }
        }
        if (this.state.addEvent) {
            cards.push(
                <Draggable id={123} key={123}>
                    <div className='cardGrabber'><i className="fal fa-ellipsis-v" style={{ margin: '0 2px 0 20px' }} /><i className="fal fa-ellipsis-v" /></div>
                    <ActCards
                        key={'tempCard' + 123}
                        activityData={[t]}
                        isCompact={true}
                        compactMode={false}
                        history={history}
                        siteToggle={'2'}
                        eventTypes={eventTypes}
                        workStatuses={workStatuses}
                        stations={stations}
                        users={users}
                        jobList={jobList}
                        calEventTypes={calEventTypes}
                        displayBoth={true}
                        saveEvent={(e) => { this.props.saveEvent(e); this.setState({ addEvent: false }); } }
                        removeEvent={(e, u) => { this.props.removeEvent(e, u); this.setState({ addEvent: false }); } }
                        customerWithSites={customerWithSites} />
                </Draggable>)
        }
        return cards 
    }

    render() {
        return (
            <div style={{height:'100%', minHeight:'500px'}}>
                {this.getButtons()}
                <Container key='activityScheduler' dropPlaceholder={{ className: 'drop-contain' }} groupName={'calSch'} shouldAcceptDrop={(s, p) => { return s.groupName != 'calSch' }} onDrop={(e) => this.onDrop(e)} style={{ backgroundColor: '#f1f1f1', height: '100%', borderRadius: '3px', padding: '3px' }} getChildPayload={(i) => this.props.data[i].calId}>
                    {this.props.data && this.renderCards(this.props.data) }
                </Container>
            </div>
        )
	}
}

export default ActivityScheduler
