import React, { Component } from 'react';
import { connect } from 'react-redux';
import MeteringLists from './MeteringLists';
import MeteringController from './MeteringController';

class MeteringContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mData: null
        };
        this.handleData = this.handleData.bind(this);
    }

    handleData(data) {
        this.setState({ mData: data });
        console.log('contain', data);
    }

    render() {
        return (
            <div style={{ maxWidth: '1100px' }}>
                <div className='titleBlock'>Metering</div>
                <MeteringController handleData={this.handleData} />
                <MeteringLists mData={this.state.mData} />
            </div>
        );
    }

};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(MeteringContainer)
