import React from 'react';

const ProgressBar = (props) => {
    return (
        <div className="progress" style={{backgroundColor: "#EEE", height:"20px"}}>
            <div className="progress-bar bg-danger" role="progressbar" style={{width: props.value + "%", height:"20px"}} aria-valuenow={props.value} aria-valuemin="0" aria-valuemax="100"></div>
         </div>
    );
};

export default ProgressBar;


