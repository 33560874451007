import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { ApiPost } from '../../../api'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'

class LookupSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { allData: null, lTables: null, lFields: null, dTable: null, dField: null }
    }

    componentDidUpdate(pp) {
        if (JSON.stringify(pp) != JSON.stringify(this.props)) {
            if (this.props.data) {
                this.getData()
            }
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        ApiPost('CustomForm', 'GetTableLookups', { type: this.props.type })
        .then((d) => {
            var t = []
            d.map(x => {
                var buffer = ''
                if (!x.isHeader) {
                    buffer = '- '
                }
                t.push({ id: x.id, name: buffer + x.name })
            })
            this.setState({ allData:d, lTables: t}, () => this.setVals())
        })
    }

    setVals() {
        console.log('this.props.data', this.props.data)
        if (this.props.data && this.props.data.customTableLookup) {
            var dField = this.props.data.customTableLookup
            var dTable = null
            this.state.allData.map(x => {
                if (x.fields) {
                    if (x.fields.find(y => y.id == dField)) {
                        dTable = x.id
                    }
                }
            })
            if (dTable) {
                var lFields = this.state.allData.filter(x => x.id == dTable)[0].fields
                console.log({ dField, dTable, lFields })
                this.setState({ dField, dTable, lFields })
            }
        }
    }

    setTable(id) {
        var lFields = this.state.allData.filter(x => x.id == id)[0].fields
        this.setState({lFields, dTable: id})
    }

    setVal(id) {
        this.setState({ dField: id })
        this.props.onChange(id)
    }

    render() {
        if (this.state.lTables) {
            return (
                <Row>
                    <Col sm={3}>
                        <IdNameDropdown zeroText={'Select ' + this.props.mainLabel} options={this.state.lTables} valueField="id" displayField="name" value={this.state.dTable} onChange={(e) => this.setTable(e)} />
                    </Col>
                    <Col sm={2} style={{marginLeft:'-20px'}}>
                        <IdNameDropdown zeroText={'Select ' + this.props.subLabel} options={this.state.lFields} valueField="id" displayField="name" value={this.state.dField} onChange={(e) => this.setVal(e)} />
                    </Col>

                </Row>
            )
        }
        else {
            return <span>No available lookup tables</span>
        }
    }
}

export default LookupSelector
