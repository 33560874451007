import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { formatPower } from './MapStation';
import Station from './MapStation.js';
import MapStationSquare from './MapStationSquare'

const K_WIDTH = 50;
const K_HEIGHT = 50;

const stationStyle =
{
    width: K_WIDTH,
    height: K_HEIGHT
};

const outputStyle =
    {
        position: 'absolute',
        width: K_WIDTH * 2,
        height: K_HEIGHT / 1.9,
        left: -K_WIDTH / 2,
        top: K_HEIGHT * 1,
        backgroundColor: '#004336',
        textAlign: 'center',
        color: '#FFF',
        fontSize: 10,
        fontWeight: 'bold',
        padding: -1
    };

const siteNameStyle =
    {
        position: 'absolute',
        width: K_WIDTH * 4,
        height: K_HEIGHT / 1.5,
        textAlign: 'center',
        top: K_HEIGHT * 1.5,
        left: -K_HEIGHT * 1.5
    };

export default class ClusterStation extends Component {
    static propTypes =
    {
        windSpeed: PropTypes.string,
        siteName: PropTypes.string,
        siteOutput: PropTypes.string
    };

    static defaultProps = {};

    render() {

        const { points } = this.props;

        var avgWS = Math.round(points.reduce((a, b) => a + parseInt(b.ws), 0) / points.length);
        var totPower = points.reduce((a, b) => a + parseInt(b.pw), 0);
        var avgPW = Math.round(totPower / points.length);

        var ws = avgWS?(
            <div style={stationStyle}>
                {avgWS}
            </div>
        ) : <div style={stationStyle} />;

        //var styles = calcAngles(this.props.siteOutput,250)
        
        var pointRadius = 10;
        // calc the height with the firt point
        const first = getRotation(0,points.length,pointRadius);
        const clusterStyle = {
            width: first[2] + (pointRadius*2),
            height: first[2]+ (pointRadius*2)
        }
        const clusterWrapStyle = {
            top: first[2]/-2 + 'px',
            left: first[2]/-2 + 'px',
        }
        const clusterCenterStyle = {
            top: pointRadius + 'px',
            left: pointRadius + 'px',
            backgroundColor: 'white',
            width: first[2],
            height: first[2]
        }
        const clusterCenterSize = {
            lineHeight: first[2] + 'px'
        }
        const KwFooterStyle = {
            top: (first[2] + pointRadius + 6) + 'px'
        }
        if (points.length > ((points[0].industryId == 1)?8:4)) {
            var classn = "big-cluster";
            if (totPower >= 1) {
                classn += " producing"
            }
            return (<div style={stationStyle} className={classn}>
                {points.length} <span className="small">sites</span><br/>
                {formatPower(totPower,null ,null )}
            </div>)
        }  
        else {
            return (
                <div className="clusterwrap" style={clusterWrapStyle}>
                    <div className="cluster"style={clusterStyle}>
                        {(points[0].industryId == 1)?
                        <div className="cluster-center" style={clusterCenterStyle}>
                            {(points.length > 7 && points.length < 10)?formatPower(totPower, clusterCenterSize):null}
                        </div>:null}
                        {points.map((item,index) => {
                            if (item.industryId != 1) {
                                return (
                                    <MapStationSquare key={index} id={item.id} industryId={item.industryId} alertsx={item.alerts} lat={item.lat} lng={item.lng} asset={item} siteName={item.name} siteOutput={item.pw} stalex={item.stale} />
                                );
                            } else {
                                const rot = getRotation(index, points.length, pointRadius);
                                return ( 
                                    <Station key={index} lat={item.lat} id={item.id} alertsx={item.alerts} lng={item.lng} windSpeed={item.ws} siteName={item.name} multi={true} siteOutput={item.pw} style={getStylePos(rot)} />
                                );
                            }
                            
                            
                            })
                        }
                    </div>
                    {(points.length <= 7)?(<div className="point-footer">{formatPower(totPower,null ,null )}</div>):null}
                </div>
            );
        }
    }
}



const getStylePos = function(pos) {
    return {  left: pos[0] + 'px', top: pos[1] + 'px' }
}

const getRotation = function(index, total, pointR) {
    var extrarot = 0;
    if (total == 3) {
        extrarot = (Math.PI /2) * 3;
    } else if (total == 4) {
        extrarot = (Math.PI /4);
    }
    var deg = (index / total) * 360
    // Overlap a bit
    //if (total > 4) { pointR = Math.floor(pointR * 0.8); }
    //get radius of circle to wrap points around

    var clusterRadius = pointR / Math.cos(Math.PI * (total -2)/ (2* total) );
    var height = 2*(clusterRadius);
    var midpoint = clusterRadius;

    var rot = index * (2*Math.PI / total);
    var centerPoint = rotate(midpoint, midpoint, 0,midpoint, rot + extrarot);
    return [centerPoint[0], centerPoint[1], height];
}

const rotate = function(cx, cy, x, y, radians) {
    var cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx, ny];
}