import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { universalMetrics, universalState, industryDefaultMetrics } from '../../../configuration/enums'
import MonitoringTileDynamic from './MonitoringTileDynamic'
import DonutChart from '../../../components/MiniCharts/DonutChart'
import LiquidLevel from '../../../components/MiniCharts/LiquidLevel'
import ValueRender from '../../../components/MiniCharts/ValueRender'
import { connect } from 'react-redux'

class MonitoringTileDevices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleGroups: [], initialMetricVals: []
        }
    }

    titleClick(stationid) {
        this.props.history.push('/Scada/' + stationid)
    }

    renderChildDevices(devices) {
        var distinctDeviceTypes = []
        devices.map(x => {
            if (!distinctDeviceTypes.includes(x.deviceTypeId)) {
                distinctDeviceTypes.push(x.deviceTypeId)
            }
        })
        if (distinctDeviceTypes.length == 1) {
            this.toggleGroup(distinctDeviceTypes[0], true)
        }
        return (distinctDeviceTypes.map(type => {
            if (this.props.config[type]) {
                var dTitle = this.props.config[type].title + 's'
                var tDevices = devices.filter(f => f.deviceTypeId == type)
                var siteRenders = []
                tDevices.map((o, i) => {
                    if (this.props.metricMap && this.props.metricMap.length > 0) {
                        siteRenders.push(<MonitoringTileDynamic station={o} key={'cd-' + i} config={this.props.config} metricMap={this.props.metricMap[o.id]} favourites={this.props.favourites[o.id]} getVal={(mId, dp, dId, isUId) => this.props.getVal(mId, dp, dId, isUId)} toggleFavMetric={(mId, dId) => this.props.toggleFavMetric(mId, dId)} />)
                    }
                    else {
                        if (o.metrics) {
                            siteRenders.push(<MonitoringTileDynamic station={o} key={i} childDevices={this.props.commonstate.header.childDevices} config={this.props.config} />)
                        }
                    }
                })

                return (<div className='typeBlock' key={'b' + type}>
                    <div className='typeBlockTitle' onClick={() => this.toggleGroup(type)} ><span>{dTitle}</span><i className={'fas fa-chevron-' + (this.state.visibleGroups.includes(type) ? 'up' : 'down')} style={{ float: 'right', padding: '5px' }} /></div>
                    <div>{this.renderTypeSummary(tDevices, type)}</div>
                    {this.state.visibleGroups.includes(type) && <div style={{ width: '100%', display: 'inline-block' }}>{siteRenders}</div>}
                </div>)
            }
        }))
    }

    toggleGroup(type, initial) {
        if (initial) {
            if (this.state.visibleGroups.length == 0) {
                this.setState({ visibleGroups: [type] })
            }
        }
        else {
            var tVisible = this.state.visibleGroups
            if (tVisible.includes(type)) {
                tVisible = tVisible.filter(x => x != type)
            }
            else {
                tVisible.push(type)
            }
            this.setState({ visibleGroups: tVisible })
        }
        
    }

    renderTypeSummary(typeDevices, type) {
        var metrics = this.props.config[type].groupMetrics
        var metricSummaryVals = []
        return (metrics.map(mId => {
            var metricVal = 0
            var metricVals = []
            var metricInfo = this.props.config[type].groupRenderDetails[mId]
            if (metricInfo) {
                typeDevices.map(d => {
                    var cVal = null, cMetId = null
                    if (d.metrics) { //GRID VIEW - METRICS FROM REDUX
                        cMetId = mId
                        cVal = d.metrics[mId] != undefined ? d.metrics[mId]  : null
                        //else if (this.props.metricMap) { //SCADA VIEW - METRICS FROM SCADA DICT
                        //    cMetId = this.props.metricMap[d.id][mId] //CONVERT UMetId TO MetId
                        //    cVal = parseFloat(this.props.getVal(cMetId, 0, d.id))
                        //}
                        if (cVal != null) {
                            if ((this.state.initialMetricVals[d.id] == null || this.state.initialMetricVals[d.id][cMetId] == null) && !isNaN(cVal)) {
                                var temp = this.state.initialMetricVals
                                if (temp[d.id] == null) {
                                    temp[d.id] = []
                                }
                                temp[d.id][cMetId] = cVal
                                this.setState({ initialMetricVals: temp })
                            }
                            if (this.state.initialMetricVals[d.id] && this.state.initialMetricVals[d.id][cMetId] && metricInfo.type == 'difference') {
                                cVal = (cVal - this.state.initialMetricVals[d.id][cMetId])
                            }
                            metricVals.push(cVal)
                        }
                    }

                })
                if (metricVals.length > 0) {
                    metricVal = metricVals.reduce((a, b) => a + b, 0)
                    var per = (metricVal / (metricInfo.max * typeDevices.length)) * 100
                    if (metricInfo.type == 'average') {
                        metricVal = (metricVal / metricVals.length) || 0
                        per = (metricVal / metricInfo.max) * 100
                    }
                    metricVal = metricVal.toFixed(metricInfo.decimalPlaces)
                    return this.renderTile(metricVal, per, metricInfo.unit, mId, metricInfo.title, metricInfo.renderer, metricInfo.rendererDetails)
                }
            }
        }))
    }

    getMetricDetail = (o, id) => {
        const info = industryDefaultMetrics.renderDetails[id]
        if (o.metrics && info) {
            var max = info.max
            const value = o.metrics[id]
            if (value != undefined) {
                if (!Number.isInteger(info.max)) {
                    max = o[info.max]
                }
                var per = ((value / max) * 100)
                var overclass = ""
                if (per < 0) per = 0
                if (per > 100) {
                    if (per > 102) {
                        overclass = "over"
                    }
                    per = 100
                }

                var ret = {
                    v: value.toFixed(info.decimalPlaces),
                    max: max,
                    renderer: info.renderer,
                    units: info.unit,
                    title: info.title,
                    per,
                    overclass: overclass,
                    dP: info.decimalPlaces
                }
                return ret;
            }
        }
    }

    renderMetrics(device) {
        var metrics = []
        if (this.props.config[device.deviceTypeId]) {
            metrics = this.props.config[device.deviceTypeId].metrics
        }
        else {
            metrics = industryDefaultMetrics.views[device.industryId].metrics
        }
        return (metrics.map(metricid => {
            var valueinfo = this.getMetricDetail(device, metricid)
            if (valueinfo && valueinfo != undefined && valueinfo.renderer == 'bar') {
                var per = ((valueinfo.v / valueinfo.max) * 100);
                if (per > 100) { per = 100 }
                return this.renderTile(valueinfo.v, per, valueinfo.units, metricid, valueinfo.title)
            }
        }))
    }

    renderTile(value, valuepercent, units, key, name, renderer, rendererDetails) {
        return (<div className='renderTile' key={key}>
            <div className='renderTileContent'>
                {renderer == 'donut' || renderer == null ?
                    <DonutChart value={value} valuePercent={valuepercent} units={units} size={100} rendererDetails={rendererDetails} />
                : renderer == 'liquidLevel' ?
                        <LiquidLevel value={value} valuePercent={valuepercent} units={units} size={100} rendererDetails={rendererDetails} />
                : renderer == 'value' ?
                    <ValueRender value={value}  units={units}/>
                : null}
            </div>
            <div className='renderTileTitle'>
                {name}
            </div>
        </div>)
    }

    render() {
        const o = this.props.station
        var cDevices = o.childDevices ? o.childDevices : this.props.commonstate.header.childDevices[this.props.station.id]
        return (
            <div className='monitoringMultiDevice' >
                {!this.props.metricMap && <div className='multiDeviceHead' >
                    <div className='multiDevicHeadTitle' onClick={() => this.titleClick(o.id)}>
                        <span>{o.name}</span>
                    </div>
                    <div>
                        {
                            this.renderMetrics(o)
                        }
                    </div>
                </div>}
                <div className='multiDeviceBody'>
                    {
                        this.renderChildDevices(cDevices)
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common, scada: state.common.scada }
}

export default withRouter(connect(mapStateToProps)(MonitoringTileDevices))