import React, { Component } from 'react'
import { ApiGet, ApiPost, SERVER_PATH } from '../../../api'
import { connect } from 'react-redux'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import { Col, Input, Label, Row, Button } from 'reactstrap'
import DropDatePicker from '../../../components/DropDatePicker'
import moment from 'moment'
import AnalysisMenu from './AnalysisMenu'
import CheckBox from './../../../components/Controls/CheckBox'
import OptionBtn from '../../../components/Controls/OptionButton'
import { metricGroups } from '../../../configuration/enums'
import { withRouter } from 'react-router-dom'
import CheckBoxList from '../../../components/Controls/CheckBoxList'

class AnalysisController extends Component {
    constructor(props) {
        super(props);
        var toDate = moment().subtract(10, 'minutes');
        var fromDate = moment().subtract(1, 'months');
        var fPCS = []
        var vizType = 0
        var manageExternal = false
        if (this.props.location.state && this.props.location.state.requestInfo) {
            fPCS = this.props.location.state.requestInfo
            vizType = this.props.location.state.dvType
            manageExternal = true
        }
        this.state = {
            removeSelected: true, disabled: false, stayOpen: false, sites: [], fromDate: fromDate, toDate: toDate, vizType: vizType, metricVisible: false, metricList: null, selectedMetric: 0, modalOpen: false, pcSelection: {},
            sController: false, sMeter: true, sSpec: false, tfDeg: false, tfDR: true, fullPCSelection: fPCS, selectedRanges: null, vizTypes: null, selectionCount: 10, rangeSelection: [], selectPage: 1, multiDate: false, assetSubFilter: null, manageExternal,
            hideShowAll: false, tariffs: [], activeTariffs: null, existingTariffs: [], existingTariff: null
        };
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.reportTypeChange = this.reportTypeChange.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.onSelectMetric = this.onSelectMetric.bind(this);
        this.arrowRenderer = this.arrowRenderer.bind(this);
        this.handleActivityData = this.handleActivityData.bind(this);
    }

	componentDidMount() {
        ApiGet('DataViz', 'GetDataViz')
        .then((data) => {
            console.log('vizTypes', data)
            this.setState({ vizTypes: data.options, tariffs: data.comparisonTariffs, existingTariffs: data.tariffs });
        })

        this.getStationMetrics();
        if (this.state.vizType != 0) {
            this.getAnalysisData(this.props.location.state.dvType, this.state.fullPCSelection[0].fromDate, this.state.fullPCSelection[0].toDate, 0)
        }
    }

    handleSelectChange(sites) {
        var siteArray = []
        siteArray.push.apply(siteArray, sites.split(",").map(Number))
        var currentSelection = this.state.fullPCSelection
        var range = this.state.rangeSelection
        var tempKeys = []
        currentSelection.map((x, i) => {
            if (!siteArray.includes(parseInt(x.pcId))) {
                tempKeys.push(i)
            }
        });
        tempKeys.reverse().map(x => {
            currentSelection.splice(x, 1)
        })
        this.state.rangeSelection.map(x => {
            if (!siteArray.includes(x.value)) {
                range = range.filter(z => z.value != x.value)
            }
        })
        this.setState({ fullPCSelection: currentSelection, sites: siteArray, rangeSelection: range })
        const { fromDate, toDate, vizType } = this.state;
        this.getAnalysisData(vizType, fromDate, toDate)
    }

    setDefaults() {
        this.setState({ fullPCSelection: [], rangeSelection: [], sites: [], selectPage: 1 })
    }

    reportTypeChange(e) {
        this.props.handleAnalysisData(null);
        this.setDefaults()
        var rpt = e.target ? e.target.value : e
        this.setState({ tfDR: true, tfDeg: false });
        if (rpt == 3) {
            this.setState({ vizType: 3, metricVisible: true, selectionCount: 150, fromDate: moment().subtract(12, 'hours'), toDate: moment(), multiDate: false, modalOpen: false });
        }
        else if (rpt == 0) {
            this.setState({ modalOpen: false, metricVisible: false, multiDate: false });
        }
        else if (rpt == 7) {
            this.setState({ vizType: 7, metricVisible: false, selectionCount: 1, fromDate: moment().subtract(12, 'months'), toDate: moment(), modalOpen: false, multiDate: false });
        }
        else if (rpt == 11) {
            this.setState({ vizType: 11, tfDR: false, metricVisible: false, selectionCount: 1, fromDate: moment().subtract(12, 'months'), toDate: moment(), modalOpen: false, multiDate: false });
        }
        else {
            var fL = false
            if (this.state.vizType == 0) {
                fL = true
            }
            if (rpt == 2) {
                this.setState({ vizType: 2, metricVisible: false, modalOpen: true, selectionCount: 2, multiDate: true, fromDate: moment().subtract(30, 'days'), toDate: moment() }, () => { this.props.toggleMenu(true), this.selectOptions(null, true) })
            }
            else if (rpt == 1) {
                this.setState({ vizType: 1, metricVisible: false, modalOpen: true, selectionCount: 0, multiDate: true, fromDate: moment().subtract(30, 'days'), toDate: moment() }, () => { this.props.toggleMenu(true), this.selectOptions(null, true) })
            }
            else if (rpt == 11) {
                this.setState({ vizType: 11, metricVisible: false, modalOpen: true, selectionCount: 0, multiDate: false, fromDate: moment().subtract(30, 'days'), toDate: moment() }, () => { this.props.toggleMenu(true), this.selectOptions(null, true) })
            }
            else if (rpt == 9) {
                this.setState({ vizType: 9, metricVisible: false, modalOpen: true, selectionCount: 0, multiDate: true, fromDate: moment().subtract(24, 'hours'), toDate: moment() }, () => { this.props.toggleMenu(true), this.selectOptions(null, true) })
            }
            else if (rpt == 12) {
                this.setState({ vizType: 12, metricVisible: false, modalOpen: true, selectionCount: 100, multiDate: false, fromDate: moment().subtract(90, 'days'), toDate: moment() }, () => { this.props.toggleMenu(true), this.selectOptions(null, true) })
            }
        }
        if (rpt != 1) {
            this.setState({ assetSubFilter: null })
            if (this.props.menuVisible) {
                this.props.toggleMenu(false)
            }
        }
        //document.getElementById("analysis-chart").classList.add("show");
    }

    getStationMetrics() {
        var tempList = [];
        var tempStationIds = [];
        this.state.fullPCSelection.map(x => { if (!tempStationIds.includes(x.stationId)) { tempStationIds.push(x.stationId) } });
        tempList.push(<option key={'metric_place'} value="">Select Metric</option>);
        var stationDeets = {
            stations: tempStationIds
        }

        ApiGet('DataViz', 'GetUniMetrics', stationDeets)
            .then((data) => {
                Object.keys(data).map((x, i) => {
                    tempList.push(<option key={'metric_' + data[x].id} value={data[x].id}>{data[x].name}</option>);
                })
            })

        this.setState({ metricList: tempList });
    }

    handleDate(fromDate, toDate, getData) {
        this.setState({ fromDate: fromDate, toDate: toDate }, () => getData ? this.selectOptions() : null);
    }

    getAnalysisData(reportType, fromDate, toDate, metric, add = { analysisType: 'faultCount', alertType: 0 }) {
        var dataSent = {};
        this.state.fullPCSelection.map(x => {
            dataSent[x.stationId] = x
        })

        var dataSent = JSON.stringify(this.state.fullPCSelection);
        var tempStationIds = [];
        this.state.fullPCSelection.map(x => { if (!tempStationIds.includes(x.stationId)) { tempStationIds.push(x.stationId) } });
        var dataSettings = {
            fullRequestInfo: dataSent,
            fromDate: moment(fromDate).format('DD/MM/YY HH:mm:ss'),
            toDate: moment(toDate).format('DD/MM/YY HH:mm:ss'),
            stationIds: tempStationIds,
            reportType,
            metric: metric.existingTariff ? metric.existingTariff : metric,
            reportDetails: JSON.stringify(add),
            fullComparisonTariffs: metric.activeTariffs
        }
        console.log('dataSettings', dataSettings)
        if (dataSettings.stationIds.length > 0) {
            var dataRetst = performance.now()
            this.props.toggleLoading(true)
            ApiPost('DataViz', 'GetAnalysisData', dataSettings)
                .then((data) => {
                    if (data != null) {
                        console.log("Retrieval time: ", (performance.now() - dataRetst) * 0.001);
                    }
                    this.props.toggleLoading(false)
                    this.props.handleAnalysisData(data);
                })
        }
        else {
            this.props.handleAnalysisData(null);
        }

        if (this.state.metricList == null && this.state.sites != []) {
            this.getStationMetrics();
        }
    }

    onSelectMetric(e) {
        const { fromDate, toDate, vizType } = this.state;
        var selectedMetric = e.target.value;
        if (selectedMetric != 0) {
            this.setState({ selectedMetric }, () => this.getAnalysisData(vizType, fromDate, toDate, selectedMetric));
        }
    }

    selectOptions(custData, preventLoad = false) {
        var siteArray = [];
        var siteList = [];
        var tempSelect = this.state.rangeSelection;
        var filterSites = this.props.header.assets;
        if (!this.state.multiDate && this.state.rangeSelection.length == 1) {
            tempSelect.shift()
        }

        var index
        if (this.state.selectPage == 1) {
            index = 0
        }
        else {
            index = (this.state.selectionCount * this.state.selectPage) - this.state.selectionCount
        }

        if (this.state.assetSubFilter) {
            filterSites.map(x => {
                if (this.state.assetSubFilter.includes(x.id)) {
                    siteArray.push(x)
                }
            })
        }
        else {
            filterSites.slice(index, this.state.selectionCount * this.state.selectPage).map(x => {
                siteArray.push(x)
            })
        }

        var currentSelection = this.state.fullPCSelection;
        var pcId = parseInt(this.state.fromDate.format('DDMMYY') + '' + this.state.toDate.format('DDMMYY'))
        siteArray.map(x => {
            var dvId = parseInt(x.id + '' + pcId);
            if (!currentSelection.find(y => y.value == dvId)) {
                currentSelection.push({ value: dvId, pcId: pcId, label: x.name + ' ' + this.state.fromDate.format('DD/MM/YY') + '-' + this.state.toDate.format('DD/MM/YY'), stationId: x.id, fromDate: this.state.fromDate, toDate: this.state.toDate, controller: this.state.sController, meter: this.state.sMeter, spec: this.state.sSpec });
                if (this.state.tfDeg && this.state.degDuration) {
                    var fDate = moment().subtract(this.state.degDuration, 'months');
                    var tDate = moment().subtract((this.state.degDuration - 1), 'months');
                    currentSelection.push({ value: parseInt(x.id + '' + fDate.format('DDMMYY') + '' + tDate.format('DDMMYY')), label: x.name + ' ' + fDate.format('DD/MM/YY') + '-' + tDate.format('DD/MM/YY'), stationId: x.id, fromDate: fDate, toDate: tDate, controller: this.state.sController, meter: this.state.sMeter, spec: this.state.sSpec });
                }
            }
        })
        if (this.state.tfDeg && this.state.degDuration) {
            var fDate = moment().subtract(this.state.degDuration, 'months');
            var tDate = moment().subtract((this.state.degDuration - 1), 'months');
            tempSelect.push({ value: parseInt(fDate.format('DDMMYY') + '' + tDate.format('DDMMYY')), label: fDate.format('DD/MM/YY') + ' - ' + tDate.format('DD/MM/YY') });
        }
        else {
            tempSelect.push({ value: pcId, label: this.state.fromDate.format('DD/MM/YY') + ' - ' + this.state.toDate.format('DD/MM/YY') });
        }

        tempSelect.map(x => {
            siteList.push(parseInt(x.value));
        })
        this.setState({ fullPCSelection: currentSelection, sites: siteList, rangeSelection: tempSelect, modalOpen: false });
        const { fromDate, toDate, vizType, selectedMetric, add } = custData ? custData : this.state;
        if (!preventLoad && this.state.vizType != 3) { this.getAnalysisData(vizType, fromDate, toDate, selectedMetric, add) }
    }

    toggleModal() {
        this.setState({ modalOpen: !this.state.modalOpen })
    }

    checkCheck(type, val) {
        if (type == 'source') {
            this.setState({ [val]: !this.state[val], fullPCSelection: [], rangeSelection: [], sites: [] }, () => this.selectOptions())
        }
        else if (type == 'time') {
            this.setState({ tfDeg: false, tfDR: false });
            this.setState({ [val]: !this.state[val] })
        }
    }

    changeDeg(e) {
        var monthDeg = e.target.value;
        this.setState({ degDuration: e.target.value });
    }

    typeClick(e) {
        if (this.state.modalOpen) {
            this.setState({ modalOpen: false })
        }
    }

    arrowRenderer() {
        return <i className="fa fa-plus" style={{ fontSize: '1.5rem', color: '#888' }} onMouseDown={() => this.toggleModal()} onTouchStart={() => this.toggleModal()} />
    }

    page(direction) {
        var maxPages = Math.floor(this.props.header.assets.length / this.state.selectionCount)
        if (direction == '+') {
            if (this.state.selectPage <= maxPages) {
                this.setState({ selectPage: this.state.selectPage + 1, fullPCSelection: [], rangeSelection: [], sites: [] }, () => this.selectOptions())
            }
        }
        else {
            if (this.state.selectPage != 1) {
                this.setState({ selectPage: this.state.selectPage - 1, fullPCSelection: [], rangeSelection: [], sites: [] }, () => this.selectOptions())
            }
        }
    }

    getFilterMsg() {
        var assetCount = this.props.header.assets.length;
        if (assetCount <= this.state.selectionCount) {
            return <span style={{ fontWeight: 'bold' }}>{'Showing all ' + assetCount + ' assets'}</span>
        }
        else {
            var index, msg
            if (this.state.selectPage == 1) {
                index = 1
            }
            else {
                index = (this.state.selectionCount * this.state.selectPage) - this.state.selectionCount
            }

            if (this.state.selectionCount == 1) {
                msg = this.state.selectPage + ' of ' + assetCount
            }
            else {
                var topRange = this.state.selectionCount * this.state.selectPage
                if (topRange > assetCount) {
                    topRange = assetCount
                }
                msg = index + ' - ' + topRange + ' of ' + assetCount
            }

            return [<OptionBtn key='goL' icon='far fa-angle-left' onClick={() => this.page('-')} />, <span key='goM' style={{ fontWeight: 'bold' }}>{msg}</span>, <OptionBtn key='goR' icon='far fa-angle-right' onClick={() => this.page('+')} />]
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.header.filterToken != this.props.header.filterToken) {
            this.setState({ fullPCSelection: [], rangeSelection: [], sites: [], selectPage: 1 }, () => this.selectOptions())
        }
    }

    handleActivityData(e) {
        var custData = { vizType: this.state.vizType, fromDate: e.fromDate, toDate: e.ToDate, selectedMetric: null, add: { analysisType: e.analysisType, alertType: e.alertType } }
        this.selectOptions(custData)
    }

    setSubFilter(filter) {
        //this.setState({ assetSubFilter: filter.assets }, () => this.selectOptions())
        this.setState({ fullPCSelection: [], rangeSelection: [], sites: [], assetSubFilter: filter.assets }, () => this.selectOptions())
    }

    changeTariff(activeTariffs) {
        this.setState({ activeTariffs })
    }

    compareTariff() {
        const { fromDate, toDate, vizType, activeTariffs, existingTariff } = this.state
        var metric = { activeTariffs, existingTariff }
        this.getAnalysisData(vizType, fromDate, toDate, metric)
    }

    readFile(e) {
        ApiPost('DataViz', 'UploadVdf', { File: e.target.files[0] })
        .then((data) => {
            this.parseVDFData(data)
        })
    }

    parseVDFData(data) {
        var metricCount = data.header.channels.length
        var rawData = data.body
        var allVdfTimes = []
        var vdfData = []
        var mGroups = []
        var dataByTime = []

        for (var i = 0; i < metricCount; i++) {
            var tValues = []
            rawData.map(x => {
                var t = moment(x.timeStamp).valueOf()
                allVdfTimes.push(t)
                var tA = [t, x.value[i]]
                tValues.push(tA)
                dataByTime[t] = x.value
            })
            vdfData.push({ columns: ['time', 'first'], name: 'esync_tagshistory', tags: { MetricId: i }, values: tValues })
        }

        data.header.channels.map((x, i) => {
            mGroups.push({ calcMethod: "Last", decimalPlaces: 3, group: x.group ? x.group : 9999, groupOrder: 3, groupRender: "Bullet", groupTitle: metricGroups[x.group], hideMetric: false, metricId: i, name: x.name, readOnly: false, unit: x.unit})
        })

        var sDate = moment(data.header.startDateTime).add(-1, 'minutes').format('DD/MM/YYYY HH:mm')
        var eDate = moment(data.header.startDateTime).add(20, 'minutes').format('DD/MM/YYYY HH:mm')
        this.props.handleAnalysisData({ data: vdfData, vdfHeader: data.header, type: 'scada', startDate: sDate, endDate: eDate, allHistoricTimes: allVdfTimes, scadaGroups: mGroups, dataByTime: dataByTime  });
    }

    render() {
        const { vizType, metricVisible, vizTypes, sites, rangeSelection } = this.state
        return (
            <div>
                <Row>
                    <Col lg={2} xs={6}>
                        <select id='reportSelector' className="form-control" value={vizType} onFocus={(e) => this.typeClick(e)} onChange={(e) => this.reportTypeChange(e)} >
                            <option value={0}>Select Type</option>
                            {vizTypes ? vizTypes.map(x => {
                                return <option key={x.dataVizId} value={x.dataVizId}>{x.dataVizName}</option>
                            }) : null}
                        </select>
                    </Col>
                    <Col lg={3} xs={5}>
                        {
                            metricVisible ?
                                <select className="form-control" onChange={this.onSelectMetric} defaultValue={this.state.selectedMetric} >
                                    {this.state.metricList}
                                </select>
                                : vizType == 1 ?
                                    <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: '6px' }}>
                                        <CheckBox name='chkbxsMeter' checked={this.state.sMeter} onChange={() => this.checkCheck('source', 'sMeter')} label='Meter' />
                                        <CheckBox name='chkbxsController' checked={this.state.sController} onChange={() => this.checkCheck('source', 'sController')} label='Controller' />
                                        <CheckBox name='chkbxsSpec' checked={this.state.sSpec} onChange={() => this.checkCheck('source', 'sSpec')} label='Spec' />
                                    </Row>
                                : vizType == 12 ?
                                    <Row>
                                        <Col xs={5}>
                                            <select className="form-control" onChange={(e) => this.setState({existingTariff:e.target.value})} defaultValue={this.state.existingTariff} >
                                                <option value={0}>Existing Tariff</option>
                                                    {this.state.existingTariffs.map((x) => {
                                                        return <option key={'ta_' + x.id} value={x.id}>{x.name}</option>
                                                    })}
                                            </select>
                                        </Col>
                                        <Col xs={5}>
                                            <Select
                                                multi
                                                onChange={(e) => this.changeTariff(e)}
                                                options={this.state.tariffs}
                                                placeholder="Comparison Tariff(s)"
                                                clearable={false}
                                                simpleValue
                                                value={this.state.activeTariffs} />
                                        </Col>
                                        <Col xs={2}>
                                            <Button color='primary' onClick={()=>this.compareTariff()}>Compare</Button>
                                        </Col>
                                    </Row>
                                : null
                        }
                    </Col>
                    <Col lg={2} xs={5} style={{ alignItems: 'center', justifyContent: 'center', marginTop: '6px' }}>
                        {vizType > 2 && vizType < 9 && this.getFilterMsg()}
                    </Col>
                    {vizType > 0 &&
                        <Col lg={5} xs={11}>
                            {vizType <= 2 || vizType == 9 ?
                                <Select
                                multi
                                onChange={this.handleSelectChange}
                                options={rangeSelection}
                                placeholder="No date ranges selected"
                                removeSelected={true}
                                clearable={false}
                                className="hide-selectable"
                                simpleValue
                                arrowRenderer={this.arrowRenderer}
                                value={sites} /> :
                                vizType == 11 ? <input type='file' id='vdfPicker' accept='.vdf' onChange={(e) => this.readFile(e)} onClick={(e) => e.target.value = null} /> :
                                <DropDatePicker key='ddtfDR' right handleDate={(fromDate, toDate) => this.handleDate(fromDate, toDate, true)} startDate={this.state.fromDate} endDate={this.state.toDate} />}
                        </Col>}
                </Row>
                <div style={{ display: this.state.modalOpen ? 'block' : 'none' }}>
                    <Col lg={{ size: 5, offset: 7 }}>
                        <div className="analysisControl" >
                            <Row>
                                <Col lg={4} xs={5}>
                                    <Input key='checktfDR' type="radio" name="raadio" defaultChecked="checked" onClick={() => this.checkCheck('time', 'tfDR')} /><Label key='lbltfDR' style={{ marginRight: '5px' }}>Date Range</Label><br />
                                    {this.state.vizType <= 2 || this.state.vizType >= 9 ? [<Input key='checktfDeg' type="radio" name="raadio" onClick={() => this.checkCheck('time', 'tfDeg')} />, <Label key='lbltfDeg' style={{ marginRight: '5px' }}>Degradation</Label>] : null}
                                </Col>
                                <Col lg={8} xs={7}>
                                    {this.state.tfDR ? <DropDatePicker key='ddtfDR' right handleDate={this.handleDate} startDate={this.state.fromDate} endDate={this.state.toDate} /> : null}
                                    {this.state.tfDeg ? [<Input key="tfDeg" min="2" type="number" step="1" style={{ width: '40px', margin: '0 5px 0 5px', padding: '0', display: 'inline' }} onChange={(e) => this.changeDeg(e)} />, <Label key="tfDegL">months</Label>] : null}
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }}>
                                <Col lg={2} />
                                <Col lg={5} xs={5}>
                                    <button type="button" key='btnCancel' className="btn btn-secondary" style={{ width: '100%', cursor: 'pointer', backgroundColor: '#dddddd', border: 'none', color: '#000' }} onClick={() => this.toggleModal()}>Cancel</button>
                                </Col>
                                <Col lg={5} xs={6}>
                                    <button type="button" key='btnSelect' className="btn btn-primary" style={{ width: '100%', cursor: 'pointer' }} onClick={() => this.selectOptions()}>Select</button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </div>
                {vizTypes && ((vizType <= 2 || vizType >= 9) && vizType != 11 && vizType != 12) && <AnalysisMenu vizTypes={vizTypes} setSubFilter={(e) => this.setSubFilter(e)} hideShowAll={this.state.hideShowAll} assets={this.props.header.assets} selectedViz={vizType} toggleMenu={(v) => this.props.toggleMenu(v)} menuVisible={this.props.menuVisible} manageExternal={this.state.manageExternal} reportTypeChange={(e) => this.reportTypeChange(e)}  /> }

            </div>
        );
    }
};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default withRouter(connect(mapStateToProps)(AnalysisController))
