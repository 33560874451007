import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ApiPost, ApiGet } from '../../../api';
import { Table } from 'reactstrap';
import HistoricDataExport from '../../../components/HistoricDataExport';

class MeteringList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            meteringInfo: null,
            selMetric: 0
        }
    }

    componentDidMount() {
        var date = new Date();
        const { stationId, dateRange, month, year } = this.props;
        const meteringInfo = { stationId, monthId: month, yearId: year, dateRange }
        ApiGet('Monitoring', 'GetMetering', meteringInfo)
        .then((data) => {
            this.setState({ meteringInfo: data })
            });

        ApiGet('Monitoring', 'GetMetrics')
        .then((data) => {
            this.setState({ stationMetrics: data });
        })
    }

    onMeterClick(id, month, year) {
        HistoricDataExport({ stationId: id, day: 0, month: month, year: year, metrics: this.state.stationMetrics, stationList: this.props.header.assets });
    }

    renderLine(readings, dayNum, monthNum, yearNum, stationId) {
        const { umets } = this.state.meteringInfo
        const { selMetric } = this.state

        if (selMetric) {
            var found = readings.find((v) => v.umetId == selMetric);
            return (<tr key={dayNum + monthNum + "-" + yearNum}>
                        <td>{dayNum != 0 ? dayNum + '-' :null}{monthNum}-{yearNum}</td>
                        <td>{found ? found.diff : '-'}</td>
                        <td>{found ? found.start : '-'}</td>
                        <td>{found ? found.end : '-'}</td>
                        <td><input type="number" /></td>
                        <td><input type="file" /></td>
                </tr>
            )
        }
        else
        {
            return (<tr key={dayNum + monthNum + "-" + yearNum}>
                        <td>{dayNum != 0 ? dayNum + '-' :null}{monthNum}-{yearNum}</td>
                        {umets.map((o,i) => {
                        var found = readings.find((v) => v.umetId == o.id);
                                return (found) ?
                                <td key={o.id} style={{ cursor: "pointer" }} onClick={o.aggType == 't' ? () => this.setState({ selMetric: o.id }) : null}>
                                    {found.diff}
                                </td> :
                                <td key={o.id}>-</td>
                            }
                        )}
                        <td style={{ cursor: "pointer" }}>
                            <button className="btn btn-default" style={{ padding: '1px 6px', marginRight: '10px' }}><i className="fa fa-download" onClick={() => this.onMeterClick(stationId, monthNum, yearNum)} /></button>
                        </td>
                </tr>
            )
        }
    }

    renderHeaders(readings) {
        const { umets } = this.state.meteringInfo
        const { selMetric } = this.state
        
        if (selMetric) {
            const ufound = umets.find((v) => v.id == selMetric);
            return (<thead><tr>
                <th>Period</th>
                <th style={{cursor:"pointer"}} onClick={() => this.setState({ selMetric: 0 })}><u>{ufound.name}</u></th>
                <th>{ufound.start} start</th>
                <th>{ufound.end} end</th>
                <th>Manual Reconcile</th>
                <th>Photo</th>
                </tr></thead>
            )
        } else {
            return (
            <thead><tr>
                <th>Period</th>
                {umets.map((o,i) => {
                        return <th style={{cursor:"pointer"}} key={o.id} onClick={o.aggType == 't' ? () => this.setState({ selMetric: o.id }) : null}>
                            {o.name}
                        </th>
                    }
                )}
                <th style={{ cursor: "pointer" }}>
                    Download
                </th>
            </tr></thead>
            )
        }
    }

    render() {
        const { assets } = this.props.header
        const { meteringInfo } = this.state
        if (meteringInfo) {
            return(
            <div style={{overflowX: 'auto'}}>
            <Table>
                {this.renderHeaders()}
                <tbody>
                    {meteringInfo.readings.map((o,i) => {
                            return this.renderLine(o.uMetricReadings, o.dayNum, o.monthNum, o.yearNum, o.stationId)
                        }
                    )}
                </tbody>
            </Table>
            </div>)
        } else {
            return "Loading..."
        }
    }
};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(MeteringList)
