import React, { Component } from 'react';
import { Alert, Button, Modal, Badge, Input, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { ApiPost,FileRequest, ApiGet } from '../../../api';
import moment from 'moment';
import Select from 'react-select';
import DropDatePicker from '../../../components/DropDatePicker';
import HistoricDataExport from '../../../components/HistoricDataExport';

export default class MeteringExportModal extends Component {

    constructor(props) {
        super(props);

        this.state = { exportGroupRate: '60m', view:0, exportValueType: 'FIRST', isExporting: false, activeTab: '4', isGMT: true,isSummary: false, stationMetrics: null, removeSelected: true, disabled: false, stayOpen: false, sites: [], errorMsg: '' };
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        this.getAllStationMetrics();
        this.setState({ selectedType: this.props.selectedType, month: this.props.month, year: this.props.year || moment().year(), day: 0 })
    }

    getExportData() {
        this.setState({ errorMsg: '' });
        var startDate = this.props.fromDate;
        var endDate = this.props.toDate;
        var exportData = [];
        this.setState({ isExporting: true });
        var siteArray = JSON.parse("[" + this.state.sites + "]");
        const { stationId, month, year, day, activeTab } = this.state;

        if (activeTab == "5") {
            var mapLength = siteArray.length;
            siteArray.map((x, i) => {
                if (i < 10) {
                    var iId = this.props.stations.find(s => s.id == x).industryId
                    var dataSettings = {
                        fromDate: moment(startDate).format('DD/MM/YY HH:mm:ss'),
                        toDate: moment(endDate).format('DD/MM/YY HH:mm:ss'),
                        exportValueType: this.state.exportValueType,
                        stationIds: x,
                        groupRate: this.state.exportGroupRate,
                        reportType: 6,
                        metricIds: this.props.favourites,
                        industryId: iId
                    }
                    ApiPost('Monitoring', 'GetHistoricScada', dataSettings)
                        .then((data) => {
                            exportData = [];
                            data.historicData.results.map(x => {
                                x.series.map(y => {
                                    exportData = exportData.concat(y);
                                })
                            })
                            this.downloadData(exportData, startDate, endDate, this.props.stations.find(y => y.id == x).name, mapLength === (i + 1) ? true : false);
                        })
                }
            })
        }
        else if (activeTab == "4") {
            if (!this.state.view > 0) {
                this.setState({ exportGroupRate: '30m' });
                var mapLength = this.props.stations.length;
                this.props.stations.map((a, i) => {
                    HistoricDataExport({ stationId: a.id, day: day, month: month, year: year, metrics: this.state.stationMetrics, stationList: this.props.stations });
                    if (mapLength === (i + 1)) {
                        this.props.toggleExportModal();
                        this.setState({ isExporting: false });
                    }
                });
            } else {
                FileRequest("Monitoring", "Get30minSummaryData", "summary.csv", { day: day, month: month, year: year, metrics: this.state.stationMetrics, stationList: this.props.stations, view: this.state.view }, (fname) => { this.props.toggleExportModal(); this.setState({ isExporting: false }); } );
            }
        }
        else {
            var date = new Date();
            var headers;
            var selectedType;
            if (activeTab == "1") {
                selectedType = 2
            }
            else if (activeTab == "2") {
                selectedType = 1
            }
            else {
                selectedType = 3
            }

            ApiGet('Monitoring', 'GetMeteringMetrics')
                .then((data) => {
                    headers = data;
                })
                .then(() => {

                    var csv;
                    this.props.stations.map((x, i) => {
                        var meteringInfo = { stationId: x.id, monthId: month, yearId: year, dayId: day, dateRange: selectedType }
                        ApiGet('Monitoring', 'GetMetering', meteringInfo)
                            .then((data) => {
                                if (data.readings.length > 0) {
                                    csv = csv + this.createMeteringExport(data, x.name, headers);
                                }
                            })
                            .then(() => {
                                if (csv) {
                                    if (i + 1 == this.props.stations.length) {
                                        let title = ["Station", "Date"];
                                        headers.map(f => {
                                            title.push(f.name);
                                        })
                                        csv = title.join(",") + "\r\n" + csv;
                                        var fileName = selectedType == 1 ? 'Monthly Metering Export ' + year : selectedType == 2 ? 'Daily Metering Export ' + moment.months(month - 1) : 'Annual Metering Export';
                                        this.downloadExportData(csv, fileName);
                                        this.setState({ errorMsg: '' });
                                    }
                                }
                                else {
                                    this.setState({ errorMsg: 'No data to export', isExporting: false });
                                }
                            })
                    })

                })
        }
    }

    downloadExportData(csv, fileName) {

        csv = csv.replace("undefined", "");

        var a = window.document.createElement("a");
        var data = new Blob([csv], { type: 'text/csv' });
        a.href = window.URL.createObjectURL(data);
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.setState({ isExporting: false });
        this.props.toggleExportModal();
    }

    downloadData(exportData, startDate, endDate, name, last) {

        var dataTimeGrouped = [];
        var csv;
        let title = ["Date"];

        exportData[0].values.map(i => {
            dataTimeGrouped[i[0]] = [];
            if (this.state.isGMT) {
                dataTimeGrouped[i[0]].push(moment.utc(i[0]).format('DD/MM/YYYY HH:mm:ss'));
            }
            else {
                dataTimeGrouped[i[0]].push(moment(i[0]).format('DD/MM/YYYY HH:mm:ss'));
            }
        })

        var intervalArray = Object.keys(dataTimeGrouped)
        exportData.map(i => {
            title.push(this.state.stationMetrics[parseInt(i.tags.MetricId)].metricName);
            var tPrev = 0
            var startIndex = intervalArray.indexOf(i.values[0][0].toString())
            var indexFix = 0, deltaSet = false
            i.values.map((x, j) => {
                var type = i.columns[1]
                if (x[1]) {
                    if (intervalArray[j + indexFix] && intervalArray[j+indexFix].toString() != x[0].toString()) {
                        console.log('problem - ' + this.state.stationMetrics[parseInt(i.tags.MetricId)].metricName, intervalArray[j + indexFix])
                        indexFix++
                        tPrev = 0
                    }
                    if (!dataTimeGrouped.hasOwnProperty(x[0])) {
                        //console.log('dataTimeGrouped', dataTimeGrouped)
                        console.log('x', x)
                    }
                    else {
                        dataTimeGrouped[x[0]].push(x[1])
                        if (type == 'first') {
                            if (deltaSet) {
                                dataTimeGrouped[x[0]].push(x[1] - tPrev)
                            }
                            else {
                                title.push(this.state.stationMetrics[parseInt(i.tags.MetricId)].metricName + ' Delta');
                                dataTimeGrouped[x[0]].push(0)
                                deltaSet = true
                            }
                            tPrev = x[1]
                        }
                    }
                }
                else {
                    dataTimeGrouped[x[0]].push('')
                }
            })
        })

        csv += title.join(",") + "\r\n";

        Object.keys(dataTimeGrouped).map(time => {
            let row = dataTimeGrouped[time].join(",");
            csv += row + "\r\n";
        })

        csv = csv.replace("undefined", "");

        var fileName = (this.props.stationDeets ? this.props.stationDeets.name : name) + ' ' + this.state.exportGroupRate + ' export ' + moment(startDate).format('DD-MM-YYYY') + ' - ' + moment(endDate).format('DD-MM-YYYY');
        var a = window.document.createElement("a");
        var data = new Blob([csv], { type: 'text/csv' });
        a.href = window.URL.createObjectURL(data);
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        if (last) {
            this.props.toggleExportModal();
            this.setState({ isExporting: false });
        }
    }

    createMeteringExport(data, stationName, headers) {
        var dataTimeGrouped = [];
        var csv;
        var cols = data.readings;
        var headerArray = [];

        headers.map(x => {
            headerArray.push(x.id);
        })

        cols.map(i => {
            var varName;
            if (i.dayNum != 0) {
                varName = i.dayNum + '-' + i.monthNum + '-' + i.yearNum;
            }
            else if (i.monthNum != 0) {
                varName = i.monthNum + '-' + i.yearNum;
            }
            else {
                varName = i.yearNum;
            }
            dataTimeGrouped[varName] = [];
            dataTimeGrouped[varName].push(varName);

            headerArray.map(l => {
                var temp = i.uMetricReadings.find(x => x.umetId == l)
                if (temp) {
                    dataTimeGrouped[varName].push(temp.diff);
                }
                else {
                    dataTimeGrouped[varName].push('');
                }
            })

        })

        Object.keys(dataTimeGrouped).map(time => {
            let row = stationName + "," + dataTimeGrouped[time].join(",");
            csv += row + "\r\n";
        })

        csv = csv.replace("undefined", "");

        return csv;
    }

    onIntervalChange(e) {
        this.setState({ exportGroupRate: e.target.value })
    }

    getAllStationMetrics() {
        ApiGet('Monitoring', 'GetMetrics')
        .then((data) => {
            this.setState({ stationMetrics: data });
        })
    }

    onTypeChange(e) {
        console.log('type', e.target.value);
        this.setState({ exportValueType: e.target.value })
    }

    onCheck() {
        this.setState({ isGMT: !this.state.isGMT });
    }

    onSummaryCheck(e) {
        this.setState({ view: e.target.value });
    }

    mapStationOptions() {
        var options = [];
        this.props.stations.map(x => {
            options.push({ label: x.name, value: x.id });
        })
        return options;
    }

    handleSelectChange(sites) {
        this.setState({ sites });
    }

    getExportIntervals() {
        var interArray = [<option key="60m" value="60m">60 mins</option>, <option key="30m" value="30m">30 mins</option>];
        var duration = moment.duration(this.props.toDate.diff(this.props.fromDate)).asHours();
        interArray.push(<option key="10m" value="10m">10 mins</option>);
        if (duration <= 768) {
            //interArray.push(<option key="10m" value="10m">10 mins</option>);
        }
        if (duration <= 192) {
            interArray.push(<option key="5m" value="5m">5 mins</option>);
            interArray.push(<option key="1m" value="1m">1 min</option>);
            interArray.push(<option key="30s" value="30s">30 Secs</option>);
            interArray.push(<option key="10s" value="10s">10 Secs</option>);
        }
        return (interArray)
    }

    navToggle(tab) {
        if (this.state.activeTab != tab) {
            this.setState({ activeTab: tab });
        }
    }

    typeChange(event, type) {
        if (type == 'type') {
            this.setState({ selectedType: event.target.value });
        }
        else if (type == 'month') {
            this.setState({ month: event.target.value });
        }
        else if (type == 'year') {
            this.setState({ year: event.target.value });
        }
        else if (type == 'day') {
            this.setState({ day: event.target.value });
        }
    }

    renderDays() {
        var r = [];
        for (var i = 1; i < 32; i++) {
            r.push(<option key={'d-' + i} value={i}>{i}</option>)
        }
        return r
    }

    renderYears() {
        var r = [];
        r.push(<option key={'y-' + moment().year()} value={moment().year()}>{moment().year()}</option>)
        r.push(<option key={'y-' + moment().subtract(1, 'y').year()} value={moment().subtract(1, 'y').year()}>{moment().subtract(1, 'y').year()}</option>)
        r.push(<option key={'y-' + moment().subtract(2, 'y').year()} value={moment().subtract(2, 'y').year()}>{moment().subtract(2, 'y').year()}</option>)
        return r
    }

    renderSampleTab() {
        return (<div>{
            this.props.sample ?
                [
                    <Row>
                        <Col sm="3">
                            <b>Sites</b>
                        </Col>
                        <Col sm="9">
                            <Select
                                closeOnSelect={!this.state.stayOpen}
                                disabled={this.state.disabled}
                                multi
                                onChange={this.handleSelectChange}
                                options={this.mapStationOptions()}
                                placeholder="Select site(s)"
                                removeSelected={this.state.removeSelected}
                                clearable={false}
                                simpleValue
                                value={this.state.sites} />
                        </Col>
                    </Row>, <br />
                    , <Row>
                        <Col sm="3">
                            <b>Date Range</b>
                        </Col>
                        <Col sm="9">
                            <DropDatePicker handleDate={this.props.handleDate} startDate={this.props.startDate} endDate={this.props.endDate} />
                        </Col>
                    </Row>, <br />] : null
        }
            <Row>
                <Col sm="3">
                    <b>Interval</b>
                </Col>
                <Col sm="9">
                    <select className="form-control" onChange={e => this.onIntervalChange(e)}>
                        {this.getExportIntervals()}
                    </select>
                </Col>
            </Row >
            <br />
            {
                !this.props.sample ?
                    [<Row>
                        <Col sm="3">
                            <b>Values</b>
                        </Col>
                        <Col sm="9">
                            <select className="form-control" onChange={e => this.onTypeChange(e)}>
                                <option key="vF" value="FIRST">First</option>
                                <option key="vL" value="LAST">Last</option>
                                <option key="vMe" value="MEAN">Avg</option>
                                <option key="vMi" value="MIN">Min</option>
                                <option key="vMa" value="MAX">Max</option>
                            </select>
                        </Col>
                    </Row>, <br />]
                    : null
            }
            <Row>
                <Col sm="3">
                    <b>GMT Time</b>
                </Col>
                <Col sm="9" style={{ paddingLeft: '35px' }}>
                    <Input type="checkbox" checked={this.state.isGMT ? 'checked' : ''} onChange={(e) => this.onCheck(e)} />
                </Col>
            </Row>
            {
                this.props.sample ?
                    <Row>
                        <Col sm="12">
                            <br />
                            <span>Please note: Exports will be saved in a seperate file per site.</span><br />
                            <span>There is a max of 10 sites per export.</span>
                        </Col>
                    </Row> : null
            }</div>);
    }

    getExportMsg(type) {
        if (type == 3) {
            return 'Daily totals and averages'
        }
        else if (type == 2) {
            return 'Monthly totals and averages'
        }
        else if (type == 1) {
            return 'Annual totals and averages'
        }
        else {
            if (this.state.view == 2) {
                return 'Summary of 30 minute Exceedances of MEC, TIC or DNC'
            }
            if (this.state.view == 0) {
                return '30 minute totals and averages for the selected month or day. Single file for each asset'
            }
            if (this.state.view == 1) {
                return '30 minute export for each site in on document'
            }
        }
    }

    renderExportTab(type) {
        return (
            <div>
                <Row>
                    <Col sm="12">
                        <div className="alert alert-secondary fade show">{this.getExportMsg(type)}</div>
                    </Col>
                </Row>
                {type > 1 ?
                    <Row>
                        <Col sm="3">
                            <b>Year</b>
                        </Col>
                        <Col sm="9">
                            <select className="form-control" defaultValue={this.props.year} onChange={(e) => this.typeChange(e, 'year')} >
                                {this.renderYears()}
                            </select>
                        </Col>
                    </Row>
                    : null}
                <br />
                {type > 2 ?
                    <Row>
                        <Col sm="3">
                            <b>Month</b>
                        </Col>
                        <Col sm="9">
                            <select className="form-control" defaultValue={this.props.month} onChange={(e) => this.typeChange(e, 'month')} >
                                <option key={'m-0'} value={0}>All</option>
                                <option key={'m-1'} value={1}>January</option>
                                <option key={'m-2'} value={2}>February</option>
                                <option key={'m-3'} value={3}>March</option>
                                <option key={'m-4'} value={4}>April</option>
                                <option key={'m-5'} value={5}>May</option>
                                <option key={'m-6'} value={6}>June</option>
                                <option key={'m-7'} value={7}>July</option>
                                <option key={'m-8'} value={8}>August</option>
                                <option key={'m-9'} value={9}>September</option>
                                <option key={'m-10'} value={10}>October</option>
                                <option key={'m-11'} value={11}>November</option>
                                <option key={'m-12'} value={12}>December</option>
                            </select>
                        </Col>
                    </Row >
                    : null}
                <br />
                {type > 3 ?
                    <Row>
                        <Col sm="3">
                            <b>Day</b>
                        </Col>
                        <Col sm="9">
                            <select className="form-control" onChange={(e) => this.typeChange(e, 'day')} >
                                <option key={'day-0'} value={0}>All</option>
                                {this.renderDays()}
                    </select>
                        </Col>
                    </Row> : null}
                <br />
            </div>)
    }

    render() {
        return (
            <Modal isOpen={true} autoFocus={false} style={{ maxWidth: '515px' }}>
                <ModalHeader>Export Data</ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.navToggle('4'); }}>
                                30  Minute
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.navToggle('1'); }}>
                                Daily
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.navToggle('2'); }}>
                                Monthly
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.navToggle('3'); }}>
                                Annual
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.navToggle('5'); }}>
                                Export Meter Readings
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <br />{this.renderExportTab(3)}

                        </TabPane>
                        <TabPane tabId="2">
                            <br />{this.renderExportTab(2)}
                        </TabPane>
                        <TabPane tabId="3">
                            <br />{this.renderExportTab(1)}
                        </TabPane>
                        <TabPane tabId="4">
                            <br />{this.renderExportTab(4)}
                            <Row>
                                <Col sm="3">
                                    <b>Report</b>
                                </Col>
                                <Col sm="9">
                                    <select className="form-control" defaultValue={this.props.view} onChange={(e) => this.onSummaryCheck(e)} >
                                        <option key={'m-0'} value={0}>HHD Seperate Documents</option>
                                        <option key={'m-1'} value={1}>Export HHD, All sites one Spreadsheet</option>
                                        <option key={'m-2'} value={2}>Exceedances Overview</option>
                                    </select>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="5">
                            <br />{this.renderSampleTab()}
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" style={{ marginRight: 'auto' }} onClick={() => this.props.toggleExportModal()}>Cancel</Button>
                    <b style={{ color: '#d0021b' }}>{this.state.errorMsg}</b>
                    <Button color="primary" onClick={() => this.getExportData()}>Export {this.state.isExporting ? <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} /> : null}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
