import React, { Component } from 'react';
import ScadaChart from './ScadaChart';
import ScadaCollapsibles from './ScadaCollapsibles';
import ScadaTagMgmt from './ScadaTagMgmt';
import ScadaExportModal from './ScadaExportModal';
import ScadaLogs from './ScadaLogs';
import { ToggleSwitch } from '../../../components/Controls/ToggleSwitch';
import classnames from 'classnames'
import { ApiGet, ApiPost, GetStationLiveState, getConfigItem} from '../../../api';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import moment from 'moment';
import { updateLiveConnection, toggleLiveConnection, disconnectLive, scadaSendCommand, SCADA_COMMANDS  } from '../../../actions/scada';
import ScadaActions from './ScadaActions'
import VestasHeader from './VestasHeader'
import ScadaSchematic from './ScadaSchematic'
import ScadaDash from './ScadaDash'
import AlertPicker from './AlertPicker'
import ParameterContainer from '../../Parameters/components/ParameterContainer'
import SiteDetails from './SiteDetails'

class Scada extends Component {

    constructor(props) {
        super(props);
        var defaultMetrics = getConfigItem('$.monitoring.scadaDefaultUMetrics')
        var tabOrder = getConfigItem('$.monitoring.scadaTabOrder')
        var enableSiteDetails = getConfigItem('$.monitoring.enableSiteDetails')
        if (this.props.location && this.props.location.state && this.props.location.state.metricId) {
            //defaultMetrics.push(this.props.location.state.metricId)
        }
        this.state = {
            retryConnection:false,retrying:false, isLive: true, scadaData: null, gotGroups: false, recentAlerts: null, tagMgmtOpen: false, renderInfo: null,
            lastMetricVals: null, futureMetricVals: null, editMode: false, mgmtMetric: null, defaultMetric: null, exportOpen: false, connectionState: null, 
            fromDate: null, toDate: null, defaultMetrics, alertMetrics: [3], favourites: [], allHistoricTimes: [], maxMetricVals: [], minMetricVals: [], binaryMetrics: [241, 242, 243, 308, 309, 310, 311],
            reconnecting: false, activeTab: '1', tabOrder, navData: null, deviceList: null, config: null, metricMap: null, defaultView: enableSiteDetails ? 1 : null
        }
        //defaultView 1 = Monitoring, 2 = Site Details
    }

    componentDidMount() {
        this.initiateScada()
    }

    initiateScada() {
        this.stationDet = null
        this.stationId = this.props.match ? parseInt(this.props.match.params.stationid) : 1
        this.dataByTime = []
        if (this.props.commonstate.header.assets.length > 0) {
            this.getDefaultMetrics()
        }
    }

    getDefaultMetrics() {
        var defaultMetrics = {}
        var mainStation = this.props.commonstate.header.assets.find(x => x.id == this.stationId)
        var deviceList = [mainStation]
        if (this.props.commonstate.header.childDevices[this.stationId]) {
            this.props.commonstate.header.childDevices[this.stationId].map(x => {
                deviceList.push(x)
            })
        }

        ApiGet('Monitoring', 'GetMonitoringConfigs')
        .then((data) => {
            deviceList.map(device => {
                if (device.deviceTypeId) {
                    defaultMetrics[device.id] = data.configs[device.deviceTypeId].metrics
                }
                else {
                    defaultMetrics[device.id] = getConfigItem('$.monitoring.scadaDefaultUMetrics')
                }
            })
            this.setState({ defaultMetrics, deviceList, config: data.configs }, () => this.getRenderInfo())
        })
    }

    componentWillUnmount() {
        this.props.updateLiveConnection([]);
    }

    toggleGetGroups() {
        this.setState({ gotGroups: !this.state.gotGroups });
    }

    toggleEditMode() {
        this.setState({ editMode: !this.state.editMode });
    }

    tryReconnect() {
        this.setState({reconnecting: true})
        this.getLiveData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.scada.connectionState == 'disconnected' && !this.state.reconnecting && !this.props.scada.timeOut) {
            console.log('scada trying reconnect', this.props.scada)
            this.tryReconnect()
        }
        else if (this.props.scada.connectionState != 'disconnected' && this.state.reconnecting) {
            this.setState({ reconnecting: false })
        }
        if ((this.props.commonstate.header.childDevices != prevProps.commonstate.header.childDevices) && (this.state.deviceList == null)) {
            this.getDefaultMetrics()
        }
        if (prevProps.match.params.stationid != this.props.match.params.stationid) {
            this.props.history.push('/Scada/' + this.props.match.params.stationid)
            this.props.history.go(0)
        }

        this.stationDet = GetStationLiveState(this.props.commonstate, this.stationId)
        if (this.state.isLive && this.stationDet) {
            if (this.stationDet.metrics && this.stationDet.metrics !== this.state.scadaData) {
                Object.keys(this.stationDet.metrics).map(i => {
                    if (this.state.binaryMetrics.includes(i)) {
                        var value = '';
                        var wsValue = this.stationDet.metrics[i];
                        var bin = ("0".repeat(16) + (wsValue >>> 0).toString(2));
                        bin = bin.substr(bin.length - 16, 16);

                        this.state.renderInfo.renderInfo.map(x => {
                            if (x.metricId == i) {
                                value = '';
                                value = bin.substr(15 - (x.lookupCode - 1), 1);
                                this.stationDet.metrics[i + '-' + x.lookupCode] = parseInt(value);
                            }
                        })
                    }
                    this.setMaxMin(i)
                })
                this.setState({ scadaData: this.stationDet.metrics })
            }
        }

        if (this.props.scada.connectionState == 'error' && this.state.connectionState == null && this.state.scadaData != null) {
            this.setState({ connectionState: 'error', retryConnection: true });
        }
        else if (this.props.scada.connectionState == 'disconnected' && this.state.connectionState == null && this.state.scadaData != null) {
            this.setState({ connectionState: 'disconnected', retryConnection: true });
        }
    }

    setMaxMin(metricId, metricVal) {
        var tempMax = this.state.maxMetricVals;
        var tempMin = this.state.minMetricVals;

        if (this.state.isLive) {
            metricVal = this.stationDet.metrics[metricId];
        }

        if (tempMax[metricId] != null) {
            if (tempMax[metricId] < metricVal) {
                tempMax[metricId] = metricVal;
                this.setState({ maxMetricVals: tempMax });
            }
        }
        else {
            tempMax[metricId] = metricVal;
            this.setState({ maxMetricVals: tempMax });
        }

        if (tempMin[metricId] != null) {
            if (tempMin[metricId] > metricVal) {
                tempMin[metricId] = metricVal;
                this.setState({ minMetricVals: tempMin });
            }
        }
        else {
            tempMin[metricId] = metricVal;
            this.setState({ minMetricVals: tempMin });
        }
    }

    getLiveData() {
        const { station } = this.props;
        let siteids = [this.stationId];
        this.props.updateLiveConnection(siteids);
        this.setState({retrying:true, retryConnection: false})
        setTimeout(() => this.checkLiveData(), 15000);
    }

    checkLiveData() {
        console.log('CheckLiveData', this.state)
        if (this.state.isLive && !this.state.scadaData && !this.state.connectionState) {
            this.setState({ connectionState: 'error', retryConnection: true, retrying:false });
            this.props.updateLiveConnection([]);
        } else {
            this.setState({ retrying:false });
        }
    }

    getScadaGroups(groupType, metricList) {

        if (metricList) {
            metricList = JSON.stringify(metricList).replace('[', '').replace(']', '')
        }

        var scadaGroup = {
            groupType: groupType,
            metricList: metricList,
            stationId: this.stationId
        }

        console.log('metricList', metricList)

        ApiGet('Monitoring', 'GetScadaGroups', scadaGroup)
            .then((data) => {
                var tempGroups = []
                var tempFavs = {}
                var metricMap = []
                Object.keys(this.state.defaultMetrics).map(dId => {
                    var deviceGroups = data.scadaGroups[dId]
                    var deviceUMetrics = []
                    var deviceDefaultMetrics = this.state.defaultMetrics[dId]
                    deviceDefaultMetrics.map(m => {
                        if (deviceGroups && deviceGroups.find(x => x.uMetId == m)) {
                            deviceUMetrics.push(deviceGroups.find(x => x.uMetId == m).metricId)
                        }
                    })
                    tempFavs[dId] = deviceUMetrics
                    if (deviceGroups) {
                        deviceGroups.map(dg => {
                            if (dg.uMetId) {
                                if (!metricMap[dId]) {
                                    metricMap[dId] = []
                                }
                                metricMap[dId][dg.uMetId] = dg.metricId
                            }
                        })
                    }
                })

                this.state.renderInfo.renderInfo.map(x => {
                    if (tempGroups[x.metricId] && this.state.binaryMetrics.includes(x.metricId)) {
                        tempGroups.push( { 'metricId': x.metricId + '-' + x.lookupCode, 'name': x.desc.replace('!','').replace('*',''), 'group': 9999, 'decimalPlaces' : 0 });
                    }
                })
                this.setState({ scadaGroups: data.scadaGroups, gotGroups: true, favourites: tempFavs, metricMap });
            })
    }

    getRecentAlerts() {
        var alertStations = {
            stationId: this.stationId
        }
        ApiGet('Monitoring', 'GetRecentAlerts', alertStations)
        .then((data) => {
            this.setState({ recentAlerts: data });
        })
    }

    getRenderInfo() {
        ApiGet('Monitoring', 'GetRenderInfo')
        .then((data) => {
            this.setState({ renderInfo: data });
        })
        .then(() => {
            if (this.props.analysisData) {
                this.setState({ scadaGroups: this.props.analysisData.scadaGroups, gotGroups: true });
            }
            else {
                this.getScadaGroups('Live');
            }
        })
        .then(() => {
            this.getRecentAlerts();
        })
    }

    getVDFData() {
        this.dataByTime = this.props.analysisData.dataByTime;

        this.setState({
            scadaData: this.props.analysisData.data,
            fromDate: this.props.analysisData.startDate,
            toDate: this.props.analysisData.endDate,
            allHistoricTimes: this.props.analysisData.allHistoricTimes,
            favourites: [0, 4]
        }, () => this.refs.ScadaChart.setHistoricGraphData(true))
    }

    getTestStationId(id) {
        if (id == 138) {
            return 73
        }
        else if (id == 139) {
            return 74
        }
        else if (id == 137) {
            return 72
        }
        else if (id == 140) {
            return 75
        }
        else {
            return id
        }
    }

    getHistoricData(fromDate, toDate, getFuture, getNavOnly, favsOnly = true)
    {
        if (favsOnly) {
            console.log('** GET FAVS DATA **')
        }
        else {
            console.log('** GET ALL HISTORIC DATA **')
        }
        var allHistoricData = {}
        var startDate = fromDate
        var endDate = toDate
        var groupRate = '1m'
        var navGroupRate = '1m'
        var nowDate = null

        if (fromDate != null && toDate != null)
        {
            var timeDiff = Math.round(endDate.diff(startDate, 'minutes')/500);
            groupRate = timeDiff + 'm';
            navGroupRate = (timeDiff * 2) + 'm'

            if (groupRate == '0m'){
                groupRate = '10s'
                navGroupRate = '10s'
            }
        }
        else
        {
            startDate = moment(startDate).add(-1, 'days');
            endDate = new Date().getTime();
            groupRate = '5m';
        }

        var calcMethod = 'Last';
        var metricId;

        if (getNavOnly) {
            this.getNavData(startDate, endDate, nowDate, navGroupRate)
        }
        else {
            var iId = this.props.commonstate.header.assets.find(a => a.id == this.stationId).industryId

            var deviceList = [this.stationId]
            if (this.props.commonstate.header.childDevices[this.stationId]) {
                this.props.commonstate.header.childDevices[this.stationId].map(x =>{
                    deviceList.push(x.id)
                })
            }

            var dCount = 0;
            deviceList.map(deviceId => {
                var dataSettings = {
                    fromDate: startDate.format('DD/MM/YY HH:mm:ss'),
                    toDate: endDate.format('DD/MM/YY HH:mm:ss'),
                    stationIds: deviceId,
                    groupRate: groupRate,
                    reportType: 2,
                    metricIds: favsOnly ? this.state.favourites[deviceId] : deviceId != this.stationId ? this.state.favourites[deviceId] : [],
                    industryId: iId
                }
                ApiPost('Monitoring', 'GetHistoricScada', dataSettings)
                .then((data) => {
                    if (favsOnly) {
                        console.log('** GOT FAVS DATA ** ' + deviceId)
                        dCount++;
                    }
                    else {
                        console.log('** GOT ALL HISTORIC DATA ** ' + deviceId)
                    }
                    allHistoricData[deviceId] = data.historicData.results && data.historicData.results[0].series
                    //if (this.state.allHistoricTimes.length == 0) {
                        var tAllHistoricTimes = []
                        if (allHistoricData[deviceId]) {
                            allHistoricData[deviceId][0].values.map(i => {
                                tAllHistoricTimes.push(parseInt(i[0]))
                                this.dataByTime[i[0]] = {}
                            })
                            this.setState({ allHistoricTimes: tAllHistoricTimes })
                        }
                    //}
                })
                .then(() => {
                    if (allHistoricData[deviceId]) {
                        allHistoricData[deviceId].map(i => {
                            metricId = parseInt(i.tags.MetricId)
                            calcMethod = this.getMetricCalc(metricId, deviceId)
                            if (calcMethod == "Last") {
                                this.setLastInterpolation(metricId, i.values, getFuture, deviceId)
                            }
                            else if (calcMethod == "Avg") {
                                this.setAvgInterpolation(metricId, i.values, getFuture, deviceId)
                            }
                        })
                    }
                })
                .then(() => {
                    if (favsOnly) {
                        this.setState({ scadaData: allHistoricData, fromDate: startDate, toDate: endDate })
                        //this.setState({ scadaData: allHistoricData, fromDate: startDate, toDate: endDate }, () => this.getHistoricData(startDate, endDate, false, false, false))

                        if (dCount == deviceList.length) {
                            console.log('set historic', deviceList.length)
                            this.refs.ScadaChart.setHistoricGraphData()
                        }
                    }
                    else {

                        this.setState({ scadaData: allHistoricData })
                    }
                })
                if (deviceId == this.stationId && favsOnly) {
                    this.getNavData(startDate, endDate, nowDate, navGroupRate) //GET NAV BAR FOR MAIN DEVICE
                }
            })
            if (favsOnly) {
                this.getHistoricData(startDate, endDate, false, false, false)
            }

        }

    }

    getNavData(startDate, endDate, nowDate, groupRate) {
        var nStart = new moment(startDate),
            nEnd = new moment(endDate),
            nDif = moment.duration(nEnd.diff(nStart)).asMinutes()

        nowDate = new moment()
        var fDate = nStart.subtract(nDif * 1, 'minutes')
        var tDate = nEnd.add(nDif * 1, 'minutes')
        var tNow = moment(nowDate).subtract(5, 'minutes')

        if (tDate.isAfter(tNow)) {
            tDate = tNow
        }
        else {
            tDate = tDate
        }

        var deviceType = this.state.deviceList.find(x => x.id == this.stationId).deviceTypeId
        var configMetric = this.state.config[deviceType]
        var navMetric = 7
        var stationLookup = this.stationId
        if (configMetric && configMetric.chartNavMetric) {
            navMetric = this.state.metricMap[stationLookup][configMetric.chartNavMetric]
        }
        if (deviceType == null && this.state.deviceList.filter(x => x.id != this.stationId)[0]) {
            stationLookup = this.state.deviceList.filter(x => x.id != this.stationId)[0].id
            deviceType = this.state.deviceList.find(x => x.id == stationLookup).deviceTypeId
            configMetric = this.state.config[deviceType]
            navMetric = this.state.metricMap[stationLookup][configMetric.chartNavMetric]
        }

        var dataNavSettings = {
            fromDate: fDate.format('DD/MM/YY HH:mm:ss'),
            toDate: tDate.format('DD/MM/YY HH:mm:ss'),
            stationIds: stationLookup,
            metricIds: navMetric,
            groupRate: groupRate,
            reportType: 1,
            exportValueType: 'AUTO',
        }
        ApiPost('Monitoring', 'GetHistoricScada', dataNavSettings)
        .then((data) => {
            if (data.historicData.results && data.historicData.results[0].series) {
                this.setState({ navData: data.historicData.results[0].series[0].values })
            }
        })
    }

    setDifferenceInterpolation(metricId, metricVals, deviceId) {
        metricVals.map((sv, i, arr) => {
            if (this.dataByTime[parseInt(sv[0])]) {
                if (this.dataByTime[parseInt(sv[0])][deviceId] == null) {
                    this.dataByTime[parseInt(sv[0])][deviceId] = {}
                }
                var diffVal = 0
                if (arr[i - 1] && arr[i - 1][1]) {
                    diffVal = (sv[1] - arr[i - 1][1])
                }
                this.dataByTime[parseInt(sv[0])][deviceId][metricId] = diffVal
            }
        })
    }

    setLastInterpolation(metricId, metricVals, getFuture, deviceId) {
        metricVals.map((sv) => {
            if (this.dataByTime[parseInt(sv[0])]) {
                if (this.dataByTime[parseInt(sv[0])][deviceId] == null) {
                    this.dataByTime[parseInt(sv[0])][deviceId] = {}
                }
                this.dataByTime[parseInt(sv[0])][deviceId][metricId] = sv[1]
            }
        })
    }

    setAvgInterpolation(metricId, metricVals, getFuture, deviceId) {
        var lastArray = (metricVals.length - 1)
        var startVal = null
        var endVal, endIndex
        
        metricVals.map((sv, i) => {
            if (this.dataByTime[parseInt(sv[0])]) {
                var endValSet = false
                if (sv[1] != null) {
                    startVal = sv[1]
                    //GET NEXT VALID POINT
                    metricVals.map((next, index) => {
                        if (next[1] != null && index > i && !endValSet) {
                            endVal = next[1]
                            endIndex = index
                            endValSet = true
                        }
                    })
                }
                else if (startVal) {
                    var newVal = startVal + (endVal - startVal) * (i / lastArray)
                    sv[1] = newVal
                }
                else {
                    sv[1]
                }

                if (this.dataByTime[parseInt(sv[0])]) {
                    if (this.dataByTime[parseInt(sv[0])][deviceId] == null) {
                        this.dataByTime[parseInt(sv[0])][deviceId] = {}
                    }
                    this.dataByTime[parseInt(sv[0])][deviceId][metricId] = sv[1]
                }
            }
        })
    }

    getDataAtTime(origX) {
        
        for (var i = 0; i < this.state.allHistoricTimes.length; i++) {
            if (this.state.allHistoricTimes[i] > origX) {
                this.newX = this.state.allHistoricTimes[i];
                break;
            }
            if (this.newX != this.prevX)
            {
                this.prevX = this.newX;
                this.forceUpdate();
            }
        }
    }

    toggleMetricHide(stationTagId) {

        var tempGroup = this.state.scadaGroups;

        tempGroup.map(x => {
            if (x.stationTagId == stationTagId) {
                x.hideMetric = !x.hideMetric
            }
        })

        this.setState({ scadGroups: tempGroup });

        const metricInfo = { stationTagId: stationTagId }
        ApiPost('Monitoring', 'ToggleHiddenMetric', metricInfo)
        .then((data) => {
            if (data) {
                console.log('metricToggled', data);
            }
        })
    }

    toggleFavMetric(metricId, deviceId)
    {
        console.log('this.state.favourites', this.state.favourites)
        if (this.state.favourites[deviceId].includes(metricId))
        {
            if (this.state.favourites[deviceId].length > 1) {
                var tFavs = this.state.favourites, tDFavs = tFavs[deviceId]
                tDFavs = tDFavs.filter(x => x !== metricId)
                tFavs[deviceId] = tDFavs
                this.setState({favourites: tFavs})

                this.refs.ScadaChart.removeChartSeries(metricId, deviceId)
            }
            else {
                console.log('at least 1 metric required')
            }      
        }
        else
        {
            if (this.state.isLive) {
                this.setState({ favourites: { [deviceId]: this.state.favourites[deviceId].concat(metricId)} });
            }
            else {
                if (this.state.scadaData[deviceId].find(x => x.tags.MetricId == metricId.toString())) {
                    var tFavs = this.state.favourites
                    tFavs[deviceId].push(metricId)
                    this.setState({ favourites: tFavs }, () => this.refs.ScadaChart.setHistoricGraphData(false))
                }
                else {
                    console.log('no data exists for metric')
                }
            }
        }
    }

    toggleLive(toggleLive) {

        this.setState({ scadaData: null });

        if (toggleLive) {
            this.setState({ isLive: true, connectionState: null, retryConnection: false }, this.getLiveData());
        }
        else {
            this.props.updateLiveConnection([]);
            this.setState({ isLive: false, retryConnection: false  });
        }
    }

    getMetricCalc(metricId, deviceId) {
        var name = ''
        var dMetrics = this.state.scadaGroups[deviceId]
        var n = dMetrics.find(x => x.metricId == metricId)
        if (n) {
            name = n.calcMethod
        }
        return name
    }

    getMetricName(metricId, deviceId) {
        var name = ''
        var dMetrics = this.state.scadaGroups[deviceId]
        var n = dMetrics.find(x => x.metricId == metricId)
        if (n) {
            name = n.name
        }
        return name
    }

    getMetricDef(metricId, deviceId) {
        var def = null
        var dMetrics = this.state.scadaGroups[deviceId]
        var n = dMetrics.find(x => x.metricId == metricId)
        if (n) {
            def = n
        }
        return def
    }

    getMetricUnit(metricId, deviceId) {
        var name = ''
        var dMetrics = this.state.scadaGroups[deviceId]
        var n = dMetrics.find(x => x.metricId == metricId)
        if (n) {
            name = n.unit || ''
        }
        return name
    }

    getScadaValue(metricId, decimalPlaces = 0, deviceId) {
        var value = '-'

        if (this.state.isLive) {
            if (this.state.scadaData) {
                var wsValue = this.state.scadaData[metricId];
                if (wsValue != undefined && !isNaN(wsValue)) {
                    if (decimalPlaces < 0) {
                        decimalPlaces = 0
                    }
                    value = wsValue.toFixed(decimalPlaces);
                }   
            }
        }
        else if (!this.state.isLive && this.dataByTime && this.newX != undefined) {
            if (decimalPlaces == -1) {
                decimalPlaces = 0
            }
            if (this.dataByTime[this.newX] && this.dataByTime[this.newX][deviceId] && this.dataByTime[this.newX][deviceId][metricId] != null) {
                value = this.dataByTime[this.newX][deviceId][metricId].toFixed(decimalPlaces)
            }
        }

        return value
    }

    toggleTagMgmt(metricInfo) {
        this.setState({ tagMgmtOpen: !this.state.tagMgmtOpen, mgmtMetric: metricInfo})
    }

    toggleExportModal() {
        this.setState({ exportOpen: !this.state.exportOpen})
    }  

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    renderEditMode() {
        if (this.props.commonstate.users && this.props.commonstate.users.authRole && this.props.commonstate.users.authRole.length > 0) {
            if (this.props.commonstate.users.authRole.includes("Administrator"))
            return (<ToggleSwitch smallMode={true} switchLabel="Edit Mode" onToggle={() => this.toggleEditMode()} />)
        }
        else {
            return null
        }
    }

    renderActionCollapsible(stationDet) {
        if (this.props.commonstate.users && this.props.commonstate.users.authRole && this.props.commonstate.users.authRole.length > 0 && stationDet.id) {
            if (this.props.commonstate.users.authRole.includes("Internal"))
                return (<ScadaActions alerts={stationDet.alerts} metrics={stationDet.metrics} stationDeets={stationDet} scadaGroups={this.state.scadaGroups} toggleFavMetric={(e, d) => this.toggleFavMetric(e, d)} getScadaValue={(mId, dP, dId) => this.getScadaValue(mId, dP, dId)} getMetricUnit={(metricId, deviceId) => this.getMetricUnit(metricId, deviceId)} getMetricName={(metricId, deviceId) => this.getMetricName(metricId, deviceId)} favourites={this.state.favourites} cmdSuccess={this.props.scada.actCmdSuc} />)
        }
        else {
            return null
        }
    }

    renderAlertMgmt(station) {
        return <AlertPicker station={station} />
    }

    defaultViewSelector() {
        var df = this.state.defaultView
        if (df) {
            return (<div id='viewSelector'>
                <span style={{ fontWeight: df == 1 && 'bold', cursor: 'pointer' }} onClick={() => this.setState({ defaultView: 1 })} >Monitoring</span>
                <span style={{padding:'0 5px'}}>|</span>
                <span style={{ fontWeight: df == 2 && 'bold', cursor: 'pointer' }} onClick={() => this.setState({ defaultView: 2 })} >Details</span>
            </div>)
        }
    }

    renderTabs(sDeets) {
        var tabDict = { 1: <i className='fal fa-table' title='Metrics' />, 2: <i className='fal fa-sitemap' title='Schematic' />, 3: <i className='fal fa-list-ol' title='Logs' />, 4: <i className='fal fa-sliders-v' title='Parameters' />, 5: <i className='fal fa-analytics' title='Performance' /> }
        var station = this.props.commonstate.header.assets.find(x => x.id == this.stationId)
        //console.log('station', station)
        var tabSpec = {
            //1: {content: <div/> },
            1: { content: <ScadaCollapsibles ref="ScadaCollapsibles" station={station} config={this.state.config} stationId={this.stationId} cmdState={this.stationDet ? this.stationDet.cmdState : []} {...this.state} toggleFavMetric={(e,d) => this.toggleFavMetric(e,d)} toggleMetricHide={(e) => this.toggleMetricHide(e)} getScadaValue={(mId, dP, dId, isUId) => this.getScadaValue(mId, dP, dId, isUId)} getMetricUnit={(id) => this.getMetricUnit(id)} getMetricName={(id) => this.getMetricName(id)} toggleTagMgmt={(metricInfo) => this.toggleTagMgmt(metricInfo)} /> },
            2: { content: <ScadaSchematic stationId={this.stationId} devices={this.props.commonstate.header.assets} tags={this.state.scadaGroups} getScadaValue={(mId, dP, dId) => this.getScadaValue(mId, dP, dId)} sInfo={sDeets} /> },
            3: { content: <ScadaLogs siteid={this.stationId} />, validController: [1,7]},
            4: { content: <ParameterContainer stationId={this.stationId} controllerId={station.controllerid}/> },
            5: { content: <div><ScadaDash stationId={this.stationId} metrics={this.state.scadaGroups} period={2} /><ScadaDash stationId={this.stationId} metrics={this.state.scadaGroups} period={1}/></div> }
        }
        var tTabs = []
        var tPanes = []
        this.state.tabOrder.forEach((x, i) => {
            if (!tabSpec[x].validController || (tabSpec[x].validController && tabSpec[x].validController.includes(sDeets.controllerid))){
                tTabs.push(
                    <NavItem key={'nv'+i}>
                        <NavLink className={classnames({ active: this.state.activeTab === (i + 1).toString() })} onClick={() => { this.toggleTab((i + 1).toString()) }}>{tabDict[x]}</NavLink>
                    </NavItem>
                )
                if (this.state.activeTab === (i + 1).toString()) {
                    tPanes.push(
                        <TabPane key={'tp' + i} tabId={(i + 1).toString()}>
                            {tabSpec[x].content}
                        </TabPane>
                    )
                }
            }
        })
        return (
            <div>
                <div className='rightTabs'>
                    <Nav tabs key='scadaTabs'>
                        {tTabs}
                    </Nav>
                </div>
                <TabContent key='casesTabs' activeTab={this.state.activeTab} style={{minHeight:'400px'}}>
                    {tPanes}
                </TabContent>
            </div>
            )
    }

    render() {
        const stationDet = GetStationLiveState(this.props.commonstate, this.stationId);
        const constate = this.state.retrying ? null : this.props.scada.connectionState ? this.props.scada.connectionState : this.state.connectionState;
        const { isLive, favourites, navData, scadaData, fromDate, toDate, defaultMetric, recentAlerts, scadaGroups, retryConnection, defaultView} = this.state
        const chartProps = {
            connectionState: constate,
            getHistoricData: (fD, tD, gF, gNO) => this.getHistoricData(fD, tD, gF, gNO),
            toggleFavMetric: (mId, dId) => this.toggleFavMetric(mId,dId),
            getScadaValue: (mI, dP, dId) => this.getScadaValue(mI, dP, dId),
            getMetricUnit: (metricId, deviceId) => this.getMetricUnit(metricId, deviceId),
            getMetricName: (metricId, deviceId) => this.getMetricName(metricId, deviceId),
            getMetricDef: (metricId, deviceId) => this.getMetricDef(metricId, deviceId),
            getDataAtTime: (origX) => this.getDataAtTime(origX),
            toggleLive: (tL) => this.toggleLive(tL),
            toggleGetGroups: () => this.toggleGetGroups(),
            toggleExportModal: () => this.toggleExportModal(),
            analysisData: this.props.analysisData,
            getVDFData: this.props.analysisData && this.getVDFData(),
            isLive,
            favourites,
            navData,
            scadaData,
            fromDate,
            toDate,
            defaultMetric,
            recentAlerts,
            scadaGroups,
            retryConnection,
            devices: this.props.commonstate.header.assets,
            deviceId: this.stationId
        }
        return ([
            !this.props.hideHeader ?
                <div key='con1' className="d-flex justify-content-end" style={{ borderBottom: '1px solid rgba(99, 99, 99, 0.2)' }}>
                    <div className="mr-auto p-2">
                        <h3>Monitoring - {stationDet.name}</h3>
                        <h4>{stationDet.model ? stationDet.model : "-"}</h4>
                    </div>
                    <div className="ml-auto p-2">
                        <div style={{ marginRight: '25px' }}>
                            {this.renderEditMode()}
                        </div>
                        {this.defaultViewSelector()}
                    </div>

                </div> : null,
            this.props.analysisData && this.props.analysisData.vdfHeader && <VestasHeader key='vdfHead' vdfHeader={this.props.analysisData.vdfHeader} />,
            <div key='scadaContain' className="container-fluid" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                {(defaultView == 1 || defaultView == null) ?
                    <div className="accordionCards">

                        <ScadaChart ref="ScadaChart" {...chartProps} />
                        {this.renderActionCollapsible(stationDet)}
                        {stationDet.id && this.renderAlertMgmt(stationDet)}
                        {stationDet.id && this.renderTabs(stationDet)}

                        {this.state.tagMgmtOpen ? <ScadaTagMgmt mgmtMetric={this.state.mgmtMetric} stationDeets={stationDet} metrics={scadaGroups} editMode={this.state.editMode} isOpen={this.state.tagMgmtOpen} toggleTagMgmt={() => this.toggleTagMgmt()} /> : null}

                        {this.state.exportOpen ? <ScadaExportModal {...this.state} stationId={this.stationId} stationDeets={stationDet} getMetricName={(id) => this.getMetricName(id)} authRole={this.props.commonstate.users.authRole} toggleExportModal={() => this.toggleExportModal()} /> : null}

                    </div>
                    :
                    <div>
                        <SiteDetails station={stationDet} />
                    </div>
                }
            </div>
        ])
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common, scada: state.common.scada }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateLiveConnection: (siteList) => {
            dispatch(updateLiveConnection(siteList));
        },
        disconnectLive: () => {
            dispatch(disconnectLive());
        },
        sendCommand: (stationId, Command) => {
            dispatch(scadaSendCommand(stationId,Command));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Scada)
