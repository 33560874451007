import React, { Component } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import { ApiPost, ApiGet } from '../api'
import ReactTooltip from 'react-tooltip'
import Alert from 'react-s-alert'

export default class DeviceTagPicker extends Component {

    constructor(props) {
        super(props);
        this.state = { clientTags: [], deviceTags: []  }
    }

    componentDidMount() {
        this.getTags()
    }

    getTags() {
        ApiGet('Device/GetDeviceTags', this.props.deviceId)
        .then((data) => {
            console.log('data',data)
            this.setState({ clientTags: data.clientTags, deviceTags: data.deviceTags })
        })
    }

    deleteDeviceTag(i) {
        var tagId = this.state.deviceTags[i].id
        ApiPost('Device', 'DeleteTag', { tagId, deviceId: this.props.deviceId })
        .then((data) => {
            this.setState({ deviceTags: data })
        })
    }

    createDeviceTag(tag) {
        ApiPost('Device', 'AddTag', { tagId: tag.id, deviceId: this.props.deviceId })
        .then((data) => {
            this.setState({ deviceTags: data })
            Alert.info('Tag "' + tag.name + '" added')
        })
    }

    render() {
        const KeyCodes = { comma: 188, enter: 13, }
        const delimiters = [KeyCodes.comma, KeyCodes.enter]
        return (
            <div style={{ height: '150px' }}>
                <ReactTooltip />
                <i className='fa fa-info-circle' data-tip='Press return to add a new tag' style={{ position:'absolute', zIndex:1, right:'20px', top: '10px' }} />
                <ReactTags tags={this.state.deviceTags} labelField='name' suggestions={this.state.clientTags} placeholder='Search Tags' allowDragDrop={false} inputFieldPosition='top' handleDelete={(t) => this.deleteDeviceTag(t)} handleAddition={(t) => this.createDeviceTag(t)} delimiters={delimiters} />
            </div>
        )
    }
}