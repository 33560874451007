const SCADA_PATH = process.env.SCADA_PATH;
export const SCADA_CONNECT_WEB_SOCKET = 'SCADA_CONNECT_WEB_SOCKET'
export const SCADA_DISCONNECT_WEB_SOCKET = 'SCADA_DISCONNECT_WEB_SOCKET'
export const UPDATE_LIVE_CONNECTIONS = 'UPDATE_LIVE_CONNECTIONS'
export const ADD_LIVE_CONNECTION = 'ADD_LIVE_CONNECTION'
export const REMOVE_LIVE_CONNECTION = 'REMOVE_LIVE_CONNECTION'
export const UPDATE_LIVE_SAMPLE = 'UPDATE_LIVE_SAMPLE'
export const TOGGLE_LIVE_CONNECTION = 'TOGGLE_LIVE_CONNECTION'
export const DISCONNECT_LIVE = 'DISCONNECT_LIVE'
export const CONNECT_ERROR = 'CONNECT_ERROR'
export const UPDATE_LIVE_SAMPLE_BATCH = 'UPDATE_LIVE_SAMPLE_BATCH'
export const POOR_SIGNAL = 'POOR_SIGNAL'
export const TIME_OUT = 'TIME_OUT'
export const AUTH_ERROR = 'AUTH_ERROR'
export const ACT_CMD_SUCCESS = 'ACT_CMD_SUCCESS'
export const SCADA_COMMANDS = {
    RUN: "RUN",
    STOP: "PAUSE",
    YAW_CW: "YAW_CW",
    YAW_ACW: "YAW_ACW",
    TOGGLE_MODE: "TOGGLE_MODE",
    //TOGGLE_MODE: "TEST_MODE_TOGGLE",
    ACTION: "ACTION",
    MOTOR_START: "MOTOR_START"
}
import Alert from 'react-s-alert';
import { ApiPost, GetSessionToken } from '../api'

export function updateLiveConnection(siteList) {
    return (dispatch) => {
        if (!window.scadaConn || window.scadaConn.readyState > 1) {
            window.scadaConn = connectWebSocket(dispatch,siteList);
        } else {
            scadaSubscribeSites(window.scadaConn, dispatch, siteList)
        }
    }
}

export function toggleLiveConnection() {
    return {
        type: TOGGLE_LIVE_CONNECTION
    }
}

export function disconnectLive(filters) {
    console.log('disconnectLive')
    const ws = getWs();
    ws.close();
    dispatch({
        type: DISCONNECT_LIVE,
    });
}

const getWs = (arrSiteIds) => {
    if (!window.scadaConn || window.scadaConn.readyState > 1) {
        window.scadaConn = connectWebSocket(arrSiteIds);
    }
    return window.scadaConn;
}

const scadaSubscribeSites = (ws, dispatch, arrSiteIds) => {
    console.log('ws',ws)
    const paramSites = arrSiteIds.join(",")
    const sessionToken = GetSessionToken(true);
    var wsCmd = '{ "Status": 200, "Command": "CONNECT", "Parameters": "'+paramSites+'", "Auth":"' + sessionToken + '" }';
    // Send an initial message
    if (ws.readyState != 0) {
        console.log('sending connection ', wsCmd);
        ws.send(wsCmd);
        paramSites && logWS("CONNECT", paramSites, "")
        dispatch({
            type: UPDATE_LIVE_CONNECTIONS,
            payload: { siteIds: arrSiteIds, connected: true }
        });
    }
}

export const scadaSendCommand = (siteId, Command, note, param) => {
    var wsCmd = '{ "Status": 200, "SiteId":' + siteId + ', "Command":"' + Command + '", "Parameters":"' + (param ? param : '2') + '", Auth:"[TOKEN]" }';
    const reqdata = { cmd: Command, siteid: siteId, note } //this.props.alert.stationAlertId }
    ApiPost('Monitoring', 'GetScadaAuthToken', reqdata)
    .then((data) => {
        const ws = getWs();
        wsCmd = wsCmd.replace("[TOKEN]", data.token)
        console.log("wsCmd", wsCmd)
        ws.send(wsCmd);
    });
}

let arrDispatchBuffer = [];
let dispatchTimeStamp = Date.now()

function Add2DispatchBuffer(dispatch, actionObj, timeout) {
    var timeToDispatch = Date.now() - dispatchTimeStamp - timeout;
    arrDispatchBuffer.push(actionObj)
    if (timeToDispatch > 0) {
        dispatchTimeStamp = Date.now();
        dispatch({
            type: UPDATE_LIVE_SAMPLE_BATCH,
            payload: arrDispatchBuffer.slice()
        })
        arrDispatchBuffer = []
    }
}

function logWS(cmd, siteids, note) {
    ApiPost('Monitoring', 'LogWebSocket', {cmd, siteids, note})
    .then((data) => {
        console.log('ws logged')
    });
}


export function connectWebSocket(dispatch, arrSiteIds)
{
    var socket = new WebSocket(SCADA_PATH)
    console.log('socket open', socket)
    socket.onopen = () => {
        // Listen for messages
        socket.onmessage = (event) => {
            var msg = JSON.parse(event.data);
            if (msg.CmdState.length > 0) {
                console.log('msg', msg)
            }
            HandleMessage(dispatch,msg, arrSiteIds != undefined && arrSiteIds.length == 1)
        };

        if (arrSiteIds) {
            scadaSubscribeSites(socket, dispatch, arrSiteIds)
        }

        // Listen for socket closes
        socket.onclose = () => {
            console.log('socket has closed');
            dispatch({
                type: DISCONNECT_LIVE
            })
            //alert('Connection Closed');
        };
    }

    socket.onerror = (e) => {
        console.log('socket has errored', socket);
        dispatch({
            type: CONNECT_ERROR
        })
    }

    return socket;
}

function HandleMessage(dispatch, respObj, dontBatch) {
    if (respObj.Log && respObj.Log.length > 0) {
        for (var log of respObj.Log) {
            var txt = log.m;
            if (log.t == 'i') {
                Alert.info(txt);
            } else if (log.t == 'w') {
                Alert.warning(txt);
            } else if (log.t == 'e') {
                Alert.error(txt);
            }
        }
    }
    if (respObj.Command == "READ") {
        if (dontBatch) {
            dispatch({
                type: UPDATE_LIVE_SAMPLE,
                siteId: respObj.SiteId,
                payload: respObj.Data,
                cmdState: respObj.CmdState
            })
        } else {
            Add2DispatchBuffer(dispatch,{
                type: UPDATE_LIVE_SAMPLE,
                siteId: respObj.SiteId,
                payload: respObj.Data,
                cmdState: respObj.CmdState
            }, 2000)
        }
        
    }
    if (respObj.Command == "SIGNAL") {
        dispatch({
            type: POOR_SIGNAL
        })
    }
    else if(respObj.Command == "TIMEOUT") {
        dispatch({
            type: TIME_OUT
        })
    }
    else if (respObj.Command == "AUTH") {
        console.log('** dispatching auth error **')
        dispatch({
            type: AUTH_ERROR
        })
    }
    if (respObj.Status == 503) {
        dispatch({
            type: CONNECT_ERROR
        })
    }
    if (respObj.Command != "READ" && respObj.Data != null) {
        console.log('respObj', respObj.Status)
        if (respObj.Status == 200) {
            dispatch({
                type: ACT_CMD_SUCCESS
            })
        }
        //else if(respObj.Status == 503){
        else {
            dispatch({
                type: ACT_CMD_SUCCESS
            })
        }
    }
}
