import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { ApiPost, ApiGet } from '../../../api'
import moment from 'moment'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { listRequest } from '../../../configuration/enums'
import { colorSwatches, colorShadeSwatches } from '../../../configuration/enums'

class ProjectBoard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jobTypes: [],
            activity: null
        }
    }

    componentDidMount() {
        this.getJobTypes()
        document.title = 'Project Board | izon'
    }

    getJobTypes() {
        var listsToGet = [listRequest.WorkCardTypes, listRequest.WorkCardStatuses]
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
        .then((data) => {
            this.setState({ jobTypes: data[listRequest.WorkCardTypes], jobType: data[listRequest.WorkCardTypes][0].id, workcardStatuses: data[listRequest.WorkCardStatuses] }, () => this.getData())
        })
    }

    getData() {
        ApiGet('JobOverview', 'GetActivity', { jobType: this.state.jobType })
        .then((data) => {
            this.setState({ activity : data })
        })
    }

    getCols() {
        var headers = this.state.workcardStatuses.find(x => x.id == this.state.jobType)
        var kH = [], dDevices = [...new Set(this.state.activity.map(item => item.deviceId))]
        if (headers)
        {
            headers.options.map(h => {
                var kC = []
                var tA = JSON.parse(JSON.stringify(this.state.activity))
                var sCards = tA.filter(x => x.jobStatus == h.id)
                sCards.map(s => {
                    kC.push(
                        <div className='activityCardCompact kanbanCard' key={'kb' + s.jobId} onClick={() => this.props.history.push('Workcards/' + s.jobId, { history: null })}>
                            <div className='cardTag' style={{ backgroundColor: colorSwatches[0][dDevices.indexOf(s.deviceId)] }} />
                            <Row className='kanbanContent'>
                                <b>{s.stationName}</b>
                                <div>
                                    <i className='far fa-clock' title='Total time spent on job' /><span>{s.totalTime}</span>
                                    <i className='far fa-user-hard-hat' title='Total number of visits' /><span>{s.totalVists}</span>
                                </div>
                            </Row>
                            <Row className='kanbanContent'>
                                <span>{s.jobRef || s.jobId}</span>
                            </Row>
                        </div>
                    )
                })
                kH.push(<div className='kanbanCol' key={'b-'+h.name}><div className='kanbanHeader'><b>{h.name}</b> <span>({sCards.length})</span></div>{kC}</div>)
            })
        }
        return (<div className='kanbanBoard'>
                    {kH}
                </div>)
    }

    render() {
        var jTName = this.state.jobTypes && this.state.jobType && this.state.jobTypes.find(x => x.id == this.state.jobType).name
        return (
            <div style={{height:'100%'}}>
                <Row>
                    <Col xs={10}>
                        <div className='titleBlock'>{jTName} Project Board</div>
                    </Col>
                    <Col xs={2}>
                        {this.state.jobTypes.length > 0 && <IdNameDropdown options={this.state.jobTypes} style={{ height: '100%', padding: '0.2rem' }} defaultValue={this.state.jobType} valueField='id' displayField='name' onChange={(e) => this.setState({ jobType: e }, () => this.getData())} />}
                    </Col>
                </Row>
                {this.state.workcardStatuses && this.state.activity && <div>{this.getCols()}</div>}
            </div>
        )
    }
}

export default ProjectBoard
