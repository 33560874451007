import React, { Component } from 'react';
import SimpleModal from './../../../components/SimpleModal'
//import Container from './dnd/Container'
import Container from './dnd/Container'
import { ApiPost, ApiGet } from '../../../api'
import MetricSelect from './MetricSelect'
import { Button, Input, Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import ColorPicker from 'rc-color-picker'
import 'rc-color-picker/assets/index.css';
import SchematicTemplate from './SchematicTemplate'

class ScadaSchematic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dM: null, schemId: null, schemTemps: null, header: null, body: null, footer: null, open: false, imgW: 0, imgH: 800, imgSrc: '',
            editAuth: true, editActive: false, tempActive: false, subId: null, subHist: []
        }
    }

    componentDidMount() {
        this.getTags()
        this.getTemplates()
    }

    getImgConfig(imgBase) {
        var schemImg = new Image()
        schemImg.src = imgBase
        schemImg.onload = () => {
            var actWidth = schemImg.width, actHeight = schemImg.height, setWidth = schemImg.width, setHeight = schemImg.height
            if (actWidth > 1700) {
                setWidth = 1700
                setHeight = (1700 / actWidth) * actHeight
            }
            this.setState({ imgH: setHeight, imgW: setWidth, imgSrc:schemImg.src })
        }
    }

    getTags() {
        ApiGet('Monitoring', 'GetSchemTags', { indId: this.props.sInfo.industryId, modelId: this.props.sInfo.turbineModelId, statId: this.props.stationId, subId: this.state.subId })
        .then((data) => {
            if (data.schematicTemplateId) {
                this.setState({ dM: JSON.parse(data.tagConfig), schemId: data.schematicTemplateId }, () => this.getImgConfig(data.schematicImg))
            }
        })
    }

    getTemplates() {
        ApiGet('Monitoring', 'GetSchemTemplates', { indId: this.props.sInfo.industryId })
        .then((data) => {
            this.setState({schemTemps: data})
        })
    }

    setTags(setTemplate) {
        ApiPost('Monitoring', 'SetSchemTags', { tagConfig: JSON.stringify(this.state.dM), schemId: this.state.schemId, stationId: this.props.stationId, setTemplate })
    }

    saveTemplate(id, modelId, imgBase, schDesc) {
        console.log('id', {id, modelId, imgBase, schDesc})
        ApiPost('Monitoring', 'UpdateTemplate', { tempId: id ? id : null, indId: this.props.sInfo.industryId, modelId, imgBase, schDesc })
        .then((data) => {
            this.getTemplates()
        })
    }

    useTemplate(tempId) {
        ApiPost('Monitoring', 'SetTemplate', { tempId: tempId, stationId: this.props.stationId})
        .then((data) => {
            this.getTags()
            this.toggleEnableTemp()
        })
    }

    modifyTag(i) {
        if (i.s == 'move') {
            if (i.l < 25 && i.t > (this.state.imgH - 50)) { //REMOVE TAG
                var temp = this.state.dM
                delete temp[i.id]
                this.setState({ dM: temp })
            }
            else {
                this.setState(({ dM }) => ({
                    dM: { ...dM, [i.id]: { ...dM[i.id], l: i.l, t: i.t } }
                }));
            }
        }
        else {
            var temp = this.state.dM, oHeight = temp[i.id].h ? temp[i.id].h : 43, oWidth = temp[i.id].w ? temp[i.id].w : 100,
                nHeight = oHeight + i.t,
                nWidth = oWidth + i.l
            this.setState(({ dM }) => ({
                dM: { ...dM, [i.id]: { ...dM[i.id], w: nWidth, h: nHeight } }
            }));
        }
    }

    parseColour(e) {
        var rOpacity = Math.ceil(e.alpha/5)*5, rColour = e.color
        var opacityDict = {
            0: '00', 5: '0D', 10: '1A', 15: '26', 20: '33', 25: '40', 30: '4D', 35: '59', 40: '66', 45: '73', 50: '80',
            55: '8C', 60: '99', 65: 'A6', 70: 'B3', 75: 'BF', 80: 'CC', 85: 'D9', 90: 'E6', 95: 'F2', 100: 'FF'
        }
        var nOpacity = opacityDict[rOpacity]
        return rColour + nOpacity
    }

    editTag(id) {
        var dId = id.split('_')[0]
        var mId = id.split('_')[1]
        var cTag = this.state.dM[id],
            dTag = this.props.tags[dId] ? this.props.tags[dId].find(x => x && x.metricId == mId) : null,
            cTitle = cTag.title ? cTag.title : dTag ? dTag.name : '',
            cFColour = cTag.fColour ? cTag.fColour : '#000',
            cBColour = cTag.bColour ? cTag.bColour : '#FFF',
            cOColour = cTag.oColour ? cTag.oColour : '#d9d9d9',
            cTSize = cTag.tSize ? cTag.tSize : 14,
            cTBold = cTag.tBold ? cTag.tBold : 'normal',
            cBWidth = cTag.bWidth ? cTag.bWidth : 2,
            cViz = cTag.viz ? cTag.viz : "1",
            cMViz = cTag.mViz ? cTag.mViz : 1,
            cNViz = cTag.nViz ? cTag.nViz : 0,
            cVColour = cTag.vColour ? cTag.vColour : '#5b8cd2',
            cSubSchem = cTag.subSchem ? cTag.subSchem : 0,
            nTitle, nFColour, nBColour, nOColour, nTSize, nTBold, nBWidth, nViz, nMViz, nNViz, nVColour, nSubSchem

        var header = <span>Edit Tag {dTag && '- ' + dTag.name}</span>
        var body =
            <div className='schematicModalCont'>
                        <Row><Col sm="4"><b>Tag Text </b></Col><Col sm="8"><Input placeholder={cTitle} onChange={(e) => nTitle = e.target.value}/></Col></Row>
                        <Row><Col sm="4"><b>Text Size (px) </b></Col><Col sm="8"><Input placeholder={cTSize} onChange={(e) => nTSize = e.target.value} /></Col></Row>
                        <Row><Col sm="4"><b>Text Style </b></Col><Col sm="8"><select className="form-control" defaultValue={cTBold} onChange={e => cTBold = e.target.value}> <option value="normal">Normal</option> <option value="bold">Bold</option></select></Col></Row>
                        <Row><Col sm="4"><b>Viz Type</b></Col><Col sm="8"><select className="form-control" defaultValue={cViz} onChange={(e) => { nViz = e.target.value; document.getElementById('saveBtn').click(); }}><option value="1">Value only</option><option value="2">Value on bar</option><option value="3">Value on column</option></select></Col></Row>
                        {(cViz != "1") && <Row><Col sm="4"><b>Viz Max Val</b></Col><Col sm="8"><Input placeholder={cMViz} onChange={(e) => nMViz = e.target.value} /></Col></Row>}
                        {(cViz != "1") && <Row><Col sm="4"><b>Viz Min Val</b></Col><Col sm="8"><Input placeholder={cNViz} onChange={(e) => nNViz = e.target.value} /></Col></Row>}
                        {(cViz != "1") && <Row><Col sm="4"><b>Viz Colour </b></Col><Col sm="8"><ColorPicker color={cVColour} placement="bottomRight" onChange={(e) => nVColour = this.parseColour(e)} /></Col></Row>}
                        <Row><Col sm="4"><b>Border Width (px) </b></Col><Col sm="8"><Input placeholder={cBWidth} onChange={(e) => nBWidth = e.target.value} /></Col></Row>
                        <Row><Col sm="4"><b>Text Colour </b></Col><Col sm="8"><ColorPicker color={cFColour} placement="bottomRight" onChange={(e) => nFColour = this.parseColour(e)} /></Col></Row>
                        <Row><Col sm="4"><b>Back Colour </b></Col><Col sm="8"><ColorPicker color={cBColour} placement="bottomRight" onChange={(e) => nBColour = this.parseColour(e)} /></Col></Row>
                        <Row><Col sm="4"><b>Border Colour </b></Col><Col sm="8"><ColorPicker color={cOColour} placement="bottomRight" onChange={(e) => nOColour = this.parseColour(e)} /></Col></Row>
                        {id.includes('9998_') && <Row><Col sm="4"><b>Sub-schematic</b></Col><Col sm="8">
                        <select className="form-control" defaultValue={cSubSchem} onChange={e => { nSubSchem = e.target.value; }}>
                            <option value={0}>Select Schematic</option>
                            {this.state.schemTemps.map(x => {
                                return (<option value={x.schematicTemplateId}>{x.schematicDesc}</option>)
                            })}
                        </select></Col></Row>}
            </div>
            var footer = <Button id='saveBtn' onClick={() => this.saveTagEdit(cTitle != nTitle ? nTitle : null, cFColour != nFColour ? nFColour : null,
            cBColour != nBColour ? nBColour : null, cOColour != nOColour ? nOColour : null,
            cTSize != nTSize ? nTSize : null, cTBold, cBWidth != nBWidth ? nBWidth : null, 
            cViz != nViz ? nViz : null, cMViz != nMViz ? nMViz : null, cNViz != nNViz ? nNViz : null, cVColour != nVColour ? nVColour : null, cSubSchem != nSubSchem ? nSubSchem : null,
                id)}>Save</Button>

        this.setState({open: true, header, body, footer}, () => console.log('state updated'))
    }

    saveTagEdit(title, fColour, bColour, oColour, tSize, tBold, bWidth, viz, mViz, nViz, vColour, subSchem, id) {
        var tDm = this.state.dM
        if (title) {
            tDm[id].title = title
        }
        if (bColour) {
            tDm[id].bColour = bColour
        }
        if (fColour) {
            tDm[id].fColour = fColour
        }
        if (oColour) {
            tDm[id].oColour = oColour
        }
        if(tSize) {
            tDm[id].tSize = tSize
        }
        if (tBold) {
            tDm[id].tBold = tBold
        }
        if (bWidth) {
            tDm[id].bWidth = bWidth
        }
        if (viz) {
            tDm[id].viz = viz
        }
        if (mViz) {
            tDm[id].mViz = mViz
        }
        if (nViz) {
            tDm[id].nViz = nViz
        }
        if (vColour) {
            tDm[id].vColour = vColour
        }
        if (subSchem) {
            tDm[id].subSchem = subSchem
        }
        this.setState({ open: !this.state.open, dM: tDm, sViz: false })
    }

    toggleTag(x) {
        if (x == 9999 || x == 9998) {
            x = x + '_' + Date.now()
        }
        var temp = this.state.dM ? this.state.dM : []
        temp[x] = { t: 28, l: 350 }
        this.setState({dM: temp})
    }

    toggleEnableEdit(saveTemplate) {
        this.setState({ editActive: !this.state.editActive }, () => !this.state.editActive && this.setTags(saveTemplate))
    }

    toggleEnableTemp() {
        this.setState({ tempActive: !this.state.tempActive, subId: null, subHist: [] })
    }

    viewSub(s) {
        var tHist = this.state.subHist
        tHist.push(s)
        this.setState({ subId: s, subHist: tHist }, () => this.getTags())
    }

    subReturn() {
        var tHist = this.state.subHist
        tHist.pop()
        var tId = null
        if (tHist.length > 0) {
            tId = tHist.slice(-1).pop()
        }
        this.setState({ subId: tId, subHist: tHist }, () => { this.getTags(); console.log('subReturn', { tHist, tId }); })
    }

    render() {
        const { footer, header, body, open, dM, schemTemps, imgW, imgH, imgSrc, schemId, editAuth, editActive, tempActive, subId } = this.state
        if (schemId && !tempActive) {
            return (
                <div style={{width:'100%'}}>
                    <div style={{ position: 'absolute', zIndex: 3, right: 0 }}>
                        {editActive && <MetricSelect addGeneric={true} addSub={true} scadaGroups={this.props.tags} devices={this.props.devices} toggleFavMetric={(x) => this.toggleTag(x)} />}
                        {editActive &&
                            <UncontrolledDropdown style={{ display: 'inline-block' }}>
                                <DropdownToggle className="filterButton" style={{padding:'.3rem .9rem', color:'#c7c7c7', marginRight:'5px'}}>
                                    <i className='fal fa-bars' />
                                </DropdownToggle>
                                <DropdownMenu right style={{width:'225px'}}>
                                    <DropdownItem onClick={()=>this.toggleEnableEdit()}>Save schematic to station only</DropdownItem>
                                    <DropdownItem onClick={()=>this.toggleEnableEdit(true)}>Save schematic to template</DropdownItem>
                                    <DropdownItem onClick={()=>this.toggleEnableTemp()}>Manage templates</DropdownItem>
                                    <DropdownItem onClick={()=>this.setState({ editActive: !this.state.editActive })}>Cancel</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                        {editAuth && !editActive && <button className='schematicBtns btn' onClick={() => this.toggleEnableEdit()} ><i className={editActive ? 'fa fa-save btn' : 'fa fa-edit btn'} /></button>}
                    </div>
                    {subId && <button className='schematicBtns btn' style={{position:'absolute'}} onClick={() => this.subReturn()} ><i className='far fa-arrow-left' /></button>}
                    <div className="schematicCont" style={{ minHeight: imgH }}>
                        <img src={imgSrc} className='schematicImg' />
                        <Container tags={this.props.tags} hideSourceOnDrag={true} canDrag={editActive} editTag={(e) => this.editTag(e)} id={this.id} editActive={editActive} dimensions={{ imgW, imgH }} dM={dM} modifyTag={(i) => this.modifyTag(i)} getValue={(metricId, decimalPlaces, deviceId) => this.props.getScadaValue(metricId, decimalPlaces, deviceId)} templates={schemTemps} devices={this.props.devices} viewSub={(s)=>this.viewSub(s)}/>
                    </div>
                    <SimpleModal {...{ header, body, footer, open }} />
                </div>
            )
        }
        else if (schemId) {
            return (<SchematicTemplate templates={schemTemps} saveTemplate={(e, i, o, u) => this.saveTemplate(e, i, o, u)} useTemplate={(e) => this.useTemplate(e)} toggleEnableTemp={() => this.toggleEnableTemp()} />)
        }
        else {
            return <div style={{height:'500px'}} />
        }
    }
}


export default ScadaSchematic
