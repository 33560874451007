import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import Highcharts from 'highcharts';

export default class SimpleChart extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.createChart()
        console.log('chartData', this.props.data)
    }

    componentDidUpdate(nextProps) {
        if (nextProps.data != this.props.data) {
            this.createChart()
            return true
        }
        else {
            false
        }
    }

    createChart() {
        if (this.chart != null) {
            this.chart.destroy();
        }
        this.chart = new Highcharts["Chart"](
            this.refs.chart, {
            chart: {
                type: 'spline',
                inverted: false
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: null,
                },
                maxPadding: 0.05,
                showLastLabel: true,
                type: 'category'
            },
            yAxis: [{
                title: {
                    text: null
                },
                lineWidth: 1,
                gridLineWidth: 0,
                tickLength: 10,
                tickWidth: 1
            },
            {
                title: {
                    text: null
                },
                lineWidth: 1,
                gridLineWidth: 0,
                tickLength: 10,
                tickWidth: 1,
                opposite: true
            }],
            plotOptions: {
                spline: {
                    marker: {
                        enable: false
                    }
                },
                series: {
                    marker: { enabled: false }
                },
                bar: {
                    animation: true,
                    grouping: false,
                    shadow: false
                },
                columnrange: {
                    grouping: false
                }
            },
            tooltip: {
                shared: false
            },
            exporting: {
                enabled: false
            },
            series: this.props.data
        }
        );
        this.chart.setSize(null)
        window.myChart = this.chart;
    }


    render() {
        return (
            <div ref="chart" className="scada-chart" />
        );
    }
}