import React, { Component } from 'react';

export default class VesselPin extends Component {
    static defaultProps = {};

    constructor(props) {
        super(props);
    }

    render() {
        const { heading, color, stroke } = this.props;

        var rotate = 'rotate(' + heading + 'deg)'
        //<div style={{ transform: 'rotate(90deg)' }}>{this.props.title}</div>
        return (
            <div>
                <div className='vesselLabel'><span>{this.props.title}</span></div>
                <div title={this.props.title} style={{ transform: rotate }}>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className='vesselPin' width="20.000000pt" height="56.000000pt" viewBox="0 0 20.000000 56.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,56.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="#000000" strokeWidth={stroke ? "5" : "0"}>
                            <path fill={color} d="M71 527 c-49 -53 -61 -116 -61 -319 l0 -188 90 0 90 0 0 188 c0 200 -7 246 -47 305 -30 43 -42 45 -72 14z"/>
                        </g>
                    </svg>
                </div>
            </div>
          )
    }
}