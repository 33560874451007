import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums';
export const API_PATH = process.env.SERVER_PATH + '/api'

class CompanyCard extends Component {

    constructor(props) {
        super(props);
        
    }

    render() {

        var CompanyCardFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Company", fieldId: "name", list: listRequest.ClientCompanies },
            { label: "Client id", fieldId: "clientId", getId: 'ClientSettings', display: 'none' },

            
        ];

        var CompanyCardConfig = {
            model: 'CompanyCard',
            fields: CompanyCardFields,               
            master: true,
            titleField: 'name',
            apiPath: API_PATH
        }

        var AssetCardFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Asset", fieldId: "name", width: 200 },           
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/AssetCard/' } },            

        ];

        var AssetCardConfig = {
            model: 'CompanyAssets',
            path: '/CompanyCard/GetComapnyAssets/' + this.props.match.params.id,
            fields: AssetCardFields, 
            gridButton: false,
            titleField: 'name',
            apiPath: API_PATH
        }

        return (
            <div>
                <h1 key='h1wc'>Company Details</h1>
                <MasterDetail key='mdwc' {...CompanyCardConfig} />                
            </div>
        );

    }
}

export default (CompanyCard)
