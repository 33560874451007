import React, { Component } from 'react';
import OverlayLoader from '../../../components/OverlayLoader'
import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more'
// import boost from 'highcharts/modules';
More(Highcharts)

export default class AnalysisChart extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { analysisData, menuVisible } = this.props;
        this.createChart();
        this.processChart(analysisData, menuVisible)
    }

    componentDidUpdate(prevProps) {
        const { analysisData, menuVisible } = this.props;
        if (menuVisible != prevProps.menuVisible) {
            setTimeout(() => this.chart.setSize(null), 250)
            return null
        }
        if ((analysisData && analysisData.analysisData && prevProps.analysisData != analysisData)) {
            this.processChart(analysisData, menuVisible)
        }
        else {
           // console.log('removing all chart data')
            while (this.chart.series.length > 0) {
                this.chart.series[0].remove(false);
            }
        }
    }

    processChart(analysisData, menuVisible) {
        var chartData = analysisData.analysisData;
        var start = performance.now();
        while (this.chart.series.length > 0) {
            this.chart.series[0].remove(true);
        }
        while (this.chart.yAxis.length > 1) {
            this.chart.yAxis[1].remove(true);
        }
        if (analysisData.chartDetails) {
            this.chart.xAxis[0].setCategories(analysisData.chartDetails.categories || null);
            this.chart.redraw();

            if (analysisData.chartDetails.chartId != this.chart.chartId) {
                this.setChartSpec(analysisData);
            }
            if (analysisData.chartDetails.json) {
                //console.log('analysisData', analysisData.chartDetails.json);
            }
            this.chart.setTitle({ text: analysisData.chartDetails.chartTitle || null })

        }
        chartData.map(x => {
            var chartArr = [];
            chartArr = Object.keys(x.data).map(function (i) {
                if (!isNaN(i)) {
                    return [parseFloat(i), x.data[i]];
                }
                else {
                    return [i, x.data[i]];
                }
            });

            var seriesExists = false;
            var axisExists = false;

            this.chart.yAxis.map(a => {
                if ((a.userOptions && a.userOptions.id == x.id) || x.id == undefined) {
                    axisExists = true;
                }
            })

            if (!axisExists) {
                this.chart.addAxis({
                    id: x.id,
                    opposite: true,
                    title: {
                        align: 'high',
                        text: x.axisLabel,
                        offset: -15
                    },
                    allowDecimals: false,
                    lineWidth: 1,
                    gridLineWidth: 0,
                    tickLength: 10,
                    tickWidth: 1,
                });
            }

            this.chart.series.map(a => {
                if (a.name.toUpperCase() == x.name.toUpperCase()) {
                    seriesExists = true;
                }
            })
            if (!seriesExists) {
                var tData = x.data
                if (x.needsCleaned) {
                    var cleanData = []
                    Object.keys(x.data).map(y => {
                        cleanData.push(x.data[y])
                    })
                    tData = cleanData
                    //console.log('cleanData', cleanData)
                }
                else if (x.pprocess == "array") {
                    var cleanData = []
                    var ppstart = performance.now()
                    x.data.map(y => {
                        cleanData.push(y)
                    })
                    var ppend = performance.now()
                    console.log("Post Processing Time: ", ppend - ppstart)
                    tData = cleanData
                }
                else {
                    tData = chartArr.sort(function (o1, o2) { return o1[0] - o2[0] })
                }
                //console.log('addSeries')
                if (x.plot != false) {
                    //console.log("tData: ", tData)
                    this.chart.addSeries({ ...x, data: tData, marker: x.marker ? x.marker : { symbol: 'circle', radius: 2 }, yAxis: x.yAxis ? x.yAxis : 0, zIndex: x.zInd ? x.zInd : 0, color: x.color ? x.color : null, type: x.type ? x.type : analysisData.chartDetails.type || 'spline', visible: x.visible != null ? x.visible : true }, false);
                }
            }
        })
        var end = performance.now();
        this.chart.setSize(null)
        console.log('processedChart', ((end - start) * 0.001) + " secs");
    }

    setChartSpec(analysisData) {
        console.log('analysisData', analysisData)
        this.chart.chartId = analysisData.chartDetails.chartId

        this.chart.setTitle({ text: analysisData.chartDetails.chartTitle || null })

        this.chart.update({ chart: { polar: analysisData.chartDetails.polar || null } })
        this.chart.update({ chart: { height: analysisData.chartDetails.height || 500 } })
        this.chart.update({ plotOptions: analysisData.chartDetails.plotOptions || null })

        this.chart.xAxis[0].update({ title: { text: analysisData.chartDetails.xAxisTitle || null } })
        this.chart.xAxis[0].update({ type: analysisData.chartDetails.type || 'category' })
        this.chart.xAxis[0].update({ min: analysisData.chartDetails.xAxisMin || null })
        this.chart.xAxis[0].update({ max: analysisData.chartDetails.xAxisMax || null })
        this.chart.yAxis[0].update({ title: { text: analysisData.chartDetails.yAxisTitle || null } })
        this.chart.yAxis[0].update({ min: analysisData.chartDetails.yAxisMin || null })
        this.chart.yAxis[0].update({ max: analysisData.chartDetails.yAxisMax || null })
        this.chart.yAxis[0].update({ gridLineWidth: analysisData.chartDetails.gridLineWidth || 0 })

        for (var i = 0; i < this.chart.series.length; i++) {
            // this.chart.series[i].update({ showInLegend: analysisData.chartDetails.showLegend || true })
        }

        this.chart.xAxis[0].setCategories(analysisData.chartDetails.categories || null)

        this.chart.update({ chart: { animation: analysisData.chartDetails.animation || true } })

        this.chart.update({ chart: { inverted: analysisData.chartDetails.inverted || null } })

        if (analysisData.chartDetails.yAxis) {
            this.chart.update({ yAxis: analysisData.chartDetails.yAxis || this.chart.yAxis })
        }
        if (analysisData.chartDetails.xAxis) {
            this.chart.update({ xAxis: analysisData.chartDetails.xAxis || this.chart.xAxis })
        }
        if (analysisData.chartDetails.tooltip) {
            this.chart.update({ tooltip: analysisData.chartDetails.tooltip || this.chart.tooltip })
        }
        if (analysisData.chartDetails.legend) {
            this.chart.update({ legend: analysisData.chartDetails.legend || this.chart.legend })
        }
        if (analysisData.chartDetails.subtitle) {
            this.chart.update({ subtitle: analysisData.chartDetails.subtitle || this.chart.subtitle })
        }
        if (analysisData.chartDetails.caption) {
            this.chart.update({ caption: analysisData.chartDetails.caption || this.chart.caption })
        }
    }

    createChart() {
        if (this.chart != null) {
            this.chart.destroy();
        }
        this.chart = new Highcharts["Chart"](
            this.refs.chart, {
                chart: {
                    type: 'spline',
                    inverted: false
                },
                title: {
                    text: null
                },
                xAxis: {
                    title: {
                        text: '_',
                        align: 'high',
                        offset: -15
                    },
                    maxPadding: 0.05,
                    showLastLabel: true,
                    type: 'category'
                },
                yAxis: {
                    title: {
                        align: 'high',
                        text: '_',
                        offset: -15
                    },
                    lineWidth: 1,
                    gridLineWidth: 0,
                    tickLength: 10,
                    tickWidth: 1
                },
                plotOptions: {
                    spline: {
                        marker: {
                            enable: false
                        }
                    },
                    /*
                    series: {
                        turboThreshold: 0,
                        zIndex: 0,
                    },
                    */
                    bar: {
                        animation: true,
                        grouping: false,
                        shadow: false
                    },
                    columnrange: {
                        grouping: false
                    }
                },
                tooltip: {
                    shared: true
                }
            }
        );
        this.chart.setSize(null)
        window.myChart = this.chart;
    }

    render() {
        return (
            <div id="analysis-chart" style={{ paddingLeft: '20px', width: this.props.analysisData.chartDetails.widthPercent ? this.props.analysisData.chartDetails.widthPercent + '%' : '100%', display: 'inline-block' }} >
                {this.props.isLoading && <OverlayLoader />}
                <div ref="chart" className="scada-chart" />
            </div>
        );
    }
};
