import React, { Component } from 'react';
import { Button, Modal, Badge, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { ApiPost, ApiGet } from '../../../api';
import RuleCard from './RuleCard';

export default class ScadaTagMgmt extends Component {

    constructor(props) {
        super(props);

        this.navToggle = this.navToggle.bind(this);
        this.state = { activeTab: '3', ruleModal: false, criteriaMetric: this.props.mgmtMetric.name, criteriaOperator: '', criteriaVal: null, criteria: '', metricRules: null, activeRuleType: null };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        this.getRuleData();
    }

    navToggle(tab) {
        if (this.state.activeTab != tab) {
            this.setState({ activeTab: tab });
        }
    }

    ruleToggle(id) {
        this.setState({ ruleModal: !this.state.ruleModal, activeRuleType: id });
    }

    addCriteria() {
        if (this.state.criteriaOperator != '' && this.state.criteriaVal != null) {
            if (this.state.criteria == '') {
                this.setState({ criteria: this.state.criteriaMetric + ' ' + this.state.criteriaOperator + ' ' + this.state.criteriaVal });
            }
            else {
                this.setState({ criteria: this.state.criteria + ' & ' + this.state.criteriaMetric + ' ' + this.state.criteriaOperator + ' ' + this.state.criteriaVal });
            }
        }
    }

    getRuleData() {

        var ruleData = {
            metricId: this.props.mgmtMetric.metricId,
            modelId: this.props.stationDeets.turbineModelId,
            stationId: this.props.stationDeets.id,
            clientId: this.props.stationDeets.companyid
        }

        ApiGet('Monitoring', 'GetMetricRules', ruleData)
        .then((data) => {
            this.setState({ metricRules: data.metricRules }, () => console.log('gotRuleData', this.state.metricRules));
        })
    }

    renderRuleManagement() {
        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.navToggle('3'); }}>
                            Asset
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.navToggle('2'); }}>
                            Model
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.navToggle('1'); }}>
                            All Assets
                         </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {this.renderTabPane("3")}
                    {this.renderTabPane("2")}
                    {this.renderTabPane("1")}
                </TabContent>
            </div>
        )
    }

    renderTabPane(id) {
        return(
        <TabPane tabId={id}>
            <Row>
                <Col sm="12">
                    <span style={{lineHeight: '47px', paddingLeft: '15px', fontSize: '12px'}}>Greyed out rules have been inherited</span>
                    <Button className="btn" style={{ float: 'right', margin: '1rem 1rem 0 0' }} onClick={() => this.ruleToggle(id)}><i className="fa fa-plus"></i> Add</Button>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    {this.renderRuleCards(id)}
                </Col>
            </Row>
        </TabPane>)
    }

    renderRuleCards(id) {
        let cardRenderer = [];
        if (this.state.metricRules) {
            this.state.metricRules.map(x => {
                if (id >= x.ruleType) {
                    cardRenderer.push(<RuleCard ruleDeets={x} id={id} ruleToggle={() => this.ruleToggle(id)} />);
                }
            })
        }
        return cardRenderer;
    }

    renderSetVars() {
        return(
        <Row style={{lineHeight: '34px'}}>
            <Col sm="2">
                <Badge href="#" color="warning" style={{fontSize: '18px'}}>Current</Badge>
            </Col>
            <Col sm="2">
                <input type="text" className="form-control" placeholder="Current" value="76" />
            </Col>
            <Col sm="1">
                <Badge href="#" color="info" style={{fontSize: '18px'}}>Min</Badge>
            </Col>
            <Col sm="2">
                <input type="text" className="form-control" placeholder="Min" value="50" />
            </Col>
            <Col sm="1">
                <Badge href="#" color="danger" style={{fontSize: '18px'}}>Max</Badge>
            </Col>
            <Col sm="2">
                <input type="text" className="form-control" placeholder="Max" value="100" />
            </Col>
        </Row>)
    }

    renderDisplayMgmt() {
        return (
            <div>
            <Row>
            <Col sm="12">
                <b>Display</b>
            </Col>
            </Row>
            <Row style={{ lineHeight: '34px' }}>
            <Col xs="4" sm="2">
                    Normal State
            </Col>
            <Col xs="6" sm="2">
                <input type="text" className="form-control" value="Normal" />
            </Col>
            <Col xs="1" sm="1">
                <i className="fa fa-circle" style={{ color: '#d0021b', fontSize: '20px' }} />
            </Col>
            <Col xs="4" sm="2">
                Visualise as
            </Col>
            <Col xs="6" sm="4">
                <select className="form-control" >
                    <option value="0">Bar Chart</option>
                    <option value="1">Line Chart</option>
                    <option value="2">Flag</option>
                    <option value="3">Binary Flag</option>
                </select>
            </Col>
            </Row><br /></div>)
    }

    handleSubmit(event) {
        event.preventDefault();
        let formD = new FormData(event.target);

        let formData = this.parseFormData(formD);

        formData = Object.assign(formData, { 'ruleMetric': this.props.mgmtMetric.metricId, 'ruleClient': this.props.stationDeets.companyid, 'ruleId': (this.state.metricRules[0] === undefined ? 0 : 0)});

        if (this.state.activeRuleType == 1) {
            //ALL
            formData = Object.assign(formData, { 'ruleType': 1, 'ruleStation': null });
        }
        else if (this.state.activeRuleType == 2) {
            //MODEL
            formData = Object.assign(formData, { 'ruleType': 2, 'ruleModel': this.props.stationDeets.turbineModelId });
        }
        else if (this.state.activeRuleType == 3) {
            //SITE
            formData = Object.assign(formData, { 'ruleType': 3, 'ruleStation': this.props.stationDeets.id });
        }

        console.log('formData', formData);

        ApiPost('Monitoring', 'SetMetricRules', formData)
        .then((data) => {
            console.log('metricDataSet', data);
        })

        this.props.toggleTagMgmt();
    }

    parseFormData(fd) {
        const data = {};
        for (let key of fd.keys()) {
            data[key] = fd.get(key);
        }
        return data;
    }

    handleMetric(e) {
        this.setState({ criteriaMetric: '[' + e.target.value + ']'})
    }

    handleOperator(e) {
        this.setState({ criteriaOperator: e.target.value })
    }

    handleValue(e) {
        this.setState({ criteriaVal: e.target.value })
    }

    renderAddRuleModal() {

        var ruleDesc = { [1]: 'All Assets', [2]: 'Model', [3]: 'Assest' };

        return (
            <Modal isOpen={this.state.ruleModal} style={{ maxWidth: '684px' }} autoFocus={false}>
                <form onSubmit={this.handleSubmit}>
                <ModalHeader>Add {this.props.mgmtMetric.name} Rule - {ruleDesc[this.state.activeRuleType]}</ModalHeader>
                <ModalBody>
                        <Row>
                            <Col xs="6" sm="3">
                                <b>Label</b>
                                <input type="text" name="ruleLabel" className="form-control" placeholder="Rule label" required autoFocus />
                            </Col>
                            <Col xs="6" sm="2">
                                <b>Colour</b>
                                <select className="form-control" name="ruleColour">
                                    <option value="red" style={{ color: '#d0021b' }}>Red</option>
                                    <option value="blue" style={{ color: '#ffa500' }}>Yellow</option>
                                    <option value="yellow" style={{ color: '#5b8cd2' }}>Blue</option>
                                </select>
                            </Col>
                            <Col xs="6" sm="4">
                                <b>Action</b>
                                <select className="form-control" name="ruleAction" >
                                    <option value="0">Discard Sample</option>
                                    <option value="1">Raise Alert</option>
                                    <option value="2">Log</option>
                                    <option value="3">Highlight</option>
                                </select>
                            </Col>
                            <Col xs="6" sm="3">
                                <b>Criteria Duration</b>
                                <input type="text" name="ruleDuration" className="form-control" placeholder="Duration Seconds" required />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xs="11" sm="4">
                                <b>Criteria Builder</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6" sm="3">
                                <select className="form-control" onChange={e => this.handleMetric(e)} defaultValue={this.props.mgmtMetric.name} >
                                    {
                                    this.props.metrics.map(i => {
                                        return (
                                            <option value={i.name} key={i.metricId}>{i.name}</option >
                                        )
                                    })
                                    }
                                </select>
                            </Col>
                            <Col xs="6" sm="3">
                                <select className="form-control" onChange={e => this.handleOperator(e)}>
                                    <option value="<">Less than</option>
                                    <option value="=">Equal to</option>
                                    <option value=">">Greater than</option>
                                </select>
                            </Col>
                            <Col xs="6" sm="2">
                                <input type="text" className="form-control" placeholder="Value" onChange={e => this.handleValue(e)} />
                            </Col>
                            <Col xs="6" sm="1">
                                <Button type="button" color="secondary" onClick={() => { this.addCriteria() }}><i className="fa fa-plus" ></i></Button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm="12">
                                <textarea type="text" name="ruleCriteria" className="form-control" cols="40" rows="5" value={this.state.criteria} />
                            </Col>
                        </Row>
                </ModalBody>
                <ModalFooter>
                        <Button color="secondary" type="button" style={{ marginRight: 'auto' }} onClick={() => this.ruleToggle()}>Cancel</Button>
                        <Button color="primary">Save</Button>
                </ModalFooter>
                </form>
            </Modal>
            )
    }

    render() {

        return (
            <div>
            <Modal isOpen={this.props.isOpen} style={{maxWidth: '684px'}} autoFocus={false}>
                    <ModalHeader>Metric - {this.props.mgmtMetric.name}</ModalHeader>
                <ModalBody>

                    {this.props.editMode ? this.renderDisplayMgmt() : null}
                    {this.props.editMode ? this.renderRuleManagement() : null}

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" style={{ marginRight: 'auto' }} onClick={() => this.props.toggleTagMgmt()}>Cancel</Button>
                    <Button color="primary" onClick={() => this.props.toggleTagMgmt()}>Save</Button>
                </ModalFooter>
            </Modal>

                {this.renderAddRuleModal()}

            </div>
        );
    }
}
