import React, { Component } from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import HWCard from './HWCard'

class HealthAlertWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = { aS : 1, showModal:false}
    }

    getStationList(vals) {
        var assets = this.props.assets
        var sArray = []

        vals.map(x => {
            sArray.push(<HWCard key={x.sourceId + '-' + x.stationId} data={x} assets={assets} />)
        })

        return sArray
    }

    render() {

        const ArrowLeft = ({ currentSlide, slideCount, children, ...props }) => (
			<i {...props} className='fal fa-caret-circle-left' style={{ cursor: 'pointer' }} />
		);
        const ArrowRight = ({ currentSlide, slideCount, children, ...props }) => (
			<i {...props} className='fal fa-caret-circle-right' style={{ cursor: 'pointer' }} />
		);

		const settings = {
			infinite: true,
			speed: 200,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			fade: true,
			afterChange: current => this.setState({ aS: current + 1 }),
			nextArrow: <ArrowRight />,
			prevArrow: <ArrowLeft />
		}

		var wId = 7
		var sList = this.props.widgetValue && this.props.widgetValue.filter(x => x.sourceId == wId)

        var hasIssue = sList && sList.length > 0

        return (
            <div className={'warnWidget ' + (hasIssue ? 'wAlert' : 'ok')}>
                {wId != null && wId != undefined &&
                    <div style={{height:'100%'}}>
                        <div style={{ height: '20%', padding: '5px', borderBottom: '1px solid #ffffff80', minHeight: '35px' }}>
                            <b style={{ fontSize: '1.5rem' }}>Threshold Alert</b>
                            {hasIssue && <span style={{ float: 'right', fontSize: '1.5rem' }}>{this.state.aS} of {sList.length}</span>}
                        </div>
                        <div style={{ margin: '10px', height: '70%' }}>
                            {hasIssue ?
                            <Slider {...settings}>{this.getStationList(sList)}</Slider>  :
                            <div className='warnContain'>
                                <div><b style={{ fontSize: '25px' }}>No Issues</b><i style={{ fontSize: '2em', marginLeft: '5px' }} className='fad fa-check-circle faGW' /></div>
                            </div>}
                        </div>
                    </div>
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return state.common.header
}

export default connect(mapStateToProps)(HealthAlertWidget)
