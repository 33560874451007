import React from 'react'

const ValueRender = (props) => {

    return (
        <div className='dCard'>
            <span className="donutchart-text-val">{props.value}</span>
            <span className="donutchart-text-percent">{props.units}</span>
        </div>
     )
}

export default ValueRender