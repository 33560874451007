import * as headerActions from '../actions/dashboard'
import helper from '../utils/helpers'

const dashboard = (state = {   }, action) => {
  switch (action.type) {
    case headerActions.GOT_WIDGET_DATA:
        return {
        ...state,
        ...action.payload
        }
    default: 
      return state
  }
}
export default dashboard
