import React, { Component } from 'react'
import GoogleMap from 'google-map-react'
import { connect } from 'react-redux'
import { findZoomAndCenter } from '../../../utils/findZoomAndCenter'
import helpers from '../../../utils/helpers'
import MapPath from './../../../components/Header/MapPath'
import VesselPin from './../../../components/Header/VesselPin'
import IntersectPoint from './../../../components/IntersectPoint'
import { ToggleSwitch } from '../../../components/Controls/ToggleSwitch'
import moment from 'moment'
/* global google */

const stationMap = {
    defaultZoom: 6,
    defaultCenter: { lat: 57.39712, lng: 2.38697 },
    options: {
        fullscreenControl: false,
        maxZoom: 16,
        styles: [{ "featureType": "administrative", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative.country", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative.province", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "color": "#f2f2f2" }] }, { "featureType": "landscape.natural", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "color": "#e2e4e4" }] }, { "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "labels.text", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.station.airport", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.station.airport", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e2e4e4" }] }, { "featureType": "water", "elementType": "labels", "stylers": [{ "visibility": "off" }] }],
        gestureHandling: 'greedy',
        scaleControl: true
    },
}

class IncidentMap extends Component {

    constructor(props) {
        super(props);
        this.mapRef = null
        const { center, zoom } = findZoomAndCenter(this.getPathExtremes(this.props.assets), false)
        this.center = center
        this.zoom = zoom
        this.state = { mapLoaded: false, assetPaths: null, groupData: null, defaultZoom: zoom, defaultCenter: center, isHeatmap: true, zoomed: zoom}
        this.assetPaths = null
        this.vesselIntersects = [] 
    }

    componentDidMount() {
        this.createGroupedData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.data != prevProps.data || this.props.iData != prevProps.iData) {
            this.createGroupedData()
        }
    }

    createGroupedData() {
        var iG = {}

        this.props.data.map(x => {
            var ref = x.groupRef
            if (!iG[ref]) {
                iG[ref] = { alertTimes: [], geoData: [], mmsi: x.groupId, inData: [], pathColour: helpers.randomColour() }
            }

            if (!iG[ref].alertTimes[x.id]) {
                iG[ref].alertTimes[x.id] = {start : null, stop : null}
            }

            if (iG[ref].alertTimes[x.id].start == null || moment(iG[ref].alertTimes[x.id].start) > moment(x.raised)) {
                iG[ref].alertTimes[x.id].start = x.raised
            }
            if (iG[ref].alertTimes[x.id].stop == null || moment(iG[ref].alertTimes[x.id].stop) < moment(x.closed)) {
                iG[ref].alertTimes[x.id].stop = x.closed
            }
            if (x.geoData) {
                var t = iG[ref]
                x.geoData.map(g => {
                    var coor = JSON.parse(g.position).coordinates
                    var point = { lat: coor[1], lng: coor[0], heading: g.heading, speed: g.speed, alertId: x.id, alertLevel: x.alertTypeId, timestamp: moment(g.timestamp) }
                    t.geoData.push(point)
                })
            }
        })

        if (Object.keys(iG).length == 1) {
            var t = iG[Object.keys(iG)[0]].geoData
            const { center, zoom } = findZoomAndCenter(t, false)
            this.center = center
            this.zoom = zoom
        }
        else {
            const exteremes = this.getPathExtremes(this.props.assets)
            const { center, zoom } = findZoomAndCenter(exteremes, false)
            this.center = center
            this.zoom = zoom
        }
        if (this.props.iData && this.props.iData.values) {
            Object.keys(iG).map(x => {
                //INTERPOLATION
                var rawPoints = this.props.iData.values.filter(i => i[7] == iG[x].mmsi)
                var iPoints = []
                for (var i = 0; i < rawPoints.length-1; i++) {
                    var nowTime = moment.unix(rawPoints[i][0] / 1000)
                    var nextTime = moment.unix(rawPoints[i+1][0] / 1000)
                    var diff = nextTime.diff(nowTime, 'seconds')
                    var pointCount = diff / 30
                    var latDiff = rawPoints[i + 1][4] - rawPoints[i][4]
                    var lngDiff = rawPoints[i + 1][5] - rawPoints[i][5]
                    var latIterate = latDiff / pointCount
                    var lngIterate = lngDiff / pointCount
                    var latTally = 0, lngTally = 0

                    var calculatedBearing = helpers.getBearing(rawPoints[i][4], rawPoints[i][5], rawPoints[i + 1][4], rawPoints[i + 1][5])

                    var actualPoint = [rawPoints[i][0], calculatedBearing, rawPoints[i][4], rawPoints[i][5], rawPoints[i][7]]
                    iPoints.push(actualPoint)
                    for (var j = 0; j < pointCount; j++) {
                        var tOb = rawPoints[i]
                        latTally += latIterate
                        lngTally += lngIterate
                        var tTally = (tOb[0] + (j * 30000))
                        var lT = tOb[4] + latTally
                        var lgT = tOb[5] + lngTally
                        var heading = calculatedBearing, mmsi = tOb[7], speed = tOb[6]
                        var tt = [tTally, heading, lT, lgT, mmsi, speed]
                        iPoints.push(tt)
                    }
                }
                iG[x].inData = iPoints
            })
        }

        this.vesselIntersects = [] 

        this.setState({ groupData: iG })
    }

    assets2Paths(assets) {
        var paths = []

        var iAsset = assets.find(x => x.id == this.props.selectedAsset)
        if (iAsset) {
            paths.push(iAsset.assetPath)
        }
        else {
            assets.map((o, i) => {
                if (o.assetPath) {
                    paths.push(o.assetPath)
                }
            })
        }
        if (this.state.groupData)
        {
            var pt = moment(this.props.plotTime)
            Object.keys(this.state.groupData).map(x => {
                var d = this.state.groupData[x]
                var tCoor = []
                var alertGeoData = []
                d.geoData.map(z => {
                    tCoor.push(z)
                    if (pt > moment(d.alertTimes[z.alertId].start) && pt < moment(d.alertTimes[z.alertId].stop)) {
                        alertGeoData.push(z)
                    }
                })
                var nearestRec = this.getCurrentGeoData(alertGeoData)
                if (nearestRec) {
                    var maxTime = moment(d.alertTimes[nearestRec.alertId].stop).add(5,'m'), minTime = moment(d.alertTimes[nearestRec.alertId].start)
                    if (pt > minTime && pt < maxTime) {

                    }
                }
            })
        }
        return paths
    }

    getPathExtremes(sites) {

        var pointArray = []

        sites.map(o => {
            if (o.id == this.props.selectedAsset) {
                var lPB = { lat: (o.lat - 0.1), lng: (o.lng - 0.1) }
                var uPB = { lat: (o.lat + 0.1), lng: (o.lng + 0.1) }

                var tPath = JSON.parse(o.assetPath)
                if (tPath) {
                    var tCoor = tPath.coordinates
                    lPB = { lat: tCoor[0].lat, lng: tCoor[0].lng }
                    uPB = { lat: tCoor[tCoor.length - 1].lat, lng: tCoor[tCoor.length - 1].lng }
                    console.log({ lPB, uPB })
                }

                pointArray.push(o, lPB, uPB)
            }
        })
        return pointArray
    }

    getCurrentGeoData(recs) {
        var tTime = moment(this.props.plotTime)
        var aTimes = recs.map(x => new Date(x.timestamp))

        var temp = aTimes.map(d => Math.abs(tTime - d));
        var idx = temp.indexOf(Math.min(...temp));

        return recs[idx]
    }

    getIntersectPoints() {
        return (Object.keys(this.vesselIntersects).map(i => {
            var t = JSON.parse(this.vesselIntersects[i][0])
            var coor = t.coordinates[1]
            var title = { speed: t.speed, distance: t.nmDistance, time: t.pTime }
            return <IntersectPoint key={'ip_' + i} lat={coor.lat} lng={coor.lng} title={title} showTitle={this.state.zoomed >= 10} />
        }))
    }

    getAlertPositions()
    {
        var assetDetails = this.props.selectedAsset ? this.props.assets.find(x => x.id == this.props.selectedAsset) : null
        var assetPath = null
        if (assetDetails) {
            assetPath = JSON.parse(assetDetails.assetPath).coordinates
        }
        var alertColours = { 34: '#9bc7cf', 35: '#FCD757', 36: '#fc9f57', 37: '#EE6352' }
        var pTime = moment(this.props.plotTime).valueOf()

        var activeAlerts = []
        var inactiveAlerts = []
        this.props.data.map(x => {
            var start = moment(x.raised).add(0, 'hours'), stop = x.closed ? moment(x.closed).add(0, 'hours') : moment()
            if (pTime > start && pTime < stop) {
                activeAlerts.push(x)
            }
            else {
                inactiveAlerts.push(x)
            }
        })
        var fActiveAlerts = []

        var vp = []

        if (this.state.groupData) {
            activeAlerts.map(x => {
                if (!fActiveAlerts.find(i => i.groupId == x.groupId)) {
                    fActiveAlerts.push(x)
                }
            })

            fActiveAlerts.map(x => {
                var mmsi = x.groupId
                var vName = x.groupRef
                if (this.state.groupData[vName]) {
                    var geoData = this.state.groupData[vName].inData
                    var diff = 999999999
                    var closest = 1
                    if (geoData.length > 0) {
                        geoData.map((g, i) => {
                            if (Math.abs((g[0] - pTime)) < diff) {
                                diff = Math.abs(g[0] - pTime)
                                closest = i
                            }
                        })
                        if (geoData[closest + 1]) {
                            var lat = geoData[closest][2], lng = geoData[closest][3], heading = geoData[closest][1], stroke = false, speed = geoData[closest][5]
                            if (assetPath && pTime > moment(this.props.toDate).add(-30, 'minutes')) {
                                stroke = this.willIntersect(lat, lng, heading, assetPath, mmsi, speed)
                            }
                            else if (assetPath && this.vesselIntersects.hasOwnProperty(mmsi)) {
                                delete this.vesselIntersects[mmsi]
                            }
                            vp.push(<VesselPin key={mmsi + '_' + closest} lat={lat} lng={lng} color={alertColours[x.alertTypeId]} stroke={stroke} heading={heading} title={vName} />)
                        }
                    }
                }
            })

            //CLEAR INACTIVE ALERTS FROM MAP
            inactiveAlerts.map(x => {
                if (this.vesselIntersects.hasOwnProperty(x.groupId)) {
                    delete this.vesselIntersects[x.groupId]
                }
            })
        }
        return vp
    }

    willIntersect(lat, lng, heading, assetPath, mmsi, speed) {
        console.log('will intersect?')
        var des = 0.089982311915998 //10km
        var uLimit = lat + des
        var lLimit = lat - des
        var boundedPath = assetPath.filter(x => x.lat < uLimit && x.lat > lLimit)
        for (var i = 0; i < boundedPath.length -1; i++) {
            var x = boundedPath[i]
            var y = boundedPath[i + 1]
            var z = this.infinityPoint(lat, lng, heading, 10000)

            var inter = this.lineIntersect(x.lat, x.lng, y.lat, y.lng, lat, lng, z.lat, z.lng)
            if (inter) {
                this.createIntersectLine(lat, lng, inter, mmsi, speed)
                return true
            }
            else {
                if (this.vesselIntersects.hasOwnProperty(mmsi)) {
                    delete this.vesselIntersects[mmsi]
                }
            }
        }
    }

    createIntersectLine(lat, lng, intersect, mmsi, speed) {
        var distanceBetween = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lat, lng), new google.maps.LatLng(intersect.lat, intersect.lng))
        var nmDistance = distanceBetween / 1852
        var pTime = null

        if (speed > 0) {
            var time = 0
            time = nmDistance / speed
            time = time * 60
            pTime = helpers.parseDuration(null, null, time)
            var paths = [JSON.stringify({ colour: '#d0021b', speed, nmDistance, pTime, coordinates: [{ lat, lng }, { lat: intersect.lat, lng: intersect.lng }] })]
            this.vesselIntersects[mmsi] = paths
        }
    }

    infinityPoint(lat, lng, bearing, distance) {
        var pointA = new google.maps.LatLng(lat, lng)
        var pointB = google.maps.geometry.spherical.computeOffset(pointA, distance, bearing);
        return { lat: pointB.lat(), lng: pointB.lng() }
    }

    lineIntersect(x1, y1, x2, y2, x3, y3, x4, y4)
    {
        if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
            return false
        }
        let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))
        if (denominator === 0) {
            return false
        }
        let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
        let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator
        if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
            return false
        }
        let x = x1 + ua * (x2 - x1)
        let y = y1 + ua * (y2 - y1)

        return { lat: x, lng: y }
    }

    drawIntersects() {
        return (Object.keys(this.vesselIntersects).map(i => {
            return <MapPath key={'inter_' + i} mapRef={this.mapRef.map_} paths={this.vesselIntersects[i]} destroy={true} intersect={true} />
        }))
    }

    getPaths() {
        var paths = []
        if (this.props.iData.values) {
            Object.keys(this.state.groupData).map((x, i) => {
                var m = this.state.groupData[x].mmsi
                var mData = this.props.iData.values.filter(o => o[7] == m)
                var tCoor = []
                mData.map(k => {
                    tCoor.push({ "lat": k[4], "lng": k[5] })
                })
                paths.push(JSON.stringify({ type: 'Point', coordinates: tCoor, colour: this.state.groupData[x].pathColour }))
            })
        }
        return paths
    }

    loadAreas(map) {
        if (this.props.guardAreas) {
            if (this.props.guardAreas[this.props.selectedAsset]) {
                var paths = JSON.parse(this.props.guardAreas[this.props.selectedAsset])
                return paths
            }
            else {
                //var areaColours = ['#9bc7cf', '#FCD757', '#fc9f57', '#EE6352']
                //Object.keys(this.props.guardAreas).map(x => {
                //    var gas = JSON.parse(this.props.guardAreas[x])
                //    gas.map((m, i) => {
                //        var coords = []
                //        m.map(g => {
                //            coords.push({ lat: g.lat, lng: g.lng })
                //        })

                //        var area = new window.google.maps.Polygon({
                //            paths: coords,
                //            strokeColor: areaColours[i],
                //            strokeOpacity: 0.5,
                //            strokeWeight: 0.5,
                //            fillColor: areaColours[i],
                //            fillOpacity: 0.15
                //        });

                //        area.setMap(map);
                //    })
                //})
            }

        }
    }

    export() {
        var data = []
        console.log('this.props.data', this.props)
        //this.props.data.map(d => {
        //    console.log('d',d)
        //    d.geoData.map(g => {
        //        var tC = JSON.parse(g.position).coordinates
        //        data.push({id:g.alertGeoDataId, TimeStamp: g.timestamp, AIS: '', MMSI: d.supp_143, Vessel: d.supp_144, Lat:tC[1], Lon:tC[0], SOG: g.speed, COG: g.heading, ReportingVessel: 'Gleaner', Type: d.supp_145 })
        //    })
        //})
        //'time', 'AlertId', 'DistanceToCable', 'Heading', 'Lat', 'Long', 'Speed', 'mmsi'
        this.props.iData.values.map((d) => {
            data.push({ Timestamp: moment(d[0]), AlertId: d[1], DistanceToCable: d[2], Heading: d[3], Lat: d[4], Long: d[5], Speed: d[6], MMSI: d[7]})
        })

        var sData = data.sort((a, b) => b.TimeStamp - a.TimeStamp)

        const items = sData
        const replacer = (key, value) => value === null ? '' : value
        const header = Object.keys(items[0])
        const csv = [
            header.join(','),
            ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n')

        var fileName = 'AIS_LOG ' + this.props.fromDate.format('YYYY-MM-DD') + ' ' + this.props.toDate.format('YYYY-MM-DD')
        var a = window.document.createElement("a");
        var data = new Blob([csv], { type: 'text/csv' });
        a.href = window.URL.createObjectURL(data);
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    toggleHeatmap() {
        //HEATMAP MUST BE TOGGLED ON BY DEFAULT THEN UNTOGGLED ON FIRST LOAD
        this.setState({ isHeatmap: !this.state.isHeatmap }, () => {
            if (this.mapRef !== undefined && this.mapRef.heatmap) {
                this.mapRef.heatmap.setMap(this.state.isHeatmap ? this.mapRef.map_ : null)
            }
            else {
                //RELOAD MAPS
                console.log('refreshmap')
                if (this.props.heatmapData) {
                    window.location.reload();
                }
                //this.props.refreshMap()
            }
        })
    }

    handleZoom(zoomed) {
        this.setState({zoomed})
    }

    setRef(el) {
        if (el) {
            this.mapRef = el
        }
    }

    render() {
        if (this.props.assets.length == 0) {
            
            return null
        }
        else {
            var h = this.props.height ? this.props.height + 'px' : '400px'
            var heatData = { positions: this.props.heatmapData, options: { radius: 30, opacity: 0.9 } }
            return (
                <div id='incidentMapContainer' style={{ height: h, maxHeight:'100%' }} >
                    <button onClick={() => this.export()} className='btn btn-primary exportBtn'>Export</button>
                    {this.props.heatmapData && <ToggleSwitch style={{ marginLeft: '5px', position: 'absolute', zIndex: 2 }} smallMode={true} switchLabel="Heatmap" onToggle={() => this.toggleHeatmap()} />}
                    {this.mapRef && this.mapRef.map_ && this.props.iData && !this.state.isHeatmap && <MapPath mapRef={this.mapRef.map_} paths={this.getPaths()} style={{ solid: true }} />}
                    {this.mapRef && this.mapRef.map_  && <MapPath mapRef={this.mapRef.map_} paths={this.assets2Paths(this.props.assets)} />}
                    {this.mapRef && this.vesselIntersects.length > 0 && this.drawIntersects()}
                    {this.mapRef && this.mapRef.map_ && <MapPath mapRef={this.mapRef.map_} paths={this.loadAreas(this.mapRef.map_)} />}
                    <GoogleMap ref={(el) => this.setRef(el)} onGoogleApiLoaded={({ map, maps }) => { this.setState({ mapLoaded: true }, () => this.toggleHeatmap());  }} yesIWantToUseGoogleMapApiInternals onZoomAnimationEnd={(e) => this.handleZoom(e)} options={stationMap.options} zoom={this.zoom} center={this.center} bootstrapURLKeys={{ key: "AIzaSyC5Rjf0BTAKzGGmfHuWROJGtfebKR5rB_U" }} heatmapLibrary={true} heatmap={heatData} >
                        {!this.state.isHeatmap && this.getAlertPositions()}
                        {this.vesselIntersects.length > 0 && this.getIntersectPoints()}
                    </GoogleMap>
                </div>
            )
        }
    }
}

const mapStateToProps = state => { return { assets: state.common.header.assets } }

export default connect(mapStateToProps)(IncidentMap)
