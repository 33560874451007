import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
export const API_PATH = process.env.SERVER_PATH + '/api'

class CompanySettings extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { fileDetails: null, clientName: '', activeTab: '1', mainActiveTab: '1' };

    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    render() {

        const { mainActiveTab } = this.state;

        var CompanySettingsFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Company", fieldId: "name", width: 170 },
            { label: "Short Name", fieldId: "shortName", width: 170 },
            { label: "Client", fieldId: "clientId", width: 170, list: listRequest.Clients },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' },
        ];

        var CompanySettingsConfig = {
            model: 'CompanySettings',
            fields: CompanySettingsFields,
            master: true,
            titleField: 'name',
            apiPath: API_PATH
        }

        var AssetCardFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Asset Name", fieldId: "name", width: 200 },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/AssetCard/' } },
        ];

        var AssetCardConfig = {
            model: 'CompanyAssets',
            path: '/CompanySettings/GetComapnyAssets/' + this.props.match.params.id,
            fields: AssetCardFields,
            gridButton: false,
            titleField: 'name',
            apiPath: API_PATH
        }

        return ( 
            <div>
                <h1 key='h1wc'>Companies</h1>
                <MasterDetail key='csc' {...CompanySettingsConfig} >  
                    {this.props.match.params.id != 'New' ?
                        [<Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Assets</NavLink>
                            </NavItem>
                        </Nav>,
                        <TabContent key='modelTabs' activeTab={this.state.mainActiveTab}>
                            <TabPane tabId='1'>
                                {this.state.mainActiveTab == '1' ? <MasterDetail key='mdacc' {...AssetCardConfig} /> : null}
                            </TabPane>
                            </TabContent>] : null}
                </MasterDetail>
            </div>
        );

    }
}

export default (CompanySettings)
