export const period = {
    Current : 0,
    LastTenSeconds : 1,
    LastMinute : 2,
    Last10Minutes : 3,
    LastHour : 4,
    CurrentHour : 5,
    Last24hours : 6,
    CurrentDay : 7,
    PrevDay : 8,
    CurrentMonth : 9,
    PrevMonth : 10,
    Last3months : 11,
    Last6months : 14,
    Last12months : 13,
    CurrentYear : 14,
    PrevYear : 15,
    SinceCommisioning : 16
}

export const universalMetrics = {
    ControllerPower : 1,
    WindSpeed : 2,
    RPM : 3,
    Export : 4,
    TurbineStatus : 5,
    ErrorCode : 6,
    GSM : 7,
    Mode : 8,
    RocComms : 9,
    ControllerComms : 10,
    RocPower : 11,
    UniversalState : 12,
    UniversalMode : 13,
    VoltageL1 : 14,
    VoltageL2 : 15,
    VoltageL3 : 16,
    VoltageL1L3: 17,
    VoltageL2L3:18,
    VoltageL3L1:19,
    PowerFactor:20,
    PowerFactorL1:21,
    PowerFactorL2:22,
    PowerFactorL3:23,
    CurrentL1:24,
    CurrentL2:25,
    CurrentL3:26,
    Import:27,
    ConsumerGridImport:28,
    ConsumerGenImport:29,
    GeneratorTemp1:30,
    GeneratorTemp2:31,
    GenBearingTemp:32,
    GenBearingTemp2:33,
    GearboxTemp:34,
    GearboxTemp2:35,
    GearboxBearingTemp:36,
    GearboxBearingTemp2:37,
    AmbientTemp:38,
    AmbientTemp2:39,
    HydraulicTemp:40,
    OilTemp:41,
    OutsideTemp:42,
    LandfillGasVolume:100,	
    LandfillGasFlow: 101,
    LandfillPercCH4: 102,
    TrailerAPressure:103,
    TrailerBPressure: 104,
    TrailerCPressure:105,
    BiomethaneGasVolume: 106,
    BiomethaneGasFlow: 107,
    BiomethanePercCH4:108,
    GasBlendPerc:109,
    BiomethaneTrailerVolume:110,
    BiomethaneTrailerHoursRemain:111
}

export const universalState = {
    0: "Powering Up",
    1: "Startup - Wind Too High",
    2: "Startup - Grid Stabilizing",
    3: "Waiting on Wind",
    5: "Stopped No Autostart",
    8: "Auto Motorstart",
    9: "Manual Motorstart",
    10: "Freewheeling",
    11: "Generator cutin",
    12: "Reconnect To Sync",
    13: "Reconnect From Sync",
    14: "Production Small",
    15: "Production",
    16: "Braking",
    18: "Release Brake",
    19: "Emergency Stopped",
    20: "Stopped",
    21: "Untwist",
    22: "Yawing",
    23: "Error",
    24: "Heating Slipring",
    25: "Low Hyd Oil Temp",
    26: "Block Heating",
    27: "Low Gear Oil",
    28: "Mechanical Gear heating"
}

export const alertType = {
    2:"Meter Warning Alert",
    3:"Meter Fault Alert",
    5:"Adhoc Maintenance",
    6:"No Data Sync",
    7:"Meter Fault Alert",
    8:"Meter Comms Alert",
    11:"Controller Alert",
    12:"Invalid Warning",
    13:"Controller Comms",
    14:"Power Supply",
    15:"Force majeure",
    16:"No Export",
    17:"Invalid Fault",
    18:"Paused Mode",
    19:"Manual Mode",
    21:"VMP4400 Alert",
    22:"TMC2 Alert",
    23:"TMC3 Alert",
    24:"Grid Alert",
    25:"G59 Alert",
    27:"VMP3500 Alert",
    28:"TMC3-Bonus",
    29:"Nordex",
    51:"Service",
    52:"Scheduled Maintenance",
    53:"Commisioning",
    54:"Investigation",
    55:"Stat Inspection",
    56: "Bolt Torque",
    58: "SCADA",
    60:"Scheduled Grid Maintenance"
}
export const industryDefaultMetrics = {
    renderDetails: {
        1: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 250,
            title: 'Energy Usage',
            unit: 'kW',
            alert: (v, all) => ((v >= 6) ? all.metrics[11] <= 0.2 : false)
        },
        2: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 25,
            title: 'Wind Sp',
            unit: 'm/s',
            alert: (v, all) => ((v >= 6) ? all.metrics[11] <= 0.2 : false)
        },
        3: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 40,
            title: 'Rotor Sp',
            unit: 'rpm'
        },
        4: {
            decimalPlaces: 0,
            renderer: 'difference',
            max: 40,
            title: 'Total Export',
            unit: 'kWh'
        },
        7: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: 40,
            title: 'Output',
            unit: 'spm'
        },
        11: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 'maxkw',
            title: 'Active Power',
            unit: 'kW',
        },
        17: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: '410',
            title: 'Voltage L1',
            unit: 'V',
        },
        18: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: '410',
            title: 'Voltage L2',
            unit: 'V',
        },
        19: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: '410',
            title: 'Voltage L3',
            unit: 'V',
        },
        20: {
            decimalPlaces: 2,
            renderer: 'bar',
            max: '1',
            title: 'Power Factor',
            unit: '',
        },
        24: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: 400,
            title: 'Current L1',
            unit: 'amp',
        },
        25: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: 400,
            title: 'Current L2',
            unit: 'amp',
        },
        26: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: 400,
            title: 'Current L3',
            unit: 'amp',
        },
        27: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: 'maxkw',
            title: 'Import',
            unit: 'kWh',
        },
        101: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 300,
            title: 'Lf Flow',
            unit: 'm<sup>3</sup>/min',
            mini: 'L'
        },
        102: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 100,
            title: 'Lf % CH4',
            unit: '',
            mini: ''
        },
        112: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 20,
            title: 'Lf Suction',
            unit: 'mBar',
            mini: '',
            warnLevels:[-600,-500,null,600],
            barOffset:60
        },
        103: {
            decimalPlaces:1,
            renderer: 'bar',
            max: 100,
            title: 'trailer A',
            unit: 'bar',
            mini: ''
        },
        104: {
            decimalPlaces:1,
            renderer: 'bar',
            max: 100,
            title: 'trailer B',
            unit: 'bar',
            mini: ''
        },
        108: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 100,
            title: 'BioM % CH4',
            unit: '%CH4',
            mini: ''
        },
        109: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 100,
            title: 'Blend %',
            unit: '%',
            shortTitle: ''
        },
        107: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 300,
            title: 'BioM Flow',
            unit: 'm<sup>3</sup>/hr',
            mini: 'B'
        },
        110: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 48,
            title: 'BioM Remain',
            unit: 'hrs',
            mini: 'T',
            warnLevels:[4,8,null,null]
        },
        111: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 100,
            title: 'BioM Trailer Vol',
            unit: 'm<sup>3</sup>',
            mini: ''
        },
        113: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 100,
            title: 'Nat Gas Vol',
            unit: 'm<sup>3</sup>',
            mini: ''
        },
        114: {
            decimalPlaces:1,
            renderer: 'bar',
            max: 35,
            title: 'Nat Gas Temp',
            unit: '&deg;C',
            mini: ''
        },
        115: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 35,
            title: 'Nat Gas Pressure',
            unit: 'kpa',
            mini: ''
        },
        116: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 35,
            title: 'BioM Temp',
            unit: 'kpa',
            mini: ''
        },
        117: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 100,
            title: 'BioM Pressure',
            unit: 'kpa',
            mini: ''
        },
        118: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 1000,
            title: 'Load Power',
            unit: 'kW',
            mini: ''
        },
        119: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 4000,
            title: 'CHP1 Power',
            unit: 'kW',
            mini: '1'
        },
        120: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 2000,
            title: 'CHP2 Power',
            unit: 'kW',
            mini: '2'
        },
        121: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 2000,
            title: 'CHP3 Power',
            unit: 'kW',
            mini: '3'
        },
        122: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 2000,
            title: 'CHP4 Power',
            unit: 'kW',
            mini: '4'
        },
        123: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 2000,
            title: 'Export Power',
            unit: 'kW',
            mini: ''
        },
        124: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 500,
            title: 'LF Power',
            unit: 'kW',
            mini: ''
        },
        125: {
            decimalPlaces:0,
            renderer: 'bar',
            max: 500,
            title: 'BM Power',
            unit: 'kW',
            mini: ''
        },
        43: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: 100,
            title: 'Run Hours',
            unit: 'hrs',
            mini: ''
        },
        182: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Current',
            unit: 'amps',
            mini: ''
        },
        183: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Status',
            unit: '',
            mini: ''
        },
        184: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Full Load Current',
            unit: 'amps',
            mini: ''
        },
        185: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Tank Level',
            unit: '%',
            mini: ''
        },
        187: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 10,
            title: 'Suction Pressure',
            unit: 'bar',
            mini: ''
        },
        189: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 10,
            title: 'Discharge Pressure',
            unit: 'bar',
            mini: ''
        },
        190: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 500,
            title: 'Tank Volume',
            unit: 'm&#179;',
            mini: ''
        },
        191: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Temp1',
            unit: '&deg;C',
            mini: ''
        },
        192: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Temp2',
            unit: '&deg;C',
            mini: ''
        },
        193: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Temp3',
            unit: '&deg;C',
            mini: ''
        },
        194: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Gas Bag level',
            unit: 'm&#179;',
            mini: ''
        },
        195: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Digester Level',
            unit: 'm&#179;',
            mini: ''
        },
        197: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'CH4',
            unit: '%',
            mini: ''
        },
        198: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'H2S',
            unit: '%',
            mini: ''
        },
        199: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'O2',
            unit: '%',
            mini: ''
        },
        200: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'CO2',
            unit: '%',
            mini: ''
        },
        211: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Lube Oil Pressure',
            unit: 'bar',
        },
        212: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Lube Oil Temp',
            unit: '&deg;C',
        },
        213: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Jacket Water Pressure',
            unit: 'bar',
        },
        218: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Cabin Temp',
            unit: '&deg;C',
        },
        219: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Speed',
            unit: '',
        },
        220: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Exhaust Gas Temp Inlet',
            unit: '&deg;C',
        },
        221: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Exhaust Gas Temp Outet',
            unit: '&deg;C',
        },
        222: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Cyclinder Temp 1',
            unit: '&deg;C',
        },
        223: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Cyclinder Temp 2',
            unit: '&deg;C',
        },
        231: {
            decimalPlaces: 2,
            renderer: 'bar',
            max: 51,
            title: 'Frequency',
            unit: 'Hz',
        },
        232: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Reactive Power',
            unit: 'kW',
        },
        233: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Apparant Power',
            unit: 'kVA',
        },
        234: {
            decimalPlaces: 1,
            renderer: 'bar',
            max: 100,
            title: 'Reactive Energy',
            unit: 'kVarHr',
        },
        999: {
            decimalPlaces: 0,
            renderer: 'bar',
            max: 100,
            title: 'Day Cap',
            unit: '%',
            mini: '',
            alert: (v, all, avg) => (all.avgCF < 5) || (all.avgCF * 0.15) < v || (all.metrics[11] > 0) || (all.metrics[2] == undefined ? avg[2] < 6 : (all.metrics[2] < 6) ) ? false : true
        }
    },
    viewOrder: [1,7,2,6,5,9,3,4],
    views:{
        1: { title: "Wind", metrics: [2, 3, 11, 999], unitsInCell: false }, /* Wind */
        2: { title: "Biomethane", metrics: [11, 123, 103, 104, 108, 107, 111, 110, 118], unitsInCell: true }, /* Biomethane Only */
        5: { title: "Landfill", metrics: [11, 101, 102, 112], unitsInCell: true }, /* Landfill */
        6: { title: "Mixed BioGas", metrics: [11, 123, 109, 124, 101, 102, 112, 125, 103, 104, 108, 107, 111, 110], unitsInCell: true }, /* Mixed fuel BioMethane/Landfill */
        7: { title: "Supply Sites", metrics: [11, 123, 119, 120, 121, 122], unitsInCell: true }, /* Mixed fuel BioMethane/Landfill */
        8: { title: "Cable", metrics: [], unitsInCell: false }, /* Cable */
        9: { title: "Packaging", metrics: [11, 27], unitsInCell: false }, /* Industry */
        3: { title: "Mixed", metrics: [], unitsInCell: false },
        4: { title: "Solar", metrics: [], unitsInCell: false }
    }
}

export const deviceDefaultMetrics = {
    views: {
        1: { title: "Printer", metrics: [1, 2, 7], unitsInCell: false }, /* PRINTER */
        2: { title: "Meter", metrics: [1, 2, 7], unitsInCell: false }, /* METER */
        3: { title: "Electricity Meter", metrics: [11, 27], unitsInCell: false }, /* METER */
    }
}

export const listRequest ={
    windsites: 1,
    dvs: 2,
    controllers: 3,
	caseCats: 4,
	caseCats: 4,
    faultTypes: 5,
    operators:6,
    ScheduleEventTypes: 7,
    workTypes: 8,
    engineers: 9,
    engineersNexternalSupplier: 10,
    AlertModes: 11,
    TurbineModel: 12,
    ClientCompanies: 13,
    Industries: 14,
    TurbineManufacturers: 15,
    UniversalMetric: 16,
    Clients: 17,
    BillingTemplate: 18,
    FieldEngineer: 19,
    CaseState: 20,
    Parts: 21,
    PartTypes: 22,
    TurbineModelInUse: 23,
    PartSuppliers: 24,
    AlertTypes: 25,
    EmailTemplates: 26,
    NotificationEvents: 27,
    FormLookups: 28,
    DBFormLookups: 29,
    Warehouses: 30,
    Departments: 31,
    NonStockCats: 32,
    NonStockSubCats: 33,
    FormEntityGroups: 34,
    FormNavSections: 35,
    ClientRoles: 36,
    ClientTags: 37,
    DeviceTypes: 38,
    AllDevices: 39,
    AllClientCompanies: 40,
    PartsIncArchive: 41,
    OpenJobs: 42,
    WorkCardTypes: 43,
    WorkCardStatuses: 44,
    CalendarEventTypes: 45,
    ReportableFields: 46,
    AllJobStatuses: 47,
    PartNameIdentifier: 48,
    CustomerWithSites: 49,
    DNOs: 50,
    Tariffs: 51,
    ClientTariffs: 52,
    ClientForms: 53
}

export const checklistPositions = {
    MidForm: 1,
    PreForm: 2,
    PostForm: 3
}

export const checklistTypes = {
    Workcard: 1,
    Incident: 2,
    Case: 3
}

export const checklistQTypes = {
    bool: 1,
    string: 2,
    int: 3
}

export const metricGroups = {
    10: 'State',
    20: 'Energy',
    30: 'Power',
    50: 'Electricity',
    80: 'Mechanical',
    90: 'Fuel',
    100: 'Temperature',
    110: 'Other',
    120: 'Input/Output',
    130: 'Biomethane',
    140: 'Landfill',
    150: 'Gas',
}

export const turbineModels = {
    1: 'v27',
    3: 'v29',
    4: 'v39',
    5: 'v42',
    6: 'v44',
    7: 'N27',
    8: 'N29',
    10: 'B300',
    11: 'NTK 150',
    12: 'M750',
    14: 'DW54',
    21: 'WD34',
    25: 'Gas Engine',
    28: 'M530',
    30: 'FL250',
    31: 'B37'
}

export const inputTypes = [
    { id: 1, name: 'Text' },
    { id: 2, name: 'Number' },
    { id: 3, name: 'Date' },
    { id: 4, name: 'DB Entity Value' },
    { id: 5, name: 'Map' },
    { id: 6, name: 'Upload' },
    { id: 7, name: 'Textarea' },
    { id: 8, name: 'Checkbox' },
    { id: 9, name: 'Lookup' },
    { id: 10, name: 'DB Lookup' },
    { id: 11, name: 'Read Only' },
    { id: 12, name: 'Line break' },
    { id: 13, name: 'Coordinate Picker' },
    { id: 14, name: 'Table Value Lookup' },
    { id: 15, name: 'Tag Allocation' },
    { id: 16, name: 'Meter Readings' },
    { id: 17, name: 'Subtable' }
]

export const inlineInputTypes = [
    { id: 1, name: 'Text' },
    { id: 2, name: 'Number' },
    { id: 3, name: 'Date' },
    { id: 7, name: 'Textarea' },
    { id: 8, name: 'Checkbox' },
    { id: 11, name: 'Read Only' },
    { id: 12, name: 'Line break' }
]

export const limitedInputTypes = [
    { id: 1, name: 'Text' },
    { id: 2, name: 'Number' },
    { id: 3, name: 'Date' },
    { id: 5, name: 'Map' },
    { id: 6, name: 'Upload' },
    { id: 7, name: 'Textarea' },
    { id: 8, name: 'Checkbox' },
    { id: 9, name: 'Lookup' },
    { id: 10, name: 'DB Lookup' },
    { id: 11, name: 'Read Only' },
    { id: 12, name: 'Line break' },
    { id: 13, name: 'Coordinate Picker' },
    { id: 15, name: 'Tag Allocation' },
]

export const appEntityIcons = [
    { id: 'fas fa-analytics', name: 'Line Chart' },
    { id: 'fas fa-chart-pie', name: 'Pie Chart' },
    { id: 'fas fa-exclamation-triangle', name: 'Alert' },
    { id: 'fas fa-tools', name: 'Tools' },
    { id: 'fas fa-calendar-alt', name: 'Calendar' },
    { id: 'fas fa-sun-cloud', name: 'Weather' },
    { id: 'fas fa-broadcast-tower', name: 'Broadcast' },
    { id: 'fas fa-user-hard-hat', name: 'Health & Safety' },
    { id: 'fas fa-map-marked-alt', name: 'Map & Pin' },
    { id: 'fal fa-tachometer-alt-fast', name: 'Meter' },
]

export const colorSwatches = {
    0: ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
    1: ["#2f7ed8", "#0d233a", "#8bbc21", "#910000", "#1aadce", "#492970", "#f28f43", "#77a1e5", "#c42525", "#a6c96a"],
    2: ["#20707b", "#1f8f76", "#2fac66", "#5ec747", "#a7db22", "#fce51e", "#340042", "#371865", "#2f3776", "#26547b"],
    3: ["#6272a4", "#8be9fd", "#50fa7b", "#ffb86c", "#ff79c6", "#bd93f9", "#ff5555", "#f1fa8c", "#282a36", "#44475a"],
    4: ["#FC6451", "#FF932F", "#FFD000", "#00F7C6", "#00DF87", "#00C3E5"],
    5: ["#25476d", "#10505b", "#b72c31", "#c7c8ca", "#d48207", "#e36942", "#353a71" ]
}

export const colorShadeSwatches = {
    0: ["#44AF69", "#51b474", "#5eb87f", "#6bbd8b", "#78c296", "#85c7a1", "#91cbac", "#9ed0b7", "#abd5c2", "#b8dace", "#c5ded9", "#d2e3e4","#dfe8ef"],
    1: ["#ee6352", "#ed6e5f", "#eb796c", "#ea8479", "#e98f86", "#e89a93", "#e7a5a1", "#e5b1ae", "#e4bcbb", "#e3c7c8", "#e1d2d5", "#e0dde2", "#dfe8ef"],
    2: ["#0a92b1", "#1c99b6", "#2ea0bb", "#3fa7c1", "#51afc6", "#63b6cb", "#74bdd0", "#86c4d5", "#98cbda", "#aad3e0", "#bbdae5", "#cde1ea", "#dfe8ef"],
    3: ["#fac05e", "#f8c36a", "#f6c776", "#f3ca82", "#f1cd8e", "#efd19a", "#edd4a7", "#ead7b3", "#e8dbbf", "#e6decb", "#e4e1d7", "#e1e5e3", "#dfe8ef"],
    4: ["#000000", "#131314", "#252728", "#383a3c", "#4a4d50", "#5d6164", "#6f7478", "#82878b", "#959b9f", "#a7aeb3", "#bac1c7", "#ccd5db", "#dfe8ef"],
    5: ["#ffffff", "#F29727", "#F24C3D"]
}

export const publicRoutes = ["form"]

export const fieldServiceReportStatuses = [
    //{ id: 0, name: 'Unscheduled' },
    { id: 1, name: 'Scheduled' },
    { id: 2, name: 'On Site' },
    { id: 3, name: 'Complete' },
    { id: 4, name: 'Incomplete' }
]

export const dueByStatuses = [
    { id: 1, name: '1 Day' },
    { id: 2, name: '3 Days' },
    { id: 3, name: '1 Week' },
    { id: 4, name: '2 Weeks' },
    { id: 5, name: '4 Weeks' },
    { id: 6, name: '3 Months' },
    { id: 7, name: '6 Months' }
]

export const Hours24 = [
    { id: 0, name: '00' },
    { id: 1, name: '01' },
    { id: 2, name: '02' },
    { id: 3, name: '03' },
    { id: 4, name: '04' },
    { id: 5, name: '05' },
    { id: 6, name: '06' },
    { id: 7, name: '07' },
    { id: 8, name: '08' },
    { id: 9, name: '09' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' },
    { id: 16, name: '16' },
    { id: 17, name: '17' },
    { id: 18, name: '18' },
    { id: 19, name: '19' },
    { id: 20, name: '20' },
    { id: 21, name: '21' },
    { id: 22, name: '22' },
    { id: 23, name: '23' },
]

export const Months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'Feburary' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
]

 