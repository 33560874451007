import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GetStationsStateC, GetStationsStateIndustry } from '../../../api';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import MonitoringGrid from './MonitoringGrid'
import MonitoringLists from './MonitoringLists'

class MonitoringContainer extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '1'
        };
    }

    componentDidMount() {
        document.title = 'Monitoring | izon'
    }
    
    getSitesArray() {
        let ss = GetStationsStateC(this.props.commonstate);
        return (!ss)?[]:ss;
    }

    getSitesIndustryArray() {
        let ss = GetStationsStateIndustry(this.props.commonstate);
        return (!ss)?[]:ss;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
            activeTab: tab
            });
        }
    }
    getTab1(sitesarr) {
        const comp = '1'
        return (
            this.state.activeTab != comp?null:
            <MonitoringGrid sites={sitesarr} />
        )
    }
    getTab2(sitesarr) {
        const comp = '2'
        return (
            this.state.activeTab != comp?null:
            <MonitoringLists sites={sitesarr} />
        )
    }

    getLegend() {
        if (this.props.commonstate.header.client == 'everun' && this.props.commonstate.users.authRole && (this.props.commonstate.users.authRole.includes("Customer") || this.props.commonstate.users.authRole.includes("Administrator") )) {
            return (<div className='monitoringLegend'>
                <div style={{ backgroundColor: '#d0021b', marginRight:'3px' }}><span>Actioned Fault</span></div>
                <div style={{ backgroundColor: '#0295cf' }}><span>Scheduled Event</span></div>
            </div>)
        }
    }


    render() {
        const sitesarr = this.getSitesIndustryArray()

        return (
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                  Grid
                </NavLink>
              </NavItem>
              {this.getLegend()}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    {this.getTab1(sitesarr)}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        );
      }
    
};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(MonitoringContainer)
