import { fetchCollection, fetchRecord, createRecord, updateRecord, deleteRecord, select } from 'redux-crud-store'
import setRecord from './actions'

export default function Crud(props) {

  let { model, path = null, dispatch, parentId = null } = props;
  const PATH = path ||'/' + model;
  const MODEL = model;
  const xModel = (path && path.includes('/') ? path.replace('/','') : model)
  this.Model = MODEL

  this.fetchRecs = (params = {}) => {
      return fetchCollection(MODEL, PATH, params, { fetchConfig: { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } } })
  }

  this.fetchRec = (id, params = {}) => {
    return fetchRecord(MODEL, id, `${PATH}/${id}`, params, { fetchConfig: { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } } })
  }

  this.createRec = (data = {}) => {
      console.log('createRec', props)
      var t = createRecord(MODEL, PATH, data, {}, { fetchConfig: { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'), 'X-Model': xModel, 'X-ParentId': parentId } } })
      return t
  }

  this.updateRec = (id, data = {}, r) => {
    console.log('update rec', {id,data,r})
    var t = updateRecord(MODEL, id, `${PATH}/${id}`, data, { retain: r }, { fetchConfig: { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'), 'X-Model': xModel, 'X-ParentId': parentId } } })
    return t
  }

  this.deleteRec = (id) => {
      return deleteRecord(MODEL, id, `${PATH}/${id}`, {}, { fetchConfig: { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'), 'X-Model': xModel, 'X-ParentId': parentId } } })
  }

  this.GetRecsFromStore = function (state) {
    var result = []
    if (state.models && state.models.get(MODEL)) {
      var o = state.models.get(MODEL).toJS().byId;
      var keys = Object.keys(o);
      var result = keys.reduce(function(r,e) {
          return r.concat(o[e].record)
      },[]);
    }
    return result;
  }

  this.GetRecFromStore = function (state, id) {
    let result = {}
    if (state.models && state.models.get(ModelName)) {
      var o = state.models.get(ModelName).toJS().byId;
      result = o[id]
    }
    return result;
  }

  this.getData = (state, params = {}) => {
    //if (!params.hasOwnProperty('sspFilter') && state.models[MODEL] && state.models[MODEL].collections.length > 0 && state.models[MODEL].collections[state.models[MODEL].collections.length - 1].otherInfo.hasOwnProperty('sspFilter')) {
    //    params.sspFilter = state.models[MODEL].collections[state.models[MODEL].collections.length - 1].otherInfo.sspFilter;
    //    console.log('new params', params);
    // ISSUE WITH AUTOREFRESH
    //}

      var fetchstore = select(this.fetchRecs(params), state.models, { "interval": 3600000 }); //CRUD AUTO REFRESH INTERVAL
    fetchstore.getById = (id) => {
      let result = null
      fetchstore.data.forEach(function(r) {
          if (r && r.id == id) {
            result = r
          }
        })
      return result
    }

    fetchstore.getCurrent = (state) => {
      let result = {}
      
      if (state.crud.model[MODEL]) {
        const recid = state.crud.model[MODEL].currentRecId
        if (recid) {
          result = fetchstore.getById(recid)
        } else {
          result = {}
        }
      }
      return result?result:{}
    }

    /* This is a hack to fix a problem where crud-redux-store will initiate a fetch after create but not carry through with it. The redux stire is correct so we use that */
    /*
    if (fetchstore.isLoading && fetchstore.needsFetch) {
        fetchstore.data = this.GetRecsFromStore(MODEL,state)
        fetchstore.isLoading = false
    }
      */
    if (params.id == 'New') {
        fetchstore.isLoading = false
      }

    return fetchstore
  }

  this.getCurId = (state) => {
    let ret = null;
    if (state.crud && state.crud.model && state.crud.model[MODEL] ) { 
        ret = state.crud.model[MODEL].currentRecId
    }
    return ret
  }

    this.handleSubmit = (e, v, p) => {
        console.log('handleSubmit', {e,v,p})
        if (e.id > 0 || (e.id && e.id.length > 0)) {
            dispatch(this.updateRec(e.id, { ...e, params: p }, true))
        }
        else {
            dispatch(this.createRec(e));
        }
        return false
    }

  this.startAdd = () => {
    dispatch(setRecord(MODEL,0))
  }

  this.cancelEdit = () => {
    dispatch(setRecord(MODEL,null))
  }

  this.startDel = (id, label) => {
    dispatch(this.deleteRec(id))
  }

  this.startEdit = (id) => {
    dispatch(setRecord(MODEL,id))
  }
}