import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class SiteSettings extends Component {

    constructor(props) {
        super(props);
    }

    componentDidUpdate(pp) {
        if (pp.match.params.id != undefined && pp.match.params.id != this.props.match.params.id) {
            window.location.reload()
        }
    }

    render() {

        var SiteSelectionFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none', section: 'Site Details' },
            { label: "Site Name", fieldId: "name", width: 170, section: 'Site Details', canFilter: true },
            { label: "WindNi", fieldId: "windNiId", width: 170, display: 'form', section: 'Site Details' },
            { label: "Lat", fieldId: "lat", width: 170, display: 'form', section: 'Site Details' },
            { label: "Long", fieldId: "lon", width: 170, display: 'form', section: 'Site Details' },
            { label: "Hide", fieldId: "hide", width: 170, display: 'form', section: 'Site Details' },
            { label: "Turbine Size(kw)", fieldId: "turbineSize", width: 170, canFilter: true, section: 'Site Details' },
            { label: "Controller Type", fieldId: "controllerTypeId", list: listRequest.controllers, width: 170, canFilter: true, section: 'Site Details' },
            { label: "Wind Group", fieldId: "windGroupId", width: 170, display: 'form', canFilter: false, section: 'Site Details' },
            { label: "Alert Mode", fieldId: "alertModeId", list: listRequest.AlertModes, width: 170, display: 'form', canFilter: false, section: 'Site Details' },
            { label: "Turbine Model", fieldId: "turbineModelId", list: listRequest.TurbineModel, width: 170, canFilter: true, section: 'Site Details' },
            { label: "Company", fieldId: "dvid", list: listRequest.AllClientCompanies, width: 170, canFilter: true, section: 'Site Details' },
            { label: "Commision Date", fieldId: "commisionDate", type: "datetime", width: 170, display: 'form', canFilter: false, section: 'Site Details' },
            { label: "Accreditation Date", fieldId: "accrediationDate", type: "datetime", width: 170, display: 'form', canFilter: false, section: 'Site Details' },
            { label: "Industry", fieldId: "industryId", list: listRequest.Industries, width: 170, display: 'form', canFilter: false, section: 'Site Details' },
            { label: "Parent Id", fieldId: "parentId", list: listRequest.AllDevices, width: 170, display: 'form', canFilter: false, section: 'Site Details' },
            { label: "Device Type", fieldId: "deviceTypeId", list: listRequest.DeviceTypes, width: 170, display: 'form', canFilter: false, section: 'Site Details' },
            { label: "Tariff", fieldId: "tariff", list: listRequest.ClientTariffs, display: 'form', section: 'Site Details' },
            { label: "ModbusIp", fieldId: "modbusIP", width: 170, display: 'form', canFilter: false, section: 'RTU' },
            { label: "ModbusPort", fieldId: "modbusPort", width: 170, display: 'form', canFilter: false, section: 'RTU' },
            { label: "RocMeterAlertOffsetKw", fieldId: "rocMeter", width: 170, display: 'form', canFilter: false, section: 'RTU' },
            { label: "SimMSISDN", fieldId: "simMSISDN", width: 170, display: 'form', canFilter: false, section: 'RTU' },
            { label: "ControllerIp", fieldId: "controllerIp", width: 170, display: 'form', canFilter: false, section: 'RTU' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', section: 'Site Details' },
        ];

        var SiteSelectionConfig = {
            model: 'SiteSettings',
            fields: SiteSelectionFields,
            master: true,
            titleField: 'name',
            hideDelete: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH
        }

        var DeviceFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none', section: 'Site Details' },
            { label: "Device Name", fieldId: "name", width: 170, canFilter: true },
            { label: "WindNi", fieldId: "windNiId" },
            { label: "ParentId", fieldId: "parentId" },
            { label: "Device Type", fieldId: "deviceTypeId", list: listRequest.DeviceTypes },
            { label: "Industry", fieldId: "industryId", list: listRequest.Industries },
            { label: "Development Vehicle", fieldId: "dvid" },
            { label: "Hide", fieldId: "hide", display: 'form', },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/SiteSettings/' }, section: 'Site Details' },
        ];

        var DeviceConfig = {
            model: 'DeviceSettings',
            path: '/SiteSettings/GetDevices/' + this.props.match.params.id,
            fields: DeviceFields,
            gridButton: { pathname: '/SiteSettings/New', state: { parentId: this.props.match.params.id } },
            master: false,
            titleField: 'name',
            hideDelete: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 10 },
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Sites</div>
                <MasterDetail key='mdcs' {...SiteSelectionConfig}>
                    <MasterDetail key='mdcu' {...DeviceConfig} /> 
                </MasterDetail>
            </div >
            );
    }
}

export default (SiteSettings)