import React, { Component } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap';
import EventsWorkflow from './EventsWorkflow';
import AssetSchedule from './AssetSchedule';

export default class Events extends Component {

    constructor(props) {
        super(props);

        this.state = { activeTab: '1' }
    }

    navToggle(tab) {
        if (this.state.activeTab != tab) {
            this.setState({ activeTab: tab });
        }
    }

    renderEventsWorkflow() {
        return(
        <div className="d-flex justify-content-end">
            <select className="form-control" style={{ maxWidth: '20%' }}>
                <option value="0">Show All Event Status</option>
                <option value="1">Next 6 Months</option>
                <option value="2">Next 12 Months</option>
            </select>

            <select className="form-control" style={{ maxWidth: '20%' }}>
                <option value="0">All Event Types</option>
                <option value="1">Next 6 Months</option>
                <option value="2">Next 12 Months</option>
            </select>
        </div>)
    }

    renderAssetSchedule() {

    }


    render() {
      
        return (
            <div>
            <h1>Events</h1>
            <div className="d-flex justify-content-between">
                <p style={{ fontSize: '1.3rem' }}>07/03/2018 to 08/06/2018</p>
                <select className="form-control" style={{maxWidth: '20%'}}>
                    <option value="0">Next 3 Months</option>
                    <option value="1">Next 6 Months</option>
                    <option value="2">Next 12 Months</option>
                </select>
            </div>

            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.navToggle('1'); }}>
                        Events WorkFlow
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.navToggle('2'); }}>
                        Asset Schedule
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <EventsWorkflow />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <AssetSchedule />
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>

            </div>
        );
    }
}
