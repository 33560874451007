import React, { Component } from 'react';
import WorkflowComponent from './WorkflowComponent'
import { Collapse, CardBlock, Card, CardTitle, Col, Form, FormGroup, Label, Input, FormText, Button, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';

export default class WfSubmit extends WorkflowComponent {

    constructor(props) {
        super(props);
        this.state = { text: ""  }
        this.initState(
            { text: ""  }
        )
        const { submitController = "", submit = "",submitWFData } = props;
        if (submitController && submit) {
            submitWFData(submitController, submit).then(
                (d) => {
                    console.log("submit", d);
                    this.setState({ text: d.message })
                }
            )
        } else {
            
        }
    }

    render() {
        return (
            <div style={{width :'100%', padding:'10px'}} className="card">
                <h5 class="card-title">{this.props.title}</h5>
                <p class="card-text">{this.state.text}</p>
            </div>
        );
    }
}