import React, { Component } from 'react'
import GoogleMap from 'google-map-react'
import { findZoomAndCenter } from '../../../utils/findZoomAndCenter'
import { withRouter } from 'react-router-dom'
import MapPath from './../../../components/Header/MapPath'

const stationMap = {
    defaultZoom: 11,
    defaultCenter: { lat: 55.0655387, lng: -6.8154664 },
    options: {
        fullscreenControl: false,
        maxZoom: 16,
        styles: [{ "featureType": "administrative", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative.country", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative.province", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "color": "#f2f2f2" }] }, { "featureType": "landscape.natural", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "color": "#e2e4e4" }] }, { "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "labels.text", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.station.airport", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.station.airport", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e2e4e4" }] }, { "featureType": "water", "elementType": "labels", "stylers": [{ "visibility": "off" }] }],
        gestureHandling: 'greedy'
    },
}

class MonitoringTileMap extends Component {

    constructor(props) {
        super(props);
    }

    titleClick(stationid) {
        this.props.history.push('/Scada/' + stationid)
    }

    assets2Paths(assets) {
        return assets.map((o, i) => {
            if (o.assetPath) {
                return o.assetPath
            }
        });
    }

    getPathExtremes(o) {
        var lPB = { lat: (o.lat - 0.1), lng: (o.lng - 0.1) }
        var uPB = { lat: (o.lat + 0.1), lng: (o.lng + 0.1) }

        var tPath = JSON.parse(o.assetPath)
        if (tPath) {
            var tCoor = tPath.coordinates
            lPB = { lat: tCoor[0].lat, lng: tCoor[0].lng }
            uPB = { lat: tCoor[tCoor.length - 1].lat, lng: tCoor[tCoor.length - 1].lng}
        }

        var t = [o, lPB, uPB]

        return t
    }

    render() {

        const o = this.props.station
        //console.log('o', o)
        var lPB = { lat: (o.lat - 0.1), lng: (o.lng - 0.1) }
        var uPB = { lat: (o.lat + 0.1), lng: (o.lng + 0.1) }
        const { center, zoom } = findZoomAndCenter(this.getPathExtremes(o), false)
        var assetPaths = this.assets2Paths([o])
        return (
        <div className='monitoringMiniMap'>
                <div className='miniMapHead' onClick={()=>this.titleClick(o.id)}>
                    <span>{o.name}</span>
                </div>
                <MapPath mapRef={this.mapRef} paths={assetPaths} />
                <div className='miniMap'>
                    <GoogleMap onGoogleApiLoaded={({ map, maps }) => this.mapRef = map} yesIWantToUseGoogleMapApiInternals options={stationMap.options} defaultCenter={center} defaultZoom={zoom} bootstrapURLKeys={{ key: "AIzaSyC5Rjf0BTAKzGGmfHuWROJGtfebKR5rB_U" }}>
                    </GoogleMap>
                </div>
        </div>
        )
    }
}

export default withRouter(MonitoringTileMap)
