import React, { Component } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';

export default class AnalysisTable extends Component {
    constructor(props) {
        super(props);
        this.csvHeader = '';
        this.csvBody = '';
    }

    componentDidMount() {
        const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

        const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
            v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

        document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
            const table = th.closest('table');
            const tbody = table.querySelector('tbody')
            Array.from(tbody.querySelectorAll('tr'))
                .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
                .forEach(tr => tbody.appendChild(tr));
        })));
    }

    renderHeaders() {

        if (this.props.analysisData && this.props.analysisData.analysisList[0] && this.props.analysisData.analysisList[0].chartDetails && this.props.analysisData.hideTable != true) {
            //var data = this.props.analysisData.analysisData.filter(x => x.showInLegend != false)
            var data = []
            this.props.analysisData.analysisList.map(x => {
                data = data.concat(x.analysisData)
            })
            var tempId = null;
            var groupName = '';
            this.csvHeader = '';

            this.csvHeader = this.props.analysisData.analysisList[0].chartDetails.xAxisTitle + ',';


            if (!data) {
                return null
            }
            return (<thead>
                <tr className="groupRow">
                    <th></th>
                    {
                        data.map(x => {
                            if (tempId != x.groupId && (x.hideOnTable == null || x.hideOnTable == false)) {
                                tempId = x.groupId;
                                return (
                                    <th key={tempId} colSpan={data.filter(c => c.groupId === tempId).length} style={{textAlign: 'center'}}>{x.groupName}</th>
                                )
                            }
                        })
                    }
                    
                </tr>
                <tr style={{ cursor:'pointer' }}>
                    <th>{this.props.analysisData.analysisList[0].chartDetails.xAxisTitle}</th>
                    {data.map(x => {
                        if (x.hideOnTable == null || x.hideOnTable == false) {
                            {
                                if (x.groupName) { groupName = x.groupName };
                                this.csvHeader += groupName + ' ' + (x.tName || x.name) + ',';
                            }
                            return (
                                <th key={x.name + x.uId}>{x.tName || x.name}</th>
                            )
                        }
                    })}
                </tr>
            </thead>)

        }
    }

    renderBody() {
        this.csvBody = '';
        if (this.props.analysisData && this.props.analysisData.analysisList[0] && this.props.analysisData.hideTable != true) {
            //var data = this.props.analysisData.analysisData.filter(x => x.showInLegend != false)
            var data = []
            this.props.analysisData.analysisList.map(x => {
                data = data.concat(x.analysisData)
            })
            var columnIndex = [];
            data.map(x => {
                Object.keys(x.data).map(y => {
                    if (!columnIndex.includes((y))) {
                        columnIndex.push((y));
                    }
                })
            })

            columnIndex.sort(function (a, b) { return a - b; });

            var returnRow = [];

            for (var i = 0; i < columnIndex.length; i++) {
                var index = columnIndex[i];
                var rowContent = [];
                rowContent.push(<td key={index + i}>{index.slice(-4) == '0000' ? moment.unix(index/1000).format('DD/MM/YY') : index}</td>);
                this.csvBody += index + ',';

                data.map(x => {
                    if (x.hideOnTable == null || x.hideOnTable == false) {
                        rowContent.push(<td key={i * index + x.name + x.groupId + x.uId}>{typeof x.data[index] === 'object' ? x.data[index].low + ' - ' + x.data[index].high : x.data[index]}</td>);
                        if (x.data[index] == undefined) {
                            this.csvBody += ',';
                        }
                        else {
                            this.csvBody += (typeof x.data[index] === 'object' ? x.data[index].low + ' - ' + x.data[index].high : x.data[index]) + ',';
                        }
                    }
                })

                this.csvBody += '\r\n';
                returnRow.push(<tr key={'row'+i}>{rowContent}</tr>);
            }

            return returnRow;
        }

    }

    exportData() {

        var csv = this.csvHeader + '\r\n' + this.csvBody;

        var fileName = this.props.analysisData.analysisList[0].chartDetails.chartId + ' Analysis Export ' + moment(this.props.analysisData.fromDate).format('DD-MM-YY') + ' - ' + moment(this.props.analysisData.toDate).format('DD-MM-YY');
        var a = window.document.createElement("a");
        var data = new Blob([csv], { type: 'text/csv' });
        a.href = window.URL.createObjectURL(data);
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        csv = '';
    }
    
    render() {
        if (this.props.analysisData != null && this.props.analysisData.hideTable != true) {
            return (
                <div>
                    {this.props.analysisData ? <i className="fa fa-download " style={{ cursor: 'pointer', marginTop: '15px', position: 'absolute' }} onClick={() => this.exportData()} /> : null}
                    <Table striped responsive bordered hover className="analysisTable" style={{ marginTop: '15px' }} >
                        {this.renderHeaders()}
                        <tbody>
                            {this.renderBody()}
                        </tbody>
                    </Table>
                </div>
            );
        }
        return (
            <div>
                </div>);
    }
};
