import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import IdNameDropdown from './IdNameDropdown'


export default class TestControlTags extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            user: "Unknown"
        }
    }
    
    test() {
        console.log("recx", this)
        let me = this;
        ApiGet('auth','UsersOnline')
            .then((data) => {
                console.log("user", data)
                this.setState( { user: data })
            });
    }

    render() {
        const { user } = this.state
        return (
            <div class="card text-center">
                <div class="card-header">
                    Image View
                </div>
                <div class="card-block">
                <Draggable
                    axis="x"
                    handle=".handle"
                    defaultPosition={{x:25,y:25}}
                    position={null}
                    grid={[25, 25]}
                    zIndex={100}
                    onStart={this.handleStart}
                    onDrag={this.handleDrag}
                    onStop={this.handleStop}>
                    <div>
                    <div className="handle">Drag from here</div>
                    <div>This readme is really dragging on...</div>
                    </div>
                </Draggable>
                </div>
            </div>
            
        )
    }
}

