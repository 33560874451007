import React, { Component } from 'react'

class ChartControls extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }

    getButtons() {  
        var plA = this.props.plotlineActive
        var buttonArray = []
        buttonArray.push(<button key='fb-btn' type="button" className="btn ch-ctrl" onClick={() => this.props.moveAction(true, 5)} ><i className="fa fa-fast-backward" /></button>)
        buttonArray.push(<button key='b-btn' type="button" className="btn ch-ctrl" onClick={() => this.props.moveAction(true, 0.1)}><i className="fa fa-step-backward" /></button>)
        buttonArray.push(<button key='p-btn' type="button" className="btn ch-ctrl" onClick={() => this.props.togglePlot()}>{plA ? <i className="fa fa-pause" /> : <i className="fa fa-play" />}</button>)
        buttonArray.push(<button key='f-btn' type="button" className="btn ch-ctrl" onClick={() => this.props.moveAction(false, 0.1)}><i className="fa fa-step-forward" /></button>)
        buttonArray.push(<button key='ff-btn' type="button" className="btn ch-ctrl" onClick={() => this.props.moveAction(false, 5)}><i className="fa fa-fast-forward" /></button>)


        //<button type="button" className="btn" style={{ cursor: 'pointer' }}>
        //    {this.state.plotlineActive ?
        //        <i className="fa fa-pause" /> :
        //        <i className="fa fa-play" />}
        //</button>
        return buttonArray
    }

    render() {
        return (
            <div className={'ch-ctrl-con' + (this.props.responOnly ? ' respon' : '')}>
                {this.getButtons()}
            </div>
        )
    }

}

export default ChartControls