import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class DeviceTypeSettings extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        var DeviceTypeFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Device Type", fieldId: "name", width: 170 },
            { label: "Config", fieldId: "config", display: 'form', width: 170, type: 'textarea', typeProps: { height: 200, prettyPrint: true } },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton'},
        ]

        var DeviceTypeConfig = {
            model: 'DeviceTypeSettings',
            fields: DeviceTypeFields,
            master: true,
            titleField: 'name',
            hideDelete: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH
        }

        var MetricFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Name", fieldId: "metricName", width: 170, canFilter: true },
            { label: "Units", fieldId: "metricUnits" },
            { label: "Tag Name", fieldId: 'tagName' },
            { label: "Full Tag Name", fieldId: 'fullTagName' },
            { label: 'Universal Metric', fieldId: 'umetId', list: listRequest.UniversalMetric },
            { label: 'UMetId', fieldId: 'umetId' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/MetricSettings/' } },
        ]

        var DeviceMetricsConfig = {
            model: 'DeviceTypeMetricSettings',
            path: '/DeviceTypeSettings/GetMetrics/' + this.props.match.params.id,
            gridButton: { pathname: '/MetricSettings/New', state: { deviceTypeId: this.props.match.params.id } },
            fields: MetricFields,
            master: false,
            titleField: 'metricName',
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Device Type Setup</div>
                <MasterDetail {...DeviceTypeConfig} >
                    {this.props.match.params.id != 'New' && <MasterDetail {...DeviceMetricsConfig} />}
                </ MasterDetail>
            </div >
            )
    }
}

export default (DeviceTypeSettings)