import React, { Component } from 'react';
import { Card, Form, FormGroup, Col } from 'reactstrap';
import Highcharts from 'highcharts/highstock';
import HistoricPicker from './ScadaHistoricPicker';
import Loader from '../../../components/Loader';
import moment from 'moment'
import FavMetrics from './FavMetrics';
import ChartControls from './ChartControls';
import SignalOverlay from './SignalOverlay'

export default class ScadaChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            yBinded: true, 
            loadingOverlay: true, 
            plotTime: moment().format('DD/MM/YY 00:00:00'), 
            setHistroic: false, 
            overlayMsg: 'Collecting Data',
            plotlineActive: false,
            maxAxisVals: {}, 
            infoOverlay: false,
            infoMsg: null,
            navFrom: null,
            navTo: null,
            navZoom: 0,
            noLoad: false
        }
    }

    componentDidMount() {
        this.renderChart()
        this.plotLineSet = false
    }

    componentWillReceiveProps(nextProps) {

        if (!this.props.isLive) {
            if (nextProps.favourites != this.props.favourites || nextProps.scadaData != this.props.scadaData) {
                if (!nextProps.isLive && nextProps.scadaData && nextProps.scadaData[0]) {
                    if (nextProps.favourites != this.props.favourites) {
                        this.setState({ overlayMsg: 'Collecting Data' }, () => this.setHistoricGraphData(false))
                    }
                    else {
                        this.setState({ overlayMsg: 'Collecting Data' })
                    }
                }
            }
            else if (nextProps.navData != this.props.navData && this.props.fromDate) {
                this.chart.update({ navigator: { enabled: true, series: { data: nextProps.navData } } })
                if (this.props.fromDate._d.toString().includes('British Summer Time')) {
                    this.chart.xAxis[0].setExtremes(moment(this.props.fromDate).subtract(1,'h').valueOf(), moment(this.props.toDate).subtract(1,'h').valueOf())
                }
                else {
                    this.chart.xAxis[0].setExtremes((this.props.fromDate).valueOf(), (this.props.toDate).valueOf())
                }
            }
        }
        else {
            if (this.props.scadaData != nextProps.scadaData) {
                this.updateGraphData()
            }
            if (nextProps.connectionState == 'error') {
                this.setState({ loadingOverlay: true, overlayMsg: 'Connection Error' })
            }
            else if (nextProps.connectionState == 'disconnected') {
                this.setState({ loadingOverlay: true, overlayMsg: 'Connection has timed out' })
            }
            else if (nextProps.connectionState == 'auth') {
                this.setState({ loadingOverlay: true, overlayMsg: 'Unable to authorise user' })
            }
            else {
                this.setState({ overlayMsg: 'Collecting Data' })
            }
            if (nextProps.connectionState == 'signal') {
                this.setState({ infoOverlay: true, infoMsg: <SignalOverlay />, overlayMsg: '' })
            }
            else if (nextProps.connectionState != 'signal' && this.state.infoOverlay) {
                this.setState({ infoOverlay: false})
            }
        }
    }

    getTimeData(origX) {
        this.props.getDataAtTime(origX)
        this.setState({ plotTime: moment(origX).format(this.props.analysisData ? 'DD/MM/YY HH:mm:ss.SSS' : 'DD/MM/YY HH:mm:ss')});
    }

    renderChart() {
        var setPlotLineFunctions = (chart) => {
            this.setPlotLineFunctions(chart);
        }

        if (this.chart != null) {
            this.chart.destroy();
        }

        var offset = new Date().getTimezoneOffset()
        this.chart = new Highcharts["Chart"](
            this.refs.chart, {
                chart: {
                    type: 'line',
                    height: 400,
                    events: {
                        click: (e) => {
                            this.plotJump(e)
                        }
                    }
                },
                time: {
                    timezoneOffset: offset
                },
                title: {
                    text: null
                },
                navigator: {
                    adaptToUpdatedData: false,
                    enabled: this.props.navData && true,
                    series: {
                        color: '#0a92b1',
                        lineWidth: 1,
                    },
                    outlineWidth: 0,
                    maskFill: 'rgba(102,133,194,0.1)',
                    handles: {
                        height: 40,
                        lineWidth: 0,
                        backgroundColor:'#095c6f'
                    }
                },
                scrollbar: {
                    enabled: true,
                    height: 0,
                    buttonArrowColor: '#FFF'
                },
                rangeSelector: {
                    selected: 1
                },
                tooltip: {
                    enabled: false
                },
                xAxis: {
                    type: 'datetime',
                    plotLines: [{ color: '#d0021b', width: 4, value: 0, zIndex: 5 }],
                    events: {
                        setExtremes: (e) => {
                            if (e.DOMEvent) {
                                this.navChange(e)
                            }
                        }
                    },
                },
                yAxis: [{
                    title: { text: null },
                    id: '_T',
                    //labels: '',
                    min: 0,
                    opposite: true
                }],
                plotOptions: {
                    series: {
                        marker: { enabled: false },
                        states: { hover: { enabled: false } },
                        connectNulls: true,
                        events: {
                            click: (e) => {
                                this.plotJump(e)
                            }
                        },
                        lineWidth: 1
                    }
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                colors: ['#5B8CD2', '#FFBA08', '#27C564', '#032B43', '#136F63', '#7D1D3F'],
				series: [{ id: this.props.defaultMetric, data: [] }],
                responsive: {
                    rules: [{
                        condition: { maxWidth: 576 },
                        chartOptions: { yAxis: { labels: { align: 'left' } } }
                    }]
                }
            },
            function (chart)
            {
                setPlotLineFunctions(chart);
            }
        );
    }

    navChange(e) {
        if (e.trigger) {
            if (e.DOMEvent.type == 'mousemove') {
                this.setState({ navFrom: e.min, navTo: e.max, navZoom: 0, noLoad: true })
            }
            else {
                this.setState({ navFrom: e.min, navTo: e.max, navZoom: 0, noLoad: false })
            }
        }
    }

    plotJump(e) {
        var plotLine = this.chart.xAxis[0].plotLinesAndBands[0].svgElem.translateX
        var diff = (this.chart.mouseDownX - this.chart.plotLeft) - plotLine - 5
        var abDiff = Math.abs(diff)
        if (diff < 0) {
            this.playPlotline(true, abDiff)
        }
        else {
            this.playPlotline(false, abDiff)
        }
    }

    setPlotLineFunctions(chart) {
        var line, clickX, clickY

        var getTimeData = (origX) => {
            this.getTimeData(origX)
        }

        var start = function (e) {
            document.addEventListener('mousemove', step)
            document.addEventListener('mouseup', stop)
            chart.xAxis[0].plotLinesAndBands[0].svgElem.css({ 'cursor': '-webkit-grabbing' })
            clickX = e.pageX - line.translateX
        }

        if (chart.xAxis[0].plotLinesAndBands[0]) {
            line = chart.xAxis[0].plotLinesAndBands[0].svgElem.css({ 'cursor': '-webkit-grab' }).translate(0, 0).on('mousedown', start)
        }

        var step = function (e) {
            if ((e.pageX - clickX) < chart.xAxis[0].width && (e.pageX - clickX) > 0) {
                line.translate(e.pageX - clickX)
                var axis = chart.xAxis[0]
                var plotLine = axis.plotLinesAndBands[0]
                var translation = plotLine.svgElem.translateX

                var origX = axis.toValue(translation) - axis.toValue(0) + plotLine.options.value
                origX = Math.round(Math.max(axis.min, Math.min(axis.max, origX)))

                getTimeData(origX)
            }         
        }

        var stop = function (e) {
            chart.xAxis[0].plotLinesAndBands[0].svgElem.css({ 'cursor': '-webkit-grab' })
            document.removeEventListener('mousemove', step)
            document.removeEventListener('mouseup', stop)
        }
    }

    togglePlayback() {
        if (this.state.plotlineActive){
            clearInterval(this.plotlineTimer)
        }
        else {
            this.plotlineTimer = setInterval(this.timer.bind(this), 50)
        }
        this.setState({ plotlineActive: !this.state.plotlineActive})
    }

    timer() {
        this.playPlotline()
    }

    playPlotline(rewind, val = 1)
    {
        if (this.chart.xAxis[0].plotLinesAndBands[0]) {
            var plotLine = this.chart.xAxis[0].plotLinesAndBands[0].svgElem;

            if (isNaN(plotLine.translateX)) {
                plotLine.translate(10)
            }
            
            if ((plotLine.translateX > (this.chart.plotSizeX - 10))) {
                this.togglePlayback();
                clearInterval(this.plotlineTimer);
                plotLine.translate(10)
            }
            else {
                if (rewind) {
                    plotLine.translate(plotLine.translateX - val);
                }
                else {
                    plotLine.translate(plotLine.translateX + val);
                }

                var axis = this.chart.xAxis[0];
                var newPlotLine = axis.plotLinesAndBands[0];
                var translation = newPlotLine.svgElem.translateX;
                var origX = axis.toValue(translation) - axis.toValue(0) + newPlotLine.options.value;
                origX = Math.round(Math.max(axis.min, Math.min(axis.max, origX)));

                this.getTimeData(origX)
            }
        }
    }

    resetPlotline() {
        var plotLine = this.chart.xAxis[0].plotLinesAndBands[0].svgElem
        clearInterval(this.plotlineTimer)
        plotLine.translate(0)
    }

    removeChartSeries(metricId, deviceId) {
        if (this.chart.get((deviceId + '_' + metricId)) != undefined) {
            this.chart.get((deviceId + '_' + metricId)).remove()
            console.log('removed', metricId + ' ' + this.props.getMetricName(metricId,deviceId) + ' ' + this.props.getMetricUnit(metricId, deviceId));
        }
    }

    toggleOverlay() {
        this.setState({ loadingOverlay: !this.state.loadingOverlay })
    }

    updateGraphData() {
        //LIVE DATA UPDATE
        var time = new Date().getTime()
        var value;
        var minYVal = null;
        if (this.state.yBinded) {
            minYVal = 0;
        }

        if (this.props.isLive) {
            this.chart.xAxis[0].options.plotLines[0].value = time; 
            if (this.chart.options.navigator.enabled == true) {
                this.chart.update({ navigator: { enabled: false } })
            }
        }

        if (this.state.loadingOverlay && this.chart.series[0] && this.chart.series[0].data.length > 1) {
            this.setState({ loadingOverlay: false })
        }

        if (this.chart.series[0] && this.chart.series[0].id == null && this.props.defaultMetric) {
            this.chart.series[0].id = this.props.defaultMetric;
        }

        if (this.props.favourites) {
            this.props.favourites[this.props.deviceId].map((metric, i) => {
                value = parseFloat(this.props.getScadaValue(metric, 1, this.props.deviceId));
                var unit = this.props.getMetricUnit(metric, this.props.deviceId);

                if (this.chart.series[i] != undefined) {
                    if (!isNaN(value)) {
                        var tMax = this.chart.series[i].dataMax
                        if (this.state.maxAxisVals[unit] == null || this.state.maxAxisVals[unit] < tMax) {
                            var tA = this.state.maxAxisVals
                            tA[unit] = tMax
                            this.chart.series[i].yAxis.update({ max: tMax })
                            this.setState({ maxAxisVals: tA })
                        }
                        this.chart.series[i].addPoint([time, value], false, this.chart.series[i].data.length > 20 ? true : false, false);
                    }
                }
                else {

                    var axisExists = false;

                    this.chart.yAxis.map(a => {
                        if (a.userOptions.id.toUpperCase() == unit.toUpperCase()) {
                            axisExists = true;
                        }
                    })

                    if (!axisExists) {
                        console.log('width', window.screen.width)
                        this.chart.addAxis({
                            id: unit,
                            title: {
                                text: null
                            },
                            opposite: this.chart.yAxis.length == 1 ? false : true,
                            labels: window.screen.width > 576 ? { format: '{value} ' + unit.replace('&deg;', '\u00B0') } : { enabled: false },
                            min: minYVal
                        });
                        console.log('axis added ' + unit);
                    }

                    this.chart.addSeries({ data: [], type: 'line', yAxis: unit, name: this.props.getMetricName(metric, this.props.deviceId), id: metric });
                    this.chart.series[i].addPoint([time, value], false, false, false);
                }
            })
        }

        if ((time - this.redrawTime > 500) || this.redrawTime == null) {
            this.chart.redraw();
            this.redrawTime = time;
        }
    }

    setHistoricGraphData(resetPL = true) {
        var minYVal = null;
        if (this.state.yBinded) {
            minYVal = 0;
        }
        while (this.chart.series.length > 0)
        {
            this.chart.series[0].remove(true)
        }

        Object.keys(this.props.scadaData).map(deviceId => {
            this.props.favourites[deviceId].map((metric, i) => {
                if (this.props.scadaData && this.props.scadaData[deviceId]) {
                    this.props.scadaData[deviceId].map(j => {
                        if (metric == j.tags.MetricId) {
                            var metricDef = this.props.getMetricDef(metric, deviceId),
                                unit = metricDef.unit
                            if (!this.chart.get(unit)) {
                                this.chart.addAxis({
                                    id: unit,
                                    title: {
                                        text: null
                                    },
                                    opposite: this.chart.yAxis.length == 1 ? false : true,
                                    labels: window.screen.width > 576 ? { format: '{value} ' + (unit && unit.replace('&deg;', '\u00B0')) } : { enabled: false },
                                    min: minYVal
                                });
                            }
                            var seriesId = (deviceId + '_' + metric)
                            var metricVals = []
                            var seriesType = 'line'
                            if (metricDef.chartType == 'column') {
                                //PARSE INTO BAR CHARTS
                                var timeRange = (this.props.toDate.diff(this.props.fromDate, 'hours')),
                                    division = (timeRange > 168) ? 1440 : (timeRange <= 2) ? 1 : 30,
                                    fromTime = (timeRange > 168) ? moment(j.values[0][0]).set('minute', 0).set('hour',0) : moment(j.values[0][0]).set('minute', 0),
                                    fromVal = null

                                seriesType = 'column'
                                j.values.map(sv => {
                                    var cDiv = moment(sv[0]).diff(moment(fromTime), 'minutes')
                                    if (cDiv >= division) {
                                        if (fromVal == null) {
                                            fromVal = sv[1]
                                        }
                                        var diffVal = (sv[1] - fromVal)
                                        metricVals.push([sv[0], diffVal])
                                        fromTime = sv[0]
                                        fromVal = sv[1]
                                    }
                                })
                            }
                            else {
                                metricVals = j.values
                            }
                            this.chart.addSeries(
                            {
                                data: metricVals,
                                type: seriesType,
                                yAxis: unit,
                                name: deviceId + ' - ' + metricDef.name,
                                id: seriesId
                            }, false)
                        }
                    })
                }
            })
        })
		var flag = this.props.analysisData ? moment(this.props.analysisData.vdfHeader.trigDateTime).valueOf() : null
		if (flag) {
			var title = this.props.analysisData.vdfHeader.errorNum
			this.chart.addSeries({ data: [{ x: flag, title: title ? 'Error ' + title : 'Error' }], type: 'flags', y: -252, color:'#000'});
		}

        this.renderPlotBands()

        if (this.props.navData) {
            this.chart.update({ navigator: { enabled: true, series: {data:this.props.navData} }})
            this.chart.addSeries({ data: this.props.navData, showInNavigator: true, color: 'rgba(255,255,255,0)' })
            if (this.props.fromDate._d.toString().includes('British Summer Time')) {
                this.chart.xAxis[0].setExtremes(moment(this.props.fromDate).subtract(1, 'h').valueOf(), moment(this.props.toDate).subtract(1, 'h').valueOf())
            }
            else {
                this.chart.xAxis[0].setExtremes((this.props.fromDate).valueOf(), (this.props.toDate).valueOf())
            }
        }

        if (resetPL) {     
            if (this.chart.series[0] && this.chart.series[0].data) {
                if (this.props.fromDate._d.toString().includes('British Summer Time')) {
                    this.chart.xAxis[0].options.plotLines[0].value = moment(this.props.fromDate).subtract(1, 'h').valueOf()
                }
                else {
                    this.chart.xAxis[0].options.plotLines[0].value = this.props.fromDate.valueOf()
                }
                this.chart.xAxis[0].update()
                this.setPlotLineFunctions(this.chart)
                this.setState({ maxAxisVals: {} })
                this.chart.yAxis.map(a => { a.update({ max: null }) })
            }
            this.setState({ loadingOverlay: false })
        }
        this.playPlotline()
        this.playPlotline(true)
    }

    renderPlotBands() {
        var maxTime = this.chart.xAxis[0].getExtremes().max;
        var minTime = this.chart.xAxis[0].getExtremes().min;
        var fromTime = null;
        var toTime = null;

        if (this.props.recentAlerts) {
            this.props.recentAlerts.recentAlerts.map((x, i) => {
                if (moment(x.raised).format('x') > minTime && moment(x.raised).format('x') < maxTime) {
                    fromTime = moment(x.raised).format('x')
                    toTime = moment(x.closed).format('x')
                    if (toTime > maxTime) {
                        toTime = maxTime
                    }
                    this.chart.xAxis[0].addPlotBand({
                        from: fromTime,
                        to: toTime,
                        color: '#d6d6d6',
                        id: i
                    })
                }
            })
        }
    }

    moveNav(type) {
        var from = this.props.fromDate, to = this.props.toDate, 
            multiplier = this.state.navZoom + 0.5, diff = moment.duration(to.diff(from)).asMinutes()
        if (type == 'zoom') {
            var fromDiff = from.subtract(diff * multiplier, 'm')
            var toDiff = to.add(diff * multiplier, 'm')
            console.log('** MOVE NAV **')
            this.setState({ navZoom: multiplier }, () => this.props.getHistoricData(fromDiff, toDiff, false, true))
        }
        else if (type == 'left') {
            var f = from.subtract(diff, 'm'), t = to.subtract(diff, 'm')
            this.setState({ navFrom: f.valueOf(), navTo: t.valueOf(), navZoom: 0, noLoad: false })
        }
        else if (type == 'right') {
            var f = from.add(diff, 'm'), t = to.add(diff, 'm')
            this.setState({ navFrom: f.valueOf(), navTo: t.valueOf(), navZoom: 0, noLoad: false })
        }
    }

    bindYAxis() {
        var minVal = 0;
        if (this.state.yBinded) {
            minVal = null
        }

        this.chart.yAxis.map(a => {
            a.update({
                min: minVal
            })
        })

        this.setState({ yBinded: !this.state.yBinded });
    }

    render() {
        return (
            <div style={{ paddingTop: '10px' }}>
                <Card>
                    <div style={{ paddingBottom: 0 }} className="card-block">
                        <Form>
                            {this.props.scadaGroups ? <HistoricPicker ref="historicPicker" isLive={this.props.isLive} recentAlerts={this.props.recentAlerts} getHistoricData={(fromDate, toDate, getFuture) => this.props.getHistoricData(fromDate, toDate, getFuture)} plotlineActive={this.state.plotlineActive} moveAction={(r, v) => this.playPlotline(r, v)} togglePlayback={(plotlineActive) => this.togglePlayback(plotlineActive)} toggleLive={(toggleLive) => this.props.toggleLive(toggleLive)} externalDates={{from:this.state.navFrom,to:this.state.navTo,noLoad:this.state.noLoad}} renderChart={() => this.renderChart()} toggleOverlay={() => this.toggleOverlay()} toggleGetGroups={() => this.props.toggleGetGroups()} analysisData={this.props.analysisData} getVDFData={() => this.props.getVDFData()} /> : <div style={{height: '33.5px'}} />}
                            <FormGroup row style={{marginBottom: '0.5rem'}}>
                                <Col xs={12} lg={12} style={{ paddingRight: 0, paddingLeft: 0 }}>
                                    <div className='chartOptionsBtns'>
                                        {this.props.isLive || this.props.analysisData ? null : <i className="fa fa-download chart-option-btn" style={{ marginRight: '45px' }} onClick={() => this.props.toggleExportModal()} />}
                                        <i className={this.state.yBinded ? "fa fa-lock chart-option-btn" : "fa fa-unlock chart-option-btn"} onClick={() => this.bindYAxis()} />
                                    </div>
                                    <div ref="chart" className="scada-chart" />
                                    <div className={this.state.loadingOverlay ? "disabled-overlay" : "disabled-overlay hide-overlay"} style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                                        <Loader height="0" reconnect={this.props.retryConnection} customMessage={this.state.overlayMsg} toggleLive={(toggleLive) => this.props.toggleLive(toggleLive)} />
                                    </div>
                                    <div className={this.state.infoOverlay ? "disabled-overlay" : "disabled-overlay hide-overlay"}>
                                        <div className='infoOverlay'>{this.state.infoMsg}</div>
                                    </div>
                                </Col>
                            </FormGroup>
                            {!this.props.isLive && <div className='d-block d-sm-none'>
                                <ChartControls moveAction={(r, v) => this.playPlotline(r, v)} togglePlot={() => this.togglePlayback()} plotlineActive={this.state.plotlineActive} responOnly={true} />
                            </div>}
                            {!this.props.isLive &&
                                <div>
                                <div className='chartNav' style={{ left:30}}>
                                    <i className='fas fa-search-minus' onClick={() => this.moveNav('zoom')} />
                                </div>
                                <div className='chartNav' style={{left:55}}>
                                    <i className='fas fa-chevron-left' onClick={() => this.moveNav('left')} />
                                </div>
                                <div className='chartNav' style={{right:35}}>
                                    <i className='fas fa-chevron-right' onClick={() => this.moveNav('right')} />
                                </div>
                                </div>}
                            <FavMetrics showTime={this.props.isLive ? null : this.state.plotTime} /* string (label), null = hide */
                                scadaGroups={this.props.scadaGroups}
                                seriesColors={(this.chart && this.chart.series) ? this.chart.series.map((d) => d.color) : []} /* array of color */
                                favourites={this.props.favourites} /* array of metricid */
                                getMetricName={this.props.getMetricName} /* func */
                                toggleFavMetric={this.props.toggleFavMetric} /* func */
                                getScadaValue={this.props.getScadaValue} /* func */
                                getMetricUnit={this.props.getMetricUnit} /* func */
                                allowAdding={true}
                                devices={this.props.devices}
                                />
                        </Form>
                    </div>
                </Card>
            </div>
        );
    }
}
