import React, { Component } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';
import { turbineModels } from '../../../configuration/enums'
import SimpleModal from './../../../components/SimpleModal'

export default class SchematicTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            header: null, body: null, footer: null, open: false
        }
        this.group = null
        this.imgBase = null
        this.tModel = null
        this.tDesc = null
        this.tId = null
    }

    getTemplateRender(t) {
        return (
        <div key={'te'+t.schematicTemplateId} className='templateContainer'>
            <Row style={{margin:0, height: '120px', overflow:'hidden'}}>
                <Col xs={7} style={{padding:0, overflow:'hidden'}}>
                    <img height='120px' style={{ position: 'absolute' }} src={t.schematicImg ? t.schematicImg : '../../../content/schematics/1.png'} />
                        <button className='btn' style={{ position: 'absolute', bottom: 0, padding: '.5rem' }} onClick={() => { this.tModel = t.turbineModelId; this.tDesc = t.schematicDesc; this.imgBase = t.schematicImg; this.tId = t.schematicTemplateId; this.modifyTemplate(); }}><i className="fal fa-edit"  /></button>
                </Col>
                <Col xs={5}>
                    <Row style={{justifyContent:'center'}}><span>{t.schematicDesc ? t.schematicDesc : 'Template Id ' + t.schematicId}</span></Row>
                    <Row style={{ justifyContent: 'center' }}><button style={{ position: 'absolute', bottom: '5px', right: 0 }} onClick={() => this.props.useTemplate(t.schematicTemplateId)} className="btn btn-primary" >Use <i className="fal fa-check-circle"></i></button></Row>
                </Col>
            </Row>
        </div>
        )
    }
    getImgBase(file) {
        if(file.type.match(/image.*/)) {
            var reader = new FileReader();
            reader.addEventListener('load', (readerEvent) => {
                var image = new Image();
                image.addEventListener('load', (imageEvent) => {

                    var canvas = document.createElement('canvas'),
                        max_size = 1700,
                        width = image.width,
                        height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    var dataUrl = canvas.toDataURL('image/jpeg');
                    this.imgBase = dataUrl
                    this.modifyTemplate()
                })
                image.src = readerEvent.target.result;
            })
            reader.readAsDataURL(file);
        }
    }

    modifyTemplate() {
        //const { tModel, tDesc, imgBase } = this.state;
        var header = <span>{this.tId ? 'Edit' : 'Create'} Template</span>,
            body = <div>
                <Row>
                    <Col sm="4"><b>Description </b></Col>
                    <Col sm="8">
                        <Input onChange={(e) => this.tDesc= e.target.value} defaultValue={this.tDesc} style={{ width: "100%" }} />
                    </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col sm="4"><b>Model </b></Col>
                    <Col sm="8">
                        <Input type='select' onChange={(e) => this.tModel = e.target.value} defaultValue={this.tModel} style={{ width: "100%" }} >
                            <option key={'all'} value={0}>Universal</option>
                            {Object.keys(turbineModels).map(x => {
                                return <option key={x} value={x}>{turbineModels[x]}</option>
                            })}
                        </Input>
                    </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col sm="4"><b>Schematic </b></Col>
                    <Col sm="8">
                        <Input type="file" accept="image/*" onChange={(e) => this.getImgBase(e.target.files[0])} />
                        <div className='schematicImgHolder'>
                            {this.imgBase ? <img width='250px' src={this.imgBase} /> : <p>Upload a schematic</p>}
                        </div>
                    </Col>
                </Row>
            </div>,
            footer = <div style={{ width: '100%' }}>
                <Button onClick={() => { this.setState({ open: !this.state.open }); this.tModel = null; this.tDesc = null; this.imgBase = null; }}>Cancel</Button>
                <Button style={{ float: 'right' }} onClick={() => { console.log('te', this.tId); this.props.saveTemplate(this.tId, this.tModel, this.imgBase, this.tDesc); this.setState({ open: !this.state.open }); this.tModel = null; this.tDesc = null; this.imgBase = null; this.tId = null; }}>Save</Button>
            </div>

        this.setState({ open: true, header, body, footer })
    }

    render() {
        const { templates } = this.props;
        const { footer, header, body, open, imgBase } = this.state;
        return (
            <div style={{minHeight:'700px'}}>
                <Col><Row style={{ padding: '10px 0', justifyContent: 'space-between' }}>
                    <h3>Select schematic</h3>
                    <div>
                        <button className="btn btn-primary" onClick={(e) => this.modifyTemplate()} >Create template <i className="fal fa-plus-circle" /></button>
                        <button className="btn" style={{marginLeft:'5px'}} onClick={() => this.props.toggleEnableTemp()} >Cancel</button>
                    </div>
                </Row></Col>
                {
                    templates && templates.map((x,i) => {
                        var tReturn = []

                        if (this.group != x.turbineModelId) {
                            tReturn.push(<div key={'t'+i} style={{marginTop:'15px', borderTop:'1px solid #ccc', paddingTop:'10px'}}>{x.turbineModelId == 0 ? 'Universal' : turbineModels[x.turbineModelId]}</div>)
                            this.group = x.turbineModelId
                        }

                        tReturn.push(this.getTemplateRender(x))

                        return tReturn

                    })
                }
            <SimpleModal {...{ header, body, footer, open,  imgBase}} />
            </div>
        );
    }
}
