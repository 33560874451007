import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'

class FavMetrics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false
        }

    }

    renderFavouriteRow() {

        var fullRow = [], seriesColor = '#888'
        const { seriesColors } = this.props
        if (this.props.scadaGroups) {
            Object.keys(this.props.favourites).map(dId => {
                var deviceName = this.props.devices && this.props.devices.find(d => d.id == dId).name
                this.props.favourites[dId].map((i, index) => {
                    if (seriesColors && seriesColors[index] != undefined) {
                        seriesColor = seriesColors[index]
                    }
                    var decimalPlaces = 1

                    if (this.props.scadaGroups[dId]) {
                        if (this.props.scadaGroups[dId][i] && i == this.props.scadaGroups[dId][i].metricId) {
                            var arr = this.props.scadaGroups[dId]
                            arr = arr.filter(function (e) { return e });
                            decimalPlaces = arr.find(x => x.metricId == i).decimalPlaces;
                        }
                        else {
                            decimalPlaces = this.props.scadaGroups[dId].find(x => x != null && x.metricId == i) ? this.props.scadaGroups[dId].find(x => x != null && x.metricId == i).decimalPlaces : 0
                        }
                    }
                    if (this.props.scadaGroups[dId]) {
                        fullRow.push(<div className="fav-badge" key={dId + '_' + i}>
                            {this.props.toggleFavMetric ? <div style={{ verticalAlign: 'super' }}><i className="fa fa-star fav" onClick={() => this.props.toggleFavMetric(i, dId)} /></div> : null}
                            <div>
                                {this.props.devices && this.props.devices.length > 1 && <span className='favMetricDevice'>{deviceName}</span>}
                                <span>{this.props.getMetricName(i, dId)}</span>
                                <span className="scada-badge" style={{ borderBottom: seriesColor + ' solid 2px' }}>
                                    <b>{this.props.getScadaValue(i, decimalPlaces, dId)}</b> {this.props.getMetricUnit(i, dId).replace('&deg;', '\u00B0')}
                                </span>
                            </div>
                        </div>)
                    }
                })
            })
        }

        return fullRow
    }

    render() {
        const { allowAdding, scadaGroups, showTime, toggleFavMetric} = this.props;
        return (
        <Row className='favMetricRow'>
            <Col xs={12} lg={12} style={{padding: '0 5px'}}>
                {showTime && <div className="fav-badge time-badge"><span style={{lineHeight:'38px'}}>{showTime}</span></div>}
                {this.renderFavouriteRow()}
            </Col>
        </Row>)
    }

}

export default FavMetrics