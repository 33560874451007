import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
//import MasterDetail from '../../../testComp/MasterDetail/src'
import { listRequest } from '../../../configuration/enums'
import _ from 'lodash'
import { ApiPost, ApiGet, getConfigItem } from '../../../api'
import { Nav, NavItem, NavLink, TabContent, TabPane, Button, Col, Row, Input  } from 'reactstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import SimpleModal from '../../../components/SimpleModal'
export const API_PATH = process.env.SERVER_PATH + "/api";

class PurchaseOrder extends Component {

    constructor(props) {
        super(props);
        var hidePONo = getConfigItem('$.finance.hidePONo') || false
        this.state = {
            poStatus: null, poId: 1, slId: 1, nslId: 1, activeTab: 1, mainActiveTab: 1, awaitingApproval: [], wfStatus: null, customFilter: null,
            wfStatuses: { 1: 'Request Approval', 2: 'Approve Purchase Order', 3: 'Approve Variance', 4: 'Request Variance Approval', 5: 'Goods Received' },
            footer: null, header: null, body: null, open: false, tDate: moment().utc(), sendingReminders: false, hidePONo
        };
    }

    componentDidMount() {
        this.getStatus()
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(this.props.match) != JSON.stringify(nextProps.match)) {
            //this.logDifferences('Props', this.props.match, nextProps.match);
            return true
        }
        else if (JSON.stringify(this.props.commonstate.users) != JSON.stringify(nextProps.commonstate.users)) {
            //this.logDifferences('Props', this.props.commonstate.users, nextProps.commonstate.users);
            return true
        }
        else if (this.state != nextState) {
            //this.logDifferences('State', this.state, nextState);
            return true
        }
        else {
            return false
        }
    }

    logDifferences(type, current, next) {
        Object.keys({ ...current, ...next }).forEach(key => {
            if (current[key] !== next[key]) {
                console.log(`${type} difference in key: ${key}`);
                console.log('Current value:', current[key]);
                console.log('Next value:', next[key]);
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.setState({ poStatus: null, activeTab: 1, mainActiveTab: 1 })
            if (!isNaN(this.props.match.params.id)) {
                this.getStatus()
                this.setState({ poId: Math.random() })
            }
            else {
                this.setState({ poStatus: 0 })
            }
        }
    }

    filterChange(e) {
        var newFilter = []
        if (this.state.customFilter) {
            newFilter = this.state.customFilter
        }
        if (e.key == 'filter') {
            newFilter[e.key] = e.filtered
            this.setState({ customFilter: newFilter.filter })
        }
    }

    getStatus() {
        if (this.props.match.params.id) {
            ApiGet('Stock', 'GetPOStatus', { id: this.props.match.params.id })
            .then((data) => {
                this.setState({ poStatus: data.status, poId: Math.random(), wfStatus: data.wfStatus })
            })
        }
    }

    receiveAll() {
        ApiPost('Stock', 'POReceiveAll', { id: this.props.match.params.id })
        .then((data) => {
            this.reloadForms()
        })
    }

    submitCallback(e) {
        console.log('submit callback')
        setTimeout(() => this.setState({ poId: Math.random() }),1000)
    }

    reloadForms() {
        this.setState({ poId: Math.random(), nslId: Math.random, slId: Math.random }, () => { this.getStatus(); })
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    workflowAction(rec) {
        ApiPost('Stock', 'poWorkflow', { id: rec.id })
        .then((data) => {
            this.reloadForms()
        })
    }

    workflowLabel(rec) {
        return this.state.wfStatuses[this.state.wfStatus]
    }

    getSupplierEmail(rec) {
        ApiGet('Stock', 'GetSupplierEmail', { supplierId: rec.partSupplierId})
        .then((data) => {
            var email = data.email
            this.toggleEmailModal(rec,email)
        })
    }

    sendReminders() {
        this.setState({ sendingReminders: true })
        ApiPost('Notifications', 'POReminders', { id: '1' })
        .then((d) => {
            this.setState({ sendingReminders: false })
            if (d.status == 1) {
                alert(d.count + ' reminders sent')
            }
            else {
                alert('Reminders not sent: ' + d.error)
            }
        })
    }

    toggleEmailModal(rec, preEmail) {
        var email = preEmail
        var cc = false
        var header = <b>Confirm Email</b>
        var body = <div>
                        <Row>
                            <Col sm="4"><b>Supplier Email</b></Col>
                            <Col sm="8">
                                <Input onChange={(e) => email = e.target.value} defaultValue={email} style={{ width: "100%" }} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col sm="4"><b>CC yourself?</b></Col>
                            <Col sm="8">
                                <Input type="checkbox" style={{marginLeft:'2px'}} onClick={() => cc = !cc} />
                            </Col>
                        </Row>
                    </div>
        var footer = <div style={{ width: '100%' }}>
            <Button style={{ float: 'left' }} onClick={() => this.setState({open:!this.state.open})}>Cancel</Button>
            <Button color='primary' style={{ float: 'right' }} onClick={() => this.sendToSupplier(rec, cc, email)}>Send Email</Button>
        </div>
        this.setState({header,body,footer,open:!this.state.open})
    }

    sendToSupplier(rec, cc, email) {
        if (email != '') {
            ApiPost('Stock', 'EmailSupplier', { id: rec.id, cc, email })
            .then((data) => {
                console.log('email sent') 
            })
        }
        this.setState({ open: !this.state.open })
    }

    getPONo(data, isAdmin, status) {
        var value = '-'
        if (isAdmin) {
            value = data.invoiceNo
        }
        return (
            <input name="invoiceNo" class="form-control" readonly="" type="readOnly" value={value} style={{ width:'79%',float:'right' }} />
        )
    }

    getMD(tabStatus) {
        var userId = this.props.commonstate.users.authInternalId, rId = this.props.match.params.id, status = this.state.poStatus
        var isAdmin = this.props.commonstate.users.authRole.includes("Administrator") || this.props.commonstate.users.authRole.includes("StockAdmin")
        var lists = {
            stockCat: [{ id: 1, name: 'CON' }, { id: 2, name: 'CP' }, { id: 3, name: 'SER' }, { id: 4, name: 'PRT' }],
            wh: [{ id: 1, name: 'Default' }],
            status: [{ id: 0, name: 'Open' }, { id: 1, name: 'Awaiting Approval' }, { id: 2, name: 'Approved' }, { id: 3, name: 'Received' }, { id: 4, name: 'Awaiting Variance Approval' }, { id: 5, name: 'Complete' }, { id: 6, name: 'Part Received' }],
            departments: [{ id: 1, name: 'O&M' }, { id: 2, name: 'AM' }, { id: 3, name: 'Software/IT' }, { id: 4, name: 'Office' }],
            nc: [{ id: 1, name: 'Non chargeable' }, { id: 2, name: 'Chargeable' }],
        };
        var iN = rId == 'New'
        var poFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'PO No', fieldId: 'invoiceNo', type: 'readOnly', display: iN ? 'none' : 'both', canFilter: true, width: 80 },
            { label: 'Department', fieldId: 'departmentId', list: listRequest.Departments, validation: 'required', width: 190, readOnly: status > 1 && !isAdmin && true },
            { label: 'Supplier', fieldId: 'partSupplierId', width: 220, list: listRequest.PartSuppliers, readOnly: status > 1 && !isAdmin && true, validation: 'required', },
            { label: 'Billable Company', fieldId: 'billableCompanyId', display: 'form', list: listRequest.ClientCompanies },
            { label: 'Site Ref', fieldId: 'billableSiteId', display: isAdmin ? 'both' : 'form', list: listRequest.windsites, width: 120 },
            { label: 'JobId', fieldId: 'jobId', width: 70, list: listRequest.OpenJobs },
            { label: 'Note', fieldId: 'note', display: isAdmin ? 'both' : 'form' },
            { label: 'Requisitioned By', fieldId: 'requisitionedBy', display: 'form', list: listRequest.operators, width: 130, readOnly: status > 1 && !isAdmin && true },
            { label: 'Raised By', fieldId: 'completedBy', display: (!iN && isAdmin) ? 'both' : 'none', list: listRequest.operators, width: 130, readOnly: true },
            { label: 'Raised Date', fieldId: 'orderDate', type: 'date', readOnly: status > 1 && !isAdmin && true, width: 100, display: 'form' },
            { label: 'Raised Date', fieldId: 'tOrderDate', type: 'date', readOnly: status > 1 && !isAdmin && true, width: 100, display: 'grid' },
            { label: 'Receive Date', fieldId: 'deliverDate', type: 'datetime', display: iN ? 'none' : 'form' },
            { label: 'HOD Approval', fieldId: 'primaryApprovalUserId', readOnly: true, list: listRequest.operators, display: iN ? 'none' : 'form' },
            { label: 'Status', fieldId: 'status', list: lists.status, display: iN ? 'none' : 'both', readOnly: true, width: 150 },
            { label: 'Awaiting', fieldId: 'statusBlocker', display: !iN ? 'both' : 'none', readOnly: true, list: listRequest.operators, width: 100 },
            { label: 'Expected Value', fieldId: 'expectedValue', type: 'info', col: 2, display: iN ? 'none' : 'both', width: 170 },
            { label: 'Total Value', fieldId: 'totalValue', type: 'info', col: 2, display: iN ? 'none' : 'form' },
            //{ label: 'Cmnt', fieldId: 'commentCount', display: isAdmin ? 'grid' : 'none', width: 70 },
            { label: '', fieldId: 'workflowBtn', display: iN ? 'none' : 'form', type: 'funcButton', displayIf: (rec) => rId != 'New' && this.workflowLabel(rec), typeProps: { label: (rec) => this.workflowLabel(rec), style: { width: '100%', backgroundColor: '#0a92b1', color: '#FFF' }, func: (rec) => this.workflowAction(rec) } },
            { label: '', fieldId: "navButton", width: 60, display: 'grid', type: 'navButton' },
            { label: '', fieldId: 'sendToSupplier', col: 2, display: iN ? 'none' : 'form', type: 'funcButton', typeProps: { label: <span><i className="fal fa-envelope" /> Email PO to supplier</span>, style: { width: '100%', backgroundColor: '#0a92b1', color: '#FFF' }, func: (rec) => this.getSupplierEmail(rec) }  },
        ];

        var formActions = [{ action: 'delete' }, { action: 'save', label: 'Save', icon: 'far fa-arrow-alt-left', after: () => this.props.history.push('/PurchaseOrder') }, { action: 'save', label: 'Save', icon: 'fal fa-save', after: () => this.reloadForms() }]

        var preData = {}
        if (this.props.location.state) {
            if (this.props.location.state.siteId) {
                preData.billableSiteId = this.props.location.state.siteId
            }
            if (this.props.location.state.jobId) {
                preData.jobId = this.props.location.state.jobId
            }
        }
        if (iN) {
            preData.orderDate = moment().format('YYYY-MM-DD')
        }

        var poConfig = {
            model: 'purchaseorder',
            master: true,
            crudParams: { status: tabStatus, hidePONo: this.state.hidePONo },
            preData,
            fields: poFields,
            showFilterRow: false,
            titleField: 'invoiceNo',
            loadNewRec: true,
            //customFilter: this.state.customFilter,
            showFilterRow: true,
            serversidePaging: true,
            apiPath: API_PATH,
            gridProps: { defaultPageSize: 50 },
            formActions: status != 1 && rId != 'New' && [{ action: 'delete' }, { action: 'save', label: 'Save', icon: 'far fa-arrow-alt-left', after: () => this.props.history.push('/PurchaseOrder') }, { action: 'save', label: 'Save', icon: 'fal fa-save', after: () => this.reloadForms() }]
        }

        var isHistoric = this.props.match.params.id != 'New' && this.props.match.params.id < 3553 ? true : false;

        var poLines = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: "Part", fieldId: "partId", list: isHistoric ? listRequest.PartsIncArchive : listRequest.Parts, categories: listRequest.PartTypes, inlineEdit: status > 1 && !isAdmin ? false : true, type: 'filterList', footer: <b>Totals</b>, typeProps: { showCats: true } },
            { label: "NC", fieldId: "nominalCode", width: 140, list: lists.nc, inlineEdit: true, display: status >= 3 && 'none' },
            { label: "WH", fieldId: "moveTo", width: 90, list: listRequest.Warehouses, inlineEdit: true, validation: 'requiredList', display: status >= 3 && 'none'},
            //{ label: "WH", fieldId: "moveTo", width: 90, list: lists.wh, inlineEdit: true, display: status >= 3 && 'none' },
            { label: "Expect Qty", fieldId: "expectedQty", width: 100, inlineEdit: status > 1 && !isAdmin ? false : true, copyTo: status == 6 && 'qty', footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.expectedQty)), 2)}</b>) },
            { label: "Actual Qty", fieldId: "qty", width: 100, inlineEdit: true, display: status < 3 && 'none', footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.qty)), 2)}</b>) },
            { label: "Expect Price (\u00A3)", fieldId: "expectedPrice", copyTo: status == 6 && 'costPrice', width: 120, inlineEdit: status > 1 && !isAdmin ? false : true, footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.expectedPrice)), 2)}</b>) },
            { label: "Actual Price (\u00A3)", fieldId: "costPrice", width: 100, display: status < 3 && 'none', inlineEdit: true, footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.costPrice)), 2)}</b>) },
            { label: "KF Ref (KF)", fieldId: "invoiceRef", inlineEdit: true, display: status < 3 && 'none' },
            { label: "POId", fieldId: "partMovementHeaderId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var poLInesConfig = {
            model: 'purchaseOrderLine',
            fields: poLines,
            gridButton: false,
            apiPath: API_PATH,
            addInline: status > 1 && !isAdmin ? false : true,
            gridProps: { defaultPageSize: 10 },
            submitCallback: (e) => this.submitCallback(e)
        }

        var nsLines = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: "Category", fieldId: "categoryId", list: listRequest.NonStockSubCats, categories: listRequest.NonStockCats, inlineEdit: status > 1 && !isAdmin ? false : true, type: 'filterList', typeProps: { showCats: true } },
            { label: "Description", fieldId: "description", inlineEdit: status > 1 && !isAdmin ? false : true },
            { label: "Expect Qty", fieldId: "expectedQty", width: 120, inlineEdit: status > 1 && !isAdmin ? false : true, copyTo: status == 6 && 'qty', footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.expectedQty)), 2)}</b>) },
            { label: "Actual Qty", fieldId: "qty", width: 120, inlineEdit: true, display: status > 0 && isAdmin ? 'both' : (status < 2 ? 'none' : 'both'), footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.qty)), 2)}</b>) },
            { label: "Expect Price (\u00A3)", fieldId: "expectedPrice", copyTo: status == 6 && 'costPrice', width: 120, inlineEdit: status > 1 && !isAdmin ? false : true, footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.expectedPrice)), 2)}</b>) },
            { label: "Actual Price (\u00A3)", fieldId: "costPrice", width: 120, display: status > 0 && isAdmin ? 'both' : (status < 2 ? 'none' : 'both'), inlineEdit: isAdmin, footer: (rec) => (<b>{_.round(_.sum(_.map(rec.data, d => d.costPrice)), 2)}</b>) },
            { label: "KF Ref (KF)", fieldId: "invoiceRef", inlineEdit: true, display: status < 2 && 'none' },
            { label: "POId", fieldId: "partMovementHeaderId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var nsLinesConfig = {
            model: 'nonStockLine',
            path: '/purchaseOrderLine/NonStock',
            fields: nsLines,
            gridButton: false,
            apiPath: API_PATH,
            addInline: status > 1 && !isAdmin ? false : true,
            gridProps: { defaultPageSize: 10 },
            submitCallback: (e) => this.submitCallback(e)
        }

        var commentLines = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: "Time", fieldId: 'timeStamp', type: 'datetime', width: 120 },
            { label: "User", fieldId: "userId", list: listRequest.operators, width: 100 },
            { label: "Comment", fieldId: "comment", inlineEdit: true },
            { label: "Notify", fieldId: "notifyUserId", inlineEdit: true, list: listRequest.operators, width: 120 },
            { label: "POId", fieldId: "partMovementHeaderId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn', displayIf: (r) => r.id == -1 },
        ];

        var commentConfig = {
            model: 'PMComment',
            fields: commentLines,
            gridButton: false,
            addInline: true,
            apiPath: API_PATH,
            gridProps: { defaultPageSize: 10 }
        }

        const { activeTab } = this.state
        var poKey = 'mdPO_' + this.state.poId + '_' + status + '_' + tabStatus
        console.log('poKey', poKey)
        console.log('rId', rId)

        if (status != null || rId == undefined) {
            return (
                <MasterDetail key={poKey} {...poConfig} filterChange={(e) => this.filterChange(e)}>
                    {rId != 'New' && this.state.poStatus != null &&
                        <div style={{ marginRight: '15px' }} key={'navHolder_' + activeTab}>
                            <div style={{ margin: '10px 0 10px 0', display: 'flex', justifyContent: 'flex-end' }}>{this.state.poStatus == 6 && <Button className="btn btn-primary" onClick={() => this.receiveAll()}>Receive All</Button>}</div>
                            <Nav tabs className="nav-docked">
                                <NavItem>
                                    <NavLink className={activeTab == 1 ? 'active' : ''} onClick={() => { this.toggle(1) }}>Stock Lines</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={activeTab == 2 ? 'active' : ''} onClick={() => { this.toggle(2) }}>Non-stock Lines</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={activeTab == 3 ? 'active' : ''} onClick={() => { this.toggle(3) }}>Comments</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent key={'detailTabs_' + activeTab} activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    {activeTab == 1 && <MasterDetail key={'mdPOL' + this.state.slId} {...poLInesConfig} />}
                                </TabPane>
                                <TabPane tabId={2}>
                                    {activeTab == 2 && <MasterDetail key={'mdNSL' + this.state.nslId} {...nsLinesConfig} />}
                                </TabPane>
                                <TabPane tabId={3}>
                                    {activeTab == 3 && <MasterDetail key={'mdComments'} {...commentConfig} />}
                                </TabPane>
                            </TabContent>
                        </div>}

                </MasterDetail>
            )
        }
    }

    render() {
        const { footer, header, body, open, mainActiveTab, poStatus, sendingReminders } = this.state
        var isAdmin = this.props.commonstate.users.authRole.includes("Administrator") || this.props.commonstate.users.authRole.includes("StockAdmin")
        const tabConfig = [{ id: 1, name: 'All Outstanding', sId: 9999 }, { id: 2, name: 'Open', sId: 0 }, { id: 3, name: 'Awaiting Approval', sId: 1 }, { id: 4, name: 'Approved', sId: 2 }, { id: 5, name: 'Received', sId: 3 }, { id: 6, name: 'Part Received', sId: 6 }, { id: 7, name: 'Awaiting Variance Approval', sId: 4 }, { id: 8, name: 'Complete', sId: 5 }, { id: 9, name: 'All', sId: 100 }];

        if (this.props.match.params.id == undefined || poStatus != null || this.props.match.params.id == 'New') {
            return (
                <div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <div className='titleBlock'>Purchase Orders</div>
                        {isAdmin && this.props.match.params.id == undefined && <Button color="primary" onClick={() => this.sendReminders()}>{sendingReminders && <i className='fa fa-spinner fa-spin' />} Send Reminders</Button>}
                    </div>
                    {this.props.match.params.id == undefined ?
                        <div>
                            <Nav tabs className="nav-docked">
                                {tabConfig.map(t => (
                                    <NavItem key={t.id}>
                                        <NavLink
                                            className={mainActiveTab === t.id ? 'active' : ''}
                                            onClick={() => { this.toggle(t.id, 'main') }}>
                                            {t.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent key='clientTabs' activeTab={mainActiveTab}>
                                {tabConfig.map(t => (
                                    <TabPane key={t.id} tabId={t.id}>
                                        {mainActiveTab === t.id && this.getMD(t.sId)}
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div> : this.getMD(9999)}
                    <SimpleModal {...{ header, body, footer, open }} />
                </div>
            )
        }
        else {
            return <div>Loading...</div>
        }

    }
}

const mapStateToProps = state => {
    return { commonstate: state.common }
}

export default connect(mapStateToProps)(PurchaseOrder)
