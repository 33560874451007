import React, { Component } from 'react'
import { Col, Label, Row } from 'reactstrap'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { withRouter } from 'react-router-dom'

class ValidationBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = { val: { op: null, opVal: null, type: 'custom'} }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.data != this.props.data) {
            if (nextProps.data && nextProps.data.customValidation) {
                if (nextProps.data.customValidation.includes('{')) {
                    this.setState({ val: JSON.parse(nextProps.data.customValidation) })
                }
            }
        }
        return true
    }

    setValidation(val, type) {
        console.log('setValidation', { val, type })
        var t = this.state.val
        if (type == 'val') {
            t.opVal = val
        }
        else {
            t.op = val
        }

        this.setState({ val: t })
        this.props.onChange(JSON.stringify(t))
    }

    getList() {
        var complete = []

        complete.push({ name: '=', id: '=' }, { name: '!=', id: '!=' }, { name: '>', id: '>' }, { name: '<', id: '<' })
        return complete
    }

    render() {
        if (this.props.data) {
            return (
                <div style={{ width: '100%' }}>
                    <Row>
                        <Col xs={12} sm={2}><Label ><b>Validation Builder</b></Label></Col>
                        <Col xs={12} sm={1}>
                            <IdNameDropdown options={this.getList()} zeroText='Operator' valueField="id" displayField="name" value={this.state.val.op} onChange={(e) => this.setValidation(e,'op')} />
                        </Col>
                        <Col xs={12} sm={3}>
                            <input className="input" className="form-control" defaultValue={this.state.val.opVal || ''} placeholder="Value" onChange={(e) => this.setValidation(e.target.value, 'val')} />
                        </Col>
                    </Row>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export default withRouter(ValidationBuilder)
