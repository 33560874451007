import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
export const API_PATH = process.env.SERVER_PATH + '/api'

class GroupSetup extends Component {
    constructor(props) {
        super(props);
    }

    parseData(e) {
        console.log('parse', e)
    }

    cascadeComponents() {

        console.log('cascadeComponents')

        var subCatFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Asset / Device / Site', fieldId: 'name', inlineEdit: true },
            { label: 'Address', fieldId: 'address' },
            { label: 'Jobs Completed', fieldId: 'jobsCompleted' },
            { label: 'Total Jobs', fieldId: 'totalJobs' },
            { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { location: '/Customers/' }, width: 70 }
        ];
        var nsSubCatsConfig = {
            model: 'customerSites',
            path: '/customerGroupSetup/GetCustomerSites',
            fields: subCatFields,
            gridButton: false,
            crudParams: { cId: this.props.match.params.id },
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH,
            gridProps: { minRows: 1, noDataText: <div><br /><b>No customer asset / device / site</b></div>, showPagination: false, style: { border: 'none' } },
        }

        return <MasterDetail {...nsSubCatsConfig} />
    }

    render() {
        var contactFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Name', fieldId: 'name', canFilter: true, width: 300, validation: 'required' },
            { label: 'Address', fieldId: 'address', canFilter: true },
            { label: 'Sites', fieldId: 'sites', width: 100, display:'grid' },
            { label: 'Total Jobs', fieldId: 'totalJobs', width: 100, display:'grid' },
        ];

        var contactConfig = {
            model: 'customerGroupSetup',
            fields: contactFields,
            titleField: 'name',
            master: true,
            formActions: [
                { action: 'back' },
                { action: 'save' }   
            ],
            gridButton: { pathname: '/Customers/New' },
            defaultSorted: [{ id: 'name', desc: false }],
            gridProps: { defaultPageSize: 50 },
            cascade: true,
            cascadeComponents: this.cascadeComponents(),
            apiPath: API_PATH
        }

        return (
            <div>
                <div>
                    <div className='titleBlock'>Customer Setup</div>
                </div>
                <div>
                    <MasterDetail {...contactConfig} />
                </div>
            </div>
        )
    }
}

export default (GroupSetup)