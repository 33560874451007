import React, { Component } from 'react';
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom';
import { Field, reduxForm } from "redux-form";
import logo from '../../../../content/izon-logo.png';
import { setAuthInfo } from '../state/actions'
import { ApiPost, ApiGet } from '../../../api/index'
import Loader from '../../../components/Loader';
import GoogleMap from 'google-map-react';
import moment from 'moment'

const stationMap = {
    defaultZoom: 8,
    defaultCenter: { lat: 55.0, lng: -5.5 },
    options: {
        fullscreenControl: false,
        styles: [{ "featureType": "administrative", "elementType": "labels", "stylers": [{ "visibility": "off" }, { "lightness": "50" }] }, { "featureType": "administrative.country", "elementType": "geometry.stroke", "stylers": [{ "visibility": "on" }, { "lightness": "70" }, { "weight": ".8" }] }, { "featureType": "administrative.province", "elementType": "geometry.stroke", "stylers": [{ "visibility": "on" }, { "lightness": "50" }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "color": "#f2f2f2" }] }, { "featureType": "landscape.natural", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "lightness": "82" }, { "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "color": "#e2e4e4" }] }, { "featureType": "road", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.line", "elementType": "labels.text", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.station.airport", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit.station.airport", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e2e4e4" }] }, { "featureType": "water", "elementType": "labels", "stylers": [{ "visibility": "off" }] }],
        gestureHandling: 'none'
    }
};

class Login extends Component {

    constructor(props) {
        super(props)
        const { location } = props
        const qs = queryString.parse(location.search)
        let newLoc = '/Dashboard'
        if (location.search) {
            newLoc = location.search.toString().replace('?prev=', '').replace('?Logout=1', '')
        }
        this.state = {
            redirect: 0,
            redirectTo: newLoc,
            failedLogin: false,
            loading: true,
            isMounted: false,
            isPosting: false,
        }
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        var t = new moment().format('DD-MM-YY HH:mm:ss')
        //console.log('*LOGIN LOAD ' + t + '*')
        console.log("%cizon", "color: #FFF; background-color: #0a92b1; font-family:sans-serif; font-size: 50px; font-weight:600; padding:10px; border-radius:5px;");
        this.setState({ isMounted: false })

        setTimeout(function () {
            if (this.state.isMounted) {
                this.setState({ loading: false })
            }
        }.bind(this), 3000)

        var logoutParam = queryString.parse(this.props.location.search).Logout;
        if (logoutParam != 1) {
            //console.log('trying fetch');
            ApiGet('auth', 'UsersOnline')
                .then((data) => {
                    if (data) {
                        this.props.setAuthInfo(true, data),
                        this.setState({ failedLogin: false, redirect: 1, loading: false })
                    }
                    else {
                        this.setState({ loading: false })
                    }
                })
                .catch(err => this.setState({ loading: false }))
        }
        else if (logoutParam == 1) {
            this.setState({ loading: false })
            ApiGet('auth', 'Logout')
                .then((data) => {
                    console.log("logged out")
                    localStorage.removeItem('jwtToken')
                    localStorage.removeItem('userConfig')
                });
        }
        else {
            this.setState({ loading: false })
        }
    }

    submit(rec) {
        this.setState({ isPosting: true })
        //console.log("recx", this)
        let me = this
        ApiPost('auth', 'WebLogin', rec)
            .then((data) => {
                const loggedIn = data.ret > 0
                const doReset = (data.ret == 2)
                localStorage.setItem('jwtToken', data.token)
                localStorage.setItem('userConfig', JSON.stringify(data.userConfig))
                console.log('tokenSet')
                this.props.setAuthInfo(true, loggedIn)
                this.setState({ failedLogin: !loggedIn, redirect: data.ret, isPosting: false })
            })
            .then(() => {
                ApiGet('auth', 'UsersOnline')
                    .then((data) => {
                        this.props.setAuthInfo(true, data)
                    })
            });
    }

    render() {
        const { handleSubmit } = this.props
        const { redirect, redirectTo, failedLogin, loading } = this.state
        let msg = (failedLogin) ? <div className="alert alert-danger">Invalid Login</div> : '';

        if (redirect == 1) {
            return <Redirect to={redirectTo} />
        }
        else if (redirect == 2) {
            return <Redirect to="/Welcome/" />
        }
        else if (redirect == 3) {
            return <Redirect to="/EngineerSchedule" />
        }
        else if (loading) {
            return <Loader />
        }
        else {
            return (
                <div style={{ height: '100vh' }}>
                    <form className="form-signin" onSubmit={handleSubmit(this.submit)}>

                        <img src={logo} height="50px" className="loginHeader lf" role="presentation"></img>
                        <label htmlFor="inputEmail" className="sr-only">Email address</label>
                        <Field type="email" name="username" component="input" className="form-control" placeholder="Username" required autoFocus />
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <Field type="password" name="password" component="input" className="form-control" placeholder="Password" required />
                        {msg}
                        <button className="btn btn-lg btn-primary btn-block login" type="submit">Sign in {this.state.isPosting ? <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} /> : null}</button>
                        <a href="/Policy" style={{ color: '#909096', margin: '20px auto', display: 'block', width: '100px', textAlign: 'center', fontSize: '10px' }}>Privacy Policy</a>
                    </form>

                    <div id="map-canvas">
                        <GoogleMap center={stationMap.defaultCenter} zoom={stationMap.defaultZoom} options={stationMap.options} bootstrapURLKeys={{ key: "AIzaSyD6MIMPATeyHjaOwrMxHqWJ57kQvjVhf2w" }} />
                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthInfo: (authInfo, authRole) => {
            dispatch(setAuthInfo(authInfo, authRole));
        }
    };
};

Login = connect(
    null,
    mapDispatchToProps
)(Login)

export default reduxForm({
    form: "LoginForm" // a unique identifier for this form
})(Login);
