import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
export const API_PATH = process.env.SERVER_PATH + '/api'

class VanCard extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        var VanCardFields = [
            { label: "Id", fieldId: "id", width: 70 , display: 'none'},
            { label: "Van Id", fieldId: "vanId", width: 70 },
            { label: "Van Name", fieldId: "vanName" },
            { label: "Client Id", fieldId: "clientId", getId: 'ClientSettings', width: 70, display: 'none' },

        ];

        var VanCardConfig = {
            model: 'VanCard',
            fields: VanCardFields,
            master: true,
            titleField: 'vanName',
            apiPath: API_PATH
        }
       

        return (
            <div>
                <h1 key='h1wc'>Van Card</h1>
                <MasterDetail key='mdwc' {...VanCardConfig} />                
            </div>
        );

    }
}

export default (VanCard)
