import React, { Component } from 'react';
import HealthIndicator from '../../../components/HealthIndicator';
import moment from 'moment';
import helpers from '../../../utils/helpers'

class DynamicTableWidget extends Component {

    getPeriodDots() {
        if (this.props.periods != null) {
            const dots = [];
            for (var i = 0; i < this.props.periods.length; i++)
            {
                if (this.props.periods[i].id == this.props.period)
                {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 1 }} key={i}></i>);
                }
                else
                {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 0.4 }} key={i}></i>);
                }
            }
            return dots;
        }
        else {
            return null;
        }
    }

    getPeriodExists()
    {
        if (this.props.periods != null) {
            return { cursor: 'pointer', width: '98%', height: '95%' };
        }
        else
        {
            return { width: '98%', height: '95%' };
        }
    }

    getCellRender(val, detail) {
        
        if (val)
        {
            if (detail.type == 'posNeg') {
                return this.getPosNegRender(val);
            }
            else if (detail.type == 'healthStatus') {
                return <HealthIndicator healthVal={val} detail={detail} />;
            }
            else if (detail.type == 'dateTime') {
                return moment(val).format('DD/MM/YY HH:mm')
            }
            else if (detail.type == 'totalTime') {
                return helpers.parseDuration(null,null,val)
            }
            else if (detail.type == 'timeSince') {
                return helpers.parseDuration(val, moment())
            }
            else {
                return val;
            }
        }
        else {
            return '';
        }
    }

    getPosNegRender(val) {
        val = val.toFixed(1);
        if (val > 0) {
            return <span className="posNegRender" style={{ backgroundColor: '#d0021b'}}>{'x ' + val}</span>;
        }
        else {
            val = Math.abs((1 / val).toFixed(1));
            return <span className="posNegRender" style={{ backgroundColor: '#27c564' }}>{'x ' + val}</span>;
        }
    }


    render() {
        const val = this.props.widgetValue || '';
        var params = this.props.params;
        var paramValues = this.props.selectedParam.params;
        var selectedValues = {}
        if (paramValues) {
            Object.keys(paramValues).map(x => {
                selectedValues[params[x].group] = paramValues[x];
            })
        }
        
        return (
            <div className="widget" style={this.getPeriodExists()}>
                <div className="summaryDots">
                    {this.getPeriodDots()}
                </div>
                <h4>{val.mainHeading || null}</h4>
                <table className="metricTable stripped-row" style={{ width: 'calc(100% - 40px)' }}>
                    <thead>
                        <tr key='headerTopRow'>
                            {val.headers ? val.headers.map(x => {
                                if (selectedValues[x.group] == true || selectedValues[x.group] == undefined) {
                                    return <th className={x.rotate ? "rotate" : ""} key={x.shortName}><div><span>{x.shortName}</span></div></th>
                                }
                                else {
                                    return null
                                }
                            }) : null}
                        </tr>
                    </thead>
                    <tbody>
                        {val.tableData && val.headers ? 
                            val.tableData.map((r,i) => {
                                return (
                                    <tr key={'tr-' + r.id + i}>
                                        {val.headers.map(h => {
                                            if (selectedValues[h.group] == true || selectedValues[h.group] == undefined) {
                                                if (h.type != "") {
                                                    return (<td key={r[h.name] + h.name} style={h.rotate ? { width: '40px', borderRight: '1px solid #bbb', textAlign: 'center' } : {}}>{this.getCellRender(r[h.name], h)}</td>);
                                                }
                                                else {
                                                    return (<td key={r[h.name] + h.name} style={h.rotate ? { width: '40px', borderRight: '1px solid #bbb', textAlign: 'center' } : {}}>{r[h.name] + h.suffix}</td>);
                                                }
                                            }
                                            else {
                                                return null;
                                            }
                                        })}
                                    </tr>
                                );
                            })
                            : null}
                    </tbody>
                </table>
            </div>
        );
    }
};

export default DynamicTableWidget;
