import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Textfit } from 'react-textfit'

const widgetLevel = "";

class SummaryWidget extends Component {

    getResult(value, perid, factor, decimalPlaces) {

        if (!factor)
        {
            factor = 1;
        }
        if (!decimalPlaces)
        {
            decimalPlaces = 0;
        }

        this.getLevel(value);

        if (value != undefined)
        {
            value = value * factor
            if (value < 0)
            {
                value = 0;
            }
            return value.toFixed(decimalPlaces);
        }
        else
        {
            return '-';
        }
    }

    getSubtitle()
    {
        if (this.props.periods != null)
        {
            var periodTitle = this.props.periods.find(x => x.id == this.props.period)

            if (periodTitle != null)
            {
                return periodTitle.title;
            }
            else
            {
                return this.props.subtitle;
            }
        }
        else
        {
            return this.props.subtitle;
        }
    }

    getPeriodDots() {
        if (this.props.periods != null) {
            const dots = [];
            for (var i = 0; i < this.props.periods.length; i++)
            {
                if (this.props.periods[i].id == this.props.period)
                {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 1 }} key={i}></i>);
                }
                else
                {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 0.4 }} key={i}></i>);
                }
            }
            return dots;
        }
        else {
            return null;
        }
    }

    getPeriodExists()
    {
        if (this.props.periods != null) {
            return { cursor: 'pointer' };
        }
        else
        {
            return null;
        }
    }

    getLevel(value) {
        if (value) {
            if (this.props.levelRange) {

                if (value <= this.props.levelRange.danger) {
                    this.widgetLevel = 'danger'
                }
                else if (value > this.props.levelRange.danger && value < this.props.levelRange.success) {
                    this.widgetLevel = 'warning'
                }
                else if (value >= this.props.levelRange.success) {
                    this.widgetLevel = 'success'
                }

            }
            else {
                this.widgetLevel = this.props.level
            }
        }
        else {
            this.widgetLevel = this.props.level
        }
    }

    getIcon(val) {
        if (this.props.iconRange && val) {
            var closest = Object.keys(this.props.iconRange).reduce(function (prev, curr) {
                return (Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev);
            });
            return <i className={this.props.iconRange[closest]} />
        }
        else {
            return <i className={this.props.icon} />
        }
    }

    render() {
        const val = this.getResult(this.props.widgetValue, this.props.period, this.props.factor, this.props.decimalPlaces);

        return (
            <div className={"summary-widget widget " + this.widgetLevel} style={this.getPeriodExists()}>
                <div className="summaryDots">
                    {this.getPeriodDots()}
                </div>
                <div className="widget-icon" >
                    {this.getIcon(val)}
                </div>
                <div className="widget-info">
                    <Textfit>
                        <div >
                            {val}
                            <span>{this.props.suffix}</span>
                        </div>
                    </Textfit>
                    <div>
                        <span>{this.getSubtitle()}</span>
                    </div>
                </div>
            </div>
        );
    }
};

export default SummaryWidget;
