import React, { Component } from 'react'
import { Col, Row, Input } from 'reactstrap'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import ScheduleSelector from 'react-schedule-selector'
import moment from 'moment'
import { morningWeekend, weekend, outOfOffice, night } from '../../../configuration/schedules'

export default class Scheduler extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewEdit: false, rec: { type: 0, sch:[] }
        }
    }

    componentDidMount() {
        if (this.props.rec.data) {   
            this.parseExisitingSchedule()
        }
    }

    componentDidUpdate(pp) {
        if (pp.rec.data && !pp.rec.data.hasOwnProperty('id')) {
            this.parseExisitingSchedule()
        }
    }

    parseExisitingSchedule() {
        var t = this.state.rec
        console.log('this.props.rec.data.alertSchedule', this.props.rec.data.alertSchedule)
        if (this.props.rec && this.props.rec.data.alertSchedule) {
            t.type = this.props.rec.data.alertSchedule.type
            t.sch = typeof this.props.rec.data.alertSchedule.sch === 'string' ? JSON.parse(this.props.rec.data.alertSchedule.sch) : this.props.rec.data.alertSchedule.sch
        }
        this.setState({ rec: t }, () => console.log(this.state.rec))
    }

    valChange(type, sch) {
        var t = this.state.rec
        t.type = type
        t.sch = sch
        this.setState({ rec: t }, () => this.props.rec.onChange(t))
    }

    parseDate(e) {
        if (e.length > 0) {
            var tSch = []
            e.map(x => {
                tSch.push(moment(x).format())
            })
            this.valChange(6, tSch)
        }
    }

    getScheduleFromType(type) {
        var sch
        if (type == 2) {
            sch= morningWeekend
        }
        else if (type == 3) {
            sch = weekend
        }
        else if (type == 4) {
            sch = outOfOffice
        }
        else if (type == 5) {
            sch = night
        }
        return sch
    }

    enableEdit() {
        var t = this.state.rec
        if (t.type != 6) {
            t.sch = this.getScheduleFromType(t.type)
        }
        this.setState({ viewEdit: !this.state.viewEdit, rec: t })
    }

    getScheduler() {
        console.log('getScheduler', this.state)
        return (
            <ScheduleSelector
                selection={this.state.rec.sch}
                numDays={7}
                minTime={0}
                maxTime={24}
                rowGap={'1px'}
                columnGap={'1px'}
                timeFormat={'HH'}
                startDate={'2020-01-06'}
                dateFormat={'dd'}
                onChange={(e) => this.parseDate(e)} />)
    }

    render() {
        var r = this.state.rec
        return (
            <div>
                <Row>
                    <Col sm={4}><IdNameDropdown value={r.type} options={this.props.list} zeroText='Select' valueField="id" displayField="name" onChange={(e) => { this.valChange(e, this.getScheduleFromType(e)); this.setState({ viewEdit: false }) }} /></Col>
                    <Col sm={2}>{r.type > 1 && <button type="button" className='btn btn-primary' onClick={() => this.enableEdit()} ><i className='fal fa-edit' /></button>}</Col>
                </Row>
                {this.state.viewEdit &&
                <Row>
                    <Col sm={5} style={{marginTop:'10px'}}>{this.getScheduler()}</Col>
                </Row>}
            </div>
        );
    }
}