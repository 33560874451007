import React, { Component } from 'react';
import { Collapse, CardBlock, Card, CardTitle, Col, Form, FormGroup, Label, Input, FormText, Button, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupButton  } from 'reactstrap';

export default class DeferAction extends Component {

    constructor(props) {
        super(props);

        this.state = { showMetric: false, showTime: false};
    }

    typeChange(e)
    {
        console.log('type changed', e.target.value);

        if (e.target.value == 'Metric')
        {
            this.setState({
                showMetric: true,
                showTime: false
            })
        }
        else if (e.target.value == 'Time')
        {
            this.setState({
                showTime: true,
                showMetric: false
            })
        }
        else
        {
            this.setState({
                showTime: false,
                showMetric: false
            })
        }
    }

    render() {
        return (
            <div style={{ width: '100%'}}>
                <Card>
                    <CardBlock style={{ backgroundColor: 'rgb(230, 230, 230)' }}>
                        <CardTitle>Defer Action</CardTitle>
                        <Form>
                            <FormGroup row>
                                <Col xs={6} lg={3}>
                                    <Input type="select" name="select" id="exampleSelect" onChange={e => this.typeChange(e)}>
                                        <option>Defer Type</option>
                                        <option>Time</option>
                                        <option>Metric</option>
                                    </Input>
                                </Col>

                                {this.state.showTime ?
                                    <Col xs={6} lg={3}>
                                        <InputGroup>
                                            <InputGroupButton color="secondary">-</InputGroupButton>
                                            <Input placeholder="1 hr" value="1 hr" />
                                            <InputGroupButton color="secondary">+</InputGroupButton>
                                        </InputGroup>
                                    </Col>
                                    : null}

                                {this.state.showMetric ? 
                                        <Col xs={6} lg={3}>
                                            <Input type="select" name="select" id="exampleSelect">
                                                <option>Metric Type</option>
                                                <option>Gen Temp</option>
                                                <option>Gearbox Temp</option>
                                                <option>Oil Temp</option>
                                                <option>Bearing Temp</option>
                                                <option>Wind Speed</option>
                                            </Input>
                                        </Col>
                                    : null}

                                {this.state.showMetric ?
                                <Col xs={6} lg={3}>
                                    <Input type="select" name="select" id="exampleSelect">
                                        <option>Operator type</option>
                                        <option>Less than</option>
                                        <option>Equal to</option>
                                        <option>Greater than</option>
                                    </Input>
                                    </Col>
                                    : null}

                                {this.state.showMetric ?
                                <Col xs={6} lg={3}>
                                    <input type="text" className="form-control" placeholder="Value" />
                                    </Col>
                                    : null}
                                
                            </FormGroup>
                        </Form>
                    </CardBlock>
                </Card>
            </div>
        );
    }
}