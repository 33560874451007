import React, { Component } from 'react'

class HTMLWidget extends Component {

    parseHTML(val) {
        try {
            return (<div style={{ height: '100%', width: '100%' }} dangerouslySetInnerHTML={{ __html: val }} />)
        }
        catch (e) {
            return <div>-</div>
        }
    }

    render() {
        return (
            <div style={{height: '100%', width: '100%'}}>
                {this.props.selectedParam.params && this.props.selectedParam.params.inputHTML && this.parseHTML(this.props.selectedParam.params.inputHTML)}
            </div>
        );
    }
}

export default HTMLWidget