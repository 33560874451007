import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import * as headerActions from '../../actions/header';
import FilterDropDown  from './FilterDropDown'

class MainFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
        this.onSelectFilter = this.onSelectFilter.bind(this);
        this.onToggleFilter = this.onToggleFilter.bind(this);
        this.getFilterValues = this.getFilterValues.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.props.getFilterOptions();
    }

    toggleFilterType(event, id) {
        this.props.toggleTypeDisplay(id, true)
        this.toggle(event)
    }

    toggle(event) {
        this.setState({
        dropdownOpen: !this.state.dropdownOpen
        });
    }

    insertSpaces(string) {
        string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
        string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        string = string.charAt(0).toUpperCase() + string.slice(1);
        return string;
    }

    onSelectFilter(list,ids, close) {
        const { filters } = this.props

        this.props.changeValue(list,ids, filters, close)
    }

    onToggleFilter(e,list) {
        this.props.toggleFilterOpen(list)
    }

    getFilterValues() {
        const {filters} = this.props
        var ret = {}
        var keys = Object.keys(filters)
        var fl = {};
        for (var k of keys) {
            fl[k] = filters[k].value
        }
        return { filters: fl }
    }

    clearFilter(e,list,id) {
        const {filters} = this.props
        this.props.toggleTypeDisplay(list, false, filters)
    }

    renderFilter(filterDic, index, lastItem) {
        var obj = filterDic[index];
        var sep = lastItem?'':<span className="sep">&gt;</span>
        //console.log("obj.items",obj.items);
        if (obj.shown) {
            var inList = {}; // dedup
            var listitems = obj.items.filter((o)=> {
               if (inList[o.id] == undefined) {
                inList[o.id] = 1    
                return true
               }
            });
            const sortedList = listitems.sort((a,b) => {
                if(a.name<b.name) { return -1; }
                if(a.name>b.name) { return 1; }
                return 0;
            })
            return (
            <span key={'span'+index}>
                <FilterDropDown itemKey={index} onRemove={this.clearFilter} initvalues={obj.value} onToggle={this.onToggleFilter} onSelect={this.onSelectFilter} isOpen={obj.open} dropdownItems={sortedList} text={obj.text}></FilterDropDown>
                {sep}
            </span>
            )
        } else {
            return ""
        }
    }

    renderDropdownItem(index) {
        var name = this.insertSpaces(index);
        return (
            <div key={index} onClick={(e) => this.toggleFilterType(e, index)}>
                {name} <i className="fas fa-chevron-right"></i>
            </div>
        )
    }

    getCurrFilterResult() {
        var { assets } = this.props
        assets = assets.filter(x => x.parentId == null)
        var ret = ""
        var cnt = 0;
        var tip = "";
        if (assets.length == 0) {
            ret = "No assets in filter";
        }
        else if (assets.length < 4) {
            for (var a of assets) {
                cnt++
                if (cnt > 1) ret += ", "
                ret += a.name + ((assets.length < 3)?" " + a.model:"") + ((assets.length == 1)?" " + a.kw:"")
            }
        } else {
            ret = assets.length + " assets";
            for (var a of assets) {
                cnt++
                if (cnt > 1) tip += "<br />"
                tip += a.name + (a.model != "" ? " - " + a.model : "") + (a.kw != "" ? " - " + a.kw : "")
            }
        }
        return { text: ret, tip}
    }

    render() {
        var dropdowns = '';
        var filterList = '';
        var filterCount = 0
        var filterRunCount = 0
        var filterSummary = this.getCurrFilterResult()
        if (this.props.filtersLoaded) {
            for (var k of Object.keys(this.props.filters)) {
                if (this.props.filters[k].shown) {
                    filterCount++;
                }
            }
            dropdowns = Object.keys(this.props.filters).map(index => {
                if (this.props.filters[index].shown) {
                    filterRunCount++;
                }
                return this.renderFilter(this.props.filters, index, filterCount == filterRunCount) 
            })
            filterList = Object.keys(this.props.filters).map(index => this.renderDropdownItem(index))
        }
        const { dropdownOpen } = this.state
        return (
            <div className="main-filter-container">
                <h3 id="mainFilter">
                    <span>{this.props.client}</span>
                    {(filterCount > 0)?<span className="sep">&gt;</span>:''}
                    {dropdowns}
                    
                    <Dropdown isOpen={dropdownOpen} toggle={this.toggle} style={{ display:'inline-block'}}>
                        <DropdownToggle style={{backgroundColor:'transparent', border:'none'}}>
                            <i onClick={(e) => this.toggle(e, 0)} data-toggle="dropdown" aria-haspopup="true" aria-expanded={dropdownOpen} className="fa fa-plus" />
                        </DropdownToggle>
                        <DropdownMenu>
                        {filterList}
                        </DropdownMenu>
                    </Dropdown>
                </h3>
                <h4 id="stationModel" data-for="below-tooltip" data-tip={filterSummary.tip}>{filterSummary.text}</h4>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state.common.header
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getFilterOptions: () => {
      dispatch(headerActions.getFilterOptions());
    },
    setFilter: (filters) => {
      console.log('mainfilter set filter')
      dispatch(headerActions.setFilter(filters));
    },
    changeValue: (filterTarget, index, getFiltFunc, close) => {
      dispatch(headerActions.changeValue(filterTarget, index, getFiltFunc,close));
    },
    toggleTypeDisplay: (filterTarget, show, getFiltFunc) => {
      dispatch(headerActions.toggleTypeDisplay(filterTarget, show, getFiltFunc));
    },
    toggleFilterOpen:(filterTarget) => {
      dispatch(headerActions.toggleFilterOpen(filterTarget));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainFilter)