import React, { Component } from 'react';
import { connect } from 'react-redux';
import Controller from './ParameterController';
import ParamTables from './ParameterTables';
import { ApiGet, ApiPost, CommApiGet } from '../../../api';

class ParameterContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sites: [],
            stationParamVals: [],
            paramInfo: null,
            selectedSites:[],
            selectedController: 1,
            external: false
        };
        this.controllerChange = this.controllerChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.reloadSite = this.reloadSite.bind(this);
    }

    componentDidMount() {
        console.log('mount', this.props)
        if (this.props.stationId) {
            this.controllerChange(this.props.controllerId)
            this.handleSelectChange(this.props.stationId)
            this.setState({external: true})
        }
    }

    // Called from ParameterTable to reload a timedout site
    reloadSite(siteId) {
        var cloned = {}
        for (var k of Object.keys(this.state.stationParamVals)) {
            if (k != "site"+ siteId.toString()) cloned[k] = this.state.stationParamVals[k]
        }
        this.setState({ 
            stationParamVals: cloned
        }, () => this.handleSelectChange(this.state.sites, siteId))
    }

    controllerChange(controllerId) {
        if (controllerId.target) {
            controllerId = controllerId.target.value
        }
        this.setState({ selectedController: controllerId, sites: [] });
        var stationParams = {
            ControllerId: controllerId
        }
        ApiGet('Monitoring', 'GetParamInfo', stationParams)
        .then((data) => {
            this.setState({ paramInfo: data, paramVals: null })
        })
    }

    handleSelectChange(sites, reloadSiteId) {
        var selectedSites = [];
        var newSites = [];
        var sitePrevArr = JSON.parse("[" + this.state.sites + "]");
        var siteArr = JSON.parse("[" + sites + "]");
        this.props.header.assets.map(x => {
            var indexPrev =sitePrevArr.indexOf(x.id)

            var index =siteArr.indexOf(x.id)
            if (index >=0) {
                selectedSites.push({ label: x.name, value: x.id, index });
                if (indexPrev == -1 || reloadSiteId == x.id) {
                    newSites.push({ label: x.name, value: x.id, index });
                }
            }
            
        })
        this.setState({ sites, selectedSites: selectedSites});
        this.getStationVals(selectedSites, newSites);
    }

    getStationVals(selectedSites, newSites) {
        var paramVals = null;
        var siteCount = newSites.length;
        this.setState({ selectedSites });
        newSites.map(x => {
            return CommApiGet('parameters', x.value)
                .then((data) => {
                    paramVals = data;
                })
                .then(() => {
                    this.getParamInfo(paramVals, x.value, siteCount)
                })
            }
        )
    }

    getParamInfo(paramVals, site, siteCount) {
        var cloned = {}
        for (var k of Object.keys(this.state.stationParamVals)) {
            cloned[k] = this.state.stationParamVals[k]
        }
        var found = false;
        cloned["site"+site.toString()] = paramVals.Data
        this.setState({ 
            stationParamVals: cloned
        })
        
    }

    render() {
        return (
            <div>
                {!this.state.external && <Controller controllerChange={this.controllerChange} selectChange={this.handleSelectChange} sitesCsv={this.state.sites} selectedController={this.state.selectedController} />}
                <ParamTables paramInfo={this.state.paramInfo} paramVals={this.state.stationParamVals} sites={this.state.selectedSites} reloadSite={this.handleReloadSite} fnReloadSite={this.reloadSite} external={this.state.external}/>
            </div>
        );
    }

};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(ParameterContainer)
