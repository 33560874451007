import React, { Component } from 'react'
import { ApiGet, ApiPost } from '../../../api';
import SimpleModal from './../../../components/SimpleModal'
import { Col, Row, Button, Input } from 'reactstrap'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

class PreForm extends Component {

    constructor(props) {
        super(props);
        this.state = { header: null, body: null, footer: null, searchData: [] }
    }

    componentDidMount() {
        this.loadPreForm()
    }

    componentDidUpdate(pp) {
        if (pp.preForm != this.props.preForm) {
            this.loadPreForm()
        }
    }

    saveSearch(value, fieldDefId) {
        var tempSearch = this.state.searchData
        tempSearch.push({ fieldDefId, value })
        this.setState({searchData: tempSearch})
    }

    searchForForm() {
        var d = {
            entityId: this.props.preForm.formEntityId,
            fields: JSON.stringify(this.state.searchData)
        }

        ApiPost('CustomForm', 'SearchForForm', d)
        .then((data) => {
            if (data.formRowId != '00000000-0000-0000-0000-000000000000') {
                this.props.history.push('/FormCustom/' + data.formRowId + '/1')
            }
            else {
                ApiPost('CustomForm', 'CreatePlaceholder', { entityId: this.props.preForm.formEntityId })
                .then((d) => {
                    console.log('placeholder', d)
                    var formUrl = '/FormCustom/' + d.formId + '/1'
                    this.props.history.push(formUrl)
                })
            }
        });
    }

    loadPreForm() {
        var header = <span>{this.props.preForm.name}</span>,
            body = <div>
                {
                    this.props.preForm.preFormFields.map(f => {
                        if (f.field.fieldDataType == 3) {
                            this.saveSearch(moment().format('DD/MM/YYYY'), f.field.formFieldDefId)
                        }
                        return (<Row style={{ marginTop: '10px' }}>
                                <Col sm='2'><b>{f.field.dataName}</b></Col>
                                <Col sm='10'>
                                {f.field.fieldDataType == 3 ?
                                    <DateTimePicker onChange={(e) => this.saveSearch(moment(e).format('DD/MM/YYYY'), f.field.formFieldDefId)} defaultValue={moment().toDate()} format='DD/MM/YYYY' time={false}  />
                                    :
                                    <Input onBlur={(e) => this.saveSearch(e.target.value, f.field.formFieldDefId)} style={{ width: '100%' }} />
                                }
                                </Col>
                        </Row>)
                    })
                }
            </div>,
            footer = <div style={{ width: '100%' }}>
                <Button onClick={() => this.props.toggle()}>Cancel</Button>
                <Button color='primary' style={{ float: 'right' }} onClick={() => this.searchForForm()}><i className='far fa-search' /></Button>
            </div>

        this.setState({ open: true, header, body, footer })
    }


    render() {
        const { footer, header, body } = this.state
        return (
            <SimpleModal {...{ header, body, footer }} width={'500px'} open={true} />
        )
    }
}

export default withRouter(PreForm)