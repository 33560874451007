import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + "/api";

class PurchaseOrder extends Component {

    constructor(props) {
        super(props);
        this.state = { smId: 1 };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            if (!isNaN(this.props.match.params.id)) {
                this.setState({smId: Math.random()})
            }
        }
    }

    render() {
        var lists = {
            stockCat: [{ id: 1, name: 'CON' }, { id: 2, name: 'CP' }, { id: 3, name: 'SER' }, { id: 4, name: 'PRT' }],
            wh: [{ id: 1, name: 'Default' }],
        };

        var poFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Ref', fieldId: 'invoiceNo' },
            { label: 'TimeStamp', fieldId: 'orderDate', type: 'datetime' },
            { label: 'Entered By', fieldId: 'completedBy', list: listRequest.operators, displayIf: () => this.props.match.params.id != 'New' },
            { label: 'To WH', fieldId: 'warehouseId', list: listRequest.Warehouses },
            { label: '', fieldId: "navButton", width: 60, display: 'grid', type: 'navButton'},
        ];

        var poConfig = {
            model: 'stockMovement',
            master: true,
            fields: poFields,
            defaultSorted: [{ id: 'orderDate' }],
            titleField: 'invoiceNo',
            loadNewRec: true,
            apiPath: API_PATH
        }

        var poLines = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: "Part", fieldId: "partId", list: listRequest.Parts, width: 400, categories: listRequest.PartTypes, inlineEdit: true, type: 'filterList' },
            { label: "From WH", fieldId: "moveFrom", list: listRequest.Warehouses, inlineEdit: true },
            { label: "Move Qty", fieldId: "qty", inlineEdit: true },
            { label: "POId", fieldId: "partMovementHeaderId", display:'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var poLInesConfig = {
            model: 'stockMovementLine',
            fields: poLines,
            gridButton: false,
            addInline: true,
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Stock Movement</div>
                <MasterDetail key={'mdPO' + this.state.smId} {...poConfig}>
                    {this.props.match.params.id != 'New' && <MasterDetail key='mdPOL' {...poLInesConfig} />}
                </MasterDetail>
            </div>
        )
    }
}

export default (PurchaseOrder)
