import React, { Component } from 'react'
import { Col, Row, Label } from 'reactstrap'
import { ApiPost, ApiGet } from '../../../api'
import { connect } from 'react-redux'
import { ToggleSwitch } from '../../../components/Controls/ToggleSwitch'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import TemplateBuilder from './TemplateBuilder'
import PreForm from './PreForm'

class FormPage extends Component {

    constructor(props) {
        super(props);
        this.state = { forms: null, pageInfo: null, fGroups: null, editMode: false, selectedTemplate: 1, preFormOpen: false, preForm: null };
    }

    componentDidMount() {
        this.getPageData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.setState({ forms: null, pageInfo: null, fGroups: null }, () => this.getPageData())
        }
    }

    getPageData() {
        ApiGet('CustomForm', 'GetPageForms', { pageId: this.props.match.params.id })
        .then((d) => {
            this.setState({ forms: d.forms, pageInfo: d.pDeets, groups: d.fGroups })


            if (d.pDeets.p.templateConfig && d.pDeets.p.templateConfig.includes('{')) {
                var tSTem = JSON.parse(d.pDeets.p.templateConfig).templateType
                this.setState({selectedTemplate: tSTem})
            }
            var isAdmin = this.props.commonstate.users.authRole.includes("AppBuilder")
            this.setState({ isAdmin })
        });
    }

    toggleEditMode() {
        if (this.state.editMode) {
            this.setState({ forms: null, pageInfo: null, fGroups: null }, () => this.getPageData())
        }
        this.setState({ editMode: !this.state.editMode })
    }

    getFormList(id) {
        var forms = this.state.forms
        var fReturn = [], fGro = null, groups = []
        forms.map(x => {
            var fEGId = x.formEntityGroupId || 0
            if (fEGId == id) {
                if (fEGId != fGro) {
                    fGro = fEGId
                    groups[fGro] = []
                }
                groups[fGro].push(x)
            }
        })

        groups.map((g, i) => {
            var catName = this.state.pageInfo.groups.find(y => y.id == i)
            catName = catName ? catName.name : 'Forms'
            fReturn.push(<div className='formPageCard'>
                <div key={'t' + i} >{catName}</div>
                {g.map(x => {
                    return (<div key={'te' + x.formEntityId} style={{height:'50px'}} >
                        <Row style={{margin: '0 15px', borderTop: '1px solid #f1f1f1', height: '50px'}}>
                            <Col xs={2} sm={1}>
                                <Row style={{height:'50px', alignItems:'center'}}><i className={(x.icon || 'fa fa-edit') + ' fa-2x'} /></Row>
                            </Col>
                            <Col xs={6} sm={7}>
                                <Row><b style={{marginTop:'5px'}}>{x.name}</b></Row>
                                <Row><span>{x.desc}</span></Row>
                            </Col>
                            <Col xs={4}>
                                <Row style={{ justifyContent: 'flex-end', alignItems: 'center', height: '50px' }} >
                                    {x.preFormFields.length > 0 ?
                                        <div>
                                            <button className='btn btn-primary' style={{ marginRight: '5px', width: '65px' }} onClick={() => this.togglePreForm(x)}><i className='far fa-search' /></button>
                                            {this.state.isAdmin && <button className='btn btn-primary' onClick={() => this.props.history.push('/FormSelection/' + x.formEntityId + (x.dbEntityId ? '/1' : '/0'))}>View</button>}
                                        </div> :
                                    <button className='btn btn-primary' onClick={() => this.props.history.push('/FormSelection/' + x.formEntityId + (x.dbEntityId ? '/1' : '/0'))}>View</button>}
                                </Row>
                            </Col>
                        </Row>
                    </div>)
                })}
            </div>)
        })
        return fReturn
    }

    togglePreForm(fD) {
        this.setState({ preFormOpen: !this.state.preFormOpen, preForm: fD ? fD : null})
    }

    getSingleForm(id) {
        var f = this.state.forms.find(x => x.formEntityId == id)
        if (f) {
            return (<div className='formPageCard'>
                <Row style={{ margin: '0 15px', height: '100px' }}>
                    <Col xs={2}>
                        <Row style={{ height: '100px', alignItems: 'center' }}><i className={(f.icon || 'fa fa-edit') + ' fa-3x'} /></Row>
                    </Col>
                    <Col xs={6}>
                        <Row style={{ height:'60%' }}><b style={{ marginTop: '25px' }}>{f.name}</b></Row>
                        <Row style={{ height: '30%' }}><span>{f.desc}</span></Row>
                    </Col>
                    <Col xs={4}>
                        <Row style={{ justifyContent: 'flex-end', alignItems: 'center', height: '100px' }} >
                            {f.preFormFields.length > 0 ?
                                <div>
                                    <button className='btn btn-primary' style={{ marginRight: '5px', width: '65px' }} onClick={() => this.togglePreForm(f)}><i className='far fa-search' /></button>
                                    {this.state.isAdmin && <button className='btn btn-primary' onClick={() => this.props.history.push('/FormSelection/' + f.formEntityId + (f.dbEntityId ? '/1' : '/0'))}>View</button>}
                                </div> :
                                <button className='btn btn-primary' onClick={() => this.props.history.push('/FormSelection/' + f.formEntityId + (f.dbEntityId ? '/1' : '/0'))}>View</button>}
                        </Row>
                    </Col>
                </Row>
            </div>)
        }
    }

    getPaneContent(paneNo, config) {
        var content = <div>Open edit mode to add content</div>
        if (config.panes.find(x => x.id == paneNo)) {
            var p = config.panes.find(x => x.id == paneNo)
            if (p.type == 1) {
                content = this.getSingleForm(p.val)
            }
            else if (p.type == 2) {
                content = this.getFormList(p.val)
            }
            else {
            }
            
            return content
        }
    }

    getPanes() {

        var config = this.state.pageInfo.p.templateConfig
        if (config && config.includes('{')) {
            config = JSON.parse(config)
            var templateTypes = [{ name: 'Single Form', id: 1 }, { name: '1 Column', id: 2 }, { name: '2 Column', id: 3 }, { name: '3 Column', id: 4 }]
            var type = config.templateType

            if (type == 1) {
                return <Row><Col sm={6}>{this.getPaneContent(2, config)}</Col></Row>
            }
            else {
                return (
                    <div>
                        <Row>
                            {type > 1 && <Col sm={12}>{this.getPaneContent(1, config)}</Col>}
                        </Row>

                        <Row>
                            <Col xs={12} sm={4}>{this.getPaneContent(2, config)}</Col>
                            {type > 2 && <Col xs={12} sm={4}>{this.getPaneContent(3, config)}</Col>}
                            {type > 3 && <Col xs={12} sm={4}>{this.getPaneContent(4, config)}</Col>}
                        </Row>

                        <Row>
                            {type > 1 && <Col sm={12}>{this.getPaneContent(5, config)}</Col>}
                        </Row>
                    </div>)
            }
        }
        else {
            return <div style={{display:'flex',justifyContent:'center'}}><b>Enable edit mode to add content to this page</b></div>
        }
    }

    render() {
        const { pageInfo, forms, editMode, selectedTemplate, groups } = this.state
        var templateTypes = [{ name: 'Single Form', id: 1 }, { name: '1 Column', id: 2 }, { name: '2 Column', id: 3 }, { name: '3 Column', id: 4 }]
        console.log('props', this.props)
        if (pageInfo) {
            return (
                <div>
                    <Col sm={12}>
                        <Row style={{justifyContent: 'space-between'}}> 
                            <div className='titleBlock'>{pageInfo.p.menuText}</div>
                            {editMode && <Row><b>Template Type:</b><IdNameDropdown options={templateTypes} valueField="id" displayField="name" value={selectedTemplate} onChange={(e) => this.setState({ selectedTemplate: e })} /></Row>}
                            <ToggleSwitch smallMode={true} switchLabel="Edit Mode" onToggle={() => this.toggleEditMode()} />
                        </Row>
                    </Col>
                    {editMode ?
                        <div>
                            <TemplateBuilder config={pageInfo.p.templateConfig} type={selectedTemplate} forms={forms} groups={groups} />
                        </div> :
                        this.getPanes()
                    }
                    {this.state.preFormOpen && this.state.preForm && <PreForm preForm={this.state.preForm} toggle={() => this.togglePreForm()}  />}
                </div>
            )
        }
        else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common }
}

export default connect(
    mapStateToProps,
)(FormPage)