import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import TableModal from './TableModal'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

class HWCard extends Component {

    constructor(props) {
        super(props);
        this.state = { showModal: false, mItems: null }
    }

    toggleModal() {
        this.setState({ showModal: !this.state.showModal })
	}

	navTo() {
        if (this.props.data.sourceId > 100) {
            this.props.history.push({ pathname: '/Scada/' + this.props.data.stationId, state: { rangeId: 5, metricId: this.props.data.sourceId-400 } })
        }
        else if (this.props.data.sourceId == 7 || this.props.data.sourceId == 5) {
            this.props.history.push({ pathname: '/Scada/' + this.props.data.stationId, state: { rangeId: 2 } })
        }
        else {
            var fDate = moment().add(-1, 'days')
            var tDate = moment().add(-30, 'days')
            var fDateC = moment().add(-90, 'days')
            var tDateC = moment().add(-60, 'days')
            var pcId = parseInt(fDate.format('DDMMYY') + '' + tDate.format('DDMMYY'))
            var dvId = parseInt(this.props.data.stationId + '' + pcId);
            var pcIdC = parseInt(fDateC.format('DDMMYY') + '' + tDateC.format('DDMMYY'))
            var dvIdC = parseInt(this.props.data.stationId + '' + pcIdC);
            var requestInfo = [{
                value: dvId,
                pcId: pcId,
                label: this.state.sName + ' ' + fDate.format('DD/MM/YY') + '-' + tDate.format('DD/MM/YY'),
                stationId: this.props.data.stationId ,
                fromDate: fDate.format('YYYY-MM-DDTHH:mm:ss'),
                toDate: tDate.format('YYYY-MM-DDTHH:mm:ss'),
                controller: true,
                meter: false,
                spec: true
            }, {
                    value: dvIdC,
                    pcId: pcIdC,
                    label: this.state.sName + ' ' + fDateC.format('DD/MM/YY') + '-' + tDateC.format('DD/MM/YY'),
                    stationId: this.props.data.stationId,
                    fromDate: fDateC.format('YYYY-MM-DDTHH:mm:ss'),
                    toDate: tDateC.format('YYYY-MM-DDTHH:mm:ss'),
                    controller: true,
                    meter: false,
                    spec: true
                }];

            this.props.history.push({ pathname: '/Analysis', state: { dvType: 1, stationId: this.props.data.stationId, requestInfo: requestInfo } })
			//this.props.history.push('/Analysis')
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps != this.props) {
            var x = this.props.data
			var d = JSON.parse(x.warningDetail)
			var oCount = typeof d.o === 'object' ? Object.keys(d.o).length : d.o.length
			var uCount = typeof d.u === 'object' ? Object.keys(d.u).length : d.u.length
			var tCount = oCount + uCount
			var sName = x.name
            this.setState({ mItems: { title: sName + ' ' + x.sourceName, data: d }, tCount, sName, warnAlias: x.warnAlias ? x.warnAlias : 'occurrence' })
		}
    }

    render() {
        return (
            <div key={'hwCard' + this.state.sName}>
                <div className='indSlide' key={this.state.sName + 'c' + this.state.tCount}>
                    <Row>
                        <Col sm='12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><h2>{this.state.sName}</h2></Col>
                    </Row>
                    {this.props.data.warningMsg && 
                        <Row>
                            <Col sm='12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><h3>{this.props.data.warningMsg}</h3></Col>
                        </Row>
                    }
                    <Row>
						<Col sm='12' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1.2rem' }}>
                            <span>{this.state.tCount} {this.state.warnAlias}{this.state.tCount > 1 && 's'}</span>
							<i style={{ marginLeft: '10px', cursor: 'pointer' }} className='fal fa-info-square' onClick={() => this.toggleModal()} />
							<i style={{ marginLeft: '10px', cursor: 'pointer' }} className='fal fa-sign-out-alt' onClick={() => this.navTo()} />
						</Col>
                    </Row>
                </div>
                {this.state.showModal && <TableModal data={this.state.mItems} tagId={this.props.data.eTagId} uId={this.props.data.id} toggleModal={() => this.toggleModal()} />}
            </div>
        );
    }
};

export default withRouter(HWCard);