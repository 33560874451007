import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducers from './root-reducer.js'
import createSagaMiddleware from 'redux-saga'
import { CRUD_PATH } from './api'
import createDebounce from 'redux-debounced';

const crudMiddleware = createSagaMiddleware()

//let createStoreWithMiddleware = applyMiddleware(createDebounce(),thunk,crudMiddleware)(createStore);

let createStoreWithMiddleware = applyMiddleware(thunk,crudMiddleware)(createStore);

import { crudSaga, ApiClient } from 'redux-crud-store'

const client = new ApiClient({ basePath: CRUD_PATH, credentials: 'include', mode: 'cors' })

const configureStore = () => {
  const store = createStoreWithMiddleware(
   reducers, /* preloadedState, */
   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  crudMiddleware.run(crudSaga(client))
  return store
}

export default configureStore