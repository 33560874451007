import React, { Component } from 'react';
import IdNameDropdown from '../Controls/IdNameDropdown'
import WorkflowComponent from './WorkflowComponent'

export default class WcDetails extends WorkflowComponent {

    constructor(props) {
        super(props);
        
        this.setFaultCase = this.setFaultCase.bind(this)
    }

    componentDidMount() {
        var engineers = this.props.initData.engineers;
        var alertName = "";
        let alertId;

        this.initState(
            { isScheduled:false, engineerList:[], startAdd:true, addId:"", addName:"" }
        )
    }

    addEngLine(IdName) {

    }

    render() {
        const { engineers, faultCases, recCase, recomendedCase} = this.props.initData

        return (
            <div>
                <IdNameDropdown options={faultCases} valueField="caseId" displayField="caseRef" value={this.state.data.selCase} onChange={(event) => this.setState({ addName: event.target })} />
            </div>
        );
    }
}