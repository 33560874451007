import React, { Component } from 'react';
import Select from 'react-select';
import { Col, Label, Row, Badge } from 'reactstrap'

export default class SearchList extends Component {

    constructor(props) {
        super(props);
        this.state = { flVal: null }
    }

    handleFilterList(type, newValue) {
        this.setState({ [type]: newValue })
    }

    getSelect() {
        var options = [], defaultValue = this.state.flVal ? this.state.flVal : this.props.value
        this.props.mainData.map(l => {
            options.push({ label: l.name, value: l.id });
        })

        if (this.props.readOnly && !isNaN(defaultValue)) {
            return this.props.mainData.find(x => x.id == defaultValue) ? this.props.mainData.find(x => x.id == defaultValue).name : ''
        }

        return (<Col xs={12} style={{ padding: 0 }}><Select options={options} removeSelected={false} clearable={false} onChange={(e) => { this.handleFilterList('flVal', e), this.props.onChange(e) }} value={defaultValue} placeholder={this.props.placeholder ? this.props.placeholder : 'Select Option'} /></Col >)
    }

    render() {
        return (
            <Row style={{margin: 0}}>
                {this.getSelect()}
            </Row>
        )
    }

}