import React, { Component } from 'react';
import { Col, Dropdown, DropdownToggle, DropdownMenu, Input } from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import MomentLocaleUtils, { formatDate, parseDate, } from 'react-day-picker/moment';
moment.locale('en-gb');

const DAY_FORMAT = 'DD/MM/YYYY';

export default class DropDatePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dateSelectTitle: 'Date Range', dropdownOpen: false, fromDate: null, toDate: null,
            errorMsg: ''
        };

        this.toggleDD = this.toggleDD.bind(this);
        this.onSelectCustomRange = this.onSelectCustomRange.bind(this);
    }

    toggleDD() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    setTime(range, data) {
        if (range == 'From') {
            var fromTime = moment(this.state.fromDate);
            fromTime.set('hour', data.target.value.split(':')[0]);
            fromTime.set('minute', data.target.value.split(':')[1]);
            this.setState({ fromDate : fromTime });
        }
        else {
            var toTime = moment(this.state.toDate);
            toTime.set('hour', data.target.value.split(':')[0]);
            toTime.set('minute', data.target.value.split(':')[1]);
            this.setState({ toDate: toTime });
        }
    }

    handleFromChange(from) {
        this.setState({ fromDate: moment(from).startOf('day')});
    }

    handleToChange(to) {
        this.setState({ toDate: moment(to).endOf('day') });
    }

    componentDidMount() {

        this.setState({ fromDate: this.props.startDate ? this.props.startDate : moment(), toDate: this.props.endDate ? this.props.endDate : moment()}, () => this.onSelectCustomRange(false));
        this.toggleDD();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps != this.props) {
            if (this.props.startDate != nextProps.startDate || this.props.endDate != nextProps.endDate) {
                this.onSelectPredefinedRange(nextProps.startDate, nextProps.endDate);
            }
        }
    }

    onSelectPredefinedRange(from, to) {
        var fromDate = from;
        var toDate = to;

        if (fromDate.format('DD/MM/YY').toString() == toDate.format('DD/MM/YY').toString()) {
            this.setState({
                dateSelectTitle: moment(fromDate).format('DD/MM/YY') + ' ' + moment(fromDate).format('HH:mm') + ' to ' + moment(toDate).format('HH:mm')
            })
        }
        else {
            this.setState({
                dateSelectTitle: moment(fromDate).format(DAY_FORMAT) + ' to ' + moment(toDate).format(DAY_FORMAT)
            })
        }

        if (fromDate > toDate) {
            this.setState({ errorMsg: "'From' cannot be after 'To' date" })
        }
        else {
            this.setState({ errorMsg: '', fromDate, toDate })
            this.toggleDD();
            this.props.handleDate(fromDate, toDate, false);
        }
    }

    onSelectCustomRange(custom) {
        var fromDate = this.state.fromDate;
        var toDate = this.state.toDate;

        if (fromDate.format('DD/MM/YY').toString() == toDate.format('DD/MM/YY').toString()) {
            this.setState({
                dateSelectTitle: moment(fromDate).format('DD/MM/YY') + ' ' + moment(fromDate).format('HH:mm') + ' to ' + moment(toDate).format('HH:mm')
            })
        }
        else {
            this.setState({
                dateSelectTitle: moment(fromDate).format(DAY_FORMAT) + ' to ' + moment(toDate).format(DAY_FORMAT)
            })
        }

        if (fromDate > toDate) {
            this.setState({ errorMsg: "'From' cannot be after 'To' date" })
        }
        else {
            this.setState({ errorMsg: '' })
            this.toggleDD();
            this.props.handleDate(fromDate, toDate, custom);
        }
    }

    render() {
        return (
            <div style={this.props.width?{width:this.props.width}:null}>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDD}>
                    <DropdownToggle caret>
                        {this.state.dateSelectTitle}
                    </DropdownToggle>
                    <DropdownMenu className="cosy rightRespon time-picker" right={this.props.right ? true : false} >

                        <div style={{ display: 'flex', paddingBottom: '5px' }}>
                            <Col xs={2}><span>From</span></Col>
                            <Col xs={5}>
                                <DayPickerInput parseDate={parseDate} value={`${formatDate(this.state.fromDate, 'L', 'en-gb')}`} dayPickerProps={{ locale: 'en-gb', localeUtils: MomentLocaleUtils }} onDayChange={(selectedDay) => this.handleFromChange(selectedDay)} />
                            </Col>
                            <Col xs={5}>
                                <Input type="time" value={moment(this.state.fromDate).format('LT')} onChange={data => this.setTime('From', data)} />
                            </Col>
                        </div>

                        <div style={{ display: 'flex', paddingBottom: '5px' }}>
                            <Col xs={2}><span>To</span></Col>
                            <Col xs={5}>
                                <DayPickerInput parseDate={parseDate} value={`${formatDate(this.state.toDate, 'L', 'en-gb')}`} dayPickerProps={{ locale: 'en-gb', localeUtils: MomentLocaleUtils }} onDayChange={(selectedDay) => this.handleToChange(selectedDay)} />
                            </Col>
                            <Col xs={5}>
                                <Input type="time" value={moment(this.state.toDate).format('LT')} onChange={data => this.setTime('To', data)} />
                            </Col>
                        </div>

                        <div style={{ display: 'flex', paddingBottom: '5px' }}>
                            <Col xs={7} />
                            <Col xs={5}>
                                <button type="button" className="btn btn-primary" style={{ width: '100%', cursor:'pointer' }} onClick={() => this.onSelectCustomRange(true)}>
                                    Select
                                 </button>
                            </Col>
                        </div>

                        <div style={{ textAlign: 'center' }}>
                            <Col xs={12}>
                                <span style={{ color: '#d0021b' }}>{this.state.errorMsg}</span>
                            </Col>
                        </div>
                    </DropdownMenu>
                </Dropdown >
            </div>
        );
    }
}