import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
//import MasterDetail from '../../../testComp/MasterDetail/src'
import { listRequest } from '../../../configuration/enums'
import { ApiPost, ApiGet } from '../../../api'
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap'
import classnames from 'classnames'
import { getConfigItem } from '../../../api'
export const API_PATH = process.env.SERVER_PATH + '/api'

class FormCustom extends Component {

    constructor(props) {
        super(props);
        var formConfig = getConfigItem('$.forms')
        var tabletConfig = null
        if (formConfig && formConfig.tabletModeMax && formConfig.tabletModeMin) {
            tabletConfig = { max: formConfig.tabletModeMax, min: formConfig.tabletModeMin}
        }
        this.state = {
            formConfig: null, formTitle: null, dbEntityKey: null, subForms: null, subActiveTab: '0', parentRowId: null, lookups: null, displayRules: null, customTableLookups: null,
            formId: null, isEdit: false, isTemplate: false, formDesc: null, tabletConfig, tabletMode: false, exportIcon: false, mdArr: [], gotSubForms: false
        }
    }

    componentDidMount() {
        this.initiate()
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.isExternal && prevProps.externalFormId != this.props.externalFormId) || (prevProps.match && prevProps.match.params.id != this.props.match.params.id)) {
            this.initiate()
        }
    }

    initiate() {
        if (this.props.isExternal) {
            var formId = this.props.formId, isEdit = this.props.isEdit ? '1' : '0', isTemplate = false, parentRowId = null
            this.setState({ formId, isEdit, isTemplate, dbEntityKey: null, parentRowId })
            this.getFormSetup(formId, isEdit)
        }
        else {
            var formId = this.props.match && this.props.match.params.id, isEdit = this.props.match && this.props.match.params.edit,
                isTemplate = this.props.match && this.props.match.params.template && this.props.match.params.template == "1"
            this.setState({ formId, isEdit, isTemplate })
            var formSelectionId = this.props.location.state && this.props.location.state.formSelectionId ? this.props.location.state.formSelectionId : null
            if (this.props.location.state && this.props.location.state.history) {
                var i = this.props.location.state.history.length - 1
                formId = formId != 'New' ? formId : this.props.location.state.history[i].formId
                formSelectionId = this.props.location.state.history[i].formSelectionId && this.props.location.state.history[i].formSelectionId 
                this.setState({ dbEntityKey: this.props.location.state.history[i].dbEntityKey != undefined ? this.props.location.state.history[i].dbEntityKey : null, formSelectionId: formSelectionId })
                var tId = this.props.location.state.history.length == 1 ? 0 : i - 1

                if (this.props.location.state.history[tId].parentRowId != -1) {
                    this.setState({ parentRowId: this.props.location.state.history[tId].parentRowId })
                }
            }
            this.getFormSetup(formId, isEdit, formSelectionId)
        }
        if (this.state.tabletConfig) {
            if (window.screen.availWidth > this.state.tabletConfig.min && window.screen.availWidth < this.state.tabletConfig.max) {
                this.setState({tabletMode: true})
            }
            else {
                this.setState({ tabletMode: false })
            }
        }
    }

    getFormSetup(fId, isEdit, formSelectionId) {
        var formId = formSelectionId ? formSelectionId : fId
        ApiGet('CustomForm', 'GetFormFields', { formId })
        .then((d) => {
            this.setState({ subForms: d.subForms, lookups: d.lookups, customTableLookups: d.customTableLookups, formSelectionId: d.formSelectionId })
            this.getFieldConfig(d.data, fId, isEdit)
            this.getFormConfig(d.data, fId)
        });
    }

    getFormConfig(data, formId) {
        var tConfig = data.formConfig
        if (tConfig) {
            tConfig = JSON.parse(tConfig)
            if (tConfig.rules) {
                var tRules = JSON.parse(tConfig.rules)
                this.setState({displayRules: tRules})
            }
        }
    }

    getList(x, config) {
        var list = null
        if (x.fieldDataType == 9) {
            list = this.state.lookups[config.lookupTbl]
        }
        else if (x.fieldDataType == 10) {
            list = parseInt(config.dbLookupTbl)
        }
        else if (x.fieldDataType == 14) {
            list = this.state.customTableLookups[config.customTableLookup]
        }

        return list
    }

    getDisplay(rec, d) {
        var display = true
        if (this.state.displayRules) {
            var fId = d.formFieldDefId, gId = d.groupId, tRules = this.state.displayRules
            tRules.map(r => {
                r.actions.map(a => {
                    if (('ff_' + a.aVal == fId && a.aType == 'field') || (a.aVal == gId && a.aType == 'group')) {
                        r.conditions.map(c => {
                            var recVal = parseFloat(rec['ff_' + c.cField]), comVal = parseFloat(c.cVal), comOp = c.cOperator
                            if (comOp == '=') {
                                if (recVal == comVal) {
                                    display = a.aAction == 'hide' ? false : true;
                                }
                                else {
                                    display = a.aAction == 'hide' ? true : false;
                                }
                            }
                            else if (comOp == '!=') {
                                if (recVal != comVal) {
                                    display = a.aAction == 'hide' ? false : true;
                                }
                                else {
                                    display = a.aAction == 'hide' ? true : false;
                                }
                            }
                            else if (comOp == '>') {
                                if (recVal > comVal) {
                                    display = a.aAction == 'hide' ? false : true;
                                }
                                else {
                                    display = a.aAction == 'hide' ? true : false;
                                }
                            }
                            else if (comOp == '<') {
                                if (recVal < comVal) {
                                    display = a.aAction == 'hide' ? false : true;
                                }
                                else {
                                    display = a.aAction == 'hide' ? true : false;
                                }
                            }
                        })
                    }
                })
            })
        }
        else if (d.hidden == true) {
            display = false
        }

        return display
    }

    getValidation(r, v) {
        if (v && v.includes('{')){
            var t = JSON.parse(v)
            if (r != undefined) {
                if (t.op == '=') {
                    if (r == t.opVal) {
                        return 'Error'
                    }
                    else {
                        return undefined
                    }
                }
                else if (t.op == '!=') {
                    if (r != t.opVal) {
                        return 'Error'
                    }
                    else {
                        return undefined
                    }
                }
                else if (t.op == '>') {
                    if (r > t.opVal) {
                        return 'Error'
                    }
                    else {
                        return undefined
                    }
                }
                else if (t.op == '<') {
                    if (r < t.opVal) {
                        return 'Error'
                    }
                    else {
                        return undefined
                    }
                }
                else if (t.op == '>..<') {
                    if (r < t.opVal || r > t.opVal2) {
                        return 'Error'
                    }
                    else {
                        return undefined
                    }
                }
            }
        }
        else {
            return null
        }

    }

    getFieldConfig(data, formId, isEdit) {
        var fieldTypes = { 1: 'text', 2: 'number', 3: 'datetime', 4: 'readOnly', 5: 'map', 6: 'upload', 7: 'textarea', 8: 'checkbox', 9: 'list', 10: 'list', 11: 'readOnly', 12: 'lineBreak', 13: 'coorPicker', 14: 'list', 15: 'tagAllocation', 16: 'meterReadings', 17: 'custom' },
            formFields = [{ label: "Id", fieldId: "id", display: "none" }, { label: "pId", fieldId: "parentRowId", display: "none" }]
        data.fields.map(x => {
            var config = x.config != "" && JSON.parse(x.config)
            var fieldConfig = { label: x.dataName, fieldId: x.formFieldDefId.toString(), type: fieldTypes[x.fieldDataType], section: x.fGroup, displayIf: (r) => this.getDisplay(r, x), validation: x.validation ? (r) => this.getValidation(r, x.validation) : null, list: this.getList(x, config), config }
            if (x.fieldDataType == 17 && this.state.mdArr[config.subTable]) {
                var cConfig = this.state.mdArr[config.subTable]
                fieldConfig['custom'] = () => <div><MasterDetail key={x.formFieldDefId} {...cConfig} /><button id={'a' + formId} style={{display:'none'}} onClick={() => console.log(formId)}></button></div>
                //fieldConfig['customProps'] = this.state.mdArr[config.subTable]
            }
            formFields.push(fieldConfig)
        })
        var titleField = data.titleFieldId ? formFields.find(x => x.fieldId == 'ff_'+data.titleFieldId).label : 'name'
        var parentRowId = this.state.parentRowId
        var formConfig = {
            model: 'FormData',
            crudParams: { formId, isEdit, dbEntityKey: this.state.dbEntityKey, parentRowId, isTemplate: this.state.isTemplate ? '1' : '0', formSuppId: this.props.location.state && this.props.location.state.formSuppId && this.props.location.state.formSuppId, formSelectionId: this.state.formSelectionId, isWizard: data.isWizard, isDynamicForm: true },
            preData: { formId, isEdit, parentRowId, id: formId },
            fields: formFields,
            master: true,
            defaultSorted: [{ id: 'timeStamp' }],
            titleField,
            hideDelete: true,
            submitAction: -1,
            isWizard: data.isWizard,
            apiPath: API_PATH
        }
        if (this.props.isExternal) {
            formConfig.externalFormId = this.props.formId
        }
        this.setState({formConfig, formTitle: data.formName, formDesc: data.formDesc})
    }

    goNav(sF) {
        this.props.history.push('/FormCustom/' + sF.id + '/' + this.state.isEdit ? 1 : 0, { history: [{ dbEntityKey: this.state.dbEntityKey }] })
    }

    toggle(tab, type) {
        if (this.state.subActiveTab !== tab) {
            this.setState({ subActiveTab: tab });
        }
    }

    getSubForms() {
        var rowRet = [], ret = null
        var tabRet = [], navRet = []
        var mdArr = []

        if (this.state.subForms.length > 0 && this.state.isEdit) {
            this.state.subForms.map((x, i) => {
                var galleryView = x.isGallery
                var responseFields = [
                    { label: "Id", fieldId: "id", width: 70, display: 'none' },
                    //{ label: "Time Stamp", fieldId: "timeStamp", type: "datetime" },
                    //{ label: "User", fieldId: "userId", list: listRequest.operators },
                    //{ label: "Version", fieldId: "version" },
                ];
                x.fFields.map(y => {
                    responseFields.push({ label: y.dataName, fieldId: 'f_' + y.formFieldDefId, type: y.fieldDataType == 3 ? 'datetime' : y.fieldDataType == 8 ? 'checkbox' : null, width: y.gridWidth, display: y.gridWidth == 0 && 'none' })
                })
                responseFields.push({ label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { btnLabel: 'View' }, funcProps: (rec) => { return { location: '/FormCustom/' + rec._original.id + '/1', state: { parentRowId: this.state.formId } } }, width: 70 })
                var galleryFields = [
                    { label: "Id", fieldId: "id" },
                    { label: "Thumbnail", fieldId: "thumbnail" }
                ]
                var responseConfig = {
                    model: 'FormBuilderResponses',
                    path: '/FormSelection/Selection' + (galleryView ? 'Gallery' : ''),
                    fields: galleryView ? galleryFields : responseFields,
                    hideDelete: true,
                    galleryView: galleryView ? JSON.parse(galleryView) : null,
                    crudParams: { rowId: this.state.formId, dbEntityKey: this.state.dbEntityKey, formId: x.id },
                    gridButton: { pathname: '/FormCustom/' + x.id + '/0', history: [{ parentRowId: this.state.formId, dbEntityKey: this.state.dbEntityKey }] },
                    type: x.isTaskList ? 'checklist' : null,
                    staticTitleField: 'Response',
                    gridProps: { minRows: 2, showPaginationBottom: true },
                    isSubtable: true,
                    apiPath: API_PATH
                }
                mdArr[x.id] = responseConfig
                navRet.push(<NavItem key={'ni' + i}><NavLink className={classnames({ active: this.state.subActiveTab === i.toString() })} onClick={() => { this.toggle(i.toString(), 'main') }}>{x.name}</NavLink></NavItem>)
                tabRet.push(<TabPane key={'tp' + i} tabId={i.toString()}>{this.state.subActiveTab == i.toString() && <MasterDetail key={'mdss' + i.toString()} {...responseConfig} />}</TabPane>)
            })

            ret = (
                <div style={{ marginTop: '20px' }}>
                    <Nav tabs>
                        {navRet}
                    </Nav>
                    <TabContent key='clientTabs' activeTab={this.state.subActiveTab}>
                        {tabRet}
                    </TabContent>
                </div>
            )
        }

        this.setState({ gotSubForms: true, mdArr })

        //return ret;
    }

    tabClick(e) {
        //e.preventDefault()
        e.focus()
        /*window.dispatchEvent(new KeyboardEvent('keydown', { 'key': 9 }));*/
        //var tabEvent = new KeyboardEvent('keydown', { key:'a' })
        //console.log('tabEvent', tabEvent)
        //window.dispatchEvent(tabEvent)
    }

    exportForm() {
        ApiPost('FormExport', 'FormToPDF', { formRowId: this.state.formId })
        .then((data) => {
            if (data.pdf) {
                const decodedData = atob(data.pdf); // Decode Base64
                const arrayBuffer = new ArrayBuffer(decodedData.length);
                const uint8Array = new Uint8Array(arrayBuffer);

                for (let i = 0; i < decodedData.length; i++) {
                    uint8Array[i] = decodedData.charCodeAt(i);
                }

                const blob = new Blob([uint8Array], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = data.fileName + '.pdf';

                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                this.setState({ exporting: false })
            } else {
                // Handle the case when the PDF is not available
                console.error('PDF not generated');
                this.setState({ exporting: false })
            }
        })
        .catch((error) => {
            // Handle the error from the API call
            console.error('Error exporting form:', error);
            this.setState({ exporting: false })
        });
    }

    render() {
        {!this.state.gotSubForms && this.state.subForms && this.getSubForms() }
        return (
            <div>
                <div className="mr-auto p-2">
                    <h3>{this.state.formTitle}</h3>
                    <p>{this.state.formDesc}</p>
                    <button className="btn btn-primary" type="button" style={{ position: 'absolute', top: '15px', right: '45px' }} onClick={() => this.setState({ exporting: true }, () => this.exportForm())}>Export <i className={this.state.exporting ? 'fa fa-spinner fa-spin' : 'far fa-file-download'}/></button>
                </div>
                <div>
                    {this.state.formConfig && <div><MasterDetail key={this.state.formTitle} {...this.state.formConfig} tabletMode={this.state.tabletMode} />{/*this.getSubForms()*/}</div>}
                    {this.state.tabletMode && <div className='tabFloater' onClick={(e) => { e.preventDefault(); this.tabClick(e); }}>Next Input</div>}
                </div>
            </div >
            );
    }
}

export default (FormCustom)