import React, { Component } from 'react';
import WorkflowComponent from './WorkflowComponent'
import { Collapse, CardBlock, Card, CardTitle, Col, Form, FormGroup, Label, Input, FormText, Button, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';

export default class WfShowMessage extends WorkflowComponent {

    constructor(props) {
        super(props);
        this.state = { text: ""  }
        
    }

    componentDidMount() {
        this.initState(
            { text: ""  }
        )
    }

    render() {
        var msg = "";
        const { data } = this.state;
        if (data) msg = data.message;
        return (
            <div style={{width :'100%'}}>
                {msg}
            </div>
        );
    }
}