import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Table, ModalHeader } from 'reactstrap';
import moment from 'moment'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { ApiPost } from '../../../api'

class TableModal extends Component {

    constructor(props) {
        super(props);
        this.state = {updated: false}
    }

    renderTables() {
        var returnA = []
        const data = this.props.data.data
        if (data.n) {
            returnA.push(<span style={{display:'block'}}>{data.n}</span>)
        }

        if (data.o.length > 0 || Object.keys(data.o).length > 0) {
            returnA.push(<span key='sO'>Values over threshold</span>)
            returnA.push(
            <Table key='tO' striped responsive bordered hover className="analysisTable" >
                    <thead><tr>
                        <th></th><th>Val</th>
                    </tr></thead>
                    <tbody>
                        {Object.keys(data.o).map(x => {
                            return <tr key={'o' + x}><td>{moment(x).isValid() ? moment(x).format('DD/MM/YY HH:mm') : /^\d+$/.test(x) && moment(parseInt(x)).isValid() ? moment(parseInt(x)).format('DD/MM/YY HH:mm') : x}</td><td>{data.o[x]}</td></tr>
                        })}
                    </tbody>
            </Table>)
        }

        if (data.u.length > 0 || Object.keys(data.u).length > 0) {
            returnA.push(<span key='sU'>Values under threshold</span>)
            returnA.push(
                <Table key='tU' striped responsive bordered hover className="analysisTable" >
                    <thead><tr>
                        <th></th><th>Val</th>
                    </tr></thead>
                    <tbody>
                        {Object.keys(data.u).map(x => {
                            return <tr key={'u' + x}><td>{moment(x).isValid() ? moment(x).format('DD/MM/YY HH:mm') : /^\d+$/.test(x) && moment(parseInt(x)).isValid() ? moment(parseInt(x)).format('DD/MM/YY HH:mm') : x}</td><td>{data.u[x]}</td></tr>
                        })}
                    </tbody>
                </Table>)
        }

        return returnA
    }

    setDefer(id, val) {
        var mGroup = {
            tagId: id,
            dDate: val,
            id: this.props.uId
        }

        ApiPost('Monitoring', 'MetricWarnDefer', mGroup)
        .then((data) => {
            this.setState({updated: true})
        })
    }

    getActionButton(type, id) {
        if (type == 'deferMetric') {
            var deferOpts = [{ id: 0, name: 'None' }, { id: 7, name: '1 Week' }, { id: 14, name: '2 Weeks' }, { id: 30, name: '1 Month' }, { id: 90, name: '3 Months' }]
            return <div style={{ marginLeft: 'auto' }}><span style={{ fontSize: '12px' }}>Defer Length</span><i className='fad fa-check-circle faGW' style={{ opacity: this.state.updated ? 100 : 0, transition: 'linear all 0.5s'}} /><IdNameDropdown style={{ width: '80px', padding: 0, height: '20px' }} options={deferOpts} valueField='id' displayField='name' onChange={(e) => this.setDefer(id, e)} /></div>
        }
    }

    render() {
        return (
            <Modal key={'modal' + this.props.data.title} isOpen={true} autoFocus={false}>
                <div style={{ display: 'flex', flexWrap:'wrap', padding: '10px 20px 0' }}>
                    <h5>{this.props.data.title}</h5>
                    {this.getActionButton('deferMetric', this.props.tagId)}
                </div>
                <ModalBody>
                    <div style={{maxHeight:'75vh', overflow:'auto'}}>{this.renderTables()}</div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn" onClick={() => this.props.toggleModal()} >Close</button>
                </ModalFooter>
            </Modal>
        );
    }
};

export default TableModal;