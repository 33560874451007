import React, { Component } from 'react';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import { Col, Row, Table, Button } from 'reactstrap';
import MeteringList from './MeteringList';

class MeteringLists extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPane: 0
        }

    }

    renderCollapsibles() {

        let collapsibleRender = [];
        var tempStationId = 0;

        if (this.state.meteringData) {
            this.state.meteringData.map(i => {

                if (tempStationId != i.stationId) {

                    tempStationId = i.stationId;

                    var tempStationData = [];

                    this.state.meteringData.map(x => {
                        if (x.stationId == tempStationId) {
                            tempStationData.push(x);
                        }
                    })

                    collapsibleRender.push(
                        <Collapsible trigger={i.stationName} key={i.stationId + i.metricId} transitionTime={200} open={true}>

                            <Table>
                                <thead><tr>{this.renderHeaderRow(tempStationData)}</tr></thead>
                                
                            </Table>

                        </Collapsible>
                    )
                }

            });
        }

        return collapsibleRender;
    }

    render() {
        const { assets } = this.props.header

        const dateRange = this.props.mData ? parseInt(this.props.mData[0]) : 1;
        const month = this.props.mData ? parseInt(this.props.mData[1]) : 0;
        const year = this.props.mData ? parseInt(this.props.mData[2]) : (new Date()).getFullYear();

        return (assets.map((o,i) => {
                    const fa = "fa fa-chevron-" + ((this.state.currentPane == o.id)?"up":"down") + " pull-right"
                    return <div className="card" key={o.id}>
                        <div className="card-header" onClick={() => { this.setState({ currentPane: o.id }) }}>{o.name} <i className={fa} /></div>
                        {(this.state.currentPane == o.id)?
                            <div className="card-body">
                                <MeteringList stationId={o.id} dateRange={dateRange} month={month} year={year} />
                            </div>:null
                        }
                    </div>
                }
            )
        );
    }
};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(MeteringLists)
