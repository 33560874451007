import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class UniversalMetricSettings extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        var lists = {
            aggType: [{ id: 'a', name: 'Avg' }, { id: 't', name: 'Last' }, { id: 'm', name: 'Max' }],
            archivePlan: [{ id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4' }, { id: 5, name: '5' }],
            renderer: [{ id: 'renderTmcSysError', name: 'renderTmcSysError' }, { id: 'renderTmcSysState', name: 'renderTmcSysState' }, { id: 'renderVeryBigNum', name: 'renderVeryBigNum' }, { id: 'renderBinary', name: 'renderBinary' }],
            chartType: [{ id: 'column', name: 'Column' }]
        }

        var MetricSettingsFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Name", fieldId: "universalName", width: 170, canFilter: true },
            { label: "Units", fieldId: "unit" },
            { label: 'Agg Type', fieldId: 'aggType', list: lists.aggType },
            { label: 'Decimal Places', fieldId: 'decimalPlaces' },
            { label: 'Surpress Above', fieldId: 'surpressAbove', display: 'form' },
            { label: 'Surpress Below', fieldId: 'surpressBelow', display: 'form' },
            { label: 'Is Meter?', fieldId: 'isMeter', display: 'form', type:'checkbox' },
            { label: 'View', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' },
        ]

        var MetricSettingsConfig = {
            model: 'UniversalMetricSettings',
            fields: MetricSettingsFields,
            master: true,
            titleField: 'universalName',
            hideDelete: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            defaultSorted: [{ id: 'id', desc: false }],
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Universal Metric Settings</div>
                <MasterDetail {...MetricSettingsConfig} />
            </div >)
    }
}

export default (UniversalMetricSettings)