import React, { Component } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import { NavLink as Link, withRouter } from 'react-router-dom';
import { ApiPost } from '../../api'
import logo from '../../../content/everun.png'
import NotificationItem from '../../scenes/Notifications/components/NotificationItem'
const BROWSER_ALERT = process.env.BROWSER_ALERT 

class NotificationMgmt extends Component {

    constructor(props) {
        super(props)
        this.state = { open: false, nCount: 0 }
    }

    componentDidMount() {
        if (this.props.notifications) {
            this.setState({ nCount: this.props.notifications.filter(x => x.read == false).length})
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.notifications) != JSON.stringify(this.props.notifications)) {
            var nCount = this.props.notifications.filter(x => x.read == false).length
            this.setState({ nCount })
            if (nCount > 0 && BROWSER_ALERT) {
                this.browserNotification(nCount)
            }
        }
    }

    browserNotification(count) {
        if (Notification.permission !== 'granted') {
            Notification.requestPermission()
        }
        else {
            var n = new Notification('Everun Portal Notification', { icon: logo, body: 'There '+ (count > 1 ? 'are ' : 'is ') + count + ' new notification' + (count > 1 ? 's' : '') })
            n.onclick = () => { window.open('http://app.everun.ltd/Notifications'); }
        }
    }

    createDD() {
        return (<Dropdown isOpen={this.state.open} toggle={() => this.setState({ open: !this.state.open })} className="headerDD" >
            <DropdownToggle >
                <i className="fas fa-bell fa-lg" style={{ color: '#9d9d9d' }} />
                {this.state.nCount > 0 && <span className="badge badge-pill badge-danger notif-badge" key={this.state.nCount}>{this.state.nCount}</span>}
            </DropdownToggle>
            <DropdownMenu right>
                {
                    this.props.notifications.map(x => {
                        return <DropdownItem to={'/Notifications'} tag={Link} key={x.id} style={{ padding: 0 }} onClick={() => this.setRead()}><NotificationItem {...x} /></DropdownItem>
                    })
                }
                <DropdownItem to="/Notifications" tag={Link} style={{ textAlign: 'center', marginTop:'3px' }} onClick={() => this.setRead()}>View All</DropdownItem>
            </DropdownMenu>
        </Dropdown >)
    }

    setRead() {
        ApiPost('Notifications', 'SetRead', {})
        .then((data) => {
            console.log('marked read')
            this.setState({ nCount: 0 })
        })
    }

    linkNav(link) {
        this.props.history.push(link, {history: null})
    }

    render() {
        if (this.props.notifications) {
            return this.createDD()
        }
        else {
            return null
        }
    }
}

export default withRouter(NotificationMgmt);