import React, { Component } from 'react'
import { ApiGet } from '../../../api';
import FormCustom from '../../../scenes/CustomForm/components/FormCustom'

export default class FormPreview extends Component {

    constructor(props) {
        super(props);
        this.state = { formFields: null, formId: null, isPublic: false}
    }

    componentDidMount() {
        var formId = this.props.match && this.props.match.params.id
        this.publicCheck(formId)
    }

    publicCheck(formId) {

        ApiGet('IsPublicCheck', formId)
        .then((data) => {
            this.setState({isPublic: data, formId})
        })
    }


    render() {
        return (
            <div>
                {this.state.formId && this.state.isPublic && <FormCustom formId={this.state.formId} isExternal={true} isEdit={false} />}
                {this.state.formId && !this.state.isPublic && <div>You do not have access to this form. If you think this is a mistake please <b><a href='mailto:contactus@contactus.com'>contact us</a></b>.</div>}
            </div>
        )
    }
}