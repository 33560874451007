import React, { Component } from 'react'
import Select from 'react-select'
import { colorSwatches, colorShadeSwatches } from '../configuration/enums'

class SwatchPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {sVal:null}
    }

    onChange() {
        var fullConfig = this.props.params && this.props.params.constructor === Array ? this.props.params.filter(x => x.metricId != this.state.editMetric.metricId) : []

        fullConfig.push(this.state.editMetric)

        this.props.onChange(fullConfig)
    }

    handleSelectChange(color) {
        this.props.onChange(color.value)
        this.setState({sVal:color.value})
    }

    getSwatches() {
        var options = [];
        var swatches = colorSwatches

        if (this.props.isShade) {
            swatches =  colorShadeSwatches
        }

        Object.keys(swatches).map(s => {
            var t = []
            swatches[s].map(c => {
                t.push(<div key={'swa_' + c} style={{ backgroundColor: c, height: '15px', width: '15px', display: 'inline-block' }} />)
            })
            options.push({ label: <div key={'lab_' + s} ><div style={{ display: 'inline-block', width: '240px' }}>{t}</div><span style={{ marginLeft: '5px' }}>{swatches[s].length}</span></div>, value: s })
        })
        options.push({ label: <div><b>Custom</b></div>, value: 6 })

        return options
    }

    render() {
        var sVal = this.props.params || this.state.sVal
        return (
            <div>
                <Select
                    closeOnSelect={true}
                    disabled={false}
                    options={this.getSwatches()}
                    placeholder="Select Swatch"
                    onChange={(e) => this.handleSelectChange(e)}
                    value={sVal}
                    clearable={false}
                    />
            </div>
        )
    }
};

export default SwatchPicker;