import React, { Component } from 'react'
import ReactTable from "react-table";
import { ApiPost, ApiGet } from '../../../api';
import SimpleModal from './../../../components/SimpleModal'
import SimpleChart from './../../../components/SimpleChart'
import { Button, Col, Row, Input } from 'reactstrap'
import moment from 'moment'
import OptionBtn from './../../../components/Controls/OptionButton'
import CheckBoxList from './../../../components/Controls/CheckBoxList'

class ScadaDash extends Component {

    constructor(props) {
        super(props);
        this.currentYear = moment.utc().year()
        this.currentMonth = moment.utc().month() + 1
        this.defaultChartRender = { 1: 'line', 2: 'line', 4: 'column', 11: 'line', 27: 'column', 28: 'column', 29: 'column', 130: 'column', 131: 'column', 132: 'line', 133: 'line', 1002: 'column' }
        this.tableConfig = { defaultPageSize: this.props.period == 1 ? 30 : 12, showPagination: false, className:'scadaPerfTbl' }
        this.state = {
            sMetrics: this.props.period == 1 ? [4, 11, 2] : [4,1002,11,2], sYears: [this.currentYear, this.currentYear - 1], sMonths: [1],
            header: null, body: null, footer: null, open: false, data: null, columns: null, uMets: null,
            chartData: null, chartCats: null, tempMetric: null

        }
    }

    componentDidMount() {
        this.getUMetricList()
    }

    setModal() {
        var yearL = [{ id: this.currentYear, name: this.currentYear }, { id: this.currentYear - 1, name: this.currentYear - 1 }, { id: this.currentYear - 2, name: this.currentYear - 2 }],
            metricsL = this.state.uMets, cTypes = ['column', 'line'], monthL = [{ id: this.currentMonth, name: this.currentMonth }, { id: this.currentMonth - 1, name: this.currentMonth - 1 }, { id: this.currentMonth - 2, name: this.currentMonth - 2 }],
            p = this.props.period

        var header = <span>Site Stats</span>,
            body = <div>
                <Row>
                    <Col sm="4"><b>Metrics</b></Col>
                    <Col sm="8">
                        <CheckBoxList showSelectAll={false} options={metricsL} DefaultIds={this.state.sMetrics} onChange={(e) => this.setState({sMetrics: e}, () => this.setColumns())} />
                    </Col>
                </Row>
                {p != 1 && <Row style={{ marginTop: '10px' }}>
                    <Col sm="4"><b>{p == 1 ? 'Months' : 'Years'}</b></Col>
                    <Col sm="8">
                        <CheckBoxList showSelectAll={false} options={p == 1 ? monthL : yearL} DefaultIds={p == 1 ? this.state.sMonths : this.state.sYears} onChange={(e) => this.setState(p == 1 ? { sMonths: e } : { sYears: e }, () => this.setColumns())} />
                    </Col>
                </Row>}
                <Row style={{ marginTop: '10px', borderTop: '1px solid #eceeef', paddingTop: '10px' }}>
                    <Col sm='12'><h5>Format</h5></Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col sm="4"><b>Metric</b></Col>
                    <Col sm="8">
                        <Input type='select' onChange={(e) => this.setState({ tempMetric: e.target.value }, () => this.setModal())} style={{ width: "100%" }} >
                            <option key={'all'} value={0}>Select Metric</option>
                            {this.state.sMetrics.map(x => {
                                return <option key={x} value={x}>{this.state.uMets ? this.state.uMets.find(z => z.id == x).name : ''}</option>
                            })}
                        </Input>
                    </Col>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <Col sm="4"><b>Type</b></Col>
                    <Col sm="8">
                        <Input type='select' onChange={(e) => { this.defaultChartRender[this.state.tempMetric] = e.target.value; this.setModal(); }} value={this.defaultChartRender[this.state.tempMetric]} style={{ width: "100%", textTransform:'capitalize' }} >
                            <option key={'all'} value={0}>Select Type</option>
                            {cTypes.map(x => {
                                return <option key={x} value={x}>{x}</option>
                            })}
                        </Input>
                    </Col>
                </Row>
            </div>,
            footer = <div style={{ width: '100%' }}>
                <Button onClick={() => { this.setState({ open: !this.state.open }); this.tModel = null; this.tDesc = null; this.imgBase = null; }}>Cancel</Button>
                <Button style={{ float: 'right' }} onClick={() => { this.setState({ open: !this.state.open }, () => this.getTableData()); }}>Save</Button>
            </div>

        this.setState({ open: true, header, body, footer })
    }

    setColumns() {
        const { sMetrics, uMets, sYears, sMonths } = this.state
        var tColumns = [{ Header: this.props.period == 1 ? 'Day' : 'Month', accessor: 'month' }]
        sMetrics.map((m,i) => {
            var mColumn = []
            var aa = this.props.period == 1 ? sMonths : sYears
            aa.slice(0).reverse().map(y => {
                mColumn.push({ Header: y, id: y + '-' + m, accessor: d => d.vals[y + '-' + m], style: i % 2 == 0 && { backgroundColor: '#fbfbfb' }, headerStyle: i % 2 == 0 && { backgroundColor: '#fbfbfb' } })
            })
            tColumns.push({ Header: uMets ? uMets.find(z => z.id == m).name + ' (' + uMets.find(z => z.id == m).unit + ')' : '', columns: mColumn })
        })
        this.setState({ columns: tColumns }, () => this.getTableData())
    }

    getTableData() {
        var config = { metrics: this.state.sMetrics, years: this.props.period == 1 ? this.state.sMonths : this.state.sYears, stationId: this.props.stationId, period: this.props.period }
        ApiGet('Monitoring', 'GetSiteSummaryData', config)
        .then((data) => {
            this.setState({ data }, () => this.processChartData())
        })
    }

    processChartData() {
        const { sMetrics, sYears, uMets, data, sMonths } = this.state
        var series = [], cats = []
        sMetrics.map(x => {
            var aa = this.props.period == 1 ? sMonths : sYears
            aa.slice(0).reverse().map(y => {
                var key = y + '-' + x, mVals = []
                data.map(m => {
                    mVals.push({ name: m.month, y: m.vals[key] })
                })
                series.push({ name: uMets.find(z => z.id == x).name + '-' + y, data: mVals, type: this.defaultChartRender[x], yAxis: x == 4 ? 1 : 0 })
            })
        })
        data.map(m => {
            cats.push(m.month)
        })

        this.setState({ chartData: series, chartCats: cats})
    }

    getUMetricList() {
        ApiGet('Monitoring', 'GetUMetrics', { stationId: this.props.stationId })
        .then((data) => {
            this.setState({ uMets: data }, () => this.setColumns())
        })
    }

    exportData(d) {
        var csv = '', title = ['Month']
        const { sMetrics, uMets, sYears, sMonths } = this.state
        var aa = this.props.period == 1 ? sMonths : sYears
        sMetrics.map(f => {
            aa.slice(0).reverse().map(y => {
                title.push(uMets.find(y => y.id == f).name + ' - ' + y)
            })
        })
        csv += title.join(',') + '\r\n'
        d.map(x => {
            var row = x.month + ','
            sMetrics.map(f => {
                aa.slice(0).reverse().map(y => {
                    row += x[y+'-'+f] + ','
                })
            })
            csv += row + '\r\n'
        })
        csv = csv.replace(/undefined/g, '')
        var fileName = 'Site ' + this.props.stationId  + ' Data Export'
        var a = window.document.createElement("a");
        var data = new Blob([csv], { type: 'text/csv' });
        a.href = window.URL.createObjectURL(data);
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {
        const { footer, header, body, open, data, columns, chartData, chartCats, tempMetric } = this.state
        return (
            <div>
                {data &&
                    <div style={{ margin: '10px 0' }}>
                        <h3>{this.props.period == 1 ? 'Daily' : 'Monthly'} Performance</h3>
                        {chartData && <div style={{marginTop:'10px'}}><SimpleChart data={chartData} cats={chartCats} /></div>}
                        <Row className="gridHeader"><OptionBtn icon='fa fa-download' onClick={() => this.exportData(this.reactTable.getResolvedState().sortedData)}/><OptionBtn icon='fa fa-cog' onClick={() => this.setModal()} /></Row>
                        <ReactTable ref={(r) => this.reactTable = r} data={data} columns={columns} {...this.tableConfig} />
                    </div>}
                <SimpleModal {...{ header, body, footer, open, tempMetric }} />
            </div>
        )
    }

}

export default ScadaDash