import React, { Component } from 'react';
import { ApiPost, ApiGet } from '../api'
import { Col, Label, Row, Input } from 'reactstrap';

class ShortCodeGenerator extends Component {

    constructor(props) {
        super(props);
        this.state = { shortCodes: null, prefix: null, origPrefix: null }
    }

    componentDidMount() {
        this.getShortcodes()
        if (this.props.rec.data.shortCode) {
            console.log('setShortcode', this.props.rec.data.shortCode)
            this.setState({ prefix: this.props.rec.data.shortCode.split('-')[0], prefixCount: this.props.rec.data.shortCode.split('-')[1], origPrefixCount: this.props.rec.data.shortCode.split('-')[1], origPrefix: this.props.rec.data.shortCode.split('-')[0] })
        }
        //else {
        //    console.log('no short code', this.props.rec)
        //}
    }

    getShortcodes() {
        ApiGet('Stock', 'GetShortCodes')
        .then((d) => {
            this.setState({ shortCodes: d })
        })
    }

    prefixChange(val) {
        val = val.replace(/[^a-z0-9]/gi, '').toUpperCase().trim()
        var fil = this.state.shortCodes.filter(x => x.split('-')[0] == val), count = 1
        fil.map(f => {
            var tId = parseInt(f.split('-')[1])
            if (tId >= count) {
                count = (tId + 1)
            }
        })
        if(val == this.state.origPrefix){
            count = this.state.origPrefixCount
        }
        var shortCode = val + '-' + count
        this.setState({ prefixCount: count, prefix: val }, () => this.props.rec.onChange(shortCode))
    }

    render() {
        if (this.state.shortCodes) {
            return (
                <Row>
                    <Col xs={0} sm={2} /><Col style={{ marginLeft: '25px' }}>
                        <input className="form-control" value={this.state.prefix || ''} placeholder="Enter Prefix" onChange={(e) => this.prefixChange(e.target.value)} />
                    </Col>
                    <Col xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '-30px' }}><span>-</span></Col>
                    <Col>
                        <input className="form-control" readOnly value={this.state.prefixCount} />
                    </Col>
                </Row>
            )
        }
        else {
            return <div>Short Code Error</div>
        }
    }
}

export default (ShortCodeGenerator)