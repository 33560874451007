import React, { Component } from 'react'
import ScadaSchematic from '../../../scenes/Scada/components/ScadaSchematic'
import { ApiGet } from '../../../api';

class SchematicWidget extends Component {

    constructor(props) {
        super(props);
        this.state = { stationId: null, sDeets: null, scadaGroups: null }
    }

    initialise() {
        var stationId = this.props.devices[0].clientid + '9999'
        var sDeets = this.props.devices[0]
        this.setState({ stationId, sDeets })
        this.getScadaGroups('Live', stationId)
    }

    componentDidUpdate(pp) {
        if (pp.devices.length != this.props.devices.length) {
            this.initialise()
        }
    }

    getScadaGroups(groupType, stationId) {

        var scadaGroup = {
            groupType: groupType,
            metricList: undefined,
            stationId: stationId,
            allDevices: true
        }

        ApiGet('Monitoring', 'GetScadaGroups', scadaGroup)
        .then((data) => {
            this.setState({ scadaGroups: data.scadaGroups, gotGroups: true });
        })
    }

    getScadaValue(metricId, decimalPlaces = 0, deviceId) {
        var uMetId = this.state.scadaGroups[deviceId].find(x => x.metricId == metricId).uMetId
        var deviceVals = this.props.widgetValues.items.find(x => x.id == deviceId)
        if (deviceVals && deviceVals.metrics && deviceVals.metrics[uMetId]) {
            return (deviceVals.metrics[uMetId]).toFixed(decimalPlaces > 0 ? decimalPlaces : 0)
        }
        else {
            return 0
        }
    }

    render() {

        return (
            <div className="summary-widget widget">
                {this.state.gotGroups && <ScadaSchematic stationId={this.state.stationId} devices={this.props.devices} tags={this.state.scadaGroups} getScadaValue={(mId, dP, dId) => this.getScadaValue(mId, dP, dId)} sInfo={this.state.sDeets} /> }
            </div>
        );
    }
}

export default SchematicWidget