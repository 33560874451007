import redux from 'redux'
export const GET_FILTER_OPTIONS = 'GET_FILTER_OPTIONS'
export const GOT_FILTER_OPTIONS = 'GOT_FILTER_OPTIONS'
export const CHANGE_VALUE = 'FILTER_CHANGE_VALUE'
export const TOGGLE_TYPE_DISPLAY = 'TOGGLE_TYPE_DISPLAY'
export const TOGGLE_FILTER_OPEN = 'TOGGLE_FILTER_OPEN'
export const SET_FILTER_RESULT = 'SET_FILTER_RESULT'
export const GET_STATION_METRICS = 'GET_STATION_METRICS'
export const GOT_STATION_METRICS = 'GOT_STATION_METRICS'
export const RESET_AND_SET_FILTER = 'RESET_AND_SET_FILTER'
export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN'
export const TOGGLE_EDIT_MAPDASH = 'TOGGLE_EDIT_MAPDASH'
export const TOGGLE_BTN_MAPDASH = 'TOGGLE_BTN_MAPDASH'
export const GET_HISTORIC_DATA = 'GET_HISTORIC_DATA'
export const GOT_HISTORIC_DATA = 'GOT_HISTORIC_DATA'
export const EDIT_BREADCRUMB = 'EDIT_BREADCRUMB'
export const SAVE_GRID_FILTER = 'SAVE_GRID_FILTER'
export const SAVE_ACTIVITY_FILTER = 'SAVE_ACTIVITY_FILTER'
export const SET_MD_ID = 'SET_MD_ID'

import { ApiPost, ApiJsonPost, ApiGet } from '../api'


import { widgetconfig } from '../configuration/widgets';

export function getFilterOptions() {
    return (dispatch) => {
        ApiGet('UserSession','GetFilterOptions')
        .then((items) => {
            dispatch({
                type: GOT_FILTER_OPTIONS,
                payload: items
            })
        })
    }
}

export function setFilter(filters) {
    return (dispatch) => {
        ApiJsonPost('UserSession','SetFilter')
        .then((items) =>{
            console.log('setFilterDone')
            dispatch({
                type: SET_FILTER_DONE,
                payload: items
            })
        })
    }
}

export function changeValueX(filterTarget, index) {
    return {
        type: CHANGE_VALUE,
        filterTarget,
        index
    }
}
   
  

export function changeValue(filterTarget, index, filters, close) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_VALUE,
            filterTarget,
            index,
            close
        })
        //console.log("filters",filters);
        // the state has changed so only get the state from the control now
        var filt = getFilterValues(filters);
        // Set the filter server side and update the state with the result
        ApiJsonPost('UserSession','SetFilter',filt)
        .then((data) => {
            dispatch({
                type: SET_FILTER_RESULT,
                payload: data.result,
                filterId: data.filterId,
                exportCapacity: data.exportCapacity,
                users: data.users
            });         
            }
        )
    }
}

function getFilterValues(filters) {
    var ret = {}
    var keys = Object.keys(filters)
    var fl = {};
    for (var k of keys) {
        fl[k] = filters[k].value
    }
    return { filters: fl }
}

export function toggleTypeDisplay(filterTarget, show,filters) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_TYPE_DISPLAY,
            filterTarget,
            show
        })
        if (!show) {
            // the state has changed so only get the state from the control now
            var filt = getFilterValues(filters); 
            // Set the filter server side and update the state with the result
            ApiJsonPost('UserSession','SetFilter',filt)
            .then((data) =>
                dispatch({
                    type: SET_FILTER_RESULT,
                    payload: data.result,
                    filterId: data.filterId,
                    exportCapacity: data.exportCapacity,
                    users: data.users,
                    childDevices: data.childDevices
                })
            )
        }
    }
}

export function toggleFilterOpen(filterTarget) {
    return {
        type: TOGGLE_FILTER_OPEN,
        filterTarget
    }
}

export function getStationMetrics() {
    return (dispatch) => {
        dispatch({
            type: GET_STATION_METRICS
        })
        ApiGet('Dashboard','GetLiveState')
        .then((data) =>
            dispatch({
                type: GOT_STATION_METRICS,
                payload: data
            })
        )
    }
}

export function toggleFullScreen(fullScreen) {
    return {
        type: TOGGLE_FULL_SCREEN,
        fullScreen
    }
}

export function toggleEditMapDash(editMapDash) {
    return {
        type: TOGGLE_EDIT_MAPDASH,
        editMapDash
    }
}

export function toggleMapDashBtn(mapDashBtn) {
    return {
        type: TOGGLE_BTN_MAPDASH,
        mapDashBtn
    }
}

export function editBreadcrumb(breadcrumb) {
    //console.log('EDIT_BREADCRUMB', breadcrumb)
    return {
        type: EDIT_BREADCRUMB,
        breadcrumb
    }
}

export function saveGridFilter(gridFilter) {
    return {
        type: SAVE_GRID_FILTER,
        gridFilter
    }
}

export function saveActivityFilter(activityFilter, save = false) {
    if (save) {
        console.log('activityFilter', activityFilter)
        ApiJsonPost('UserSession', 'SetActivityFilter', activityFilter)
        .then((data) =>
            console.log('saved',data)
        )
    }
    return {
        type: SAVE_ACTIVITY_FILTER,
        activityFilter
    }
}

export function setMDId(newId) {
    console.log('this.props.match.params.id',newId)
    return {
        type: SET_MD_ID,
        newId
    }
}

export function resetFilter(setTo = {}) {
    return (dispatch) => {
        dispatch({
            type: RESET_AND_SET_FILTER,
            filterTarget: setTo.name,
            index: setTo.id
        })
        var setfilterParam = { filters: {} };
        setfilterParam.filters[setTo.name] = setTo.id
        // Set the filter server side and update the state with the result
        ApiJsonPost('UserSession','SetFilter',setfilterParam)
        .then((data) =>
            dispatch({
                type: SET_FILTER_RESULT,
                payload: data.result,
                filterId: data.filterId,
                exportCapacity: data.exportCapacity,
                users: data.users
            })
        )
    }
}
