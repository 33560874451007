import React, { Component } from 'react';
import  {ApiPost,ApiGet}  from '../../../api/index'

class ReportDefault extends Component {  
    
    constructor(props) {
        super(props)
        this.state = {
            reports: []
        }
    }

    componentDidMount() {
        this.loadReports();    
    }

    loadReports() {
        let me = this;
        ApiGet('Reports','')
            .then((data) => {
                console.log("got reports")
                this.setState( { reports: data })
            });
    }

    render() {
        var { reports } = this.state; 
        console.log("reports", reports)
        var iconstyle = {
                fontSize: '24px',
                marginRight: '8px',
                float: 'left'
        }
        return (
            <div className="container" style={{marginTop:'40px'}}>
                <div className="row">
                    <div className="col-12" style={{textAlign:'center'}}>
                        <h1>Reports</h1>
                        <p>  
                        </p>
                        <table className="table summary">
                            <thead className="thead-inverse">
                                <tr>
                                <th width="35%">Report</th>
                                <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reports.map(
                                    (rep,i) => (
                                        <tr key={rep.reportTemplateId}>
                                            <td key={i + '__' + rep.reportName }> 
                                                {(rep.reportIcon)?<i className={rep.reportIcon} style={iconstyle} />:''}
                                                {rep.reportName}
                                            </td>
                                            <td key={i + '_' + rep.reportDescription}>{rep.reportDescription}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
export default ReportDefault

