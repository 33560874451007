import React, { Component } from 'react';
import OverlayLoader from './../../../components/OverlayLoader'
import Highcharts from 'highcharts';
import addTreemap from 'highcharts/modules/treemap'
import addExporting from 'highcharts/modules/exporting'
import addHeatmap from 'highcharts/modules/heatmap'
addTreemap(Highcharts)
addExporting(Highcharts)
addHeatmap(Highcharts)

class ChartWidget extends Component {

    constructor(props) {
        super(props);

        const cacheResult = null;
        this.state = { renderComplete: false }
    }

    getValue()
    {
        var result = this.props.widgetValue;

        if (JSON.stringify(result) == this.cacheResult)
        {
            return null
        }

        if (result != undefined)
        {
            this.cacheResult = JSON.stringify(result)
            this.setState({renderComplete: true})

            return result
        }
        else
        {
            return null
        }
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps.widgetValue || (prevProps.widgetValue && JSON.stringify(this.props.widgetValue.series) != JSON.stringify(prevProps.widgetValue.series))) {
            var updateValue = this.getValue();
            if (updateValue != null) {
                if (this.chart == null) {
                    var t0 = performance.now()
                    this.chart = new Highcharts["Chart"]
                        (
                            this.refs.chart,
                            updateValue
                    );
                    var t1 = performance.now()
                    //console.log(this.props.id + ' new took: ', (t1 - t0))
                }
                else if (this.chart != null) {
                    if (updateValue.series.length != this.chart.series.length) {
                        console.log('destroying output chart');
                        this.chart.destroy();
                        var t0 = performance.now()
                        this.chart = new Highcharts["Chart"]
                            (
                                this.refs.chart,
                                updateValue
                        );
                        var t1 = performance.now()
                        console.log(this.props.id + ' rebuild took: ', (t1 - t0))
                    }
                    else {
                        var t0 = performance.now()
                        this.chart.update(updateValue)
                        var t1 = performance.now()
                        //console.log(this.props.id + ' update took: ',(t1-t0))
                    }
                }

                if (this.chart != null && this.props.periods != null) {
                    var periodTitle = this.props.periods.find(x => x.id == this.props.period)
                    if (periodTitle && this.chart.title.textStr != periodTitle.title) {
                        this.chart.setTitle({ text: periodTitle.title }, { text: periodTitle.subtitle });
                    }
                }

                if (this.props.widgetValue.title.customTitle && this.props.widgetValue.title.customTitle != '') {
                    this.chart.setTitle({ text: this.props.widgetValue.title.customTitle })
                }
            }

            if (this.chart) {
                this.chart.reflow();
            }
        }
    }

	componentDidMount()
    {
        var updateValue = this.getValue();

        if (updateValue != null)
        {
            this.chart = new Highcharts["Chart"](
                this.refs.chart,
                updateValue
            )

            if (this.props.periods != null)
            {
                var periodTitle = this.props.periods.find(x => x.id == this.props.period)
                this.chart.setTitle({ text: periodTitle.title }, {text : periodTitle.subtitle});
            }

            if (this.props.widgetValue.title.customTitle && this.props.widgetValue.title.customTitle != '') {
                console.log('mount' + this.props.widgetValue.title.customTitle)
                this.chart.setTitle({ text: this.props.widgetValue.title.customTitle })
            }
        }  
	}

    componentWillUnmount()
    {
        if (this.chart != null)
        {
            this.chart.destroy();
        }
    }

    getPeriodDots() {
        if (this.props.periods != null) {
            const dots = [];
            for (var i = 0; i < this.props.periods.length; i++) {
                if (this.props.periods[i].id == this.props.period) {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 1 }} key={i}></i>);
                }
                else {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 0.4 }} key={i}></i>);
                }
            }
            return dots;
        }
        else {
            return null;
        }
    }

    getPeriodExists() {
        if (this.props.periods != null) {
            return { cursor: 'pointer', width: '100%' };
        }
        else {
            return { width: '100%' };
        }
    }

    render() {
        return (
            <div id="resizer" style={this.getPeriodExists()}>
                {!this.state.renderComplete && <OverlayLoader label='Rendering...' />}
                <div className="chartDots">{this.getPeriodDots()}</div>
                <div ref="chart" className="default-chart-widget" />
            </div>)
    }
};

export default ChartWidget;