import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { resetFilter } from '../../actions/header';
import Measure  from 'react-measure';

const K_WIDTH = 20;
const K_HEIGHT = 20;

const stationStyle =
{
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    borderRadius: K_HEIGHT,
    textAlign: 'center',
    color: '#FFF',
    fontSize: 12,
    fontWeight: 'bold',
    padding: 4
    };
/* backgroundColor: '#249117', */
const outputStyle =
    {
        position: 'absolute',
        width: K_WIDTH * 2,
        height: K_HEIGHT / 1.9,
        left: -K_WIDTH / 2,
        top: K_HEIGHT * 1,
        backgroundColor: '#249117',
        textAlign: 'center',
        color: '#FFF',
        fontSize: 10,
        fontWeight: 'bold',
        padding: -1
    };
    /*        backgroundColor: '#249117',*/

const siteNameStyle =
    {
        position: 'absolute',
        width: K_WIDTH * 4,
        height: K_HEIGHT / 1.5,
        textAlign: 'center',
        top: K_HEIGHT * 1.5,
        left: -K_HEIGHT * 1.5
    };

export class station extends Component {
    static propTypes =
    {
        windSpeed: PropTypes.string,
        siteName: PropTypes.string,
        siteOutput: PropTypes.string,
        id: PropTypes.number
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
    
        this.state = {
            footeroffset: -1
        };
    }

    SelectItem(id) {
        console.log("Select filter asset",id);
        this.props.setAndClearFilter("asset",id);
    }

    render() {
        const { multi, siteOutput, siteName, windSpeed, style, id, alertsx, stalex, modelId } = this.props;
        var ws = windSpeed?(
            <div style={stationStyle} className="windspeed">
                {(windSpeed > 0)?windSpeed:''}
            </div>
        ):'';
        
        var styles = calcAngles(siteOutput,250)
        var donutClass = 'donut-chart';
        if (styles[2]) {
            donutClass += ' flip';
        }
        if (id == 75) {
            //console.log("alert", alertsx);
        }
        if (stalex) {
            donutClass += ' stalepoint';
        }
        if (alertsx && alertsx.length > 0) {
            if (alertsx[0].alertTypeId > 50) {
                donutClass += ' schedulepoint';
            }
            else {
                donutClass += ' alertpoint';
            }
        }
        if (modelId == 37) {
            return (
              <div className="stationWrap"    data-toggle="tooltip" data-placement="right"  onClick={() => this.SelectItem(id)} title={siteName}>
                    <i className='fas fa-wind-turbine' style={{ fontSize: '45px', color:'#757575'}} />
              </div>
            )
        }
        else
        {
            if (siteOutput >= 1) {
                donutClass += " producing"
            }
            return (
                <div className="stationWrap" data-toggle="tooltip" data-placement="right" onClick={() => this.SelectItem(id)} title={siteName}>
                    <div className={donutClass} style={style}>
                        <div className="slice one" style={styles[0]}></div>
                        <div className="slice two" style={styles[1]}></div>
                        <div className="chart-center">
                            {windSpeed > 0 ? <span>{windSpeed}</span> : null}
                        </div>
                    </div>
                    {!multi ?
                        <Measure bounds onResize={(contentRect) => {
                            if (contentRect.bounds) {
                                let offsetw = 0;
                                if (contentRect.bounds.width > 30) {
                                    offsetw = ((contentRect.bounds.width - 30) / 2) * -1
                                }
                                this.setState({ footeroffset: offsetw })
                            }
                        }}>
                            {({ measureRef }) =>
                                <div ref={measureRef} style={{ textAlign: 'center', left: this.state.footeroffset, position: 'absolute', lineHeight: '12px' }}>
                                    <div className="point-footer">{formatPower(siteOutput, null, null)}</div>
                                    <div className="point-footer site-name">{siteName}</div>
                                </div>
                            }
                        </Measure> : null}
                </div>
            )
        }
    }
}

const calcAngles = function(curVal, maxVal) {
    var perc = (curVal / maxVal) * 100;
    var deg = (perc/100*360);
    
    var deg1  = 90;
    var deg2 = deg;
    if (deg < 180) {
        deg1 = deg + 90;
        deg2 = 0;
    }
    var styleSlice1 = {
        transform: 'rotate(' + deg1 + 'deg)'
    }
    var styleSlice2 = {
        transform: 'rotate(' + deg2 + 'deg)'
    }
    var flip = perc < 50;
    return [styleSlice1, styleSlice2, flip]
}

export const formatPower = function(power, style, classn) {
    var ret = power;
    var unit = "Kw";
    if (power >= 1000) {
        unit = "Mw";
        ret = (power / 1000).toFixed(2);
    }
    return (<span style={style} className={classn}>{ret}<span className="small">{unit}</span></span>)
}

const mapStateToProps = (state) => state.common.header

const mapDispatchToProps = (dispatch) => {
    return {
        setAndClearFilter: (name, id) => {
            dispatch(resetFilter({ name, id }));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(station)