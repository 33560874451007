import React from 'react';
import helpers from '../../../utils/helpers'
import moment from 'moment'

const NotificationItem = (d) => {

        return (
            <div className='notifItem' style={{ backgroundColor: d.read && '#f5f5f5' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}><b style={{ fontSize: '.9rem' }}>System</b><b style={{ fontSize: '.9rem' }}>{helpers.parseDuration(d.createdAt, moment())} ago</b></div>
                <span>{d.title}</span>
                {d.link && <u onClick={(e) => { e.preventDefault(); this.linkNav(d.link); }}>View Report</u>}
            </div>
        );
}

export default NotificationItem;