import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Measure  from 'react-measure';
import { resetFilter } from '../../actions/header';
import { universalMetrics,industryDefaultMetrics } from '../../configuration/enums'

const K_WIDTH = 20;
const K_HEIGHT = 20;

const stationStyle =
{
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    borderRadius: K_HEIGHT,
    textAlign: 'center',
    color: '#FFF',
    fontSize: 12,
    fontWeight: 'bold',
    padding: 4
    };
/* backgroundColor: '#249117', */
const outputStyle =
    {
        position: 'absolute',
        width: K_WIDTH * 2,
        height: K_HEIGHT / 1.9,
        left: -K_WIDTH / 2,
        top: K_HEIGHT * 1,
        backgroundColor: '#249117',
        textAlign: 'center',
        color: '#FFF',
        fontSize: 10,
        fontWeight: 'bold',
        padding: -1
    };
    /*        backgroundColor: '#249117',*/

const siteNameStyle =
    {
        position: 'absolute',
        width: K_WIDTH * 4,
        height: K_HEIGHT / 1.5,
        textAlign: 'center',
        top: K_HEIGHT * 1.5,
        left: -K_HEIGHT * 1.5
    };

export class MapStationSquare extends Component {
    static propTypes =
    {
        siteName: PropTypes.string,
        siteOutput: PropTypes.string,
        id: PropTypes.number,
        industryId: PropTypes.number
    };

    constructor(props) {
        super(props);
    
        this.state = {
            footeroffset: -1
        };
    }

    static defaultProps = {};

    SelectItem(id) {
        console.log("Select filter asset",id);
        this.props.setAndClearFilter("asset",id);
    }

    getWarnLevel(levels, value) {
        if (!levels) return "";
        var ret = ""; var offset = 0;
        if (levels.length == 4) {
            offset = 2;
            if (levels[1] != null && value < levels[1]) {
                ret = " warn";
            }
            if (levels[0] != null && value < levels[0]) {
                ret = " error";
            }
        }
        if (levels.length == 2 || levels.length == 4) {
            if (levels[offset] != null && value >= levels[offset]) {
                ret = " warn";
            }
            if (levels[offset +1] != null && value >= levels[offset + 1]) {
                ret = " error";
            }
        }
        return ret;
    }

    getPowerTop(Indu) {
        if (Indu == 7) {
            return '60px';
        } else {
            return '40px';
        }
    }

    render() {
        const { multi, siteOutput, siteName, asset, style, id, alertsx, stalex,industryId } = this.props;
        var donutClass = 'stationSq';
        if (stalex) {
            donutClass += ' alertpoint';
        }
        if (alertsx && alertsx.length > 0) {
            donutClass += ' alertpoint';
        }
        var pwPercent = '0%';
        if (asset.maxkw) {
            pwPercent = ((siteOutput / asset.maxkw)*100) + "%";
        }
        
        if (siteOutput >= 1) {
            donutClass += " producing"
        }
        return (
            <div className="stationWrap" style={style}  data-toggle="tooltip" data-placement="right"  onClick={() => this.SelectItem(id)} title={siteName}>
                <div className={donutClass} >
                    <div className="power-bar">
                        <div style={{width: pwPercent}}></div>
                    </div>
                    {industryDefaultMetrics.views[industryId].metrics.map((k, i) => {
                        if (asset && asset.metrics) {
                            var metinfo = industryDefaultMetrics.renderDetails[k];
                            const v = asset.metrics[k];
                            var vdis = "";
                            if (v != undefined && metinfo != undefined) {
                                vdis = (metinfo.decimalPlaces != undefined) ? v.toFixed(metinfo.decimalPlaces) : v.toFixed(0);
                            }
                            if (metinfo && metinfo.mini && v != undefined) {
                                const levels = metinfo.warnLevels;
                                var stateClass = "";
                                if (levels != undefined) {
                                    stateClass = this.getWarnLevel(levels, v);
                                    if (!stateClass) stateClass = "success";
                                }
                                stateClass = "line " + stateClass;
                                return (<div className={stateClass} key={k}>
                                    <span className="small">{metinfo.mini}:</span>
                                    <span className="">{vdis}</span>
                                </div>)
                            } else {
                                return null;
                            }
                        }
                        })
                    }
                </div>
                {!multi?
                <Measure bounds onResize={(contentRect) => {
                            if(contentRect.bounds)  { 
                                let offsetw = 0;
                                if (contentRect.bounds.width> 40) {
                                    offsetw = ((contentRect.bounds.width - 40) /2) *-1
                                }
                                this.setState({ footeroffset: offsetw }) 
                            }
                        }}>
                    {({ measureRef }) => 
                        <div ref={measureRef} style={{textAlign:'center',left:this.state.footeroffset,position:'absolute'}}>
                            <div className="point-footer">{formatPower(siteOutput,null ,null )}</div>
                            <div className="point-footer site-name">{siteName}</div>
                        </div>
                    }
                </Measure>:null}
            </div>
        );
    }
}

export const formatPower = function(power, style, classn) {
    var ret = power;
    var unit = "Kw";
    if (power >= 1000) {
        unit = "Mw";
        ret = (power / 1000).toFixed(2);
    }
    return (<span style={style} className={classn}>{ret}<span className="small">{unit}</span></span>)
}

const mapStateToProps = (state) => state.common.header

const mapDispatchToProps = (dispatch) => {
    return {
        setAndClearFilter: (name, id) => {
            dispatch(resetFilter({ name, id }));
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapStationSquare)