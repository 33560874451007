import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { ApiPost } from '../api';
import moment from 'moment';

export default class HealthIndicator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalOpen : false, deferLength: 3
        }
    }

    acknowledge(save) {
        this.setState({ modalOpen: !this.state.modalOpen });
        if (save) {
            //console.log('defering ', this.props.healthVal.id, ' for ' + this.state.deferLength);
            const warnInfo = { warnId: this.props.healthVal.id, deferLength: this.state.deferLength }
            var actionType = 'AckWarning';
            if (this.props.healthVal.acknowledged) {
                actionType = 'ActionWarning';  
            }
            ApiPost('Dashboard', actionType, warnInfo)
            .then((data) => {
                if (data) {
                    console.log(actionType, data);
                }
            })
        }
    }

    getHealthIndicator(val) {
        if (val.level == 0) {
            return <i className={"fa fa-circle dashlight green"} />;
        }
        else if (val.level == 1) {
            if (val.actioned) {
                return ([<i key={val.id + val.status} data-tip={val.warningMsg} className={"fa fa-circle dashlight blue"} onClick={() => this.acknowledge(false)} />, <ReactTooltip key={val.id + val.status + 'tt'} effect="solid" />]);
            }
            else {
                return ([<i key={val.id + val.status} data-tip={val.warningMsg} className={"fa fa-circle dashlight grey"} onClick={() => this.acknowledge(false)} />, <ReactTooltip key={val.id + val.status + 'tt'} effect="solid" />]);
            }
        }
        else if (val.level == 2) {
            return ([<i key={val.id + val.status} data-tip={val.warningMsg} className={"fa fa-circle dashlight amber"} onClick={() => this.acknowledge(false)} />, <ReactTooltip key={val.id + val.status + 'tt'} effect="solid" />]);
        }
        else {
            return ([<i key={val.id + val.status} data-tip={val.warningMsg} className={"fa fa-circle dashlight red"} onClick={() => this.acknowledge(false)} />, <ReactTooltip key={val.id + val.status + 'tt'} effect="solid" />]);
        }
    }

    onChange(e) {
        this.setState({ deferLength: e.target.value });
    }

    render() {

        const hVal = this.props.healthVal;
        return (
            <div>
                {this.getHealthIndicator(hVal)}
                <Modal isOpen={this.state.modalOpen} autoFocus={false} >
                    <ModalHeader>
                        {hVal.acknowledged ? 'Action' : 'Acknowledge'} {this.props.detail.shortName} Warning
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Warning Msg:</label>
                            <label className="col-sm-8 col-form-label">{hVal.warningMsg}</label>
                            {hVal.acknowledged ? [<label className="col-sm-4 col-form-label">Acknowledged Until:</label>, <label className="col-sm-8 col-form-label">{moment(hVal.acknowledged).format('DD/MM/YY')}</label>] : null}
                            {hVal.actioned ? [<label className="col-sm-4 col-form-label">Actioned Until:</label>, <label className="col-sm-8 col-form-label">{moment(hVal.actioned).format('DD/MM/YY')}</label>] : null}
                        </div>
                        {!hVal.actioned ?
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{hVal.acknowledged ? 'Pending Length' : 'Defer Length'}</label>
                                <div className="col-sm-8">
                                    <select className="form-control" onChange={e => this.onChange(e)}>
                                        <option value="3">3 days</option>
                                        <option value="7">7 days</option>
                                        <option value="2">2 weeks</option>
                                        <option value="30">1 month</option>
                                    </select>
                                </div>
                            </div> : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => this.acknowledge(false)}>Cancel</Button>
                        <Button color="primary" onClick={() => this.acknowledge(hVal.actioned ? false : true)}>OK</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}