import React, { Component } from 'react'
import { connect } from 'react-redux'
import { WithContext as ReactTags } from 'react-tag-input'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { ApiGet } from '../../../api'
import helpers from '../../../utils/helpers'

class DeviceMeteringSideController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treeChecked: [], treeExpanded: [], tags: [], listData: null, suggestions: [], nodes: []
        }
    }

    componentDidMount() {
        this.getFilterVals()
    }

    handleTagDelete(i) {
        const { tags } = this.state
        this.setState({ tags: tags.filter((tag, index) => index !== i) }, () => this.updateData())
    }

    handleTagAddition(tag) {
        if (this.state.suggestions.includes(tag)) {
            var tTags = [...this.state.tags, tag]
            this.setState({ tags: tTags }, () => this.updateData())
        }
    }

    getFilterVals() {
        ApiGet('Dashboard', 'GetWidgetListVals', { widgetId: 9999, getParams: true })
        .then((data) => {
            var devices = helpers.wrangleTreeData(data['devices'])
            var tc = []
            data.devices.map(d => {
                tc.push(d.id + '')
            })
            console.log('data',data)
            this.setState({ suggestions: data['filter'], nodes: devices, treeChecked: tc }, () => this.updateData())
        })
    }

    updateData() {
        this.props.handleData({ ...this.state })
    }

	render() {

        const KeyCodes = { comma: 188, enter: 13, }
        const delimiters = [KeyCodes.comma, KeyCodes.enter]
        const icons = { parentClose: null, parentOpen: null, leaf: null }

        return (
            <div style={{ height: '100%', width: '20%', display: 'inline-block'}}>
                <div className='widgetMgmtBox' style={{ backgroundColor: '#FFF' }} >
                    <ReactTags tags={this.state.tags} suggestions={this.state.suggestions} placeholder='Search Tags' allowDragDrop={false} inputFieldPosition='top' handleDelete={(i) => this.handleTagDelete(i)} handleAddition={(tag) => this.handleTagAddition(tag)} delimiters={delimiters} />
                </div>
                <div className='widgetMgmtBox' style={{ marginTop: '10px', backgroundColor: '#FFF' }}>
                    <CheckboxTree nodes={this.state.nodes} icons={icons} checked={this.state.treeChecked} expanded={this.state.treeExpanded} onCheck={treeChecked => this.setState({ treeChecked }, () => this.updateData())} onExpand={treeExpanded => this.setState({ treeExpanded })} />
                </div>
            </div>
        );
    }

};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(mapStateToProps)(DeviceMeteringSideController)
