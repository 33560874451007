import React, { Component } from 'react'
import DonutChart from '../../../components/MiniCharts/DonutChart'
import LiquidLevel from '../../../components/MiniCharts/LiquidLevel'
import ValueRender from '../../../components/MiniCharts/ValueRender'

class LiveSummaryWidget extends Component {

    getVal() {
        var deviceId = this.props.selectedParam.params.devices
        var uMId = this.props.selectedParam.params.valueToChart || this.props.selectedParam.params.deviceMetrics
        var deviceInfo = this.props.widgetValues.items.find(x => x.id == deviceId)
        if (deviceInfo && deviceInfo.metrics) {
            var val = deviceInfo.metrics[uMId]
            var renderer = null
            if (deviceInfo && this.props.selectedParam.deviceConfig) {
                renderer = this.getRenderInfo(deviceInfo.deviceTypeId, uMId, val, deviceInfo.name)
            }
        }

        return renderer
    }

    getStale()
    {
        var deviceId = this.props.selectedParam.params.devices
        var deviceInfo = this.props.widgetValues.items.find(x => x.id == deviceId)
        console.log('deviceInfo', deviceInfo)
        return deviceInfo && deviceInfo.stale
    }

    getRenderInfo(typeId, uMId, val, deviceName) {
        var config = this.props.selectedParam.deviceConfig[typeId]
        var mTConfig = config.groupRenderDetails[uMId]
        if (mTConfig) {
            var value = Math.round(val, mTConfig.decimalPlaces)
            var per = (value / mTConfig.max) * 100
            if (mTConfig.renderer == 'donut') {
                return <div><DonutChart value={value} valuePercent={per} units={mTConfig.unit} size={100} /><span style={{ fontSize: '14px' }}>{deviceName}</span></div>
            }
            else if (mTConfig.renderer == 'liquidLevel') {
                return <div><LiquidLevel value={value} valuePercent={per} units={mTConfig.unit} size={100} /><span style={{ fontSize: '14px' }}>{deviceName}</span></div>
            }
            else if (mTConfig.renderer == 'value') {
                return <div><ValueRender value={value} units={mTConfig.unit} /><span style={{ fontSize: '14px' }}>{deviceName}</span></div>
            }
        }
    }

    render() {
        return (
            <div className={"summary-widget widget"}>
                <div className="widget-info" style={{ width: '100%' }}>
                    {this.props.widgetValues && this.props.widgetValues.items && this.getVal()}
                </div>
            </div>
        );
    }
}

export default LiveSummaryWidget