import { ActionTypes } from './actions'

const getState = (state, btn) => {
    return {
        ...state,
        message: '',
        isOpen: false,
        button: btn
      }
}

const alertbox = (state = { isOpen: false, mode:'ok', button:0 }, action) => {
  
    switch (action.type) {
    case ActionTypes.OPEN_ALERT:
        var ab = (window.alertbox = window.alertbox? window.alertbox: {});
        ab.onYes = action.payload.onYes;
        ab.onNo = action.payload.onNo;
        ab.onCancel = action.payload.onCancel;
      return {
        ...state,
        message: action.payload.msg,
        mode: action.payload.mode,
        isOpen: true,
        button: 0
      }
    case ActionTypes.YES_CLICK:
      return getState(state,1)
    case ActionTypes.NO_CLICK:
      return getState(state,2)
    case ActionTypes.CANCEL_CLICK:
      return getState(state,3)
    case ActionTypes.FUNC_EXEC:
      return getState(state,0)
    default: 
      return state
  }
}

export default alertbox