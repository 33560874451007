import React, { Component } from 'react'
import IdNameDropdown from '../Controls/IdNameDropdown'


export default class ComboBox extends Component {

    constructor(props) {
        super(props);
    }  

    click(event) {
        if (Number(event) || event == '0')
            this.props.onClick(event)
        else if (event.currentTarget.id == 'previous')
            this.props.onClick(Number(this.props.value) - 1)
        else if (event.currentTarget.id == 'next')
            this.props.onClick(Number(this.props.value) + 1)        
    }

    render() {

        const { options, value } = this.props;        
        
        return (
            <div className="row" style={{margin: 0}}>
                <div>
                    <button id="previous" className="btn" onClick={(event) => this.click(event)}>
                        <i className="fas fa-caret-left" style={{ fontSize: '15px' }}></i>
                    </button>
                </div>
                <IdNameDropdown value={value} style={{ width: '130px', borderRadius:'0px', textAlign:'center' }} options={options} valueField="id" displayField="name" onChange={(event) => this.click(event)} />  
                <div>
                    <button id="next" className="btn" onClick={(event) => this.click(event)}>
                        <i className="fas fa-caret-right" style={{ fontSize: '15px' }}></i>
                    </button>
                </div>
            </div>
            )
    }
}