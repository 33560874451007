import React, { Component } from 'react'
import { Col, Row, Input } from 'reactstrap'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'

export default class ConditionSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conditions: [], ops: [{ id: '>', name: '>' }, { id: '<', name: '<' }, { id: '=', name: '=' }]
        }
    }

    componentDidMount() {
        this.populateConditions()
    }

    populateConditions() {
        if (this.props.rec && this.props.rec.data && this.props.rec.data.conditions) {
            var c = JSON.parse(this.props.rec.data.conditions)
            var tCond = this.state.conditions
            c.map(x => {
                tCond.push(x)
            })
            this.setState({ conditions: tCond })
        }
    }

    addRow() {
        var tCond = this.state.conditions
        var nCond = { id: (1 + tCond.length), metric: 0, op: '', val: '' }
        tCond.push(nCond)
        this.setState({conditions: tCond})
    }

    valChange(val, type, id) {

        var tCond = this.state.conditions
        var ix = tCond.findIndex(x => x.id == id)
        tCond[ix][type] = val

        var t = JSON.stringify(tCond)

        this.props.rec.onChange(t)
    }

    render() {
        return (
            <div>
                <Col sm={6} style={{ padding: 0 }}>
                    <Row style={{ lineHeight: '33.5px' }}>
                        <Col sm={5}>Metric</Col>
                        <Col sm={3}>Operator</Col>
                        <Col sm={3}>Value</Col>
                        <Col sm={1} style={{padding:0}}><button type="button" className='btn btn-primary' onClick={() => this.addRow()} ><i className='fal fa-plus' /></button></Col>
                    </Row>
                    {
                        this.state.conditions && this.state.conditions.map(x => {
                            return (
                            <Row key={'r'+x.id} style={{marginBottom:'5px'}}>
                                <Col style={{paddingRight:'5px'}} sm={5}><IdNameDropdown defaultValue={x.metric} options={this.props.list} zeroText='Select' valueField="id" displayField="name" onChange={(e)=>this.valChange(e,'metric',x.id)}  /></Col>
                                <Col style={{paddingRight:'5px'}} sm={3}><IdNameDropdown defaultValue={x.op} options={this.state.ops} zeroText='Select' valueField="id" displayField="name" onChange={(e)=>this.valChange(e,'op',x.id)} /></Col>
                                <Col style={{paddingRight:'5px'}} sm={3}><Input defaultValue={x.val} onChange={(e)=>this.valChange(e.target.value,'val',x.id)} /></Col>
                            </Row>)
                        })
                    }
                </Col>
            </div>
        );
    }
}