import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
export const API_PATH = process.env.SERVER_PATH + '/api'

class AssetCard extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        var AssetCardFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Asset", fieldId: "name" } 
        ];

        var AssetCardConfig = {
            model: 'AssetCard',
            fields: AssetCardFields,
            master: true,
            apiPath: API_PATH
        }

        return ([
            <h1 key='h1wc'>Asset Card</h1>,
            <MasterDetail key='mdwc' {...AssetCardConfig} />]);

    }
}

export default (AssetCard)