import React from 'react';

function getIcon(props) {

    if (props.pending) {
        return <i className="fa fa-spinner fa-spin" />
    }
    else if (props.successful) {
        return <i className="fa fa-check" />
    }
    else if (props.fault) {
        return <i className="fal fa-exclamation-triangle" />
    }
    else {
        return <i className={props.custIcon ? props.custIcon : 'fal fa-save'} />
    }

}

const ConfirmButton = (props) => {
    var cName = 'btn btn-primary'
    if (props.compact) {
        cName = 'btn btn-default btn-option'
        if (props.fault) {
            cName += ' fault'
        }
    }
    if (props.successful) {
        cName += ' successful'
    }
    
    return (
        <button type="button" style={props.style} className={cName} onClick={props.clickAction} >{getIcon(props)} {props.text != null ? props.text : 'Save'}</button>
    );
}

export default ConfirmButton;