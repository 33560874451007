import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import { ApiPost } from '../../../api'
export const API_PATH = process.env.SERVER_PATH + '/api'

class FormLookupMgmt extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {

        var TableFields = [
            { label: "Id", fieldId: "id", display:'none' },
            { label: "Lookup Name", fieldId: "name" },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' },
        ];

        var TableConfig = {
            model: 'FormLookup',
            fields: TableFields,
            master: true,
            titleField: 'name',
            gridProps: { defaultPageSize: 25 },
            apiPath: API_PATH
        }

        var LookupFFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Value", fieldId: "name", inlineEdit: true },
            { label: 'FormLookupTableId', fieldId: 'FormLookupTableId', display: 'none', getId: -1},
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var FieldsConfig = {
            model: 'FormLookupFields',
            path: '/FormLookup/Fields',
            fields: LookupFFields,
            titleField: 'name',
            addInline: true,
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Lookup Configuration</div>
                <MasterDetail key='tblMD' {...TableConfig} >
                    {this.props.match.params.id != 'New' && <div style={{ marginTop: '20px' }}>
                        <MasterDetail key='fieldMD' {...FieldsConfig} />
                    </div>}
                </MasterDetail>
            </div >
        )
    }
}

export default (FormLookupMgmt)