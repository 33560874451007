import React from 'react'

import { connect } from 'react-redux';
import { updateLiveConnection, toggleLiveConnection, disconnectLive, scadaSendCommand, SCADA_COMMANDS  } from '../../actions/scada';


export default class CommandButton extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = { progressClicked: false, progressComplete: false }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.cmdSucess && this.props.cmdSucess && this.state.progressClicked) {
            this.setState({ progressClicked: false, progressComplete: true })
            setTimeout(() => this.setState({ progressComplete: false }), 2000)
        }
    }

    clickProgress()
    {
        if (!this.props.disabled) {
            const { submitController = "", submit = "",submitWFData, updateData, data, getData } = this.props;
            this.setState({ progressClicked: true });

            setTimeout(function () { this.setState({ progressClicked: false }); }.bind(this), 5000);

            const { stationId } = this.props.initData;
            if (submitController && submit) {
                submitWFData(submitController, submit, ).then(
                    (d) => {
                        scadaSendCommand(stationId, data)
                        updateData(null,null, true, "Logged")
                    }
                )
            } else {
                scadaSendCommand(stationId, data)
                updateData(null,null, true, data + " Sent")
            }
        }
    }

    render() {
        const { title, initData, data = 0, className, onButtonClick = () => { }, disabled } = this.props;
        var cls = "btn"
        if (className) {
            cls += className
        } else {
            cls += " btn-default"
        }
        if (disabled) {
            cls = "btn btn-disabled"
        } else {
            cls = this.state.progressClicked ? "progress-btn btn active" : this.state.progressComplete ? "progress-btn btn complete" : "progress-btn btn"
        }
        return (
            <div style={{ width: '100px' }} className={cls} data-progress-style="fill-back" >
                <div className="progress"></div>
                <div className="" style={{ width: '100%', zIndex: 6, position: 'absolute', top: '5px',left: '0px' }} onClick={() => this.clickProgress()}>{title}</div>
            </div>
        )
    }
}
