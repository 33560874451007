import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { Hours24, Months } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class TariffSettings extends Component {

    constructor(props) {
        super(props)
        this.state = { sId: 1, isTemplate: (props.match.params.isTemplate && props.match.params.isTemplate == 1) || props.match.url.toLowerCase().includes('template') }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            if (this.props.match.params.id != 'New') {
                this.setState({ sId: Math.random() })
            }
        }
    }

    render() {

        var TariffFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Code", fieldId: "code", display: this.state.isTemplate ? 'both' : 'none' },
            { label: "Description", fieldId: "description" },
            { label: "DNO", fieldId: "dnoId", list: listRequest.DNOs },
            { label: "Tariff Code", fieldId: "tariffId", list: listRequest.Tariffs, display: this.state.isTemplate ? 'none' : 'form' },
            { label: "Day Standing Charge (\u00A3)", fieldId: "dayStandingCharge"},
            { label: "Month Standing Charge (\u00A3)", fieldId: "monthStandingCharge" },
            { label: "Valid From", fieldId: "validFrom", type: 'date' },
            { label: "Valid To", fieldId: "validTo", type: 'date' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton'},
        ]

        var tariffConfig = {
            model: 'TariffSettings',
            fields: TariffFields,
            master: true,
            hideDelete: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            defaultSorted: [{ id: 'name', desc: false }],
            crudParams: { isTemplate: this.state.isTemplate },
            apiPath: API_PATH,
            submitAction: (r) => r && r.tariffId ? this.setState({ sId: Math.random() }, () => this.props.history.push('/TariffSettings/' + r.tariffId)) : this.props.history.push('/TariffSettings')
        }

        var lists = {
            type: [{ id: 1, name: 'All Week' }, { id: 2, name: 'Weekdays Only' }, { id: 3, name: 'Weekend Only' }],
        }

        var TariffRateFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "", fieldId: "tariffId", width: 70, display: 'none', getId: -1 },
            { label: "Active Hour From", fieldId: "startTime", list: Hours24, inlineEdit: true },
            { label: "Active Hour To", fieldId: "endTime", list: Hours24, inlineEdit: true },
            { label: "Day Type", fieldId: "activeDayType", list: lists.type, inlineEdit: true },
            { label: "Active Month From (inclusive)", fieldId: "activeMonthFrom", list: Months, inlineEdit: true },
            { label: "Active Month To (inclusive)", fieldId: "activeMonthTo", list: Months, inlineEdit: true },
            { label: "Unit Charge (p / kWh)", fieldId: "unitCharge", inlineEdit: true },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
        ]

        var tariffRateConfig = {
            model: 'TariffRate',
            fields: TariffRateFields,
            master: false,
            hideDelete: true,
            showFilterRow: false,
            addInline: true,
            gridProps: { defaultPageSize: 10 },
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>{this.state.isTemplate && 'Template '}Tariff Setup</div>
                <MasterDetail {...tariffConfig} key={this.state.sId}>
                    {this.props.match.params.id != 'New' && <div>
                        <Nav tabs className="nav-docked">
                            <NavItem>
                                <NavLink className='active'>Rates</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent key='clientTabs' activeTab='1'>
                            <TabPane tabId='1'>
                                <MasterDetail {...tariffRateConfig} />
                            </TabPane>
                        </TabContent>
                    </div>}
                </MasterDetail>
            </div >
            )
    }
}

export default (TariffSettings)