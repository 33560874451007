import React, { Component } from 'react';
import WorkflowComponent from './WorkflowComponent'
import WfCheckItem from './WfCheckItem'
import { Collapse, CardBlock, Card, CardTitle, Col, Form, FormGroup, Label, Input, FormText, Button, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FavMetrics from '../../scenes/Scada/components/FavMetrics';
import IdNameDropdown from '../Controls/IdNameDropdown';
import { getConfigItem } from '../../api/index';
import { object } from 'prop-types';

/*
Workflow Component

Use updateState instead of setState. This allows the data to be updated in the parent Workflow

*/
export default class WizardValidation extends WorkflowComponent {

    constructor(props) {
        super(props);
        this.onCheck = this.onCheck.bind(this);
    }

    componentWillMount() {
        this.fieldEngineers = getConfigItem('$.operations.fieldEngineers');
        this.scadaResetCheckList = getConfigItem('$.monitoring.scadaResetCheckList');

        //this.required = ['genTemp', 'gearTemp','bearingTemp'];
        //this.requiredMsg = ['genTemp required', 'gearTemp Required','bearingTemp Required'];
        
        this.initState(
            {
                metricsValues: {},
                engineer: null
            }
        )
        this.customValidate = () => {
            var questions = this.getQuestions()
            const { metricsValues } = this.state.data
            var ret = "";
            if (questions && metricsValues != undefined) {
                Object.keys(questions).map((k, i) => {
                        if (!metricsValues[k] && !questions[k]) {
                            ret = ret + (i>0?",":"") + questions[k] + " required" 
                        }
                    }
                )
            }
            console.log("question", questions);
            console.log("mv", metricsValues);
            return ret;
        }
        this.refreshbase();
        console.log("WizardValidation","mount")
    }

    onCheck(ev,type)
    {
        const checked = ev.target.checked;
        var now = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
        var metricsValues = {...this.state.data.metricsValues};
        const metLookup = this.props.initData.metricFuncs;
        if (metLookup) {
            metricsValues[type] = checked?metLookup.getScadaValue(type) + ' ' + metLookup.getMetricUnit(type, this.props.initData.stationId):'';
            this.updateState({
                metricsValues: metricsValues
            })
        }
    }

    onChange(ev, type) {
        this.updateState({
            engineer: ev.value
        })
    }

    renderCheckList(alertname) {
        var ret = [];
        var cnt = 0;
        this.scadaResetCheckList.map((d) => {

            var regex = new RegExp(d.regex);
            if (regex.test(alertname)) {
                cnt++;
                if (d.list) {
                    var listops = getConfigItem(d.list);
                    ret.append(
                    <FormGroup row>
                        <Col xs={3} lg={3}>{cnt==1?<Label ><b>Checklist:</b></Label>:null}</Col>
                        <Col xs={9} lg={9}><Input type="checkbox" /> &nbsp;&nbsp; 
                            <Label><b>Engineer Authorised</b></Label>
                            <IdNameDropdown options={listops}  onChange={(e) => this.onChange(e,'list')} zeroText='Select' valueField="id" displayField="n" value={0} />
                        </Col> 
                    </FormGroup>
                    );
                } else {
                    ret.append(
                        <FormGroup row>
                            <Col xs={3} lg={3}>{cnt==1?<Label ><b>Checklist:</b></Label>:null}</Col>
                            <Col xs={9} lg={9}><Input type="checkbox" onClick={(e) => this.onCheck(e,'gen')} /> &nbsp;&nbsp;<Label ><b>{d.title}</b></Label> &nbsp;&nbsp; <Label className="badge badge-pill badge-success" style={{ fontSize:"13px" }}>{this.state.data.genTemp}</Label> </Col>
                        </FormGroup>
                        );
                }
            }
            return ret;
        })
    }

    getQuestions() {
        const {initData} = this.props;
        var faultDef =  initData.alertData.resetQuestions;
        var questions = null;
        if (faultDef != undefined) {
            questions = faultDef.metricChecklist
        }
        return questions
    }

    render() {
        const {initData} = this.props;
        var questions = this.getQuestions()
        var mL = this.props.initData.scadaGroups[this.props.initData.stationId]
        return (
            <div style={{ width: '100%' }}>
                <Card>
                    <CardBlock style={{ backgroundColor: 'rgb(230, 230, 230)' }}>
                            <Row style={{margin: 0}}>
                                <Col xs={12}>
                                    <FavMetrics showTime={null} /* string (label), null = hide */ 
                                        scadaGroups={initData.scadaGroups}
                                        favourites={questions ? { [initData.stationId]: Object.keys(questions) } : initData.favourites} /* array of metricid */
                                        getMetricName={initData.metricFuncs.getMetricName} /* func */ 
                                        getScadaValue={initData.metricFuncs.getScadaValue} /* func */ 
                                        getMetricUnit={initData.metricFuncs.getMetricUnit} /* func */
                                        allowAdding={false}
                                    />
                                    {questions && <b style={{position:'absolute'}}>Checklist:</b>}
                                    {questions?
                                    Object.keys(questions).map((k, i) => {
                                        if (!questions[k] && mL) {
                                            return (<WfCheckItem key={k} id={k} name={mL.find(x => x != null && x.metricId == k) ? mL.find(x => x != null && x.metricId == k).name : questions[k] + " checked"} title={i == 0 ? "Checklist:" : ""} values={this.state.data.metricsValues} onCheck={this.onCheck} />)
                                        }
                                    }):null
                                    }
                                </Col>
                            </Row>
                    </CardBlock>
                </Card>
            </div>
        );
    }
}