export const ActionTypes = {
    OPEN_ALERT: 'ALERTBOX/OPEN_ALERT',
    YES_CLICK: 'ALERTBOX/YES_CLICK',
    NO_CLICK: 'ALERTBOX/NO_CLICK',
    CANCEL_CLICK: 'ALERTBOX/CANCEL_CLICK',
    FUNC_EXEC: 'ALERTBOX/FUNC_EXEC'
}

export function openAlert(msg,mode = 'ok',onYes = null,onNo = null,onCancel = null) {
    const payload = {msg, onYes, onNo, onCancel,mode}
    return {
        type: ActionTypes.OPEN_ALERT,
        payload
    }
}

export function AlertYesClick(msg,onYes,onNo,onCancel) {
    return {
        type: ActionTypes.YES_CLICK
    }
}

export function AlertNoClick(msg,onYes,onNo,onCancel) {
    return {
        type: ActionTypes.NO_CLICK
    }
}

export function AlertCancelClick(msg,onYes,onNo,onCancel) {
    return {
        type: ActionTypes.CANCEL_CLICK
    }
}

export function FunctionExecuted(msg,onYes,onNo,onCancel) {
    return {
        type: ActionTypes.FUNC_EXEC
    }
}


