import React, { Component } from 'react'
import { Col } from 'reactstrap'
import { GetStationsStateIndustry } from '../../../api'
import { industryDefaultMetrics } from '../../../configuration/enums'
import { connect } from 'react-redux'
import MonitoringList from '../../Monitoring/components/MonitoringList'

class TableMetricWidget extends Component {
    
    constructor(props) {
        super(props);
        this.renderGroupedSites = this.renderGroupedSites.bind(this)
    }

    renderGroupedSites(id, title, sites, scada) {
        var onlyIssues = this.props.selectedParam.params && this.props.selectedParam.params.displayType == 'issues' 
        var validSites = sites.filter(x => x.turbineSize > 0).length == 0 ? sites.filter(x => x.deviceTypeId == 3) : sites.filter(x => x.turbineSize > 0)
        return (
            <div className="row" key={id}>
                <Col sm="12">
                    <MonitoringList title={title} sites={validSites} industryId={id} onlyIssues={onlyIssues} />
                </Col>
            </div>
        )
    }

    getSitesArray() {   
        let ss = GetStationsStateC(this.props.commonstate);
        return (!ss)?[]:ss;
    }

    getSitesIndustryArray() {
        let ss = GetStationsStateIndustry(this.props.commonstate);
        return (!ss)?[]:ss;
    }

    render() {
        const { scada, params } = this.props
        const sites = this.getSitesIndustryArray()
        var vorder = [1,2,7,6,5];
        if (industryDefaultMetrics.viewOrder) {
            vorder = industryDefaultMetrics.viewOrder;
        }

        return (
            <div style={{padding:'10px', width:'100%',backgroundColor: '#ffffff7d',borderRadius: '10px'}} className="widget ">
                <div className="accordionCards">
                    {vorder.map((k,i) => { 
                            const industry = sites[k]
                            if (industry) {
                                return this.renderGroupedSites(k, industry.name, industry.items, scada)
                            }
                        }
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    // Dictionary of stationId { ...asset, siteMetric[umet], alerts[] }
    //const newstations = GetStationsState(state)
    //return {stations: newstations, scada: state.common.scada }
    //state.common.header
    return {scada: state.common.scada, commonstate:state.common }
}

export default connect(
    mapStateToProps
)(TableMetricWidget)
