import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums';
import moment from 'moment'
import { ApiPost } from '../../../api'
export const API_PATH = process.env.SERVER_PATH + '/api'

class Returns extends Component {

    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            fromDate: moment(date).add(-3, 'months').startOf('day'),
            toDate: moment(date.getTime()).endOf('day'),
        };
    }

    completeReturn(rec) {
        console.log('completePicklist', rec.id)
        ApiPost('Returns', 'Complete', { id: rec.id })
            .then((data) => {
                console.log('completeReturn', data)
                this.props.history.goBack()
            })
    }

    render() {

        var returnFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Return Status', fieldId: 'status', display: 'none' },
            { label: 'Date Returned', fieldId: 'orderDate', type: 'datetime', readOnly: true},
            { label: 'Note', fieldId: 'note' },
            { label: "", fieldId: "funcButton2", display: 'form', displayIf: (r) => r.status < 2, type: 'funcButton', typeProps: { label: <span>Complete Return</span>, style: { width: '100%', backgroundColor: '#0a92b1', color: '#FFF' }, func: (rec) => this.completeReturn(rec) } },
        ];

        var returnsConfig = {
            model: 'Returns',
            fields: returnFields,
            master: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        var returnLineFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "PickId", fieldId: "pickId", display: 'none', getId: -1 },
            { label: "Part", fieldId: "partId", list: listRequest.PartNameIdentifier, type: 'searchableList', width: 500, readOnly: true },
            { label: "Picked Qty", fieldId: "pickedQty",  width: 200 },
            { label: "Return Qty", fieldId: "qty", inlineEdit: true, width: 200 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, type: 'inlineSaveBtn' }
        ]

        var returnLinesConfig = {
            model: 'ReturnLines',
            fields: returnLineFields,
            showFilterRow: false,
            gridProps: { defaultPageSize: 10 },
            apiPath: API_PATH
        }

        return ([<div key='tt' className='titleBlock'>Returns</div>, <MasterDetail key='mdReturns'{...returnsConfig}><MasterDetail key='mdReturnLines'{...returnLinesConfig} /></MasterDetail>])
    }
}

export default (Returns)
