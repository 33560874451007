import React, { Component } from 'react'
import { listRequest } from '../../../configuration/enums';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Modal, ModalFooter, ModalBody, Button, ModalHeader } from 'reactstrap'
import classnames from 'classnames'
import MasterDetail from 'MasterDetail'
import { ApiGet } from '../../../api'
import { connect } from 'react-redux'
import * as headerActions from '../../../actions/header'
export const API_PATH = process.env.SERVER_PATH + "/api"

class CustomerSetup extends Component {
    constructor(props) {
        super(props);  
        this.state = { subActiveTab: '1', existingCustomers: [] }
    }

    toggle(tab, type) {
        if (type == 'sub') {
            if (this.state.subActiveTab !== tab) {
                this.setState({subActiveTab: tab});
            }
        }
    }

    componentDidMount() {
        ApiGet('CustomerSetup', 'GetExistingCustomers')
        .then((data) => {
            this.setState({ existingCustomers : data})
        })
    }

    displayTabs() {
        return (
            <Nav tabs>
                <NavItem>
                    <NavLink className={classnames({ active: this.state.subActiveTab === '1' })} onClick={() => { this.toggle('1', 'sub') }}>Contacts</NavLink>
                </NavItem>

                <NavItem>
                    <NavLink className={classnames({ active: this.state.subActiveTab === '2' })} onClick={() => { this.toggle('2', 'sub') }}>Job History</NavLink>
                </NavItem>
            </Nav>
        )
    }

    displaySubTable() {
        var subTab = [], ret = null
        var isEdit = false

        if (this.props.match && this.props.match.params.id) {
            if (this.props.match.params.id != "New") {
                if (this.props.match.params.id > 0) {
                    isEdit = true
                }
            }
        }

        if (isEdit) {
            var lists = {
                ContactType: [{ id: 1, name: 'Site Contact' }, { id: 2, name: 'Billing Contact' }],
                workType: this.state.customResponses || [{ id: 1, name: 'L1 - Remote Reset' }, { id: 2, name: 'L2 - Local Reset' }, { id: 3, name: 'L3 - Maintenance' }, { id: 4, name: 'L4 - Outsource' }],
                workStatus: this.state.customStatus || [{ id: 0, name: 'Scheduled' }, { id: 1, name: 'On Site' }, { id: 2, name: 'Offsite, to be logged' }, { id: 3, name: 'Fault Resolved' }, { id: 4, name: 'Case Resolved' }, { id: 6, name: 'Req. Followup' }, { id: 7, name: 'Cancelled' }]
            }

            var contactFields = [
                { label: 'Id', fieldId: 'id', display: 'none' },
                { label: 'Name', fieldId: 'name', validation: 'required'},
                { label: 'Phone', fieldId: 'tel' },
                { label: 'Email', fieldId: 'email' },
                { label: 'Address', fieldId: 'address', display: 'form' , type: 'textarea'},
                { label: 'Contact Type', fieldId: 'typeId', display: 'form', list: lists.ContactType, validation: 'requiredList' },
                { label: 'Notes', fieldId: 'notes', display: 'form', type: 'textarea'},
                //{ label: '', fieldId: 'navButton', type: 'navButton', typeProps: { location: '/Customers/' }, display: 'grid', width: 100 },
                { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { btnLabel: 'View' }, funcProps: (rec) => { return { location: '/ContactSetup/' + rec._original.id + '/0/' } }, width: 70 }
            ];

            var contactConfig = {
                model: 'contacts',
                path: '/CustomerSetup/ContactsLookUp/',
                fields: contactFields,
                titleField: 'name',
                crudParams: { stationId: this.props.match.params.id },
                defaultSorted: [{ id: 'name', desc: false }],
                gridButton: { pathname: '/ContactSetup/New', state: { stationId: this.props.match.params.id } },
                gridProps: { defaultPageSize: 20 },
                apiPath: API_PATH
            }

            var jobFields = [
                { label: 'Id', fieldId: 'id', display: 'none' },
                { label: 'Ref', fieldId: 'jobRef', display: 'grid' },
                { label: 'Type', fieldId: 'type', display: 'grid', list: lists.workType },
                { label: 'Status', fieldId: 'status', display: 'grid', list: lists.workStatus },
                { label: 'Start Time', fieldId: 'sTime', display: 'grid', type: 'datetime' },
                { label: 'Notes', fieldId: 'notes', display: 'grid' },
                { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { btnLabel: 'View' }, funcProps: (rec) => { return { location: '/WorkCards/' + rec._original.id } }, width: 70 }
            ];
    
            var jobConfig = {
                model: 'jobs',
                path: '/CustomerSetup/JobsLookUp/',
                fields: jobFields,
                titleField: 'name',
                crudParams: { stationId: this.props.match.params.id },
                defaultSorted: [{ id: 'sTime', desc: true }],
                gridButton: false,
                gridProps: { defaultPageSize: 20 },
                apiPath: API_PATH
            }
            
            var config = this.state.subActiveTab === '1' ? contactConfig : jobConfig
            subTab.push(<TabPane><MasterDetail {...config} /></TabPane>)

            ret = (
                <div style={{marginTop: '20px'}}>
                    {/* <div className='titleBlock'>Station Name Mapping</div> */}
                    {this.displayTabs()}

                    <div>
                        <TabContent>
                            {subTab}
                        </TabContent>
                    </div>
                </div>
            )
        }

        return ret
    }

    editCust(rec) {
        console.log('editCust', rec)
        console.log('props', this.props)
        this.props.history.push('/GroupSetup/' + rec.groupId, { history: this.props.history.location.state.history })
    }

    validateCustomer(r,b) {
        var groupId = b.groupId.value
        var existingSites = this.state.existingCustomers.filter(x => x.groupId == groupId).map(x => { return x.name })
        if (existingSites.includes(r)) {
            return 'Site name already exists for this customer'
        }
        else {
            return undefined
        }
    }

    cascadeComponents() {
        var subCatFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Asset / Device / Site', fieldId: 'name', inlineEdit: true },
            { label: 'Address', fieldId: 'address' },
            { label: 'Jobs Completed', fieldId: 'jobsCompleted' },
            { label: 'Total Jobs', fieldId: 'totalJobs' },
            { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { location: '/Incidents/' }, width: 70 }
        ];
        var nsSubCatsConfig = {
            model: 'customerSites',
            path: '/CustomerSetup/GetCustomerSites',
            fields: subCatFields,
            gridButton: { pathname: '/Customers/New' },
            crudParams: { cId: this.props.match.params.id },
            defaultSorted: [{ id: 'name', desc: false }],
            gridProps: { minRows: 1, noDataText: <div><br /><b>No customer asset / device / site</b></div>, showPagination: false, style: { border: 'none' } },
            apiPath: API_PATH
        }
        return <MasterDetail {...nsSubCatsConfig} />
    }

    render() {
        var lists = {
            ContactType: [{ id: 1, name: 'Site Contact' }, { id: 2, name: 'Billing Contact' }],
        }

        console.log('setip', this.props.history.location.state)

        var customerFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Customer', fieldId: 'groupId', list: listRequest.dvs, type: 'comboBox', validation: 'requiredList', canFilter: true },
            { label: '', fieldId: 'groupName', display: 'none' },
            { label: "", fieldId: "editCustomer", display: 'form', type: 'funcButton', typeProps: { label: <span>Edit Selected Customer<i className="fa fa-edit" style={{marginLeft:'10px'}} /></span>, func: (rec) => this.editCust(rec) } },
            { label: 'Asset / Device / Site', fieldId: 'name', display:'form', validation: (rec, b) => rec && this.validateCustomer(rec, b), canFilter: true },
            { label: 'Archive', fieldId: 'archive', type: 'checkbox',  width: 200, displayIf: () => ![true].includes(this.props.match.params.id == "New" ? true : false) },
            { label: "Active Products", fieldId: "products", display: 'form', type: 'checkList', listSource: '/CustomerSetup/GetClientProducts', typeProps: { defaultSorted: [{ id: 'name' }], listHeight: '300px' } },
            { label: "Primary Contact", fieldId: "pContact", display: 'form', displayIf: (rec) => ![false].includes(this.props.match.params.id == "New" ? true : false) },
            { label: 'Phone', fieldId: 'tel', display: 'form', displayIf: (rec) => ![false].includes(this.props.match.params.id == "New" ? true : false) },
            { label: 'Email', fieldId: 'email', display: 'form', displayIf: (rec) => ![false].includes(this.props.match.params.id == "New" ? true : false) },
            { label: 'Address', fieldId: 'address', display: 'form' , type: 'textarea', displayIf: (rec) => ![false].includes(this.props.match.params.id == "New" ? true : false)},
            // { label: 'Contact Type', fieldId: 'typeId', display: 'form', list: lists.ContactType, validation: 'required', displayIf: (rec) => ![false,].includes(this.props.match.params.id == "New" ? true : false) },
            { label: 'Contact Type', fieldId: 'typeId', display: 'form', list: lists.ContactType, displayIf: (rec) => ![false,].includes(this.props.match.params.id == "New" ? true : false) },
            { label: 'Notes', fieldId: 'notes', display: 'form', type: 'textarea', displayIf: (rec) => ![false].includes(this.props.match.params.id == "New" ? true : false)},
            { label: '', fieldId: 'navButton', type: 'navButton', typeProps: { location: '/Customers/' }, display: 'grid', width: 100 },
        ];

        //var customerFields = [
        //    { label: 'Id', fieldId: 'id', display: 'none' },
        //    { label: 'Name', fieldId: 'name', canFilter: true, width: 300 },
        //    { label: 'Address', fieldId: 'address', canFilter: true },
        //    { label: 'Sites', fieldId: 'sites', width: 100 },
        //    { label: 'Total Jobs', fieldId: 'totalJobs', width: 100 },
        //];

        var customerConfig = {
            model: 'customerSetup',
            fields: customerFields,
            titleField: 'name',
            master: true,
            showFilterRow: false,
            defaultSorted: [{ id: 'name', desc: false }],
            gridButton: { pathname: '/Customers/New' },
            gridProps: { defaultPageSize: 50 },
            submitAction: (r) => {
                this.props.setFilter();
                setTimeout(() => this.props.location.state && this.props.location.state.calendarReturn && this.props.history.push({ pathname: '/Calendar', state: { calendarReturn: this.props.location.state.calendarReturn, rec: r } }), 500) 
            },
            apiPath: API_PATH
            //cascade: true,
            //cascadeComponents: this.cascadeComponents(),
            //groupRowsBy: 'groupName'
        }

        return (
            <div>
                <div className='titleBlock' onClick={() => this.props.setFilter()} >Customers</div>
                <MasterDetail key='custsetup' {...customerConfig}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return state.common.header
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFilterOptions: () => {
            dispatch(headerActions.getFilterOptions())
        },
        setFilter: (filters) => {
            dispatch(headerActions.setFilter(filters));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSetup)