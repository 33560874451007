import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class MetricSettings extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        var lists = {
            calcMethod: [{ id: 'Avg', name: 'Avg' }, { id: 'Last', name: 'Last' }, { id: 'Max', name: 'Max' }],
            archivePlan: [{ id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4' }, { id: 5, name: '5' }],
            renderer: [{ id: 'renderTmcSysError', name: 'renderTmcSysError' }, { id: 'renderTmcSysState', name: 'renderTmcSysState' }, { id: 'renderVeryBigNum', name: 'renderVeryBigNum' }, { id: 'renderBinary', name: 'renderBinary' }],
            chartType: [{ id: 'column', name: 'Column' }]
        }

        var MetricSettingsFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Name", fieldId: "metricName", width: 170, canFilter: true },
            { label: "Units", fieldId: "metricUnits"},
            { label: 'Calc Method', fieldId: 'calcMethod', list: lists.calcMethod },
            { label: 'Archive Plan', fieldId: 'archivePlan', list: lists.archivePlan, display:'form' },
            { label: "Tag Name", fieldId: 'tagName' },
            { label: "Full Tag Name", fieldId: 'fullTagName' },
            { label: "Hide", fieldId: 'hide', type: 'checkbox', display: 'form' },
            { label: 'Decimal Places', fieldId: 'decimalPlaces' },
            { label: 'Renderer', fieldId: 'renderer', list: lists.renderer, display: 'form' },
            { label: 'Universal Metric', fieldId: 'umetId', list: listRequest.UniversalMetric, display: 'form' },
            { label: 'Surpress Above', fieldId: 'surpressAbove', display: 'form' },
            { label: 'Surpress Below', fieldId: 'surpressBelow', display: 'form' },
            { label: 'Surpress Zeros', fieldId: 'surpressZeros', display: 'form' },
            { label: 'Chart Type', fieldId: 'chartType', list: lists.chartType, display: 'form' },
            { label: 'Device Type', fieldId: 'deviceTypeId', display: 'form', list: listRequest.DeviceTypes },
            { label: 'Config', fieldId: 'config', display: 'form', type:'textarea' },
            { label: 'View', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton'},
        ]

        var MetricSettingsConfig = {
            model: 'MetricSettings',
            fields: MetricSettingsFields,
            master: true,
            titleField: 'metricName',
            hideDelete: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            defaultSorted: [{ id: 'id', desc: false }],
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Metric Settings</div>
                <MasterDetail {...MetricSettingsConfig} />                    
            </div >)
    }
}

export default (MetricSettings)