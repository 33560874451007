import { combineReducers } from 'redux'
import header from './header'
import scada from './scada'
import dashboard from './dashboard'
import lookups from './lookups'
import login from '../scenes/Login/state/reducer'

const reducers = combineReducers({
    header,
    dashboard,
    scada,
    users:login,
    lookups
})

export default reducers
