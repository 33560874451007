import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { listRequest } from '../../../configuration/enums'
import { ApiPost } from '../../../api'
import moment from 'moment'
import helpers from '../../../utils/helpers'

class DurationTracker extends Component {

	constructor(props) {
        super(props);
        this.state = { duration: "0h 0m", editMode: false }
    }

    componentDidMount() {
        if(this.props.endTime == null){
            this.timerId = setInterval(this.updateTimer.bind(this), 1000)
        }       
        this.updateTimer()
    }

    updateTimer() {
        var end = this.props.endTime ? moment.utc(this.props.endTime) : moment.utc(), start = moment.utc(this.props.startTime), t = helpers.parseDuration(start, end, false, true)
        if (end > start) {
            this.setState({ duration: t })
        }
    }

    setCustomTime() {
        this.setState({ customStart: helpers.parseGMTTime(this.props.startTime).format('HH:mm'), customEnd: (this.props.endTime ? helpers.parseGMTTime(this.props.endTime).format('HH:mm') : helpers.parseGMTTime(this.props.startTime).add(1, 'hour').format('HH:mm')) })
    }

    saveCustomTime() {
        var start = moment(moment(this.props.startTime).format('DD/MM/YYYY') + ' ' + this.state.customStart, 'DD/MM/YYYY HH:mm'), end = moment(moment(this.props.startTime).format('DD/MM/YYYY') + ' ' + this.state.customEnd, 'DD/MM/YYYY HH:mm')

        if (start < end) {
            ApiPost('Operations', 'UpdateWorkcardTime', { start: start.format('DD/MM/YY HH:mm'), end: end.format('DD/MM/YY HH:mm'), id: this.props.timeId })
            .then((data) => {
                this.setState({ editMode: false, timeError: false }, () => { this.props.reload(); })
            })
        }
        else {
            this.setState({ timeError: true })
        }
    }

    render() {
        const { editMode, timeError, customStart, customEnd } = this.state
        if (editMode) {
            return (
                <div className={'whTracker ' + (timeError && 'timeError')} style={{ marginBottom: '5px', padding: '1px', display:'block', borderColor:'#FFF' }}>
                    <Row>
                        <Col xs={12}>
                            <input type='time' className='form-control' defaultValue={customStart} style={{ padding: '0.25rem' }} onChange={(e) => this.setState({ customStart: e.target.value })} />
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'center' }}>-</div>
                    <Row>
                        <Col xs={12}>
                            <input type='time' className='form-control' defaultValue={customEnd} style={{ padding: '0.25rem' }} onChange={(e) => this.setState({ customEnd: e.target.value })} />
                        </Col>
                    </Row>
                    <button type="button" className="btn btn-primary" style={{width:'100%',marginTop:'5px'}} onClick={() => this.saveCustomTime()}>Save</button>
                </div>
            )
        }
        else {
            return (
                <div className='btn btn-default' style={{ border: '1px solid #ccc', marginBottom: '5px', padding: '5px' }}>
                    <Row style={{ alignItems: 'center' }}>
                        <Col xs={9}>
                            <div>{this.props.msg || 'Time On Site'}</div>
                            <div><b>{this.state.duration}</b></div>
                        </Col>
                        <Col xs={2}>
                            {this.props.allowEdit && <i className='fas fa-edit' style={{ lineHeight: '35px' }} onClick={() => this.setState({ editMode: !this.state.editMode }, () => this.setCustomTime())} />}
                        </Col>
                    </Row>
                </div>
            )
        }

	}
}

export default DurationTracker
