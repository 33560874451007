import React, { Component } from 'react';
import { ApiGet, ApiPost, getConfigItem } from '../../../api';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import TabPicker from './../../../components/TabPicker';

class DashContainer extends Component {
    constructor(props) {
        super(props);
        var clientDashConfig = getConfigItem('$.general.mainDashConfig')
        const availTabs = [];
        this.state = { clientDashConfig }
    }

    componentDidMount() {
        //document.title = 'Dashboard - everun'
        this.getApplicableDashboards();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.header.filterToken != this.props.header.filterToken) {
            this.getApplicableDashboards();
        }
    }

    getApplicableDashboards() {
        this.availTabs = [];

        var dashSpec = { dashboardId: this.props.dashId || 1, boundryId: 1 }
        ApiGet('Dashboard', 'GetDashboards', dashSpec)
        .then((data) => {
            data.map(x => {
                var dashInfo = { dashId: x.dashId, tabId: x.tabId, tabName: x.dashboardName, industryId: x.industryId, rowHeight: x.rowHeight || 120, fullDash: x.fullscreen, cols: x.cols || 12, stationId: this.props.stationId || 0 };
                this.availTabs.push({ tabName: x.dashboardName || "Default", tabContent: <Dashboard dashInfo={dashInfo} />})
            })
        })
    }
    
    render() {
        var singleDash = { dashId: this.props.dashId || 1, tabId: 0, tabName: '', dashType: 0, fullDash: false, cols: 18, rowHeight: 120 };

        //if (this.availTabs && this.availTabs[0]) {
        //    singleDash = this.availTabs[0].tabContent.props.dashInfo
        //}
        if (this.props.preloadedDashInfo) {
            singleDash = this.props.preloadedDashInfo;
        }
        var hideEdit = this.props.hideEdit
        var hideTitle = this.props.hideTitle
        if (singleDash.dashId == 1) {
            hideEdit = false
            hideTitle = true
            if (this.state.clientDashConfig) {
                if (this.state.clientDashConfig.rowHeight) {
                    singleDash.rowHeight = this.state.clientDashConfig.rowHeight
                }
                if (this.state.clientDashConfig.disableCompact) {
                    singleDash.disableCompact = this.state.clientDashConfig.disableCompact
                }
            }
        }
        return (
            <div>
                {this.availTabs && this.availTabs.length > 1 ? <TabPicker availTabs={this.availTabs} /> : <Dashboard dashInfo={singleDash} preloadedWidgets={this.props.preloadedWidgets} hideEdit={hideEdit} hideTitle={hideTitle} /> }
            </div>
        );
    }  
};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(DashContainer)
