import React, { Component } from 'react';

class iFrameWidget extends Component {

	componentDidMount() {
		if (this.props.selectedParam && this.props.selectedParam.params && this.props.selectedParam.params.refresh) {
			var rTime = parseInt(this.props.selectedParam.params.refresh)
			if (rTime >= 1) {
				this.timerId = setInterval(this.timer.bind(this), rTime * 60000);
			}
		}
	}

	timer() {
		document.getElementById('i' + this.props.selectedParam.i).src += ''
	}

    render() {
        var fProps = this.props.selectedParam.params
        return (
			fProps && <iframe id={'i'+this.props.selectedParam.i} src={fProps.iFrameURL} scrolling="no" style={{ width: '100%', height: 'auto', display: 'block', border:'none', overflow: 'hidden', marginTop: fProps.topOffset ? (fProps.topOffset * -1) + 'px' : 0, marginLeft: fProps.sideOffset ? (fProps.sideOffset * -1) + 'px' : 0 }} />
        );
    }
};

export default iFrameWidget;