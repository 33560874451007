import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { ToggleSwitch } from '../../../components/Controls/ToggleSwitch'
import { getConfigItem } from '../../../api'
import { connect } from 'react-redux'
import { saveActivityFilter } from '../../../actions/header';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap'
import { ApiGet } from '../../../api'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class ProductConfiguration extends Component {

    constructor(props) {
        super(props)
        var customTemplateGroups = getConfigItem('$.general.templateGroups')
        this.state = { editMode: false, customTemplateGroups, sId: 1, activityFilter: null, activeTab: 1, }
       
    }

    componentDidMount() {
        document.title = 'Product / Services Configuration | izon'

        ApiGet('UserSession', 'GetActivityFilter')
        .then(data => {
            this.setState({ activityFilter: data.filter.activityFilter })
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.setState({ editMode: false })
            if (this.props.match.params.id != 'New') {
                this.setState({ sId: Math.random() })
            }
        }
    }

    navNewRecord(id) {
        if (this.state.activityFilter != null) {
            var taf = this.state.activityFilter
            console.log('taf',taf)
            taf.wcTypes.push(id)
            delete taf['fromDate']
            delete taf['toDate']
            this.props.dispatch(saveActivityFilter(taf, true))
        }

        this.setState({ sId: Math.random() }, () => this.props.history.push('/ProductConfiguration/' + id))
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    getTable(tabId) {
        var lists = {
            statusTypes: [{ id: 1, name: 'Active' }, { id: 2, name: 'Prospective' }, { id: 3, name: 'Completed' }, { id: 4, name: 'Cancelled' }],
        }

        if (tabId == 1) {

            var WCSFields = [
                { label: "Id", fieldId: "id", display: 'none' },
                { label: "OrderId", fieldId: "orderId", display: 'none' },
                { label: "Status Name", fieldId: "title", inlineEdit: true },
                { label: "Status Type", fieldId: "statusTypeId", inlineEdit: true, list: lists.statusTypes },
                { label: "WCSId", fieldId: "contractTemplateId", display: 'none', getId: -1 },
                { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
                { label: '', fieldId: 'inlineDeleteBtn', width: 60, display: 'grid', type: 'inlineDeleteBtn' },
            ]

            var WCSConfig = {
                model: 'WorkCardStatusConfiguration',
                fields: WCSFields,
                addInline: true,
                defaultSorted: [{ id: 'orderId' }],
                reorder: this.state.editMode && 'field',
                apiPath: API_PATH
            }

            return(
                <div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <span>Please ensure there is at least one product status</span>
                        <ToggleSwitch style={{ marginBottom: '-30px', width: '150px', marginRight:'50px' }} smallMode={true} switchLabel="Reorder Mode" onToggle={() => this.setState({ editMode: !this.state.editMode })} />
                    </div>
                    <MasterDetail key={'md' + this.state.editMode} {...WCSConfig} />
                </div >
            )
        }
        else if (tabId == 2) {

            var WCSFields = [
                { label: "Id", fieldId: "id", display: 'none' },
                { label: "Form", fieldId: "formTypeId", inlineEdit: true, list: listRequest.ClientForms },
                { label: "Job Status", fieldId: "statusTypeId", inlineEdit: true, list: listRequest.WorkCardStatuses, listParent: 'contractTemplateId', typeProps: {customSelectMsg: 'Any'} },
                { label: "WCSId", fieldId: "contractTemplateId", display: 'none', getId: -1 },
                { label: '', fieldId: 'view', width: 60, display: 'grid', type: 'navButton' },
                { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
                { label: '', fieldId: 'inlineDeleteBtn', width: 60, display: 'grid', type: 'inlineDeleteBtn' },
            ]

            var WCSConfig = {
                model: 'ProductFormConfiguration',
                path: '/WorkCardStatusConfiguration/GetForms',
                fields: WCSFields,
                addInline: true,
                showFilterRow: false,
                apiPath: API_PATH
            }

            return <MasterDetail key={'md' + this.state.editMode} {...WCSConfig} />
        }
    }

    render() {

        var lists = {
            templateGroups: this.state.customTemplateGroups ? this.state.customTemplateGroups : [{ id: 'Default', name: 'Default' }],
        }

        var WCCFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Title", fieldId: "title" },
            { label: "Group", fieldId: "group", list: lists.templateGroups },
            { label: "Notes", fieldId: "notes", type: 'textarea' },
            { label: "Config", fieldId: "config", type: 'textarea', display: 'form' },
            { label: "Auto Allocate Alerts", fieldId: "alertAutoAllocate", type: 'checkbox', display: 'form' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton'},
        ]

        var WCCConfig = {
            model: 'ProductConfiguration',
            fields: WCCFields,
            master: true,
            titleField: 'name',
            hideDelete: true,
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            defaultSorted: [{ id: 'name', desc: false }],
            submitAction: (e) => e.contractTemplateId && this.navNewRecord(e.contractTemplateId),
            apiPath: API_PATH
        }

        const { activeTab } = this.state;
        const tabConfig = [{ id: 1, name: 'Product Statuses' }, { id: 2, name: 'Product Forms' }]

        return (
            <div>
                <div className='titleBlock'>Product Configuration</div>
                <MasterDetail {...WCCConfig} key={this.state.sId}>
                    {this.props.match.params.id != 'New' &&
                        <div>
                            <Nav tabs>
                                {tabConfig.map(t => (
                                    <NavItem key={t.id}>
                                        <NavLink
                                            className={activeTab === t.id ? 'active' : ''}
                                            onClick={() => this.toggle(t.id)}>
                                            {t.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent key='clientTabs' activeTab={activeTab}>
                                {tabConfig.map(t => (
                                    <TabPane key={t.id} tabId={t.id}>
                                        {activeTab === t.id && this.getTable(t.id)}
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    }
                </MasterDetail>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return state.common.header
}

export default connect(mapStateToProps, null, null, { withRef: true })(ProductConfiguration)
