import React, { Component } from 'react';

export default class IntersectPoint extends Component {

    constructor(props) {
        super(props)
    }

    parseTitle(title) {
        return (
        <div className='intersectInfo'>
            <span>{Math.round(title.distance, 1)} NM</span>
            <span>{title.speed} kts</span>
            {title.speed > 0 && <span>{title.time}</span>}
        </div>)
    }

    render() {
        const { title, showTitle } = this.props;

        //<div className='vesselLabel'><span>{this.props.title}</span></div>

        return (
            <div>
                {showTitle && this.parseTitle(title)}
                <div style={{ margin: '-6px 0 0 -4px' }}>
                    <svg viewBox="0 -0.012 16 16.023" width="10" height="10" fill="#d0021b" >
                        <path d="M 16 8 C 16 1.842 9.333 -2.007 4 1.072 C 1.525 2.501 0 5.142 0 8 C 0 14.158 6.667 18.007 12 14.928 C 14.475 13.499 16 10.858 16 8 Z"></path>
                    </svg>
                </div>
            </div>
          )
    }
}