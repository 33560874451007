import React, { Component } from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import HWCard from './HWCard'

class HealthWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = { aS : 1, showModal:false}
    }

    getStationList(vals) {
        var assets = this.props.assets
        var sArray = []

        vals.map(x => {
            sArray.push(<HWCard key={x.sourceId + '-' + x.stationId} data={x} assets={assets} />)
        })

        return sArray
	}

	//shouldComponentUpdate(nextProps) {
	//	if (JSON.stringify(nextProps) != JSON.stringify(this.props) && nextProps.assets.length > 0) {
	//		this.setComponent()
	//		return true
	//	}
	//	else {
	//		return false
	//	}
	//}


 //   setComponent() {
	//	console.log('setting comp', this.props)
 //       const ArrowLeft = (props) => (
 //           <i {...props} className='fal fa-caret-circle-left' style={{ cursor: 'pointer' }} />
 //       );
 //       const ArrowRight = (props) => (
 //           <i {...props} className='fal fa-caret-circle-right' style={{ cursor: 'pointer' }} />
 //       );

 //       const settings = {
 //           infinite: true,
 //           speed: 200,
 //           slidesToShow: 1,
 //           slidesToScroll: 1,
 //           autoplay: true,
 //           autoplaySpeed: 5000,
 //           fade: true,
 //           afterChange: current => this.setState({ aS: current + 1 }),
 //           nextArrow: <ArrowRight />,
 //           prevArrow: <ArrowLeft />
 //       }

 //       var wId = this.props.selectedParam.params ? this.props.selectedParam.params.warnSource : 0
 //       var sList = this.props.widgetValue && this.props.widgetValue.filter(x => x.sourceId == wId)
        
 //       var sArray = null
 //       if (sList && sList.length > 0) {
 //           sArray = <Slider {...settings}>{this.getStationList(sList)}</Slider> 
 //       }

 //       this.setState({ sList, wId, sArray })
 //   }

    render() {

        const ArrowLeft = ({ currentSlide, slideCount, children, ...props }) => (
			<i {...props} className='fal fa-caret-circle-left' style={{ cursor: 'pointer' }} />
		);
        const ArrowRight = ({ currentSlide, slideCount, children, ...props }) => (
			<i {...props} className='fal fa-caret-circle-right' style={{ cursor: 'pointer' }} />
		);

		const settings = {
			infinite: true,
			speed: 200,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			fade: true,
			afterChange: current => this.setState({ aS: current + 1 }),
			nextArrow: <ArrowRight />,
			prevArrow: <ArrowLeft />
		}

		var wId = this.props.selectedParam.params ? this.props.selectedParam.params.warnSource : 0
        var sList = this.props.widgetValue && this.props.widgetValue.filter(x => x.sourceId == wId)

        var hasIssue = sList && sList.length > 0

        return (
            <div className={'warnWidget ' + (hasIssue ? 'warn' : 'ok')}>
                {wId != null && wId != undefined &&
                    <div style={{height:'100%'}}>
                    <div style={{ height: '20%', padding: '5px', borderBottom: '1px solid #ffffff80', minHeight: '35px' }}><b style={{ fontSize: '1.5rem' }}>{this.props.params && this.props.params.warnSource.items.find(x => x.id == wId).title}</b>{hasIssue && <div style={{ float: 'right', fontSize: '1.5rem' }}><span>{this.state.aS}</span><span> of </span><b>{sList.length}</b></div>}</div>
                        <div style={{ margin: '10px', height: '70%' }}>
                            {hasIssue ?
                            <Slider {...settings}>{this.getStationList(sList)}</Slider>  :
                            <div className='warnContain'>
                                <div><b style={{ fontSize: '25px' }}>No Issues</b><i style={{ fontSize: '2em', marginLeft: '5px' }} className='fad fa-check-circle faGW' /></div>
                            </div>}
                        </div>
                    </div>
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return state.common.header
}

export default connect(mapStateToProps)(HealthWidget)
