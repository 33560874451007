import React, { Component } from 'react'
import { Col, Label, Row, Input, Button } from 'reactstrap'
import CheckBoxList from '../../../components/Controls/CheckBoxList'
import SimpleModal from './../../../components/SimpleModal'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { ApiPost } from '../../../api'

class RuleSetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rules: [], availFields: [], availGroups: [], showRule: false,  
            header: null, body: null, footer: null, open: false,
            cRuleName: null, cRuleId: null, selectedRules: [], 
            cRuleConditions: [], cRuleCondition: null, cConditionId: null, selectedConditions: [],
            cRuleActions: [], cRuleAction: null, cActionId: null, selectedActions: [], 
        }
    }

    componentDidMount() {
        this.configureRules()
    }

    configureRules() {
        var tFields = [], tGroups = []
        this.props.rec.formFields.map(x => {
            tFields.push({ id: x.formFieldDefId, name: x.dataName })
            if (!tGroups.find(y => y.id == x.groupId)) {
                if (x.groupName != null) {
                    tGroups.push({ id: x.groupId, name: x.groupName })
                }
            }
        })
        this.setState({ availFields: tFields, availGroups: tGroups, rules: this.props.rec.rules ? JSON.parse(this.props.rec.rules) : [] })
    }

    addCondition(cField, cOperator, cVal) {
        if (cField != undefined && cOperator != undefined && cVal != undefined ) {
            var name = this.state.availFields.find(x => x.id == cField).name + ' ' + cOperator + ' ' + cVal
            var tConds = this.state.cRuleConditions
            var cond = { cField, cOperator, cVal, id: this.state.cConditionId || tConds.length + 1, name }
            if (this.state.cConditionId) {
                var i = tConds.findIndex(x => x.id == cond.id)
                tConds[i] = cond
            }
            else {
                if (tConds.length > 0) {
                    tConds.push({ id: 'o_' + tConds.length + 1, name: 'AND', cOperator: 'AND' })
                }
                tConds.push(cond)
            }
            this.setState({ cRuleConditions: tConds, cConditionId: null, open: !this.state.open, errorMsg:null }, () => console.log('this', this.state.cRuleConditions))
        }
        else {
            this.setState({errorMsg: 'Please complete all inputs'})
        }
    }

    addAction(aAction, aType, aVal) {
        var name = aAction + ' ' + (aType == 'field' ? this.state.availFields.find(x => x.id == aVal).name : this.state.availGroups.find(x => x.id == aVal).name) + ' ' + aType
        var tActs = this.state.cRuleActions
        var act = { aAction, aType, aVal, id: this.state.cActionId || tActs.length + 1, name }
        if (this.state.cActionId) {
            var i = tActs.findIndex(x => x.id == act.id)
            tActs[i] = act
        }
        else {
            tActs.push(act)
        }
        this.setState({ cRuleActions: tActs, cActionId: null, open: !this.state.open, errorMsg: null  }, () => console.log('this', this.state.cRuleActions))
    }

    setConditionsModal(f,o,v) {
        var ops = [{ name: '=', id: '=' }, { name: '!=', id: '!=' }, { name: '>', id: '>' }, { name: '<', id: '<' }],
            cField = f, cOperator = o, cVal = v
        var header = <span>Rule Conditions</span>,
            body = <div>
                <Row>
                    <Col sm="4"><b>Field</b></Col>
                    <Col sm="4"><b>Operator</b></Col>
                    <Col sm="4"><b>Value</b></Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <IdNameDropdown options={this.state.availFields} zeroText='Field' valueField="id" displayField="name" value={cField} onChange={(e) => { this.setConditionsModal(e, cOperator, cVal) }} />
                    </Col>
                    <Col sm="4">
                        <IdNameDropdown options={ops} zeroText='Operator' valueField="id" displayField="name" value={cOperator} onChange={(e) => { this.setConditionsModal(cField, e, cVal) }} />
                    </Col>
                    <Col sm="4">
                        <input className="input" className="form-control" defaultValue={cVal || ''} placeholder="Value" onChange={(e) => { this.setConditionsModal(cField, cOperator, e.target.value) }} />
                    </Col>
                </Row>
                {this.state.errorMsg && <Row><span style={{ color: 'red' }}>{this.state.errorMsg}</span></Row>}
            </div>,
            footer = <div style={{ width: '100%' }}>
                <Button onClick={() => { this.setState({ open: !this.state.open, errorMsg: null });}}>Cancel</Button>
                <Button color='primary' style={{ float: 'right' }} onClick={() => this.addCondition(cField, cOperator, cVal) }>Save</Button>
            </div>

        this.setState({ open: true, header, body, footer })
    }

    setActionsModal(a, t, v) {
        var actions = [{ name: 'Show', id: 'show' }, { name: 'Hide', id: 'hide' }],
            types = [{ name: 'Field', id: 'field' }, { name: 'Group', id: 'group' }],
            aAction = a, aType = t, aVal = v

        var header = <span>Rule Actions</span>,
            body = <div>
                <Row>
                    <Col sm="4"><b>Action</b></Col>
                    <Col sm="4"><b>Type</b></Col>
                    <Col sm="4"><b>Value</b></Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <IdNameDropdown options={actions} zeroText='Action' valueField="id" displayField="name" value={aAction} onChange={(e) => { this.setActionsModal(e, aType, aVal); }} />
                    </Col>
                    <Col sm="4">
                        <IdNameDropdown options={types} zeroText='Type' valueField="id" displayField="name" value={aType} onChange={(t) => { this.setActionsModal(aAction, t, aVal); }} />
                    </Col>
                    <Col sm="4">
                        {aType == 'field' ? <IdNameDropdown options={this.state.availFields} zeroText='Value' valueField="id" displayField="name" value={aVal} onChange={(e) => { this.setActionsModal(aAction, aType, e); }} /> : <IdNameDropdown options={this.state.availGroups} zeroText='Value' valueField="id" displayField="name" value={aVal} onChange={(e) => { this.setActionsModal(aAction, aType, e) }} />}
                    </Col>
                </Row>
            </div>,
            footer = <div style={{ width: '100%' }}>
                <Button onClick={() => { this.setState({ open: !this.state.open, errorMsg: null }); }}>Cancel</Button>
                <Button color='primary' style={{ float: 'right' }} onClick={() => this.addAction(aAction, aType, aVal) }>Save</Button>
            </div>

        this.setState({ open: true, header, body, footer })
    }

    saveRule() {
        var rule = { id: this.state.cRuleId || this.state.rules.length +1, name: this.state.cRuleName, conditions: this.state.cRuleConditions, actions: this.state.cRuleActions }
        var t = this.state.rules
        var ruleIndex = t.findIndex(x => x.id == rule.id)
        if (ruleIndex >= 0) {
            t[ruleIndex] = rule
        }
        else {
            t.push(rule)
        }
        console.log('saveRule t', t)
        this.setState({ rules: t, errorMsg: null }, () => this.saveFormRule())
    }

    resetRule() {
        this.setState({ cRuleName: null, cRuleConditions: [], cRuleActions: [], cRuleId: null, selectedRules: [], showRule: false, errorMsg: null })
    }

    saveFormRule() {
        const t = { entityId: this.props.rec.id, rules: JSON.stringify(this.state.rules) }
        console.log('saveFormRule', t)
        ApiPost('CustomForm', 'UpdateFormRules', t)
        .then((n) => {
            console.log('n', n)
            this.resetRule()
        })
    }

    removeItem(type) {
        if (type == 'rule') {
            var tRules = this.state.rules.filter(x => !this.state.selectedRules.includes(x.id))
            this.setState({ rules: tRules, selectedRules: [] }, () => this.saveFormRule())
        }
        else if (type == 'condition') {
            var tConds = this.state.cRuleConditions.filter(x => !this.state.selectedConditions.includes(x.id))
            this.setState({ cRuleConditions: tConds, selectedConditions: [] }, () => this.saveRule())
        }
        else if (type == 'action'){
            var tActs = this.state.cRuleActions.filter(x => !this.state.selectedActions.includes(x.id))
            this.setState({ cRuleActions: tActs, selectedActions: [] }, () => this.saveRule())
        }
    }

    editItem(type) {
        if (type == 'rule') {
            var rule = this.state.rules.find(x => x.id == this.state.selectedRules[0])
            console.log('editRule', rule)
            this.setState({ cRuleId: this.state.selectedRules[0], cRuleName: rule.name, cRuleConditions: rule.conditions, cRuleActions: rule.actions, showRule: true })
        }
        else if (type == 'condition') {
            var cond = this.state.cRuleConditions.find(x => x.id == this.state.selectedConditions[0])
            console.log('editCond', cond)
            this.setState({ cConditionId: this.state.selectedConditions[0] })
            this.setConditionsModal(cond.cField, cond.cOperator, cond.cVal)
        }
        else {
            var act = this.state.cRuleActions.find(x => x.id == this.state.selectedActions[0])
            console.log('editAct', act)
            this.setState({ cActionId: this.state.selectedActions[0] })
            this.setActionsModal(act.aAction, act.aType, act.aVal)
        }
    }

    changeOperator() {
        var tConds = this.state.cRuleConditions, tId = this.state.selectedConditions[0],
        i = tConds.findIndex(x => x.id == tId), tOp = tConds[i].cOperator == 'AND' ? 'OR' : 'AND'
        tConds[i] = { id: tId, name: tOp, cOperator: tOp }
        this.setState({cRuleConditions: tConds})
    }

    render() {
        const { footer, header, body, open, selectedRules, selectedActions, selectedConditions } = this.state
        var sOperator = selectedConditions.filter(x => typeof x === 'string' && x.includes('o_')).length > 0
        return (
            <Row>
                <Col sm={3}>
                    <Col sm={12}>
                    <Row>
                        <Label>Rules</Label>
                    </Row>
                    <Row>
                        <CheckBoxList options={this.state.rules} nameField="name" className='ruleChecklist' DefaultIds={selectedRules} innerStyle={{ marginLeft: 0 }} onChange={(ids) => this.setState({selectedRules: ids})} />
                    </Row>
                    <Row className='ruleSetupRow' >
                        {selectedRules.length == 0 && <Button color='primary' onClick={() => this.setState({ showRule: !this.state.showRule })}>Add Rule</Button>}
                        {selectedRules.length == 1 && <Button onClick={() => this.editItem('rule')}>Edit</Button>}
                        {selectedRules.length > 0 && <Button onClick={() => this.removeItem('rule')}>Remove</Button>}
                    </Row>
                    </Col>
                </Col>
                {this.state.showRule && <Col sm={4}>
                    <Row>
                        <Col sm={12}>
                            <Row><Label>Rule Name</Label></Row>
                            <Row><Input value={this.state.cRuleName} onChange={(e) => this.setState({ cRuleName: e.target.value })} /></Row>

                            <Row style={{marginTop:'5px'}}><Label>Conditions</Label></Row>
                            <Row><CheckBoxList options={this.state.cRuleConditions} className='ruleChecklist' nameField="name" innerStyle={{ marginLeft: 0 }} onChange={(ids) => this.setState({ selectedConditions: ids })} /></Row>
                            <Row className='ruleSetupRow'>
                                {selectedConditions.length == 0 && <Button color='primary' onClick={() => this.setConditionsModal()}>Add</Button>}
                                {selectedConditions.length == 1 ? sOperator ? <Button onClick={() => this.changeOperator()}>Change</Button> : <Button onClick={() => this.editItem('condition')}>Edit</Button> : null}
                                {selectedConditions.length > 0 && !sOperator && <Button onClick={() => this.removeItem('condition')}>Remove</Button>}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Row><Label>Actions</Label></Row>
                            <Row><CheckBoxList options={this.state.cRuleActions} className='ruleChecklist' nameField="name" innerStyle={{ marginLeft: 0 }} onChange={(ids) => this.setState({ selectedActions: ids })} /></Row>
                            <Row className='ruleSetupRow'>
                                {selectedActions.length == 0 && <Button color='primary' onClick={() => this.setActionsModal()}>Add</Button>}
                                {selectedActions.length == 1 && <Button onClick={() => this.editItem('action')}>Edit</Button>}
                                {selectedActions.length > 0 && <Button onClick={() => this.removeItem('action')}>Remove</Button>}
                            </Row>
                        </Col>
                    </Row>
                    <Row className='ruleSetupRow'>
                        <Button color='primary' onClick={() => this.saveRule()}>Save Rule</Button>
                    </Row>
                </Col>}
                <SimpleModal {...{ header, body, footer, open }} />
            </Row>
            )
    }
}

export default RuleSetup
