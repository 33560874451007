// JavaScript source code
import React, { Component } from 'react'
import CheckBox from './CheckBox';

export default class CheckBoxList extends Component {
    constructor(props) {
        super(props)
        this.doChange = this.doChange.bind(this)
        this.clearAll = this.clearAll.bind(this)
        this.state = { SelectedIds: [], SelectedNames: [], initalised : false, debug: false }
    }

    defaultWrapperStyle = { border: '1px solid rgba(0,0,0,.15)', overflowY: 'auto', overflowX: 'hidden', maxHeight: '500px', whiteSpace: 'nowrap'}

    componentWillMount() {
        const props = this.props;
        if(this.state.debug) console.log("componentDidMount")
        var result = (props.DefaultIds != undefined);          

        if (!this.state.initalised && result) {
            const { DefaultIds } = props;
            if (DefaultIds) {
                this.setState({ SelectedIds: DefaultIds, initalised: true });
            }
            else {
                this.setState({ initalised: true });     
            }
        }    
    }    

    setSelectedIds(arrSelectedIds) {
        if (this.state.debug) console.log("setSelectedIds")
        {
            this.setState({ SelectedIds: arrSelectedIds })
        }        
    }

    getSelectedIds() {

        return this.state.SelectedIds;
    }

    setSelectedNames(arrSelectedNames) {
        if (this.state.debug) console.log("setSelectedNames")
        this.setState({ SelectedNames: arrSelectedNames })
    }

    getSelectedNames() {

        return this.state.SelectedNames;
    }

    selectAll() {
        const { options, keyField } = this.props
        var keyFieldA = "id";
        if (keyField) keyFieldA = keyField;
        const selIds = options.map(
            (item) => item[keyFieldA]
        )
        this.setState({ SelectedIds: selIds}, () => this.props.onChange(this.state.SelectedIds, null, null, false))
    }

    clearAll() {
        this.setState({ SelectedIds: []}, () => this.props.onChange(this.state.SelectedIds, null, null, false))
    }

    

    doChange(chkbox) {

        if(this.state.debug) console.log("doChange",chkbox)
        var target = chkbox;  //event.target;
        var alreadyExists = this.state.SelectedIds.find(k => k == target.value);

        if (target.checked && ((alreadyExists != target.value)|| (alreadyExists == "undefined")))
        {
            var current_Status = target.checked;
            var id = target.value;
            //var name = target.name;

            var newIds = this.state.SelectedIds; //.slice();
            newIds.push(id);
            this.setState({ SelectedIds: newIds }, () => this.props.onChange(this.state.SelectedIds, id, current_Status, target.labelClick))
        }
        else {     
            var newIds = this.state.SelectedIds;
            newIds = newIds.filter(item => item !== target.value)
            this.setState({ SelectedIds: newIds }, () => this.props.onChange(this.state.SelectedIds, id, current_Status, target.labelClick))
        } 
        if(this.state.debug) console.log('In doChange', newIds);
    }   

    render() {
        const { options, DefaultIds, keyField, nameField, innerStyle, style, labelMaxChars = 0, showSelectAll, className } = this.props    
        const stylex = options && options.length >= 0 ? { ...this.defaultWrapperStyle, ...style} : { ...this.defaultWrapperStyle, ...style, border: 'none' }; 
        const innerStyleX = {marginLeft: '20px', ...innerStyle}
        var keyFieldA = "id";
        var NameFieldA = "name";
        if (keyField) keyFieldA = keyField;
        if (nameField) NameFieldA = nameField;
        if(this.state.debug) console.log("render")
        return ( 
            <div style={stylex} className={className} >
                {this.props.showSelectAll && options ? <div style={{textAlign:'left'}}>
                    <div onClick={() => this.clearAll()} style={{ cursor:'pointer'}}><i className="fa fa-remove" style={{ color: 'red', width: '16px', marginLeft: '12px' }} /> Clear filter</div>
                    <div onClick={() => this.selectAll()} style={{ cursor: 'pointer' }}><i className="fas fa-check-double" style={{ color: '#4e99c7', width: '16px', marginLeft: '12px' }} /> Select All</div>
                </div> : null}
                {       
                    options &&
                    options.map(
                        (x) => {
                            var result = this.state.SelectedIds.find(k => k == x[keyFieldA]);
                            var checked = (result != undefined);
                            return ( 
                                <div style={innerStyleX} key={x[keyFieldA]}>
                                    <CheckBox label={x[NameFieldA]} renderer={x.renderer} labelMaxChars={labelMaxChars} name={x[NameFieldA]} value={x[keyFieldA]} checked={checked} onChange={(item) => this.doChange(item)} />
                                </div>
                                
                            )                            
                        })                    
                }
                </div> 
               )  
    }  
   
}