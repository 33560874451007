import React, { Component } from 'react';
import { Input, Table, Label, Row, Col } from 'reactstrap';
import ActCards from './ActivityCards'
import { ApiPost, getConfigItem } from '../../../api';
import moment from 'moment'
import ComboBox from '../../../components/Controls/ComboBox'
import { Container, Draggable } from 'react-smooth-dnd'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { connect } from 'react-redux'
import ActivityScheduler from './ActivityScheduler'

class ActivityCalendar extends Component {
    constructor(props) {
        super(props);
        var yearView = [];

        for (var i = 1; i <= 53; i++) {
            yearView.push(i);
        }
        var defaultRowOrder = props.rowOrder && props.rowOrder.length > 0 ? props.rowOrder : props.users.map((x, i) => { return x.id })
        this.state = {
            sites: [],
            engineerSiteToggle: this.props.engineerSiteToggle != null ? this.props.engineerSiteToggle : '2',
            activityData: [],
            HideWeekchecked: true,
            MonFrichecked: true,
            HideYearchecked: true,
            hideChecked: this.props.hideChecked != null ? this.props.hideChecked : true,
            message: null,
            responseOpen: false,
            responseData: null,
            mainActiveYear: this.props.mainActiveYear != null ? this.props.mainActiveYear : new Date().getFullYear(),
            mainActiveWeek: this.props.mainActiveWeek != null ? this.props.mainActiveWeek : this.getIndexOfDate(new Date(), 'year'),
            mainActiveMonth: this.props.mainActiveMonth != null ? this.props.mainActiveMonth : new Date().getMonth(),
            toggleUpdated: true,
            userDictionary: {},
            siteDictionary: {},
            yearView,
            ctrlPressed: false,
            preRec: null,
            reorder: false,
            rowOrder: defaultRowOrder,
            compactMode: false
        }
        this.monthValues = [{ id: 0, name: 'January' }, { id: 1, name: 'February' }, { id: 2, name: 'March' }, { id: 3, name: 'April' }, { id: 4, name: 'May' }, { id: 5, name: 'June' },
        { id: 6, name: 'July' }, { id: 7, name: 'August' }, { id: 8, name: 'September' }, { id: 9, name: 'October' }, { id: 10, name: 'November' }, { id: 11, name: 'December' }]

    }

    ctrlFunc(e) {
        console.log
        if (e.ctrlKey) {
            if (!this.state.ctrlPressed) {
                this.setState({ ctrlPressed: true })
            }
        }
        else {
            if (!this.state.ctrlPressed) {
                setTimeout(() => this.setState({ ctrlPressed: false }),500)
            }
        }
    }

    componentDidMount() {
        this.toggleSlider(true)
        this.reloadData()
        document.addEventListener("keydown", (e) => this.ctrlFunc(e), false);
        document.addEventListener("keyup", (e) => setTimeout(() => this.setState({ ctrlPressed: false }), 500), false);
        if (this.props.history.location.state && this.props.history.location.state.calendarReturn && this.props.history.location.state.calendarReturn.users) {
            this.addEvent(this.props.history.location.state.calendarReturn.users[0], 0, this.props.history.location.state.calendarReturn.date)
        }
        if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.rec) {
            this.setState({ preRec: this.props.history.location.state.rec })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", (e) => this.ctrlFunc(e), false);
        document.removeEventListener("keyup", (e) => this.setState({ ctrlPressed: false }), false);
    }

    toggleSlider(activate = true) {
        const slider = document.getElementById('activityContain')
        let isDown = false, startX, scrollLeft;

        slider.addEventListener('mousedown', (e) => {
            if (e.target.className != 'cardGrabber') {
                isDown = true;
                slider.style.cursor = 'grabbing'
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            }
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.style.cursor = 'default'
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.style.cursor = 'default'
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1; //scroll-normal
            slider.scrollLeft = scrollLeft - walk;
        });

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.activityData != nextProps.activityData) {
            if (this.state.engineerSiteToggle == '1') {
                this.setState({ activityData: nextProps.activityData }, () => this.setSiteData(nextProps))
            }
            else if (this.state.engineerSiteToggle == '2') {
                this.setState({ activityData: nextProps.activityData }, () => this.setUserData(nextProps))
            }
            return true
        }
        else {
            if (JSON.stringify(nextState) != JSON.stringify(this.state)) {
                if (Object.entries(this.state.userDictionary).length === 0 && Object.entries(this.state.siteDictionary).length === 0 && this.props.activityData.filter(x => x.fsrStatus != 0).length > 0) {
                    if (this.state.engineerSiteToggle == '1') {
                        this.setSiteData(nextProps)
                    }
                    else if (this.state.engineerSiteToggle == '2') {
                        if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.rec) {
                            console.log('returning to existing rec')
                        }
                        else {
                            this.setUserData(nextProps)
                        }
                    }
                }
                return true
            }
            else {
                return false
            }
        }
    }

    //Function for arranging activityData into userDictionary for Users.
    setUserData(nextProps) {
        if (this.state.toggleUpdated != false) {
            this.setState({ toggleUpdated: false }, () => console.log('toggleUpdated'))
        }
        var userDictionary = {}
        const { activityData, mode } = nextProps
        if (mode == 'month') {
            var monthDays = moment(this.state.mainActiveYear + '-' + (this.state.mainActiveMonth + 1), 'YYYY-MM').daysInMonth()
        }
        var counter = mode == 'year' ? 53 : mode == 'week' ? this.state.MonFrichecked ? 5 : 7 : mode == 'month' ? monthDays : 0
        var userList = this.props.users.filter(x => this.props.header.activityFilter.engineers.includes(x.id))
        if (userList) {
            userList.map(u => {
                var userActivity = activityData.length > 0 && activityData.filter(x => x.users && x.users.includes(u.id) && x.fsrStatus != 0)
                if (this.state.hideChecked && userActivity.length == 0) {
                    this.setState({ hideChecked: !this.state.hideChecked }, () => {
                        this.props.onFilterChange('hideChecked', this.state.hideChecked)
                    })
                }
                else {
                    userDictionary[u.id] = []
                    if (userActivity) {
                        userActivity.map(wc => {
                            var index = this.getIndexOfDate(wc.activityDate, mode)
                            if (index <= counter) {
                                var tArray = userDictionary[u.id][index] != null ? userDictionary[u.id][index] : []
                                tArray.push(wc)
                                userDictionary[u.id][index] = tArray
                            }
                        })
                    }
                    for (var i = 0; i <= counter; i++) {
                        if (userDictionary[u.id][i] == null) {
                            userDictionary[u.id][i] = []
                        }
                    }
                }
            })
        }
        if (userDictionary != this.state.userDictionary) {
            this.setState({ userDictionary })
        }
    }

    //Function for arranging activityData into Dictionary for Sites.
    setSiteData(p) {
        var activityData = p.activityData.filter(x => x.fsrStatus != 0), siteDictionary = {}
        if (p.mode == 'month') {
            var monthDays = moment(this.state.mainActiveYear + '-' + (this.state.mainActiveMonth + 1), 'YYYY-MM').daysInMonth()
        }
        activityData.sort((a, b) => (a.deviceId - b.deviceId))
        var siteList = activityData.map(a => a.deviceId).filter((value, index, self) => self.indexOf(value) === index)
        if (!this.state.hideChecked) {
            siteList = this.props.stations.map(a => a.id).filter((value, index, self) => self.indexOf(value) === index)
        }
        var counter = p.mode == 'year' ? 53 : p.mode == 'week' ? this.state.MonFrichecked ? 5 : 7 : p.mode == 'month' ? monthDays : 0
        siteList.map(site => {
            var siteActivity = activityData.filter(x => x.deviceId == site)
            siteDictionary[site] = []
            siteActivity.map(wc => {
                var index = this.getIndexOfDate(wc.activityDate, p.mode)
                var tArray = siteDictionary[site][index] != null ? siteDictionary[site][index] : []
                tArray.push(wc)
                siteDictionary[site][index] = tArray
            })
            for (var i = 0; i <= counter; i++) {
                if (siteDictionary[site][i] == null) {
                    siteDictionary[site][i] = []
                }
            }
        })
        this.setState({ siteDictionary, toggleUpdated: false  })
    }

    onHideEmpty() {
        this.setState({ hideChecked: !this.state.hideChecked }, () => {
            this.props.onFilterChange('hideChecked', this.state.hideChecked)
            this.reloadData()
        })
    }

    reloadData() {
        if (this.state.engineerSiteToggle == '1') {
            this.setSiteData(this.props)
        }
        else if (this.state.engineerSiteToggle == '2') {
            this.setUserData(this.props)
        }
    }

    onMonToFri() {
        this.setState({ MonFrichecked: !this.state.MonFrichecked }, () => this.reloadData())
    }

    //Based on the date and mode, here we get the index of the date(Nothing but column number)
    getIndexOfDate(innerDate, mode) {
        if (mode == 'year') {
            return moment(innerDate).week()
        }
        else if (mode == 'month') {
            return moment(innerDate).date()
        }
        else {
            var date = new Date(innerDate);
            var result = date.toLocaleDateString('en-gb', { weekday: 'long' });
            var index = result == 'Monday' ? 1 : result == 'Tuesday' ? 2 : result == 'Wednesday' ? 3 : result == 'Thursday' ? 4 : result == 'Friday' ? 5 : result == 'Saturday' ? 6 : result == 'Sunday' ? 7 : null;
            return index;
        }
    }

    onChange(value, mode, comboMode) {
        var cYear = new Date().getFullYear() + 1
        if (mode == 'year' && value <= cYear && value >= 2014) {
            this.setState({ mainActiveYear: value, hideChecked: true, toggleUpdated: true })
            this.props.onYearChange(value);
        }
        else if (mode == 'month') {
            if (comboMode == 'month') {
                this.setState({ mainActiveMonth: value, toggleUpdated: true });
                this.props.onMonthChange(value, this.state.mainActiveYear);
            }
            else if (comboMode == 'year' && value <= cYear && value >= 2014) {
                this.setState({ mainActiveYear: value, toggleUpdated: true });
                this.props.onMonthChange(this.state.mainActiveMonth, value);
            }
        }
        else if (mode == 'week') {
            //console.log('week', { value, mode, comboMode })
            if (comboMode == 'week') {
                this.setState({ mainActiveWeek: value, toggleUpdated: true });
                this.props.onWeekChange(value, this.state.mainActiveYear);
            }
            else if (comboMode == 'year' && value <= cYear && value >= 2014) {
                this.setState({ mainActiveYear: value, toggleUpdated: true });
                this.props.onWeekChange(this.state.mainActiveWeek, value);
            }

        }
        document.getElementById('activityContain').scrollTo(0, 0)
    }

    //Based on year and week number get the first monday of the week.
    firstDateOfWeek(y, weekNo) {
        var d = moment().day("Monday").year(y).week(weekNo).format("YYYY-MM-DD")
        return d
    }

    //for filling year combo values
    getYearValues() {
        var yearValues = [];
        var i;

        var currentYear = new Date().getFullYear() + 1;
        var yearsDiff = currentYear - 2013;

        for (i = 1; i <= yearsDiff; i++) {
            yearValues.push({ id: 2013 + i, name: 2013 + i });
        }

        return yearValues;
    }

    //for filling week combo values
    getWeekValues() {
        var weekValues = [];
        var i;

        for (i = 1; i <= 52; i++) {
            weekValues.push({ id: i, name: 'W' + i + ' ' + moment().day("Monday").week(i).format('DD/MM')});
        }

        return weekValues;
    }

    updateDropData(updateD) {
        var tempProps = this.props
        var tActData = tempProps.activityData.filter(x => x.calId != 9999)
        var updateIndex = 9999
        if (updateD.id) {
            updateIndex = tActData.findIndex(x => x.calId == updateD.id)
        }
        var dToUpdate = updateD.copy ? JSON.parse(JSON.stringify(tActData[updateIndex])) : tActData[updateIndex]
        if (dToUpdate == undefined) {
            updateIndex = 9999
            dToUpdate = { ...updateD }
            dToUpdate.activityDate = moment(updateD.date, 'DD/MM/YY')
        }
        var nTime = moment(updateD.date, 'DD/MM/YY').format('YYYY-MM-DD') + 'T' + moment(dToUpdate.activityDate).format('HH:mm:ss')
        dToUpdate.activityDate = nTime
        if (this.state.engineerSiteToggle == '1') {
            dToUpdate.deviceId = parseInt(updateD.deviceId)
        }
        else {
            dToUpdate.users = updateD.rowId ? [parseInt(updateD.rowId)] : updateD.users
        }
        if (updateD.copy) {
            var rInt = (dToUpdate.id + (9999 + Math.floor(Math.random() * 9999)))
            dToUpdate.id = rInt
            console.log('dToUpdate', dToUpdate)
            tActData.push(dToUpdate)
        }
        else {
            tActData[updateIndex] = dToUpdate
        }
        if (this.state.engineerSiteToggle == '1') {
            this.setSiteData({ activityData: tActData, mode: this.props.mode })
        }
        else if (this.state.engineerSiteToggle == '2') {
            this.setUserData({ activityData: tActData, mode: this.props.mode })
        }
    }

    onDrop(e, toDate, toRow) {
        var copy = this.state.ctrlPressed
        if ((e.addedIndex != null && e.removedIndex == null)) {
            console.log('onDrop', { e, toDate, toRow })
            var dropDate = moment().day("Monday").year(this.state.mainActiveYear).week(this.state.mainActiveWeek).add(toDate - 1, 'd').format('DD/MM/YY')
            if (this.props.mode == 'month') {
                var date = toDate.toString().padStart(2, 0) + '/' + (this.state.mainActiveMonth + 1).toString().padStart(2, 0) + '/' + this.state.mainActiveYear
                dropDate = moment(date, 'DD/MM/YYYY').format('DD/MM/YY')
            }
            var wcId = e.payload
            var actObj = { id: wcId, date: dropDate, rowId: toRow, copy, type: this.state.engineerSiteToggle }
            console.log('actObj', actObj)
            this.updateDropData(actObj)
            this.setState({ toggleUpdated: true })
            ApiPost('Calendar', 'WCDateUpdate', actObj)
            .then((data) => {
                this.refreshData()
            })
        }
    }

    refreshData() {
        this.props.mode == 'week' ? this.props.onWeekChange(this.state.mainActiveWeek, this.state.mainActiveYear) : this.props.mode == 'month' && this.props.onMonthChange(this.state.mainActiveMonth, this.state.mainActiveYear)
    }

    //called when toggling in between Sites and Users.
    siteUserToggle(e) {
        if (e == '1') {
            this.setState({ sites: this.props.stations, activityData: this.props.activityData, engineerSiteToggle: '1', toggleUpdated: true }, () => this.setSiteData(this.props))
        }
        else if (e == '2') {
            this.setState({engineerSiteToggle: '2' }, () => this.setUserData(this.props))
        }
    }

    addEvent(id, to, definedDate = null) {
        var dropDate = moment().day("Monday").year(this.state.mainActiveYear).week(this.state.mainActiveWeek).add(to - 1, 'd').format('DD/MM/YY')
        if (definedDate) {
            dropDate = definedDate
        }
        if (this.props.mode == 'month') {
            var date = to.toString().padStart(2, 0) + '/' + (this.state.mainActiveMonth + 1).toString().padStart(2, 0) + '/' + this.state.mainActiveYear
            dropDate = moment(date, 'DD/MM/YYYY').format('DD/MM/YY')
        }
        console.log('add event: ' + id, dropDate)
        if (this.state.engineerSiteToggle == '1') //SITES
        {
            this.updateDropData({ date: dropDate, calId: 9999, deviceId: parseInt(id), selectEvent: definedDate ? false : true, newEvent: definedDate ? false : true, users: null, preSelect: false, calEventType: 1, workcard: { engineerLogId: 9999 } })
        }
        else if (this.state.engineerSiteToggle == '2') //USERS
        {
            this.updateDropData({ date: dropDate, calId: 9999, deviceId: null, selectEvent: definedDate ? false : true, users: [id], preSelect: false, calEventType: 1, newEvent: definedDate ? true : false, workcard: { engineerLogId: 9999, engineers: [id] } })
        }
    }

    addNonEvent(id, to, definedDate = null) {
        var dropDate = moment().day("Monday").year(this.state.mainActiveYear).week(this.state.mainActiveWeek).add(to - 1, 'd').format('DD/MM/YY')
        if (definedDate) {
            dropDate = definedDate
        }
        if (this.props.mode == 'month') {
            var date = to.toString().padStart(2, 0) + '/' + (this.state.mainActiveMonth + 1).toString().padStart(2, 0) + '/' + this.state.mainActiveYear
            dropDate = moment(date, 'DD/MM/YYYY').format('DD/MM/YY')
        }
        this.updateDropData({ date: dropDate, calId: 9999, deviceId: null, selectEvent: definedDate ? false : true, users: [id], preSelect: false, workcard: { engineerLogId: 9999, engineers: [id] }, calEventType: 2, newEvent: definedDate ? true : false })
    }

    getNavBar() {
        return (
            <div>
                {
                this.props.mode == 'week' ?
                <div>
                    <div style={{ float: 'right'}}>
                        <Input id="MonToFri" defaultChecked={this.state.MonFrichecked} type="checkbox" onClick={(e) => this.onMonToFri(e)} /> <Label style={{ marginTop: '6px', width: '90px' }}><b>Mon-Fri</b></Label>
                        <Input id="HideEmpty" defaultChecked={this.state.hideChecked} type="checkbox" onClick={(e) => this.onHideEmpty(e)} /><Label style={{ marginTop: '6px', width: '110px' }}><b>Hide Empty</b></Label>
                        <Input defaultChecked={this.state.compactMode} type="checkbox" onClick={(e) => this.setState({compactMode: !this.state.compactMode})} /><Label style={{ marginTop: '6px', width: '90px' }}><b>Compact</b></Label>
                        <button className='btn' onClick={()=>this.setState({reorder:!this.state.reorder}, () => this.state.hideChecked && this.onHideEmpty())}><i title='Sort calendar rows' className='fas fa-sort-shapes-up' /></button>
                    </div>
                    <div className="row" style={{marginBottom: '5px'}}>
                        <ComboBox value={this.props.mainActiveWeek != null ? this.props.mainActiveWeek : this.state.mainActiveWeek} onClick={(v) => this.onChange(v, this.props.mode, 'week')} options={this.getWeekValues()} />
                        <ComboBox style={{}} value={this.props.mainActiveYear != null ? this.props.mainActiveYear : this.state.mainActiveYear} onClick={(v) => this.onChange(v, this.props.mode, 'year')} options={this.getYearValues()} />
                    </div>
                </div>
                :
                <div>
                    <div style={{ float: 'right' }}>
                        <Input id="HideEmpty" defaultChecked={this.state.hideChecked} type="checkbox" onClick={(e) => this.onHideEmpty(e)} /><Label style={{ marginTop: '6px', width: '90px' }}><b>Hide Empty</b></Label>
                    </div>
                    <div className="row">
                        <ComboBox value={this.props.mainActiveMonth != null ? this.props.mainActiveMonth : this.state.mainActiveMonth} onClick={(v) => this.onChange(v, this.props.mode, 'month')} options={this.monthValues} />
                        <ComboBox value={this.props.mainActiveYear != null ? this.props.mainActiveYear : this.state.mainActiveYear} onClick={(v) => this.onChange(v, this.props.mode, 'year')} options={this.getYearValues()} />
                    </div>
                </div>
                }
            </div>)
    }

    getCalHead() {
        var testYear = this.props.mainActiveYear != null ? this.props.mainActiveYear : this.state.mainActiveYear
        var testMonth = this.props.mainActiveMonth != null ? this.props.mainActiveMonth : this.state.mainActiveMonth
        var month = moment(testYear + '-' + (testMonth + 1), 'YYYY-MM').daysInMonth()
        var monthView = [];

        for (var i = 1; i <= month; i++) {
            monthView.push(i);
        }

        var weekdays = new Array(7);

        weekdays[1] = "Monday";
        weekdays[2] = "Tuesday";
        weekdays[3] = "Wednesday";
        weekdays[4] = "Thursday";
        weekdays[5] = "Friday";
        if (!this.state.MonFrichecked) {
            weekdays[6] = "Saturday"
            weekdays[7] = "Sunday"
        }

        return (
            <thead id="tblHead">
                <tr>
                    <th className='staticCol'>
                        <IdNameDropdown value={this.state.engineerSiteToggle} style={{ width: '100px' }} options={[{ id: 1, name: 'Sites' }, { id: 2, name: 'Users' }]} valueField="id" displayField="name" onChange={(event) => this.siteUserToggle(event)} />
                    </th>
                    {
                        this.props.mode == 'week' ?
                        weekdays.map((w, j) => {
                            var test = j - 1;
                            var startdate = this.firstDateOfWeek(this.state.mainActiveYear, this.state.mainActiveWeek);
                            var new_date = moment(startdate, "YYYY-MM-DD");
                            new_date.add(test, 'days');
                            return <th id={w} key={w} style={{ padding: '9px', minWidth: '120px', textAlign: 'center' }}>
                                <span>{w + " "}</span>
                                <span style={{ fontWeight: '400', fontSize: '12px' }}>{j == 1 ? (moment(startdate).endOf('day').format('DD-MM-YYYY')) : (moment(new_date).endOf('day').format('DD-MM-YYYY'))}</span>
                            </th>
                        })
                        :
                        monthView.map((w, j) => {
                            var test = j;
                            var startdate = new Date(this.state.mainActiveYear, this.state.mainActiveMonth, 1);
                            var new_date = moment(startdate, "YYYY-MM-DD");
                            new_date.add(test, 'days');
                            return <th id={w} key={w} style={{ padding: '9px', width: '200px', textAlign: 'center' }}>
                                <span style={{ fontWeight: '400', fontSize: '12px' }}><b>{moment(new_date).endOf('day').format('DD-MM-YYYY')}</b></span>
                            </th>
                        })
                    }
                </tr>
            </thead>
        )
    }

    getWidth() {
        var colWidth = 'auto'
        if (document.getElementById('activityContain')) {
            var totalWidth = document.getElementById('activityContain').offsetWidth - 20
            if (this.props.mode == 'week') {
                colWidth = totalWidth / (this.state.MonFrichecked ? 5 : 7)
                colWidth += 'px'
            }
            else if (this.props.mode == 'month') {
                colWidth = '200px'
            }
        }
        return colWidth
    }

    setRowOrder(targetId, targetIndex) {
        var tempRowOrder = this.state.rowOrder
        const targetObjectIndex = tempRowOrder.findIndex(obj => obj === targetId);
        const targetObject = tempRowOrder.splice(targetObjectIndex, 1)[0];
        tempRowOrder.splice(targetIndex, 0, targetObject);
        this.setState({ rowOrder: tempRowOrder }, () => this.props.onFilterChange('rowOrder', this.state.rowOrder))
    }

    getReorderTable(rows) {
        return (
            <Container onDrop={(e) => this.setRowOrder(parseInt(rows[e.removedIndex].id), (e.addedIndex))}>
                {
                    rows.map(x => {
                        return (<Draggable id={x.id} key={x.id} draggableId={x.id} className='calReorder'><i className="fal fa-ellipsis-v" /><i className="fal fa-ellipsis-v" /><span>{x.name}</span></Draggable>)
                    })
                }
            </Container>
        )
    }

    getStatus(id) {
        var colour = "#ccc", icon = "fas fa-question-circle", title = "Engineer hasn't clocked on"
        if (this.state.engineerSiteToggle == '2' && this.props.header.userActivityStatus) {
            if (this.props.header.userActivityStatus[id]) {
                if (this.props.header.userActivityStatus[id] == 1) {
                    colour = "#44AF69"
                    icon = "fas fa-location-circle"
                    title = "Engineer is currently active"
                }
                else {
                    colour = "#000"
                    icon = "fas fa-check-circle"
                    title = "Engineer has clocked off"
                }
            }
            return <i className={icon} title={title} style={{ color: colour, marginLeft:'5px' }} />
        }
    }

    render() {
        const { mode, history, eventTypes, stations, users, workStatuses, jobList, calEventTypes, customerWithSites } = this.props;

        var resultDictionary = this.state.engineerSiteToggle == '1' ? this.state.siteDictionary : this.state.userDictionary
        var colTitles = this.state.engineerSiteToggle == '1' ? stations : users
        var rows = []
        var unscheduledData = this.props.activityData ? this.props.activityData.filter(x => x.fsrStatus == 0) : []
        unscheduledData = unscheduledData.map(x => ({ ...x, activityDate: x.fsrStatus == 1 && null }))
        Object.keys(resultDictionary).map(r => {
            rows.push({ id: r, name: colTitles.find(x => x.id == r) ? colTitles.find(x => x.id == r).name : '', orderId: this.state.rowOrder.findIndex(x=> x == r) })
        })
        rows = rows.sort((a, b) => (a.orderId > b.orderId) ? 1 : ((b.orderId > a.orderId) ? -1 : 0))
        return (
            <div style={{ position: 'relative', overflow: 'hidden' }}>
                {this.state.ctrlPressed && <div style={{ width: '100%', textAlign:'center', position:'absolute' }}><b style={{ color: '#d0021b', fontSize:'20px' }}>COPY MODE</b></div> }
                {this.getNavBar()}
                <Row>
                    <Col sm={10} style={{ paddingRight:'5px' }}>
                    {this.state.reorder ?
                        this.getReorderTable(rows)
                        :
                        <div id='activityContain' className='activityContain'>
                            <Table striped bordered className="yearTable" id="calendarTable">
                                {this.getCalHead()}
                                <tbody onScroll={(e) => (typeof this.onScroll === "function") && this.onScroll(e)} id="tblBody" >
                                    {
                                        (resultDictionary && Object.keys(resultDictionary).length > 0) ?
                                            (
                                                rows.map((k, i) => {
                                                    var temp = resultDictionary[k.id];
                                                    delete temp[0];
                                                    return (
                                                        <tr key={k.id} id={k.id}>
                                                            <td className='staticCol'>{k.name}{this.getStatus(parseInt(k.id))}</td>
                                                            {
                                                                temp.map((a, l) => {
                                                                    if (mode == 'week' && l > 7) {
                                                                        return null
                                                                    }
                                                                    return (
                                                                        <td key={'td' + k.id + l} className='dropContainer' style={{ padding: '1px', minWidth: (mode == 'year' ? '66px' : '120px'), height: (this.state.compactMode ? '50px' : '100px') }} >
                                                                            <Container key={k.id + l} behaviour={this.state.ctrlPressed ? 'copy' : 'move'} dropPlaceholder={{ className: 'drop-contain' }} getChildPayload={(i) => a[i].calId} groupName={'calGroup'} onDrop={(e) => this.onDrop(e, l, k.id)} lockAxis={this.state.engineerSiteToggle != '1' ? null : 'x'} style={{ height: '100%', width: this.getWidth() }} shouldAcceptDrop={() => { return true }} >
                                                                                {a.map(wD => {
                                                                                    if (wD.deviceId != 9999999 && wD && wD.fsrStatus != 0) {
                                                                                        return (
                                                                                            <Draggable id={wD.calId} key={wD.calId}>
                                                                                                <div className='cardGrabber'><i className="fal fa-ellipsis-v" style={{ margin: '0 2px 0 20px' }} /><i className="fal fa-ellipsis-v" /></div>
                                                                                                <ActCards
                                                                                                    key={'ac' + wD.calId}
                                                                                                    colId={k.id}
                                                                                                    activityData={[wD]}
                                                                                                    isCompact={true}
                                                                                                    compactMode={this.state.compactMode}
                                                                                                    history={history}
                                                                                                    siteToggle={this.state.engineerSiteToggle}
                                                                                                    eventTypes={eventTypes}
                                                                                                    workStatuses={workStatuses}
                                                                                                    stations={stations}
                                                                                                    users={users}
                                                                                                    jobList={jobList}
                                                                                                    calEventTypes={calEventTypes}
                                                                                                    saveEvent={(e) => this.props.saveEvent(e)}
                                                                                                    removeEvent={(e, u) => this.props.removeEvent(e, u)}
                                                                                                    customerWithSites={customerWithSites}
                                                                                                    preRec={this.state.preRec} />
                                                                                            </Draggable>)
                                                                                    }
                                                                                })}
                                                                                <div className='calAdd' onClick={() => this.addEvent(parseInt(k.id), l)}><i className="fas fa-user-hard-hat" /><i className="fas fa-plus" /></div>
                                                                                <div className='calAdd' style={{ left: '2px', right: 'unset' }} onClick={() => this.addNonEvent(parseInt(k.id), l)}><i className="fas fa-calendar-times" /><i className="fas fa-plus" /></div>
                                                                            </Container>
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                }
                                                )
                                            )
                                            : <tr><td colSpan='31' style={{ textAlign: 'center', width: '100%' }}>No Data</td></tr>
                                    }
                                </tbody>
                            </Table>
                    </div>}
                    </Col>
                    <Col sm={2} style={{ paddingLeft: 0 }}>
                        <ActivityScheduler data={unscheduledData} {...this.props} refreshData={() => this.refreshData()} addEvent={() => this.addEvent()} addNonEvent={() => this.addNonEvent()} removeEvent={(e, u) => this.props.removeEvent(e, u)} />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(mapStateToProps, null, null, { withRef: true })(ActivityCalendar)