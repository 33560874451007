import React, { Component } from 'react'
import { ApiGet, ApiPost } from '../../../api'
import { WithContext as ReactTags } from 'react-tag-input'
import Alert from 'react-s-alert'

class TagMgmt extends Component {   

    constructor(props) {
        super(props);
        this.state = { Success: false }
    }     

    componentDidMount() {
        this.getFilterVals()
    }

    handleTagDelete(i, groupId) {
        const tags = this.state.tags.filter(x => x.group == groupId)
        var tag = tags[i]
        ApiPost('Metering', 'RemoveTag', { tagId: tag.id })
        .then((t) => {
            Alert.info('Tag "' + tag.text + '" deleted')
            this.getFilterVals()
        })
    }

    createNewTag(tag, groupId) {
        ApiPost('Metering', 'CreateNewTag', { tagTitle: tag.id, groupId })
        .then((data) => {
            var tTags = [...this.state.tags, { id: data.toString(), text: tag.id }]
            this.setState({ tags: tTags }, () => this.getFilterVals())
            Alert.info('Tag "' + tag.id + '" added')
        })
    }

    getFilterVals() {
        ApiGet('Dashboard', 'GetWidgetListVals', { widgetId: 9999, getParams: true })
        .then((data) => {
            var tags = data['filter']
            var tagGroups = tags.filter(x => x.group == null)
            this.setState({ tags, tagGroups }, () => console.log('this.',this.state.tags))
        })
    }
 

    render() {        

        const KeyCodes = { comma: 188, enter: 13, }
        const delimiters = [KeyCodes.comma, KeyCodes.enter]

        return (
            <div>
                <div className='titleBlock'>Tag Management</div>
                {this.state.tagGroups &&
                <div>
                    <div className='tagMgmt'>
                        <h5>Tag Groups</h5>
                        <ReactTags tags={this.state.tags.filter(x => x.group == null)} suggestions={[]} placeholder='Type here and press return to create a tag group' allowDragDrop={false} inputFieldPosition='top' handleDelete={(i) => this.handleTagDelete(i, null)} handleAddition={(tag) => this.createNewTag(tag, null)} delimiters={delimiters} />
                    </div>
                    <div className='subTagGroups'>
                        {
                           this.state.tagGroups.map(t => {
                                return (
                                    <div className='tagMgmt' key={t.id}>
                                        <h5>{t.text}</h5>
                                        <ReactTags tags={this.state.tags.filter(x => x.group == t.id)} suggestions={[]} placeholder='Type here and press return to create a tag' allowDragDrop={false} inputFieldPosition='top' handleDelete={(i) => this.handleTagDelete(i, t.id)} handleAddition={(tag) => this.createNewTag(tag, t.id)} delimiters={delimiters} />
                                    </div>
                                    )
                            })
                        }
                    </div>
                </div>
                }
            </div>)
    }
}
export default (TagMgmt)