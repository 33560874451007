import React, { Component } from 'react';
import { ApiGet, ApiPost } from '../../../api';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Table, Row } from 'reactstrap';

class ParameterTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onlyDiff: false
        };
    }

    renderHeaders(data, vals, sites) {
        var valsLoaded
        return (
            <tr key={this.props.sectionNo}>
                <th colSpan='2'>{this.props.sectionName}</th>
                {sites ? sites.map((x,i) => {
                    if (vals) {
                        if (vals["site"+ x.value]) {
                            valsLoaded = Object.keys(vals["site" + x.value]).length
                        } else {
                            valsLoaded = -2;
                        }
                    } else {
                        valsLoaded = -1;
                    }
                    return <th key={i}>{x.label} {this.getConnectionIcon(valsLoaded, x.value)}</th>
                }) : null}
                
            </tr>)
    }

    getConnectionIcon(state, siteId) {
        var ret = null;
        if (state < 0) {
            ret = <i className="fa fa-spinner fa-spin"></i>
        }
        else if (state == 0) {
            ret = <button className="btn btn-default" style={{padding:'.2rem'}} onClick={() => this.props.fnReloadSite(siteId)}><i className="fa fa-refresh"></i></button>
        }
        return ret;
    }

    getHeaderName(id) {
        return this.props.header.assets.find(x => x.id == id).name;
    }

    getVal(paramId, vals, unit, scale) {
        var valCols = [];
        const sites = this.props.sites
        var valsLoaded;
        if (vals) {
            var siteId = 0;
            var tempD = [];
            if (scale == undefined || scale == null) {
                scale = 1;
            }
            {sites ? sites.map((x,i) => {
                siteId = x.value // site
                if (vals) {
                    if (vals["site"+ siteId.toString()]) {
                        valsLoaded = Object.keys(vals["site"+ siteId]).length
                    } else {
                        valsLoaded = -2;
                    }
                } else {
                    valsLoaded = -1;
                }
                if (valsLoaded > 0) {
                    var y = vals["site"+siteId.toString()];
                    var tData = (y && y[paramId] != undefined)? y[paramId] * scale : '-';
                    tempD.push(tData);
                    valCols.push(<td key={i}>{tData + ' ' + unit}</td>);
                } else {
                    tempD.push(null);
                    valCols.push(<td key={i}>-</td>);
                }
            }) : null}




            var hideable = false;
            if (tempD.length > 1) {
                hideable = tempD.every((val, i, arr) => val === arr[0])

                if (this.props.onlyDiff && hideable) {
                    valCols = null
                }
            }
            else {
                hideable = true;
            }
        }

        var rD = { valCols: valCols, hideable: hideable };

        return rD;
    }

	getRow(x, vals) {

        var rD = this.getVal(x.controllerParamId, vals, x.unit, x.scale);
        if (rD.valCols) {
            return <tr key={x.address}><td style={{ width: '5%' }}>{x.address}</td><td style={{ width: '30%' }}>{x.paramName} {!rD.hideable && rD.hideable != undefined ? <i className="fa fa-exclamation-triangle warning" /> : null}</td>{rD.valCols}</tr>
        }
        else {
            return null
        }
    }

    renderBody(data, vals) {
        if (data) {
            return (
                <tbody>
                    {data.map(x => {
                        return this.getRow(x, vals)
                    })}
                </tbody>
            )
        }
    }

    render() {

        var data = this.props.paramData;
        var vals = this.props.paramValues;
        const sites = this.props.sites;
        return (
            <div>
                <Table striped responsive bordered hover className="analysisTable">
                    <thead>
                        {this.renderHeaders(data, vals, sites)}
                    </thead>
                    {this.renderBody(data, vals)}
                </Table>
            </div>
        );
    }

};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(ParameterTable)
