import React, { Component } from 'react'
import Highcharts from 'highcharts/highcharts-gantt'
import HighchartsCustomEvents  from 'highcharts-custom-events'
import moment from 'moment'
HighchartsCustomEvents(Highcharts)

class IncidentChart extends Component {
    constructor(props) {
        super(props);
        this.state = { plotlineActive: false, filtered: false }
    }

    componentDidMount() {
        this.createChart()
    }

    componentDidUpdate(prevProps) {
        if (this.props.chartData != prevProps.chartData) {
            this.createChart()
        }
    }

    createChart() {
        var setPlotLineFunctions = (chart) => {
            this.setPlotLineFunctions(chart);
        }

        if (this.chart != null) {
            this.chart.destroy();
        }

        var max = this.props.cats.length > 15 ? 15 : this.props.cats.length - 1

        var offset = new Date().getTimezoneOffset()
        this.chart = new Highcharts["Chart"](
        this.refs.chart, {
            chart: {
                type: 'xrange',
                events: {
                    click: (e) => {
                        this.plotJump(e)
                    }
                },
                marginLeft: 150
            },
            time: {
                    timezoneOffset: offset
            },
            plotOptions: {
                series: {
                    turboThreshold: 2000
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                plotLines: [{ color: '#313131', width: 4, value: 0, zIndex: 5 }],
                min: new Date(this.props.fromDate).getTime(),
                max: new Date(this.props.toDate).getTime(),
            },
            yAxis: {
                labels: {
                    events: {
                        click: (e) => this.filterGroup(e)
                    }
                },
                title: {
                    text: ''
                },
                categories: this.props.cats,
                reversed: true,
                min: 0, 
                max: max,
                scrollbar: {
                    enabled: max > 14 ? true : false,
                }
            },
            legend: {
                enabled: false
                },
            credits: {
                enabled: false
            }, 
            series: [{
                data: this.props.chartData,
                dataLabels: {
                    enabled: true,

                }
            }]
        },
        function (chart) {
            setPlotLineFunctions(chart);
        })
        this.chart.setSize(null)
        window.myChart = this.chart
    }

    filterGroup(e) {
        this.props.setGroupRef(this.state.filtered ? null : e.target.innerHTML)
        this.props.setGroupRef(this.state.filtered ? null : e.target.innerHTML)
        this.setState({ filtered: !this.state.filtered })
    }

    restartPlot(chart) {
        chart.xAxis[0].options.plotLines[0].value = chart.xAxis[0].min
        chart.xAxis[0].update()
    }

    plotJump(e) {
        var plotLine = this.chart.xAxis[0].plotLinesAndBands[0].svgElem.translateX
        var diff = (this.chart.mouseDownX - this.chart.plotLeft) - plotLine
        var abDiff = Math.abs(diff)
        if (diff < 0) {
            this.playPlotline(true, abDiff)
        }
        else {
            this.playPlotline(false, abDiff)
        }
    }

    setPlotLineFunctions(chart) {
        var line, clickX

        this.restartPlot(chart)

        var getTimeData = (origX) => {
            this.props.setPlotTime(origX)
        }

        var start = function (e) {
            document.addEventListener('mousemove', step)
            document.addEventListener('mouseup', stop)
            chart.xAxis[0].plotLinesAndBands[0].svgElem.css({ 'cursor': '-webkit-grabbing' })
            clickX = e.pageX - line.translateX;
        }

        if (chart.xAxis[0].plotLinesAndBands[0]) {
            line = chart.xAxis[0].plotLinesAndBands[0].svgElem.css({ 'cursor': '-webkit-grab' }).translate(0, 0).on('mousedown', start)
        }

        var step = function (e) {
            if ((e.pageX - clickX) < chart.xAxis[0].width && (e.pageX - clickX) > 0) {
                line.translate(e.pageX - clickX);
                var axis = chart.xAxis[0];
                var plotLine = axis.plotLinesAndBands[0];
                var translation = plotLine.svgElem.translateX;

                var origX = axis.toValue(translation) - axis.toValue(0) + plotLine.options.value;
                origX = Math.round(Math.max(axis.min, Math.min(axis.max, origX)));
                getTimeData(origX)
            }
        }

        var stop = function (e) {
            chart.xAxis[0].plotLinesAndBands[0].svgElem.css({ 'cursor': '-webkit-grab' });
            document.removeEventListener('mousemove', step)
            document.removeEventListener('mouseup', stop)
        }
    }

    playPlotline(rewind, val = 0.4) {
        if (this.chart.xAxis[0].plotLinesAndBands[0]) {
            var axis = this.chart.xAxis[0]
            var plotLine = axis.plotLinesAndBands[0].svgElem

            if (isNaN(plotLine.translateX)) {
                plotLine.translate(0)
            }

            if ((plotLine.translateX > (this.chart.plotSizeX - 10))) {
                this.togglePlayback()
                clearInterval(this.plotlineTimer)
                plotLine.translate(0)
            }
            else {
                if (rewind) {
                    plotLine.translate(plotLine.translateX - val)
                }
                else {
                    plotLine.translate(plotLine.translateX + val)
                }

                var newPlotLine = axis.plotLinesAndBands[0]
                var translation = newPlotLine.svgElem.translateX
                var origX = axis.toValue(translation) - axis.toValue(0) + newPlotLine.options.value
                origX = Math.round(Math.max(axis.min, Math.min(axis.max, origX)))

                this.props.setPlotTime(origX)
            }
        }
    }

    timer() {
        this.playPlotline()
    }

    resetPlotline() {
        var plotLine = this.chart.xAxis[0].plotLinesAndBands[0].svgElem
        plotLine.translate(0)
    }

    togglePlayback() {
        if (this.state.plotlineActive) {
            clearInterval(this.plotlineTimer);
        }
        else {
            this.plotlineTimer = setInterval(this.timer.bind(this), 50);
        }
        this.setState({ plotlineActive: !this.state.plotlineActive })
    }


    render() {

        return (
            <div>
                <div ref="chart" className="scada-chart" style={{ height: '300px' }} />
                <div className='favMetricRow'>
                    <div className="fav-badge time-badge"><span style={{ lineHeight: '38px' }}>{moment(this.props.plotTime).format('DD/MM/YY HH:mm:ss')}</span></div>
                    <button type="button" className="btn ch-ctrl" style={{height:'38px'}} onClick={() => this.togglePlayback()}>{this.state.plotlineActive ? <i className="fa fa-pause" /> : <i className="fa fa-play" />}</button>
                </div>
            </div>
        )
        
    }  
}

export default (IncidentChart)
