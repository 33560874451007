//import { SERVER_PATH } from '../configuration/AppConfig';
import { universalMetrics, industryDefaultMetrics } from '../configuration/enums'
import debounce from 'debounce-promise'
import {JSONPath} from 'jsonpath-plus';
export const SERVER_PATH = process.env.SERVER_PATH;
const DEBUG_MODE = process.env.DEBUG_MODE;
const COMM_PATH = process.env.COMM_PATH;

export const API_PATH = SERVER_PATH + '/api'
export const CRUD_PATH = SERVER_PATH + '/crud'

// var formData = new FormData();
// data.append("filePath", cFilename);
export const FileRequest = (moduleName, funcName, cFilename, paramObject, onReadFn) => {
    var fdata = new FormData();          
    for (var key in paramObject) {
        fdata.append(key, paramObject[key]);
    }
    fdata.append("filePath", cFilename);
    fetch(SERVER_PATH + '/' + moduleName + "/" + funcName, { method: "POST", body: fdata, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }})
        .then(function (response) {
            return response.blob();
        }).then((data) => {
            downloadFile(data, cFilename);
            if (onReadFn) {
                onReadFn(cFilename);
            }
        });        
}

const downloadFile = (fileData, fileName) => {     
    var a = window.document.createElement("a");
    var data = new Blob([fileData], { type: "application/octet-stream" });
    a.href = window.URL.createObjectURL(data);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const ApiGet = (moduleName, funcName, params) => {
    const queryParams = new URLSearchParams(params).toString();
    return fetch(API_PATH + '/' + moduleName + "/" + funcName + (queryParams && ('?' + queryParams)), { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
        .then(function(response) {
            // redirect to login screen if user is logged out
            if (funcName == 'GetLiveState' && response.status == 302) {
                window.location = "/"
            }
            return response.json();
            })
        .then((data) => {
                return data.data;
            });
}

export const CrudGet = (moduleName, funcName) => {
    return fetch(CRUD_PATH + '/' + moduleName + "/" + funcName, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
    .then(function (response) {
        // redirect to login screen if user is logged out
        if (funcName == 'GetLiveState' && response.status == 302) {
            window.location = "/"
        }
        return response.json();
    })
    .then((data) => {
        return data;
    });
}

export const ApiPost = (moduleName, funcName, dataObj) => {
    var data = new FormData();
    for ( var key in dataObj ) {
        data.append(key, dataObj[key]);
    }
    console.log('apipost',data)
    return fetch(API_PATH + '/' + moduleName + "/" + funcName, { method: "POST", body: data, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'), 'X-Model': funcName } } )
    .then(function (response) {     
        if (response.redirected || response.status == 401) {
            //window.location = '/Login'
        }
        if (response.status == 200) {
            return response.json();
        }
        else {
            console.log('invalid response', moduleName, funcName, response)
            return { data: null }
        }
    }).then((data) => {
        return data.data?data.data:data;
    });
}

export const ApiFilePost64 = async (moduleName, funcName, dataObj) => {
    let data = {};
    for (var key in dataObj) {
        if (key != 'files') {
            data[key] = dataObj[key];
        }
    }

    if (dataObj && dataObj.files) {
        var files = dataObj.files;
        console.log('files', files);
        data['files'] = [];
        data['fileNames'] = [];

        for (var i = 0; i < files.length; i++) {
            let base64String = await getFileAsBase64(files[i]);
            data['files'].push(base64String);
            data['fileNames'].push(files[i].name);
        }
    }

    console.log('data', data)

    return fetch(API_PATH + '/' + moduleName + "/" + funcName, { method: "POST", body: JSON.stringify(data), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
        .then(function (response) {
            return response.json();
        }).then((data) => {
            return data.data ? data.data : data;
        });
}

const getFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const ApiFilePost = (moduleName, funcName, dataObj) => {
    var data = new FormData();
    for (var key in dataObj) {
        data.append(key, dataObj[key]);
    }
    if (dataObj && dataObj.files) {
        var files = dataObj.files
        for (var i = 0; i < files.length; i++) {
            data.append('files', files[i], files[i].name)
        }
    }
    return fetch(API_PATH + '/' + moduleName + "/" + funcName, { method: "POST", body: data, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
    .then(function (response) {
        if (response.redirected || response.status == 401) {
            //window.location = '/Login'
        }
        return response.json();
    }).then((data) => {
        return data.data ? data.data : data;
    });
}

export const CommApiGet = (moduleName, funcName) => {
    return fetch(COMM_PATH + '/api' + '/' + moduleName + "/" + funcName, { credentials: 'include' })
    .then(function (response) {
        return response.json();
    })
        .then((data) => {
        return data;
    });
}

export const CommApiPost = (moduleName, funcName, dataObj) => {
    var data = new URLSearchParams();
    for ( var key in dataObj ) {
        data.append(key, dataObj[key]);
    }
    return fetch(COMM_PATH + '/api' + '/' + moduleName + (funcName.length > 0?"/":"") + funcName, { method: "POST", contentType: "application/x-www-form-urlencoded", body: data, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken')} })
    .then(function (response) {   
        return response.json();
    }).then((data) => {
        return data;
    });
}

export const GetSessionToken = (shortFormat) => {
    var ret = localStorage.getItem('jwtToken');
    if (shortFormat) {
        var split = ret.split(".");
        ret = split[split.length-1];
    }
    return ret;
}

export const ApiJsonPost = (moduleName, funcName, dataObj, debounceMs) => {
    var data = JSON.stringify(dataObj)
    const fetchFn = fetch(API_PATH + '/' + moduleName + "/" + funcName, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'), 'Content-Type': 'application/json' }, method: "POST", body: data, credentials: 'include' })
    .then(function (response) {
        return response.json();
    }).then((data) => {
        return data.data;
    });
    if (debounceMs > 0) {
        return debounce(e => {
            console.log('Debounced Event:', e);
            fetchFn();
          }, debounceMs);
    } else {
        return fetchFn
    }
}
const getLiveU = (scada, stationId) => {
    //console.log("Getting " + stationId + " from live", scada.assetsLiveU.get(stationId.toString()))
    var Umap = scada.assetsLiveU.get(stationId);
    var ret = null;
    if (Umap) {
        if (Umap.toJS) {
            ret = Umap.toJS();
        } else {
            ret = Umap;
        }
    } else {
        console.log("Unable to get station " + stationId + " from live store");
    }
    return ret;
}
export const getLive = (scada, stationId) => {
    //console.log("Getting " + stationId + " tags from live", scada.assetsLive.get(stationId))
    var metmap = scada.assetsLive.get(stationId);
    var ret = null;
    if (metmap) {
        if (metmap.toJS) {
            ret = metmap.toJS();
        } else {
            ret = metmap;
        }
    } else {
        console.log("Unable to get station " + stationId + " from live store");
    }
    return ret;
}

export const GetStationsState = (stateRoot) => {
    return GetStationsStateC(stateRoot.common);
}
// Get Sites stats grouped by industry
export const GetStationsStateIndustry = (stateRootcommon, asDictionary = false) => {
    var ret = {};
    var sites = GetStationsStateC(stateRootcommon)
    for (var sid in sites) {
        const s = sites[sid]
        if (ret[s.industryId]) {
            ret[s.industryId]
        } else {
            const indname = stateRootcommon.lookups.industries[s.industryId]
            ret[s.industryId] = {id: s.industryId, name: indname, items:(asDictionary?{}:[])}
        }
        if (asDictionary) {
            ret[s.industryId].items[s.id] = s;
        } else {
            ret[s.industryId].items.push(s);
        }
    }
    return ret;
}

const getMetricDetails = (metrics, o) => {
    const ind = o.industryId
    var ret = {}
    for (var k in industryDefaultMetrics.views[ind].metrics) {
        var mr = industryDefaultMetrics.renderDetails[k]
        getMetricDetail(ret,o,metrics,mr)
    }
    return ret
}
const getMetricDetail = (dict, o,metrics, id) => {
    const ind = o.industryId
    const info = industryDefaultMetrics.renderDetails[id]
    const value = metrics[id]
    if (info && value != undefined) {
        var max = info.max
        if (!Number.isInteger(info.max)) {
            max = o[info.max]
        }
        var ret = {
            v: value.toFixed(info.decimalPlaces),
            max: max,
            rend: info.renderer
        }
        dict[id] = ret;
    } 
}

export const GetStationsStateC = (stateRootcommon, asDictionary = false) => {
    const { header, scada } = stateRootcommon;
    const { livesiteids = [] } = scada;
    var ret = asDictionary ? {} : [];

    header.assets.map((o, i) => {
        let stationId = o.id;
        let ws = 0;
        let pw = 0;
        
        const metrics = livesiteids.find(x => x == stationId) ?
            scada.assetsLiveU ? getLiveU(scada, stationId) : null :
            header.siteMetric ? header.siteMetric[stationId] : null
        if (metrics) {
            ws = metrics[universalMetrics.WindSpeed];
            if (!ws) ws = 0;
            pw = metrics[universalMetrics.RocPower];
            if (!pw) pw = 0;
        }

        o['ws'] = ws.toFixed(0);
        o['pw'] = pw.toFixed(0);
        o['precentagePw'] = ((pw / o['maxkw']) * 100) + "%";
        o['precentageWs'] = (ws)?
            ((ws / 20) * 100) + "%"
            : '0';
        o['stale'] = header.stale && header.stale.includes && header.stale.includes(stationId) ? true : false;
        const alerts = header.alerts ? header.alerts[stationId] : [];
        if (asDictionary) {
            if (!ret[o.id]) {
                ret[o.id] = { ...o, metrics, alerts };
            } 
        } else {
            ret.push({ ...o, metrics, alerts });
        }
    })

    //Object.keys(header.childDevices).map(cd => {
    //    header.childDevices[cd].map(c => {
    //        const metrics = livesiteids.find(x => x == c.id) ?
    //            scada.assetsLiveU ? getLiveU(scada, c.id) : null :
    //            header.siteMetric ? header.siteMetric[c.id] : null

    //        console.log(c.id, metrics)
    //    })
    //})

    ret.map(x => {
        let stationId = x.id
        x['childDevices'] = []
        if (header.childDevices && header.childDevices[stationId]) {
            var tDevices = []
            header.childDevices[stationId].map(c => {
                var dev = c
                const metrics = livesiteids.find(x => x == c.id) ?
                    scada.assetsLiveU ? getLiveU(scada, c.id) : null :
                    header.siteMetric ? header.siteMetric[c.id] : null
                tDevices.push({ ...dev, metrics })
            })
            x['childDevices'] = tDevices
        }
    })
    return ret;
}
const getCmdState = (scada, stationId) => {
    var tCmdState = []
    scada.cmdState[stationId].map(x => {
        tCmdState[x[0]] = x[1]
    })
    return tCmdState
}
export const GetStationLiveState = (common, stationId) => {
    const { header, scada } = common;
    const { livesiteids = [] } = scada;
    var ret = [];
    const metrics = (livesiteids.find(x => x == stationId))?
    (scada.assetsLive?getLive(scada,stationId):null):
    null;

    const cmdState = scada.cmdState && scada.cmdState[stationId] ? getCmdState(scada, stationId) : null
    const alerts = header.alerts ? header.alerts[stationId] : [];
    const stationdet = header.assets.find(x => x.id == stationId);

    return { ...stationdet, metrics, alerts, cmdState };
}

// returns array of results. Normally use getConfigItem
export const getConfigItems = (path) => {
    // store config in memory object or get from localstorage
    var json = {};
    if (window.userConfig) {
        json = window.userConfig
    } else {
        const jsonstring = localStorage.getItem('userConfig')
        if (jsonstring) {
            json = JSON.parse(jsonstring)
        }
        window.userConfig = json
    }
    const result = JSONPath({path, json});
    return result;
}
// path is a jsonpath e.g. getConfigItem('$.operations.eventTypes')
export const getConfigItem = (path) => {
    const result = getConfigItems(path);
    return result && result.length > 0?result[0]:null;
}

