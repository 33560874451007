import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import Collapsible from 'react-collapsible'
import ScadaBullet from './ScadaBullet'
import ScadaDashlight from './ScadaDashlight'
import { Badge} from 'reactstrap'
import ConfirmBtn from '../../../components/Controls/ConfirmButton'
import OptionBtn from '../../../components/Controls/OptionButton'
import MonitoringTileDynamic from '../../Monitoring/components/MonitoringTileDynamic'
import { scadaSendCommand } from '../../../actions/scada';
import { getConfigItem } from '../../../api'
import { withRouter } from 'react-router-dom'
import { deviceDefaultMetrics } from '../../../configuration/enums'
import MonitoringTileDevices from '../../Monitoring/components/MonitoringTileDevices'
import { ApiPost } from '../../../api'
import SimpleModal from '../../../components/SimpleModal'
import DerivedMetric from '../../../scenes/DerivedMetric/components/DerivedMetric'

class ScadaCollapsibles extends Component {

    constructor(props) {
        super(props);
        var sHL = getConfigItem('$.monitoring.scadaHeadliners')
        var addMetric = true
        this.state = { maxMinMetrics: [], editableMetrics: null, savingPending: false, saveSuccessful: false, saveFault: false, sHL, sDevice: null, addMetric, addMetricModal: false};
    }

    componentDidMount() {
        this.setState({ sDevice: this.props.stationId })
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isLive != this.props.isLive) {
            this.setState({ maxMinMetrics: [], editableMetrics: null });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.cmdState && (this.props.cmdState != prevProps.cmdState)) {
            if (this.props.cmdState.length > 0) {
                var cmdStatus = this.props.cmdState[this.state.editableMetrics]
                if (cmdStatus == 1) {
                    this.setState({ savePending: false, saveSuccessful: true, saveFault: false })
                }
                else if (cmdStatus = 2) {
                    this.setState({ savePending: false, saveSuccessful: false, saveFault: true })
                }
            }
            else {
                //this.setState({ savePending: false, saveSuccessful: false, saveFault: false, editableMetrics:null })
            }
        }
    }

    getHeadliners(tempGroupObj, title) {

        var rend = []
        rend.push(<Col key={'t' + title} xs={3} sm={2} style={{ padding: '0 15px' }}>{title}</Col>)
        var i = 0
        var headCount = window.screen.width > 576 ? 3 : 2

        var group = tempGroupObj[0].group
        

        if (this.state.sHL && this.state.sHL[group]) {
            var hLers = this.state.sHL[group]
            hLers.map((x, j) => {
                if (j < headCount) {
                    var temm = tempGroupObj.find(u => u.metricId === x)
                    if (temm) {
                        rend.push(this.getScadaRow(temm, true, this.state.sDevice))
                    }
                }
            })

        }
        else {
            while (i < headCount) {
                var t = tempGroupObj[i]
                if (t) {
                    rend.push(this.getScadaRow(t, true, this.state.sDevice))
                }
                i++
            }
        }
        return <Row style={{marginRight:'5px'}}>{rend}</Row>
    }

    renderScadaCollapsibles() {
  
        if (this.props.scadaGroups && this.props.scadaGroups[this.state.sDevice]) {

            var fullGroup = this.props.scadaGroups[this.state.sDevice]

            fullGroup = fullGroup.sort(function (a, b) {
                if (a.group < b.group) return -1;
                if (a.group > b.group) return 1;
                if (a.metricId < b.metricId) return -1;
                if (a.metricId > b.metricId) return 1;
                return 0;
            });

            var tempTopGroup = 999;
            var tempMetricCount = 0;
            var tempGroupObj = [];
            let collapsibleRender = [];

            fullGroup.map(i => {
                if ((Math.floor(i.group / 10) * 10) != tempTopGroup) {
                    tempTopGroup = Math.floor(i.group / 10) * 10
                    tempMetricCount = 0;
                    tempGroupObj = [];

                    fullGroup.map(j => {
                        if ((Math.floor(j.group / 10) * 10) == tempTopGroup) {
                            if (this.props.editMode) {
                                tempGroupObj.push(j);
                                tempMetricCount++;
                            }
                            else if (!j.hideMetric && !this.props.editMode){
                                tempGroupObj.push(j);
                                tempMetricCount++;
                            }
                        }
                    })

                    if (i.group == 120) {
                        //INPUT/OUTPUT TRIPLE
                        collapsibleRender.push( 
                            <Collapsible trigger={i.groupTitle} key={i.groupTitle} transitionTime={200} open={false} >
                                <div className="row scada-container">
                                    {tempGroupObj.map((m) => {
                                        var metricConfig = JSON.parse(m.config)
                                        if (metricConfig && metricConfig.statusBits) {

                                            var tVal = this.props.getScadaValue(m.metricId, 0, this.state.sDevice)
                                            var bin = ("0".repeat(16) + (tVal >>> 0).toString(2))
                                            bin = bin.substr(bin.length - 16, 16)
                                            var splitLength = Math.ceil(metricConfig.statusBits.length / 3)
                                            var tables= []

                                            if (splitLength > 5) {
                                                for (var i = 0; i < 3; i++) {
                                                    var from = i * splitLength, to = (i + 1) * splitLength
                                                    tables.push(this.renderDINTable(m, bin, metricConfig.statusBits, from, to))
                                                }
                                            }
                                            else {
                                                tables.push(this.renderDINTable(m, bin, metricConfig.statusBits, 0, metricConfig.statusBits.length))
                                            }

                                            return tables
                                        }
                                        else {
                                            //console.log('metric ' + m.metricId + ' does not contain status bits')
                                        }
                                    })
                                    }
                                </div>
                            </Collapsible>)
                    }
                    else if (i.group != 9999) {
                        collapsibleRender.push(
                            <Collapsible trigger={this.getHeadliners(tempGroupObj, i.groupTitle == '' ? 'Misc' : i.groupTitle)} triggerWhenOpen={i.groupTitle == '' ? 'Misc' : i.groupTitle} key={i.groupTitle} transitionTime={200} open={false}>
                                <div className="row scada-container">

                                    <table className="metricTable scada-table col-sm-12 col-md-6 col-lg-6">
                                        <tbody>
                                            {Object.keys(tempGroupObj).map(m => {
                                                while (m < Math.round(tempMetricCount / 2)) {
                                                    return this.getScadaRow(tempGroupObj[m], false, this.state.sDevice)
                                                }
                                            })}
                                        </tbody>
                                    </table>

                                    <table className="metricTable scada-table col-sm-12 col-md-6 col-lg-6">
                                        <tbody>
                                            {this.checkRow(Math.round((tempMetricCount / 2)))}
                                            {Object.keys(tempGroupObj).map(m => {
                                                while (m >= Math.round(tempMetricCount / 2)) {
                                                    return this.getScadaRow(tempGroupObj[m], false, this.state.sDevice)
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                {this.state.addMetric &&
                                    <div className='fav-badge alertBadge' style={{ background: '#0a92b1', float: 'right', color: '#FFF', border: 'none', fontSize: '12px' }} onClick={() => this.setState({ addMetricModal: !this.state.addMetricModal })} >
                                    <span style={{fontSize:'12px'}}>Add Derived Metric</span>
                                        <i className='fas fa-plus' style={{ paddingLeft: '5px' }} />
                                    </div>
                                }
                            </Collapsible>)
                    }
                }
            });
            return collapsibleRender;
        }
    }

    renderMetricModal() {
        var header = <span>Add Derived Metric</span>,
            body = <DerivedMetric externalFormId={'New'} />,
            footer = <div style={{ width: '100%' }}>
                <button color='primary' style={{ float: 'right' }} onClick={() => { this.setState({ addMetricModal: !this.state.addMetricModal }); }}>Save</button>
            </div>

        return <SimpleModal {...{ header, body, footer, open }} />
    }

    renderDINTable(m, bin, statusBits, from, to) {
        return (
            <table className="metricTable scada-table scada-din col-sm-12 col-md-4 col-lg-4">
                <tbody>
                    <th colSpan={3}>{m.name}</th>
                    {
                        statusBits.map((s, i) => {
                            if (i >= from && i < to) {
                                var dashVal = "", favourited = ""
                                var t = bin.substr(15 - (i), 1)
                                if (t == 1) {
                                    dashVal = "on"
                                }
                                return (
                                    <ScadaDashlight key={m.stationTagId + '_' + i} dashLight={dashVal} desc={s} metricDeets={m} i={i} favourited={favourited} toggleFavMetric={(metric) => this.props.toggleFavMetric(metric, this.state.sDevice)} />
                                )
                            }
                        })
                    }
                </tbody>
            </table>
        )
    }

    getScadaRow(metricInfo, isCompact, deviceId) {
        var scadaVal = this.props.getScadaValue(metricInfo.metricId, metricInfo.decimalPlaces, deviceId);

        var unit = metricInfo.unit;

        var favourited = '';
        var hidden = '';
        var editBtn

        if (unit == '&deg;C') {
            unit = '\u00B0C';
        }

        if (this.props.favourites[deviceId].includes(metricInfo.metricId)) {
            favourited = 'fav';
        }

        if (metricInfo.hideMetric) {
            hidden = 'metric-hidden';
        }

        if (!metricInfo.readOnly && this.props.isLive) {
            editBtn = <OptionBtn icon='fal fa-edit' active={false} onClick={() => this.getEditable(metricInfo)} style={{float:'right'}} />
        }

		if (isCompact) {
            return (
                <Col key={'col-' + metricInfo.metricId} xs={4} sm={3} style={{paddingRight: '0px'}}>
                    <Row style={{ margin: 0}}>
                        <Col xs={5} style={{padding: 0, overflow:'hidden'}}>
                            <span style={{ fontSize: '0.9rem', whiteSpace:'nowrap' }}>{metricInfo.name}</span>
                        </Col>
                        <Col xs={7} style={{ padding: '0 0 0 5px' }}>
                            {metricInfo.groupRender != 'Tile' && <div className='compactMVal'>{scadaVal} {unit}</div>}
                            <div>{this.getMetricRenderer(metricInfo, scadaVal)}</div>
                        </Col>
                    </Row>
                </Col>
            )
        }
        else {
            return (
                <tr key={metricInfo.metricId}>
                    {this.props.editMode ?
                        <td className="scada-star" style={{ width: '10%' }}><i className={"fa fa-ban " + hidden} onClick={() => this.props.toggleMetricHide(metricInfo.stationTagId)} /></td>
                        :
                        <td className="scada-star" style={{ width: '10%' }}><i className={"fa fa-star " + favourited} onClick={() => this.props.toggleFavMetric(metricInfo.metricId, this.state.sDevice)} /></td>
                    }

                    {this.props.editMode ?
                        <td className="scada-metric-title" style={{ width: '25%', cursor: 'pointer' }} onClick={() => this.props.toggleTagMgmt(metricInfo)}>{metricInfo.name}</td>
                        :
                        <td className="scada-metric-title" style={{ width: '25%', cursor: 'pointer' }} onClick={() => this.getMaxMin(metricInfo)}>{metricInfo.name}</td>
                    }

                    {metricInfo.groupRender == 'Tile' ? <td style={{ width: '25%' }}>{scadaVal} {unit}{editBtn}</td> : <td style={{ width: '20%' }}>{scadaVal} {unit}</td>}
                    <td>
                        {this.getMetricRenderer(metricInfo, scadaVal)}
                    </td>
                </tr>)
        }
    }

    getEditable(metric) {
        this.setState({ editableMetrics: this.state.editableMetrics == metric.metricId ? null : metric.metricId, metricVal: null, savePending: false, saveSuccessful: false, saveFault: false  }, () => console.log('editable', this.state.editableMetrics))
    }

    getMaxMin(metric) {
        var tempMaxMin = this.state.maxMinMetrics;

        if (tempMaxMin[metric.metricId]) {
            delete tempMaxMin[metric.metricId];
            this.setState({ maxMinMetrics: tempMaxMin });
        }
        else {
            if (this.props.isLive) {
                tempMaxMin[metric.metricId] = { 'max': this.props.maxMetricVals[metric.metricId], 'min': this.props.minMetricVals[metric.metricId] };
                this.setState({ maxMinMetrics: tempMaxMin });
            }
            else {
                var i = 0;
               
                while (this.props.scadaData[this.state.sDevice].length > i) {
                    if (parseInt(this.props.scadaData[this.state.sDevice][i].tags.MetricId) == metric.metricId) {
                        tempMaxMin[metric.metricId] = { 'max': Math.max.apply(Math, this.props.scadaData[this.state.sDevice][i].values.map(x => { return x[1]; })), 'min': Math.min.apply(Math, this.props.scadaData[this.state.sDevice][i].values.map(x => { return x[1]; })) };
                        this.setState({ maxMinMetrics: tempMaxMin });
                        break;
                    }
                    i++;
                }
            }
        }
    }

    checkRow(m) {
        if (m % 2 != 0) {
            return (<tr><td></td><td></td><td></td><td></td></tr>)
        }
        else {
            return null
        }
    }

    sendCommand(metric) {
        scadaSendCommand(this.state.sDevice, 'SET_METRIC', undefined, metric.metricId + ',' + this.state.metricVal)
        this.setState({ savePending: true, saveSuccessful: false, saveFault: false })
    }

    getMetricRenderer(metricInfo, scadaVal) {
        var current = 0;
        var warn = 0;
        var fault = 0;
        var dp = metricInfo.decimalPlaces || 0;

        if (this.state.maxMinMetrics[metricInfo.metricId] && this.props.scadaData) {
            return (
                <div className="maxMinRow"><Badge color="altinfo">Max</Badge><b>
                    {this.props.isLive ? this.props.maxMetricVals[metricInfo.metricId].toFixed(dp) : this.state.maxMinMetrics[metricInfo.metricId].max.toFixed(dp)}
                </b><Badge color="info">Min</Badge><b>
                    {this.props.isLive ? this.props.minMetricVals[metricInfo.metricId].toFixed(dp) : this.state.maxMinMetrics[metricInfo.metricId].min.toFixed(dp)}
                </b></div>
            )
        }

        else {
            if (metricInfo.groupRender == 'Bullet') {
                if (metricInfo.upperBound != undefined) {
                    current = (scadaVal / metricInfo.upperBound) * 100;

                    if (current > 100) {
                        current = 100;

                        warn = (scadaVal - metricInfo.upperBound) / ((metricInfo.upperBound * 1.1) - (metricInfo.upperBound)) * 100;

                        if (warn > 100) {
                            warn = 100;

                            fault = ((scadaVal - (metricInfo.upperBound * 1.1)) / ((metricInfo.upperBound * 1.2) - (metricInfo.upperBound * 1.1)) * 100);
                        }

                        if (fault > 100) {
                            fault = 100;
                        }
                    }
                }

                return <ScadaBullet current={current} warn={warn} fault={fault} />
            }
            else if (this.state.editableMetrics == metricInfo.metricId) {
                return (
                <Row style={{ margin: 0 }}>
                    <input type='text' className='form-control' style={{ width: '75%' }} onBlur={(e) => this.setState({ metricVal: e.target.value })} />
                    <ConfirmBtn text='' compact={true} clickAction={() => this.sendCommand(metricInfo)} style={{ float: 'right' }} pending={this.state.savePending} successful={this.state.saveSuccessful} fault={this.state.saveFault} />
                </Row>)
            }
            else if (metricInfo.groupRender == 'Tile') {
                var tileRender = <div style={{ height: '30px' }} />;
                this.props.renderInfo.renderInfo.map(x => {
                    if (x.metricId == metricInfo.metricId && x.lookupCode == scadaVal) {
                        tileRender = <div style={{ height: '30px', lineHeight: '30px' }}><b>{x.desc}</b></div>
                    }
                })
                return (tileRender);
            }
            else if (metricInfo.groupRender == 'Flags') {
                return ''
            }
            else {
                return <div style={{ height: '30px' }}></div>
            }
        }     
    }

    titleClick(stationid) {
        this.props.history.push('/Scada/' + stationid)
        this.props.history.go(0)
    }

    renderChildDevices() {
        var station = this.props.deviceList.find(x => x.id == this.props.stationId)

        return <MonitoringTileDevices station={station} metricMap={this.props.metricMap} config={this.props.config} favourites={this.props.favourites} getVal={(mId, dp, dId, isUId) => this.props.getScadaValue(mId, dp, dId, isUId)} toggleFavMetric={(mId, dId) => this.props.toggleFavMetric(mId, dId)} />
    }


    render() {
        if (this.state.sDevice) {
            return (
                <div>
                    {this.renderScadaCollapsibles()}
                    {this.props.deviceList && this.props.deviceList.length > 1 && this.props.scadaData &&
                        <div style={{ marginTop: '10px' }}>
                            <h4>Devices</h4>
                            <div className='dashboard-controllers  themelight col'>
                                {this.renderChildDevices()}
                            </div>
                        </div>}
                    {this.state.addMetricModal && this.renderMetricModal()}
                </div>
            )
        }
        else {
            return null
        }
    }
}

export default withRouter(ScadaCollapsibles)
