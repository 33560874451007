import React, { Component } from 'react'
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip'
import '../../style/bootstrap.min.css';
import '../../style/style.css';
import '../../style/toggle.css';
import '../../style/monitoring.css';
import '../../style/everun_fello.css';
import '../../style/fa_duotone.css';
import '../../style/font-awesome.css';
import '../../style/slick.css';
import '../../style/miniCharts.css';
import '../../style/hamburger.css';
import '../../style/react_resizable.css';
import '../../style/reactTags.css';
import '../../style/kartLoader.css';
import 'react-s-alert/dist/s-alert-default.css';
import '../../node_modules/@fortawesome/fontawesome-pro/css/all.css'
 
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import { connect } from 'react-redux';
import { getAuthInfo } from '../scenes/Login/state/actions'
import { getLookups } from '../actions/common'
import { publicRoutes } from '../configuration/enums'

import Header from './Header/Header';
import HeaderTop from './Header/HeaderTop';
import Sidebar from './SideNav';
import AlertBox from '../api/alert/AlertBox';
import Alert from 'react-s-alert';
import MainFilter from './Header/MainFilter'
import ResponsiveMenu from './ResponsiveMenu'

import ClientSetup from '../scenes/ClientSetup/components/ClientSetup.js';
import MonitoringContainer from '../scenes/Monitoring/components/MonitoringContainer.js';
import ParameterContainer from '../scenes/Parameters/components/ParameterContainer';
import IncidentActions from '../scenes/Incidents/components/IncidentActions.js';
import IncidentDetail from '../scenes/Incidents/components/IncidentDetail.js';
import Analysis from '../scenes/Analysis/components/AnalysisContainer.js';
import Scada from '../scenes/Scada/components/Scada.js';
import ScadaWF from '../scenes/Scada/components/ScadaWF.js';
import ReportsMenu from '../scenes/Reports/components/ReportsMenu';
import TestControl from './Controls/TestControl';
import Events2 from '../scenes/Events/components/Events.js';
import Cases from '../scenes/Cases/components/Cases.js';
import Incidents from '../scenes/Incidents/components/Incidents.js';
//import SchEvents from '../scenes/Incidents/components/SchEvents.js';
import SchEvents from '../scenes/WorkCards/components/WorkCards.js';
import WorkCards from '../scenes/WorkCards/components/WorkCards.js';
import DashContainer from '../scenes/Dashboard/components/DashContainer';
import Metering from '../scenes/Metering/components/MeteringContainer';
import DashFullscreen from '../scenes/Dashboard/components/DashFullscreen';
import ChangePasswordPage from '../scenes/Accounts/components/ChangePasswordPage';
import WelcomePage from '../scenes/Accounts/components/WelcomePage';
import Calendar from '../scenes/Activity/components/ActivityContainer';
import EngineerSchedule from '../scenes/Activity/components/EngineerSchedule';
import DocumentRepo from '../scenes/DocumentRepo/components/DocumentRepo';
import ClientSettings from '../scenes/ClientSetup/components/ClientSettings';
import SiteSettings from '../scenes/SiteSetup/components/SiteSettings';
import ModelSettings from '../scenes/ModelSetup/components/ModelSettings';
import CompanySettings from '../scenes/CompanySetup/components/CompanySettings';
import RTUSettings from '../scenes/EwonSetup/components/RTUSettings';
import DeviceSettings from '../scenes/EwonSetup/components/DeviceSettings';
import TagSettings from '../scenes/EwonSetup/components/TagSettings';
import CompanyCard from '../scenes/ClientSetup/components/CompanyCard';
import UniversalMetricCard from '../scenes/ModelSetup/components/UniversalMetricCard';
import VanCard from '../scenes/ClientSetup/components/VanCard';
import UserCard from '../scenes/ClientSetup/components/UserCard';
import AssetCard from '../scenes/ClientSetup/components/AssetCard';
import Tasks from '../scenes/Tasks/components/Tasks'
import Parts from '../scenes/Parts/components/Parts'
import PartsSuppliers from '../scenes/Parts/components/PartsSuppliers'
import Billing from '../scenes/Finance/components/Billing';
import IncidentSettings from '../scenes/Monitoring/components/IncidentSettings';
import ChecklistSetup from '../scenes/ChecklistSetup/components/ChecklistSetup'
import ControllerSettings from '../scenes/SiteSetup/components/ControllerSettings'
import FaultTagSettings from '../scenes/SiteSetup/components/FaultTagSettings'
import FormBuilder from '../scenes/CustomForm/components/FormBuilder'
import FormCustom from '../scenes/CustomForm/components/FormCustom'
import FormPageSetup from '../scenes/CustomForm/components/FormPageSetup'
import FormSelection from '../scenes/CustomForm/components/FormSelection'
import FormPage from '../scenes/CustomForm/components/FormPage'
import FormLookupMgmt from '../scenes/CustomForm/components/FormLookupMgmt'
import NotificationRules from '../scenes/Notifications/components/NotificationRules'
import Notifications from '../scenes/Notifications/components/Notifications'
import PurchaseOrder from '../scenes/Stock/components/PurchaseOrder'
import StockTake from '../scenes/Stock/components/StockTake'
import StockMovement from '../scenes/Stock/components/StockMovement'
import DerivedMetric from '../scenes/DerivedMetric/components/DerivedMetric'
import LookupSetup from '../scenes/LookupSetup/components/LookupSetup'
import AlertRules from '../scenes/AlertRules/components/AlertRules'
import VesselMap from '../scenes/VesselMap/components/VesselMap'
import DeviceMetering from '../scenes/DeviceMetering/components/DeviceMeteringContainer'
import TagMgmt from '../scenes/ClientSetup/components/TagMgmt'
import MetricSettings from '../scenes/DeviceSetup/components/MetricSettings'
import UniversalMetricSettings from '../scenes/DeviceSetup/components/UniversalMetricSettings'
import DeviceTypeSettings from '../scenes/DeviceSetup/components/DeviceTypeSettings'
import TariffSettings from '../scenes/TariffSetup/components/TariffSettings'
import Jobs from '../scenes/Jobs/components/Jobs'
import FormPublic from '../scenes/CustomForm/components/FormPublic'
import DropFolderParser from '../scenes/DropFolderParser/components/DropFolderParser'
import ImportLookup from '../scenes/DropFolderParser/components/DropFolderParserLookup'
import CustomerSetup from '../scenes/CustomerSetup/components/CustomerSetup'
import ContactSetup from '../scenes/CustomerSetup/components/ContactSetup'
import ProductConfiguration from '../scenes/ProductConfiguration/components/ProductConfiguration'
import ProjectBoard from '../scenes/ProjectBoard/components/ProjectBoard'
import PickList from '../scenes/PickList/components/PickList'
import Returns from '../scenes/Returns/components/Returns'
import GroupSetup from '../scenes/CustomerSetup/components/GroupSetup'
import JobOverview from '../scenes/JobOverview/components/JobOverview'
import FieldServiceReport from '../scenes/FieldServiceReport/components/FieldServiceReport'
import FieldServiceReportUpload from '../scenes/FieldServiceReport/components/FieldServiceReportUpload'
import Quote from '../scenes/Stock/components/Quote'
import PurchasingDepartment from '../scenes/Parts/components/PurchasingDepartment'
import { XeroAuthorise } from '../scenes/XeroAuthorise/XeroAuthorise'

import moment from 'moment'

class App extends Component
{
    constructor(props) {
        super(props);
        this.state = {isPublic: false, isAuthenticating: true}
    }

    componentDidMount() {
        var pr = this.isPublicRoute(this.props.location.pathname)
        console.log('this.props', this.props)
        if (!this.props.authInfo && !pr) {
            this.props.history.push('/Login' + '?prev=' + this.props.location.pathname);
        }
        else if(!pr){
            this.props.getLookups()
            this.checkUserConfig()
        }
        this.setState({ isAuthenticating: false })
        //var dynTitle = this.props.location.pathname.split('/')[1].match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
        //document.title = dynTitle + ' | izon'
    }

    checkUserConfig() {
        if (localStorage.getItem('jwtToken') && process.env.BUILD_TIME) {
            var jtIssue = JSON.parse(atob(localStorage.getItem('jwtToken').split('.')[1])).iat * 1000
            if (moment(jtIssue) < moment(process.env.BUILD_TIME)) {
                localStorage.removeItem('jwtToken')
                localStorage.removeItem('userConfig')
            }
        }
    }

    isPublicRoute(fullPath) {
        var path = (fullPath.split('/')[1]).toLowerCase()
        var pRoute = false
        if (publicRoutes.includes(path)) {
            pRoute = true
            this.setState({isPublic: true})
        }
        
        return pRoute
    }

    render()
    {
        if (this.state.isAuthenticating) {
            return null
        }
        else {
            if (this.state.isPublic) {
                return (
                    <div className="container" id="full-container">
                        <div className="container-fluid row" id="main-container">
                            <main className="col pt-3" id="main-grid">
                                <Switch>
                                    <Route path="/Form/:id?" name="FormPublic" component={FormPublic} />
                                    <Redirect from="/" to="/Dashboard" />
                                </Switch>
                            </main>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="container" id="full-container">
                        <MainFilter />
                        <HeaderTop />
                        <Header />
                        <div className="container-fluid row" id="main-container">
                            <Sidebar />
                            <main className="col pt-3" id="main-grid">
                                <Switch>
                                    <Route path="/ClientSetup" name="ClientSetup" component={ClientSetup} />
                                    <Route path="/Dashboard/:full?" name="Dashboard" component={DashContainer} />
                                    <Route path="/Events2" name="Events2" component={Events2} />
                                    <Route path="/Events/:id?/:create?" name="Events" component={SchEvents} />
                                    <Route path="/DashboardFull" name="DashboardFull" component={DashFullscreen} />
                                    <Route path="/Cases/:id?/:hist?" name="Cases" component={Cases} />
                                    <Route path="/Incidents/:id?/:hist?" name="Incidents" component={Incidents} />
                                    <Route path="/WorkCards/:id?/:hist?/:create?" name="WorkCards" component={WorkCards} />
                                    <Route path="/Tasks/:id?" name="Tasks" component={Tasks} />
                                    <Route path="/Parts/:id?" name="Parts" component={Parts} />
                                    <Route path="/PartsSuppliers/:id?" name="PartsSuppliers" component={PartsSuppliers} />
                                    <Route path="/Reports" name="Reports" component={ReportsMenu} />
                                    <Route path="/Monitoring" name="Monitoring" component={MonitoringContainer} />
                                    <Route path="/Parameters" name="Parameters" component={ParameterContainer} />
                                    <Route path="/Actions" name="Actions" component={IncidentActions} />
                                    <Route path="/IncidentDetail" name="IncidentDetail" component={IncidentDetail} />
                                    <Route path="/Analysis" name="Analysis" component={Analysis} />
                                    <Route path="/Scada/:stationid" name="Scada" component={Scada} />
                                    <Route path="/ScadaWF/:stationid" name="ScadaWF" component={ScadaWF} />
                                    <Route path="/Metering" name="Metering" component={Metering} />
                                    <Route path="/Test" name="Test" component={TestControl} />
                                    <Route path="/Calendar" name="Calendar" component={Calendar} />
                                    <Route path="/EngineerSchedule" name="EngineerSchedule" component={EngineerSchedule} />
                                    <Route path="/Welcome" name="Welcome" component={WelcomePage} />
                                    <Route path="/ChangePassword" name="ChangePasswordPage" component={ChangePasswordPage} />
                                    <Route path="/DocumentRepo" name="DocumentRepo" component={DocumentRepo} />
                                    <Route path="/ClientSettings/:id?" name="ClientSettings" component={ClientSettings} />
                                    <Route path="/SiteSettings/:id?" name="SiteSettings" component={SiteSettings} />
                                    <Route path="/ControllerSettings/:id?" name="ControllerSettings" component={ControllerSettings} />
                                    <Route path="/FaultTagSettings/:id?" name="FaultTagSettings" component={FaultTagSettings} />
                                    <Route path="/ModelSettings/:id?" name="ModelSettings" component={ModelSettings} />
                                    <Route path="/CompanySettings/:id?" name="CompanySettings" component={CompanySettings} />
                                    <Route path="/RTUSettings/:id?" name="RTUSettings" component={RTUSettings} />
                                    <Route path="/DeviceSettings/:id?" name="DeviceSettings" component={DeviceSettings} />
                                    <Route path="/TagSettings/:id?" name="TagSettings" component={TagSettings} />
                                    <Route path="/CompanyCard/:id?" name="CompanyCard" component={CompanyCard} />
                                    <Route path="/UniversalMetricCard/:id?" name="UniversalMetricCard" component={UniversalMetricCard} />
                                    <Route path="/VanCard/:id?" name="VanCard" component={VanCard} />
                                    <Route path="/UserCard/:id?" name="UserCard" component={UserCard} />
                                    <Route path="/AssetCard/:id?" name="AssetCard" component={AssetCard} />
                                    <Route path="/Billing/:id?" name="Billing" component={Billing} />
                                    <Route path="/IncidentSettings/:id?" name="IncidentSettings" component={IncidentSettings} />
                                    <Route path='/ChecklistSetup/:id?' name='ChecklistSetup' component={ChecklistSetup} />
                                    <Route path='/FormBuilder/:id?/:hist?' name='FormBuilder' component={FormBuilder} />
                                    <Route path='/FormPageSetup/:id?' name='FormPageSetup' component={FormPageSetup} />
                                    <Route path='/FormCustom/:id?/:edit?/:hist?' name='FormCustom' component={FormCustom} />
                                    <Route path='/FormSelection/:fId?/:ent?' name='FormSelection' component={FormSelection} />
                                    <Route path='/FormPage/:id?' name='FormPage' component={FormPage} />
                                    <Route path='/FormLookup/:id?' name='FormLookupMgmt' component={FormLookupMgmt} />
                                    <Route path='/NotificationRules/:id?' name='NotificationRules' component={NotificationRules} />
                                    <Route path='/Notifications/:id?' name='Notifications' component={Notifications} />
                                    <Route path='/PurchaseOrder/:id?/:hist?' name='PurchaseOrder' component={PurchaseOrder} />
                                    <Route path='/StockTake/:id?' name='StockTake' component={StockTake} />
                                    <Route path='/StockMovement/:id?' name='StockMovement' component={StockMovement} />
                                    <Route path='/DerivedMetric/:id?' name='DerivedMetric' component={DerivedMetric} />
                                    <Route path='/AlertRules/:id?' name='AlertRules' component={AlertRules} />
                                    <Route path='/LookupSetup/:id?' name='LookupSetup' component={LookupSetup} />
                                    <Route path="/VesselMap" name="VesselMap" component={VesselMap} />
                                    <Route path="/DeviceMetering" name="DeviceMetering" component={DeviceMetering} />
                                    <Route path="/TagManagement" name="TagMgmt" component={TagMgmt} />
                                    <Route path="/MetricSettings/:id?" name="MetricSettings" component={MetricSettings} />
                                    <Route path="/UniversalMetricSettings/:id?" name="UniversalMetricSettings" component={UniversalMetricSettings} />
                                    <Route path="/DeviceTypeSettings/:id?" name="DeviceTypeSettings" component={DeviceTypeSettings} />
                                    <Route path="/TariffSettings/:id?/:isTemplate?" name="TariffSettings" component={TariffSettings} />
                                    <Route path="/TariffTemplateSettings" name="TariffTemplateSettings" component={TariffSettings} />
                                    <Route path="/Job/:id?" name="Job" component={Jobs} />
                                    <Route path="/Form/:id?" name="FormPublic" component={FormPublic} />
                                    <Route path="/Import/:id?" name="DropFolderParser" component={DropFolderParser} />
                                    <Route path="/ImportLookup/:id?" name="ImportLookup" component={ImportLookup} />
                                    <Route path="/Customers/:id?" name="CustomerSetup" component={CustomerSetup} />
                                    <Route path="/ContactSetup/:id?" name="ContactSetup" component={ContactSetup} />
                                    <Route path="/ProductConfiguration/:id?" name="ProductConfiguration" component={ProductConfiguration} />
                                    <Route path="/ProjectBoard" name="ProjectBoard" component={ProjectBoard} />
                                    <Route path="/PickList/:id?" name="PickList" component={PickList} />
                                    <Route path="/Returns/:id?" name="Returns" component={Returns} />
                                    <Route path="/GroupSetup/:id?" name="GroupSetup" component={GroupSetup} />
                                    <Route path="/FieldServiceReport/:id?/:hist?" name="FieldServiceReport" component={FieldServiceReport} />
                                    <Route path="/FieldServiceReportUpload/:id?/:hist?" name="FieldServiceReportUpload" component={FieldServiceReportUpload} />
                                    <Route path="/JobOverview" name="JobOverview" component={JobOverview} />
                                    <Route path="/Quote/:id?/:hist?" name="Quote" component={Quote} />
                                    <Route path="/PurchasingDepartment/:id?/:hist?" name="PurchasingDepartment" component={PurchasingDepartment} />
                                    <Route path='/XeroAuthorise/' component={XeroAuthorise} />
                                    <Redirect from="/" to="/Dashboard" />
                                </Switch>
                                <AlertBox />
                                <Alert stack={{ limit: 10, spacing: 5 }} effect="bouncyflip" position='bottom-right' timeout={5000} html={true} />
                                <ReactTooltip id="below-tooltip" multiline={true} place="bottom" type="light" />
                            </main>
                        </div>
                        <ResponsiveMenu authRole={this.props.authRole} />
                    </div>
                )
            }
        }
    }
}
const mapStateToProps = state => {
    return state.common.users
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAuthInfo: () => {
            dispatch(getAuthInfo());
        },
        getLookups: () => {
            dispatch(getLookups());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)