import React, { Component } from 'react'
import helpers from '../../../utils/helpers'
import { connect } from 'react-redux'

class TilesWidget extends React.Component {

    constructor(props) {
        super(props);
    }

    getMarker(wc){
	    var allOff = true;
	    wc.map(a => {
		    if(!a.offSiteTime){
			    allOff = false;
		    }
	    })   
	    if(allOff){
            return <i className='fad fa-map-marker-check faGW' style={{ fontSize:'2em' }} />
	    }
        else {
            //return <i className='fad fa-map-marker-check faGW' style={{ fontSize: '2em' }} />
            return <i className='fad fa-map-marker-exclamation faRW' style={{ fontSize: '2em' }} />
	    }
    }

    getUser(id) {
        var at = this.props.users;

        return at && at[id] ? at[id] : '3rd Party'
    }

    render() {
        return (
            <div style={{ overflowY: 'auto' }}>
                <p style={{ margin: 0, padding: '5px 0 0 5px', fontWeight: 'normal' }}><strong>Engineers Onsite</strong></p>
                {this.props.widgetValue && this.props.widgetValue.map(x => {
                    return (
                        <div key={x.engineerLogId} className="alertbox" style={{display:'inline-block', float:'left'}}>
                            <div className='bodybox nAlert' style={{ float:'left' }}>
                                <h1>{x.siteName}</h1>
                                <b>{x.alertName}</b>
                            </div>
                            <div className='sidebox nAlert' style={{lineHeight:'50px'}}>
                                {x.wcI && this.getMarker(x.wcI)}
                            </div>
                            <div style={{display:'flex', flexWrap:'wrap', backgroundColor:'#fff'}}>
                            {x.wcI && x.wcI.map(a => {
                                return (
                                    <div key={'eng' + a.engineerId} style={{ padding: '0 5px', width: '100%' }}>
                                        <b>{this.getUser(a.userId)}</b> 
                                        <span style={{float:'right'}}>
                                            {a.onSiteTime ?
                                            helpers.parseGMTTime(a.onSiteTime).format('DD/MM HH:mm') + ' - ' + (a.offSiteTime ? helpers.parseGMTTime(a.offSiteTime).format('HH:mm') : 'now') :
                                            'Not checked in'}
                                        </span>
                                    </div>)
                            })}
                            </div>           
                      </div>
                    )
                })}
                {
                    this.props.widgetValue && this.props.widgetValue.length == 0 &&
                    <div key='noEngin' className="alertbox" style={{ display: 'inline-block', float:'left'}}>
                        <div className='bodybox gAlert' style={{ float: 'left' }}>
                            <b>No engineers checked onsite</b>
                        </div>
                        <div className='sidebox gAlert' style={{ lineHeight: '50px' }}>
                            <i className='fad fa-map-marker-check faGW' style={{ fontSize: '2em' }} />
                        </div>
                    </div> 
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return state.common.header
}

export default connect(mapStateToProps)(TilesWidget)
