import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

export default class TabPicker extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0
        };

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            }, this.resizeEvent());
        }
    }

    resizeEvent() {
        //console.log('resizeEvent');
        //window.dispatchEvent(new Event('resize'));
    }

    renderTabs(content) {

        let tabRenderer = [];

        content.map((x, i) => {
            tabRenderer.push(
                <NavItem key={i}>
                    <NavLink className={classnames({ active: this.state.activeTab === i })} onClick={() => { this.toggle(i); }}>
                        {x.tabName}
                    </NavLink>
                </NavItem>)
        });

        return tabRenderer;
    }

    renderPanes(content) {

        let paneRenderer = [];

        content.map((x, i) => {
            paneRenderer.push(
                <TabPane tabId={i} key={i}>
                    {x.tabContent}
                </TabPane>)
        });

        return paneRenderer;
    }

    render() {
        return (
            <div>
                <Nav className="custom-tabs" tabs>
                    {this.renderTabs(this.props.availTabs)}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {this.renderPanes(this.props.availTabs)}
                </TabContent>
            </div>
        );
    }
}