import React, { Component } from 'react'
import { Input} from 'reactstrap';

export default class IdNameDropdown extends Component {
    constructor(props) {
        super(props)
        this.doChange = this.doChange.bind(this)
    }

    doChange(newValue) {
        if (this.props.onChange) {
            this.props.onChange(newValue) 
        }
    }

    render() {
        const { options, valueField, displayField, value, zeroText, defaultSelect, defaultValue, readOnly } = this.props
        return (
            <Input type="select" name="select" value={value} defaultValue={defaultValue} disabled={readOnly == true} onChange={(event) => this.doChange(event.target.value)} style={this.props.style}>
            {(zeroText)?<option value={0}>{zeroText}</option>:null}
            {options != null?options.map((x, i) => 
                    <option key={i} value={x[valueField]} disabled={x.disabled ? true : null} style={x.disabled ? { fontWeight: 'bold' } : null}>{x[displayField]}</option>    
            ):null}
                {defaultSelect ? <option value={defaultSelect.value} disabled={x.disabled ? true : null}>{defaultSelect.display}</option>:null}
            </Input>
        )
    }
}

