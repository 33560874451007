import React, { Component } from 'react'
import { ApiPost } from '../../api' 
// import MapPin from './MapPin.js';
import GoogleMap from 'google-map-react';
const DEBUG_MODE = process.env.DEBUG_MODE;


export default class MapPath extends Component {

    constructor(props) {
        super(props);
        this.state = {mapSet: false}
        this.polylineCurrentList= [];
        // add iterator for colour based on owner | length | region
    }

    mapPath()
    {
        const { mapRef, paths } = this.props;
        if (!mapRef) {
            return
        }
        if (this.props.destroy) {
            this.polylineCurrentList.map(p => {
                p.setMap(null)
            })
        }
        var oldLines = this.polylineCurrentList.slice(0);
        if (paths && paths.length > 0) {
            var skipped = 0;
            var areaColours = ['#9bc7cf', '#FCD757', '#fc9f57', '#EE6352']
            for (var i = 0; i < paths.length; i++)
            {         
                if (paths[i]) {
                    if (Array.isArray(paths[i])) {
                        var coordinates = paths[i];
                        var polyline = new window.google.maps.Polygon({
                            paths: coordinates,
                            strokeColor: areaColours[i],
                            strokeOpacity: 0.5,
                            strokeWeight: 0.5,
                            fillColor: areaColours[i],
                            fillOpacity: 0.05
                        });
                        polyline.setMap(mapRef)
                        this.polylineCurrentList.push(polyline)
                    }
                    else {
                        var jpath = JSON.parse(paths[i]);
                        var path = jpath["coordinates"];
                        var polyline = this.path2polyline(path, jpath["colour"] || "#0a92b1", false)
                        polyline.setMap(mapRef)
                        this.polylineCurrentList.push(polyline)
                    }
                } else {
                    skipped = skipped +1
                }
            }
            for (var i = 0; i < oldLines.length; i++) {
                oldLines[i].setMap(null)
            }
            this.setState({mapSet: true})
        }
    }  

    path2polyline(coordinates, color)
    {
        var style = this.props.style || { solid: false }

        var lineSymbol = { path: 'M 0,-1 0,1', strokeOpacity: 1, scale: 2}

        return new window.google.maps.Polyline({
            strokeColor: color,
            fillColor: color,
            fillOpacity: 0.5,
            strokeOpacity: 1,
            strokeWeight: this.props.intersect ? 3 : 2,
            icons: this.props.intersect && [{
                icon: lineSymbol,
                offset: '0',
                repeat: '20px'
            }],
            path: coordinates 
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.paths) != JSON.stringify(prevProps.paths)) {
            this.mapPath()
        }
    }

    componentDidMount() {
        this.mapPath()
    }

    componentWillUnmount() {
        this.polylineCurrentList.map(p => {
            p.setMap(null)
        })
    }

    render()
    {
        return null
    }
}


    

