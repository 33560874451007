import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ApiPost } from '../../../api'
import ReactTable from 'react-table'
import OverlayLoader from '../../../components/OverlayLoader'
import HistoricDataExport from '../../../components/HistoricDataExport'

class DeviceMeteringTables extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentPane: 0, meteringData: null, isLoading: false
        }
    }

    componentDidMount() {
        //this.getData()
    }

    componentDidUpdate(pp) {
        if (JSON.stringify(pp.mData) != JSON.stringify(this.props.mData)) {
            this.getData()
        }
    }

    getData() {
        var dDetails = this.props.mData
        var filterTags
        if (dDetails.treeChecked && dDetails.treeChecked.length > 0) {
            this.setState({ isLoading: true })
            if (dDetails.tags.length > 0) {
                var tTags = []
                dDetails.tags.map(x => {
                    tTags.push(x.id)
                })
                filterTags = tTags
            }
            const meteringInfo = { devices: dDetails.treeChecked, fromDate: dDetails.fromDate.format('DD/MM/YYYY 00:00:00'), toDate: dDetails.toDate.add(1, 'd').format('DD/MM/YYYY 00:00:00'), filterTags, monthly: dDetails.showMonthly }
            ApiPost('Metering', 'GetDeviceMetering', meteringInfo)
            .then((data) => {
                this.setState({ meteringData: data, isLoading: false })
            })
        }
    }

    renderTable(tableData, tableTitle) {
        var dM = this.state.meteringData.umets
        var defaultSorted = [{ id: 'device', desc: false }, { id: 'period', desc: false }]
        if (this.props.mData.sortType == 2) {
            defaultSorted = [{ id: 'period', desc: false }, { id: 'device', desc: false }]
        }
        else if (this.props.mData.sortType == 3) {
            defaultSorted = this.props.mData.sortVal
        }
        var tableConfig = { showPagination: false, minRows: 0, defaultPageSize: 1000, className: 'meteringTable', defaultSorted, onSortedChange: (e) => this.props.setSort(3) }
        var tColumns = [{ Header: 'Period', accessor: 'period' }, { Header: 'Device', accessor: 'device' }]
        //tColumns.push({ Header: 'Download', accessor: 'download' })
        var tData = []
        var metricColumns = []
        tableData.map(td => {
            var device = this.props.devices.find(x => x.id == td.stationId)
            if (device) {
                var dName = device.name
                var t = { period: td.period, device: dName }
                
                td.uMetricReadings.map(u => {
                    if (!metricColumns.find(f => f.accessor == u.umetId)) {
                        metricColumns.push({ Header: dM[u.umetId].name + ' (' + dM[u.umetId].unit + ')', accessor: u.umetId.toString() })
                    }
                    if (this.props.mData.showReadings && dM[u.umetId].aggType == 't') {
                        t[u.umetId] = <div className='metricReading'><span>{u.diff}</span><span>Start: {u.start}</span><span>End: {u.end}</span></div>
                    }
                    else {
                        t[u.umetId] = u.diff
                    }
                })
                t['download'] = <div><button className="btn btn-default" style={{ padding: '1px 6px', marginRight: '10px' }}><i className="fa fa-download" onClick={() => this.onMeterClick(td.stationId, td.monthNum, td.yearNum)} /></button></div>
                tData.push(t)
            }
        })
        metricColumns.sort((a, b) => a.Header.localeCompare(b.Header))
        tColumns.push(...metricColumns)
        tColumns.push({ Header: 'Download', accessor: 'download', width: 100 })

        return (<div key={'t'+tableTitle}><span>{tableTitle}</span><ReactTable data={tData} columns={tColumns} {...tableConfig} /></div>)
    }

    onMeterClick(id, month, year) {
        console.log('props', this.props)
        console.log('state', this.state)
       var metrics = this.state.meteringData.deviceMetrics
       var stationList = this.props.devices
       HistoricDataExport({ stationId: id, day: 0, month: month, year: year, metrics, stationList });
    }

    renderTables() {
        var mReadings = this.state.meteringData.readings
        var deviceTypes = mReadings.map(i => i.deviceTypeId).filter((value, index, self) => self.indexOf(value) === index)
        var tempDeviceNames = { 1:'Energy Meters', 2:'Gas Meters' }
        return (deviceTypes.map(d => {
            var deviceData = mReadings.filter(x => x.deviceTypeId == d)
            return (this.renderTable(deviceData, tempDeviceNames[d]))
        }))
    }

    render() {
        return (<div style={{ height: '100%', width: '79%', display: 'inline-block', float: 'right' }}> 
                {this.state.isLoading && <OverlayLoader label='Generating data..' icon='fad fa-spinner-third' />}
                {this.state.meteringData && this.renderTables()}
            </div>)
    }
};

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(DeviceMeteringTables)
