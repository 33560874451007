import * as headerActions from '../actions/header'
import helper from '../utils/helpers'

const header = (state = { client: '', filtersLoaded: false, filters: {}, assets: [], users: [], filterToken: 'none', filterIndex: 0, editMapDash: false, fullScreen: false, gridFilter: [], activityFilter: null, mapDashBtn: false, route: [], childDevices: [] }, action) => {
  switch (action.type) {
    case headerActions.SET_FILTER_RESULT:
      return {
        ...state,
        assets: action.payload,
        filterToken: action.filterId,
        exportCapacity: action.exportCapacity,
        users: action.users,
        childDevices: action.childDevices
      }
    case headerActions.GOT_FILTER_OPTIONS:
      var res = action.payload
      const { currentFilter, optionList } = action.payload 
      var assets = state.assets;
      var users = null;
      if (currentFilter!= null) {
          assets = currentFilter.result
          users = currentFilter.users
      }

      var filters = {
           ...state.filters
      }
      for (var p in optionList) {
        if( optionList.hasOwnProperty(p) && p != "client") {
          if (p != "currentFilter") {
            var curVal = currentFilter.filters[p]
            filters[p] = { items:optionList[p], value:curVal, shown: (curVal)?true:false, open:false, display:formatName(p), 
              text:getListValue(optionList[p],curVal,p), init:false
            } 
          }
        } 
      }  
      var ret = {
        ...state,
        client: res.client,
        filters,
        filtersLoaded: true,
        assets,
        users,
        childDevices: currentFilter.childDevices
      }
      return ret;
    case headerActions.CHANGE_VALUE:
      var filters = {
           ...state.filters
      }
      if (filters.hasOwnProperty(action.filterTarget)) {
        filters[action.filterTarget].value = action.index
        filters[action.filterTarget].text = getListValue(filters[action.filterTarget].items,action.index,action.filterTarget)
        filters[action.filterTarget].open = !action.close
        filters[action.filterTarget].init = true
        filters[action.filterTarget].shown = true
      }
      return {
        ...state,
        filters
      }
    case headerActions.RESET_AND_SET_FILTER:
      var filters = {
           ...state.filters
      }
      var filterIndex = state.filterIndex + 0;
      for (var filtname in filters) {
        if (filters.hasOwnProperty(filtname)) {
          filters[filtname].value = (filtname == action.filterTarget)?action.index:null;
          filters[filtname].text = (filtname == action.filterTarget)?getListValue(filters[action.filterTarget].items,action.index,action.filterTarget):''
          filters[filtname].shown = (filtname == action.filterTarget)
          filters[filtname].init = true
          filters[filtname].open = false
          if (filtname == action.filterTarget) filterIndex += 1;
        }
      }
      return {
        ...state,
        filterIndex,
        filters
      }
    case headerActions.TOGGLE_TYPE_DISPLAY:
      var filters = {
           ...state.filters
      }
      var filterIndex = state.filterIndex + 0;
      if (filters.hasOwnProperty(action.filterTarget)) {
        var newValue = action.show
        filterIndex += newValue?1:-1;
        filters[action.filterTarget].shown = newValue
        filters[action.filterTarget].open = newValue // open by default
        filters[action.filterTarget].value = null
        filters[action.filterTarget].text = getListValue(filters[action.filterTarget].items,null,action.filterTarget)
      }
      return {
        ...state,
        filterIndex,
        filters
      }
    case headerActions.TOGGLE_FILTER_OPEN:
      var filters = {
           ...state.filters
      }
      if (filters.hasOwnProperty(action.filterTarget)) {
        filters[action.filterTarget].open = !filters[action.filterTarget].open
      }
      return {
        ...state,
        filters
      }
    case headerActions.GOT_STATION_METRICS:
      return {
        ...state,
        ...action.payload.metrics,
          alerts: action.payload.alerts,
          stale: action.payload.stale,
          scheduled: action.payload.scheduled,
          notifications: action.payload.notifications,
          userActivityStatus: action.payload.userActivityStatus
        }
    case headerActions.TOGGLE_FULL_SCREEN:
        console.log('toggleFullScreen', !action.fullScreen);
        return {
            ...state,
            fullScreen: !action.fullScreen,
            editMapDash: false
          }
    case headerActions.TOGGLE_EDIT_MAPDASH:   
        return {
            ...state,
            editMapDash: !action.editMapDash,
        }
    case headerActions.TOGGLE_BTN_MAPDASH:
        return {
            ...state,
            mapDashBtn: action.mapDashBtn
          }
    case headerActions.EDIT_BREADCRUMB:
        return {
            ...state,
            breadcrumb: action.breadcrumb
          }
    case headerActions.SAVE_GRID_FILTER:
        return {
            ...state,
            gridFilter: action.gridFilter
          }
      case headerActions.SAVE_ACTIVITY_FILTER:
        return {
            ...state,
            activityFilter: action.activityFilter
          }
      case headerActions.SET_MD_ID:
          return {
              ...state,
              detailId: action.newId
          }
    default: 
      return state
  }
}
export default header

const formatName = (string) => {
    string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string;
}

const getListValue = (list, idarr, listname) => {
  var ret = "";  
  var cnt = 0;
  
  if (idarr) {
    //console.log(listname, list)
    //console.log("idarr", idarr)
    if (idarr.length > 2) {
      ret = "Multiple " + formatName(listname);
    } else {
      var inlist = {}
      for (var i in list) {
          var item =list[i]
          for (var id of idarr) {
            if (!(id == null)) {
              if (item.id == parseInt(id) && inlist[id] == undefined) {
                inlist[id] = 1  
                if (ret) {
                  ret += ",";  
                }
                ret += item.name;
                cnt++;
              }
            }
          }
      }
    }
  }
  if (!ret) ret = "Any " + formatName(listname);
  return ret;
}