import React, { Component } from 'react';
import logo from '../../../../content/everun-logo.png';

class ImageWidget extends Component {

    render() {
        const clientImgSource = '../../../../content/' + this.props.client.toLowerCase() + '-logo.png';
        //<img src={this.props.selectedParam.params.imageURL} style={{ width: '100%', height: 'auto', display: 'block' }} />
        console.log('this.props.selectedParam', this.props.selectedParam);
        return (
            <div>
                <img src={clientImgSource} style={{ width: '100%', height: 'auto', display: 'block' }} />
            </div>
        );
    }
};

export default ImageWidget;