import React from 'react'

import { connect } from 'react-redux';
import { updateLiveConnection, toggleLiveConnection, disconnectLive, scadaSendCommand, SCADA_COMMANDS  } from '../../actions/scada';
import { ApiJsonPost } from '../../api/index';


export default class StandardButton extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = { progressClicked: false }
    }

    clickProgress()
    {
        const { submitController = "", submit = "",submitWFData, data,updateData, advanceLevel, level } = this.props;

        if (level) {
            if (submit) {
                submitWFData(submitController,submit, data)
                    .then((data) => {
                        console.log("Standard Button adv level", data)
                        var message = ""
                        if (data.msg) message = data.msg;
                        advanceLevel(level, message)
                    })
            } else {
                advanceLevel(level)
            }
        }
        else {
            if (submit) {
                submitWFData(submitController,submit)
                    .then((data) => {
                        console.log("Standard Button", data)
                        updateData(null,null, true, "Saved")
                    })
            }
        }
    }

    render() {
        const { title, data=0,className, disabled,advanceLevel, level, onButtonClick=() => {} } = this.props;
        var cls = "btn"
        if (className) {
            cls += className
        } else {
            cls += " btn-default"
        }

        return (
            <button key={title} type="button" disabled={disabled} className={cls} onClick={() => this.clickProgress()}>
                                        {title}
                                    </button>
        )
    }
}
