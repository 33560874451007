import React, { Component } from 'react'
import ColorPicker from 'rc-color-picker'
import CheckBox from './../../../components/Controls/CheckBox'
import { Input } from 'reactstrap'

class MultiMetricPicker extends Component {

    constructor(props) {
        super(props);
        this.state = {selectedMetrics: [], editMetric: null}
    }

    componentDidMount() {
        var tSel = []
        if (this.props.params) {
            this.props.params.map(x => {
                tSel.push(x.metricId)
            })
            this.setState({ selectedMetrics: tSel })
        }
    }

    addMetric(metricId) {
        var tMets = this.state.selectedMetrics
        if (!tMets.includes(metricId)) {
            tMets.push(metricId)
            this.setState({ selectedMetrics: tMets }, () => this.toggleEdit(metricId))
        }
    }

    toggleEdit(metricId) {
        //GET EXISTING METRIC DETAILS FROM PROPS
        var metricConfig = {}
        if (this.props.params && this.props.params.constructor === Array && this.props.params.find(x => x.metricId == metricId)) {
            metricConfig = this.props.params.find(x => x.metricId == metricId)
        }
        else {
            metricConfig.metricId = metricId
            metricConfig.type = 'column'
            metricConfig.colour = '#155263'
        }
        this.setState({ editMetric: metricConfig }, () => this.onChange())
    }

    updateMetricConfig(property, val) {
        var metricConfig = this.state.editMetric
        metricConfig[property] = val
        this.setState({ editMetric: metricConfig }, () => this.onChange())
    }

    onChange() {
        var fullConfig = this.props.params && this.props.params.constructor === Array ? this.props.params.filter(x => x.metricId != this.state.editMetric.metricId) : []

        fullConfig.push(this.state.editMetric)

        this.props.onChange(fullConfig)
    }

    removeMetric() {
        var fullConfig = this.props.params.constructor === Array ? this.props.params.filter(x => x.metricId != this.state.editMetric.metricId) : []
        this.props.onChange(fullConfig)
        var tSMets = this.state.selectedMetrics.filter(x => x != this.state.editMetric.metricId)
        this.setState({ editMetric : null, selectedMetrics: tSMets })
    }

    parseColour(e) {
        var rOpacity = Math.ceil(e.alpha / 5) * 5, rColour = e.color
        var opacityDict = {
            0: '00', 5: '0D', 10: '1A', 15: '26', 20: '33', 25: '40', 30: '4D', 35: '59', 40: '66', 45: '73', 50: '80',
            55: '8C', 60: '99', 65: 'A6', 70: 'B3', 75: 'BF', 80: 'CC', 85: 'D9', 90: 'E6', 95: 'F2', 100: 'FF'
        }
        var nOpacity = opacityDict[rOpacity]
        return rColour + nOpacity
    }

    render() {
        return (
            <div>
                <select className="form-control" onChange={e => this.addMetric(e.target.value)}  >
                    <option value={null}>Add Series</option>
                    {
                        this.props.metrics.map(i => {
                            return <option key={i.id} value={i.id}>{i.title}</option>
                        })
                    }
                </select>

                <div className='widgetMgmtBox' style={{height: 'auto', marginTop:'5px' }}>
                    {this.state.selectedMetrics.map((x,i) => {
                        var m = this.props.metrics.find(j => j.id == x)
                        if (m) {
                            return (
                                <div className='widgetMgmtBox' key={'wi' + m.id + i} style={{ padding: '5px', margin: '5px', height: 'unset' }}>
                                    <span>{m.title}</span>
                                    <i className='fal fa-ellipsis-v' style={{ float: 'right', padding: '5px 10px', cursor: 'pointer' }} onClick={() => this.toggleEdit(m.id)} />
                                </div>)
                        }
                    })}
                </div>

                {this.state.editMetric && <div className='widgetMgmtBox' style={{ height: 'auto', marginTop: '5px', padding: '7px' }}>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Plot Type</label>
                        <div className="col-sm-8">
                            <select className="form-control" defaultValue={this.state.editMetric.type} onChange={(e) => this.updateMetricConfig('type',e.target.value)}  >
                                <option value='column'>Column</option>
                                <option value='line'>Line</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Plot colour</label>
                        <div className="col-sm-8">
                            <ColorPicker color={this.state.editMetric.colour} placement="bottomRight" onChange={(e) => this.updateMetricConfig('colour', this.parseColour(e))} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Trendline</label>
                        <div className="col-sm-8">
                            <CheckBox checked={this.state.editMetric.trendLine} onChange={(e) => this.updateMetricConfig('trendLine', e.checked)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Target</label>
                        <div className="col-sm-5">
                            <Input defaultValue={this.state.editMetric.target} onBlur={(e) => this.updateMetricConfig('target', e.target.value)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Show Labels</label>
                        <div className="col-sm-8">
                            <CheckBox checked={this.state.editMetric.showLabels} onChange={(e) => this.updateMetricConfig('showLabels', e.checked)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Rename</label>
                        <div className="col-sm-5">
                            <Input defaultValue={this.state.editMetric.rename} onBlur={(e) => this.updateMetricConfig('rename', e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label">Multiplier</label>
                        <div className="col-sm-5">
                            <Input defaultValue={this.state.editMetric.multiplier} onBlur={(e) => this.updateMetricConfig('multiplier', e.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <button className="btn btn-primary" style={{ float: 'right' }} type="button" onClick={() => this.removeMetric()}>Remove</button>
                        </div>
                    </div>

                </div>}
            </div>
        )
    }
};

export default MultiMetricPicker;