import React, { Component } from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';

export default class SimpleModal extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const { header, body, footer, open, width } = this.props;
        return (
            <Modal isOpen={open} autoFocus={false} size='lg' style={{ width: '100%', maxWidth: (width ? width :'1000px')}} >
                <ModalHeader>
                    {header}
                </ModalHeader>
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalFooter>
                    {footer}
                </ModalFooter>
            </Modal>
        );
    }
}