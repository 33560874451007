import React, { Component } from 'react';
import { Modal, ModalFooter, ModalBody, Row, Col } from 'reactstrap';
import { ApiPost, getConfigItem } from '../api';
import moment from 'moment';

export default class ResponseLevel extends Component {

    constructor(props) {
        super(props);
        this.state = { responseOpen: false, responseData: null }
    }

    getWeekOfDate(innerDate) {
        if (this.props.mode == 'year' ) {
            const thursday = innerDate.setDate(innerDate.getDate() - ((innerDate.getDay() + 6) % 7) + 3);

            let tdt = new Date(thursday.valueOf());
            tdt.setMonth(0, 1);
            tdt.setMonth(0, 1 + ((11 - tdt.getDay())) % 7);

            var index = 1 + Math.ceil((thursday - tdt) / (7 * 24 * 60 * 60 * 1000))
            return index;
        }
        else {
            var date = new Date(innerDate);
            var result = date.toLocaleDateString('en-gb', { weekday: 'long' });
            var index = result == 'Monday' ? 1 : result == 'Tuesday' ? 2 : result == 'Wednesday' ? 3 : result == 'Thursday' ? 4 : result == 'Friday' ? 5 : result == 'Saturday' ? 6 : result == 'Sunday' ? 7 : null;
            return index;
        }
    }

    getResponseData(id, level) {
        const response = { id: id, response: parseInt(level.replace('L', '')) }
        ApiPost('Monitoring', 'GetResponseData', response)
        .then((data) => {
            this.setState({ responseData: data });
        });
    }

    getActivityData(sName, level, week, data) {
        var tempData = [];
        data.map((x) => {
            if ((this.props.parent == 'ActivityUser' ? x.stationName == sName : x.workcard.engineerNames.includes(sName))  && x.workcard.responseLevel == parseInt(level.replace('L', ''))) {
                var innerWeek = this.getWeekOfDate(new Date(x.activityDate));
                if (innerWeek == week) {
                    tempData.push({ caseId: x.incidentId, name: 'workCarriedOut: ' + x.workcard.workCarriedOut, startTime: x.activityDate, notes: 'Notes: ' + x.workcard.notes, incidentType: x.workcard.status == '0' ? 'Open' : 'Closed' });
                }
            }
        })

        this.setState({ responseData: tempData });
    }

    toggleResponse(modalId) {
        if (this.props.clickable) {
            if (this.props.parent == 'Activity' || this.props.parent == 'ActivityUser') {
                this.getActivityData(this.props.parentId, this.props.level, this.props.week, this.props.data);
                this.setState({ [modalId]: !this.state[modalId] })
            }                     
            else {
                this.getResponseData(this.props.parentId, this.props.level);
                this.setState({ [modalId]: !this.state[modalId] })
            }
        }
    }

    renderModal() {
        //console.log('rD', this.state.responseData)
        return (
            <Modal key={'md' + this.props.level} isOpen={this.state.responseOpen} autoFocus={false} style={{ maxWidth: '400px' }} modalTransition={{ timeout: 0 }} >
                <ModalBody style={{ height: '500px', backgroundColor: 'rgb(243, 243, 243)' }}>
                    <div className={"responseLevel response" + (this.props.level)} style={{ width: '100px' }} >
                        {this.getLevelRenderer()}
                    </div>
                    <div style={{ overflowX: 'hidden', maxHeight: '450px' }}>
                        {this.state.responseData ?
                            this.state.responseData.map(x => {
                                return (
                                    <div style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                        <Row style={{ height: '21px' }}>
                                            <Col sm={6}>
                                                <b>{x.incidentType}</b>
                                            </Col>
                                            <Col sm={6}>
                                                <p style={{ float: 'right'}}><b>{moment(x.startTime).format('DD/MM/YY HH:mm')}</b></p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <b>Notes:</b>{x.notes.slice(6)}
                                            </Col>
                                        </Row> 
                                        <Row>
                                            <Col sm={12}>                                                
                                                <p>
                                                    <b>WorkCarriedOut: </b>{x.name.slice(14)}
                                                    <span>
                                                        <button style={{ WebkitAppearance: 'searchfield-cancel-button' }} onClick={() => this.props.history.push('Workcards/' + x.caseId, { history: null })}><b>...</b></button>
                                                    </span>
                                                </p>
                                            </Col>
                                        </Row>                                                                              
                                    </div>)
                            }) : null
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-default" onClick={() => this.toggleResponse('responseOpen')}>Close</button>
                </ModalFooter>
            </Modal>)
    }

    getLevelRenderer() {
        var tL = parseInt(this.props.level.replace('L', ''))
        if (getConfigItem('$.operations.responseLevels') && getConfigItem('$.operations.responseLevels')[tL]) {
            var t = getConfigItem('$.operations.responseLevels')[tL]
            //console.log('props', this.props)
            if (t.type == 'icon') {
                return <i className={t.n} />
            }
        }
        else if (getConfigItem('$.workcards.customResponses') && getConfigItem('$.workcards.customResponses')[tL]) {
            var t = getConfigItem('$.workcards.customResponses')[tL]
            return <span>{t.name}</span>
        }
        else {
            return tL == 18 ? 'Note' : tL == 99 ? <i className='fal fa-calendar-plus' /> : tL == 100 ? <i className='fal fa-wrench' /> : this.props.level
        }

    }

    render() {
        return ([
            <div key={'rL' + this.props.level} className={"responseLevel response" + (this.props.level) + (this.props.summary ? ' summary' : ' ') + (this.props.class ? ' ' + this.props.class : ' ')} style={this.props.style} onClick={() => { this.toggleResponse('responseOpen') }} onDoubleClick={(e) => { e.stopPropagation() }} >
                {this.props.summary ? this.props.value : this.getLevelRenderer()}
                {this.props.icon && <i style={{marginLeft:'5px'}} className={this.props.icon} />}
            </div>,
            this.state.responseOpen ? this.renderModal() : null
        ]
        );
    }
}