import moment from 'moment';

export default class helpers  {

    constructor() {
    } 

    static reduceNull(arr) {
        return arr.reduce(function(result, element) {
            if (element) { 
                result = result.concat(element)
            }
            return result
        },[])
    }

    static S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1); 
    }

    static GetGuid() {
        var h = helpers;
        return (h.S4() + h.S4() + "-" + h.S4() + "-4" + h.S4().substr(0,3) + "-" + h.S4() + "-" + h.S4() + h.S4() + h.S4()).toLowerCase()
    }

    static getDayName(addDays) {

        if (addDays == undefined) {
            addDays = 0;
        }

        return new Date(new Date().setDate(new Date().getDate() + addDays)).toLocaleString(undefined, { weekday: 'long' });
    }

    static getMonthName(addMonths) {
  
        if (addMonths == undefined)
        {
            addMonths = 0;
        }

        return new Date(new Date().setMonth(new Date().getMonth() + addMonths)).toLocaleString(undefined, { month: 'long', year: 'numeric' });
    }

    static getYear(addYears) {

        if (addYears == undefined) {
            addYears = 0;
        }

        return (new Date().getUTCFullYear() + addYears).toString();
    }

    static parseGMTTime(dT) {
        var dtOffset = moment(dT).utcOffset()
        return moment(dT).add(dtOffset, 'minutes')
    }

    static parseRange(startDate, endDate, showDuration) {
        var stzOffset = moment(startDate).utcOffset()
        var etzOffset = moment(endDate).utcOffset()
        var sDate = moment(startDate).add(stzOffset, 'minutes')
        var eDate = moment(endDate).add(etzOffset, 'minutes')
        if (eDate.isValid()) {
            if (sDate.isSame(eDate, 'year'))
            {
                if (sDate.isSame(eDate, 'day'))
                {
                    //return sDate.format('Do MMM HH:mm') + " - " + eDate.format('HH:mm')
                    return sDate.format('DD/MM HH:mm') + ' - ' + eDate.format('HH:mm') + (showDuration ? ' (' + this.parseDuration(sDate, eDate) + ')' : '')
                }
                else {
                    //return sDate.format('Do MMM HH:mm') + " - " + eDate.format('Do MMM HH:mm')
                    return sDate.format('DD/MM HH:mm') + ' - ' + eDate.format('DD/MM HH:mm') + (showDuration ? ' (' + this.parseDuration(sDate, eDate) + ')' : '')
                }
            }
            else {
                //return sDate.format('HH:mm Do MMM YY') + " - " + eDate.format('HH:mm Do MMM YY')
                return sDate.format('DD/MM/YY HH:mm') + ' - ' + eDate.format('DD/MM/YY HH:mm') + (showDuration ? ' (' + this.parseDuration(sDate, eDate) + ')' : '')
            }
        }
        else {
            return sDate.format('DD/MM HH:mm')
        }
    }

    static parseDuration(startDate, endDate, totalDuration, showAll) {
        const duration = totalDuration ? moment.duration(totalDuration, 'minutes') : moment.duration(endDate.diff(startDate))
        var total = ''

        if (duration.months() > 0) {
            total += duration.months() + 'M '
        }
        if (duration.days() > 0) {
            total += duration.days() + 'd '
        }
        if ((duration.hours() > 0) || showAll) {
            total += duration.hours() + 'h '
        }
        if ((duration.days() == 0 && duration.minutes() > 0) || showAll) {
            total += duration.minutes() + 'm '
        }
        if (showAll) {
            total += duration.seconds() + 's '
        }
        total = total.slice(0,-1)

        return total;
    }

    static randomColour() {
        var distinctColours = ["#000000", "#FFFF00", "#1CE6FF", "#FF34FF", "#FF4A46", "#008941", "#006FA6", "#A30059",
            "#FFDBE5", "#7A4900", "#0000A6", "#63FFAC", "#B79762", "#004D43", "#8FB0FF", "#997D87",
            "#5A0007", "#809693", "#FEFFE6", "#1B4400", "#4FC601", "#3B5DFF", "#4A3B53", "#FF2F80",
            "#61615A", "#BA0900", "#6B7900", "#00C2A0", "#FFAA92", "#FF90C9", "#B903AA", "#D16100",
            "#DDEFFF", "#000035", "#7B4F4B", "#A1C299", "#300018", "#0AA6D8", "#013349", "#00846F",
            "#372101", "#FFB500", "#C2FFED", "#A079BF", "#CC0744", "#C0B9B2", "#C2FF99", "#001E09",
            "#00489C", "#6F0062", "#0CBD66", "#EEC3FF", "#456D75", "#B77B68", "#7A87A1", "#788D66",
            "#885578", "#FAD09F", "#FF8A9A", "#D157A0", "#BEC459", "#456648", "#0086ED", "#886F4C",
            "#34362D", "#B4A8BD", "#00A6AA", "#452C2C", "#636375", "#A3C8C9", "#FF913F", "#938A81",
            "#575329", "#00FECF", "#B05B6F", "#8CD0FF", "#3B9700", "#04F757", "#C8A1A1", "#1E6E00",
            "#7900D7", "#A77500", "#6367A9", "#A05837", "#6B002C", "#772600", "#D790FF", "#9B9700",
            "#549E79", "#FFF69F", "#201625", "#72418F", "#BC23FF", "#99ADC0", "#3A2465", "#922329",
            "#5B4534", "#FDE8DC", "#404E55", "#0089A3", "#CB7E98", "#A4E804", "#324E72", "#6A3A4C",
            "#83AB58", "#001C1E", "#D1F7CE", "#004B28", "#C8D0F6", "#A3A489", "#806C66", "#222800",
            "#BF5650", "#E83000", "#66796D", "#DA007C", "#FF1A59", "#8ADBB4", "#1E0200", "#5B4E51",
            "#C895C5", "#320033", "#FF6832", "#66E1D3", "#CFCDAC", "#D0AC94", "#7ED379", "#012C58",
            "#7A7BFF", "#D68E01", "#353339", "#78AFA1", "#FEB2C6", "#75797C", "#837393", "#943A4D",
            "#B5F4FF", "#D2DCD5", "#9556BD", "#6A714A", "#001325", "#02525F", "#0AA3F7", "#E98176",
            "#DBD5DD", "#5EBCD1", "#3D4F44", "#7E6405", "#02684E", "#962B75", "#8D8546", "#9695C5",
            "#E773CE", "#D86A78", "#3E89BE", "#CA834E", "#518A87", "#5B113C", "#55813B", "#E704C4",
            "#00005F", "#A97399", "#4B8160", "#59738A", "#FF5DA7", "#F7C9BF", "#643127", "#513A01",
            "#6B94AA", "#51A058", "#A45B02", "#1D1702", "#E20027", "#E7AB63", "#4C6001", "#9C6966",
            "#64547B", "#97979E", "#006A66", "#391406", "#F4D749", "#0045D2", "#006C31", "#DDB6D0",
            "#7C6571", "#9FB2A4", "#00D891", "#15A08A", "#BC65E9", "#FFFFFE", "#C6DC99", "#203B3C",
            "#671190", "#6B3A64", "#F5E1FF", "#FFA0F2", "#CCAA35", "#374527", "#8BB400", "#797868",
            "#C6005A", "#3B000A", "#C86240", "#29607C", "#402334", "#7D5A44", "#CCB87C", "#B88183",
            "#AA5199", "#B5D6C3", "#A38469", "#9F94F0", "#A74571", "#B894A6", "#71BB8C", "#00B433",
            "#789EC9", "#6D80BA", "#953F00", "#5EFF03", "#E4FFFC", "#1BE177", "#BCB1E5", "#76912F",
            "#003109", "#0060CD", "#D20096", "#895563", "#29201D", "#5B3213", "#A76F42", "#89412E",
            "#1A3A2A", "#494B5A", "#A88C85", "#F4ABAA", "#A3F3AB", "#00C6C8", "#EA8B66", "#958A9F",
            "#BDC9D2", "#9FA064", "#BE4700", "#658188", "#83A485", "#453C23", "#47675D", "#3A3F00",
            "#061203", "#DFFB71", "#868E7E", "#98D058", "#6C8F7D", "#D7BFC2", "#3C3E6E", "#D83D66",
            "#2F5D9B", "#6C5E46", "#D25B88", "#5B656C", "#00B57F", "#545C46", "#866097", "#365D25",
            "#252F99", "#00CCFF", "#674E60", "#FC009C", "#92896B"]
        var colour = distinctColours[Math.floor(Math.random() * distinctColours.length)]
        return colour
    }

    static DDtoDDM(value, combine = false) {
        console.log('value', value)
        var ddLat = value.split(',')[0],
            ddLng = value.split(',')[1],
            latDir = 'N', 
            lngDir = 'W'

        if (ddLat < 0) {
            latDir = 'S'
        }
        if (ddLng > 0) {
            lngDir = 'E'
        }

        var latDeg = Math.abs(ddLat)
        var lngDeg = Math.abs(ddLng)

        var latDec = Math.trunc(latDeg)
        var lngDec = Math.trunc(lngDeg)

        var latM = ((latDeg - latDec) * 60).toFixed(1)
        var lngM = ((lngDeg - lngDec) * 60).toFixed(1)

        console.log({ latDeg, lngDeg, latM, latDec, lngDec, lngM })

        if (combine) {
            var ddmLat = latDec + '�' + latM + '� ' + latDir
            var ddmLng = lngDec + '�' + lngM + '� ' + lngDir
            return { lat:ddmLat, lng: ddmLng }
        }
        else {
            return { latDec, latM, latDir, lngDec, lngM, lngDir }
        }
    }

    static DDMtoDD(latDec, latM, latDir, lngDec, lngM, lngDir, combine = true) {
        var latDD = Math.abs(latDec) 
        var lngDD = Math.abs(lngDec)
        var latD = Math.abs(latM) / 60
        var lngD = Math.abs(lngM) / 60
        latDD += latD
        lngDD += lngD
        var lat = latDir == 'S' ? (latDD * -1) : latDD
        var lng = lngDir == 'W' ? (lngDD * -1) : lngDD
        if (combine) {
            return lat + ',' + lng
        }
        else {
            return {lat, lng}
        }
    }

    static toRadians(degrees) {
        return degrees * Math.PI / 180
    }

    static toDegrees(radians) {
        return radians * 180 / Math.PI
    }

    static getBearing(startLat, startLng, destLat, destLng) {
        startLat = this.toRadians(startLat)
        startLng = this.toRadians(startLng)
        destLat = this.toRadians(destLat)
        destLng = this.toRadians(destLng)

        var y = Math.sin(destLng - startLng) * Math.cos(destLat)
        var x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
        var brng = Math.atan2(y, x)
        brng = this.toDegrees(brng)
        return (brng + 360) % 360
    }

    static getMidPoint(startLat, startLng, destLat, destLng) {
        var dLon = this.toRadians(startLng - destLng)

        //convert to radians
        var lat1 = this.toRadians(startLat)
        var lat2 = this.toRadians(destLat)
        var lon1 = this.toRadians(startLng)

        var Bx = Math.cos(lat2) * Math.cos(dLon)
        var By = Math.cos(lat2) * Math.sin(dLon)
        var lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By))
        var lon3 = lon1 + Math.atan2(By, Math.cos(lat1) + Bx)

        var r = { lat: this.toDegrees(lat3), lng: this.toDegrees(lon3) }
        return r
    }

   static getChildren(parent, sourceData) {
        var allChildrenList = []
        for (var x in sourceData) {
            if (sourceData[x].parentId == parent.id) {
                var children = this.getChildren(sourceData[x], sourceData)
                if (children.length) {
                    allChildrenList.push(children)
                }
                allChildrenList.push(sourceData[x])
            }
        }
        return allChildrenList
    }

    static wrangleTreeData(sourceData) {
        var topLevels = sourceData.filter(x => x.parentId == null)
        var treeList = []
        if (topLevels.length == sourceData.length) {
            var cleanChildren = []
            sourceData.map(x => {
                cleanChildren.push({ value: x.id, label: x.name })
            })
            treeList.push({ value: 'all', label: 'All Sites', children: cleanChildren })
        }
        else {
            topLevels.map(x => {
                var t = { value: x.id, label: x.name, children: [] }
                var children = this.getChildren(x, sourceData)
                var flat = [].concat.apply([], children)
                var deviceTypes = flat.map(i => i.deviceName).filter((value, index, self) => self.indexOf(value) === index)
                deviceTypes.map(q => {
                    var tType = { value: q, label: q, children: [] }
                    var tC = flat.filter(f => f.deviceName == q)
                    tC.map(v => {
                        tType.children.push({ value: v.id, label: v.name })
                    })
                    t.children.push(tType)
                })
                treeList.push(t)
            })
        }
        return (treeList)
    }

    static simpleStringify(a) {
        var total = []
        a.map(object => {
            var simpleObject = {}
            for (var prop in object) {
                console.log()
                if (!object.hasOwnProperty(prop)) {
                    continue;
                }
                if (typeof (object[prop]) == 'object') {
                    continue;
                }
                if (typeof (object[prop]) == 'function') {
                    continue;
                }
                simpleObject[prop] = object[prop];
            }
            total.push(simpleObject)
        })

        return JSON.stringify(total);
    }
} 