import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './components/App';
import Login from './scenes/Login/components/Login';
import Policy from './scenes/Login/components/Policy';
import ReportDefault from './scenes/Reports/components/ReportDefault';
import WelcomePage from './scenes/Accounts/components/WelcomePage';

import store from './configure-store';

const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.register("/sw.js", {
                scope: "/",
            });
            if (registration.installing) {
                console.log("Service worker installing");
            } else if (registration.waiting) {
                console.log("Service worker installed");
            } else if (registration.active) {
                console.log("Service worker active");
            }
        } catch (error) {
            console.error(`Registration failed with ${error}`);
        }
    }
};

ReactDOM.render(
    <Provider store={store()}>
        <BrowserRouter>
            <Switch>
                <Route exact path="/Login" name="Login" component={Login} />                
                <Route exact path="/Policy" name="Policy" component={Policy} />  
                <Route exact path="/ReportDefault" name="ReportDefault" component={ReportDefault} />
                <Route path="/WelcomePage" name="WelcomePage" component={WelcomePage} />
                <Route path="/" name="Home" component={App} />
            </Switch>
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));

registerServiceWorker();

//serviceWorkerRegistration.register();