import React from 'react'
import ListCrud from './ListCrud'
import Simpleform from './simpleform'
import TestForm from './TestForm'
import { ApiGet } from '../../api'

export default class TestControl extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            user: "Unknown"
        }
    }
    
    test() {
        console.log("recx", this)
        let me = this;
        ApiGet('auth','UsersOnline')
            .then((data) => {
                console.log("user", data)
                this.setState( { user: data })
            });
    }

    render() {
        const { user } = this.state
        return (
            <div>
                <ListCrud model="Test" layout="modal">
                    {
                        {
                            row: (rec) => rec.name + " " + rec.id,
                            form: <TestForm />
                        }
                    }
                </ListCrud>
                {user}
                <button onClick={() => this.test()}>Get User</button>
            </div>
        )
    }
}