import React, { Component } from 'react'
import { Col, Label, Row } from 'reactstrap'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { withRouter } from 'react-router-dom'

class ValidationRange extends Component {

    constructor(props) {
        super(props);
        this.state = { val: { op: '>..<', opVal: null, opVal2: null, type: 'range'} }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.data != this.props.data) {
            if (nextProps.data && nextProps.data.rangeValidation) {
                if (nextProps.data.rangeValidation.includes('{')) {
                    this.setState({ val: JSON.parse(nextProps.data.rangeValidation) })
                }
            }
        }
        return true
    }

    setValidation(val, type) {
        console.log('setValidation', { val, type })
        var t = this.state.val
        t[type] = val

        this.setState({ val: t })
        this.props.onChange(JSON.stringify(t))
    }

    render() {
        if (this.props.data) {
            return (
                <div style={{ width: '100%' }}>
                    <Row style={{ height: '33.5px' }}>
                        <Col xs={12} sm={2}><Label ><b>Validation Range</b></Label></Col>
                        <Col xs={12} sm={1}>
                            <span style={{lineHeight:'33.5px'}}>Between</span>
                        </Col>
                        <Col xs={12} sm={1}>
                            <input className="input" className="form-control" defaultValue={this.state.val.opVal || ''} placeholder="Value" onChange={(e) => this.setValidation(e.target.value, 'opVal')} />
                        </Col>
                        <Col xs={12} sm={1}>
                            <span style={{ lineHeight: '33.5px' }}>and</span>
                        </Col>
                        <Col xs={12} sm={1}>
                            <input className="input" className="form-control" defaultValue={this.state.val.opVal2 || ''} placeholder="Value" onChange={(e) => this.setValidation(e.target.value, 'opVal2')} />
                        </Col>
                    </Row>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export default withRouter(ValidationRange)
