import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import MasterDetail from 'MasterDetail'
const SERVER_PATH = process.env.SERVER_PATH;

class RTUSettings extends Component {

    //mdRefCallback = (instance) => {
    //    this.mdRTU = instance ? instance.getWrappedInstance() : null;
    //}

	constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        //this.Connect = this.Connect.bind(this);        
        this.state = {
            activeTab: '1', mainActiveTab: '1', fileData: '', fileName: '', compile: '0',
            devices: [], connect: true
        };
    }       

    componentDidMount() {
        console.log("in componentDidMount");
        this.setState({ connect: false })
        //console.log("in componentDidMount", this.mdRTU.getFromHistory(['selectedOption']));
        //this.setState(this.mdRTU.getFromHistory(['selectedOption']))
    }


    componentWillReceiveProps() {
        console.log("in componentWillReceiveProps");
        console.log("this.props.match.params.id", this.props.match.params.id);        
        if(this.props.match.params.id == undefined)
        {
            this.setState({ connect: true })
        }
    }    

    toggle(tab, type) {
        if (type == 'main') {           
            if (this.state.mainActiveTab !== tab) {                
                this.setState({ mainActiveTab: tab });                  
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    onCompile() {

        var configData = new FormData(); 
        fetch(SERVER_PATH + '/crud/' + "RTUSettings" + "/" + "createConfig", { method: "POST", body: configData, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
            .then(function (response) {
                return response.blob();
            }).then((configData) => {
                configData.lastModifiedDate = new Date();
                configData.name = "config.txt";
                this.setState({ fileData: configData, fileName: "config.txt"}, () => this.DownLoadFile());
            });  

        var scriptData = new FormData(); 

        fetch(SERVER_PATH + '/crud/' + "RTUSettings" + "/" + "createScript", { method: "POST", body: scriptData, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
            .then(function (response) {
                return response.blob();
            }).then((scriptData) => {
                scriptData.lastModifiedDate = new Date();
                scriptData.name = "program.bas";
                this.setState({ fileData: scriptData, fileName: "program.bas" }, () => this.DownLoadFile());
            });

        //document.getElementById("script").style.display = "block";
        document.getElementById("deploy").style.display = "block";

    }

    Enable() {
        document.getElementById("compile").style.display = "block";
    }

    Disable() {
        console.log("in Disable");
        document.getElementById("compile").style.display = "none";
    }


    DownLoadFile() {

        var a = window.document.createElement("a");
        var data = new Blob([this.state.fileData], { type: "application/octet-stream" });
        a.href = window.URL.createObjectURL(data);
        a.download = this.state.fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    //onViewScript() {

    //    console.log("im in onViewScript");

    //    fetch(SERVER_PATH + '/crud/' + "RTUSettings" + "/" + "ViewScript", { method: "POST", credentials: 'include' });       
    //}

    onDeploy() {

        console.log("im in onViewScript");

        fetch(SERVER_PATH + '/crud/' + "RTUSettings" + "/" + "Deploy", { method: "POST", headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } });
        document.getElementById("deploy").style.display = "none";
    }

    Connect(rec) {
        console.log("in Connect");
        console.log(" rec", rec);
        fetch(SERVER_PATH + '/crud/' + "RTUSettings" + "/" + "Download", { method: "POST", headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }); 
        this.setState({ connect: false });
        //this.mdRTU.pushToHistory('selectedOption', rec)
    }
    

    render() {

        const { mainActiveTab } = this.state;
        console.log("Rtu props", this.props);
		var SiteFields = [
			{label: "Id", fieldId: "id", display:'none'},
			{label: "Name", fieldId: "name"},
            { label: "IP", fieldId: "ip" },
            { label: "", fieldId: "funcButton", display: 'form', type: 'funcButton', typeProps: { label: "Connect", func: (rec) => this.Connect(rec) } },
            { label: "", fieldId: 'navButton', display: "grid", type: 'navButton'}		
		];
		var SiteConfig = {
            model: 'RTUSettings',
			fields: SiteFields,
            master: true,
            gridButton: false,
            titleField: 'name',
            //crudParams: { downloadParam: this.state.connect },
        }

        var EsyncDeviceFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Device", fieldId: "device" },
            { label: 'Available', fieldId: 'available', display: 'grid' },
            { label: 'Include', fieldId: 'include', type: 'checkbox', display: 'grid' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/DeviceSettings/' }},
        ];

        var EsyncDevicesConfig = {
            model: 'DeviceSettings',
            path: '/RTUSettings/GetSiteDevices/' + this.props.match.params.id,
            fields: EsyncDeviceFields,
            gridButton: { pathname: '/DeviceSettings/New' },
            titleField: 'device',
        }

        var ExistingTagFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Tag Name", fieldId: "tag" },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/TagSettings/' } },
        ];

        var ExistingTagConfig = {
            model: 'ExistingTag',
            path: '/RTUSettings/GetExistingTags/' + this.props.match.params.id,
            fields: ExistingTagFields,           
            gridButton: false
        }

        var TagsToAddFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Tag Name", fieldId: "tag" },
            { label: 'View', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/TagSettings/' } }
        ];

        var TagsToAddConfig = {
            model: 'TagsToAdd',
            path: '/RTUSettings/GetTagsToAdd/' + this.props.match.params.id,
            fields: TagsToAddFields,
            gridButton: false
        }

        var VariablesFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Section", fieldId: "device" },
            { label: "Settings", fieldId: "setting" },
            { label: "Variable", fieldId: "variable" },
            { label: 'View', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/TagSettings/' } }
        ];

        var VariablesConfig = {
            model: 'Variables',
            path: '/RTUSettings/GetVariables/' + this.props.match.params.id,
            fields: VariablesFields,
            gridButton: false
        }

		return(
            <div>
                <div className="d-flex justify-content-end" style={{ borderBottom: '1px solid rgba(99, 99, 99, 0.2)' }}>
                    <div className="mr-auto p-2">
                        <h1>RTU's</h1>
                    </div>
                </div>
                <div>                 
                    <MasterDetail key='mdss' wrappedComponentRef={this.mdRefCallback} {...SiteConfig}>
                        {console.log('device props', this.props)}
                        {console.log('connect state', this.state.connect)}
                        {this.props.match.params.id != 'New' && this.props.match.params.id != 'undefined' && this.state.connect == false ?
                            [<Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ active: mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Apps/Devices</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Existing station tags</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: mainActiveTab === '3' })} onClick={() => { this.toggle('3', 'main') }}>Tags to Add</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: mainActiveTab === '4' })} onClick={() => { this.toggle('4', 'main') }}>Variables</NavLink>
                                </NavItem>
                            </Nav>,
                                <TabContent key='clientTabs' activeTab={this.state.mainActiveTab}>
                                    {console.log('activeTab', this.state.mainActiveTab)}
                                    <TabPane tabId='1'>
                                        {this.state.mainActiveTab == '1' ? <MasterDetail key='mddc' {...EsyncDevicesConfig} /> : null}
                                    </TabPane>
                                    <TabPane tabId='2'>
                                        {this.state.mainActiveTab == '2' ? <MasterDetail key='mdtu' {...ExistingTagConfig} /> : null}
                                    </TabPane>
                                    <TabPane tabId='3'>
                                        {this.state.mainActiveTab == '3' ? <MasterDetail key='mdav' {...TagsToAddConfig} /> : null}
                                    </TabPane>
                                    <TabPane tabId='4'>
                                        {this.state.mainActiveTab == '4' ? <MasterDetail key='mdvc' {...VariablesConfig} /> : null}
                                    </TabPane>
                                </TabContent>] : null}                          
                    </MasterDetail>
                </div>                
                <div style={{ marginBottom: '5px' }}> 
                    {this.props.match.params.id != 'New' && this.props.match.params.id != 'undefined' && this.state.connect == false ?
                        <button color="btn btn-" type="button" id="compile" className="btn btn-default" onClick={() => this.onCompile()}>Compile</button>
                        : null
                    }
                </div> 
                <div style={{ marginBottom: '5px' }}>
                    <button color="btn btn-" type="button" id="deploy" style={{ display: 'none' }} className="btn btn-default" onClick={() => this.onDeploy()}>Deploy</button>
                </div>
                <div style={{ marginBottom: '5px' }}>
                    <button color="btn btn-" type="button" id="script" style={{ display: 'none' }} className="btn btn-default" onClick={() => this.onViewScript()}>View Scripts</button>
                </div>                
		    </div>		
		)
	}
}
export default (RTUSettings)


//<div>
//    <div>Apps/Devices</div>
//    <div>
//        <MasterDetail key='mdcc' {...EsyncDevicesConfig} >                                        
//        </MasterDetail>
//    </div>
//</div>