import React, { Component } from 'react'
import { Col, Row, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { fieldServiceReportStatuses, dueByStatuses } from '../../../configuration/enums'
import { ApiPost, getConfigItem } from '../../../api'
import moment from 'moment'
import WorkCards from '../../WorkCards/components/WorkCards'
import Select from 'react-select'
import SearchList from './../../../components/SearchList'
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

class CalendarModal extends Component {

	constructor(props) {
        super(props);
        var jobInfoEnabled = getConfigItem('$.general.viewJobInfo') || 'true'
        console.log('props',props)
        var activityDate = props.job.date ? moment(props.job.date, 'DD/MM/YY').format('YYYY-MM-DD') : props.job.activityDate ? moment(props.job.activityDate).format('YYYY-MM-DD') : null
        console.log('activityDate', activityDate)
        this.state = {
            jobStatus: props.job.jobStatus,
            deviceId: props.job.deviceId ? props.job.deviceId : props.preRec && props.preRec.deviceId ? props.preRec.deviceId : null,
            dispatchNote: props.job.note || '',
            workCarriedOut: props.job.workCarriedOut || '',
            eventStartTime: props.job.startTimestamp ? moment(props.job.startTimestamp).format('HH:mm') : '09:00',
            eventEndTime: props.job.endTimestamp ? moment(props.job.endTimestamp).format('HH:mm') : '17:00',
            fsrStatus: props.job.fsrStatus || 1,
            dueBy: props.job.dueBy,
            users: props.job.users,
            jobInfoEnabled,
            error: false,
            sites: props.customerWithSites,
            scheduledTime: props.job.scheduledTime,
            activityDate
        }
    }

    componentDidMount() {
    }

    deleteEvent() {
    
    }

    getMenuButton(job) {
        var colId = parseInt(this.props.colId)
        if ((job.users.length < 2 && job.usrsInfo.length > 0 && (job.usrsInfo[0].onSiteTime == null)) || job.users.length == 0) {
            return (<Button onClick={() => this.props.removeEvent(job.calId, colId)}>Delete</Button>)
        }
        else if (job.users.length > 1 && (job.usrsInfo.every(x => x.onSiteTime == null))) {
            return (
                <Menu menuButton={<MenuButton className='btn'>Delete</MenuButton>} >
                    <MenuItem onClick={() => this.props.removeEvent(job.calId)}>for all users</MenuItem>
                    <MenuItem onClick={() => this.props.removeEvent(job.calId, colId)}>only this user</MenuItem>
                </Menu>)
        }
        else {
            return null
        }
    }

    setTime(type, v) {
        var value = v.target.value
        if (this.state[type] != null && value != this.state[type]) {
            v.currentTarget.blur()
        }
        this.setState({ [type]: value })
    }

    saveAction() {
        var saveType = this.props.edit ? 'modify' : this.props.job.existingEvent ? 'existing' : 'new', validated = true
        var requiredFields = []
        if (!this.props.edit) {
            console.log('save action', this.props)
            if (this.props.job.adHoc) { //ADD ADHOC
                requiredFields.push('deviceId', 'newEventType', 'jobStatus')
            }
            else if (this.props.job.existingEvent) { //ADD EXISTING JOB
                requiredFields.push('jobStatus', 'eWorkCard')
            }
            else { //ADD NEW JOB
                console.log('add new job')
                requiredFields.push('deviceId', 'jobStatus', 'newEventType')
            }
        }
        requiredFields.map(f => {
            if (this.state[f] == null) {
                validated = false
            }
        })
        console.log('validated', validated)
        console.log('props', this.props)
        console.log('save state', this.state)
        if (validated) {
            this.props.saveEvent(saveType, { ...this.props.job, newEventType: this.state.newEventType, deviceId: this.state.deviceId, jobStatus: this.state.jobStatus, note: this.state.dispatchNote, eWorkCard: this.state.eWorkCard, eventEndTime: this.state.eventEndTime, eventStartTime: this.state.eventStartTime, workCarriedOut: this.state.workCarriedOut, fsrStatus: this.state.fsrStatus, dueBy: this.state.dueBy, users: this.state.users, activityDate: this.state.activityDate && moment(this.state.activityDate).format('DD/MM/YY'), scheduledTime: this.state.scheduledTime })
            this.props.cancel(this.props.job.calId)
        }
        else {
            this.setState({error: true})
        }
    }

    render() {
        const { job, jobList, stations, eventTypes, workStatuses, edit, siteToggle, users, activityType, customerWithSites } = this.props
        var jobType = this.state.eWorkCard ? jobList.find(x => x.id == this.state.eWorkCard).wcType : job.workcard && job.workcard.responseLevel ? job.workcard.responseLevel : this.state.newEventType
        var jobStatuses = workStatuses.find(x => x.id == jobType) ? workStatuses.find(x => x.id == jobType).options : []
        var musers = users.map(y => { return { label: y.name, value: y.id } })
        var siteJobCount = this.state.deviceId && jobList ? jobList.filter(x => x.deviceId == this.state.deviceId).length : 0
        var filtJobList = jobList ? (this.state.deviceId ? jobList.filter(x => x.deviceId == this.state.deviceId) : jobList) : []
        //console.log('modal job', job)
        //console.log('modal edit', this.props)
        return (
            <Modal className='calendarModal' isOpen={true} autoFocus={false} backdrop={true} toggle={() => this.props.cancel(job.calId)} >
                <ModalHeader>
                    {edit ? 'Modify' : 'Calendar'} Event {edit && ': ' + job.stationName}
                    {job.workcard && job.calId != 9999 && <Button color='primary' style={{ lineHeight: 0.5, float: 'right' }} onClick={() => this.props.history.push('FieldServiceReport/' + job.calId, { history: null })}>View Work Report</Button>}
                </ModalHeader>
                <ModalBody>
                    {job.existingEvent ?
                        <div>
                            <Row style={{ marginTop: '-10px' }}>
                                <Col xs={6}><span>Select Site</span></Col>
                            </Row>
                            <Row>
                                <Col xs={12}><SearchList onChange={(e) => e.valie != 0 && this.setState({ deviceId: e.value })} placeholder='Select Site' value={this.state.deviceId} mainData={this.state.sites} readOnly={false} /></Col>
                            </Row>
                            <Row>
                                <Col xs={12}><span>Select Engineers</span></Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Select multi onChange={(e) => this.setState({ users: e })} options={musers} placeholder="Select Engineer(s)" clearable={false} simpleValue value={this.state.users} />
                                </Col>
                            </Row>
                            <Row >
                                <Col xs={12}><span>Select Job</span></Col>
                            </Row>
                            <Row>
                                <Col xs={12}><IdNameDropdown options={filtJobList} zeroText='Select Job' style={{ height: '100%', padding: '0.2rem' }} valueField='id' displayField='name' onChange={(e) => e != 0 && this.setState({ eWorkCard: e })} /></Col>
                            </Row>
                        </div>
                    : job.newEvent ?
                        <div>
                            {(siteToggle == "2" || job.adHoc) &&
                                <div>
                                    <Row>
                                        <Col xs={6}><span>Select Site</span></Col><Col xs={6}><Button style={{ lineHeight: 0.5, float: 'right', backgroundColor: '#f1f1f1', marginBottom: '2px' }} onClick={() => this.props.history.push({ pathname: '/Customers/New', state: { calendarReturn: job } })}>Add Site</Button></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}><SearchList onChange={(e) => e.valie != 0 && this.setState({ deviceId: e.value })} placeholder='Select Site' value={this.state.deviceId} mainData={this.state.sites} readOnly={false} /></Col>
                                    </Row>
                                    {this.state.deviceId && siteJobCount > 0 && <Row>
                                        <Col xs={12}><span>{siteJobCount} jobs active for this site</span></Col>
                                    </Row>}
                                </div>
                            }
                            <div>
                                <Row>
                                    <Col xs={12}><span>Select Engineers</span></Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Select multi onChange={(e) => this.setState({users: e})} options={musers} placeholder="Select Engineer(s)" clearable={false} simpleValue value={this.state.users} />
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                                <Col xs={12}><span>Select Job Type</span></Col>
                            </Row>
                            <Row>
                                <Col xs={12}><IdNameDropdown options={eventTypes} zeroText='Select Job Type' style={{ height: '100%', padding: '0.2rem' }} valueField='id' displayField='name' onChange={(e) => this.setState({ newEventType: e })} /></Col>
                            </Row>
                        </div>
                    : job.calId && job.calId != 9999 &&
                        <div>
                            <Row>
                                <Col xs={12}><span>Select Engineers</span></Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Select multi onChange={(e) => this.setState({ users: e })} options={musers} placeholder="Select Engineer(s)" clearable={false} simpleValue value={this.state.users} />
                                </Col>
                            </Row>
                        </div>
                    }
                    <Row>
                        <Col xs={12}><span>Select Job Status</span></Col>
                    </Row>
                    <Row>
                        <Col xs={12}><IdNameDropdown options={jobStatuses} zeroText='Select Job Status' readOnly={activityType == 1} style={{ height: '100%', padding: '0.2rem' }} defaultValue={this.state.jobStatus} valueField='id' displayField='name' onChange={(e) => this.setState({ jobStatus: e })} /></Col>
                    </Row>
                    <Row>
                        <Col xs={12}><span>Select FSR Status</span></Col>
                    </Row>
                    <Row>
                        <Col xs={12}><IdNameDropdown options={fieldServiceReportStatuses} readOnly={activityType == 1} zeroText='Select FSR Status' style={{ height: '100%', padding: '0.2rem' }} defaultValue={this.state.fsrStatus} valueField='id' displayField='name' onChange={(e) => this.setState({ fsrStatus: e })} /></Col>
                    </Row>
                    {(job.adHoc || edit) &&
                        <div>
                            <Row>
                                <Col xs={12}><span>Select Date</span></Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <input type='date' value={this.state.activityDate} readOnly={activityType == 1} className='form-control' style={{ padding: '0.25rem' }} onChange={(e) => this.setState({ activityDate: e.target.value })} />
                                </Col>
                            </Row>
                        </div>
                    }
                    {this.state.fsrStatus != 0 ? 
                        <div>
                            <Row>
                                <Col xs={12}><span>Scheduled Time</span><input style={{ lineHeight: '16px', marginLeft: '5px' }} type='checkbox' defaultChecked={this.state.scheduledTime} onClick={() => { activityType != 1 && this.setState({ scheduledTime: !this.state.scheduledTime }) } } /></Col>
                            </Row>
                            {this.state.scheduledTime  && <Row>
                                <Col xs={12}>
                                    <div style={{ display: 'flex', gap: '5px' }}>
                                        <input type='time' className='form-control' readOnly={activityType == 1} defaultValue={this.state.eventStartTime} style={{ padding: '0.25rem' }} onChange={(e) => this.setTime('eventStartTime', e)} />
                                        <span>to</span>
                                        <input type='time' className='form-control' readOnly={activityType == 1} defaultValue={this.state.eventEndTime} style={{ padding: '0.25rem' }} onChange={(e) => this.setTime('eventEndTime', e)} />
                                    </div>
                                </Col>
                            </Row>}
                        </div>
                        :
                    <div>
                        <Row>
                            <Col xs={12}><span>Due By</span></Col>
                        </Row>
                        <Row>
                            <Col xs={12}><IdNameDropdown options={dueByStatuses} zeroText='Select Due By' style={{ height: '100%', padding: '0.2rem' }} defaultValue={this.state.dueBy} valueField='id' displayField='name' onChange={(e) => this.setState({ dueBy: e })} /></Col>
                        </Row>
                    </div>
                    }
                    <Row>
                        <Col xs={12}><span>Dispatch Note</span></Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <textarea className='form-control' rows='3' defaultValue={this.state.dispatchNote} style={{ padding: '0.25rem' }} onChange={(e) => this.setState({ dispatchNote: e.target.value })} />
                        </Col>
                    </Row>
                    {(!job.existingEvent && !job.newEvent) &&
                    <div>
                        <Row>
                            <Col xs={12}><span>Work Carried Out</span></Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <textarea className='form-control' rows='4' defaultValue={this.state.workCarriedOut} style={{ padding: '0.25rem' }} onChange={(e) => this.setState({ workCarriedOut: e.target.value })} />
                            </Col>
                        </Row>
                    </div>}
                    {job.workcard && job.workcard.engineerLogId != 9999 && this.state.jobInfoEnabled == 'true' && <WorkCards externalFormId={job.workcard.engineerLogId} compactMode={true} cancelAction={() => this.props.cancel(job.calId)} saveAction={() => this.saveAction()} />}
                    {this.state.error && <Row><Col xs={12}><b style={{ color:'#e50808'}}>Please complete all fields</b></Col></Row>}
                </ModalBody>
                {
                    ((job.workcard == null) || (job.workcard && job.workcard.engineerLogId == 9999) || (this.state.jobInfoEnabled == 'false')) &&
                    <ModalFooter>
                        <Button onClick={() => this.props.cancel(job.calId)}>Cancel</Button>
                        {job.calId != 9999 && activityType != 1 && this.getMenuButton(job)}
                        <Button color='primary' style={{marginLeft:'auto'}} onClick={() => this.saveAction()}>Save</Button>
                    </ModalFooter>
                }
            </Modal>
			)
	}
}

export default CalendarModal
