import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import ActController from './ActivityController'
import ActCards from './ActivityCards'
import { listRequest } from '../../../configuration/enums'
import { connect } from 'react-redux'
import { ApiPost, ApiGet } from '../../../api'
import WorkHoursTracker from './WorkHoursTracker'

class EngineerSchedule extends Component {

	constructor(props) {
        super(props);
        this.state = { data: null, showFilter: false, filterTitle: 'Your Activity Next 7 days', loadComplete: false }
        this.handleData = this.handleData.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
    }

    handleData(data) {
        this.setState({ data, showFilter: false });
    }

    updateTitle(data) {
        this.setState({filterTitle: data})
    }

    reloadData() {
        this.child.getActivityData()
    }

    componentDidMount() {
        var listsToGet = [listRequest.FieldEngineer, listRequest.OpenJobs, listRequest.WorkCardTypes, listRequest.WorkCardStatuses, listRequest.CalendarEventTypes]
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
        .then((data) => {
            this.setState({ users: data[listRequest.FieldEngineer], jobList: data[listRequest.OpenJobs], eventTypes: data[listRequest.WorkCardTypes], workStatuses: data[listRequest.WorkCardStatuses], calEventTypes: data[listRequest.CalendarEventTypes], loadComplete: true })
        })
    }

    saveEvent(e) {
        console.log('saveEvent', e)
        ApiPost('Calendar', 'CreateCalEvent', { activityDate: e.activityDate, deviceId: e.deviceId, eType: e.newEventType, engineer: e.workcard && e.workcard.engineers ? e.workcard.engineers[0] : null, jobStatus: e.jobStatus, eWorkCard: e.eWorkCard, eventStartTime: e.eventStartTime, eventEndTime: e.eventEndTime, calEventType: e.calEventType, note: e.note, calId: e.calId, newEvent: e.newEvent, workCarriedOut: e.workCarriedOut, fsrStatus: e.fsrStatus, dueBy: e.dueBy, users: e.users })
        .then((data) => {
            this.reloadData()
        })
    }

    render() {
        var data = this.state.data ? this.state.data.filter(x => x.fsrStatus != 0) : null
		return (
            <div>
                <Row style={{ display: 'flex', alignItems: 'center'}}>
                    <Col sm={12} md={2} ><div className='titleBlock'>Engineer Schedule</div></Col>
                    <Col sm={12} md={4}><WorkHoursTracker completePrev={true} /></Col>
                    <Col sm={12} md={5} ><span className="filterTitle">{!this.state.showFilter ? this.state.filterTitle : null}</span></Col>
                    <Col sm={1} md={1}><button type="button" className="btn btn-default" onClick={() => this.setState({ showFilter: !this.state.showFilter })}><i className="fal fa-filter" /></button></Col>
                    <Col sm={1}/>
                </Row>
                <div style={{ display: this.state.showFilter ? 'block' : 'none' }}><ActController onRef={ref => (this.child = ref)} handleData={this.handleData} updateTitle={this.updateTitle} activityType={1} /></div>
                {data && this.state.loadComplete &&
                    <div className='engineerContain'>
                        <ActCards
                            activityData={data}
                            history={this.props.history}
                            eventTypes={this.state.eventTypes}
                            workStatuses={this.state.workStatuses}
                            users={this.state.users}
                            calEventTypes={this.state.calEventTypes}
                            reloadData={() => this.reloadData()}
                            activityType={1}
                            saveEvent={(e) => this.saveEvent(e)} />
                    </div>}
			</div>
			)
	}
}

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(mapStateToProps, null, null, { withRef: true })(EngineerSchedule)
