
import React, { Component } from 'react';
import  {ApiPost,ApiGet}  from '../../../api/index'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Loader from '../../../components/Loader';
import moment from 'moment';
//import { SERVER_PATH } from '../../../configuration/AppConfig';

const SERVER_PATH = process.env.SERVER_PATH;

import 'react-day-picker/lib/style.css';

export const REPORTS_PATH = SERVER_PATH;

const DAY_FORMAT = 'DD/MM/YYYY';

class ReportsMenu extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            reports: [],
            params: {},
            selectedReport:0,
            reportUrl: '/ReportDefault',
            collapsed: false
        }
        this.loadReports = this.loadReports.bind(this)
        this.onReportChange = this.onReportChange.bind(this)
    }

    componentDidMount() {
        console.log('SERVER PATH', REPORTS_PATH);
        this.loadReports();
    }

    loadReports() {
        let me = this;
        ApiGet('Reports','')
            .then((data) => {
                this.setState({ reports: data })
            });
    }
    printReport() {
        return this.openReport(2);
    }
    downloadReport() {
        return this.openReport(1);
    }
    saveReport() {
        return this.openReport(1);
    }
    viewReport() {
        return this.openReport(0);
    }
    emailReport() {
        return this.openReport(0, true);
    }

    onReportChange(id) {
        ApiGet('Reports',id)
        .then((data) => {
            this.setState( { selectedReport:id, params: data })
            console.log("report params")
        });
    }

    submit(rec) {
        let me = this;
        ApiPost('auth','login',rec)
            .then((data) => {
                this.setState( { failedLogin: !data, redirect: data })
            });
    }

    renderButtons(valid) {
        const { outputoptions } = this.state.params;
        var actionMap = {}
        actionMap["view"] = 2
        actionMap["export"] = 1
        var opts = {};
        if (!valid) {
            opts['disabled'] = 'disabled';
        }
        if (outputoptions) {
            return (
                <div className="btn-group" role="group" aria-label="Report Actions" key="btns">
                    {Object.keys(outputoptions).map(
                        (key,index) => (
                            <button key={outputoptions[key]} {...opts} className="btn btn-into" onClick={() => this.openReport(actionMap[outputoptions[key]])}>{outputoptions[key]}</button>
                        )
                    )}
                </div>
            )
        } else {
            return null
        }
    }

    setParamValue(fldName, e) {
        var s = {}
        fldName = "zparams-" + fldName
        s[fldName] = e.target.value //parseInt(e.target.value)
        s[fldName + "-t"] = e.target['options'][e.target['options'].selectedIndex].text
        this.setState(s)

    }

    handleDayChange(selectedDay, modifiers,fld) {
        var s = {}
        var fldName = "zparams-" + fld.name
        console.log('selectedDay', selectedDay);
        s[fldName] = this.getFormattedDate(moment(selectedDay).toDate())
        s[fldName + "-t"] = moment(selectedDay).format('DD/MM/YYYY')
        this.setState(s);
      };

    renderDate(fld) {
        const dayPickerProps = {
            todayButton: 'Go to Today',
            enableOutsideDays: true
          };
          var selectedDay = this.state["zparams-" + fld.name]
          const formattedDay = selectedDay
          ? moment(selectedDay).format(DAY_FORMAT)
          : '';
          return (
            <DayPickerInput
                key={fld.name}
                value={formattedDay}
                onDayChange={(selectedDay, modifiers) => this.handleDayChange(selectedDay, modifiers,fld)}
                format={DAY_FORMAT}
                  dayPickerProps={dayPickerProps}
                />
          )
    }

    renderList(fld, list) {
        var listnode = this.state.params.lists[list];
        return (
            <select key={fld.name} className="form-control" value={this.state["zparams-" + fld.name]} onChange={(event) => this.setParamValue(fld.name, event)}>
                <option key={0} value={'0'}>Select</option>
                {listnode.values.map(
                    (sec,index) => (
                        <option key={sec.key} value={sec.key}>{sec.value}</option>
                    )
                )}
            </select>
        )
    }

    wrapFormEl(name, cntrl) {
        return (
            <div className="form-group" key={name}>
                <label>{name}</label>
                {cntrl}
            </div>
        )
    }

    renderParams() {
        var whereFields = this.state.params.where;
        if (whereFields) {
            return (
                whereFields.map(
                    (fld, index) => {
                        if (fld.controlType == 'list') {
                            var label = this.state.params.lists[fld.name].label
                            return this.wrapFormEl(label, this.renderList(fld,fld.name))
                        }
                        if (fld.controlType == 'date') {
                            return this.wrapFormEl(fld.label, this.renderDate(fld))
                        }
                    }
                )
            )
        } else {
            return null;
        }
    }

    openReport = function (csvexport, emailme) {
        console.log('csvexport', csvexport)
        console.log('state', this.state)
        var val = this.state.selectedReport;
        var op = encodeURIComponent(this.encodeOptions());
        var semail = 0;
        if (emailme) semail = 1;
        if (val) {
            var url = REPORTS_PATH + '/Report?target=' + semail + '&a=' + op;
            if (csvexport == 1) {
                window.open(REPORTS_PATH + '/export.ashx?a=' + op, '_self', false);
            }
            else {
                console.log('opening report')
                this.setState({ reportUrl:'' });
                setTimeout(() => this.setState({ reportUrl: url }, () => this.populateIframe(this.state.reportUrl)),50);
            }
        } else {
            alert('Error', 'Please Select a report');
        }
        this.setState({collapsed:true});
    }

    encodeOptions() {
        var optionsObj = {}
        optionsObj.report = this.state.selectedReport;
        optionsObj.returnParams = [];
        optionsObj.showFields = [];

        var i = 0;
        for (var fldindex in this.state.params.where) {
            var fld = this.state.params.where[fldindex];
            var name = fld.name;
            var pname = "zparams-" + name
            if (this.state[pname] != undefined) {
                var v = this.state[pname]
                var t = this.state[pname+ "-t"]
                optionsObj.returnParams.push({ n: '' + name, v: v, t: t });
            }
        }
        return btoa(JSON.stringify(optionsObj));
    }
    

    cmpGetValue = function (cval) {
        if (cmp.xtype == 'datefield') {
            cval = getFormattedDate(cval);
        }
        return cval;
    }

    getFormattedDate(date) {
        if (date) {
            var year = date.getFullYear();
            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;
            var day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;
            return year + '-' + month + '-' + day;
        } else {
            return "";
        }
    }

    handler() {
        var iframe = document.querySelector('#reportIFrame')
        if (this.readyState === this.DONE) {
            if (this.status === 200) {
                var disp = this.getResponseHeader('Content-Disposition')
                console.log('disp', disp)
                if (disp && disp.includes('attachment')) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disp)
                    var match = matches[1].replace(/\"/g, "")
                    var fileName = match
                    var a = window.document.createElement("a");
                    a.href = URL.createObjectURL(this.response);
                    a.download = fileName
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                else {
                    iframe.src = URL.createObjectURL(this.response);
                }
            }
        }
    }

    populateIframe(url) {
        var xhr = new XMLHttpRequest()
        xhr.open('GET', url)
        xhr.onload = this.handler
        xhr.responseType = 'blob'
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('jwtToken'))
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
        xhr.send();
    }

    render() {
        let paramValidCnt = 0;
        let formOk = false;
        if (this.state.params && this.state.params.where) {
            this.state.params.where.forEach(function(element) {
                if (this.state["zparams-" + element["name"]]) paramValidCnt++;
            }, this);
            formOk = (paramValidCnt == this.state.params.where.length);
        }
        return (
            <div className="sideMenu" style={{height:'100%', display:'flex',flexDirection:'row'}}>
                <div style={{ minHeight:'1000px',  minWidth: '20px',maxWidth: '200px',display:'flex',flexDirection:'row'}} className={(this.state.collapsed)?"collapsed":""}>
                    <div style={{flexGrow:1, minWidth:0 }} className="sidePanel">
                        <div>
                            <div className="form-group">
                                <label className="col-form-label">Report</label>
                                <select className="form-control" id="reportList" value={this.state.selectedReport} onChange={(event) => this.onReportChange(parseInt(event.target.value))}>
                                <option key={0} value={0}>Select Report</option>
                                {this.state.reports.map(
                                    (sec,index) => (
                                        <option key={sec.reportSqlId} value={sec.reportSqlId}>{sec.reportName}</option>
                                    )
                                )}
                                </select>
                            </div>
                            {this.renderParams()}
                        </div>
                        {this.renderButtons(formOk)}
                    </div>
                    <div className="sidePanelBar" onClick={() => {this.setState({ collapsed: !this.state.collapsed }); return false;}}>
                            {(this.state.collapsed)?<i className="fa fa-caret-right" />: <i className="fa fa-caret-left" />}
                    </div>
                </div>
                <div style={{height:'100%',flexGrow:1,position:'relative'}}>
                    <div style={{position:'absolute',width:'100%',height:'100%'}}>
                        <Loader height="100%" />
                    </div>
                    {this.state.reportUrl ? <iframe id='reportIFrame' src={this.state.reportUrl} style={{ width: '100%', height: '100%', border: '1px solid #ccc', backgroundColor: 'transparent', position: 'absolute' }} /> : ''}
                </div>
            </div>
        );
    }

    
}

export default ReportsMenu