import React, { Component } from 'react';
import logo from '../../../../content/everun-logo.png';
import { Row, Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Label, Col, ListGroup, ListGroupItem, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import classnames from 'classnames';
import MasterDetail from 'MasterDetail'
export const API_PATH = process.env.SERVER_PATH + '/api'

export default class ClientSetup extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.clientToggle = this.clientToggle.bind(this);
        this.userToggle = this.userToggle.bind(this);
        this.state = { activeTab: '3', dropdownClientOpen: false, dropdownUserOpen: true };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    clientToggle() {
        this.setState({
            dropdownClientOpen: !this.state.dropdownClientOpen
        });
    }

    userToggle() {
        if (window.innerWidth < 992) {
            this.setState({
                dropdownUserOpen: !this.state.dropdownUserOpen
            });
        }
    }

    componentDidMount() {
        if (window.innerWidth < 992) {
            this.setState({
                dropdownUserOpen: !this.state.dropdownUserOpen
            });
        }
    }

    render() {

        var DriveSelectionFields = [
            { label: "File Name", fieldId: "name" },
            { label: "Type", fieldId: "type" },
            { label: "Year", fieldId: " " },
            { label: "Date Uploaded", fieldId: "createdTime" },
            { label: "Edit", fieldId: "funcButton", display: 'grid', type: 'funcButton', typeProps: { label: <i className="fas fa-edit" />, func: (rec) => this.FileEdit(rec) } },
            { label: "Download", fieldId: "funcButton", display: 'grid', type: 'funcButton', typeProps: { label: <i className="fa fa-download" />, func: (rec) => this.FileDownload(rec) } },

        ];

        var DriveSelectionConfig = {
            model: 'DocumentRepo',
            path: '/DocumentRepo',
            fields: DriveSelectionFields,
            crudParams: this.state.fileDetails,
            apiPath: API_PATH
        }

        return (
            <div>
                <div className="d-flex justify-content-end" style={{ borderBottom: '1px solid rgba(99, 99, 99, 0.2)' }}>
                    <div className="mr-auto p-2">
                        <h1>Client Setup</h1>
                        <p style={{ fontSize: '1.3rem' }}>Client A <b>></b> Adam</p>
                    </div>
                </div>

                <div className="col-sm-0 col-md-0 col-lg-3 offset-lg-9" style={{ paddingTop: '10px' }} >
                    <Dropdown isOpen={this.state.dropdownClientOpen} toggle={this.clientToggle}>
                        <DropdownToggle caret>
                            Select Client
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>Client A</DropdownItem>
                            <DropdownItem>Client B</DropdownItem>
                            <DropdownItem>Client C</DropdownItem>
                            <DropdownItem>everun</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12">

                    <div className="Row">
                        <div className="container-fluid">
                            <h2>everun -  Adam Smyth</h2>
                            <Nav style={{ backgroundColor: '#f0f0f0' }}>
                                <NavItem> <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }} > Client Details </NavLink> </NavItem>
                                <NavItem> <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }} > SPVs </NavLink> </NavItem>
                                <NavItem> <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }} > Users </NavLink> </NavItem>
                            </Nav>
                        </div>
                    </div>

                    <div className="Row" style={{ paddingTop: '20px' }}>

                        <Col sm="12" md="12" lg="12">
                            <TabContent activeTab={this.state.activeTab}>

                                <TabPane id="details" tabId="1"></TabPane>
                                <TabPane id="SPVs" tabId="2"></TabPane>

                                <TabPane id="users" tabId="3">
                                    <Col sm="0" md="0" lg="3">
                                        <Dropdown isOpen={this.state.dropdownUserOpen} toggle={this.userToggle}>
                                            <DropdownToggle caret id="userSelectDdl">
                                                Select User
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem header>Select User</DropdownItem>
                                                <DropdownItem>Aaron</DropdownItem>
                                                <DropdownItem>Adam</DropdownItem>
                                                <DropdownItem>Ali</DropdownItem>
                                                <DropdownItem>Rob</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                    <Col sm="12" md="12" lg={{ size: 9, offset: 3 }}>
                                        <Form>
                                            <FormGroup row>
                                                <Label sm={2}>Name</Label>
                                                <Col sm={10}>
                                                    <input type="text" className="form-control" placeholder="Name" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm={2}>Username</Label>
                                                <Col sm={10}>
                                                    <input type="text" className="form-control" placeholder="Username" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm={2}>Email</Label>
                                                <Col sm={10}>
                                                    <input type="text" className="form-control" placeholder="Email" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm={3}></Label>
                                                <Col sm={9}>
                                                    <input className="form-check-input" type="checkbox" value="" style={{ marginLeft: '0' }} /> Allow Access
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label sm={2}>Roles</Label>
                                                <Col sm={10}>
                                                    <ListGroup>
                                                        <ListGroupItem className="justify-content-between">
                                                            Template Setup
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                        <ListGroupItem className="justify-content-between">
                                                            Contract Setup
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                        <ListGroupItem className="justify-content-between">
                                                            Bill Creation
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                        <ListGroupItem className="justify-content-between">
                                                            Send Invoices
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                        <ListGroupItem className="justify-content-between">
                                                            Add Users
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                    </ListGroup>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Label sm={2}>Companies</Label>
                                                <Col sm={10}>
                                                    <ListGroup>
                                                        <ListGroupItem className="justify-content-between">
                                                            WNI Power
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                        <ListGroupItem className="justify-content-between">
                                                            WEG
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                        <ListGroupItem className="justify-content-between">
                                                            First Wind
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                        <ListGroupItem className="justify-content-between">
                                                            Myroe
                                                        <input className="form-check-input" type="checkbox" value="" />
                                                        </ListGroupItem>
                                                    </ListGroup>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row>
                                                <Col sm={{ offset: 2, size: 10 }}>
                                                    <button type="submit" className="btn">Remove</button>
                                                    <button type="submit" className="btn btn-primary pull-right">Save</button>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </TabPane>
                            </TabContent>
                        </Col>

                    </div>
                </div>

                <MasterDetail key='mdwc' {...DriveSelectionConfig} /> 

            </div>
        );
    }
}
