import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest, alertType } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'


class ChecklistSetup extends Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {

        var lists = {
            responseLevel: [{ id: 2, name: 'Local Reset' }, { id: 3, name: 'Inhouse' }, { id: 4, name: 'Outsource' }],
            checklistPositions: [{ id: 1, name: 'MidForm' }, { id: 2, name: 'PreForm' }, { id: 3, name: 'PostForm' }],
            questionType: [{ id: 1, name: 'Checkbox' }, { id: 2, name: 'Text' }, { id: 3, name: 'List' }],
            wcType: [{ id: 1, name: 'Fault Response' }, { id: 51, name: 'Service' }, { id: 52, name: 'Scheduled Maintenance' }, { id: 53, name: 'Commissioning' }, { id: 54, name: 'Investigation' }, { id: 55, name: 'Stat Inspection' }, { id: 60, name: 'Scheduled Grid Maintenance' }]
        };

        var clSFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Name", fieldId: "name", width: 170, validation: 'required' },
            { label: "Position", fieldId: "position", list: lists.checklistPositions, validation: 'required'},
            { label: "Client", fieldId: "clientId", list: listRequest.Clients, validation: 'required'},
            { label: 'Required', fieldId: "required", type: 'checkbox', display: 'form'},
            { label: 'WC Type', fieldId: 'wcTypes', display: 'form', type: 'checkList', listSource: '/ChecklistSetup/GetWCs'},
            { label: 'Response Level', fieldId: "responseLevel", list: lists.responseLevel },
            { label: 'Model', fieldId: "turbineModel", list: listRequest.TurbineModelInUse },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' },
        ];

        var setupConfig = {
            model: 'ChecklistSetup',
            fields: clSFields,
            master: true,
            titleField: 'name',
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        var qFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Question", fieldId: "question", inlineEdit: true },
            { label: "Type", fieldId: "type", list: lists.questionType, inlineEdit: true },
            { label: 'ChecklistId', fieldId: 'checklistId', display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var qConfig = {
            model: 'ChecklistSetupQs',
            path: '/ChecklistSetup/Qs',
            crudParams: { checklistId: this.props.match.params.id},
            fields: qFields,
            addInline: true,
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Checklist Setup</div>
                <MasterDetail key='mdcs' {...setupConfig}>
                    <MasterDetail key='mdcc' {...qConfig} />
                </MasterDetail>
            </div>
        );

    }
}

export default (ChecklistSetup)
