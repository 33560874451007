import * as commonActions from '../actions/common'
import helper from '../utils/helpers'

const lookups = (state = { universalmetrics: [], industries: [] }, action) => {
  switch (action.type) {
    case commonActions.GOT_APP_LOOKUPS:
      return {
        ...state,
        universalmetrics: action.payload.universalMetrics,
        industries: action.payload.industries
      }
    default:
      return state
  }
}
export default lookups