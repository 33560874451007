import React from 'react';
import { Button} from 'reactstrap';


function getRenderColour(color) {
    if (color == 'red') {
        return '#d0021b';
    }
    else if (color == 'blue') {
        return '#5b8cd2';
    }
    else if (color == 'yellow') {
        return '#ffa500';
    }
}

const RuleCard = (props) => {

    //console.log('ruleProps', props.ruleDeets);

    return (
        <div className="rule-card" style={{ borderBottomColor: getRenderColour(props.ruleDeets.colour) }}>
            {props.ruleDeets.criteria}

            <Button className="btn" style={{ float: 'right', color: '#000', backgroundColor: 'transparent', border: 'none' }} onClick={() => props.ruleToggle()}><i className="fa fa-pencil" /></Button>

            <b style={{float: 'right', paddingRight: '20px'}}>{props.ruleDeets.label}</b>

            {props.ruleDeets.ruleType != parseInt(props.id) ? <div className="disabled-overlay" /> : null}

        </div>
    );
};


export default RuleCard;
    