import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
import FormulaBox from './FormulaBox'
export const API_PATH = process.env.SERVER_PATH + '/api'

class DerivedMetric extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = 'Derived Metrics | izon'
    }

    shouldComponentUpdate(pp) {
        if (JSON.stringify(pp) == JSON.stringify(this.props)) {
            return false
        }
        else {
            return true
        }
    }

    render() {

        var lists = {
            scope: [{ id: 1, name: 'Device' }, { id: 2, name: 'Device Type' }],
            calculation: [{ id: 0, name: 'None' },{ id: 1, name: 'Average' }, { id: 2, name: 'Formula' }],
            aggregation: [{ id: 1, name: 'Average' }, { id: 2, name: 'Max' }, { id: 3, name: 'Min' }],
            metricType: [{ id: 1, name: 'Calculation' }, { id: 2, name: 'Mode' }],
        }

        var dmId = this.props.match && this.props.match.params.id ? this.props.match.params.id : this.props.externalFormId ? this.props.externalFormId : null;

        var DerivedMetricsFields = [
            { label: "Id", fieldId: "id", display: 'none' },
            { label: "Metric Name", fieldId: "name", },
            //{ label: "Metric", fieldId: "metricId", list: listRequest.UniversalMetric },
            //{ label: "Scope", fieldId: "scope", list: lists.scope },
            //{ label: "Cross Site Aggregation", fieldId: "aggregation", display: 'form', list: lists.aggregation, displayIf: (rec) => rec.scope == 2 },
            { label: "Metric Type", fieldId: "metricTypeId", list: lists.metricType },
            { label: "Device Type", fieldId: "deviceTypeId", list: listRequest.DeviceTypes },
            { label: "Store Historically", fieldId: "historical", type: 'checkbox' },
            //{ label: "Calculation", fieldId: "calculation", list: lists.calculation },
            { label: "Formula Box", fieldId: "blockConfig", display: 'form', type: 'custom', custom: (rec) => <FormulaBox rec={rec} id={dmId} />},
            //{ label: "Avg Box", fieldId: "formula", display: 'form', type: 'custom', displayIf: (rec) => rec.calculation == 1, custom: (rec) => <AvgBox rec={rec} id={dmId} />},
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton'},
        ];

        var DerivedMetricsConfig = {
            model: 'DerivedMetric',
            fields: DerivedMetricsFields,
            master: true,
            titleField: 'tagName',
            showFilterRow: false,
            gridProps: { defaultPageSize: 25 },
            externalFormId: this.props.externalFormId ? 1 : 0,
            apiPath: API_PATH
            //defaultSorted: [{ id: 'name', desc: false }],
        }

        return (
            <div>
                {!this.props.externalFormId && <div className='titleBlock'>Derived Metrics</div>}
                <MasterDetail key='mdcs' {...DerivedMetricsConfig} />                    
            </div >
            )
    }
}

export default (DerivedMetric)