import React, { Component } from 'react';
import { ApiPost } from '../../../api';
import Collapsible from 'react-collapsible';

class Password extends Component {   

    constructor(props) {
        super(props);
        this.state = {
            Success: false,
            collaps: true,
            errorMsg:"",
            password1:"",
            password2:"",
            allOk: false,
            goodMsg: false
        }
    }     

    passOk(pass) {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})");
        return strongRegex.test(pass);
    }

    validate(isSubmit) {
        const { password1, password2 } = this.state;
        var allOk = true;
        var msg ="";
        if (!this.passOk(password1)) {
            msg = "First password must contain at least one numeric, one uppercase and one special char. Password must be at least 7 chars long."
        }

        if (isSubmit) {
            if (password1 != password2) {
                msg = "Confirm Password does not match";
            }
        } else {
            allOk = msg.length == 0 && password1 == password2;
            if (password1.indexOf(password2) !=0) {
                msg = "Confirm Password does not match";
            }
        }
        this.setState({ errorMsg: msg, allOk: allOk, goodMsg: false });
        return msg.length == 0;
    }

    onChangeP1(target) {
        this.setState({ password1: target.value}, () => this.validate());
    }
    onChangeP2(target) {
        this.setState({ password2: target.value}, () => this.validate());
    }

    ClearValues() {
        this.setState({ errorMsg: '', password1: '', password2:'' });

    }

    submitPassword() {
        const p = { oldPassword: "", newPassword: this.Password1, userId: this.props.userId }

        if (this.validate(true)) {

            ApiPost('UserProfile', 'ChangePassword', p)
                .then((data) => {
                    var msg = "";
                    var goodMsg = false;
                    if (data) {
                        msg = "Password has been successfully changed."
                        goodMsg = true;
                        setTimeout(() => this.ClearValues(), 3000);
                    } else {
                        msg = "Unable to set password.";
                    }

                    this.setState({ errorMsg: msg, goodMsg: goodMsg,  password1: '', password2:'' });
                })
        }
    }      

    render() {         
        var buttonClass = "btn";
        if (this.state.allOk) {
            buttonClass = "btn btn-success"
        }
        return (
            <div style={{ width: '98%' }} className="accordionCards" >
                <Collapsible trigger={<fieldset>Change Password</fieldset>} open={false} transitionTime={200}> 
                        <form id='form1' style={{ backgroundColor: '#f7f7f7'}} >
                            <div>
                                <p>
                                    <label style={{ marginTop: '10px' }} className="col-lg-2"><b>New Password</b></label>
                                    <input style={{ marginLeft: '18px', marginTop: '10px', border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '.25em', lineHeight: '2', width:'260px' }} type="password" value={this.state.password1} onChange={(e) => this.onChangeP1(e.target)} />                                    
                                </p>
                                <p>
                                    <label className="col-lg-2"><b>Confirm Password</b></label>
                                    <input style={{ marginLeft: '18px', border: '1px solid rgba(0, 0, 0, 0.15)', borderRadius: '.25em', lineHeight: '2', width: '260px' }} type="password" value={this.state.password2} onChange={(e) => this.onChangeP2(e.target)} />
                                    
                                </p>                               
                                <p>
                                <label className="col-lg-2"></label>
                                <label className="col-lg-12" style={{ marginLeft: '10px', color: this.state.goodMsg?'green':'red' }}>{this.state.errorMsg}</label>
                                <button class="btn" disabled={this.state.allOk?'':'disabled'} style={{marginLeft: '18px', marginBottom: '10px'}} type="button" onClick={() => this.submitPassword()}>Change Password</button>
                                </p>
                            </div>
                        </form> 
                </Collapsible>               
            </div>        
            );
    }
}
export default (Password)