import React, { Component } from 'react';
import Controller from './AnalysisController';
import AnalysisChart from './AnalysisChart';
import AnalysisTable from './AnalysisTable';
import Scada from '../../Scada/components/Scada';
import OverlayLoader from '../../../components/OverlayLoader'

export default class AnalysisContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {analysisData : null, isLoading: false}
        this.handleAnalysisData = this.handleAnalysisData.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.handleLoading = this.handleLoading.bind(this)
    }

    componentDidMount() {
        document.title = 'Analysis | izon'
    }

    handleAnalysisData(data) {
        this.setState({ analysisData: data});
    }

    handleLoading(isLoading) {
        this.setState({isLoading})
    }

    toggleMenu(visible) {
        this.setState({ menuVisible: visible })
    }

    getVisuals() {
        if (this.state.analysisData.type == 'scada') {
            return <Scada hideHeader={true} analysisData={this.state.analysisData} />
        }
        else {
            return (
                <div className='aChartContainer'>{this.state.analysisData.analysisList.map(x => {
                    return <AnalysisChart analysisData={x} isLoading={this.state.isLoading} menuVisible={this.state.menuVisible} />
                })}</div>)
        }
    }

    render() {
        //console.log('this.state.analysisData', this.state.analysisData)
        return (
            <div style={{ maxWidth: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Controller handleAnalysisData={this.handleAnalysisData} toggleLoading={e => this.handleLoading(e)} toggleMenu={this.toggleMenu} menuVisible={this.state.menuVisible}/>
                {this.state.analysisData && this.getVisuals()}
                {this.state.analysisData && <AnalysisTable analysisData={this.state.analysisData} isLoading={this.state.isLoading} />}
                {this.state.isLoading && <OverlayLoader />}
            </div>
        );
    }
};
