import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ApiGet, ApiPost } from '../../api/index';

export class XeroAuthorise extends Component {

    constructor(props) {
        super(props);
        this.state = { orgInfos: null, tokenexp:null, tennants: null, err:""};
    }

    componentDidMount() {
        this.getOrgInfo()
    }

    getOrgInfo() {
        ApiGet('Authorization', 'Info')
            .then((data) => {
                console.log("org", data)
                this.setState({ orgInfos: data.orgs, tennants: data.tennants, tokenexp: data.exp, err:data.err })
            });
    }

    AuthoriseXero() {
        ApiGet('Authorization', 'Auth')
            .then((data) => {
                //console.log("auth url", data)
                window.location.replace(data.url);
            });
    }

    DisconnectXero() {
        ApiGet('Authorization', 'Disconnect')
            .then((data) => {

            });
    }


    render() {
        const { orgInfos, tokenexp,tennants, err } = this.state
        return (
            <div>
                {(orgInfos&&!err )?orgInfos.map((orgInfo, i) => 
                    (orgInfo)?
                    <div className="grid-container" style={{ maxWidth: '1000px !important' }}>
                        <div className='grid-x'>
                            <div className='large-2 medium-4 small-6 cell'>
                                TennantId
                            </div>
                            <div className='large-10 medium-8 small-6 cell'>
                                {tennants[i]}
                            </div>
                        </div>
                        <div className='grid-x'>
                            <div className='large-2 medium-4 small-6 cell'>
                                Name
                            </div>
                            <div className='large-10 medium-8 small-6 cell'>
                                {orgInfo.name}
                            </div>
                        </div>
                        <div className='grid-x'>
                            <div className='large-2 medium-4 small-6 cell'>
                                ID
                            </div>
                            <div className='large-10 medium-8 small-6 cell'>
                                {orgInfo.organisationID}
                            </div>
                        </div>
                        <div className='grid-x'>
                            <div className='large-2 medium-4 small-6 cell'>
                            ReAuth By:
                            </div>
                            <div className='large-10 medium-8 small-6 cell'>
                                {tokenexp}
                            </div>
                        </div>
                    </div>:null)
                :null}
                {(err)&&<div className="grid-container" style={{ maxWidth: '1000px !important' }}>
                        <div className='grid-x'>
                            <div className='large-12 medium-12 small-12 cell'>
                                {err}
                            </div>
                        </div>
                    </div>
                }

                <div className="grid-container" style={{ maxWidth: '1000px !important', marginTop:80 }}>
                    <div className='grid-x'>
                        <div className='large-2 medium-4 small-6 cell'>
                            <button className="submit success button" onClick={() => this.AuthoriseXero()}>Authorise</button>
                        </div>
                        <div className='large-10 medium-8 small-6 cell'>
                            <button class="submit alert button" onClick={() => this.DisconnectXero()}>Disconnect</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(XeroAuthorise)