import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class UniversalMetricCard extends Component {

    constructor(props) {
        super(props);
       
    }

   
    render() {        

        var MetricRangesFields = [
        
            { label: "TurbineModel Limit Id", fieldId: "id", width: 70, display: 'none' },            
            { label: "TurbineModel Id", fieldId: "turbineModelId", type: 'readOnly', display: 'none', getId: 'ModelSettings'},            
            { label: "Metric", fieldId: "universalMetricId", width: 170, list: listRequest.UniversalMetric },
            { label: "Error Over", fieldId: "errorOver", width: 170, canFilter: true, display: 'form' },
            { label: "Error Under", fieldId: "errorUnder", width: 170, canFilter: true, display: 'form' },
            { label: "Filter high pass", fieldId: "filterHighPass", width: 170, canFilter: true, display: 'form' },
            { label: "Filter Low Pass", fieldId: "filterLowPass", width: 170, canFilter: true, display: 'form' },
            { label: "Warn Over", fieldId: "warnOver", width: 170, canFilter: true, display: 'form' },
            { label: "Warn under", fieldId: "warnUnder", width: 170, canFilter: true, display: 'form' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' },
        ];

        var MetricRangesConfig = {
            model: 'UniversalMetricCard',            
            fields: MetricRangesFields,
            master: true,
            titleField: 'id',
            apiPath: API_PATH
        }

        return (
            <div>
                <div className="d-flex justify-content-end" style={{ borderBottom: '1px solid rgba(99, 99, 99, 0.2)' }}>
                    <div className="mr-auto p-2">
                        <h1>Metric Range</h1>
                    </div>
                </div>
                <div>
                    <MasterDetail key='mdcs' {...MetricRangesConfig} /> 
                </div>
            </div >
        );
    }
}

export default (UniversalMetricCard)