import React, { Component } from 'react';
import CheckBoxList from '../../components/Controls/CheckBoxList';

import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

export default class FilterDropDown extends Component {
    
    constructor(props) {
        super(props)
        this.chkList = React.createRef();
    }

    render() {
        const { itemKey, isOpen, dropdownItems, onSelect, onToggle, onRemove, text, initvalues } = this.props
        const removeObj = { id: -1, name: 'remove filter'}
        const selectAll = { id: -2, name: 'select All'}
        //const dropdowns = dropdownItems.map(di => renderDropDownItem(di, onSelect, itemKey))
        return (
                <Dropdown isOpen={isOpen} toggle={() => onToggle(null, itemKey)} style={{ display:'inline-block'}}>
                    <DropdownToggle className="filterButton" >
                            {text}
                    </DropdownToggle>
                    <DropdownMenu>
                        {renderDropDownItem(removeObj, onRemove, itemKey)}
                        {renderDropDownItem(selectAll, ()=>this.chkList.current.selectAll(), itemKey)}
                        <CheckBoxList ref={this.chkList} labelMaxChars={15} options={dropdownItems} DefaultIds={initvalues} nameField="name" style={{border:'none', padding: '10px 0px 0px', maxHeight: 'none'}} innerStyle={{marginLeft:0}} onChange={(SelectedCheckBoxIds,id,status,close) => onSelect(itemKey, SelectedCheckBoxIds, close)} />
                    </DropdownMenu>
                </Dropdown>
        );
    }
}

const renderDropDownItem = (obj, onSelect, itemKey) => {
    if (obj.id > 0) {
        return (
                <div key={obj.id} onClick={(e) => onSelect(e,itemKey, obj.id)}>{obj.name}</div>
        )
    } else {
        if (obj.id == -1) {
            var style = {
                color: 'red', width: '16px'
            }
            return (
                <div key={obj.id} id='removeFilterBtn' onClick={(e) => onSelect(e,itemKey, obj.id)}><i className="fa fa-remove" style={style} /> Clear filter</div>
            )
        }
        else if (obj.id == -2) {
            var style = {
                color: '#4e99c7',     width: '16px'
            }
            return (
                    <div key={obj.id}  onClick={(e) => onSelect(e,itemKey, obj.id)}><i className="fas fa-check-double" style={style} /> Select All</div>
            )
        }
    }
}


