import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums'
export const API_PATH = process.env.SERVER_PATH + '/api'

class FaultTagSettings extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        var FaultTagFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Tag", fieldId: "tagName", canFilter: true, width: 100 },
            { label: "Controller", fieldId: "controllerId", list: listRequest.controllers },
            { label: "Fault Message", fieldId: "cntrLogMessage" },
            { label: "Fault Criteria", fieldId: "cntrCriterion" },
            { label: "Acknowledge", fieldId: "cntrAcknowledge" },
            { label: "Instructions", fieldId: "instructions" },
            { label: "Disable Commands", fieldId: "disableCommands", type: 'checkbox' },
            { label: 'Fault Metrics', fieldId: 'faultMetrics', type: 'checkList', listSource: '/FaultTagSettings/GetMetrics', typeProps: { defaultSorted: [{ id: 'name' }] } },
        ];

        var FaultTagConfig = {
            model: 'FaultTagSettings',
            fields: FaultTagFields,
            master: true,
            hideDelete: true,
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        return (
            <div>
                <div className="mr-auto p-2">
                    <h1>Fault Tags</h1>
                </div>
                <div>
                    <MasterDetail key='mdcs' {...FaultTagConfig} />                    
                </div>
            </div >
            );
    }
}

export default (FaultTagSettings)