import React, { Component } from 'react'
import { Textfit } from 'react-textfit'
import { colorShadeSwatches } from '../../../configuration/enums'

class DynamicSummaryWidget extends Component {

    getResult(value, perid, factor, decimalPlaces) {

        if (value && value.data) {
            value = value.data
            if (!factor) {
                factor = 1;
            }
            if (!decimalPlaces) {
                decimalPlaces = 0;
            }

            this.getLevel(value);

            if (value != undefined) {
                value = value * factor
                if (value < 0) {
                    value = 0;
                }
                return value.toFixed(decimalPlaces);
            }
        }
        else {
            return '-'
        }
    }

    getSubtitle() {
        if (this.props.periods != null) {
            var periodTitle = this.props.periods.find(x => x.id == this.props.period)

            if (periodTitle != null) {
                return periodTitle.title;
            }
            else {
                return this.props.subtitle;
            }
        }
        else {
            return this.props.subtitle;
        }
    }

    getPeriodDots() {
        if (this.props.periods != null) {
            const dots = [];
            for (var i = 0; i < this.props.periods.length; i++) {
                if (this.props.periods[i].id == this.props.period) {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 1 }} key={i}></i>);
                }
                else {
                    dots.push(<i className="fello-dot period-dots" style={{ opacity: 0.4 }} key={i}></i>);
                }
            }
            return dots;
        }
        else {
            return null;
        }
    }

    getPeriodExists() {
        var style = {}
        if (this.props.periods != null) {
            style['cursor'] = 'pointer'
        }
        if (this.props.selectedParam.params && this.props.selectedParam.params.theme) {
            style['backgroundColor'] = colorShadeSwatches[this.props.selectedParam.params.theme][0]
            style['color'] = '#FFF'
        }
        return style
    }

    getLevel(value) {
        if (value) {
            if (this.props.levelRange) {

                if (value <= this.props.levelRange.danger) {
                    this.widgetLevel = 'danger'
                }
                else if (value > this.props.levelRange.danger && value < this.props.levelRange.success) {
                    this.widgetLevel = 'warning'
                }
                else if (value >= this.props.levelRange.success) {
                    this.widgetLevel = 'success'
                }

            }
            else {
                this.widgetLevel = this.props.level
            }
        }
        else {
            this.widgetLevel = this.props.level
        }
    }

    getIcon(val) {
        if (this.props.iconRange && val) {
            var closest = Object.keys(this.props.iconRange).reduce(function (prev, curr) {
                return (Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev);
            });
            return <i className={this.props.iconRange[closest]} />
        }
        else if (this.props.icon) {
            return <i className={this.props.icon} />
        }
        else if (this.props.selectedParam.params && this.props.selectedParam.params.customIcon) {
            return <i className={this.props.selectedParam.params.customIcon} />
        }
        else {
            return <i className='fas fa-charging-station' />
        }
    }

    getTitle() {
        var title = ''
        if (this.props.selectedParam.params && this.props.selectedParam.params.customTitle) {
            title = this.props.selectedParam.params.customTitle
        }
        else {
            title = this.props.widgetValue && this.props.widgetValue.title 
        }
        return <span>{title}</span>
    }

    render() {
        const val = this.getResult(this.props.widgetValue, this.props.period, this.props.factor, this.props.decimalPlaces)
        
        return (
            <div className={"summary-widget widget " + this.widgetLevel} style={this.getPeriodExists()}>
                <div className='widget-title'>
                    {this.getTitle()}
                </div>
                <div className="widget-icon" style={{ width: '30%' }} >
                    {this.getIcon(val)}
                </div>
                <div className="widget-info" style={{ width: '70%' }}>
                    <Textfit>
                        <div style={{marginTop:'-15px'}}>
                            {val}
                            <span>{this.props.widgetValue && this.props.widgetValue.suffix}</span>
                        </div>
                    </Textfit>
                    <div style={{ marginTop: '-15px' }}>
                        <span style={{ fontSize: '0.8rem' }}>{this.getSubtitle()}</span>
                    </div>
                </div>
                <div className="summaryDots" style={{ marginLeft: '30%', width: '70%' }}>
                    {this.getPeriodDots()}
                </div>
            </div>
        );
    }
}

export default DynamicSummaryWidget