import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums';
import { Nav, NavItem, NavLink, TabContent, TabPane, Col, Row } from 'reactstrap'
import moment from 'moment';
import { ApiPost, getConfigItem, ApiGet } from '../../../api'
import { dbTables } from '../../../configuration/formTables'
export const API_PATH = process.env.SERVER_PATH + "/api"

class WorkCards extends Component {
    
    constructor(props) {
        super(props);
        var customResponses = getConfigItem('$.workcards.customResponses'), customStatus = getConfigItem('$.workcards.customStatus'), partGroups = getConfigItem('$.workcards.partGroups'), generatePicklists = getConfigItem('$.workcards.generatePicklists') || true
        this.state = { activeTab: 1, showAdd: true, data: null, columns: null, subcomponents: null, canGeneratePicklist: false, eventType: 0, currentRec: null, customResponses, partGroups, generatePicklists, customStatus, customLogic: (customResponses != null  || customStatus != null) }
    }

    componentDidMount() {

        if (this.props.location && this.props.location.state && this.props.location.state.incidentId) {
            ApiGet('Workcards', 'GetEventType', { id: this.props.location.state.incidentId, type: 1 })
            .then((data) => { this.setState({ eventType: data }) })
        }
        else if (this.props.match && this.props.match.params.id) {
            ApiGet('Workcards', 'GetEventType', { id: this.props.match.params.id, type: 2 })
            .then((data) => { this.setState({ eventType: data }) })
        }

        this.getJobForms()
    }

    statusVal(rec) {
        if (rec != undefined && this.state.customStatus == null) {
            if (rec == 2) {
                return 'Workcard must be logged'
            }
            else {
                return undefined
            }
        }
    }

    getLC(rec) {
        return rec.data && <button type="button" className="btn" style={{ float: 'left' }} onClick={() => { this.props.history.push('/Workcards/' + rec.data.parentId, { history: [] }), window.location.reload() }} >View Parent Workcard <i className='far fa-arrow-alt-up' /></button>
    }

    currentRec(rec) {
        //console.log('rec',rec)
        this.setState({currentRec: rec})
    }

    createFSR() {
        ApiPost('Calendar', 'CreatePlaceholderFSR', { jobId: this.props.match.params.id })
        .then((data) => {
            //this.props.history.push('/FieldServiceReport/' + data.fsrId, { history: this.props.history.location.state.history })
            this.setState({ sId: Math.random() })
        })
    }

    getServiceReport(r) {
        var srFields = [
            { label: "Id", fieldId: "id", width: 70 },
            { label: "Log Id", fieldId: "engineerLogId", display: 'none', getId: -1 },
            { label: "startTime", fieldId: "startTime", display: 'none' },
            { label: "Assigned", fieldId: "assigned", readOnly: true },
            { label: "Scheduled", fieldId: "scheduledTime", width: 175, readOnly: true },
            { label: "Dispatch Note", fieldId: "dispatchNote", readOnly: true, type: 'textarea' },
            { label: "Work Carried Out", fieldId: "workCarriedOut", display: 'form', readOnly: true, type: 'textarea', readOnly: true },
            { label: "Status", fieldId: "jobStatus", list: listRequest.AllJobStatuses, readOnly: true },
            { label: 'nId', fieldId: "nId", display: 'none', getId: -1 },
            { label: '', fieldId: 'navButton', width: 70, display: 'grid', type: 'navButton', typeProps: { location: '/FieldServiceReport/' } },
            //{ label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ];

        var engineerSelectionConfig = {
            model: 'engineerSelection',
            fields: srFields,
            addInline: false,
            gridButton: false,
            crudParams: { nId: this.props.match && this.props.match.params.id ? this.props.match.params.id : this.props.externalFormId },
            defaultSorted: [{ id: 'id', desc: true }],
            gridProps: { minRows: 20, defaultPageSize: 20, noDataText: <div><br /><b>No Service Reports Available</b></div> },
            apiPath: API_PATH
        }

        return (<div>
            <button type="button" className="btn btn-primary addWorkReport" onClick={() => this.createFSR()}>Add Work Report <i className="fa fa-plus" /></button>
            <MasterDetail key={this.state.sId}{...engineerSelectionConfig} />
        </div>)
    }

    generatePicklist(rec) {
        console.log('rec',rec)
        ApiPost('PickList', 'Generate', { id: rec.id })
        .then((data) => {
            console.log('generatePicklist', data)
            this.setState({ pId: Math.random() })
        })
    }

    consumeParts(rec) {
        console.log('cosumeParts',rec)
    }

    getPartButtons(rec) {
        return (<Row>
                    <Col sm={2}></Col>
                    <Col sm={10} style={{justifyContent:'space-between', display: 'flex'}}>
                            {this.state.canGeneratePicklist && <button type="button" className="btn" style={{ width: '49%', backgroundColor: '#0a92b1', color: '#FFF' }} onClick={() => this.generatePicklist(rec.data)}>Generate Picklist</button>}
                            <button type="button" className="btn" style={{ width: '49%', backgroundColor: '#0a92b1', color: '#FFF', float:'right' }} onClick={() => this.consumeParts(rec.data)}>Consume Parts</button>
                    </Col>
                </Row >)
    }

    getIndicator(r) {
        var colour = r == 2 ? '#0a92b1' : r == 3 ? '#44AF69' : '#666'
        var icon = r == 3 ? 'check-circle' : r == 2 ? 'clock' : 'info-circle'
        var title = r == 2 ? 'On a picklist. Not yet picked.' : r == 3 ? 'Part has been picked.' : 'Not yet added to a picklist.'
        return (<i className={'fas fa-' + icon} title={title} style={{ color: colour, paddingLeft:'5px' }} />)
    }

    parsePartsData(d) {
        var canGeneratePicklist = false
        if (d.data) {
            d.data.map(x => {
                x._subRows.map(s => {
                    if (s.pickStatus == 1) {
                        canGeneratePicklist = true
                    }
                })
            })
        }
        if (canGeneratePicklist != this.state.canGeneratePicklist) {
            this.setState({ canGeneratePicklist, pId: Math.random() })
        }
    }

    qtyVal(rec) {
        if (rec != undefined) {
            if (rec == 99) {
                return 'Workcard must be logged'
            }
            else {
                return undefined
            }
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    getJobForms() {
        ApiGet('ServiceReportForm', 'GetJobFormList', { id: this.props.match.params.id, isServiceReport: false })
        .then((data) => {
            this.setState({ formList: data })
        })
    }

    getTable(tabId) {

        var lists = {
            partGroups: this.state.partGroups ? this.state.partGroups : [{ id: 'Default', name: 'Default' }, { id: 'Electrical', name: 'Electrical' }, { id: 'Civils', name: 'Civils' }],
            status: [{ id: 0, name: 'Open' }, { id: 2, name: 'Approved' }, { id: 5, name: 'Complete' }],
            formList: this.state.formList
        }

        if (tabId == 1) {
            var workcardSections = [
                { label: "Event", openIf: () => this.props.match.params.create != null ? true : false },
                { label: "Workcard", openIf: () => true },
                { label: "Engineer Update", openIf: (rec) => rec.cardState > 0 },
            ]

            var workcardFields = [
                { label: "Id", fieldId: "id", width: 70, display: 'grid', section: 'Workcard' },
                { label: "Incident", fieldId: "incidentId", type: 'readOnly', display: 'none' },
                //{ label: "", fieldId: "title", display: 'form', section: 'Workcard', type: 'custom', custom: (r) => r.data && <h3>{r.data.title}</h3> },
                { label: "Job Type", fieldId: "responseLevel", statusIdentifier: true, section: 'Workcard', list: listRequest.WorkCardTypes, validation: 'required' },
                { label: "Job Status", fieldId: "cardState", statusIdentifier: true, list: listRequest.WorkCardStatuses, listParent: 'responseLevel', section: 'Workcard', validation: (rec) => this.statusVal(rec) },
                { label: "Job Ref", fieldId: "jobRef", section: 'Workcard' },
                { label: "Note", fieldId: "notes", type: 'textarea', section: 'Workcard', validation: 'required', colSpan: 10 },
                //{ label: "Service Report", fieldId: "engineers", type: 'custom', section: 'Workcard', display: 'form', custom: (r) => this.getServiceReport(r) },
                //{ label: "Part Selection", fieldId: "parts", type: 'custom', section: 'Workcard', display: 'form', custom: MasterDetail, customProps: partSelectionConfig },
                //{ label: "", fieldId: "funcButton", display: 'form', section: 'Workcard', type: 'custom', custom: (rec) => this.getPartButtons(rec) },
                { label: "Guid", fieldId: "wCardGuid", display: 'grid' }
            ]

            var defaultData = {}
            if (this.props.match && this.props.match.params.create == 2) {
                var date = moment().utc().format('YYYY-MM-DD')
                defaultData['raised'] = moment(date + ' 08:00').utc()
                defaultData['closed'] = moment(date + ' 17:00').utc()
            }

            var cRec = this.state.currentRec
            if (cRec && cRec.responseLevel == 18) {
                workcardFields = [
                    { label: "Id", fieldId: "id", width: 70, display: 'grid', section: 'Workcard' },
                    { label: "Incident", fieldId: "incidentId", type: 'readOnly', display: 'none' },
                    { label: "Note", fieldId: "notes", type: 'textarea', section: 'Workcard', validation: 'required', colSpan: 10 }
                ]
            }

            var workcardsConfig = {
                model: 'workcards',
                fields: workcardFields,
                //submitAction: () => { },
                preData: this.props.location && { ...this.props.location.state, ...defaultData },
                master: true,
                hideBread:true,
                formActions: this.props.compactMode ? [{ label: 'Cancel', icon: ' ', style: { float: 'left' }, action: () => this.props.cancelAction() }, { action: 'save', after: () => this.props.saveAction() }] : [{ action: 'back' }, { action: 'save' }],
                sections: workcardSections,
                linkedEntity: dbTables.Workcards,
                externalFormId: this.props.externalFormId ? this.props.externalFormId : null,
                compactMode: this.props.compactMode,
                apiPath: API_PATH
            }

            return <MasterDetail key={this.state.pId} {...workcardsConfig} mdRec={(e) => this.currentRec(e)} />
        }
        else if (tabId == 2) {
            return this.getServiceReport()
        }
        else if (tabId == 3) {

            console.log('formList', lists.formList)

            var dFields = [
                { label: "Id", fieldId: "id", display: 'none' },
                { label: "JobId", fieldId: "jobId", display: 'none', getId: -1 },
                { label: "Form Type", fieldId: "formId", list: lists.formList, inlineEdit: true },
                { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn', displayIf: (r) => r.id == -1 },
                { label: "User", fieldId: "user", list: listRequest.operators },
                { label: "Last Modified", fieldId: "lastModified", type: 'datetime' },
                { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { btnLabel: 'View Form' }, funcProps: (rec) => { return { location: '/FormCustom/' + rec._original.rowId + '/1' } }, width: 120, displayIf: (r) => r.id != -1 },
                { label: "", fieldId: "serviceReportId", display: 'grid', type: 'navButton', typeProps: { btnLabel: 'Work Report' }, funcProps: (rec) => { return { location: '/FieldServiceReport/' + rec._original.serviceReportId } }, width: 150, displayIf: (r) => r.id != -1 && r.serviceReportId != 0 },
            ];

            var jobDocConfig = {
                model: 'jobDocuments',
                path: '/ServiceReportForm/JobForms/' + this.props.match.params.id,
                fields: dFields,
                addInline: true,
                gridButton: false,
                gridProps: { defaultPageSize: 20 },
                defaultSorted: [{ id: 'lastModified', desc: true }],
                preData: { lastModified: moment() },
                apiPath: API_PATH
            }

            return <MasterDetail {...jobDocConfig} />
        }
        else if (tabId == 4) {

            var dFields = [
                { label: "Id", fieldId: "id", display: 'none' },
                { label: "Ref", fieldId: "name" },
                { label: "User", fieldId: "user", list: listRequest.operators },
                { label: "Created", fieldId: "created", type: 'datetime' },
                { label: "Status", fieldId: "status", list: lists.status },
                { label: "Value", fieldId: "value" },
                { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { btnLabel: 'View' }, funcProps: (rec) => { return { location: '/Quote/' + rec._original.id } }, width: 70 },
                //{ label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
            ];

            var jobDocConfig = {
                model: 'jobDocuments',
                path: '/ServiceReportForm/GetAllQuotes/' + this.props.match.params.id,
                fields: dFields,
                gridButton: { pathname: '/Quote/New', state: { jobId: this.props.match.params.id, siteId: this.state.currentRec.stationId } },
                gridProps: { minRows: 10, noDataText: <div><br /><b>No Quotes Added</b></div>, showPagination: false },
                apiPath: API_PATH
            }

            return <MasterDetail {...jobDocConfig} />
        }
        else if (tabId == 5) {

            var dFields = [
                { label: "Id", fieldId: "id", display: 'none' },
                { label: "Ref", fieldId: "pRef" },
                { label: "User", fieldId: "user", list: listRequest.operators },
                { label: "Created", fieldId: "created", type: 'datetime' },
                { label: "Status", fieldId: "status", list: lists.status },
                { label: "Value", fieldId: "value" },
                { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', typeProps: { btnLabel: 'View' }, funcProps: (rec) => { return { location: '/PurchaseOrder/' + rec._original.id } }, width: 70 },
                //{ label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
            ];

            var jobDocConfig = {
                model: 'jobPODocuments',
                path: '/ServiceReportForm/GetAllPO/' + this.props.match.params.id,
                fields: dFields,
                gridButton: { pathname: '/PurchaseOrder/New', state: { jobId: this.props.match.params.id, siteId: this.state.currentRec.stationId } },
                gridProps: { minRows: 10, noDataText: <div><br /><b>No POs Added</b></div>, showPagination: false },
                apiPath: API_PATH
            }

            return <MasterDetail {...jobDocConfig} />
        }
        else if (tabId == 6) {

            var partSelectionFields = [
                { label: "Id", fieldId: "id", display: 'none' },
                { label: "Log Id", fieldId: "engineerLogId", display: 'none', getId: -1 },
                { label: "", fieldId: "pickStatus", width: 30, type: 'custom', custom: (rec) => this.getIndicator(rec.pickStatus) },
                { label: "Part", fieldId: "inPartId", list: listRequest.PartNameIdentifier, inlineEdit: true, type: 'searchableList', width: 500 },
                { label: "WH", fieldId: "outPartLocation", list: listRequest.Warehouses, rememberSelection: true, inlineEdit: true, width: 200 },
                { label: "Qty", fieldId: "qty", inlineEdit: true, width: 200, validation: (r) => this.qtyVal(r) },
                { label: "Group", fieldId: "partGroup", inlineEdit: true, list: lists.partGroups },
                { label: "Group", fieldId: "rGroup" },
                { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
            ];

            var partSelectionConfig = {
                model: 'partSelection',
                fields: partSelectionFields,
                gridButton: false,
                submitAction: () => { },
                externalFormId: this.props.externalFormId ? this.props.externalFormId : null,
                customWidth: 0,
                expandAll: true,
                groupRowsBy: 'rGroup',
                mdData: (e) => this.parsePartsData(e),
                gridProps: { minRows: 20, noDataText: <div><br /><b>No Parts Added</b></div>, showPagination: false },
                apiPath: API_PATH
            }

            return <MasterDetail key={this.state.pId} {...partSelectionConfig} />
        }
        else if (tabId == 7) {

            var galleryFields = [
                { label: "Id", fieldId: "id" },
                { label: "Thumbnail", fieldId: "thumbnail" }
            ]

            var jobImgConfig = {
                model: 'jobImages',
                path: '/ServiceReportForm/GetAllImages/' + this.props.match.params.id,
                fields: galleryFields,
                gridButton: { pathname: '/FieldServiceReportUpload/' + this.props.match.params.id, state: { addNew: true, uploadType: 1, isJob: true } },
                galleryView: { "gallery": { "rows": "5", "cols": "4" } },
                apiPath: API_PATH
            }

            return <MasterDetail {...jobImgConfig} />
        }
        else if (tabId == 8) {

            var galleryFields = [
                { label: "Id", fieldId: "id" },
                { label: "Thumbnail", fieldId: "thumbnail" }
            ]

            var jobImgConfig = {
                model: 'jobImages',
                path: '/ServiceReportForm/GetAllFiles/' + this.props.match.params.id,
                fields: galleryFields,
                gridButton: { pathname: '/FieldServiceReportUpload/' + this.props.match.params.id, state: { addNew: true, uploadType: 2, isJob: true } },
                galleryView: { "gallery": { "rows": "5", "cols": "4" } },
                apiPath: API_PATH
            }

            return <MasterDetail {...jobImgConfig} />
        }
    }

    render() {
        var showPreCheck = false
        if (this.props.location && this.props.location.state && this.props.location.state.history && this.props.location.state.history.find(x => x.label == 'Schedule') != undefined) {
            showPreCheck = true
        }

        const { activeTab } = this.state;
        const tabConfig = [{ id: 1, name: 'Job' }, { id: 2, name: 'Work Reports' }, { id: 3, name: 'Forms' }, { id: 4, name: 'Quotes' }, { id: 5, name: 'POs' }, { id: 6, name: 'Parts' }, { id: 7, name: 'Images' }, { id: 8, name: 'Files' }]

        var workcardFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'grid', section: 'Workcard' },
            { label: "", fieldId: "title", display: 'form', section: 'Workcard', type: 'custom', custom: (r) => r.data && <div><h5>{r.data.siteName}</h5><h4>{r.data.clientName}</h4></div> },
        ]

        var workcardsConfig = {
            model: 'workcardsHeader',
            path: '/workcards',
            fields: workcardFields,
            master: true,
            formActions: this.props.match.params.id != 'New' && [],
            apiPath: API_PATH
        }

        return (
            <div>
                <MasterDetail  {...workcardsConfig}>
                    {this.props.match.params.id != 'New' &&
                        <div>
                            <Nav tabs>
                                {tabConfig.map(t => (
                                    <NavItem key={t.id}>
                                        <NavLink
                                            className={activeTab === t.id ? 'active' : ''}
                                            onClick={() => this.toggle(t.id)}>
                                            {t.name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            <TabContent key='clientTabs' activeTab={activeTab}>
                                {tabConfig.map(t => (
                                    <TabPane key={t.id} tabId={t.id}>
                                        {activeTab === t.id && this.getTable(t.id)}
                                    </TabPane>
                                ))}
                            </TabContent>
                        </div>
                    }
                </MasterDetail>
            </div>
        )
    }
}

export default (WorkCards)
