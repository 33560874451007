import React, { Component } from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Input, Button} from 'reactstrap';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums';
import SimpleModal from '../../../components/SimpleModal'
import SearchList from './../../../components/SearchList'
import IdNameDropdown from '../../../components/Controls/IdNameDropdown'
import { ApiPost, ApiGet } from '../../../api'
export const API_PATH = process.env.SERVER_PATH + "/api";

class JobOverview extends Component {

    constructor(props) {
        super(props); 
        this.state = { activeTab: 1, header: null, body: null, footer: null, open: false };
        this.jobStatuses = []
    }  

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    componentDidMount() {
        var listsToGet = [listRequest.WorkCardTypes, listRequest.CustomerWithSites, listRequest.WorkCardStatuses ]
        ApiGet('Lists', 'GetList', { ListIds: JSON.stringify(listsToGet) })
        .then((data) => {
            this.setState({ eventTypes: data[listRequest.WorkCardTypes], sites: data[listRequest.CustomerWithSites], workStatuses: data[listRequest.WorkCardStatuses], loadComplete: true })
        })
        document.title = 'Job Overview | izon'
    }

    getTable(jobType) {

        var jobOverviewFields = [
            { label: "Job Id", fieldId: "id", width: 70 },
            { label: "Customer", fieldId: "customer", canFilter:true },
            { label: "Site", fieldId: "site", canFilter: true },
            { label: "Postcode", fieldId: "postcode", width:100 },
            { label: "Type", fieldId: "jobType", list: listRequest.WorkCardTypes },
            { label: "Ref", fieldId: "jobRef" },
            { label: "Status", fieldId: "status", list: listRequest.AllJobStatuses, canFilter: false },
            //{ label: "Status", fieldId: "status", list: (r) => this.state.workStatuses.find(x => x.id == r.jobType).options },
            { label: "Updated", fieldId: "lastUpdated", type: "datetime" },
            //{ label: "Due", fieldId: "due" },
            { label: "Work Reports", fieldId: "srCount" },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/Workcards/' } },
        ];

        var jobOverviewSetup = {
            model: 'JobOverview',
            fields: jobOverviewFields,
            master: true,
            titleField: 'jobRef',
            crudParams: { jobType },
            gridProps: { defaultPageSize: 40 },
            gridButton: false,
            showFilterRow: false,
            defaultSorted: [{ id: 'lastUpdated', desc: true }],
            apiPath: API_PATH,
        }

        return <MasterDetail key={jobType} {...jobOverviewSetup} />
    }

    addNewJob() {
        ApiPost('Calendar', 'CreateJob', { deviceId: this.state.deviceId, jobType: this.state.jobType, jobStatus: this.state.jobStatus })
        .then((data) => {
            if (data.jobId != 0) {
                this.props.history.push('Workcards/' + data.jobId )
            }
        })
    }

    getCols() {
        var headers = this.state.workcardStatuses.find(x => x.id == this.state.jobType)
        var kH = [], dDevices = [...new Set(this.state.activity.map(item => item.deviceId))]
        if (headers) {
            headers.options.map(h => {
                var kC = []
                var tA = JSON.parse(JSON.stringify(this.state.activity))
                var sCards = tA.filter(x => x.jobStatus == h.id)
                sCards.map(s => {
                    kC.push(
                        <div className='activityCardCompact kanbanCard' onClick={() => this.props.history.push('Workcards/' + s.jobId, { history: null })}>
                            <div className='cardTag' style={{ backgroundColor: colorSwatches[0][dDevices.indexOf(s.deviceId)] }} />
                            <Row className='kanbanContent'>
                                <b>{s.stationName}</b>
                                <div>
                                    <i className='far fa-clock' title='Total time spent on job' /><span>{s.totalTime}</span>
                                    <i className='far fa-user-hard-hat' title='Total number of visits' /><span>{s.totalVists}</span>
                                </div>
                            </Row>
                            <Row className='kanbanContent'>
                                <span>{s.jobRef || s.jobId}</span>
                                <Button className='pmButton' onClick={(e) => { e.stopPropagation(); e.preventDefault(); window.open('http://10.20.1.24:8080/site/project?_dc=1714381783030', '_blank'); }}>PM</Button>
                            </Row>
                        </div>
                    )
                })
                kH.push(<div className='kanbanCol'><div className='kanbanHeader'><b>{h.name}</b> <span>({sCards.length})</span></div>{kC}</div>)
            })
        }
        return (<div className='kanbanBoard'>
            {kH}
        </div>)
    }

    newJob() {
        //var jobStatuses = this.state.workStatuses.find(x => x.id == this.state.jobType) ? this.state.workStatuses.find(x => x.id == this.state.jobType).options : []
        var header = <span>New Job</span>,
            body = <div>
                <Row style={{ marginTop: '-10px' }}>
                    <Col xs={6}><span>Select Site</span></Col>
                </Row>
                <Row>
                    <Col xs={12}><SearchList onChange={(e) => e.value != 0 && this.setState({ deviceId: e.value })} placeholder='Select Site' value={this.state.deviceId} mainData={this.state.sites} readOnly={false} /></Col>
                </Row>
                <Row>
                    <Col xs={12}><span>Select Job Type</span></Col>
                </Row>
                <Row>
                    <Col xs={12}><IdNameDropdown options={this.state.eventTypes} zeroText='Select Job Type' style={{ height: '100%', padding: '0.2rem' }} valueField='id' displayField='name' onChange={(e) => { this.setState({ jobType: e, jobStatus: this.state.workStatuses.find(x => x.id == e).options[0].id }); }} /></Col>
                </Row>
            </div>,
            footer = <div style={{ width: '100%' }}>
                <Button onClick={() => { this.setState({ open: !this.state.open }); }}>Cancel</Button>
                <Button style={{ float: 'right' }} onClick={() => this.addNewJob()}>Save</Button>
            </div>

        this.setState({ open: true, header, body, footer })
    }

    render() {

        const { footer, header, body, open, activeTab, loadComplete } = this.state
        const tabConfig = [{ id: 1, name: 'Active' }, { id: 2, name: 'Prospective' }, { id: 3, name: 'Completed' }, { id: 4, name: 'Cancelled' }]

        return (
            <div>
                <div className='titleBlock'>Job Overview</div>
                <Row style={{ justifyContent:'space-between', margin: '0 0' }}>
                    <Nav tabs>
                        {tabConfig.map(t => (
                            <NavItem key={t.id}>
                                <NavLink
                                    className={activeTab === t.id ? 'active' : ''}
                                    onClick={() => this.toggle(t.id)}>
                                    {t.name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <button type="button" className="btn btn-primary addWorkReport" onClick={() => this.newJob()}>New Job <i className="fa fa-plus" /></button>
                </Row>
                <TabContent key='clientTabs' activeTab={activeTab}>
                    {tabConfig.map(t => (
                        <TabPane key={t.id} tabId={t.id}>
                            {activeTab === t.id && loadComplete && this.getTable(t.id)}
                        </TabPane>
                    ))}
                </TabContent>
                <SimpleModal {...{ header, body, footer, open }} width={'500px'} />
            </div>
        );

    }
}

export default (JobOverview)
