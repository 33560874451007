import React, { Component } from 'react'
import moment from 'moment'
import IncidentChart from './IncidentChart'
import IncidentMap from './IncidentMap'
import { getConfigItem, ApiPost, ApiGet } from '../../../api'

class IncidentViz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cats: null, chartData: null, plotTime: moment(this.props.fromDate).format('YYYY-MM-DDTHH:mm:ss'), plotlineActive: false,
            showChart: getConfigItem('$.incidents.showChart'), showMap: getConfigItem('$.incidents.showMap'),
            iData: null, guardAreas: null, fetchComplete: false, mapKey: 1
        }
    }

    componentDidMount() {
        ApiGet('Monitoring', 'GetVesselDetails')
        .then((data) => {
            this.setState({ guardAreas: data.guardAreas }, () => this.createCategories() )
        })
    }

    componentDidUpdate(nextProps) {
        if (nextProps.data != this.props.data) {
            this.createCategories()
        }
    }

    createCategories() {
        var cats = [], mmsis = []
        this.props.data.map(x => {
            if (!cats.includes(x.groupRef)) {
                cats.push(x.groupRef)
                mmsis.push(x.groupId)
            }
        })
        this.setState({ cats }, () => this.genChartData())

        ApiGet('Monitoring', 'GetAlertGeoData', { mmsi: mmsis, fromDate: moment(this.props.fromDate).format('YYYY-MM-DDTHH:mm:ss'), toDate: moment(this.props.toDate).format('YYYY-MM-DDTHH:mm:ss'), sourceId: 1 })
        .then((d) => {
            this.setState({ iData: d }, () => d.values && this.genHeatData(d.values))
        });
    }

    genHeatData(d) {
        var p = []
        d.map(x => {
            p.push({lat: x[4], lng: x[5]})
        })
        var heatmapData = p
        this.setState({ heatmapData, fetchComplete: true })
    }

    genChartData() {
        var alertColours = { 34: '#9bc7cf', 35: '#FCD757', 36: '#fc9f57', 37: '#EE6352' }
        var chartData = []
        this.props.data.map(x => {
            chartData.push({ x: Date.parse(x.raised), x2: x.closed ? Date.parse(x.closed) : new Date().getTime(), y: this.state.cats.indexOf(x.groupRef), color: alertColours[x.alertTypeId] })
        })
        this.setState({chartData})
    }

    setPlotTime(x) {
        this.setState({ plotTime: moment(x).format('YYYY-MM-DDTHH:mm:ss') });
    }

    refreshMap() {
        console.log('refreshmap')
        this.setState({mapKey: this.state.mapKey+1})
    }

    render() {
        console.log('props', this.props)
        console.log('state',this.state)
        return this.state.fetchComplete ? (
            <div>
                {this.state.showMap && <IncidentMap key={'im_' + this.state.mapKey} data={this.props.data} iData={this.state.iData} heatmapData={this.state.heatmapData} plotTime={this.state.plotTime} fromDate={this.props.fromDate} toDate={this.props.toDate} guardAreas={this.state.guardAreas} refreshMap={() => this.refreshMap()} /> }
                {this.state.showChart && <IncidentChart chartData={this.state.chartData} setPlotTime={(x) => this.setPlotTime(x)} plotTime={this.state.plotTime} cats={this.state.cats} fromDate={this.props.fromDate} toDate={this.props.toDate} setGroupRef={(e) => this.props.setGroupRef(e)} /> }
            </div>
        ) : null
    }  
}

export default (IncidentViz)
