import React from 'react';

function getBulletColor(props)
{
    if (props.fault > 0) {
        return '#d0021b';
    }
    else if (props.warn > 0) {
        return '#ffa500';
    }
    else if (props.current <= 100) {
        return '#BBB';
    }
}

const ScadaBullet = (props) => {

    return (
        <div className="scada-bullet-maincontainer">
            <div className="scada-bullet-container current" style={{ width: '81%' }}>
                <div className="scada-bullet current" style={{ width: props.current + '%', backgroundColor: getBulletColor(props) }}></div>
            </div>
            <div className="scada-bullet-container warn" style={{ width: '10%' }}>
                <div className="scada-bullet warn" style={{ width: props.warn + '%', backgroundColor: getBulletColor(props) }}></div>
            </div>
            <div className="scada-bullet-container fault" style={{ width: '10%' }}>
                <div className="scada-bullet fault" style={{ width: props.fault + '%', backgroundColor: getBulletColor(props) }}></div>
            </div>
        </div>
    );
};


export default ScadaBullet;
    