import React, { Component } from 'react';
import { ApiGet, ApiPost } from '../../../api';
import { connect } from 'react-redux';
import { getStationMetrics } from '../../../actions/header'
import { Textfit } from 'react-textfit'
import { widgetconfig, metricInfo } from '../../../configuration/widgets';

class MetricWidget extends Component {

    constructor(props) {
        super(props);

        const cacheResult = null;
        const widgetLevel = this.props.level;
    }

    getMetric(metid, avg, factor, decimalPlaces)
    {
        var result;

        if (avg && this.props.filterAverageMetric) {
            result = this.props.filterAverageMetric[metid];
        } else if (this.props.filterTotalMetric) {
            result = this.props.filterTotalMetric[metid];
        }
        if (result != undefined)
        {
            result = result * factor
            if (result < 0)
            {
                result = 0;
            }
            this.cacheResult = result.toFixed(decimalPlaces);
            return result.toFixed(decimalPlaces);
        }
        else
        {        
            return '-';
        }
    }

    render() {

        var selectedMetric = this.props.selectedParam.params && this.props.selectedParam.params.metric ? this.props.selectedParam.params.metric : this.props.selectedParam.metric;

        //console.log('selectedMetric', selectedMetric);

        var metricDetail = metricInfo[this.props.id][selectedMetric];

        const metricValue = this.getMetric(selectedMetric, metricDetail.avg, metricDetail.factor, metricDetail.decimalPlaces);

        if (metricValue == '-')
        {
            this.widgetLevel = "greyOut";
        }
        else
        {
            this.widgetLevel = metricDetail.level;
        }

        return (
            <div className={"summary-widget widget " + this.widgetLevel}>
                <div className="widget-icon" >
                    <i className={metricDetail.icon}></i>
                </div>
                <div className="widget-info">
                    <Textfit>
                        <div >
                            {metricValue}
                            <span>{metricDetail.suffix}</span>
                        </div>
                    </Textfit>
                    <div>
                        <span>{metricDetail.subtitle}</span>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return state.common.header
}

export default connect(
    mapStateToProps
)(MetricWidget)
