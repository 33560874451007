import React, { Component } from 'react'
import { connect } from 'react-redux'
import DeviceMeteringSideController from './DeviceMeteringSideController'
import DeviceMeteringTables from './DeviceMeteringTables'
import DeviceMeteringController from './DeviceMeteringController'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'

class DeviceMeteringContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mData: null, activeTab: '1', mainActiveTab: '1',
        }
        this.handleData = this.handleData.bind(this)
    }

    componentDidMount() {
        document.title = 'Metering | izon'
    }

    handleData(data) {
        var mergedState = { ...this.state.mData, ...data, ...{ showMonthly: this.state.activeTab === '1' } }
        this.setState({ mData: mergedState })
    }

    setSort(e) {
        var mergedState = { ...this.state.mData, ...{ sortType: e} }
        this.setState({ mData: mergedState })
    }

    toggle(tab, type) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        return (
            <div>
                <div className='titleBlock'>Metering</div>
                <Nav tabs key='caseTabs' style={{ marginTop: '20px' }}>
                    <NavItem >
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1', 'sub') }}>Monthly</NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2', 'sub') }}>Daily</NavLink>
                    </NavItem>
                </Nav>
                <TabContent key='casesTabs' activeTab={this.state.activeTab}>
                    <TabPane tabId='1'>
                        {this.state.activeTab == '1' &&
                            <div>
                                <DeviceMeteringController handleData={this.handleData} mData={this.state.mData} key='dmc_month' />
                                <DeviceMeteringSideController handleData={this.handleData} key='dmsc_month' />
                                {this.state.mData && <DeviceMeteringTables mData={this.state.mData} setSort={(v) => this.setSort(v)} devices={this.props.header.assets} key='dmt_month' />}
                            </div>
                        }
                    </TabPane>
                    <TabPane tabId='2'>
                        {this.state.activeTab == '2' &&
                            <div>
                                <DeviceMeteringController handleData={this.handleData} mData={this.state.mData} key='dmc_day' />
                                <DeviceMeteringSideController handleData={this.handleData} key='dmsc_day' />
                                {this.state.mData && <DeviceMeteringTables mData={this.state.mData} setSort={(v) => this.setSort(v)} devices={this.props.header.assets} key='dmt_day' />}
                            </div>
                        }
                    </TabPane>
                </TabContent>
                

            </div>
        )
    }

}

const mapStateToProps = state => {
    return { commonstate: state.common, header: state.common.header }
}

export default connect(
    mapStateToProps
)(DeviceMeteringContainer)
