import React, { Component } from 'react';
import MasterDetail from 'MasterDetail'
//import MasterDetail from '../../../testComp/MasterDetail/src'
import { listRequest, appEntityIcons, inputTypes } from '../../../configuration/enums'
import { availTables } from '../../../configuration/formTables'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import { ApiPost, ApiGet } from '../../../api'
import GroupSelector from './GroupSelector'
import LookupSelector from './LookupSelector'
import ValidationBuilder from './ValidationBuilder'
import RuleSetup from './RuleSetup'
import FormSelection from './FormSelection'
import { getConfigItem } from '../../../api/index'
import ValidationRange from './ValidationRange'
export const API_PATH = process.env.SERVER_PATH + '/api'

class FormBuilder extends Component {

    constructor(props) {
        super(props);
        var config = getConfigItem('$.appBuilder') 
        this.state = {
            activeTab: '1', mainActiveTab: '1', aTbls: null, aTblFields: null, sId: 1, isTaskList: false, currentRec: null, isSubform: false, allowGD: config && config.GoogleDrive,
            defaultValues: config && config.DefaultValues != null ? config.DefaultValues : null, formFields: [], subTables: null
        }
    }

    componentDidMount() {
        this.doSetup()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id != this.props.match.params.id) {
            this.doSetup()
        }
    }

    doSetup() {
        this.generateAvailTblFields()
        ApiGet('CustomForm', 'GetFormType', { formId: this.props.match.params.id })
        .then((d) => {
            this.setState({ isTaskList: d.isTaskList, isSubform: d.isSubform, activeTab: '1', mainActiveTab: '1', subTables: d.subTables })
        })
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab });
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    tCallback(rec) {
        console.log('callBack', rec)
        this.setState({mTbl: rec})
    }

    generateAvailTblFields() {
        var aTbls = [], aTblFields = []
        availTables.map(t => {
            aTbls.push({ id: t.id, name: t.name })
            t.fields.map(f => {
                aTblFields.push({id: t.id+'-'+f.id, name: f.name || f.id, category: t.id})
            })
        })
        if (this.state.defaultValues) {
            var tVals = []
            Object.keys(this.state.defaultValues).map(x => {
                tVals.push({ id: '['+this.state.defaultValues[x]+']', name: this.state.defaultValues[x]})
            })
        }
        this.setState({ aTbls, aTblFields, defaultValues: tVals })
    }

    cascadeComponents() {

        var lists = {
            questionType: [{ id: 1, name: 'Text' }, { id: 2, name: 'Number' }, { id: 3, name: 'Date' }, { id: 4, name: 'Entity' }],
            availTables,
            availFields: availTables.find(x => x.id == this.state.mTbl) ? availTables.find(x => x.id == this.state.mTbl).fields : [{ id: 0, name: 'None' }]
        }

        var iFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Field Name", fieldId: "dataName" },
            { label: "Field Type", fieldId: "fieldDataType", list: lists.questionType },
        ];

        var iFConfig = {
            model: 'FormBuilderField',
            path: '/FormBuilder/Field',
            fields: iFields,
            //crudParams: { fieldId: this.props.match.params.id },
            gridButton: false,
            gridProps: { defaultPageSize: 5 },
            master: true,
            apiPath: API_PATH
        }
        return <MasterDetail {...iFConfig} />
    }

    currentRec(rec) {
        if (rec.id) {
            var formFields = []
            if (rec.formFields) {
                rec.formFields.map(f => {
                    formFields.push({ id: f.formFieldDefId, name: f.dataName })
                })
            }
            this.setState({ currentRec: rec, formFields })
        }
    }

    render() {

        var lists = {
            questionType: inputTypes,
            availTables,
            validation: [{ id: 'required', name: 'Required' }, { id: 'number', name: 'Number' }, { id: 'custom', name: 'Custom' }, { id: 'range', name: 'Range' }],
            availFields: availTables.find(x => x.id == this.state.mTbl) ? availTables.find(x => x.id == this.state.mTbl).fields : [{ id: 0, name: 'None' }],
            mapType: [{ id: 'satellite', name: 'Satellite' }, { id: 'roadmap', name: 'Roadmap' }],
            uploadType: [{ id: 'img', name: 'Images' }, { id: 'file', name: 'Files' }],
            formType: [{ id: 1, name: 'Standard' }, { id: 2, name: 'Gallery' }, { id: 3, name: 'Task List' }],
            icons: appEntityIcons,
            autofillType: [{ id: 1, name: 'Active User' }, { id: 2, name: 'Timestamp' }],
        }

        if (this.state.allowGD){
            lists.uploadType.push({ id: 'folder', name: 'G Drive Folder' })
        }

        var fId = this.props.match.params.id

        var BuilderFields = [
            { label: "Id", fieldId: "id", width: 70, display:'none' },
            { label: "Form Name", fieldId: "name" },
            { label: "Description", fieldId: "desc" },
            { label: "Icon", fieldId: "icon", list: lists.icons },
            { label: "Form Type", fieldId: "formType", list: lists.formType },
            { label: "Linked Entity", fieldId: "linkedEntity", list: lists.availTables },
            { label: "Gallery Rows", fieldId: "gRows", display: 'form', displayIf: (rec) => rec.formType == 2 },
            { label: "Gallery Columns", fieldId: "gCols", display: 'form', displayIf: (rec) => rec.formType == 2 },
            { label: "", fieldId: "groupId", type: 'custom', display: 'form', custom: (r) => <GroupSelector {...r} gType={2} /> },
            { label: "", fieldId: "groupList", display: 'none' },
            { label: "Role", fieldId: "role", list: listRequest.ClientRoles, displayIf: (r) => !r.isSystem },
            { label: "Sync Freq (sec)", fieldId: "syncFreq" },
            //{ label: "Coordinates", fieldId: "coors", type: 'coorPicker', display: 'form' },
            { label: "Title Field", fieldId: "titleFieldId", list: this.state.formFields.length > 0 ? this.state.formFields : null },
            { label: "Entity Supplement", fieldId: "entitySupplement", display: 'form', type: 'checkbox' },
            { label: "Indexed filtering", fieldId: "indexedSearch", display: 'form', type: 'checkbox' },
            { label: "System Table", fieldId: "isSystem", display: 'form', type: 'checkbox' },
            { label: "Multistep Form", fieldId: "isWizard", display: 'form', type: 'checkbox' },
            { label: "Publicly Accessible", fieldId: "isPublic", display: 'form', type: 'checkbox' },
            { label: "Public Form Id", fieldId: "id", display: 'form', type:'readOnly', displayIf: (rec) => rec.isPublic == true },
            { label: "", fieldId: "funcButton", display: 'form', type: 'funcButton', typeProps: { label: 'View Responses', func: (rec) => { return this.props.history.push('/FormSelection/' + fId + '/0') } } },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' },
        ];

        var FieldFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Field Name", fieldId: "dataName" },
            { label: "Field Type", fieldId: "fieldDataType", list: lists.questionType },
            { label: "Group", fieldId: "groupName", display: 'grid' },
            { label: "DB Field", fieldId: "dbField", display: 'form', list: this.state.aTblFields, displayIf: (rec) => rec.fieldDataType == 4 },
            { label: "Map Type", fieldId: "mapType", display: 'form', list: lists.mapType, displayIf: (rec) => rec.fieldDataType == 5, validation: 'required' },
            { label: "Upload Type", fieldId: "uploadType", display: 'form', list: lists.uploadType, displayIf: (rec) => rec.fieldDataType == 6 },
            { label: "Lookup", fieldId: "lookupTbl", display: 'form', list: listRequest.FormLookups, displayIf: (rec) => rec.fieldDataType == 9 },
            { label: "Table Lookup", fieldId: "customTableLookup", display: 'form', type: 'custom', custom: (r) => <LookupSelector data={r} {...r} type={'customLookup'} mainLabel={'Table'} subLabel={'Field'} />, displayIf: (rec) => rec.fieldDataType == 14 },
            { label: "Meter Selector", fieldId: "meterSelector", display: 'form', type: 'custom', custom: (r) => <LookupSelector data={r} {...r} type={'deviceMetric'} mainLabel={'Device'} subLabel={'Metric'} />, displayIf: (rec) => rec.fieldDataType == 16 },
            { label: "DB Lookup", fieldId: "dbLookupTbl", display: 'form', list: listRequest.DBFormLookups, displayIf: (rec) => rec.fieldDataType == 10 },
            { label: "Subtable", fieldId: "subTable", display: 'form', list: this.state.subTables, displayIf: (rec) => rec.fieldDataType == 17 },
            //{ label: "Line Break Header", fieldId: "linebreakHeader", display: 'form', displayIf: (rec) => rec.fieldDataType == 12 },
            { label: "Validation", fieldId: "fieldValidation", list: lists.validation, display: 'form' },
            { label: "", fieldId: "customValidation", type: 'custom', custom: ValidationBuilder, displayIf: (rec) => rec.fieldValidation == 'custom' },
            { label: "", fieldId: "rangeValidation", type: 'custom', custom: ValidationRange, displayIf: (rec) => rec.fieldValidation == 'range' },
            { label: "Default Value", fieldId: "defaultValue", list: this.state.defaultValues, display: this.state.defaultValues ? 'form' : 'none'},
            { label: "Suffix", fieldId: "suffix", display: 'form' },
            { label: "Hidden Field", fieldId: "hidden", display: 'form', type: 'checkbox' },
            { label: "Display in table", fieldId: "showOnGrid", display: 'form', type: 'checkbox', displayIf: (rec) => rec.hidden != true },
            { label: "Allow filter", fieldId: "allowFilter", display: 'form', type: 'checkbox', displayIf: (rec) => rec.showOnGrid == true },
            { label: "Unique Search Field", fieldId: "keyField", display: 'form', type: 'checkbox' },
            { label: "App Status Field", fieldId: "statusField", display: 'form', type: 'checkbox' }, //DETERMINES FORM STATUS TO CONTROL GROUP VISABILITY
            { label: "AutoFill", fieldId: "autoFill", display: 'form', type: 'checkbox' },
            { label: "List Width (px)", fieldId: "gridWidth", display: 'form', displayIf: (rec) => rec.showOnGrid },
            { label: "", fieldId: "groupId", type: 'custom', display: 'form', custom: GroupSelector, displayIf: (rec) => rec.hidden != true},
            { label: "", fieldId: "groupList", display: 'none' },
            { label: 'feI', fieldId: "formEntityId", display: 'none', getId: -1 },
        ];

        var ResponseFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Time Stamp", fieldId: "timeStamp", type:"datetime" },
            { label: "User", fieldId: "userId", list: listRequest.operators },
            { label: "Version", fieldId: "version" },
            { label: "", fieldId: "funcButton", display: 'grid', type: 'funcButton', width: 70, typeProps: { label: 'View', func: (rec) => { return this.props.history.push('/FormCustom/' + rec._original.id + '/1') } } }
        ];

        var SubFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Form Name", fieldId: "name", inlineEdit: true },
            { label: '', fieldId: "formId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/FormBuilder/' } }
        ]

        var DefaultFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Field Name", fieldId: "name", inlineEdit: true },
            { label: '', fieldId: "formEntityId", display: 'none', getId: -1 },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' },
        ]

        var BuilderConfig = {
            model: 'FormBuilder',
            fields: BuilderFields,
            master: true,
            titleField: 'name',
            hideDelete: true,
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        var FieldConfig = {
            model: 'FormBuilderFields',
            path: '/FormBuilder/Fields',
            crudParams: { formId: fId},
            fields: FieldFields,
            addInline: true,
            cascade: true,
            expandNewRow: true,
            reorder: 'field',
            gridProps: { defaultPageSize: 25 },
            apiPath: API_PATH
        }

        var FieldConfig2 = {
            model: 'FormBuilderField',
            path: '/FormBuilder/Field',
            crudParams: { cascadeId: 1 },
            fields: FieldFields,
            master: true,
            gridButton: false,
            submitAction: () => this.setState({ sId: Math.random() }, () => this.setState({ sId: Math.random() })),
            hideBread: true,
            apiPath: API_PATH
        }

        var responseConfig = {
            model: 'FormBuilderResponses',
            path: '/FormBuilder/Responses',
            fields: ResponseFields,
            crudParams: { formId: fId },
            hideDelete: true,
            gridButton: false,
            apiPath: API_PATH
        }

        var SubConfig = {
            model: 'FormSubforms',
            fields: SubFields,
            crudParams: { formId: fId },
            hideDelete: true,
            addInline: true,
            titleField: 'name',
            reorder: 'form',
            apiPath: API_PATH
        }

        var DFieldsConfig = {
            model: 'FormDefaultFields',
            fields: DefaultFields,
            defaultSorted: [{ id: 'orderId' }],
            crudParams: { formId: fId },
            hideDelete: true,
            addInline: true,
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Form Builder</div>
                <div>
                    <MasterDetail key={fId + this.state.sId} {...BuilderConfig} mdRec={(e) => this.currentRec(e)}>
                        {fId != 'New' &&
                            <div><Nav tabs >
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Fields</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.mainActiveTab === '3' })} onClick={() => { this.toggle('3', 'main') }}>Subforms</NavLink>
                                </NavItem>
                                {this.state.isSubform && <NavItem>
                                    <NavLink className={classnames({ active: this.state.mainActiveTab === '5' })} onClick={() => { this.toggle('5', 'main') }}>Default Records</NavLink>
                                </NavItem>}
                                {this.state.currentRec && (this.state.currentRec.formType == "1" || this.state.currentRec.formType == "3")  && <NavItem>
                                    <NavLink className={classnames({ active: this.state.mainActiveTab === '6' })} onClick={() => { this.toggle('6', 'main') }}>Rules</NavLink>
                                </NavItem>}
                            </Nav>
                            <TabContent key='clientTabs' activeTab={this.state.mainActiveTab}>
                                <TabPane tabId='1'>
                                    {this.state.mainActiveTab == '1' && <MasterDetail key={'m_' + this.state.sId} {...FieldConfig} ><MasterDetail key={'mI_' + this.state.sId} {...FieldConfig2} /></MasterDetail>}
                                </TabPane>
                                <TabPane tabId='3'>
                                    {this.state.mainActiveTab == '3' && <MasterDetail key={fId + '4'} {...SubConfig} />}
                                </TabPane>
                                <TabPane tabId='5'>
                                    {this.state.mainActiveTab == '5' && <FormSelection formEntityId={fId} />}
                                </TabPane>
                                <TabPane tabId='6'>
                                    {this.state.mainActiveTab == '6' && <div>{this.state.currentRec && <RuleSetup rec={this.state.currentRec} />}</div>}
                                </TabPane>
                            </TabContent></div>}
                    </MasterDetail>
                </div>
            </div >
            );
    }
}

export default (FormBuilder)