import React, { Component } from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import MasterDetail from 'MasterDetail'
import { listRequest } from '../../../configuration/enums';
import moment from 'moment'
export const API_PATH = process.env.SERVER_PATH + '/api'

class ClientSettings extends Component {

    constructor(props) {
        super(props); 
        this.toggle = this.toggle.bind(this);
        this.state = { fileDetails: null, clientName: '', activeTab: '1', mainActiveTab: '1', testKey: 1 };
    }  

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({ mainActiveTab: tab});
            }
        }
        else {
            if (this.state.activeTab !== tab) {
                this.setState({ activeTab: tab });
            }
        }
    }

    validate(fieldValue, fullRecord) {
        console.log('value record', { fieldValue, fullRecord })
        if (fieldValue != undefined && (moment(fieldValue) < moment())) {
            return "DATE CAN'T BE LESS"
        }
        else {
            return ""
        }
    }

    validateStartDate = (rec) => {
        if (rec != undefined) {
            console.log('rec', rec)
            console.log('this.state.actualStartDate', this.state.actualStartDate)
            if (!this.state.actualStartDate)
                this.setState({ actualStartDate: moment(rec) }, () => console.log('set'));
            if (moment(rec) < this.state.actualStartDate) {
                return "Start date cannot be less than the previous start date.";
            }
            return undefined;
        }
    }

    render() {

        const { mainActiveTab } = this.state;

        var ClientSelectionFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none'},
            { label: "Client Name", fieldId: "name", width: 170 },            
            { label: "Client Id", fieldId: "clientId", display: 'form' },
            { label: "Client Secret", fieldId: "clientSecret", display: 'form' },
            { label: "Access Token", fieldId: "accessToken",  display: 'form' },
            { label: "Refresh Token", fieldId: "refreshToken",  display: 'form' },
            //{ label: "Renew Date", fieldId: "renewDate", type: "date", width: 170, canFilter: false, validation: (v, r) => this.validateStartDate(v,r) },
            { label: "Renew Date", fieldId: "renewDate", type: "datetime", width: 170, canFilter: false },
            { label: "Config", fieldId: "clientConfig", type: 'textarea', display: 'form' },
            //{ label: "Tariffs", fieldId: "tariffs", listSource: '/SiteSettings/GetTariffs', type: 'checkList', display: 'form' },
            //{ label: "Update Subs", fieldId: "updateSubs", type: 'funcButton', display: 'form', typeProps: { label: 'Refresh', func: (rec) => this.setState({testKey: Math.random()}) } },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton' }, 
        ];

        var ClientSelectionConfig = {
            model: 'ClientSettings',
            fields: ClientSelectionFields,
            master: true,    
            titleField: 'name',
            gridProps: { defaultPageSize: 20 },
            defaultSorted: [{ id: 'name', desc: false }],
            apiPath: API_PATH
        }

        var ClientCompaniesFields = [
            { label: "ClientCompanyMapId", fieldId: "id", width: 70, display: 'none' },
            { label: "Company Name", fieldId: "companyName", width: 170, list: listRequest.ClientCompanies },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/CompanyCard/' } },
        ];

        var ClientCompaniesConfig = {
            model: 'ClientCompanies',
            path: '/ClientSettings/GetClientCompanies/' + this.props.match.params.id,
            fields: ClientCompaniesFields, 
            gridButton: { pathname: '/CompanyCard/New' }, 
            titleField: 'companyName',
            apiPath: API_PATH
        }

        var ClientUsersFields = [
            { label: "Id", fieldId: "id", width: 150, display: 'none' },
            { label: "UserName", fieldId: "userName", width: 150, display: 'grid' },
            { label: "Email", fieldId: "email", width: 150 },
            { label: "Phone Number", fieldId: "phoneNumber", width: 150 },
            { label: "Last Login", fieldId: "lastLogin", width: 230, type: 'datetime'},
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/UserCard/' }, funcProps: (r) => { return { state: { attribToPass: 'testValue' } } } },            
        ];        

        var ClientUsersConfig = {
            model: 'ClientUsers',
            path: '/ClientSettings/GetClientUsers/' + this.props.match.params.id,
            fields: ClientUsersFields,
            gridButton: { pathname: '/UserCard/New' },
            titleField: 'userName',
            gridProps: { defaultPageSize: 20 },
            defaultSorted: [{ id: 'userName' }],
            apiPath: API_PATH
        }

        var ClientVanFields = [
            { label: "Id", fieldId: "id", width: 70, display: 'none' },
            { label: "Van Name", fieldId: "vanName", width: 170 },
            { label: '', fieldId: 'navButton', width: 60, display: 'grid', type: 'navButton', typeProps: { location: '/VanCard/' } },
        ];

        var ClientVansConfig = {
            model: 'ClientVans',
            path: '/ClientSettings/GetClientVans/' + this.props.match.params.id,
            fields: ClientVanFields,
            gridButton: { pathname: '/VanCard/New' },
            titleField: 'vanName',
            apiPath: API_PATH
        }

        return (
            <div>
                <div className='titleBlock'>Accounts</div>
                <div>
                    <MasterDetail key='mdcs' {...ClientSelectionConfig}> 
                        {this.props.match.params.id != 'New' ?
                            [<Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Companies</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Users</NavLink>
                            </NavItem>                            
                            <NavItem>
                                <NavLink className={classnames({ active: mainActiveTab === '3' })} onClick={() => { this.toggle('3', 'main') }}>Vans</NavLink>
                            </NavItem>  

                        </Nav>,
                        <TabContent key='clientTabs' activeTab={this.state.mainActiveTab}>
                            <TabPane tabId='1'>
                                {this.state.mainActiveTab == '1' ? <MasterDetail key={this.state.testKey} {...ClientCompaniesConfig} /> : null}                                
                            </TabPane>
                            <TabPane tabId='2'>
                                {this.state.mainActiveTab == '2' ? <MasterDetail key='mdcu' {...ClientUsersConfig} /> : null}                                
                            </TabPane>                            
                            <TabPane tabId='3'>
                                {this.state.mainActiveTab == '3' ? <MasterDetail key='mdcv' {...ClientVansConfig} /> : null}
                            </TabPane>  
                        </TabContent>]: null}
                    </MasterDetail>
                </div>
            </div>
        );

    }
}

export default (ClientSettings)
