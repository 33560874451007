import React, { Component } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Modal, ModalFooter, ModalBody, Button, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux';
import { ApiGet, ApiPost, ApiFilePost } from '../../../api'

import classnames from 'classnames'
import moment from 'moment'

import Dash from '../../Dashboard/components/DashContainer';
import DropDatePicker from '../../../components/DropDatePicker'
import MasterDetail from 'MasterDetail'
export const API_PATH = process.env.SERVER_PATH + '/api'

class DropFolderParser extends Component {
    mdRefCallback = (instance) => {
        this.caseMD = instance ? instance.getWrappedInstance() : null;
    }

    constructor(props) {
        super(props);
        var activeTab = '1'
        var locationState = this.props.location.state
        
        if (!this.props.commonstate.users.authRole.includes("Administrator")) {
            activeTab = '2'
        }

        if (locationState != null) {
            locationState.history.map((h) => {
                if (h.model.includes("client Templates")) {
                    activeTab = '2'
                }
            })
        }
        
        

        this.state = {
            mainActiveTab: activeTab, popupType: 0, popupMsg: null,
            templateList: [], templatesInfo: [], numProcessing: 0, templateId: 0, showTemplatePopup: false, canClick: false,
            fromDate: moment().subtract(1, 'months'), toDate: moment(),
        }

        this.handleDate = this.handleDate.bind(this)
    }

    componentDidMount() {
        this.getTemplateList()

        var activeTab = '1'
        if (!this.props.commonstate.users.authRole.includes("Internal")) {
            activeTab = '2'
        }

        var locationState = this.props.location.state
        if (locationState != null) {
            locationState.history.map((h) => {
                if (h.model.includes("import types")) {
                    activeTab = '2'
                }
            })
        }

        this.setState({mainActiveTab: activeTab})
    }

    toggle(tab, type) {
        if (type == 'main') {
            if (this.state.mainActiveTab !== tab) {
                this.setState({
                    mainActiveTab: tab,
                    templateId: 0,
                    fromDate: moment().subtract(1, 'months'), toDate: moment(), processing: true,
                });
            }
        }
    }

    handleTemplate(templateId) {
        this.setState({ templateId })
    }

    handleDataset(processing) {
        this.setState({ processing });
    }

    handleDate(fromDate, toDate) {
        this.setState({ fromDate, toDate })
    }

    getTemplateList() {
        ApiGet('DropFolderParser', 'GetTemplateList')
            .then((data) => {
                // console.log('data', data)
                this.setState({ templateList: data.templatesInfo })
            })
    }

    getTemplatesDetails() {
        ApiGet('DropFolderParser', 'GetTemplateDetails')
            .then((data) => {
                // console.log('data', data)
                this.setState({ templatesInfo: data.templatesInfo, numProcessing: data.processing })
            })
    }

    getFileData(rec, logs) {
        ApiPost('DropFolderParser', 'GetTaskFileData', { id: rec.id, logs })
            .then((data) => {
                // console.log('data', data)

                if (logs) {
                    this.showPopup(2, { logData: data.logData, logFile: data.logFile })
                }
                else {
                    this.showPopup(3, { dayData: data.dayData, monthData: data.monthData })
                }
            })
    }

    showPopup(popupType, popupMsg, rec) {
        var templateId = 0
        if (rec != null) {
            templateId = rec.id
        }

        this.setState({ templateId, showTemplatePopup: true, popupType, popupMsg })
    }

    uploadFiles(fileList) {
        var complete = false
        if (fileList.length > 0) { complete = true }
        this.setState({ fileList, canClick: complete })
    }

    startImport() {
        ApiFilePost('DropFolderParser', 'SetupImportPortal', { files: this.state.fileList, id: this.state.templateId })
            .then((data) => {

                var popupMsg = data.scheduled ? 'Your import has been scheduled, an email will be sent once completed' : 'Your import has not been added to the scheduling import queue'
                this.showPopup(1, popupMsg)
            })
    }

    hidePopup() {
        this.setState({ showTemplatePopup: false, canClick: false, complete: false, scheduled: false }, () => this.getTemplatesDetails())
    }

    customEmailValidation(rec) {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (rec != undefined) {
            if (regex.test(rec)) {
                return undefined
            }
            else {
                return 'Invalid email address'
            }
        }

    }

    displayWidgets() {
        const preloadedWidgets = [
            { i: '535507', x: 0, y: 0, w: 1, h: 1, type: '49', period: '0', metric: null, params: null },
            { i: '328884', x: 1, y: 0, w: 11, h: 1, type: '50', period: '9', metric: null, params: null }
        ]

        const preloadedDashInfo = { dashId: 999, tabId: 0, tabName: 'ImportDash', dashType: 0, fullDash: false, cols: 12, rowHeight: 180 }

        return (<div id='importDash'><Dash preloadedWidgets={preloadedWidgets} preloadedDashInfo={preloadedDashInfo} hideEdit={true} hideTitle={true} /></div>)
    }

    displayTabs() {
        return (
            <Nav tabs>
                {this.props.commonstate.users.authRole.includes("Internal") &&
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.mainActiveTab === '1' })} onClick={() => { this.toggle('1', 'main') }}>Templates</NavLink>
                    </NavItem>
                }

                <NavItem>
                    <NavLink className={classnames({ active: this.state.mainActiveTab === '2' })} onClick={() => { this.toggle('2', 'main') }}>Imports</NavLink>
                </NavItem>

                {/*
                <NavItem>
                    <NavLink className={classnames({ active: this.state.mainActiveTab === '3' })} onClick={() => { this.toggle('3', 'main') }}>Manual</NavLink>
                </NavItem>
                */}

                <NavItem>
                    <NavLink className={classnames({ active: this.state.mainActiveTab === '4' })} onClick={() => { this.toggle('4', 'main') }}>History</NavLink>
                </NavItem>
            </Nav>
        )
    }

    displayHeaderAdditions() {
        // var tInfo = this.state.templatesInfo

        // if (this.state.mainActiveTab === '1') {
        //     return (
        //         <div style={{ display: 'flex' }}>
        //             <select className="form-control" name="templateId" style={{ width: '200px' }} onChange={(e) => this.handleTemplate(e.target.value)}>
        //                 <option value="0">Select Template</option>

        //                 {tInfo.length > 0 && tInfo.map((i) => {
        //                     return (<option value={i.templateId}>{i.templateName}</option>)
        //                 })}

        //             </select>

        //             {this.state.templateId > 0 && <button type="button" className="btn btn-secondary" style={{ marginLeft: '20px' }} onClick={() => this.showPopup(0, "")}>Import</button>}
        //         </div>
        //     )
        // }

        if (this.state.mainActiveTab === '4') {
            return (
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '20px' }}>
                        <input type="radio" name="dataType" style={{ margin: '5px' }} onClick={() => this.handleDataset(true)} defaultChecked={this.state.processing} />
                        <label for="processing">Queued</label>
                    </div>
                    <div style={{ marginRight: '20px' }}>
                        <input type="radio" name="dataType" style={{ margin: '5px' }} onClick={() => this.handleDataset(false)} defaultChecked={!this.state.processing} />
                        <label for="completed">Completed</label>
                    </div>
                    <div>
                        <DropDatePicker right handleDate={this.handleDate} startDate={this.state.fromDate} endDate={this.state.toDate} />
                    </div>
                </div>
            )
        }
    }

    cascadeComponents() {
        var lists = { progress: [{ id: 0, name: <i className="fa fa-clock-o" style={{ color: '#0a92b1' }} /> }, { id: 1, name: <i className="fa fa-check" style={{ color: 'green' }} /> }, { id: 2, name: <i className="fa fa-times-circle" style={{ color: 'red' }} /> }] }

        var importTasksFields = [
            { label: 'Id', fieldId: 'id', display: 'none', width: 70 },
            { label: 'Filename', fieldId: 'filename', display: 'grid' },
            { label: 'Progress', fieldId: 'status', display: 'grid', width: 150, list: lists.progress },
            { label: "", fieldId: "funcButton", display: 'grid', width: 100, type: 'funcButton', typeProps: { label: 'View', func: (rec) => this.getFileData(rec, false) } },
            { label: "", fieldId: "funcButton", display: 'grid', width: 100, type: 'funcButton', typeProps: { label: 'Log', func: (rec) => this.getFileData(rec, true) } },
        ];

        var importTasksConfig = {
            model: 'tasks',
            path: '/DropFolderParser/Tasks/',
            fields: importTasksFields,
            master: true,
            crudParams: { taskId: this.props.match.params.id },
            defaultSorted: [{ id: 'filename', desc: false }],
            gridButton: false,
            gridProps: { defaultPageSize: 5 },
            apiPath: API_PATH
        }

        return <MasterDetail {...importTasksConfig} />
    }

    displaySubTable() {
        var ret = null
        var isEdit = false

        if (this.props.match && this.props.match.params.id) {
            if (this.props.match.params.id != "New") {
                if (this.props.match.params.id > 0) {
                    isEdit = true
                }
            }
        }

        if (this.state.mainActiveTab === '2' && isEdit) {
            var lookUpFields = [
                { label: 'Id', fieldId: 'id', display: 'none' },
                { label: 'Mapped Stations', fieldId: 'name', display: 'grid', width: 500 },
                { label: '', fieldId: 'navButton', display: 'grid', type: 'navButton', funcProps: (rec) => { return { location: '/ImportLookup/' + rec._original.id + '/0/' } }, width: 100 }
            ];

            var lookUpConfig = {
                model: 'lookup Details',
                path: '/DropFolderParser/ClientTemplatesLookUps/',
                fields: lookUpFields,
                titleField: 'name',
                crudParams: { clientTemplateId: this.props.match.params.id },
                defaultSorted: [{ id: 'name', desc: false }],
                gridButton: { pathname: '/ImportLookup/New', state: { clientTemplateId: this.props.match.params.id } },
                gridProps: { defaultPageSize: 20 },
                apiPath: API_PATH
            }

            ret = (
                <div style={{marginTop: '20px'}}>
                    <div className='titleBlock'>Station Name Mapping</div>

                    <div>
                        <TabContent>
                            <TabPane><MasterDetail {...lookUpConfig} /></TabPane>
                        </TabContent>
                    </div>
                </div>
            )
        }

        return ret
    }

    displayNote() {
        return (<p>*If you wish for multiple address to receive an email once import has completed separate them with <b>';'</b></p>)
    }

    displayTable() {
        var lists = {
            type: [{ id: 0, name: 'Portal' }, { id: 1, name: 'Email' }, { id: 2, name: 'FTP' }, { id: 3, name: 'Manual' }],
            templates: this.state.templateList
        }

        var fromDate = new Date(this.state.fromDate).toLocaleDateString() + " " + new Date(this.state.fromDate).toLocaleTimeString()
        var toDate = new Date(this.state.toDate).toLocaleDateString() + " " + new Date(this.state.toDate).toLocaleTimeString()

        var templateFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Template Name', fieldId: 'templateName', width: 500, validation: 'required' },
            { label: 'Definition details', fieldId: 'templateDefinition', display: 'form', type: 'textarea', validation: 'required' },
            { label: 'Settings (XML)', fieldId: 'templateSettings', display: 'form', type: 'textarea' },
            { label: '', fieldId: 'navButton', display: 'grid', width: 100, type: 'navButton', typeProps: { location: '/Import/' } },
        ];

        var clientTemplateFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Import Types', fieldId: 'templateName', display: 'grid', width: 500 },
            { label: 'Import Types', fieldId: 'templateId', display: 'form', list: lists.templates, validation: 'requiredList' },
            { label: 'Send To Address*', fieldId: 'sendTo', display: 'form', type: 'textarea',   validation: 'required' },
            { label: '', fieldId: 'id', display: 'form', type: 'custom', custom: () => this.displayNote() },            
            { label: '', fieldId: "funcButton", display: 'grid', width: 100, type: 'funcButton', typeProps: { label: 'Import', func: (rec) => this.showPopup(0, "", rec) }  },
            { label: '', fieldId: 'navButton', display: 'grid', width: 100, type: 'navButton', typeProps: { location: '/Import/' } },
        ];

        var scheduledFields = [
            { label: 'Id', fieldId: 'id', display: 'none' },
            { label: 'Status', fieldId: 'taskStatus', display: 'grid', width: 200 },
            { label: 'Import Type', fieldId: 'taskType', display: 'grid', width: 150, list: lists.type },
            { label: 'Import Source', fieldId: 'taskSource', display: 'grid' },
            { label: 'Import Template', fieldId: 'templateName', display: 'grid' },
            { label: 'Import Date', fieldId: 'taskDate', display: 'grid', width: 150, type: 'date' },
            { label: 'Start Time', fieldId: 'taskStartTime', display: 'grid', width: 150, type: 'time' },
            { label: 'End Time', fieldId: 'taskEndTime', display: 'grid', width: 150, type: 'time' }
        ];

        var templateConfig = {
            model: 'import types',
            path: '/DropFolderParser/Templates/',
            fields: templateFields,
            titleField: 'templateName',
            master: true,
            defaultSorted: [{ id: 'templateName', desc: false }],
            gridButton: { pathname: '/Import/New' },
            gridProps: { defaultPageSize: 10 },
            apiPath: API_PATH
        }

        var clientTemplateConfig = {
            model: 'client import types',
            path: '/DropFolderParser/ClientTemplates/',
            fields: clientTemplateFields,
            titleField: 'templateName',
            master: true,
            defaultSorted: [{ id: 'templateName', desc: false }],
            gridButton: { pathname: '/Import/New' },
            gridProps: { defaultPageSize: 10 },
            apiPath: API_PATH
        }

        var scheduleConfig = {
            model: 'schedules',
            path: '/DropFolderParser/Schedules/',
            fields: scheduledFields,
            cascade: true,
            cascadeComponents: this.cascadeComponents(),
            crudParams: { fromDate: fromDate, toDate: toDate, processing: this.state.processing },
            defaultSorted: [{ id: 'taskDate', desc: false }, { id: 'taskStartTime', desc: false }],
            gridButton: false,
            gridProps: { defaultPageSize: 20 },
            apiPath: API_PATH
        }

        var config = this.state.mainActiveTab === '1' ? templateConfig : this.state.mainActiveTab === '2' ? clientTemplateConfig : this.state.mainActiveTab === '4' && scheduleConfig
        return (<div><MasterDetail {...config} wrappedComponentRef={this.mdRefCallback} headerControl={null} />{this.displaySubTable()}</div>)
    }

    displayPopup() {
        var data = this.state.popupMsg

        if (this.state.popupType == 0) {
            return (
                <React.Fragment>
                    <div className='titleBlock'>File Uploader</div>
                    <div>
                        <div className="importPopupUploadContainer">
                            <p>Please select the files you wish to import</p>
                            <input type="file" className="importUpload" name="fileList" multiple onChange={(e) => this.uploadFiles(e.target.files)} />
                        </div>

                        <div className="importPopupButtonContainer">
                            <button type="button" className="btn btn-secondary" disabled={!this.state.canClick && 'disabled'} onClick={() => this.startImport()}>Import</button>
                            <button type="button" className="btn btn-secondary" onClick={() => this.hidePopup()}>Cancel</button>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else if (this.state.popupType == 1) {
            return (
                <React.Fragment>
                    <div className='titleBlock'>Import Scheduled</div>
                    <p>{data}</p>
                    <div className="importPopupButtonContainer">
                        <button type="button" className="btn btn-secondary" onClick={() => this.hidePopup()}>Ok</button>
                    </div>
                </React.Fragment>
            )
        }
        else if (this.state.popupType == 2) {
            var logFileLink = "/#/" + data.logFile
            var content = "No logs available";
            if (data.logData != null && data.logData != "") content = data.logData

            return (
                <React.Fragment>
                    <div className='titleBlock'>Log</div>
                    {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
                    <textarea style={{overflowY: 'scroll',  height: '175px', resize: 'none'}} rows="8" cols="100">{content}</textarea>

                    <div className="importPopupButtonContainer">
                        <button type="button" className="btn btn-secondary" onClick={() => this.hidePopup()}>Ok</button>

                        {data.logFile &&
                            <a className="btn btn-secondary" href={logFileLink} download>
                                <i className="fa fa-download" style={{ color: '#888888', marginRight: '5px' }} />
                                Download
                            </a>
                        }
                    </div>
                </React.Fragment>
            )
        }
        else if (this.state.popupType == 3) {
            var content = "<p>No data available</p>";
            if (data.dayData != null && data.monthData != "") content = data.dayData
            if (data.monthData != null && data.monthData != "") content = data.monthData

            return (
                <React.Fragment>
                    <div className='titleBlock'>Imported Data</div>
                    <div style={{height: '300px', overflowY: 'scroll'}} dangerouslySetInnerHTML={{ __html: content }} />

                    <div className="importPopupButtonContainer">
                        <button type="button" className="btn btn-secondary" onClick={() => this.hidePopup()}>Ok</button>
                    </div>
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <div>
                <div>
                    {this.state.mainActiveTab === '1' ?
                        <div className='titleBlock'>Template Selection</div>
                        : this.state.mainActiveTab === '2' ?
                            <div className='titleBlock'>Import Selection</div>
                            : this.state.mainActiveTab === '3' ?
                                <div className='titleBlock'>Manual</div>
                                :
                                <div className='titleBlock'>Historical Data</div>
                    }

                    {this.displayWidgets()}

                    {this.displayTabs()}

                    {this.displayHeaderAdditions()}
                </div>

                {this.displayTable()}

                {/* this.state.mainActiveTab === '1' &&
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {this.state.numProcessing > 0 ?
                            [<i className="fa fa-clock-o" style={{ color: '#0a92b1' }} />, <p style={{ margin: 'auto 5px' }}>Imports processing ({this.state.numProcessing})</p>]
                            :
                            [<i className="fa fa-check" style={{ color: 'green' }} />, <p style={{ margin: 'auto 5px' }} >No imports running</p>]
                        }
                    </div>
                */}

                {this.state.showTemplatePopup &&
                    <div className="importPopupContainer">
                        <div className="importPopupInnerContainer">
                            {this.displayPopup()}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common, scada: state.common.scada }
}

export default connect(mapStateToProps)(DropFolderParser)