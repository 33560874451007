import React, { Component } from 'react'
import { connect } from 'react-redux'

class FilterWarning extends Component {

	constructor(props) {
		super(props);
    }

    resetFilter() {
        console.log('reseting filter')
        document.getElementById('removeFilterBtn').click()
    }

    gW() {
        return (
            <div className='filterWarning' style={this.props.style}>
                <div className="summary-widget widget warning">
                <div className="widget-icon" style={{padding: 0, fontSize:'3rem'}} >
                    <i className='fal fa-exclamation-triangle' />
                </div>
                <div className="widget-info" >
                    <b style={{ display: 'block', fontSize:'1.5rem' }}>Filter Applied</b>
                    <button className='btn' onClick={() => this.resetFilter()}>Clear Filter</button>
                </div>
                </div>
            </div>
        )
    }

    render() {

        var dW = false
        if (this.props.header.filters && this.props.header.filters.asset) {
            if (this.props.header.assets.length != this.props.header.filters.asset.items.length) {
                dW = true
            }
        }

        return dW && this.gW()
	}
}

const mapStateToProps = state => {
    return { header: state.common.header }
}

export default connect(mapStateToProps)(FilterWarning)
