import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { connect } from 'react-redux'
export const API_PATH = process.env.SERVER_PATH + "/api";

class PartsSuppliers extends Component {
    mdRefCallback = (instance) => {
        this.caseMD = instance ? instance.getWrappedInstance() : null;
    }

    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        var suppliersFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Name', fieldId: 'name', display: 'form', validation: 'required' },
            { label: 'Contact', fieldId: 'contactPerson', display: 'form' },
            { label: 'Phone', fieldId: 'phone', display: 'form' },
            { label: 'Email', fieldId: 'email', display: 'form' },
            { label: 'Address Line 1', fieldId: 'address1', display: 'form' },
            { label: 'Address Line 2', fieldId: 'address2', display: 'form' },
            { label: 'Address Line 3', fieldId: 'address3', display: 'form' },
            { label: 'Town', fieldId: 'town', display: 'form' },
            { label: 'County', fieldId: 'county', display: 'form' },
            { label: 'Postcode', fieldId: 'postCode', display: 'form' },
            { label: 'Office Phone', fieldId: 'officePhone', display: 'form' },
            { label: 'Office Email', fieldId: 'officeEmail', display: 'form' },
            { label: 'Website', fieldId: 'website', display: 'form' },
            { label: 'VAT No', fieldId: 'vatNo', display: 'form' },
            { label: 'Account No', fieldId: 'accountNo', display: 'form' },
            { label: 'Terms', fieldId: 'terms', display: 'form', type: 'textarea' },
        ];

        var suppliersConfig = {
            model: 'partsSuppliers',
            path: '/PartSelection/AllSuppliers',
            fields: suppliersFields,
            titleField: 'name',
            master: true,
            apiPath: API_PATH,
            formActions: [
                { action: 'delete' },
                { action: 'back' },
                { action: 'save' }]          
        }
        return (
            <div>
                <MasterDetail key='mdSup' {...suppliersConfig} wrappedComponentRef={this.mdRefCallback} headerControl={null} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common }
}

export default connect(mapStateToProps)(PartsSuppliers)