import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { widgetconfig, metricInfo, widgetCategories } from '../../../configuration/widgets'
import Dash from './DashContainer'
import CheckboxTree from 'react-checkbox-tree'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import { WithContext as ReactTags } from 'react-tag-input'
import { ApiGet } from '../../../api'
import MultiMetricPicker from './MultiMetricPicker'
import SwatchPicker from './../../../components/SwatchPicker'
import helpers from '../../../utils/helpers'

class AddWidgetModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            periodDDVisible: false, paramDDVisible: false, currentType: null, currentDesc: null, buttonDisabled: true, currentParam: null, currentPeriod: null, listData: null,
            currentCategory: null, categoryList: null, treeChecked: [], treeExpanded: [], tags: [], paramsPerCol: 6, renderCol2: false
        };

        this.ddArray = [];
        this.renderParamDD = false;
        this.renderPeriodDD = false;
    }

    dropdownValidation(p) {
        if (this.ddArray.hasOwnProperty('period') && this.renderPeriodDD) {
            this.setState({
                buttonDisabled: false
            });
        }
        else if (this.ddArray.hasOwnProperty(p) && this.renderParamDD) {
            this.setState({
                buttonDisabled: false
            });
        }
        else if (!this.renderPeriodDD && !this.renderParamDD) {
            this.setState({
                buttonDisabled: false
            });
        }
        else {
            this.setState({
                buttonDisabled: true
            });
        }

    }

    componentDidMount() {
        if (this.props.editModal) {
            this.getWidgetVals(this.props.editWidgetInfo.type)

            this.setState({ currentCategory: widgetconfig[this.props.editWidgetInfo.type].category })

            var widgetPeriods = widgetconfig[this.props.editWidgetInfo.type].periods;

            var widgetParams = widgetconfig[this.props.editWidgetInfo.type].params;

            if (widgetPeriods) {
                this.periodArray = widgetPeriods;
                this.renderPeriodDD = true;
                this.forceUpdate();
            }
            else {
                this.renderPeriodDD = false;
                this.periodArray = null;
            }

            if (widgetParams) {
                this.paramsArray = widgetParams;
                this.renderParamDD = true;
                var paramCount = Object.keys(this.paramsArray).length + 1
                if (paramCount > this.state.paramsPerCol) {
                    this.setState({ renderCol2: true })
                }
            }
        }

        var tList = []

        Object.keys(widgetconfig).map(x => {
            var i = widgetconfig[x].category
            if (this.props.auth && (this.props.auth.includes(i) || this.props.auth.includes('Administrator'))) {
                if (tList.filter(e => e.id == i).length == 0) {
                    tList.push({ id: i, name: i })
                }
            }
        })

        this.setState({ categoryList: tList })
    }

    renderPeriods() {
            if (!this.ddArray.hasOwnProperty('period')) {
                this.ddArray['period'] = false;
            }
            return (
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label">Select Period</label>
                    <div className="col-sm-8">
                        <select className="form-control" onChange={e => { this.props.onChangePeriod(e); this.dropdownValidation(); this.setState({ currentPeriod: e.target.value }); }} defaultValue={this.props.editModal ? this.props.editWidgetInfo.period : null}>
                            <option key={'perioddll'} value={null}>Select Period</option>
                            {
                                Object.keys(this.periodArray).map(i => {
                                    return <option key={this.periodArray[i].id} value={this.periodArray[i].id}>{this.periodArray[i].title}</option>
                                })
                            }
                        </select>
                    </div>
                </div>)
    }

    renderParams(isCol2) {
        return (
            <div>
                {
                    Object.keys(this.paramsArray).map((p, i) => {
                        if (isCol2 && (i+1) <= this.state.paramsPerCol) {
                            return null
                        }
                        else if (!isCol2 && (i + 1) > this.state.paramsPerCol) {
                            return null
                        }
                        var editValue = null
                        if (!this.ddArray.hasOwnProperty(p)) {
                            this.ddArray[p] = false;
                        }
                        if (this.props.editWidgetInfo && this.props.editWidgetInfo.params) {
                            editValue = this.props.editWidgetInfo.params[p];
                        }
                        if (this.paramsArray[p].type == "dropdown") {
                            var dataName = p.split('_')[0]
                            var listItems = this.paramsArray[p].items
                            if (this.state.listData && this.state.listData[dataName]) {
                                listItems = this.state.listData[dataName]
                            }
                            return (
                                <div className="form-group row" key={this.paramsArray[p].label}>
                                    <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                    <div className="col-sm-8">
                                        <select className="form-control" onChange={e => { this.props.onSelectParam(e, p, this.paramsArray[p].type); this.selectParam(e); this.dropdownValidation(p); }}  >
                                            <option value={null}>{this.paramsArray[p].label}</option>
                                            {
                                                listItems && Object.keys(listItems).map(i => {
                                                    var selected = false
                                                    if (this.props.editModal && editValue == listItems[i].id) {
                                                        selected = true
                                                    }
                                                    var item = listItems[i].title || listItems[i].name
                                                    return <option key={'k' + item + listItems[i].id} selected={selected} value={listItems[i].id}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>)
                        }
                        if (this.paramsArray[p].type == "pairedDropdown") {
                            var listItems = this.paramsArray[p].items
                            if (this.state.listData && this.state.listData[p]) {
                                listItems = this.state.listData[p]
                            }
                            if (this.props.widgetParams && this.props.widgetParams[this.paramsArray[p].pairedWith]) {
                                var pairId = this.props.widgetParams[this.paramsArray[p].pairedWith]
                                listItems = listItems.find(x => x.id == pairId).mets
                            }
                            return (
                                <div className="form-group row" key={this.paramsArray[p].label}>
                                    <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                    <div className="col-sm-8">
                                        <select className="form-control" onChange={e => { this.props.onSelectParam(e, p, this.paramsArray[p].type); this.selectParam(e); this.dropdownValidation(p); }}  >
                                            <option value={null}>{this.paramsArray[p].label}</option>
                                            {
                                                listItems && Object.keys(listItems).map(i => {
                                                    var selected = false
                                                    if (this.props.editModal && editValue == listItems[i].id) {
                                                        selected = true
                                                    }
                                                    var item = listItems[i].title || listItems[i].name
                                                    return <option key={'k' + item + listItems[i].id} selected={selected} value={listItems[i].id}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>)
                        }
                        else if (this.paramsArray[p].type == "textbox") {
                            return (
                                <div className="form-group row" key={this.paramsArray[p].label}>
                                    <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" value={this.props.editModal ? editValue : undefined} placeholder={this.paramsArray[p].placeHolder} required onChange={e => this.inputValidation(e, p, 'textbox')} />
                                    </div>
                                </div>)
                        }
                        else if (this.paramsArray[p].type == "textarea") {
                            return (
                                <div className="form-group row" key={this.paramsArray[p].label}>
                                    <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                    <div className="col-sm-8">
                                        <textarea className="form-control" value={this.props.editModal ? editValue : undefined} style={{ resize: 'none' }} rows={this.paramsArray[p].rows} placeholder={this.paramsArray[p].placeHolder} required onChange={e => this.inputValidation(e, p, 'textarea')} />
                                    </div>
                                </div>)
                        }
                        else if (this.paramsArray[p].type == "checkbox") {
                            return (
                                <div className="form-group row" key={this.paramsArray[p].label}>
                                    <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                    <div className="col-sm-8">
                                        <input type="checkbox" id={"chkbx-" + this.paramsArray[p].label} className="form-control" defaultChecked={this.props.editModal ? editValue : true} onClick={e => this.inputValidation(e, p, 'checkbox')} />
                                    </div>
                                </div>)
                        }
                        else if (this.paramsArray[p].type == "hierarchyTree") {
                            const icons = { parentClose: null, parentOpen: null, leaf: null }
                            var nodes = []
                            var checked = this.state.treeChecked
                            if (this.props.editWidgetInfo && this.props.editWidgetInfo.params[p]) {
                                checked = this.props.editWidgetInfo.params[p]
                            }
                            if (this.state.listData && this.state.listData[p]) {
                                nodes = helpers.wrangleTreeData(this.state.listData[p])
                            }   
                            return (
                                <div className="form-group row" key={this.paramsArray[p].label}>
                                    <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                    <div className="col-sm-8">
                                        <div className='widgetMgmtBox'>
                                            <CheckboxTree nodes={nodes} icons={icons} checked={checked} expanded={this.state.treeExpanded} onCheck={treeChecked => this.setState({ treeChecked }, () => this.inputValidation(this.state.treeChecked, p, 'hierarchyTree'))} onExpand={treeExpanded => this.setState({ treeExpanded })} />
                                        </div>
                                    </div>
                                </div>)
                        }
                        else if (this.paramsArray[p].type == 'tags') {
                            var tags = this.state.tags, suggestions = []
                            if (this.state.listData && this.state.listData[p]) {
                                suggestions = this.state.listData[p].filter(x => x.group == null)
                            }    
                            if (this.props.editWidgetInfo && this.props.editWidgetInfo.params[p]) {
                                tags = this.props.editWidgetInfo.params[p]
                            }
                            const KeyCodes = { comma: 188, enter: 13, }
                            const delimiters = [KeyCodes.comma, KeyCodes.enter]
                            return (
                                <div className="form-group row" key={this.paramsArray[p].label}>
                                    <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                    <div className="col-sm-8">
                                        <div className='widgetMgmtBox'>
                                            <ReactTags tags={tags} suggestions={suggestions} placeholder='Search Tags' allowDragDrop={false} inputFieldPosition='top' handleDelete={(i) => this.handleTagDelete(i, p, tags)} handleAddition={(tag) => this.handleTagAddition(tag, p, tags, suggestions)} delimiters={delimiters} />
                                        </div>
                                    </div>
                                </div>)
                        }
                        else if (this.paramsArray[p].type == 'metricPicker') {
                            var dataName = p.split('_')[0]
                            if (this.state.listData && this.state.listData[dataName]) {
                                return (<div className="form-group row">
                                    <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                    <div className="col-sm-8">
                                        <MultiMetricPicker metrics={this.state.listData[dataName]} params={this.props.editWidgetInfo && this.props.editWidgetInfo.params[p]} onChange={(val) => this.inputValidation(val, p, 'metricPicker')} />
                                    </div>
                                </div>)
                            }
                            else {
                                return null
                            }
                        }
                        else if (this.paramsArray[p].type == 'swatchPicker') {
                            var isShade = false
                            if (this.paramsArray[p].shade) {
                                isShade = true
                            }
                            return (<div className="form-group row">
                                <label className="col-sm-4 col-form-label">{this.paramsArray[p].label}</label>
                                <div className="col-sm-8">
                                    <SwatchPicker params={this.props.editWidgetInfo && this.props.editWidgetInfo.params[p]} onChange={(val) => this.inputValidation(val, p, 'swatchPicker')} isShade={isShade} />
                                </div>
                            </div>)
                        }
                        else {
                            return null
                        }
                    })
                }
            </div>)
    }

    handleTagDelete(i, p, tags) {
        var t = this.state.tags
        if (t.length == 0 && tags.length > 0) {
            t = tags
        }
        this.setState({ tags: t.filter((tag, index) => index !== i) }, () => this.inputValidation(this.state.tags, p, 'tags'))
    }

    handleTagAddition(tag, p, tags, suggestions) {
        if (suggestions.includes(tag)) {
            var t = this.state.tags
            if (t.length == 0 && tags.length > 0) {
                t = tags
            }
            this.setState({ tags: [...t, tag] }, () => this.inputValidation(this.state.tags, p, 'tags'))
        }
    }

    inputValidation(e, p, type) {
        this.props.onSelectParam(e, p, type);
        this.setState({ buttonDisabled: false });
    }

    renderDesc() {
        return (
            <div className="form-group row">
                <label className="col-sm-4 col-form-label">Description</label>
                <div className="col-sm-8" style={{ lineHeight: '31px' }}>
                    {this.state.currentDesc}
                </div>
            </div>)
}

    renderButtons() {
        if (this.props.editModal) {
            return <Button color="primary" disabled={this.state.buttonDisabled} onClick={this.props.onEditItem}>Save</Button>
        }
        else {
            return <Button color="primary" disabled={this.state.buttonDisabled} onClick={this.props.onAddItem}>Add</Button>
        }
    }

    renderPreview() {
        if (this.state.buttonDisabled) {
            return <div className='widgetPreviewPlaceholder'><span>Select widget attributes for preview</span></div>
        }
        else {
            var wc = widgetconfig[this.state.currentType]
            if (wc) {
                var w = wc.maxW || 6
                var h = wc.maxH > 2 ? 2 : wc.maxH || 2
                const preloadedWidgets = [{ i: Math.floor(Math.random() * 1000000).toString(), x: 0, y: 0, w, h, type: this.state.currentType, period: this.state.currentPeriod, metric: this.state.currentParam, params: this.state.currentParam }]

                const preloadedDashInfo = { dashId: 995, tabId: 0, tabName: 'WidgetPreview', dashType: 0, fullDash: false, cols: 12, rowHeight: 140 }

                return <div><div className='widgetPreview'><Dash preloadedWidgets={preloadedWidgets} preloadedDashInfo={preloadedDashInfo} hideEdit={true} hideTitle={true} /></div></div>
            }
            else {
                return <div><div className='widgetPreview'></div></div>
            }
        }
    }

    onSelectWidget(e) {
        var widgetPeriods = widgetconfig[e.target.value].periods;

        this.paramsArray = widgetconfig[e.target.value].params;

        this.setState({
            currentType: e.target.value
        });

        if (this.paramsArray) {
            this.renderParamDD = true
            var paramCount = Object.keys(this.paramsArray).length + 1
            if (paramCount > this.state.paramsPerCol) {
                this.setState({renderCol2 : true})
            }
        }
        else {
            this.renderParamDD = false
        }

        if (widgetPeriods) {
            this.periodArray = widgetPeriods;
            this.renderPeriodDD = true;
        }
        else {
            this.renderPeriodDD = false;
            this.periodArray = null;
        }

        this.ddArray = [];

        //GET DESC
        var widgetDesc = null;
        this.setState({
            currentDesc: null
        });
        widgetDesc = widgetconfig[e.target.value].description;
        if (widgetDesc) {
            this.setState({
                currentDesc: widgetDesc
            });
        }
    }

    selectParam(e) {
        var paramDesc = '';

        this.setState({ currentParam: e.target.value})

        if (this.props.editModal) {
            if (metricInfo[this.props.editWidgetInfo.type]) {
                paramDesc = metricInfo[this.props.editWidgetInfo.type][e.target.value].description;
            }
        }
        else {
            if (metricInfo[this.state.currentType]) {
                paramDesc = metricInfo[this.state.currentType][e.target.value].description;
            } 
        }

        if (paramDesc) {
            this.setState({
                currentDesc: paramDesc
            });
        }
    }

    getWidgetVals(widgetId) {
        var getParams = (widgetconfig[widgetId].fetchParams != null)
        console.log('getParams', getParams)
        ApiGet('Dashboard', 'GetWidgetListVals', { widgetId, getParams })
        .then((data) => {
            this.setState({listData: data})
        })
    }

    getWidgetOptions() {
        var widgetOptions = [<option key={'widgetddl'} value={null}>Select Widget</option>]
        Object.keys(widgetconfig).map(i => {
            var w = widgetconfig[i]
            if (w.category == this.state.currentCategory) {
                if (!w.role) {
                    var selected = false
                    if (this.props.editModal && this.props.editWidgetInfo.type == w.id.toString()) {
                        selected = true
                    }
                    widgetOptions.push(<option key={w.id} selected={selected} value={w.id.toString()}>{w.title}</option>)
                }
                else {
                    if (this.props.auth && this.props.auth.includes(w.role)) {
                        var selected = false
                        if (this.props.editModal && this.props.editWidgetInfo.type == w.id.toString()) {
                            selected = true
                        }
                        widgetOptions.push(<option key={w.id} selected={selected} value={w.id.toString()}>{w.title}</option>)
                    }
                }
            }
        })
        return widgetOptions
    }

    render() {
        return (
            <Modal className='addWidgetModal' isOpen={this.props.isOpen} autoFocus={false} >
                <ModalHeader>{this.props.editModal ? 'Edit' : 'Add'} Widget</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Select Category</label>
                                <div className="col-sm-8">
                                    {this.state.categoryList && <select className="form-control" onChange={e => this.setState({ currentCategory: e.target.value })} defaultValue={this.state.currentCategory} >
                                        <option key={'widgetddl2'} value={null}>Select Category</option>
                                        {
                                            this.state.categoryList.map(i => {
                                                return <option key={i.id} value={i.id}>{i.name}</option>
                                            })
                                        }
                                    </select>}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Select Widget</label>
                                <div className="col-sm-8">
                                    {widgetconfig && <select className="form-control" onChange={e => { this.props.selectWidgetType(e); this.getWidgetVals(e.target.value); this.onSelectWidget(e); this.dropdownValidation(); }} >
                                        {this.getWidgetOptions()}
                                    </select>}
                                </div>
                            </div>
                            {this.state.currentDesc && this.renderDesc()}

                            {this.renderPeriodDD && this.renderPeriods()}

                            {this.renderParamDD && this.renderParams(false)}
                        </div>
                        <div className="col-sm-6">
                            {this.renderParamDD && this.state.renderCol2 && this.renderParams(true)}
                            {this.renderPreview()}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {this.renderButtons()}
                    <Button color="secondary" onClick={this.props.toggleWidgetModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
};

export default AddWidgetModal;