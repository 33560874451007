
export const dbTables = {
    Stations: 1,
    Controller: 2,
    Alerts: 3,
    Parts: 4,
    Workcards: 5,
    ServiceReport: 6
}

export const availTables = [
    {
        name: 'Stations',
        dbId: 'esync_stations',
        id: 1,
        fields: [
            { id: 'WindNiId', name: 'Id' },
            { id: 'Id', name: 'IntId' },
            { id: 'Name', key: true },
            { id: 'Description' },
            { id: 'Lat' },
            { id: 'Long' },
            { id: 'TurbineSize' },
            { id: 'ControllerType', name: 'Controller' },
            { id: 'TurbineModel', name: 'Model' },
            { id: 'Dvid', name: 'Dev Vehicle' },
            { id: 'CommisionDate' },
            { id: 'AccreditationDate' },
            { id: 'IndustryId', name: 'Industry' },
            { id: 'DNC' },
            { id: 'MEC' },
            { id: 'TIC' },
        ]
    },
    {
        name: 'Controller',
        dbId: 'Controller',
        id:2,
        fields: [
            { id: 'ControllerId', name: 'Id' },
            { id: 'Name', key: true },
            { id: 'IndustryId', name: 'Industry' }
        ]
    },
    {
        name: 'Alerts',
        dbId: 'StationAlert',
        id: 3,
        fields: [
            { id: 'StationAlertId', name: 'Id' },
            { id: 'Name', key: true },
            { id: 'AlertTypeId', name: 'Alert Type' }
        ]
    },
    {
        name: 'Parts',
        dbId: 'TurbinePart',
        id: 4,
        fields: [
            { id: 'PartId', name: 'Id' },
            { id: 'Name', key: true },
            { id: 'PartTypeId', name: 'Part Type' }
        ]
    },
    {
        name: 'Workcards',
        dbId: 'EngineerLog',
        id: 5,
        fields: [
            { id: 'EngineerLogId', name: 'Id', key:true },
        ]
    },
    {
        name: 'Service Report',
        dbId: 'CalendarEvents',
        id: 6,
        fields: [
            { id: 'CalendarEventId', name: 'Id', key: true },
        ]
    }

]