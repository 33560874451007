import React, { Component } from 'react'
import MasterDetail from 'MasterDetail'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row } from 'reactstrap';
import { listRequest } from '../../../configuration/enums';
export const API_PATH = process.env.SERVER_PATH + "/api";

class PurchasingDepartment extends Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: 1, poId: 1 };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    submitCallback(e) {
        console.log('e',e)
        setTimeout(() => this.setState({ poId: Math.random() }), 1000)
    }

    getTable(approverTypeId) {

        var approverFields = [
            { label: "Id", fieldId: "id", display:'none' },
            { label: "Id", fieldId: "approverTypeId", display: 'none' },
            { label: "Id", fieldId: "purchaseDepartmentId", display: 'none' },
            { label: "Approver", fieldId: "userId", list: listRequest.operators, inlineEdit: true },
            { label: "Lower Threshold (>=)", fieldId: "lowerThreshold", inlineEdit: true },
            { label: "Upper Threshold (<)", fieldId: "upperThreshold", inlineEdit: true, readOnly: (e) => console.log('readOnly',e) },
            { label: "No upper limit", fieldId: "noUpperLimit", type:'checkbox', inlineEdit: true, display: 'none' },
            { label: '', fieldId: 'inlineSaveBtn', width: 60, display: 'grid', type: 'inlineSaveBtn' }
        ]

        var approverSetup = {
            model: 'PurchasingDepartmentApprover',
            fields: approverFields,
            preData: { approverTypeId, purchaseDepartmentId: this.props.match.params.id  },
            crudParams: { approverTypeId },
            addInline: true,
            apiPath: API_PATH,
            submitCallback: (e) => this.submitCallback(e),
            defaultSorted: [{ id:'lowerThreshold', desc:'false' }]
        }

        return <MasterDetail key={approverTypeId + this.state.poId} {...approverSetup} />
    }

    getApproversTabs() {

        const { activeTab } = this.state;
        const tabConfig = [{ id: 1, name: 'HOD Approvers' }, { id: 2, name: 'Finance Approvers' }]

        return (
            <div>
                <Row style={{ justifyContent: 'space-between', margin: '0 0' }}>
                    <Nav tabs>
                        {tabConfig.map(t => (
                            <NavItem key={t.id}>
                                <NavLink
                                    className={activeTab === t.id ? 'active' : ''}
                                    onClick={() => this.toggle(t.id)}>
                                    {t.name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </Row>
                <TabContent key='clientTabs' activeTab={activeTab}>
                    {tabConfig.map(t => (
                        <TabPane key={t.id} tabId={t.id}>
                            {activeTab === t.id && this.getTable(t.id)}
                        </TabPane>
                    ))}
                </TabContent>
            </div>
        );
    }

    render() {
        var suppliersFields = [
            { label: 'Id', fieldId: 'id', width: 70, display: 'none' },
            { label: 'Department', fieldId: 'name', inlineEdit: true, width: 500 },
            { label: 'Variance % Threshold', fieldId: 'variancePercentageThreshold', inlineEdit: true },
            { label: 'Variance Min \u00A3 Threshold', fieldId: 'varianceMinAmountThreshold', inlineEdit: true },
        ];

        var suppliersConfig = {
            model: 'purchasingDepartment',
            fields: suppliersFields,
            titleField: 'name',
            master: true,
            apiPath: API_PATH,
            formActions: [
                { action: 'delete' },
                { action: 'back' },
                { action: 'save' }]          
        }
        return (
            <div>
                <MasterDetail key='mdSup' {...suppliersConfig}>
                    {this.props.match.params.id != 'New' && this.getApproversTabs()}
                </MasterDetail>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { commonstate: state.common }
}

export default connect(mapStateToProps)(PurchasingDepartment)