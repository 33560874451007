import React, { Component } from 'react';
import { ApiPost } from '../../api' 
import VanPin from './VanPin.js';
import GoogleMap from 'google-map-react';
const DEBUG_MODE = process.env.DEBUG_MODE;


export default class MapVans extends Component {

    constructor(props) {
        super(props);
        this.polylineAddList = [];
        this.polylineCurrentList= [];
        this.ownerColor = { 15: 'red', 16: '#CC61C3', 28: 'green', 29: '#ffbe00', 31: 'blue', 35:'#00D3FF' };
        this.state = {
            VanLocations: null,
            gpsUserSessionId: ""
        };
        window.vanMap = this;
    }

    componentDidMount() {  
        this.timerId = setInterval(this.timer.bind(this), 20000);
    }
    
    timer() {     
        //console.log("timer call")
        //if (!DEBUG_MODE) {
        //    this.getVans()
        //}
    };

    setUserSession() 
    {
        ApiPost('VehicleTracking', 'GetSessionId', { strUsername: 'admin', strPassword: 'Windni1234' })
            .then((data) => {
                //console.log("Get GpsGate SessionId")
                this.setState({ gpsUserSessionId: data }, () => this.getVans());                
            })
    }

    getVans() {
        //console.log("Get Vans")
        if (!this.state.gpsUserSessionId) {
            //console.log("Get GpsGateUser")
            this.setUserSession();
        }
        else {
            //console.log("Get Van locs")
            ApiPost('VehicleTracking', 'GetVans', { gpsUserSessionId: this.state.gpsUserSessionId.sessionId })
            .then((data) => {   
                this.setState({ VanLocations: data.result }, () => this.vanTracks());                    
            })
        }
    }    

    vanTracks()
    {
        const { mapRef } = this.props;
        var locations = this.state.VanLocations;
        if (locations && locations.length > 0) {
            var skipped = 0;
            for (var i = 0; i < locations.length; i++)
            {         
                if (locations[i].path) {
                    ApiPost('VehicleTracking', 'loadTrack', { Id: locations[i].path, gpsUserSessionId: this.state.gpsUserSessionId.sessionId, VanId: locations[i].vehicle })
                    .then((data) => {
                        var path = this.xmlTracks2LatLong((data.response))
                        var polyline =  this.path2polyline(path, this.ownerColor[data.vanId])
                        polyline.setMap(mapRef)
                        this.polylineAddList.push(polyline)
                        if (this.polylineAddList.length+skipped >= locations.length) {
                            this.polylineCurrentList = this.AddAndRemovePolylines(this.polylineAddList, this.polylineCurrentList);
                        }
                    })
                } else {
                    skipped = skipped +1
                }
            }
        } 
    }  

    // Add and removes polylines
    // returns a list of currentPolylines
    AddAndRemovePolylines(addList, remove) {
        const { mapRef } = this.props;

        for (var i = 0; i < addList.length; i++) {
            if (!addList[i]) {
                debugger;
            }
            addList[i].setMap(mapRef)
        }
        // Loop over PolyList and Remove all polylines from Map
        const polylineRemoveList = remove;                
        for (var i = 0; i < polylineRemoveList.length; i++) {
            polylineRemoveList[i].setMap(null);
        }
        const copyForRemove = addList.slice(0)
        this.polylineAddList = []
        return copyForRemove
    }


    xmlTracks2LatLong(result, ownerId) {        
        var parser = new DOMParser();
        var xmlResult = parser.parseFromString(result, "text/xml");
        let path = [];
        if (xmlResult.getElementsByTagName("trackPoint").length > 0) {
            for (var i = 0; i < xmlResult.getElementsByTagName("trackPoint").length; i++) {

                let pathLatLong = {
                    lat: parseFloat(xmlResult.getElementsByTagName("trackPoint")[i].getElementsByTagName("lat")[0].innerHTML),
                    lng: parseFloat(xmlResult.getElementsByTagName("trackPoint")[i].getElementsByTagName("lng")[0].innerHTML)
                };
                path.push(pathLatLong);
            }
        }
        return path;
    }  
    
    path2polyline(coordinates, color)
    {
        return new window.google.maps.Polyline({
            strokeColor: color,
            fillColor: color,
            strokeOpacity: 0.3,
            fillOpacity:0.3,
            Weight: 2,
            path: coordinates 
        });
    }

    markers() {
        return [this.state.VanLocations ?
            this.state.VanLocations.map(x => {
                return <VanPin key={x.vehicle} id={x.vehicle} lat={x.lat} lng={x.lon}  siteName={x.label}  speed={x.speed} heading={x.heading} />
            })
            : null]
    }

    render()
    {
        return null
        
        /*console.log("Render Vans")
        return [this.state.VanLocations ?
            this.state.VanLocations.map(x => {
                return <VanPin key={x.vehicle} id={x.vehicle} alertsx={null} lat={x.lat} lng={x.lon} windSpeed={x.windSpeed} siteName={x.label} siteOutput={x.siteOutput} stalex={false} speed={x.speed} heading={x.heading} $markerHolderClassName={'vanContain'} />
            })
            : null]
        */
    }
}


    