import React from "react";

export class ToggleSwitch  extends React.Component {
   constructor(props) {
    super(props)
    this.state = {checked: false};
    }

   componentWillMount() {
       if (this.props.checked) {
           this.setState({checked: true})
       }
   }

  onToggleClick()
  {
    
      const { onToggle } = this.props;
      const toState = !this.state.checked;
      this.setState({checked: toState});
      if (onToggle) {
        onToggle(toState)
      }
  }

  render() {
    const { smallMode=false, textOn, textOff, onToggle, switchLabel, customStyle, style } = this.props;
    const classn = smallMode?"can-toggle sm": "can-toggle"
    const width = (smallMode) ? 0 : 130;
    return (
      <div className={classn + ' ' + customStyle} style={{marginLeft: '-'+ width +'px', ...style}}>
          <span style={{marginLeft: '20px'}}>{switchLabel}</span>
          {(this.state.checked)?
              <input id="a" type="checkbox" defaultChecked="checked"  onClick={() => this.onToggleClick()}/>
            :
              <input id="a" type="checkbox" onClick={() => this.onToggleClick()}/>
          }
          <label htmlFor="a">
              <div className="can-toggle__switch" data-checked={textOn?textOn:"on"} data-unchecked={textOn?textOff:"off"}></div>
          </label>
      </div>
      )
  }
}
